import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  getTransactionDetails,
  getTransactionLegs,
} from "../../../../api/ApiV2";
import { LoadingCircleIcon } from "../../../common/AppIcons";
import Check from ".";

export default function CheckDetailPopup({ hoaId, onSuccess, transactionId }) {
  const [check, setCheck] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (transactionId) {
      setLoading(true);
      let apiLoading = {
        detail: true,
        legs: true,
      };
      const checkAPILoading = () => {
        if (apiLoading.detail === false && apiLoading.legs === false) {
          setLoading(false);
        }
      };
      getTransactionDetails({
        hoaId,
        transactionId,
      })
        .then((res) => {
          console.log("res", res);
          setCheck((check) => ({ ...check, ...res }));
          apiLoading.detail = false;
          checkAPILoading();
        })
        .catch((res) => {
          toast.error("Error fetching check details");
          apiLoading.detail = false;
          checkAPILoading();
          console.log(res);
        });

      getTransactionLegs({ hoaId, transactionId })
        .then((res) => {
          console.log("res", res);
          apiLoading.legs = false;
          checkAPILoading();
          setCheck((check) => ({
            ...check,
            legs: res.results,
            // items: res.results,
          }));
        })
        .catch((res) => {
          apiLoading.legs = false;
          checkAPILoading();
          console.log(res);
        });
    }
  }, [transactionId]);

  if (loading)
    return (
      <div className="w-full flex items-center justify-center py-16">
        <LoadingCircleIcon className="h-6" />
      </div>
    );
  else
    return (
      <Check
        hoaId={hoaId}
        onSuccess={onSuccess}
        readOnly={true}
        baseData={check}
      />
    );
}
