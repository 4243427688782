import { Link } from "raviger";
import { useRecoilState } from "recoil";

import { userAtom } from "../../../../store/UserAtom";
import { adminTypes } from "../../../../common/data/adminTypes";

export default function NamePlate({
  hoaId,
  label,
  messages,
  notifications,
  compliance_uploads,
}) {
  const [user] = useRecoilState(userAtom);
  return (
    <div className="bg-white rounded-md">
      <div className="flex flex-row items-center justify-between p-8">
        <div className="flex flex-row items-start">
          <img
            src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            className="rounded-full"
            style={{ height: "80px", width: "80px" }}
            alt="avatar"
          />
          <div className="flex flex-col mt-1.5 ml-6">
            <span className="text-sm text-gray-500 font-semibold">
              Welcome back,
            </span>
            <span className="font-semibold text-2xl">{label}</span>
          </div>
        </div>
        <Link
          href={
            (user.hoaType === "SINGLE_FAMILY" ||
              user.hoaType === "MULTI_FAMILY") &&
            user.selectedUserType === "BOARD_MEMBER"
              ? "/hoa/" + hoaId + "/members"
              : adminTypes.includes(user.selectedUserType)
              ? "/hoa/" + hoaId + "/update?profile=true"
              : "/hoa/" +
                hoaId +
                "/members?unit=" +
                user.userProfiles?.find(
                  (val) => val.id === user.selectedUserProfile
                )?.unit
          }
          className="text-center border border-gray-300 shadow-sm hover:shadow-md rounded-md px-4 py-2 text-sm text-gray-600 hover:text-gray-700 font-semibold"
        >
          View profile
        </Link>
      </div>
      <div className="w-full grid grid-cols-2 border-t border-gray-200 divide-x divide-gray-200">
        <div className="w-full flex flex-row gap-1 items-center justify-center p-4">
          {messages}
          <span className="text-sm text-gray-500">Messages</span>
        </div>
        {/* <div className="w-full flex flex-row gap-1 items-center justify-center p-4">
          {notifications}
          <span className="text-sm text-gray-500">Notifications</span>
        </div> */}
        <Link
          href={"/hoa/" + hoaId + "/compliance_documents/"}
          className="w-full flex flex-row gap-1 items-center justify-center p-4"
        >
          {compliance_uploads}
          <span className="text-sm text-gray-500">
            Pending Compliance Uploads
          </span>
        </Link>
      </div>
    </div>
  );
}
