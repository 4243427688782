import { useEffect, useState } from "react";
import { CashIcon, ClockIcon } from "@heroicons/react/solid";
import { toast } from "react-toastify";
import { Link } from "raviger";
import { useRecoilState } from "recoil";

import { getBills } from "../../api/ApiV2";
import Paginator from "../../common/Paginator";
import Modal from "../common/Modal";
import { amountFormat } from "../common/AmountFormat";
import { CrossIcon, GeneralDocumentsIcon } from "../common/AppIcons";
import AddRevenue from "../accounting/components/addRevenue";
import { userAtom } from "../../store/UserAtom";
import RevanueDetailPopup from "../accounting/components/addRevenue/RevenueDetailPopup";
import { addressFormatLine2 } from "../../utils/addressFormat";
import { adminTypes } from "../../common/data/adminTypes";

export default function PaymentsUnsettled({
  hoaId,
  cardPaymentStatus,
  invoiceId,
}) {
  const [user] = useRecoilState(userAtom);
  const admin = adminTypes.includes(user.selectedUserType);

  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState({ show: false, setting: "NONE", data: {} });

  useEffect(() => {
    if (cardPaymentStatus && invoiceId) {
      setMenu({
        setting: "INVOICE_POPUP",
        data: {
          id: invoiceId,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (menu.setting === "NONE") {
      fetchBills();
    }
  }, [menu.setting]);

  useEffect(() => {
    setPage({ total: 0, current: 0 });
    fetchBills(0);
  }, [user.selectedUserProfile]);

  const fetchBills = (current) =>
    getBills({
      hoaId: hoaId,
      page: current ?? page.current,
      invoice: "True",
      paid: "False",
      user: user.id,
    })
      .then((res) => {
        setPage({ ...page, total: res.count });
        setTransactions(res.results);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
      });

  return (
    <div className="pl-3 sm:px-4 lg:px-5 pt-5 pb-7 sm:w-full m-auto bg-gray-100 rounded-lg">
      {loading && (
        <div className="fixed flex items-center justify-center z-30 inset-0 bg-opacity-80 bg-white">
          <div
            style={{ borderTopColor: "transparent" }}
            className="border-solid animate-spin rounded-full border-blue-400 border-4 h-12 w-12"
          ></div>
        </div>
      )}
      <div className="flex flex-row font-semibold text-xl w-full justify-start gap-10 ml-1 ">
        <span className="opacity-80">Unpaid</span>
        <Link href={"/hoa/" + hoaId + "/invoices/user/settled"}>
          <span className="opacity-50 hover:opacity-70 cursor-pointer">
            Paid
          </span>
        </Link>
      </div>
      <div className="flex flex-col md:grid md:grid-cols-4">
        <div className="md:col-span-3">
          <div className="h-px w-full bg-gray-400 mt-1" />
          <div className="grid grid-cols-7 gap-2 px-5 mt-3 mb-2 text-sm opacity-50 font-bold">
            {[
              { title: "ADDRESS", class: "col-span-2" },
              { title: "DESCRIPTION", class: "col-span-2" },
              { title: "DATE", class: "" },
              { title: "AMOUNT", class: "w-full text-center" },
            ].map((value) => {
              return (
                <span key={value.title} className={value.class}>
                  {value.title}
                </span>
              );
            })}
          </div>
          <div className="overflow-auto">
            {transactions.length === 0 ? (
              <div className="text-center text-sm text-gray-400 my-5">
                No Transaction Available
              </div>
            ) : (
              transactions.map((value) => {
                return (
                  <div
                    key={value.id}
                    className="grid grid-cols-7 gap-2 border border-gray-500 text-gray-800 rounded-md text-sm font-semibold bg-white hover:bg-blue-300 cursor-pointer px-5 py-3 shadow"
                    onClick={() => {
                      setMenu({
                        setting: "INVOICE_POPUP",
                        data: value,
                      });
                    }}
                  >
                    <span className="break-words col-span-2">
                      {addressFormatLine2(value.customer_unit_object) ??
                        "Untracked Customer"}
                    </span>
                    <span className="break-words col-span-2">{value.name}</span>
                    <span className="">{value.date}</span>
                    <div className="w-full flex justify-center">
                      <span className="break-all">${value.amount}</span>
                    </div>
                    <div className="flex flex-row justify-end">
                      {value.payment_status === "PROCESSING" ? (
                        <div className="bg-yellow-600 bg-opacity-10 text-yellow-600 px-2 py-1 flex flex-row gap-2 justify-center items-center text-xs rounded-sm">
                          <ClockIcon className="h-3 my-px" />
                          Payment Processing
                        </div>
                      ) : value.payment_status === "REQUIRES_ACTION" ? (
                        <div className="bg-indigo-600 bg-opacity-10 text-indigo-500 px-2 py-1 flex flex-row gap-2 justify-center items-center text-xs rounded-sm">
                          <GeneralDocumentsIcon className="h-3 my-px" /> Require
                          Action
                        </div>
                      ) : value.payment_status === "FAILED" ? (
                        <div className="bg-red-600 bg-opacity-10 text-red-500 px-2 py-1 flex flex-row gap-2 justify-center items-center text-xs rounded-sm">
                          <CrossIcon className="h-3 my-px" /> Payment Failed
                        </div>
                      ) : value.payment_status === null ? (
                        <div className="bg-gray-600 bg-opacity-10 text-gray-600 px-2 py-1 flex flex-row gap-2 justify-center items-center text-xs rounded-sm">
                          <CashIcon className="h-3 my-px " /> Not paid
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <Paginator
            totalPage={page.total}
            currentPage={page.current + 1}
            entryPerPage={50}
            onChange={(pageNo) => {
              setPage({ ...page, current: pageNo - 1 });
              fetchBills(pageNo - 1);
            }}
          />
        </div>
        <div className="mt-6 md:ml-5 md:pt-6">
          <div className="border border-gray-500 hover:border-blue-500 shadow-inner opacity-90 rounded-lg bg-white flex flex-col w-full pb-3 pt-2 pr-6 pl-4">
            <span className="flex flex-col">
              <h1 className="w-full font-bold text-gray-600">Total Payable</h1>
              {/* <div className="h-px w-20 relative bottom-px bg-gray-500 ml-3" /> */}
            </span>
            <h1
              className={
                "w-full pr-2 text-right relative top-1 text-blue-900 opacity-90 font-bold text-2xl"
              }
            >
              $
              {amountFormat(
                transactions?.reduce((sum, value) => {
                  return sum + Number(value.amount);
                }, 0)
              )}
            </h1>
          </div>
        </div>
      </div>
      <Modal
        wide
        show={menu.setting !== "NONE"}
        setShow={() => {
          setMenu({ ...menu, setting: "NONE" });
        }}
      >
        {menu.setting === "CREATE_INVOICE" ? (
          <AddRevenue
            hoaId={hoaId}
            setShow={() => {
              setMenu({ ...menu, setting: "NONE" });
            }}
          />
        ) : (
          <RevanueDetailPopup
            hoaId={hoaId}
            remove_pay={menu.data.payment_status !== null || admin}
            onSuccess={() => {
              setMenu({ ...menu, setting: "NONE" });
            }}
            billId={menu.data?.id}
            set_invoice_paid={(warning) => {
              setMenu({ ...menu, setting: "NONE" });

              warning && toast.success(warning);
            }}
          />
        )}
      </Modal>
    </div>
  );
}
