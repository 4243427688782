import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getDocuments } from "../../../../api/ApiV2";
import FileInput from "./FileInput";
import { LoadingCircleIcon } from "../../../common/AppIcons";

const fields = [
  {
    label: "ARC Checklist",
    value: "CHECKLIST",
  },
  {
    label: "ARC Drawings",
    value: "DRAWING",
  },
];
export default function RequiredDocuments({
  hoaId,
  read_only,
  id,
  className,
  warning,
  remove_warning,
  set_warning,
  next_page,
}) {
  const [documents, setDocuments] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getARDocuments();
  }, []);

  const getARDocuments = () => {
    getDocuments(hoaId, "ARCHITECTURAL_REVIEW")
      .then((res) => {
        setLoading(false);
        console.log(res.results?.filter((val) => val.architecture_plan === id));
        setDocuments({
          CHECKLIST:
            res.results?.filter(
              (val) =>
                val.architecture_plan === id && val.item_type === "CHECKLIST"
            ) ?? [],
          DRAWING:
            res.results?.filter(
              (val) =>
                val.architecture_plan === id && val.item_type === "DRAWING"
            ) ?? [],
        });
      })
      .catch((res) => {
        setLoading(false);
        toast.error(res.message ?? "Error getting documents");
      });
  };

  if (loading)
    return (
      <div className="w-full py-20 bg-opacity-10 flex items-center justify-center">
        <LoadingCircleIcon className="h-8" />
      </div>
    );
  else
    return (
      <div className={"flex flex-col " + className}>
        {fields.map((field) => (
          <FileInput
            read_only={read_only}
            hoaId={hoaId}
            id={id}
            documents={documents?.[field.value] ?? []}
            label={field.label}
            value={field.value}
            remove_warning={remove_warning}
            warning={warning}
            set_warning={set_warning}
            getARDocuments={getARDocuments}
          />
        ))}
        {!read_only && (
          <div className="mt-8 text-right flex flex-row justify-end">
            <button
              onClick={next_page}
              style={{ backgroundColor: "#2A32FD" }}
              className={
                "text-white font-semibold h-10 w-20 flex items-center justify-center rounded shadow-sm " +
                (Object.keys(warning)?.length === 0
                  ? "hover:opacity-80"
                  : "opacity-50")
              }
            >
              {"Done"}
            </button>
          </div>
        )}
      </div>
    );
}
