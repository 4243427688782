import React, { useEffect, useState } from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { formatPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { Link } from "raviger";

import { LoadingCircleIcon } from "../../../common/AppIcons";
import { arch_review_plan_type } from "../../constants";
import { getDocuments } from "../../../../api/ApiV2";
import { userAtom } from "../../../../store/UserAtom";
import { useRecoilState } from "recoil";

export default function GeneralInformation({ hoaId, plan_data }) {
  const [user] = useRecoilState(userAtom);
  const [documents, setDocuments] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (plan_data?.id) getARDocuments();
  }, [plan_data?.id, user.selectedUserProfile]);

  const getARDocuments = () => {
    setLoading(true);
    getDocuments(hoaId, "ARCHITECTURAL_REVIEW")
      .then((res) => {
        setLoading(false);
        console.log(
          res.results?.filter((val) => val.architecture_plan === plan_data?.id)
        );
        setDocuments(
          res.results?.filter((val) => val.architecture_plan === plan_data?.id)
        );
      })
      .catch((res) => {
        setLoading(false);
        toast.error(res.message ?? "Error getting documents");
      });
  };

  const fields = [
    {
      label: "Name of Applicant",
      value:
        (plan_data?.created_by?.first_name ?? "") +
        " " +
        (plan_data?.created_by?.last_name ?? ""),
    },
    {
      label: "Title of Review",
      value: plan_data?.title ?? "",
    },
    {
      label: "Type",
      value: arch_review_plan_type.find(
        (val) => val.value === (plan_data?.plan_type ?? "")
      )?.label,
    },
    {
      label: "Address",
      value: "",
    },
    {
      label: "Description",
      value: plan_data?.description ?? "",
      type: "markdown",
    },
    {
      label: "Name of Contractor",
      value: plan_data?.contractor_name ?? "",
    },
    {
      label: "Contractor Phone",
      value:
        formatPhoneNumber("" + plan_data?.contractor_phone) === ""
          ? plan_data?.contractor_phone
          : formatPhoneNumber("" + plan_data?.contractor_phone),
    },
    ,
    {
      label: "Contractor email",
      value: "",
    },
    {
      label: "Planned Start Date",
      value: plan_data?.planned_start_date,
      type: "date",
    },
    {
      label: "Planned End Date",
      value: plan_data?.planned_end_date,
      type: "date",
    },
  ];

  return (
    <div className="mt-12 pl-10 flex flex-col gap-5">
      {fields.map((field, field_index) => (
        <div
          key={field_index}
          className="grid grid-cols-10 gap-4 items-center max-w-2xl"
        >
          <span className="col-span-3">{field.label}</span>
          <span className="text-center">:</span>
          <div className="col-span-6 flex flex-col justify-end items-end gap-px">
            {field.type === "markdown" ? (
              <MarkdownPreview
                className="break-words w-full text-gray-800 rounded bg-gray-200 border-none outline-none px-3 py-6"
                source={field.value}
              />
            ) : (
              <input
                readOnly
                type={field.type}
                value={field.value}
                className="w-full text-gray-800 rounded bg-gray-200 border-none outline-none px-4 py-2 focus:outline-none focus:bg-gray-300"
              />
            )}
          </div>
        </div>
      ))}

      <span className="mt-6 font-semibold text-lg" style={{ color: "#2A32FD" }}>
        Documents
      </span>
      <hr />

      <div className="mt-1 ml-6 max-w-3xl w-full flex flex-col gap-3">
        {loading && (
          <div className="flex flex-row justify-center items-center gap-2">
            <LoadingCircleIcon className="h-4" />
          </div>
        )}
        {documents?.map((file, index) => (
          <div
            key={index}
            className="rounded flex flex-row gap-6 items-center bg-gray-200 text-sm opacity-80 px-6 py-2 "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Link
              target="_blank"
              href={"/hoa/" + hoaId + "/view_document/" + file.id}
            >
              <span className="font-bold text-gray-800 truncate hover:underline underline-blue-400 hover:text-blue-600">
                {file.name.length > 60
                  ? file.name.substring(0, 60) + "..."
                  : file.name}
              </span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
