
export const date_format = (date) => {
    var today = new Date(date);
  
    if (!date) return undefined;
  
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
  
    return mm + "/" + dd + "/" + yyyy;
  };