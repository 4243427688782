import { navigate, useQueryParams } from "raviger";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import { getHoaUnits, getHoaAddresses, updateUnit } from "../../../api/ApiV2";
import { LoadingCircleIcon, PlusIcon } from "../../common/AppIcons";
import AddUnitPopup from "./AddUnitPopup";
import Input from "../../common/Form/Input";
import { addressFormatLine2 } from "../../../utils/addressFormat";
import { userAtom } from "../../../store/UserAtom";
import { adminTypes } from "../../../common/data/adminTypes";

export default function UnitList({ hoaId }) {
  const [queryParams, setQuery] = useQueryParams();
  const [units, set_units] = useState();
  const [unit_focus, set_unit_focus] = useState();
  const [add_unit_popup, set_add_unit_popup] = useState(false);
  const [addresses, set_addresses] = useState();

  const [member_focus, set_member_focus] = useState();
  const [loading, set_loading] = useState(true);
  const [warning, set_warning] = useState({});
  const [user] = useRecoilState(userAtom);

  const defaultFilters = queryParams;

  const adminPermissions = adminTypes.includes(user.selectedUserType);

  useEffect(() => {
    if (add_unit_popup === false) {
      getAddresses();
      get_units();
    }
  }, [add_unit_popup]);

  const getAddresses = () => {
    getHoaAddresses(hoaId)
      .then((res) => {
        set_addresses(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSave = () => {
    set_loading(true);
    updateUnit(hoaId, unit_focus, unit_focus?.id)
      .then((res) => {
        set_loading(false);
        get_units();
      })
      .catch((res) => {
        if (typeof res.message === "string") {
          let temp_error = {};
          res?.message?.split("],").map((val) => {
            if (val.split("'")[1] && val.split("'")[3])
              temp_error = {
                ...temp_error,
                [val.split("'")[1]]: ["" + val.split("'")[3]],
              };
          });
          set_warning(temp_error);
        } else if (res) set_warning(res);
        else set_warning({ overall: ["User uploading failed."] });
        console.log(res);
        set_loading(false);
      });
  };

  const get_units = (searchQuery) => {
    set_loading(true);
    getHoaUnits(hoaId, 0, searchQuery, { ...defaultFilters })
      .then((res) => {
        set_units(res.results);
        console.log(defaultFilters, res.results);
        set_unit_focus((unit_focus) =>
          unit_focus?.id
            ? res.results?.find((val) => val.id === unit_focus?.id)
            : defaultFilters?.unit
            ? res.results?.find((val) => val.id === defaultFilters?.unit)
            : res.results?.[0]
        );
        set_loading(false);
      })
      .catch((err) => {
        toast.error("Error fetching ");
        set_loading(false);
        console.log(err);
      });
  };

  const search_units = (keyword) => {
    get_units(keyword);
  };

  return (
    <div className="pb-8 max-w-3xl mx-auto lg:max-w-7xl rounded-xl shadow-lg bg-white">
      <div className="mx-auto relative z-0 flex overflow-hidden rounded-md">
        <main
          className={
            "flex-1 relative z-0 overflow-y-auto focus:outline-none md:order-last " +
            (unit_focus ? "" : "hidden xl:flex-1")
          }
          // tabindex="0"
          // x-data=""
          // x-init="$el.focus()"
        >
          {/* <!-- Breadcrumb --> */}
          <nav
            className="flex items-start px-4 py-3 sm:px-6 lg:px-8 md:hidden"
            aria-label="Breadcrumb"
          >
            <div className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
              <div
                onClick={() => set_member_focus()}
                className="text-gray-400 hover:text-gray-600 cursor-pointer"
              >
                <svg
                  className="-ml-2 h-5 w-5"
                  //   x-description="Heroicon name: chevron-left"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <span>Units</span>
            </div>
          </nav>

          {unit_focus ? (
            <article>
              {/* <!-- Profile header --> */}

              {/* <!-- Tabs --> */}
              <div className="mt-6 sm:mt-2 2xl:mt-5">
                <div className="border-b border-gray-200">
                  <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      <span className="border-pink-500 text-gray-900 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                        Unit Details
                      </span>
                    </nav>
                  </div>
                </div>
              </div>

              {/* <!-- Description list --> */}
              <div className="mt-3 max-w-5xl mx-auto px-4 py-2 sm:px-6 lg:px-8">
                <span className="text-red-400 mb-8 text-xs">
                  {warning["overall"] && warning["overall"][0]}
                </span>

                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 pb-8">
                  <div key="unit_number" className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500 w-full flex flex-row justify-between">
                      Unit Number
                      <span className="text-red-400 relative top-2 text-xs"></span>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <input
                        className="border-none px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-indigo-100 focus:indigo-200 w-full rounded"
                        value={unit_focus.unit_number ?? ""}
                        readOnly={!adminPermissions}
                        onChange={(e) => {
                          set_unit_focus({
                            ...unit_focus,
                            unit_number: e.target.value,
                          });
                        }}
                      />
                    </dd>
                  </div>
                  {[
                    { value: "market_rent", type: "currency" },
                    { value: "unit_size", type: "text" },
                    { value: "beds", type: "number" },
                    { value: "baths", type: "number" },
                    { value: "monthly_dues", type: "currency" },
                  ].map((field, fieldIndex) => (
                    <div key="market_rent" className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500 w-full flex flex-row justify-between capitalize">
                        {field.value.replace("_", " ")}
                        <span className="text-red-400 relative top-2 text-xs"></span>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <Input
                          key={fieldIndex}
                          readOnly={!adminPermissions}
                          value={unit_focus?.[field.value] ?? ""}
                          type={field.type}
                          onChange={(value) => {
                            typeof Number(value) === "number" &&
                              isFinite(Number(value)) &&
                              set_unit_focus({
                                ...unit_focus,
                                [field.value]: value,
                              });
                          }}
                          className="border-none px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-indigo-100 focus:indigo-200 w-full rounded"
                        />
                      </dd>
                    </div>
                  ))}
                </dl>
                <div className="w-full flex justify-between mb-4">
                  <button
                    onClick={() => {
                      navigate(`/hoa/${hoaId}/members/?unit=${unit_focus.id}`);
                    }}
                    className="text-white px-2 py-1 font-semibold bg-yellow-500 hover:bg-yellow-600 rounded shadow"
                  >
                    View Members
                  </button>
                  {adminPermissions && (
                    <button
                      onClick={onSave}
                      className="text-white px-2 py-1 font-semibold bg-blue-500 hover:bg-blue-600 rounded shadow"
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </article>
          ) : (
            <div className="h-full w-full" />
          )}
        </main>
        <aside
          className={
            "md:order-first flex-shrink-0 w-96 md::border-r md::border-gray-200 " +
            (member_focus ? "hidden md:flex md:flex-col" : "flex flex-col")
          }
        >
          <div className="px-6 pt-6 pb-4">
            <div className="flex flex-row gap-2 justify-between w-full">
              <div className="flex flex-col">
                <h2 className="text-lg font-medium text-gray-900">
                  {addressFormatLine2({
                    address_object: unit_focus?.address_object,
                  })}
                </h2>
                <p className="mt-1 text-xs text-gray-600">
                  Search directory of {units?.length} units from this building
                </p>
              </div>
              <div>
                {adminPermissions && (
                  <button
                    className="p-1 rounded bg-blue-600 hover:bg-blue-700"
                    style={{ boxShadow: "1px 1px 3px" }}
                    onClick={() => set_add_unit_popup(true)}
                  >
                    <PlusIcon className="h-4 w-4 text-white" />
                  </button>
                )}
              </div>
            </div>
            <form className="mt-6 flex space-x-4" action="#">
              <div className="flex-1 min-w-0">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    {/* <!-- Heroicon name: mail --> */}
                    <svg
                      className="h-5 w-5 text-gray-400"
                      // x-description="Heroicon name: search"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    onChange={(e) => {
                      search_units(e.target.value);
                    }}
                    className="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 px-3 py-2 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Search"
                  />
                </div>
              </div>
            </form>
          </div>
          {/* <!-- Members list --> */}
          <div className="px-6 pt-6 pb-4">
            <nav
              className="flex-1 min-h-0 md:max-h-screen relative overflow-y-auto"
              aria-label="Units"
            >
              <div
                className={
                  "fixed bg-gray-100 bg-opacity-40 flex items-center justify-center z-50 inset-0 " +
                  (loading ? "" : "hidden")
                }
              >
                <LoadingCircleIcon className="h-10 w-10 text-blue-500" />
              </div>
              <div></div>
              <ul className="relative z-0 divide-y divide-gray-200">
                {units?.map((unit, unit_index) => (
                  <li
                    key={unit_index}
                    onClick={() => {
                      set_unit_focus(unit);
                    }}
                  >
                    <div
                      className={
                        "relative px-6 py-5 flex items-center space-x-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 " +
                        (unit_focus?.id && unit_focus.id === unit.id
                          ? "bg-gray-300"
                          : "hover:bg-gray-200 bg-gray-50  border-1")
                      }
                    >
                      <div className="flex-1 min-w-0">
                        <div className="focus:outline-none">
                          {/* <!-- Extend touch target to entire panel --> */}
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          ></span>
                          <p className="text-sm font-medium text-gray-900">
                            {unit.unit_number}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </aside>
      </div>
      <AddUnitPopup
        hoaId={hoaId}
        show={add_unit_popup}
        addresses={addresses || {}}
        setShow={() => {
          set_add_unit_popup(false);
        }}
      />
    </div>
  );
}
