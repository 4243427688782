import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { navigate } from "raviger";
import { useRecoilState } from "recoil";

import { handymanSubCategory, vendors } from "../../../../common/constants";
import { getMarketplaceVendors } from "../../../../api/Api";
import VendorSelection from "./components/VendorSelection";
import MarketplaceVendorSelection from "./components/MarketPlaceVendorSelection";
import JobSelection from "./components/JobSelection";
import CatogarySelection from "./components/CatogarySelection";
import RecurringForm from "./components/RecurringForm";
import DescriptionForm from "./components/DescriptionForm";
import ExternalVendorDetails from "./components/ExternalVendorDetails";
import SRBase from "../../srBase";
import { getBuildings, postTicket } from "../../../../api/ApiV2";
import { userAtom } from "../../../../store/UserAtom";
import AddressForm from "./components/AddressForm";
import { adminTypes } from "../../../../common/data/adminTypes";

export default function CreateSR({ hoaId, url }) {
  const [user] = useRecoilState(userAtom);
  const admin = adminTypes.includes(user.selectedUserType);

  const [data, setData] = useState({ repeated: false });
  const [marketplaceVendors, setMarketplaceVendors] = useState([]);
  const [form, set_form] = useState("VENDOR_SELECTION");
  //VENDOR_SELECTION,EXTERNAL_VENDOR_DETAILS,MARKETPLACE_VENDOR_SELECTION,JOB_SELECTION,CATOGARY_SELECTION,
  //ADDRESS_UNIT_SELECTION, RECURRING_FORM,TITLE_FORM,DESCRIPTION_FORM
  const [loading, set_loading] = useState(false);
  const [address, setAddress] = useState([]);

  useEffect(
    (_) => {
      set_loading(true);
      getMarketplaceVendors()
        .then((res) => {
          setMarketplaceVendors(res.results);
          set_loading(false);
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
        });
    },
    [user.selectedUserProfile]
  );

  useEffect(() => {
    getBuildings({ hoaId })
      .then((res) => {
        console.log(res);
        setAddress(res);
      })
      .catch((res) => {
        console.log(res);
      });
  }, [user.selectedUserProfile]);

  const submit_data = () => {
    set_loading(true);
    let temp_data = {};
    Object.keys(data).map((val) => {
      if (val !== "recurringIntervalSelected")
        temp_data = { ...temp_data, [val]: data[val] };
    });
    if (temp_data.repeat_on_every)
      temp_data = {
        ...temp_data,
        repeat_on_every: new Date(temp_data.repeat_on_every).getDate(),
      };
    postTicket({
      hoaId: hoaId,
      data: {
        ...temp_data,
        unit_address_id: admin ? data.unit_address_id : undefined,
      },
    })
      .then((res) => {
        console.log(res);

        setData({ repeated: false });
        sessionStorage.setItem(
          "message_from_sr_create",
          "Sent Service Request Successfully"
        );
        set_loading(false);
        navigate("/hoa/" + hoaId + "/sr/ORDER_HISTORY");
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res.readableMessage ?? res.message ?? "Error submitting form"
        );
      });
  };
  return (
    <SRBase hoaId={hoaId} page={url}>
      <div className="rounded-lg bg-white p-4 sm:px-8 sm:py-10">
        {loading && (
          <div className="fixed flex items-center justify-center z-30 inset-0 bg-opacity-80 bg-white">
            <div
              style={{ borderTopColor: "transparent" }}
              className="border-solid animate-spin rounded-full border-blue-400 border-4 h-12 w-12"
            ></div>
          </div>
        )}

        <div className="w-full flex flex-row gap-5 items-center justify-center">
          {[
            form === "VENDOR_SELECTION",
            form === "EXTERNAL_VENDOR_DETAILS" ||
              form === "MARKETPLACE_VENDOR_SELECTION" ||
              form === "JOB_SELECTION" ||
              form === "CATOGARY_SELECTION",
            form === "ADDRESS_UNIT_SELECTION",
            form === "RECURRING_FORM",
            form === "DESCRIPTION_FORM",
          ].map((val, index, arr) => (
            <div
              key={index}
              style={
                arr.slice(index, arr.length).find((val1) => val1 === true)
                  ? { backgroundColor: "#2A32FD", height: "6px" }
                  : { height: "6px" }
              }
              className="rounded w-12 md:w-24 bg-gray-400"
            />
          ))}
        </div>
        {form === "VENDOR_SELECTION" ? (
          <VendorSelection
            vendors={vendors}
            set_form={set_form}
            data={data}
            setData={setData}
          />
        ) : form === "EXTERNAL_VENDOR_DETAILS" ? (
          <ExternalVendorDetails
            set_form={set_form}
            data={data}
            setData={setData}
          />
        ) : form === "MARKETPLACE_VENDOR_SELECTION" ? (
          <MarketplaceVendorSelection
            marketplaceVendors={marketplaceVendors}
            data={data}
            setData={setData}
            set_form={set_form}
          />
        ) : form === "JOB_SELECTION" ? (
          <JobSelection data={data} setData={setData} set_form={set_form} />
        ) : form === "CATOGARY_SELECTION" ? (
          <CatogarySelection
            handymanSubCategory={handymanSubCategory}
            data={data}
            setData={setData}
            set_form={set_form}
          />
        ) : form === "ADDRESS_UNIT_SELECTION" ? (
          <AddressForm
            data={data}
            address={address}
            setData={setData}
            set_form={set_form}
          />
        ) : form === "RECURRING_FORM" ? (
          <RecurringForm data={data} setData={setData} set_form={set_form} />
        ) : form === "DESCRIPTION_FORM" ? (
          <DescriptionForm
            submit_data={submit_data}
            data={data}
            setData={setData}
            set_form={set_form}
          />
        ) : null}
      </div>
    </SRBase>
  );
}
