import React, { useRef } from "react";
import Features from "./components/Features";
import OtherFeatures from "./components/OtherFeatures";
import Integrations from "./components/Integrations";
import Welcome from "./components/Welcome";
import Footer from "./components/Footer";
import Base from "./base";
import Header from "./components/Header";
import GetStarted from "./components/GetStarted";
import Pricing from "./components/Pricing";
import Testimonials from "./components/Testimonials";
import FAQ from "./components/FAQ";

export default function LandingPage({}) {
  const featuresComponent = useRef(null);
  const integrationsComponent = useRef(null);
  const pricingComponent = useRef(null);
  return (
    <Base
      featuresComponent={featuresComponent}
      integrationsComponent={integrationsComponent}
      pricingComponent={pricingComponent}
    >
      <Header
        pricingComponent={pricingComponent}
        featuresComponent={featuresComponent}
        integrationsComponent={integrationsComponent}
      />
      <Welcome className="pt-24 px-6 max-w-6xl mx-auto" />
      <Features
        featuresComponent={featuresComponent}
        className="mt-24 px-6 max-w-6xl mx-auto"
      />
      <OtherFeatures className="mt-40 px-6 max-w-6xl mx-auto" />
      <Integrations
        integrationsComponent={integrationsComponent}
        className="mt-32 max-w-5xl mx-auto"
      />
      {/* <Pricing
        pricingComponent={pricingComponent}
        className="px-6 mt-32 max-w-5xl mx-auto"
      /> */}
      <Testimonials className="mt-28" />
      <FAQ />
      <GetStarted className="max-w-4xl mx-auto" />
      <Footer className="mt-16 sm:mt-32" />
    </Base>
  );
}
