import { useEffect, useState } from "react";
import {
  CalenderIcon2,
  ClockIcon,
  DeleteIcon4,
  LoadingCircleIcon,
} from "../../../../../../common/AppIcons";
import { defaultPic } from "../../../../../data/defaultPic";
import { dateToTime } from "../../../utility/availableHours";
import { getAmenityPhoto } from "../../../../../../../api/ApiV2";
import { toast } from "react-toastify";

export default function Reservation({
  hoaId,
  reservation_detail,
  set_delete_reservation_popup,
}) {
  return (
    <div className="w-full flex flex-col gap-1">
      {reservation_detail
        // ?.sort(
        //   (val1, val2) =>
        //     new Date(val1?.from_datetime) - new Date(val2?.from_datetime)
        // )
        ?.map((reservation, reservation_index) => (
          <ReservationRow
            hoaId={hoaId}
            reservation={reservation}
            key={reservation_index}
            set_delete_reservation_popup={set_delete_reservation_popup}
          />
        ))}
    </div>
  );
}

export function ReservationRow({
  hoaId,
  reservation,
  set_delete_reservation_popup,
}) {
  const [photo, setPhoto] = useState();
  const [photoLoading, setPhotoLoading] = useState(false);

  useEffect(() => {
    if (reservation?.amenity?.id) fetchPicture();
  }, [reservation?.amenity?.id]);

  const fetchPicture = (id) => {
    setPhotoLoading(true);
    getAmenityPhoto({ hoaId, id: reservation?.amenity?.id })
      .then((res) => {
        setPhotoLoading(false);
        console.log(res);
        setPhoto(Object.values(res.photos)?.[0]?.url);
      })
      .catch((res) => {
        setPhotoLoading(false);
        console.log(res);
        toast.error("Amenity picture fetching failed");
      });
  };
  return (
    <div className="rounded border-2 bg-white w-full gap-4 px-4 py-3">
      <div className="lg:h-24 flex flex-col lg:flex-row gap-3">
        <div className="relative">
          <img
            src={photo ?? defaultPic}
            alt=""
            className="relative z-10 h-full max-h-28"
            style={{ maxWidth: "200px" }}
          />
          {photoLoading && (
            <div className="z-20 absolute inset-0 flex items-center justify-center bg-opacity-10 text-blue-600">
              <LoadingCircleIcon className={"h-5"} />
            </div>
          )}
        </div>
        <div className="flex flex-grow flex-col h-full gap-5">
          <span className="font-semibold text-lg">
            {reservation.amenity?.name}
          </span>
          <div className="flex flex-row items-center gap-3">
            <div
              className={"flex flex-row items-center gap-1 text-sm"}
              style={{ color: "#71717A" }}
            >
              <CalenderIcon2 style={{ height: "16px" }} />
              {new Date(reservation.from_datetime).toLocaleDateString("en-GB")}
            </div>
            <div
              className="flex flex-row items-center gap-1 text-sm"
              style={{ color: "#71717A" }}
            >
              <ClockIcon style={{ height: "16px" }} />{" "}
              {dateToTime(reservation?.from_datetime) +
                " - " +
                dateToTime(reservation?.to_datetime)}
            </div>
          </div>
        </div>
        <div className="h-full flex flex-row flex-wrap lg:flex-col justify-end lg:justify-start items-end gap-2">
          {set_delete_reservation_popup && (
            <button
              onClick={() => {
                set_delete_reservation_popup(reservation);
              }}
              className="flex flex-row items-center gap-1 text-xs text-white bg-red-500 hover:bg-red-400 rounded px-3 py-2 cursor-pointer"
            >
              <DeleteIcon4 style={{ height: "16px", width: "16px" }} />
              Cancel Reservation
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
