import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  LoadingCircleIcon2,
  TickInBoxIcon2,
} from "../../../../common/AppIcons";
import { listAmenities, postAmenitiesBulk } from "../../../../../api/ApiV2";

const amenitiesList = [
  {
    label: "Jogging/Walking Trails",
    value: "Jogging/Walking Trails",
  },
  {
    label: "Basketball court",
    value: "Basketball court",
  },
  {
    label: "Recreation Room",
    value: "Recreation Room",
  },
  {
    label: "Fitness Center",
    value: "Fitness Center",
  },
  {
    label: "Golf course",
    value: "Golf course",
  },
  {
    label: "BBQ/Picnic Area",
    value: "BBQ/Picnic Area",
  },
  {
    label: "Clubhouse",
    value: "Clubhouse",
  },
  {
    label: "Picnic Area",
    value: "Picnic Area",
  },
  {
    label: "Gated Entry",
    value: "Gated Entry",
  },
  {
    label: "Tennis courts",
    value: "Tennis courts",
  },
  {
    label: "Dog Park",
    value: "Dog Park",
  },
  {
    label: "Community Lake/Pond",
    value: "Community Lake/Pond",
  },
  {
    label: "Playground",
    value: "Playground",
  },
  {
    label: "Community Garden",
    value: "Community Garden",
  },
  {
    label: "Volleyball court",
    value: "Volleyball court",
  },
  {
    label: "Yoga Studio",
    value: "Yoga Studio",
  },
  {
    label: "Business Center",
    value: "Business Center",
  },
  {
    label: "Park/Green Space",
    value: "Park/Green Space",
  },
  {
    label: "Security Services",
    value: "Security Services",
  },
];
export default function Amenity({
  hoaId,
  previousPage,
  nextPage,
  hoaData,
  setHoaData,
}) {
  const [loading, setLoading] = useState(false);
  const [amenities, setAmenities] = useState([]);

  useEffect(() => {
    fetchAmenities();
  }, []);

  const fetchAmenities = () => {
    setLoading(true);
    listAmenities({ hoaId, limit: 200 })
      .then((res) => {
        console.log(res);
        setAmenities(res.results);
        setLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });
  };

  const submitAmenity = () => {
    setLoading(true);
    postAmenitiesBulk({
      hoaId,
      data: hoaData?.amenities?.map((val) => ({ name: val })),
    })
      .then((res) => {
        setLoading(false);
        console.log(res);
        nextPage();
        toast.success("Amenities saved successfully");
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
        toast.error("Error saving Amenities");
      });
  };

  if (loading)
    return (
      <div className="w-full flex items-center justify-center py-20">
        <LoadingCircleIcon2 className={"w-10 h-10"} />
      </div>
    );
  else
    return (
      <div className="max-w-4xl w-full flex-grow sm:mx-16 bg-white rounded-md shadow-md mt-12 pt-10 pb-24">
        <div className="mx-auto max-w-2xl h-full w-full m-auto px-4 flex flex-col items-center text-gray-800">
          <span
            className="mt-4 w-full text-center font-semibold text-xl"
            style={{ color: "#2A32FD" }}
          >
            Select Amenities for this property
          </span>
          <div className="mt-12 w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-5">
            {amenitiesList.map((amenity, amenityIndex) =>
              amenities?.find((val) => val.name === amenity.value) ? (
                <div
                  key={amenityIndex}
                  className="flex flex-row items-center gap-2"
                >
                  <TickInBoxIcon2 className="h-5 text-green-500" />
                  {amenity.label}
                </div>
              ) : (
                <div
                  key={amenityIndex}
                  className="flex flex-row items-center gap-2"
                >
                  <input
                    type="checkbox"
                    onClick={() => {
                      setHoaData((hoaData) => ({
                        ...hoaData,
                        amenities: hoaData.amenities.includes(amenity.value)
                          ? hoaData.amenities.filter(
                              (val) => val !== amenity.value
                            )
                          : [...hoaData.amenities, amenity.value],
                      }));
                    }}
                    checked={hoaData.amenities.includes(amenity.value)}
                  />
                  {amenity.label}
                </div>
              )
            )}
          </div>

          <div className="w-full mt-10 flex flex-row justify-center items-center gap-3">
            <button
              onClick={previousPage}
              className="text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 h-10 w-28 rounded"
            >
              Go Back
            </button>

            <button
              style={{
                backgroundColor: "#2A32FD",
                border: "2px solid #2A32FD",
              }}
              className="text-sm text-white hover:opacity-80 h-10 w-28 flex items-center justify-center rounded"
              onClick={submitAmenity}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
}
