import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

// import { getReservationHistory } from "../../../../../../../api/Api";

import {
  CalenderIcon,
  ClockIcon,
  LeftArrowIcon,
  LoadingCircleIcon,
} from "../../../../../../common/AppIcons";
import { dateToTime } from "../../../utility/availableHours";
import {
  getAmenityPhoto,
  getAmenityReservation,
} from "../../../../../../../api/ApiV2";
import Paginator from "../../../../../../../common/Paginator";
import AmenityAvailabilityBadge from "../../../../../utility/AmenityAvailabilityBadge";
import { defaultPic } from "../../../../../data/defaultPic";

export default function ReservationHistory({ hoaId, amenity, closePage }) {
  const [reservation_history, set_reservation_history] = useState();
  const [page, setPage] = useState({
    offset: 0,
    totalCount: 0,
  });
  const [loading, set_loading] = useState(false);
  const [image, setImage] = useState();

  useEffect(() => {
    getAmenityPhoto({ hoaId, id: amenity?.id })
      .then((res) => {
        console.log(res);
        setImage(Object.values(res.photos)?.[0]?.url);
      })
      .catch((res) => {
        console.log(res);
        toast.error("Amenity picture fetching failed");
      });
  }, [amenity?.id]);

  useEffect(() => {
    getReservation();
  }, [page.offset]);

  const getReservation = () => {
    set_loading(true);
    getAmenityReservation({ hoaId, amenityId: amenity.id, offset: page.offset })
      .then((res) => {
        setPage((page) => ({ ...page, totalCount: res.count }));
        set_reservation_history(
          res.results?.sort(
            (val1, val2) =>
              new Date(val1.from_datetime) - new Date(val2.from_datetime)
          )
        );
        set_loading(false);
        console.log(res);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res.readableMessage ?? res.message ?? "Error fetching reservations"
        );
      });
  };

  return (
    <div className="mx-auto max-w-4xl flex flex-col items-start p-6">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-opacity-40 bg-gray-400">
          <LoadingCircleIcon className="h-9 mx-auto my-20" />
        </div>
      )}
      <span
        className="font-semibold text-lg flex flex-row items-center gap-1"
        style={{ color: "#18181B" }}
      >
        <div
          className="cursor-pointer text-gray-800 hover:text-gray-600"
          onClick={() => closePage()}
        >
          <LeftArrowIcon className="h-5" />
        </div>
        Reservation History
      </span>
      <div className="mt-5 px-4 flex flex-row items-start gap-4 h-24">
        <img src={image ?? defaultPic} alt="" className="h-full" />
        <div className="flex flex-col h-full justify-center items-start gap-3 pb-3">
          <span className="font-semibold text-lg">{amenity.name}</span>
          <AmenityAvailabilityBadge availability={amenity.amenity_type} />
        </div>
      </div>
      <div className="mt-7 px-4 flex flex-col gap-2 w-full overflow-y-auto max-h-screen">
        {(!reservation_history || reservation_history.length === 0) &&
          !loading && (
            <span className="text-sm text-center font-semibold text-gray-500 py-8">
              No Reservation History Found
            </span>
          )}
        {reservation_history?.map((reservation, reservation_index) => (
          <div
            key={reservation_index}
            className="rounded border-2 bg-white w-full grid grid-cols-2 gap-4 px-3 py-2"
          >
            <div
              className="flex flex-row items-center gap-1 text-sm"
              style={{ color: "#71717A" }}
            >
              <ClockIcon style={{ height: "16px" }} />{" "}
              {dateToTime(reservation?.from_datetime) +
                " - " +
                dateToTime(reservation?.to_datetime)}
            </div>
            <div
              className="flex flex-row items-center justify-end gap-1 text-sm"
              style={{ color: "#71717A" }}
            >
              <CalenderIcon style={{ height: "16px" }} />
              {new Date(reservation.from_datetime).toLocaleDateString("en-GB")}
            </div>
          </div>
        ))}
        <Paginator
          currentPage={page.offset + 1}
          totalPage={page.totalCount}
          entryPerPage={20}
          onChange={(pageNo) => {
            setPage({ ...page, offset: pageNo - 1 });
          }}
        />
      </div>
    </div>
  );
}
