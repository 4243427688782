import { data } from "autoprefixer";
import React, { useState, useEffect } from "react";
import { LoadingCircleIcon } from "../../../../../../common/AppIcons";
import Input from "../../../../../../common/Form/Input";
import { accountingInputClassName } from "../../../../../common/data/inputClassName";

const year_start =
  new Date().getMonth() > 2
    ? new Date().getFullYear()
    : new Date().getFullYear() - 1;

export default function FilterForm({
  date,
  setDate,
  date_prompt,
  onSubmit,
  profitLoss,
  loading_filter,
}) {
  const [show_filter, set_show_filter] = useState(false);

  return !show_filter ? (
    <div className="w-full flex justify-end">
      <button
        onClick={() => set_show_filter(true)}
        className="border-2 border-gray-500 text-gray-600 font-semibold px-7 py-2 rounded focus:outline-none bg-white hover:bg-gray-100"
      >
        Filter by Date
      </button>
    </div>
  ) : (
    <div className="flex flex-col gap-px mt-8">
      <div className="flex flex-row flex-wrap items-center justify-around gap-x-4 gap-y-1 px-4">
        {[
          {
            label: "Start Date",
            value: "start_date",
          },
          {
            label: "End Date",
            value: "end_date",
          },
        ].map((value, index) => {
          return (
            <div key={index} className="flex flex-row items-center gap-3">
              <span className="font-semibold text-sm text-gray-600">
                {value.label} :
              </span>
              <div className="flex flex-col items-end relative bottom-2">
                <span
                  className={
                    "text-red-600 text-xs mr-4 " +
                    (date_prompt &&
                    (date[value.value] === "" || !date[value.value])
                      ? "visible"
                      : "invisible")
                  }
                >
                  fill here
                </span>
                <Input
                  type="date"
                  value={date[value.value]}
                  onChange={(val) => {
                    console.log(val);
                    setDate({ ...date, [value.value]: val });
                  }}
                  className={accountingInputClassName + " w-60"}
                />
              </div>
            </div>
          );
        })}
        <div className="flex flex-row items-center gap-4">
          <button
            onClick={() => onSubmit()}
            className="border-2 border-indigo-500 text-indigo-600 font-semibold h-10 w-28 flex items-center justify-center rounded focus:outline-none bg-white hover:bg-gray-100"
          >
            {loading_filter ? <LoadingCircleIcon className="h-4" /> : "Filter"}
          </button>
          <button
            onClick={() => {
              setDate({
                ...date,
                start_date: "",
                end_date: "",
              });
              profitLoss();
            }}
            className="border-2 border-gray-500 text-gray-600 font-semibold px-7 py-2 rounded focus:outline-none bg-white hover:bg-gray-100"
          >
            Clear
          </button>
        </div>
      </div>
      <div className="mt-3 px-4 sm:px-6 lg:px-8 flex flex-row items-center justify-between w-full">
        <div className="flex flex-row items-center gap-2">
          <input
            type="checkbox"
            className="border-2 border-gray-600 focus:ring-0"
            checked={
              date.start_date === year_start + "-04-01" &&
              date.end_date === new Date().toISOString().substring(0, 10)
            }
            onChange={() => {
              if (
                date.start_date === year_start + "-04-01" &&
                date.end_date === new Date().toISOString().substring(0, 10)
              ) {
                setDate({
                  ...date,
                  start_date: "",
                  end_date: "",
                });
                profitLoss();
              } else {
                setDate({
                  ...date,
                  start_date: year_start + "-04-01",
                  end_date: new Date().toISOString().substring(0, 10),
                });
                onSubmit({
                  start_date: year_start + "-04-01",
                  end_date: new Date().toISOString().substring(0, 10),
                });
              }
            }}
          />
          <span className="text-gray-600">Current Financial Year</span>
        </div>

        <button
          onClick={() => set_show_filter(false)}
          className="px-3 py-2 focus:outline-none font-semibold text-red-500 hover:text-red-600"
        >
          Close Filter
        </button>
      </div>
    </div>
  );
}
