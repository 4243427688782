import React, { useState, useEffect, useRef } from "react";
import { Transition } from "@tailwindui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Dropdown from "../../common/Form/Dropdown";
import {
  CrossCicleIcon,
  Alert,
  LoadingCircleIcon,
} from "../../common/AppIcons";
import { userType } from "../../../common/constants";
import {
  bulkPostUnits,
  getMelissaAddress,
  postBuilding,
} from "../../../api/ApiV2";
import { toast } from "react-toastify";
import { findState } from "../../../utils/findState";
import { findTerritory } from "../../../utils/findTerritory";
import { useDebounce } from "use-debounce";
import Input from "../../common/Form/Input";

export default function AddPropertyPopup({ show, setShow, hoaId }) {
  const [unit, setUnit] = useState({
    unit_number: "",
    address: "",
  });
  const [loading, setLoading] = useState(false);
  const [warning, set_warning] = useState({});

  const [address_hints, set_address_hints] = useState([]);
  const [last_address_keyword, set_last_address_keyword] = useState("");
  const [street_address_1_debounce] = useDebounce(unit.street_address_1, 700);
  const [address_hint_loading, set_address_hint_loading] = useState(false);
  const cityRef = useRef(null);

  useEffect(() => {
    if (unit.zipCode) {
      const newState = findState(unit.zipCode);
      const territory = findTerritory(unit.zipCode);
      console.log("Setting State to:", newState);
      console.log("Setting Territory to:", territory);
      setTimeout(() => {
        set_warning((warnings) => {
          return {
            ...warnings,
            street_address_1:
              !newState || !territory ? "Invalid area zipcode" : undefined,
          };
        });
      }, 500);
      setUnit({ ...unit, state: newState, territory: territory });
    }
  }, [unit.zipCode]);

  // address hints

  useEffect(() => {
    if (
      unit?.street_address_1?.split(" ")?.length > 0 &&
      unit?.street_address_1?.charAt(unit?.street_address_1.length - 1) === " "
    ) {
      get_address_hints(unit?.street_address_1);
    }
  }, [unit?.street_address_1]);

  useEffect(() => {
    if (
      unit?.street_address_1 &&
      street_address_1_debounce === unit?.street_address_1
    ) {
      get_address_hints(unit?.street_address_1);
    }
  }, [unit?.street_address_1, street_address_1_debounce]);

  const get_address_hints = (address) => {
    if (!address) return;

    if (address?.trim() !== "" && address?.trim() !== last_address_keyword) {
      set_last_address_keyword(address.trim());
      set_address_hint_loading(true);
      getMelissaAddress({ address })
        .then((res) => {
          console.log("address hints:", res);
          set_address_hints(
            res?.filter(
              (val) =>
                val?.Address?.SubBuilding && val?.Address?.SubBuilding !== ""
            )
          );
          set_address_hint_loading(false);
        })
        .catch((res) => {
          console.log("address hints error:" + res);
          set_address_hint_loading(false);
        });
    }
  };

  const addressDisplay = (address) => {
    let real_address = address.Address;

    if (!real_address) return null;
    return (
      (real_address.Address1 ? real_address.Address1 + ", " : "") +
      (real_address.Locality || real_address.SubAdministrativeArea
        ? (real_address.Locality || real_address.SubAdministrativeArea) + ",  "
        : "") +
      (real_address.AdministrativeArea ?? "") +
      (real_address.PostalCodePrimary
        ? " " + real_address.PostalCodePrimary
        : "")
    );
  };

  const remove_warning = (key) => {
    let tempWarning = warning;
    delete tempWarning[key];
    set_warning(tempWarning);
  };

  const findWarnings = () => {
    let warning = {};
    if (unit.street_address_1 === "")
      warning = { ...warning, street_address_1: "Please Fill this Field" };

    if (
      unit.zipCode &&
      (Number(unit.zipCode) < 90001 || Number(unit.zipCode) > 96162)
    )
      warning = { ...warning, street_address_1: "Invalid Zipcode" };

    set_warning(warning);
    return warning;
  };

  const submitAddress = () => {
    let tempWarnings = findWarnings();

    if (Object.keys(tempWarnings)?.length > 0) {
      toast.error("Please fill all the required fields");
      return;
    }

    setLoading(true);

    postBuilding({
      hoaId: hoaId,
      data: {
        street_address_1: unit.street_address_1,
        melissa_base_mak: unit.melissaBaseMak,
      },
    })
      .then((res) => {
        console.log(res);

        if (unit.units?.units?.length > 0)
          bulkPostUnits({
            hoaId: hoaId,
            data: {
              units: unit.units?.units?.map((val) => ({
                unit_number: val,
                address: res.id,
              })),
              // address: res.id
            },
          })
            .then((res) => {
              setLoading(false);
              console.log(res);
              setShow();
            })
            .catch((res) => {
              setLoading(false);
              console.log(res);
              toast.error("Error in creating units");
              setShow();
            });
        else {
          setLoading(false);
          setShow();
        }
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
        toast.error("Error in creating building");
      });
  };

  return (
    <div
      className={
        "fixed inset-0 overflow-hidden flex items-center justify-center bg-gray-200 bg-opacity-70 " +
        (show ? "" : "hidden opacity-0")
      }
      onClick={() => setShow()}
    >
      <div
        className="absolute bg-white rounded-md border shadow-lg sm:w-96"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-px w-full flex flex-row-reverse">
          <div
            className="relative left-3 bottom-3 cursor-pointer"
            onClick={() => setShow()}
          >
            <CrossCicleIcon className="h-8 w-8" />
          </div>
        </div>
        <Transition
          show={warning.overall ? true : false}
          className="mt-3"
          enter="transition-opacity transition-translate transform duration-75"
          enterFrom="opacity-0 -translate-y-full"
          enterTo="opacity-100 translate-y-0"
          leave="transition-opacity transition-translate transform duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-full"
        >
          <div className="bg-gray-600 rounded shadow text-white flex items-center justify-center flex-row gap-3 p-2 mx-3">
            <Alert /> {warning.overall}
          </div>
        </Transition>
        <div className="sm:overflow-auto w-full px-3">
          <h1 className="flex flex-row gap-3 justify-center text-center text-3xl font-bold mt-4 mb-8 sm:mb-10 opacity-90">
            Add Building
          </h1>
          <div className="m-auto px-4 flex flex-col justify-center items-center text-gray-800 max-w-xl mt-5">
            <div className="flex flex-col md:flex-row gap-1 w-full">
              <div
                key="street_address_1"
                className="flex flex-col gap-1 w-full"
              >
                <div className="flex flex-row gap-1 justify-between">
                  <span className="font-semibold whitespace-pre tracking-wide ml-1 text-xs">
                    Street Address 1
                  </span>
                  <span
                    style={{ fontSize: ".65rem" }}
                    className="text-xs text-red-600 text-right relative top-1"
                  >
                    {warning["street_address_1"]}
                  </span>
                </div>
                <Input
                  value={unit.street_address_1}
                  onChange={(val) => {
                    remove_warning("street_address_1");

                    setUnit((unit) => ({
                      ...unit,
                      street_address_1: val,
                    }));
                  }}
                  hint={{
                    loading: address_hint_loading,
                    hints: address_hints?.map((address) => {
                      return {
                        value: address.Address,
                        label: addressDisplay(address),
                      };
                    }),
                    onClick: (val) => {
                      remove_warning("street_address_1");
                      remove_warning("city");
                      remove_warning("state");
                      remove_warning("zipCode");

                      let real_address = val;
                      setUnit((unit) => ({
                        ...unit,
                        street_address_1: real_address?.Address1,
                        city:
                          real_address.Locality ||
                          real_address?.SubAdministrativeArea,
                        state: real_address?.AdministrativeArea,
                        zipCode: real_address?.PostalCodePrimary,
                        geocodeLatitude: real_address?.Latitude,
                        geocodeLongitude: real_address?.Longitude,
                        melissaBaseMak:
                          real_address?.BaseMAK ?? real_address?.MAK,
                        units: real_address?.BaseMAK
                          ? {
                              units: real_address?.SubBuilding.split(
                                ","
                              ).filter((val) => val !== ""),
                              maks: real_address?.MAK.split(",").filter(
                                (val) => val !== ""
                              ),
                            }
                          : null,
                      }));

                      set_address_hints();
                      set_last_address_keyword();

                      setTimeout(() => {
                        cityRef?.current?.focus();
                      }, 300);
                    },
                  }}
                  className={
                    "sm:w-full px-3 py-2 sm:tracking-wide text-gray-700 rounded focus:outline-none bg-gray-100 focus:bg-gray-200" +
                    (warning?.["street_address_1"] &&
                    warning?.["street_address_1"] !== ""
                      ? "border-red-300"
                      : "border-gray-300")
                  }
                />
              </div>
            </div>

            <div className={"flex flex-row gap-3 w-full justify-center "}>
              <div
                onClick={() => {
                  submitAddress();
                }}
                className={
                  "w-full rounded cursor-pointer text-white mx-auto text-lg sm:text-xl flex items-center justify-center font-bold tracking-wider py-2 my-8 bg-indigo-600 opacity-90 hover:bg-indigo-700"
                }
              >
                {loading ? (
                  <LoadingCircleIcon className="h-4 text-white" />
                ) : (
                  "Save"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
