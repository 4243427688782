import {
  ArchitectureIcon,
  ElectricianIcon,
  ExternalIcon,
  FilingIcon,
  FundsIcon,
  GeneralDocumentsIcon,
  HandymanIcon,
  InspectionIcon,
  InsuranceIcon,
  LegalIcon,
  MaintanenceIcon,
  MarketplaceIcon,
  MiscellaneousIcon,
  PlumbingIcon,
  ReportsIcon,
  WorkerIcon,
  LampIcon,
  SquareRulerIcon,
  DeleteIcon,
  BulbIcon,
  HillIcon,
  LockIcon,
  BathTubIcon,
  JackHammerIcon,
  WallIcon,
  SunCloudIcon,
  HexagonIcon,
} from "../components/common/AppIcons";

export const vendors = [
  { label: "Help Squad", value: "SQUADHUB", icon: HandymanIcon },
  { label: "Marketplace", value: "MARKETPLACE_VENDOR", icon: MarketplaceIcon },
  { label: "External", value: "EXTERNAL", icon: ExternalIcon },
];

export const jobTypes = [
  { label: "Handyman", value: "HANDYMAN", icon: HandymanIcon },
  {
    label: "Plumbing",
    value: "PLUMBER",
    sub_category: "PLUMBING",
    icon: PlumbingIcon,
  },
  {
    label: "Electrical",
    value: "ELECTRICIAN",
    sub_category: "ELECTRICIAN",
    icon: ElectricianIcon,
  },
  {
    label: "Inspection",
    value: "INSPECTION",
    sub_category: "INSPECTION",
    icon: InspectionIcon,
  },
];

const icon_tabs_className = "h-5 md:h-8 w-5 md:w-8";
const icon_tabs_className_bg = "h-10 md:h-16 w-10 md:w-16 opacity-5";

export const handymanSubCategory = [
  {
    icon: <WorkerIcon className={icon_tabs_className} />,
    bgIcon: <WorkerIcon className={icon_tabs_className_bg} />,
    data: "HANDYMAN",
    label: "Handyman",
  },
  {
    icon: <LampIcon className={icon_tabs_className} />,
    bgIcon: <LampIcon className={icon_tabs_className_bg} />,
    data: "APPLIANCES",
    label: "Appliance Installation",
  },
  {
    icon: <SquareRulerIcon className={icon_tabs_className} />,
    bgIcon: <SquareRulerIcon className={icon_tabs_className_bg} />,
    data: "CARPENTRY",
    label: "Carpentry",
  },
  {
    icon: <DeleteIcon className={icon_tabs_className} />,
    bgIcon: <DeleteIcon className={icon_tabs_className_bg} />,
    data: "CLEANING",
    label: "Cleaning Services",
  },
  {
    icon: <BulbIcon className={icon_tabs_className} />,
    bgIcon: <BulbIcon className={icon_tabs_className_bg} />,
    data: "ELECTRICIAN",
    label: "Electrician",
  },
  {
    icon: <HillIcon className={icon_tabs_className} />,
    bgIcon: <HillIcon className={icon_tabs_className_bg} />,
    data: "LANDSCAPE",
    label: "Landscaping",
  },
  {
    icon: <LockIcon className={icon_tabs_className} />,
    bgIcon: <LockIcon className={icon_tabs_className_bg} />,
    data: "LOCKSMITH",
    label: "Locksmith",
  },
  {
    icon: <BathTubIcon className={icon_tabs_className} />,
    bgIcon: <BathTubIcon className={icon_tabs_className_bg} />,
    data: "PLUMBING",
    label: "Plumbing",
  },
  {
    icon: <JackHammerIcon className={icon_tabs_className} />,
    bgIcon: <JackHammerIcon className={icon_tabs_className_bg} />,
    data: "REMODELLING",
    label: "Remodeling",
  },
  {
    icon: <WallIcon className={icon_tabs_className} />,
    bgIcon: <WallIcon className={icon_tabs_className_bg} />,
    data: "DRYWALL",
    label: "Drywall",
  },
  {
    icon: <SunCloudIcon className={icon_tabs_className} />,
    bgIcon: <SunCloudIcon className={icon_tabs_className_bg} />,
    data: "HVAC",
    label: "HVAC",
  },
  {
    icon: (
      <HexagonIcon className={icon_tabs_className + " transform -rotate-90"} />
    ),
    bgIcon: (
      <HexagonIcon
        className={icon_tabs_className_bg + " transform -rotate-90"}
      />
    ),
    data: "FLOORING",
    label: "Flooring",
  },
];

export const complianceDocumentsType = [
  {
    label: "Governing Documents",
    value: "GOVERNING_DOCUMENTS",
    icon: GeneralDocumentsIcon,
  },
  {
    label: "Miscellaneous",
    value: "MISCELLANEOUS_DOCUMENTS",
    icon: MiscellaneousIcon,
  },
  { label: "State Filings", value: "STATE_FILINGS", icon: FilingIcon },
  {
    label: "Architectural Review",
    value: "ARCHITECTURAL_REVIEW",
    icon: ArchitectureIcon,
  },
  { label: "Insurance", value: "INSURANCE", icon: InsuranceIcon },
  {
    label: "Records and Reports",
    value: "RECORDS_AND_REPORTS",
    icon: ReportsIcon,
  },
  {
    label: "Maintenance Planning",
    value: "MAINTENANCE_PLANNING",
    icon: MaintanenceIcon,
  },
  {
    label: "Filing Lien against Homeowner",
    value: "FILING_LIEN_AGAINST_HOMEOWNER",
    icon: LegalIcon,
  },
  { label: "Reserve Fund Study", value: "RESERVE_FUND_STUDY", icon: FundsIcon },
];

export const hoaUserRoles = [
  { num_value: 1, value: "SUPER_ADMIN" },
  { num_value: 2, value: "BOARD_MEMBER" },
  { num_value: 3, value: "OWNER" },
  { num_value: 5, value: "TENANT" },
];

export const genericCategories = [
  "payment",
  "paymnt",
  "pymnt",
  "transfer",
  "trnsfr",
  "debit",
  "credit",
];

const badge = ({ label, className }) => (
  <div
    className={
      "flex items-center justify-center h-7 w-7 text-xs shadow rounded-full font-semibold text-white bg-indigo-700 " +
      className
    }
  >
    {label}
  </div>
);

export const userType = {
  SUPER_ADMIN: {
    label: "Super Admin",
    badge: badge({ label: "SA", className: "" }),
  },
  BOARD_MEMBER: {
    label: "Board Member",
    badge: badge({ label: "BM", className: "" }),
  },
  OWNER: {
    label: "Owner",
    badge: badge({ label: "O", className: "" }),
  },
  TENANT: {
    label: "Tenant",
    badge: badge({ label: "T", className: "" }),
  },
};

export const account_type = [
  {
    label: "Asset",
    value: "ASSET",
  },
  {
    label: "Liability",
    value: "LIABILITY",
  },
  {
    label: "Equity",
    value: "EQUITY",
  },
  {
    label: "Income",
    value: "INCOME",
  },
  {
    label: "Expense",
    value: "EXPENSE",
  },
  {
    label: "Currency Trading",
    value: "CURRENCY_TRADING",
  },
];
