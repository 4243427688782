import { amountFormat } from "../../../../common/AmountFormat";
import {
  DollorInBoxIcon,
  LoadingCircleIcon,
} from "../../../../common/AppIcons";

export default function BalanceCard({ label, balance, className }) {
  if (!balance && balance !== 0)
    return (
      <div
        className={
          "w-20 sm:w-48 flex items-center justify-center py-6 " + className
        }
      >
        <LoadingCircleIcon className="h-4" />
      </div>
    );
  return (
    <div className={"flex flex-row bg-white " + className}>
      <div className="p-4 pr-2">
        <div className={"rounded p-2"} style={{ backgroundColor: "#e4dfe7" }}>
          <DollorInBoxIcon className="h-5" style={{ color: "#720ADA" }} />
        </div>
      </div>
      <div className="p-4 pl-2 flex flex-col gap-2">
        <span className="w-full font-semibold text-gray-600">{label}</span>
        <div className="flex flex-row items-end gap-2">
          <span className={"font-bold text-xl"} style={{ color: "#720ADA" }}>
            ${amountFormat(balance)}
          </span>
          <span className="text-gray-500 relative bottom-px">USD</span>
        </div>
      </div>
    </div>
  );
}
