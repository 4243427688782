import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { getAccounts, createJournalEntry } from "../../../../api/ApiV2";
import Modal from "../../../common/Modal";
import NewAccountPopup from "../../components/NewAccountPopup";
import { RightArrowIcon } from "../../../common/AppIcons";
import FormTable from "./components/formTable";
import LoadingScreen from "../../../common/LoadingScreen";
import TransactionList from "../transactionList";

export default function JournalEntry({
  source = null,
  hoaId,
  title = null,
  description = null,
  successCB = null,
  closePage = null,
  allowEmptySubmit = null,
  className = "",
}) {
  const initialEntry = {
    accountId: undefined,
    debit: 0,
    credit: 0,
    description:
      source === "OPENING_BALANCE" ? "Opening Balance Entry" : undefined,
  };

  const [baseData, setBaseData] = useState([]);
  const [newAccountPopup, setNewAccountPopup] = useState(false);
  const [transactionHistoryPage, setTransactionHistoryPage] = useState(false);
  const [journalEntry, setJournalEntry] = useState({
    description: description ?? "",
    items: [...Array(3)].map((_) => initialEntry),
  });
  const [loading, set_loading] = useState(false);

  useEffect(() => {
    if (newAccountPopup === false) {
      setTimeout(() => {
        getAccounts({ hoaId: hoaId, page_size: 300 })
          .then((res) => {
            setBaseData(res.results);
          })
          .catch(console.log);
      }, 100);
    }
  }, [newAccountPopup]);

  const clearJournalEntry = () => {
    setJournalEntry({
      description: "",
      items: [...Array(3)].map((_) => initialEntry),
    });
  };

  const submitData = () => {
    if (
      journalEntry.items?.filter(
        (leg) => leg.accountId && (leg.debit || leg.credit)
      )?.length === 0 &&
      !allowEmptySubmit
    ) {
      if (source === "OPENING_BALANCE") successCB();
      else toast.warning("Fill data before proceeding");
    } else if (
      journalEntry.items?.reduce(
        (sum, leg) => sum + Number(leg.debit ?? 0),
        0
      ) !==
      journalEntry.items?.reduce((sum, leg) => sum + Number(leg.credit ?? 0), 0)
    )
      toast.warning("Credits and Debits must be equal");
    else {
      set_loading(true);
      createJournalEntry(hoaId, {
        ...journalEntry,
        is_opening_balance: source === "OPENING_BALANCE" ? true : false,
        items: journalEntry.items.filter(
          (leg) => leg.accountId && (leg.debit || leg.credit)
        ),
      })
        .then((_) => {
          set_loading(false);
          clearJournalEntry();
          toast.success("Your Ledger Entry was Saved");

          successCB
            ? successCB()
            : setTimeout(() => {
                setTransactionHistoryPage(true);
              }, 1000);
        })
        .catch((err) => {
          set_loading(false);
          toast.error(err?.message ?? err?.[0] ?? "Error Saving Ledger Entry");
          console.log("Error", err);
        });
    }
  };

  if (transactionHistoryPage)
    return (
      <TransactionList
        hoaId={hoaId}
        type="JOURNAL"
        closePage={() => {
          setTransactionHistoryPage(false);
        }}
      />
    );
  else
    return (
      <div className={"mt-4 flex flex-col " + className}>
        <LoadingScreen show={loading} />
        <div className="-mx-6">
          <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="pr-3 w-full flex flex-row items-center">
              <div
                className={
                  "cursor-pointer mr-1 " + (closePage ? "" : "invisible")
                }
                onClick={() => {
                  closePage && closePage();
                }}
              >
                <RightArrowIcon className="h-5 w-5 text-gray-700 hover:text-gray-600 transform rotate-180" />
              </div>
              <h3 className="text-lg leading-6 font-semibold text-gray-900 flex-grow">
                {title ?? "General Ledger Entry"}
              </h3>
              {!(source === "OPENING_BALANCE") && (
                <button
                  onClick={() => {
                    setTransactionHistoryPage(true);
                  }}
                  className="transform translate-y-1 border-2 border-gray-400 text-gray-600 hover:text-gray-700 font-semibold bg-white hover:bg-gray-200 focus:outline-none px-4 py-2 rounded"
                >
                  General Ledger History
                </button>
              )}
            </div>
            <FormTable
              remove_description={source === "OPENING_BALANCE"}
              className="pl-8 pr-3 mt-6"
              readOnly={false}
              {...{
                journalEntry,
                setJournalEntry,
                initialEntry,
                setNewAccountPopup,
                baseData,
              }}
            />
            <div
              colspan="5"
              className="w-full flex gap-3 items-center justify-end mt-1 pr-3"
            >
              <button
                onClick={(_) => clearJournalEntry()}
                type="button"
                className="border-2 border-gray-400 text-gray-600 hover:text-gray-700 font-semibold bg-white hover:bg-gray-200 focus:outline-none px-7 py-1 rounded text-sm"
              >
                Clear
              </button>
              <button
                type="button"
                style={{
                  backgroundColor: "#2A32FD",
                  border: "solid 2px #2A32FD",
                }}
                className="px-8 py-1 rounded shadow-sm text-white hover:opacity-70 focus:outline-none text-sm"
                onClick={(_) => submitData()}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <Modal
          show={newAccountPopup}
          setShow={() => {
            setNewAccountPopup(false);
          }}
        >
          <NewAccountPopup
            hoaId={hoaId}
            closePopup={() => {
              setNewAccountPopup(false);
            }}
          />
        </Modal>
      </div>
    );
}
