import { navigate } from "raviger";
import React, { useState, useEffect } from "react";
import { filterProfitLoss } from "../../../../../../api/Api";
import { getProfitLoss } from "../../../../../../api/ApiV2";
import {
  LoadingAnimated,
  LoadingCircleIcon,
  RightArrowIcon,
} from "../../../../../common/AppIcons";
import FilterForm from "./components/FilterForm";
import List from "./components/List";

export default function ProfitLoss({ hoaId, closePage = null }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState({});
  const [date_prompt, set_date_prompt] = useState(false);
  const [loading_filter, set_loading_filter] = useState(false);

  useEffect(() => {
    profitLoss();
  }, []);

  useEffect(() => {
    if (date.start_date === "" && date.end_date === "") profitLoss();
  }, [date]);

  const profitLoss = () => {
    set_loading_filter(true);
    getProfitLoss({ hoaId })
      .then((res) => {
        setData(res);
        console.log("response : ", res);
        setLoading(false);
        set_loading_filter(false);
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
        set_loading_filter(false);
      });
  };

  const onSubmit = (request_data) => {
    if (
      request_data ||
      (date.start_date &&
        date.end_date &&
        date.start_date !== "" &&
        date.end_date !== "")
    ) {
      set_loading_filter(true);
      getProfitLoss({
        hoaId,
        start_date: request_data?.start_date ?? date.start_date,
        end_date: request_data?.end_date ?? date.end_date,
      })
        .then((res) => {
          set_loading_filter(false);
          setData(res);
        })
        .catch((res) => {
          console.log(res);
          set_loading_filter(false);
        });
    } else {
      set_date_prompt(true);
      setTimeout(() => {
        set_date_prompt(false);
      }, 5000);
    }
  };

  if (loading)
    return (
      <div className="h-full w-full flex items-center justify-center">
        <LoadingCircleIcon className="mt-5 text-blue-500 h-9" />
      </div>
    );
  return (
    <div className="">
      <div className="flex flex-col gap-px mt-8">
        <div className="flex flex-row items-center gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              closePage
                ? closePage()
                : navigate("/hoa/" + hoaId + "/accounts/reports");
            }}
          >
            <RightArrowIcon className="h-5 w-5 text-gray-700 hover:text-gray-500 transform rotate-180" />
          </div>
          <h2 className="text-xl leading-6 font-bold text-gray-900">
            Profit Loss Report
          </h2>
        </div>

        <FilterForm
          {...{
            date,
            setDate,
            date_prompt,
            onSubmit,
            profitLoss,
            loading_filter,
          }}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 items-baseline gap-3 min-w-max mx-auto px-4 sm:px-6 lg:px-8 pb-6 mt-4">
        <List
          heading="Income"
          data={data.incomes}
          total={data.income_sum}
          total_style={{ backgroundColor: "#09B59B" }}
        />

        <List
          heading="Expense"
          data={data.expenses}
          total={data.expense_sum}
          total_style={{ backgroundColor: "#FF8F6B" }}
        />
      </div>

      <div className="px-4 sm:px-6 lg:px-8 w-full">
        <div className="w-full bg-white rounded flex flex-row items-center justify-between gap-4 px-5 py-3">
          <span
            className={
              "text-lg font-bold " +
              (data.net_income > 0 ? "text-red-500" : "text-green-500")
            }
          >
            {data.net_income > 0 ? "Net Loss" : "Net Profit"}
          </span>
          <span className="text-lg font-bold">
            $
            {data.net_income > 0
              ? parseFloat(data.net_income)?.toFixed(2) ?? "0.00"
              : (parseFloat(data.net_income) * -1)?.toFixed(2) ?? "0.00"}
          </span>
        </div>
      </div>
    </div>
  );
}
