import React, { useState, useEffect } from "react";
import { conductMeeting } from "../../../../../api/Api";
import { LeftArrowIcon, LoadingAnimated } from "../../../../common/AppIcons";
import { ProfilePic } from "../constants";
import { editBoardMeetingAttendee } from "../../../../../api/ApiV2";

export default function MeetingAttendance({
  hoaId,
  meeting,
  members,
  attendees,
  setAttendees,
  getAttendees,
  nextPage,
}) {
  const [loading, set_loading] = useState(false);

  const submit_attendance = () => {
    set_loading(true);
    let apiLoading = [...Array(attendees.length)].fill(true);
    attendees.forEach((attendee) => {
      editBoardMeetingAttendee({
        hoaId,
        boardMeetingId: meeting.id,
        attendeeId: attendee.id,
        data: attendee,
      })
        .then((res) => {
          apiLoading[attendees.indexOf(attendee)] = false;
          if (!apiLoading.includes(true)) {
            set_loading(false);
            nextPage();
            getAttendees();
          }
        })
        .catch((res) => {
          apiLoading[attendees.indexOf(attendee)] = false;
          if (!apiLoading.includes(true)) {
            set_loading(false);
            nextPage();
          }
        });
    });
  };

  const Attendence = ({ value, label, onChange, key, member }) => {
    return (
      <div key={key} className="flex flex-row items-center gap-3">
        <input
          className="relative bottom-px right-px rounded border bg-gray-100"
          type="checkbox"
          checked={value}
          onChange={onChange}
        />
        <div className="bg-gray-100 rounded-md p-1 px-2 flex flex-row items-center gap-1">
          <ProfilePic
            member={member}
            className="shadow-none border border-gray-200"
          />
          <h1 className="font-normal text-base relative bottom-px text-gray-600">
            {label}
          </h1>
        </div>
      </div>
    );
  };
  return (
    <div className="min-w-full flex flex-col gap-4">
      <div className="px-10 py-5 flex flex-row gap-16 md:gap-32 items-start">
        {[
          {
            label: "Mandatory Attendees",
            value: "MANDATORY",
          },
          {
            label: "Optional Attendees",
            value: "OPTIONAL",
          },
        ].map((ele, ele_index) => (
          <div key={ele_index} className="flex flex-col gap-3">
            <span
              className="text-gray-800 font-semibold"
              style={{ fontSize: "17px" }}
            >
              {ele.label}
            </span>
            <div className="flex flex-col gap-4 px-2">
              {attendees
                ?.filter((val) => val.attendee_type === ele.value)
                ?.map((attendee, index) => {
                  return (
                    <Attendence
                      key={index}
                      value={attendee.attended}
                      member={members.find(
                        (member) => member.user?.id === attendee.user
                      )}
                      onChange={() => {
                        setAttendees((attendees) =>
                          attendees.map((attendee1) => {
                            if (attendee1.user === attendee.user) {
                              return {
                                ...attendee1,
                                attended: attendee1.attended ? false : true,
                              };
                            }
                            return attendee1;
                          })
                        );
                      }}
                      label={
                        (members.find(
                          (member) => member.user?.id === attendee.user
                        )?.user?.first_name ?? "") +
                        " " +
                        (members.find(
                          (member) => member.user?.id === attendee.user
                        )?.user?.last_name ?? "")
                      }
                    />
                  );
                })}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex justify-end px-5 sm:px-10 m-3">
        <button
          onClick={() => {
            submit_attendance();
          }}
          style={{ backgroundColor: "#2A32FD" }}
          className="hover:opacity-80 text-lg font-semibold text-white rounded shadow h-10 w-44 flex items-center justify-center"
        >
          {loading ? <LoadingAnimated className="h-4" /> : "Mark Attendance"}
        </button>
      </div>
    </div>
  );
}
