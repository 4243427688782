import { useEffect, useState, useRef } from "react";
import { Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { Link } from "raviger";
import { useRecoilState } from "recoil";

import { plaidData, plaidDataSR } from "../../../../api/Api";
import {
  Arrow,
  DocumentIcon,
  LoadingCircleIcon,
  LoadingIcon2,
} from "../../../common/AppIcons";
import ImgPopup from "../../../common/ImgPopup";
import ImgThumbnail from "../../../common/ImgThumbnail";
import { imageArray } from "./utility/imageArray";
import { downloadPDF } from "./utility/downloadPDF";
import SRBase from "../../srBase";
import SRSmallDetail from "./components/SRSmallDetail";
import SRAddress from "./components/SRAddress";
import SRAppointment from "./components/SRAppointment";
import PaymentPopup from "./components/PaymentPopup";
import { getAccounts, getSRDetail } from "../../../../api/ApiV2";
import { userAtom } from "../../../../store/UserAtom";
import SRPrefix from "../../../../utils/SRPrefix";
import { adminTypes } from "../../../../common/data/adminTypes";

export default function SRDetail({ sr_id, ticketId, url, hoaId }) {
  const [user] = useRecoilState(userAtom);

  const userPrivilage = adminTypes.includes(user.selectedUserType);

  const pdfRef = useRef(null);
  const [loading, set_loading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [sr_data, set_sr_data] = useState();
  const [open_pdf, set_open_pdf] = useState(null);
  const [view_files, set_view_files] = useState({
    show: false,
    index: 0,
  });
  const [payment_method_popup, set_payment_method_popup] = useState({
    view: false,
    popup: "PAY_OPTIONS",
    // PAY_OPTIONS, ACCOUNTS, ACH_PAY, CARD_PAY, PROCESSING, REQUIRES_ACTION, SUCCESS,  FAILED
  });
  const [plaid_data_hoa, set_plaid_data_hoa] = useState();
  const [plaid_data_helpsquad, set_plaid_data_helpsquad] = useState();
  const [accounts, set_accounts] = useState();

  const payButtonRef = useRef(null);

  const pic_array = () => {
    let arr = sr_data?.ticketMedia ?? [];
    sr_data?.appointments?.map((ele) => {
      arr = [...arr, ...(ele?.beforeUrls ?? [])];
    });
    return arr;
  };

  useEffect(() => {
    if (userPrivilage && payment_method_popup.popup === "ACH_PAY") {
      set_loading(true);
      plaidDataSR({
        hoaId: hoaId,
        body: { bank_account_id: payment_method_popup.data.account_id },
      })
        .then((res) => {
          console.log("plaidData hoa response:", res);
          set_plaid_data_hoa(res);
        })
        .catch((res) => {
          console.log(res);
        });
    }
  }, [payment_method_popup.popup]);

  useEffect(() => {
    plaidData({
      hoaId: hoaId,
      receiver: "helpsquad",
    })
      .then((res) => {
        console.log("plaidData helpsquad response:", res);
        set_plaid_data_helpsquad(res);
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
      });
  }, []);

  useEffect(() => {
    if (userPrivilage && payment_method_popup.popup === "ACH_PAY") {
      setTimeout(() => {
        set_loading(false);
        payButtonRef?.current.click();
      }, 1000);
    }
  }, [plaid_data_hoa]);

  useEffect(() => {
    if (open_pdf) {
      downloadPDF(set_open_pdf, set_loading, sr_data, pdfRef);

      set_loading(true);
    } else if (open_pdf === null) return;
    else set_loading(false);
  }, [open_pdf]);

  useEffect(() => {
    setApiLoading(true);
    getSRDetail({
      hoaId,
      ticketId,
      srId: sr_id,
    })
      .then((res) => {
        set_sr_data(res);
        console.log({ res });
        setApiLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setApiLoading(false);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error fetching SR"
        );
      });
  }, [user.selectedUserProfile]);

  useEffect((_) => {
    fetch_accounts();
  }, []);

  const fetch_accounts = () => {
    getAccounts({ hoaId: hoaId, bank_account: "True" })
      .then((res) => {
        console.log("accounts:", res);
        set_accounts(res.results);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  if (loading || apiLoading)
    return (
      <SRBase page={url} hoaId={hoaId}>
        <div className="w-full h-full py-20 flex items-center justify-center">
          <LoadingCircleIcon className="h-12 text-blue-500" />
        </div>
      </SRBase>
    );
  else if (!sr_data)
    return (
      <SRBase page={url} hoaId={hoaId}>
        <div className="w-full h-full py-20 flex items-center justify-center">
          <div className="text-2xl font-semibold text-gray-700">
            Service Request not found
          </div>
        </div>
      </SRBase>
    );
  else
    return (
      <SRBase page={url} hoaId={hoaId}>
        <div
          className={"flex flex-col h-min bg-white rounded overflow-x-auto"}
          style={open_pdf ? { minWidth: "1000px" } : {}}
          ref={pdfRef}
        >
          <div className={" " + (open_pdf ? "" : "p-8")}>
            {open_pdf && (
              <div className="flex justify-between mr-5 mb-20">
                <span className="mt-5 text-gray-700 font-semibold text-xl mx-8">
                  Service Request Title
                </span>
                <div
                  className={
                    "flex flex-col items-center mr-10 " +
                    (!sr_data.appointments || sr_data.appointments.length === 0
                      ? "mr-64"
                      : "")
                  }
                >
                  <img
                    src="/helpsquad-logo-orange-navy.png"
                    alt="Logo"
                    className="w-40"
                  />
                </div>
              </div>
            )}
            {!open_pdf && (
              <div className="flex flex-row items-center justify-between flex-wrap gap-5">
                <span className="-ml-3 text-gray-700 text-lg flex flex-row gap-2 items-center">
                  {sessionStorage?.getItem("srListType") && (
                    <Link
                      href={"/hoa/" + hoaId + "/ticket/" + ticketId}
                      className="-mt-1 hover:opacity-60"
                    >
                      <Arrow
                        style={{ height: "14px" }}
                        className="transform rotate-180"
                      />
                    </Link>
                  )}
                  <div className="flex flex-col text-gray-800 font-semibold text-sm">
                    <span>{SRPrefix(sr_data)}</span>
                    <span>{sr_data?.title}</span>
                  </div>
                </span>
                <div className="flex flex-row items-center gap-3">
                  <Link href={"/hoa/" + hoaId + "/sr/messages/" + ticketId}>
                    <span className="cursor-pointer rounded-md border-2 border-indigo-700 hover:border-indigo-500 text-indigo-800 hover:text-indigo-600 px-3 py-1.5 text-sm">
                      Quick Message
                    </span>
                  </Link>
                  <button
                    onClick={() => {
                      set_open_pdf(true);
                    }}
                    className="h-8.5 bg-green-600 hover:bg-green-700 text-sm text-white shadow flex flex-row justify-center items-center gap-2.5 px-3 py-2 w-44 rounded"
                  >
                    {open_pdf ? (
                      <LoadingIcon2 className="h-4 relative bottom-2" />
                    ) : (
                      <span className="flex flex-row items-center gap-2">
                        View as PDF <DocumentIcon className="h-4 w-4" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            )}

            <div
              className={
                "mt-10 w-full gap-10 " +
                (open_pdf
                  ? "flex flex-row gap-4 px-6 items-start"
                  : "flex flex-col lg:grid lg:grid-cols-5")
              }
            >
              <SRSmallDetail {...{ open_pdf, sr_data }} />
              {/* address */}
              <div
                className={
                  "mt-8 col-span-2 flex flex-col items-start justify-start gap-5 " +
                  (open_pdf ? "w-2/3 justify-center" : "")
                }
              >
                <SRAddress {...{ sr_data, open_pdf }} />

                {pic_array().length > 0 && (
                  <div
                    className={
                      "flex flex-wrap gap-1 items-start w-full mt-4 mx-auto " +
                      (open_pdf ? "flex-col" : "")
                    }
                  >
                    {pic_array()?.map((url, url_index) => (
                      <ImgThumbnail
                        key={url_index}
                        url={url}
                        className={
                          open_pdf
                            ? "max-h-28 items-start justify-start"
                            : "max-h-24"
                        }
                        onClick={() =>
                          set_view_files({
                            show: true,
                            index:
                              imageArray(sr_data)
                                .map((val, index) => {
                                  if (val === url) return index;
                                })
                                .filter((val) => val)[0] ?? 0,
                          })
                        }
                      />
                    ))}
                  </div>
                )}

                {/* Appointments */}

                {sr_data.appointments && sr_data.appointments.length > 0 && (
                  <span className="mt-5 font-semibold text-gray-800">
                    Appointments
                  </span>
                )}
                <div className="min-w-full flex flex-col divide-y">
                  {sr_data.appointments?.map((ele, ele_index) => (
                    <SRAppointment
                      key={ele_index}
                      appointment={ele}
                      {...{ sr_data, open_pdf, set_view_files }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <Transition
            show={payment_method_popup.view}
            enter="transition-opacity duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <PaymentPopup
              hoaId={hoaId}
              payment_method_popup={payment_method_popup}
              set_payment_method_popup={set_payment_method_popup}
              plaid_data_hoa={plaid_data_hoa}
              plaid_data_helpsquad={plaid_data_helpsquad}
              accounts={accounts}
              update_data={() => {
                // get_SR();
              }}
              set_loading={set_loading}
              payButtonRef={payButtonRef}
            />
          </Transition>

          <ImgPopup
            view={view_files.show ?? false}
            setView={() => {
              set_view_files({ show: false });
            }}
            files={imageArray(sr_data)}
            file_index={view_files.index}
            onChange={(val) => set_view_files({ ...view_files, index: val })}
          />
        </div>
      </SRBase>
    );
}
