import React, { useState } from "react";
import { RightArrowIcon2 } from "../../../../../common/AppIcons";
import { shortcutItems } from "./data/shortcutItems";

export default function Shortcuts({ setShortcut, hoaId }) {
  const [onHover, setOnHover] = useState(false);

  return (
    <div className="mt-2 flex-grow">
      <span className="text-gray-500 text-sm">YOUR SHORTCUTS</span>
      <div className="mt-5 grid gap-4 grid-cols-2 md:grid-cols-4 justify-around">
        {shortcutItems({ hoaId }).map((value, index) => {
          if (!value.disable)
            return (
              <div
                key={index}
                onClick={() => {
                  value.onClick ? value.onClick() : setShortcut(value.name);
                }}
                className="bg-white p-5 flex justify-center flex-col items-start gap-5 cursor-pointer shadow-sm border border-white hover:border-indigo-500 rounded"
                onMouseEnter={() => {
                  setOnHover(index);
                }}
                onMouseLeave={() => setOnHover(false)}
              >
                {value.icon("h-10 w-10 mt-4")}
                <span
                  className={
                    "w-full flex flex-row items-center duration-300 pr-2 " +
                    (onHover === index ? "gap-2" : "gap-4")
                  }
                >
                  <span
                    className="whitespace-pre truncate text-gray-800"
                    style={{ fontSize: "15px" }}
                  >
                    {value.title}
                  </span>
                  <RightArrowIcon2 className="h-3 tranform translate-y-px" />
                </span>
              </div>
            );
        })}
      </div>
    </div>
  );
}
