import React, { useState } from "react";

import Modal from "../../../../../common/Modal";
import { postDocument } from "../../../../../../api/ApiV2";
import { LoadingAnimated } from "../../../../../common/AppIcons";

export default function CreateDocumentPopup({
  hoaId,
  createDocument,
  setCreateDocument,
  get_documents,
}) {
  const [loading, setLoading] = useState(false);

  if (!createDocument) return null;
  else
    return (
      <Modal
        show={createDocument !== null}
        setShow={(_) => setCreateDocument(null)}
      >
        <form
          className="p-4"
          onSubmit={(e) => {
            e.preventDefault();
            setCreateDocument((createDocument) => {
              return { ...createDocument, errors: undefined };
            });
            setLoading(true);
            postDocument(hoaId, {
              ...(createDocument ?? []),
              document_type: createDocument?.documentType,
            })
              .then((res) => {
                setLoading(false);
                console.log(createDocument, res);
                setCreateDocument(null);
                get_documents();
              })
              .catch((res) => {
                setLoading(false);
                setCreateDocument((createDocument) => {
                  return {
                    ...createDocument,
                    errors: res?.message ?? "Error creating new document",
                  };
                });
              });
          }}
        >
          <label
            htmlFor="email"
            className="block text-lg mb-3 font-medium text-gray-700"
          >
            Create Document
          </label>
          <div className="mt-1">
            <input
              type="text"
              value={createDocument.name}
              onChange={(e) =>
                setCreateDocument({
                  ...createDocument,
                  name: e.target.value,
                })
              }
              className="px-3 py-1.5 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder={"Document Name"}
            />
          </div>
          <span className="block text-sm text-red-500">
            {createDocument.errors}
          </span>
          <button
            type="submit"
            className="relative inline-flex items-center my-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? <LoadingAnimated className="h-4" /> : "Create Document"}
          </button>
        </form>
      </Modal>
    );
}
