import { Link } from "raviger";
import { BuildingIcon } from "../../common/AppIcons";

export default function Header({
  featuresComponent,
  integrationsComponent,
  pricingComponent,
}) {
  return (
    <div className="fixed z-10 top-0 w-full bg-white text-black">
      <div className="flex flex-row items-center flex-wrap gap-y-4 justify-between mx-auto max-w-6xl pt-5 pb-3 px-4">
        <div className="flex flex-row items-center gap-2">
          <img src="/doorz_logo.png" className="w-32" />
        </div>
        <div className="md:ml-6 hidden sm:flex flex-row items-center justify-end flex-wrap gap-x-5 md:gap-x-10 lg:gap-x-20 gap-y-3">
          <button
            onClick={() => {
              console.log(featuresComponent);
              featuresComponent?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }}
            className="focus:outline-0 transform hover:scale-110 duration-300"
          >
            Features
          </button>
          {/* <button
            onClick={() => {
              pricingComponent?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }}
            className="focus:outline-0 transform hover:scale-110 duration-300"
          >
            Pricing
          </button> */}
          <button
            onClick={() => {
              integrationsComponent?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }}
            className="focus:outline-0 transform hover:scale-110 duration-300"
          >
            Integrations
          </button>
          <Link
            href="/login"
            className="mt-1 px-12 py-2 focus:outline-none border border-gray-700 rounded duration-300 hover:text-black hover:scale-105"
          >
            Login
          </Link>

          <Link
            href="/register"
            style={{ border: "1px solid #2A32FD", background: "#2A32FD" }}
            className="mt-1 lg:-ml-16 px-12 py-2 focus:outline-none rounded text-white duration-300 hover:bg-opacity-90 hover:scale-105"
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
}
