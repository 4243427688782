import React from "react";
import { renderBalance } from "../../../../../utils/StringUtils";
import {
  BalanceIcon,
  LinkIcon,
  RefreshIcon,
} from "../../../../common/AppIcons";

export default function BalanceRow({
  account,
  plaidData,
  set_no_plaid_data_popup,
  setAccount,
  get_plaid_transactions,
  startPlaidLink,
  ready,
}) {
  return (
    <tr key={account.id} className="bg-white rounded">
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        <div className="flex flex-col">
          <div className="space-x-3 truncate flex items-center flex-row">
            <BalanceIcon
              style={{ color: "#2A32FD" }}
              className="flex-shrink-0 h-5 w-5"
            />
            <p className="truncate text-base font-semibold">{account.name}</p>
          </div>
        </div>
      </td>
      <td className="w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        <div className="p-2 flex flex-col items-start text-gray-600">
          <button
            style={{
              marginBottom: "4px",
              color: "#2A32FD",
              border: "1px solid #2A32FD",
            }}
            className="font-semibold py-1 px-3 hover:opacity-70 rounded"
            onClick={() => {
              if (plaidData === null) set_no_plaid_data_popup(true);
              else setAccount(account);
            }}
          >
            Reconcile
          </button>
          Last reconciled:{" "}
          {account.last_reconciled_date
            ? account.last_reconciled_date
            : "Never"}
        </div>
      </td>
      <td className="px-6 py-4 text-right whitespace-nowrap">
        <span className="text-gray-800 font-semibold">
          {renderBalance(account.current_amount)}
        </span>
      </td>
      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
        {account.plaid_item_metadata ? (
          <button
            className="border border-gray-500 px-2 py-1 flex flex-row items-center gap-2 rounded text-gray-800 hover:text-white hover:bg-gray-700 duration-300 font-semibold text-sm"
            onClick={(_) => get_plaid_transactions(account)}
          >
            <RefreshIcon className="h-4" /> <span>Sync</span>
          </button>
        ) : (
          <button
            disabled={!ready}
            className="border border-gray-500 px-2 py-1 flex flex-row items-center gap-2 rounded text-gray-800 hover:text-white hover:bg-gray-700 duration-300 font-semibold text-sm"
            onClick={(_) => {
              if (plaidData === null) set_no_plaid_data_popup(true);
              else startPlaidLink(account.id);
            }}
          >
            <LinkIcon className="h-4" /> <span>Link</span>
          </button>
        )}
      </td>
    </tr>
  );
}
