import { check_transactions_page_data } from "./check";
import { journal_transactions_page_data } from "./journal";
import { recent_transactions_page_data } from "./recent";
import { search_transactions_page_data } from "./search";

export const pageData = (page, value) => {
  if (page === "CHECK") return check_transactions_page_data(value);

  if (page === "JOURNAL") return journal_transactions_page_data(value);

  if (page === "RECENT") return recent_transactions_page_data(value);

  if (page === "SEARCH") return search_transactions_page_data(value);
};
