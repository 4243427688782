import { Link } from "raviger";
import React from "react";

export default function DocumentLine({ hoaId, document, setUploadDocument }) {
  return (
    <div className="border-2 border-gray-200 rounded-md px-7 py-4 flex flex-row items-center justify-between">
      <span className="text-gray-700">{document?.name}</span>
      <div className="ml-2 flex-shrink-0 flex items-center gap-x-3 gap-y-1">
        {document.document_key && (
          <Link
            href={`/hoa/${hoaId}/view_document/${document.id}`}
            className="px-6 py-1 inline-flex text-xs leading-5 font-semibold rounded bg-green-100 text-green-700 hover:opacity-70"
            rel="noreferrer noopener"
            target="_blank"
          >
            View
          </Link>
        )}
        <span
          onClick={(_) => {
            setUploadDocument({
              view: true,
              documentId: document.id,
            });
          }}
          style={
            document.document_key
              ? { backgroundColor: "rgba(221, 160, 7, 0.18)", color: "#DDA007" }
              : {}
          }
          className={
            "px-6 py-1 inline-flex text-xs leading-5 font-semibold rounded cursor-pointer hover:opacity-70 " +
            (document.document_key
              ? ""
              : "bg-indigo-100 text-indigo-600 border-dashed border-2 border-indigo-600")
          }
        >
          {document.document_key ? "Upload Revision" : "Upload"}
        </span>
      </div>
    </div>
  );
}
