
export const inputFieldData=({dropdown_options})=>[
    {
        label:'Plaid Client ID',
        name:"plaid_client_id",
        placeholder:'Plaid Client ID',
    },
    {
        label:'Plaid Secret',
        name:"plaid_secret",
        placeholder:'Plaid Secret',
    },
    {
        label:'Stripe API Key',
        name:"stripe_api_key",
        placeholder:'Stripe API Key',
    },
    {
        label:'Select the Bank Account linked to your Stripe Account',
        name:"bank_account",
        placeholder:'Select Bank Account',
        options:dropdown_options,
        type:'dropdown'
    },

]
