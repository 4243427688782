import { toast } from "react-toastify";
import { jobTypes } from "../../../../../common/constants";
import SelectCard from "./common/SelectCard";

export default function JobSelection({ data, setData, set_form }) {
  return (
    <div>
      <div className="w-full text-center mt-10 mb-6 text-gray-800 text-lg font-semibold items-center my-3">
        What type of Service would you like to request?
      </div>

      <div className="flex gap-4 p-4 items-center justify-center">
        {jobTypes.map((job) => {
          return (
            <SelectCard
              onClick={(_) => {
                setData((data) => {
                  return {
                    ...data,
                    job_type: job.value,
                    sub_category: job.sub_category,
                  };
                });
              }}
              Icon={job.icon}
              label={job.label}
              selected={data?.job_type === job.value}
            />
          );
        })}
      </div>
      <div className="w-full flex justify-center flex-row gap-6 mt-5">
        <button
          onClick={() => {
            if (!data.externalVendorPhone && !data.externalVendorEmail)
              set_form("VENDOR_SELECTION");
            else set_form("EXTERNAL_VENDOR_DETAILS");
          }}
          style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
          className="px-4 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-70"
        >
          Go back
        </button>
        <button
          onClick={() => {
            if (!data.job_type) {
              toast.warning("Please select a job type");
              return;
            }
            if (data.job_type === "HANDYMAN") set_form("CATOGARY_SELECTION");
            else {
              set_form("ADDRESS_UNIT_SELECTION");
              setData((data) => {
                return { ...data, subCategory: null };
              });
            }
          }}
          style={{ backgroundColor: "#2A32FD" }}
          className="px-5 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-80 text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
}
