import { useState } from "react";
import { Transition } from "@tailwindui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Dropdown from "../../../../common/Form/Dropdown";
import { addHoaMembers } from "../../../../../api/ApiV2";
import {
  CrossCicleIcon,
  Alert,
  LoadingCircleIcon,
} from "../../../../common/AppIcons";
import { userType } from "../../../../../common/constants";
import { useUserActions } from "../../../../../actions/UserActions";

export default function AddAdminPopup({ show, setShow, hoaId }) {
  const [user, setUser] = useState({
    data: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      user_type: "OWNER",
    },
  });
  const [loading, setLoading] = useState(false);
  const [warning, set_warning] = useState({});
  const { hoaMe } = useUserActions();

  const remove_warning = (key) => {
    delete warning[key];
  };

  const add_member_not_ready = () => {
    let data = {};
    if (user.data.first_name === "")
      data = { ...data, first_name: "Please fill this field" };
    if (user.data.last_name === "")
      data = { ...data, last_name: "Please fill this field" };
    if (user?.data.phone === "")
      data = { ...data, phone: "Please fill this field" };
    if (
      user?.data.phone?.length < 2 ||
      (user?.data.phone?.charAt(0) === "1" && user?.data.phone?.length < 11)
    )
      data = { ...data, phone: "Invalid phone number" };
    if (user.data.email === "")
      data = { ...data, email: "Please fill this field" };
    if (Object.keys(data).length === 0) return false;
    else return data;
  };

  const addHoaMember = (user_data) => {
    if (warning && Object.keys(warning).length > 0) return;
    setLoading(true);
    // if (user.newUser)
    const userPayload = user_data ?? {
      user: {
        email: user.data.email,
        first_name: user.data.first_name,
        last_name: user.data.last_name,
        phone: user?.data?.phone?.length < 2 ? null : "+" + user.data.phone,
      },
      user_type: user?.data.user_type,
    };
    console.log(userPayload);

    if (add_member_not_ready()) {
      set_warning(add_member_not_ready());
      setLoading(false);
      return;
    }
    addHoaMembers(hoaId, userPayload)
      .then((res) => {
        setLoading(false);
        setUser({
          index: 0,
          data: {
            first_name: "",
            last_name: "",
            phone: "1",
            email: "",
            user_type: "OWNER",
          },
        });
        setShow();
        hoaMe(hoaId);
      })
      .catch((res) => {
        if (typeof res[0] === "string") set_warning({ overall: res[0] });
        else set_warning(res[0] ?? { overall: "User uploading failed." });
        setTimeout(() => {
          set_warning({});
        }, 3000);
        setLoading(false);
        console.log(res);
      });
  };

  return (
    <div
      className={
        "fixed inset-0 overflow-hidden flex items-center justify-center bg-gray-200 bg-opacity-70 " +
        (show ? "" : "hidden opacity-0")
      }
      onClick={() => setShow()}
    >
      <div
        className="absolute bg-white rounded-md border shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-px w-full flex flex-row-reverse">
          <div
            className="relative left-3 bottom-3 cursor-pointer"
            onClick={() => setShow()}
          >
            <CrossCicleIcon className="h-8 w-8" />
          </div>
        </div>
        <Transition
          show={warning.overall ? true : false}
          className="mt-3"
          enter="transition-opacity transition-translate transform duration-75"
          enterFrom="opacity-0 -translate-y-full"
          enterTo="opacity-100 translate-y-0"
          leave="transition-opacity transition-translate transform duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-full"
        >
          <div className="bg-gray-600 rounded shadow text-white flex items-center justify-center flex-row gap-3 p-2 mx-3">
            <Alert /> {warning.overall}
          </div>
        </Transition>
        <div className="sm:overflow-auto w-full py-5 px-3">
          <h1 className="flex flex-row gap-3 justify-center text-center text-xl font-bold mt-4 mb-8 sm:mb-10 opacity-90">
            Add Board Member or Super Admin
          </h1>
          <div className="m-auto px-4 flex flex-col gap-4 justify-center items-center text-gray-800 max-w-xl mt-5">
            <div className="flex flex-col md:flex-row gap-1 w-full">
              {[
                {
                  label: "First Name",
                  name: "first_name",
                },
                {
                  label: "Last Name",
                  name: "last_name",
                },
              ].map((input, input_index) => (
                <div key={input_index} className="flex flex-col gap-1 w-full">
                  <div className="flex flex-row gap-1 justify-between">
                    <span className="font-semibold whitespace-pre tracking-wide ml-1 text-xs">
                      {input.label}
                    </span>
                    <span
                      style={{ fontSize: ".65rem" }}
                      className="text-xs text-red-600 text-right relative top-1"
                    >
                      {warning[input.name]}
                    </span>
                  </div>
                  <input
                    value={user.data[input.name]}
                    onChange={(e) => {
                      remove_warning(input.name);
                      setUser({
                        ...user,
                        data: { ...user.data, [input.name]: e.target.value },
                      });
                    }}
                    className={
                      "sm:w-full px-2 py-1 sm:pt-2 sm:tracking-wide text-gray-700 border focus:border-2 focus:border-indigo-500 rounded focus:outline-none focus:bg-gray-50 " +
                      (warning[input.name]
                        ? "border-red-300"
                        : "border-gray-300")
                    }
                  />
                </div>
              ))}
            </div>

            <div className="flex flex-col gap-1 w-full">
              <div className="flex flex-row gap-1 justify-between">
                <span className="font-semibold tracking-wide ml-1 text-xs">
                  Email
                </span>
                <span
                  style={{ fontSize: ".65rem" }}
                  className="text-xs text-red-600 text-right relative top-1"
                >
                  {warning["email"]}
                </span>
              </div>
              <input
                value={user.data["email"]}
                onChange={(e) => {
                  remove_warning("email");
                  setUser({
                    ...user,
                    data: { ...user.data, email: e.target.value },
                  });
                }}
                className={
                  "sm:w-full px-2 py-1 sm:pt-2 sm:tracking-wide text-gray-700 border focus:border-2 border-gray-300 focus:border-indigo-500 rounded focus:outline-none focus:bg-gray-50 " +
                  (warning["email"] ? "border-red-300" : "border-gray-300")
                }
              />
            </div>

            <div className="flex flex-col gap-1 w-full">
              <div className="flex flex-row gap-1 justify-end relative top-px">
                <span
                  style={{ fontSize: ".65rem" }}
                  className="text-xs text-red-600 text-right"
                >
                  {warning["phone"]}
                </span>
              </div>
              <PhoneInput
                country={"us"}
                value={user.data.phone}
                onChange={(phone) => {
                  remove_warning("phone");

                  setUser({
                    ...user,
                    data: { ...user.data, phone: phone },
                  });
                }}
                className="text-xs font-semibold"
                inputProps={{
                  className:
                    "sm:w-full pl-12 px-2 py-1 text-base font-normal sm:pt-2 sm:tracking-wide text-gray-700 border focus:border-2 border-gray-300 focus:border-indigo-500 rounded focus:outline-none focus:bg-gray-50 " +
                    (warning["phone"] ? "border-red-300" : "border-gray-300"),
                }}
              />
            </div>

            <div className="flex flex-col md:flex-row gap-1 w-full">
              <div className="flex flex-col gap-1 w-full">
                <div className="flex flex-row gap-1 justify-between">
                  <span className="font-semibold tracking-wide ml-1 text-xs">
                    User Type
                  </span>
                  <span
                    style={{ fontSize: ".65rem" }}
                    className="text-xs text-red-600 text-right relative top-1"
                  >
                    {warning["user_type"]}
                  </span>
                </div>
                <Dropdown
                  value={user.data.user_type}
                  selectClassName={
                    "sm:w-full py-1 sm:tracking-wide text-gray-700 border focus:border-2 border-gray-300 focus:border-indigo-500 rounded focus:outline-none focus:bg-gray-50 " +
                    (warning["user_type"]
                      ? "border-red-300"
                      : "border-gray-300")
                  }
                  onChange={(value) => {
                    remove_warning("user_type");
                    setUser({
                      ...user,
                      data: { ...user.data, user_type: value },
                    });
                  }}
                  options={Object.keys(userType)
                    .filter(
                      (val) => val === "SUPER_ADMIN" || val === "BOARD_MEMBER"
                    )
                    .map((value) => {
                      return {
                        label: (
                          <div className="flex flex-row items-center gap-3">
                            {userType[value].badge}
                            {userType[value].label}
                          </div>
                        ),
                        className: "h-8",
                        value: value,
                        word: userType[value].label,
                      };
                    })
                    .filter((val) => val)}
                />
              </div>
            </div>

            <div className={"flex flex-row gap-3 w-full justify-center "}>
              <div
                onClick={() => {
                  addHoaMember();
                }}
                className={
                  "w-full rounded cursor-pointer text-white mx-auto text-lg sm:text-xl flex items-center justify-center font-bold tracking-wider py-2 my-8 bg-indigo-600 opacity-90 hover:bg-indigo-700"
                }
              >
                {loading ? (
                  <LoadingCircleIcon className="h-4 text-white" />
                ) : (
                  "Save"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
