import { useState } from "react";
import { NegativeInCircleIcon, PlusInCircleIcon } from "../../common/AppIcons";

const questions = [
  {
    question: "What is Doorz?",
    answer:
      "Doorz is a cloud platform for managing & maintaining your properties",
  },
  {
    question: "Who can use Doorz?",
    answer: "",
  },
  {
    question: "Who does the maintenance?",
    answer: "",
  },
  {
    question: "What if i am a maintenance professional?",
    answer: "",
  },
];

export default function FAQ({ className }) {
  const [activeQuestion, setActiveQuestion] = useState(null);

  return (
    <div className={"py-28 " + className}>
      <div className="flex flex-col items-center gap-4 max-w-5xl mx-auto px-6">
        <div
          className="px-4 py-1.5 rounded"
          style={{
            backgroundColor: "rgba(42, 50, 253, 0.07)",
            color: "#2A32FD",
            border: "1px solid #d8d8e7",
          }}
        >
          FAQ
        </div>
        <span
          style={{ fontSize: "26px", fontWeight: "800" }}
          className="text-center"
        >
          Frequently Asked Questions
        </span>

        <div className="mt-8 max-w-2xl w-full mx-auto flex flex-col divide-y">
          {questions.map((question, index) => (
            <div key={index} className="w-full py-4 flex flex-col gap-2">
              <div className="mt-1 flex flex-row items-center justify-between gap-4">
                {question.question}
                <button
                  onClick={() =>
                    activeQuestion === index
                      ? setActiveQuestion(null)
                      : setActiveQuestion(index)
                  }
                >
                  {activeQuestion === index ? (
                    <NegativeInCircleIcon className="h-5 bg-white text-white" />
                  ) : (
                    <PlusInCircleIcon
                      className="h-5"
                      style={{ color: "#2A32FD" }}
                    />
                  )}
                </button>
              </div>
              <span
                style={{
                  maxHeight: activeQuestion === index ? "100px" : "0px",
                }}
                className={
                  "text-sm text-gray-500 overflow-hidden transform duration-500 delay-75 " +
                  ""
                }
              >
                {question.answer}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
