import React, { useState, useReducer } from "react";
import { Transition } from "@tailwindui/react";
import { useDetectClickOutside } from "react-detect-click-outside";

import { CalenderIcon3, LeftArrowIcon } from "../AppIcons";

const removeTime = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDay());

const initialState = removeTime(new Date()); // Today without date

function reducer(state, action) {
  let mutateable = new Date(state);
  switch (action) {
    case "NEXT_MONTH":
      mutateable.setMonth(mutateable.getMonth() + 1);
      return mutateable;
    case "PREVIOUS_MONTH":
      mutateable.setMonth(mutateable.getMonth() - 1);
      return mutateable;
    default:
      throw new Error();
  }
}

const getMonthString = (date) =>
  date.toLocaleString("default", {
    month: "long",
  });

const getBlankDays = (calendarState) => {
  const date = new Date(calendarState.getFullYear(), calendarState.getMonth());
  return [...Array(date.getDay() == 0 ? 6 : date.getDay() - 1)];
};
const getDays = (calendarState) => {
  let arr = [];
  for (
    let date = new Date(
      calendarState.getFullYear(),
      calendarState.getMonth(),
      1
    );
    date.getMonth() === calendarState.getMonth();
    date.setDate(date.getDate() + 1)
  ) {
    arr.push(new Date(date));
  }
  return arr;
};

const isToday = (date) => new Date().toDateString() === date.toDateString();

export default function DatePicker2({ className, value, onChange }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const dropdown_ref = useDetectClickOutside({
    onTriggered: () => setShowDatepicker(false),
  });

  const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  return (
    <div className={"relative flex flex-col items-center " + className}>
      <div
        onClick={() => {
          setShowDatepicker(!showDatepicker);
        }}
        className="flex flex-row items-center cursor-pointer hover:opacity-70"
      >
        <CalenderIcon3 className="text-black h-4" />
        <span className="ml-3 mr-2">
          {value
            ? new Date(value)?.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })
            : "Select date"}
        </span>
        <div className="text-gray-500 hover:text-gray-400">
          <LeftArrowIcon className="transform -rotate-90 h-5" />
        </div>
      </div>

      <Transition
        show={showDatepicker}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="bg-white transform translate-y-6 rounded-lg shadow p-4 absolute z-50 w-80 sm:w-96"
      >
        <div ref={dropdown_ref} className="w-full">
          <div className="flex flex-row items-center gap-3 mb-2">
            <button
              type="button"
              className={
                "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
              }
              onClick={(e) => {
                dispatch("PREVIOUS_MONTH");
                e.stopPropagation();
              }}
            >
              <svg
                className="h-4 inline-flex"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <div>
              {getMonthString(state)} {state.getFullYear()}
            </div>
            <button
              type="button"
              className={
                "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
              }
              onClick={(e) => {
                dispatch("NEXT_MONTH");
                e.stopPropagation();
              }}
            >
              <svg
                className="h-4 inline-flex"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
            <div></div>
          </div>

          <div className="flex flex-wrap mt-3 mb-3 -mx-1">
            {DAYS.map((value1, index) => {
              return (
                <div key={value1} style={{ width: "14.26%" }} className="px-1">
                  <div className="text-gray-600 text-center text-sm uppercase">
                    {value1}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex flex-wrap -mx-1 gap-y-2">
            {getBlankDays(state).map((value1, index) => {
              return (
                <div
                  key={index}
                  style={{ width: "14.28%" }}
                  className="text-center border p-1 border-transparent text-sm"
                ></div>
              );
            })}

            {getDays(state).map((date, index) => {
              //   console.log("Rendering Date", date);
              return (
                <div
                  key={index}
                  style={{ width: "14.28%" }}
                  className="w-full flex items-center justify-center"
                >
                  <div
                    onClick={() => {
                      onChange(date);
                      setShowDatepicker(false);
                    }}
                    className={
                      "cursor-pointer w-8 h-8 flex items-center justify-center text-sm rounded-full leading-loose transition ease-in-out duration-100 hover:opacity-60 " +
                      (isToday(date) ? "border-indigo-600 border-2" : "")
                    }
                    style={
                      date.toDateString() === new Date(value).toDateString()
                        ? { backgroundColor: "#2A32FD", color: "white" }
                        : {
                            backgroundColor: "rgba(42, 50, 253, 0.08)",
                            color: "#211C52",
                          }
                    }
                  >
                    {date.getDate()}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Transition>
    </div>
  );
}
