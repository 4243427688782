import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import {
  CheckCircleIcon,
  CondoIcon,
  CrossCicleIcon,
  CrossInCircleIcon,
  LoadingCircleIcon,
  LoadingCircleIcon2,
  UploadIcon3,
} from "../../../../common/AppIcons";
import {
  getAddressPhotos,
  getBuildings,
  uploadAddressPhoto,
} from "../../../../../api/ApiV2";
import PhotosUpload from "./PhotosUpload";

export default function MultiplePropertyPhotosUpload({
  hoaId,
  previousPage,
  nextPage,
}) {
  const [buildings, setBuildings] = useState([]);
  const [files, setFiles] = useState([
    // {
    //   id: null,
    //   file: null,
    //   buildingId: null,
    //   uploadStatus: "none" | "uploading" | "uploaded" | "failed"
    // }
  ]);
  const [openFileUpload, setOpenFileUpload] = useState();
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const [changePage, setChangePage] = useState(false);

  useEffect(() => {
    if (changePage) {
      nextPage();
    }
  }, [changePage]);

  useEffect(() => {
    openFileUpload && ref.current?.click();
  }, [openFileUpload]);

  useEffect(() => {
    if (buildings?.length > 0) {
      buildings.forEach((building) => {
        building.id &&
          getAddressPhotos({ hoaId: hoaId, propertyID: building.id })
            .then((res) => {
              res?.photos &&
                Object.entries(res.photos).forEach(([key, value]) => {
                  setFiles((files) =>
                    [
                      ...files,
                      !files.find((val) => val.id === key) && {
                        id: key,
                        url: value.url,
                        buildingId: building.id,
                        uploadStatus: "uploaded",
                      },
                    ].filter((val) => val)
                  );
                });
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
      });
    }
  }, [buildings]);

  useEffect(() => {
    setLoading(true);
    getBuildings({ hoaId: hoaId })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setBuildings(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const uploadPhoto = () => {
    if (!files.filter((val) => val.uploadStatus !== "uploaded").length > 0)
      setChangePage(true);

    let apiLoading = [
      ...Array(files.filter((val) => val.uploadStatus !== "uploaded")),
    ].fill("loading");

    const checkApiLoading = () => {
      if (apiLoading.every((val) => val === "success")) {
        setChangePage(true);
      }
    };
    files
      .filter((val) => val.uploadStatus !== "uploaded")
      .forEach((file, index) => {
        setFiles((prev) =>
          prev.map((val) =>
            val.id === file.id ? { ...val, uploadStatus: "uploading" } : val
          )
        );
        uploadAddressPhoto({
          hoaId: hoaId,
          propertyID: file.buildingId,
          data: { document: file.file },
        })
          .then((res) => {
            setFiles((prev) =>
              prev.map((val) =>
                val.id === file.id ? { ...val, uploadStatus: "uploaded" } : val
              )
            );
            apiLoading[index] = "success";
            checkApiLoading();
            console.log(res);
          })
          .catch((res) => {
            setFiles((prev) =>
              prev.map((val) =>
                val.id === file.id ? { ...val, uploadStatus: "failed" } : val
              )
            );
            apiLoading[index] = "failed";
            checkApiLoading();
            console.log(res);
            toast.error("Photo upload failed");
          });
      });
  };

  if (loading)
    return (
      <div className="w-full flex justify-center items-center py-20">
        <LoadingCircleIcon2 className="h-8" />
      </div>
    );
  else if (buildings?.length === 1)
    return (
      <PhotosUpload
        {...{
          hoaId,
          previousPage,
          nextPage,
        }}
      />
    );
  else
    return (
      <div className="mt-10 max-w-4xl w-full mx-auto flex flex-col gap-4">
        {buildings?.map((hoa, hoaIndex) => (
          <div key={hoaIndex} className="flex flex-col bg-white p-5 rounded">
            <div className="w-full flex flex-row gap-3 bg-gray-100 items-center justify-between">
              <div className="flex-grow flex flex-row items-center gap-6 p-4 pr-10">
                <div
                  className="bg-indigo-100 p-3 rounded"
                  style={{ color: "#2A32FD" }}
                >
                  <CondoIcon className="h-10" />
                </div>
                <span className="text-sm">{hoa.street_address_1 ?? ""}</span>
              </div>
              <button
                onClick={() => {
                  setOpenFileUpload();
                  setTimeout(() => {
                    setOpenFileUpload(hoa.id);
                  }, 100);
                }}
                className="mr-4 rounded border border-dashed border-indigo-600 bg-indigo-100 hover:bg-indigo-200 text-indigo-600 px-4 py-1.5 flex flex-row items-center gap-4"
              >
                <UploadIcon3 className="h-4" /> Upload Property Photos
              </button>
            </div>
            <div className="flex flex-row flex-wrap gap-x-4 gap-y-2">
              {files
                .filter((val) => val.buildingId === hoa.id)
                ?.map((file, fileIndex) => (
                  <div key={fileIndex} className="mt-3">
                    {file.uploadStatus !== "uploaded" ? (
                      <div className="w-full flex justify-end">
                        <button
                          onClick={() => {
                            setFiles((files) =>
                              files.filter((file1) => file.id !== file1.id)
                            );
                          }}
                          className="relative z-20 top-2 left-2.5 transform duration-300 hover:scale-125"
                        >
                          <CrossCicleIcon className="h-4" />
                        </button>
                      </div>
                    ) : (
                      <div className="h-4" />
                    )}
                    <a
                      href={file.url ?? "#"}
                      target="_blank"
                      className="relative"
                    >
                      <img src={file.url} className="h-32" />
                      {file.uploadStatus !== "none" && (
                        <div
                          className={
                            "absolute z-10 p-2 inset-0 flex flex-col justify-end items-end"
                          }
                        >
                          <div className="p-1 rounded bg-white">
                            {file.uploadStatus === "uploading" ? (
                              <LoadingCircleIcon className="h-6 text-blue-500" />
                            ) : file.uploadStatus === "uploaded" ? (
                              <CheckCircleIcon className="h-6 text-green-500" />
                            ) : file.uploadStatus === "failed" ? (
                              <CrossInCircleIcon className="h-6 text-red-500" />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      )}
                    </a>
                  </div>
                ))}
            </div>
          </div>
        ))}
        <div className="w-full mt-4 flex flex-row justify-end items-center gap-5">
          <button
            onClick={previousPage}
            className="bg-white text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 py-2 px-6 rounded"
          >
            Go Back
          </button>

          <button
            style={{
              backgroundColor: "#2A32FD",
              border: "2px solid #2A32FD",
            }}
            className="text-sm text-white hover:opacity-80 py-2 px-6 flex items-center justify-center rounded"
            onClick={uploadPhoto}
            disabled={files.find((val) => val.uploadStatus === "uploading")}
          >
            {files.find((val) => val.uploadStatus === "uploading") ? (
              <LoadingCircleIcon2 className="h-5" />
            ) : (
              "Continue"
            )}
          </button>
        </div>
        <div className="hidden">
          <input
            type="file"
            multiple
            ref={ref}
            onChange={(e) => {
              let tempFiles = [];

              [...e.target.files]?.forEach((file) => {
                tempFiles.push({
                  id: new Date(),
                  file: file,
                  url: URL.createObjectURL(file),
                  buildingId: openFileUpload,
                  uploadStatus: "none",
                });
              });
              setFiles((files) => [...files, ...tempFiles]);

              setOpenFileUpload();
            }}
          />
        </div>
      </div>
    );
}
