import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import aify from "linkify-react";
import parse from "html-react-parser";

import {
  PhoneIcon,
  EmailIcon2,
  ChatIcon,
  LoadingCircleIcon2,
} from "../../../../common/AppIcons";
import ImgPopup from "../../../../common/ImgPopup";
import { date_format } from "../../../../../utils/dateFormat";

const incoming_chat_container_className = "justify-end items-end";
const incoming_chat_box_className =
  "bg-white border shadow text-gray-700 rounded-l-md";
const outgoing_chat_container_className = "justify-start items-start";
const outgoing_chat_box_className = "bg-indigo-900 text-gray-100 rounded-r-md";

const file_type = (url) => {
  let file =
    url.split(".").length > 0
      ? url.split(".")[url.split(".").length - 1]
      : null;

  if (
    [
      "png",
      "jpeg",
      "gif",
      "bmp",
      "jpg",
      "apng",
      "svg",
      "bmp ico",
      "png ico",
    ].includes(file)
  )
    return "image";
  else if (
    [
      "mp4",
      "webm",
      "ogg",
      "mov",
      "mkv",
      "flv",
      "avi",
      "wmv",
      "amv",
      "3gp",
    ].includes(file)
  )
    return "video";
  else if (["mp3", "aac", "m4a", "flac", "wav", "wma"].includes(file))
    return "audio";

  return false;
};

export default function Messages({
  client_data,
  chats,
  set_chats,
  load_chat_params,
  call_next_page,
  flag,
  set_flag,
}) {
  const [view_files, set_view_files] = useState({
    show: false,
    index: 0,
  });
  const [dont_scroll, set_dont_scroll] = useState(false);

  const scrollToBottom = () => {
    let ele = document.getElementById("chat_area");
    ele.scrollTop = ele.scrollHeight;
  };

  useEffect(() => {
    if (chats && chats.length !== 0 && !flag) {
      set_flag(true);
      if (!dont_scroll) scrollToBottom();
    }
    set_dont_scroll(false);
  }, [chats]);

  const viewFiles = () => {
    let files = chats
      .filter((val) => val.attachmentUrls && val.attachmentUrls.length > 0)
      .map((val) => val.attachmentUrls);
    let ans = [];
    for (let i = 0; i < files.length; i++) {
      if (Array.isArray(files[i])) ans = [...ans, ...files[i]];
      else ans = [...ans, files[i]];
    }
    return ans;
  };

  const find_file_index = (url) => {
    let index = 0;
    viewFiles().map((val, val_index) => {
      if (val === url) index = val_index;
    });
    return index;
  };

  const chat_bubble_svg = (chat_type) => {
    const svg_className = "h-3 w-3";
    if (chat_type === "CALL") return <PhoneIcon className={svg_className} />;
    else if (chat_type === "EMAIL")
      return <EmailIcon2 className={svg_className} />;
    else return <ChatIcon className={svg_className} />;
  };

  const time_format = (seconds) => {
    let data = new Date(
      new Date(0, 0, 0).setSeconds(
        new Date(0, 0, 0).getSeconds() + Number(seconds)
      )
    );
    let hrs = data.getHours();
    let mins = data.getMinutes();
    let sec = data.getSeconds();

    if (hrs <= 9) hrs = "0" + hrs;
    if (mins < 10) mins = "0" + mins;
    if (sec <= 9) sec = "0" + sec;

    let postTime = mins + ":" + sec;
    if (hrs !== "00") postTime = hrs + ":" + postTime;

    return postTime;
  };

  const chat_bubble = (chat, chat_index) => {
    return (
      <div
        key={chat_index}
        className={
          "w-full flex flex-col gap-2 " +
          (chat.isOutgoing
            ? outgoing_chat_container_className
            : incoming_chat_container_className)
        }
      >
        <div
          className={
            "pt-2 pb-1 px-4 mx-4 max-w-lg rounded-t-md text-sm flex flex-col gap-1 " +
            (chat.isOutgoing
              ? outgoing_chat_box_className
              : incoming_chat_box_className)
          }
        >
          <span
            className={
              "w-full text-xs opacity-70 flex row gap-1 items-center " +
              (chat.isOutgoing
                ? outgoing_chat_container_className
                : incoming_chat_container_className)
            }
          >
            {chat_bubble_svg(chat.channel)}
            {chat.isOutgoing ? chat.userFullName ?? "" : "You"}
          </span>
          {chat.attachmentUrls && (
            <div className="flex flex-col gap-4 my-1">
              {chat.attachmentUrls.map((url, url_index) => (
                <div
                  className="hover:opacity-80 cursor-pointer"
                  key={url_index}
                  onClick={() =>
                    set_view_files({
                      show: true,
                      index: find_file_index(url),
                    })
                  }
                >
                  {file_type(url) === "image" ? (
                    <img src={url} className="max-h-20 md:max-h-44" />
                  ) : file_type(url) === "video" ? (
                    <div className="object-contain transition duration-100 ease-in-out">
                      <ReactPlayer
                        controls
                        url={url}
                        width="100%"
                        height="100%"
                      />
                    </div>
                  ) : file_type(url) === "audio" ? (
                    <ReactAudioPlayer src={url} controls />
                  ) : (
                    <a
                      onClick={(e) => e.stopPropagation()}
                      href={url ?? "#"}
                      target="_blank"
                    >
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="cursor-pointer max-h-20 md:max-h-44 overflow-auto underline hover:text-blue-500"
                      >
                        Open to view file
                      </div>
                    </a>
                  )}
                </div>
              ))}
            </div>
          )}

          {chat.channel === "EMAIL" && chat.subject && (
            <span className="mt-2 mb-1 text-sm opacity-90">
              Subject : {chat.subject ?? ""}
            </span>
          )}

          {chat.channel === "CALL" && chat.duration && (
            <div
              className={
                "mt-4 mb-2 flex flex-row gap-2 items-center whitespace-pre text-gray-600 " +
                (chat.isOutgoing ? "text-white" : "text-black")
              }
            >
              <PhoneIcon className={"h-4 w-4 mr-1"} />
              <span>Duration : </span>
              <span className="relative top-px">
                {time_format(chat.duration) ?? ""}
              </span>
            </div>
          )}

          <span className="break-words whitespace-pre-wrap text-xs md:text-sm">
            <aify>{chat.content ?? ""}</aify>
          </span>
          <div className="mt-5 whitespace-pre-wrap">
            {chat.htmlContent &&
              parse(
                chat.htmlContent.replaceAll(`\\\"`, "").replaceAll("\\n", "\n")
              )}
          </div>
          <div
            style={{ fontSize: "13px" }}
            className="w-full opacity-30 flex flex-row items-center justify-between gap-4"
          >
            <div
              className={
                "w-full flex " +
                (chat.isOutgoing
                  ? outgoing_chat_container_className
                  : incoming_chat_container_className)
              }
            >
              {chat.channel ?? ""} {date_format(chat.createdAt)}
              {", "}
              {new Date(chat.createdAt).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
            {chat.ticketId && <div> #{chat.ticketId}</div>}
          </div>
        </div>
        {chat.isOutgoing &&
          (chat.userProfilePic ? (
            <img
              className="h-8 w-8 rounded-full object-cover"
              src={
                chat.userProfilePic ||
                (chat.isOutgoing && client_data.profile_pic)
              }
              alt="profile"
            />
          ) : (
            <div
              className={
                "h-8 w-8 flex items-center justify-center text-lg font-bold shadow rounded-full " +
                (chat.isOutgoing
                  ? "bg-indigo-700 text-white"
                  : "bg-white text-indigo-600")
              }
            >
              {chat.userFullName
                ? chat.userFullName.trim().charAt(0)
                : client_data.name?.trim().charAt(0)}
            </div>
          ))}
      </div>
    );
  };
  return (
    <div
      id="chat_area"
      className="flex flex-col bg-white p-2 w-full flex-grow overflow-auto max-h-screen"
      onScroll={(e) => {
        if (
          chats?.length !== 0 &&
          !load_chat_params.all_chat_loaded &&
          e.target.scrollTop === 0
        ) {
          set_dont_scroll(true);
          call_next_page();
        }
      }}
    >
      {" "}
      {view_files.show && (
        <ImgPopup
          view={view_files.show ?? false}
          setView={() => {
            set_view_files({ show: false });
          }}
          files={viewFiles()}
          file_index={view_files.index}
          onChange={(val) => set_view_files({ ...view_files, index: val })}
          container_className=""
        />
      )}
      {(load_chat_params.loading_chat || load_chat_params.all_chat_loaded) && (
        <div className="w-full p-2 flex justify-center text-center text-sm">
          {load_chat_params.all_chat_loaded && (
            <div className={"px-2 py-1 rounded text-white bg-red-500"}>
              End of chat
            </div>
          )}
          {load_chat_params.loading_chat && (
            <div
              className={
                "h-10 w-10 p-2 bg-white text-blue-300 rounded-full flex items-center justify-center"
              }
            >
              <LoadingCircleIcon2 className="h-8" />
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col gap-5 w-full">
        {chats?.map((chat, chat_index) => chat_bubble(chat, chat_index))}
      </div>
    </div>
  );
}
