import React from "react";
import { date_format } from "../../../../../utils/dateFormat";
import { PrinterIcon } from "../../../../common/AppIcons";

const status_options = {
  COMPLETED: "Completed",
  REVISIT_REQUIRED: "Revisit Required",
  CANCELLED: "Cancelled",
  CREATED: "Created",
  SCHEDULED: "Scheduled",
};

export default function SRSmallDetail({ open_pdf, sr_data }) {
  return (
    <div
      className={
        "col-span-2 flex flex-col gap-6 " +
        (open_pdf ? "w-1/3 flex justify-center" : "")
      }
    >
      {[
        {
          label: "Job type",
          value:
            (sr_data?.jobType?.charAt(0) ?? "") +
            (sr_data?.jobType?.slice(1)?.toLowerCase() ?? ""),
        },
        {
          label: "Prefered date and time",
          value: sr_data.preferredDateTime
            ? date_format(sr_data.preferredDateTime) +
              " " +
              new Date(sr_data.preferredDateTime).toLocaleTimeString()
            : "",
        },
        {
          label: "Duration",
          value: sr_data.duration ? sr_data.duration + " hr" : "",
        },
        {
          label: "Description",
          value: sr_data.description ?? "",
        },
        {
          label: "Unit number",
          value: sr_data.unitNumber ?? "",
        },
        {
          label: "Booking status",
          value: status_options[sr_data.bookingStatus] ?? "",
        },
      ].map((ele, ele_index) => (
        <div
          key={ele_index}
          className={"flex flex-col gap-1 " + (open_pdf ? "text-sm" : "")}
        >
          <span className="capitalize text-gray-800">{ele.label}</span>
          <span
            style={{ minHeight: "40px" }}
            className="p-2 bg-zinc-50 w-full text-gray-600"
          >
            <span
              className={"text-sm " + (open_pdf ? "relative bottom-2" : "")}
            >
              {ele.value}
            </span>
          </span>
        </div>
      ))}
    </div>
  );
}
