import { UploadIcon } from "@heroicons/react/solid";
import React, { useRef } from "react";
import {
  LoadingAnimated,
  LoadingCircleIcon,
} from "../../../../../common/AppIcons";
import Modal from "../../../../../common/Modal";

export default function UploadDocumentPopup({
  uploadDocument,
  setUploadDocument,
  loading,
  upload_document,
}) {
  const fileInput = useRef(null);
  return (
    <Modal
      show={uploadDocument?.view || loading}
      setShow={(_) => setUploadDocument(null)}
    >
      {uploadDocument?.view && !loading ? (
        <div className="p-4 flex flex-col items-start">
          <label className="block text-lg mb-3 font-medium text-gray-700">
            Select File to Upload
          </label>
          <div className="mt-1">
            <div className="flex flex-row items-center ml-1 gap-4">
              <button
                onClick={() => {
                  fileInput?.current.click();
                }}
                className="px-2 py-1 bg-green-600 hover:bg-green-700 rounded-lg text-white flex flex-row gap-2"
              >
                <UploadIcon className="h-4 mr-1" />
                Select File
              </button>
              <span className="text-gray-700">
                {uploadDocument?.file?.name}
              </span>
            </div>
            <input
              name="photo"
              ref={fileInput}
              className="hidden"
              type="file"
              onChange={(event) => {
                //   URL.createObjectURL(event.target.files[0])
                if (event.target.files && event.target.files[0]) {
                  setUploadDocument({
                    ...uploadDocument,
                    file: event.target.files[0],
                  });
                }
              }}
            />
          </div>
          <button
            onClick={upload_document}
            className="relative mt-4 flex items-center justify-center h-8 w-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Upload
          </button>
        </div>
      ) : (
        <LoadingCircleIcon className="h-10 mx-auto my-5" />
      )}
    </Modal>
  );
}
