import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getDocuments, uploadCreateRevision } from "../../../../../api/ApiV2";
import { complianceDocumentsType } from "../../../../../common/constants";
import CreateDocumentPopup from "./components/CreateDocumentPopup";
import DocumentList from "./components/DocumentList";
import UploadDocumentPopup from "./components/UploadDocumentPopup";

export default function Compliance({ hoaId, nextPage }) {
  const [documents, set_documents] = useState();
  const [uploadDocument, setUploadDocument] = useState({
    view: false,
    file: null,
    documentId: null,
  });
  const [createDocument, setCreateDocument] = useState(null);
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    get_documents();
  }, []);

  const get_documents = () => {
    getDocuments(hoaId, "ALL")
      .then((res) => {
        set_loading(false);
        console.log(res);
        set_documents(res?.results ?? []);
      })
      .catch((res) => {
        set_loading(false);
        console.log(res);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error fetching documents"
        );
      });
  };

  const upload_document = () => {
    set_loading(true);
    let formData = new FormData();
    formData.append(uploadDocument.file?.name, uploadDocument.file);
    for (var [key, value] of formData.entries()) {
      console.log(key, value);
    }
    uploadCreateRevision({
      hoaId: hoaId,
      documentId: uploadDocument.documentId,
      data: { document: uploadDocument.file },
    })
      .then((res) => {
        console.log(res);
        set_loading(false);
        setUploadDocument();
        get_documents();
      })
      .catch((res) => {
        console.log(res);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error uploading document"
        );
        set_loading(false);
        setUploadDocument();
      });
  };

  return (
    <div className="w-full ">
      <div className="bg-white rounded-md shadow-md mt-10 sm:px-16 pt-10 pb-10 flex flex-col items-center">
        <h1 className="text-center text-2xl font-semibold mb-5 sm:mb-8 text-gray-800">
          Compliance Documents
        </h1>

        <div className="w-full divide-y">
          {complianceDocumentsType
            ?.filter((ele) => ele.value !== "ARCHITECTURAL_REVIEW")
            .map((documentType, index) => (
              <DocumentList
                key={index}
                {...{
                  hoaId,
                  documents,
                  documentType,
                  setUploadDocument,
                  setCreateDocument,
                }}
              />
            ))}
        </div>

        <div className="w-full flex flex-row items-center justify-end gap-3 px-3 mt-7">
          {/* <button
            className="text-sm text-gray-500 hover:text-gray-600 border-2 border-gray-500 hover:border-gray-600 font-semibold px-8 py-2 rounded"
            onClick={nextPage}
          >
            Skip
          </button> */}
          <button
            style={{
              backgroundColor: "#2A32FD",
              border: "2px solid #2A32FD",
            }}
            className="text-sm text-white hover:opacity-80 px-8 py-2 rounded"
            onClick={nextPage}
          >
            Continue
          </button>
        </div>
      </div>
      <UploadDocumentPopup
        {...{ uploadDocument, setUploadDocument, loading, upload_document }}
      />
      <CreateDocumentPopup
        {...{
          hoaId,
          createDocument,
          setCreateDocument,
          get_documents,
        }}
      />
    </div>
  );
}
