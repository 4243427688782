import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import {
  CalenderIcon,
  CalenderIcon2,
  LoadingCircleIcon,
} from "../../../../common/AppIcons";
import SelectHeadings from "../../../../common/Form/SelectHeadings";
import EventList from "./EventList";
import Popup from "../../../../common/Popup";
import AcceptPopup from "./AcceptPopup";
import RejectPopup from "./RejectPopup";
import { respondToEventRequest, getEvents } from "../../../../../api/ApiV2";
import Paginator from "../../../../../common/Paginator";
import { userAtom } from "../../../../../store/UserAtom";
import { useRecoilState } from "recoil";

export default function EventListSection({
  hoaId,
  events_type_show,
  set_events_type_show,
  set_page,
  members,
}) {
  const scroll_ref = useRef(null);
  const [user] = useRecoilState(userAtom);

  const [events, set_events] = useState([]);
  const [loading, set_loading] = useState(false);
  const [acceptRejectPopup, set_acceptRejectPopup] = useState({
    // ACCEPT, REJECT
    popup: false,
    id: null,
    reason: "",
  });
  const [page, setPage] = useState({
    offset: 0,
    totalCount: 0,
  });

  useEffect(() => {
    get_events();
  }, [user.selectedUserProfile]);

  const get_events = (offset) => {
    set_loading(true);
    getEvents({
      hoaId,
      offset: offset ?? page.offset,
    })
      .then((res) => {
        setPage((page) => ({ ...page, totalCount: res.count }));
        set_events(res.results);
        console.log(res);
        set_loading(false);
        setTimeout(() => {
          scroll_ref?.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
      })
      .catch((res) => {
        toast.error(res.readableMessage ?? res.message ?? "Error");
        console.log(res);
        set_loading(false);
      });
  };

  const acceptRejectPost = () => {
    set_acceptRejectPopup((acceptRejectPopup) => {
      return { ...acceptRejectPopup, loading: true };
    });
    respondToEventRequest({
      hoaId,
      eventId: acceptRejectPopup?.id,
      data: {
        is_approved: acceptRejectPopup?.popup === "ACCEPT",
        reason_for_rejection: acceptRejectPopup?.reason,
      },
    })
      .then((res) => {
        console.log(res);
        set_acceptRejectPopup();
        get_events();
      })
      .catch((res) => {
        toast.error(
          res.readableMessage ?? res.message ?? res?.detail ?? "Error"
        );
        console.log(res);
        set_acceptRejectPopup((acceptRejectPopup) => {
          return { ...acceptRejectPopup, loading: false };
        });
      });
  };

  return (
    <div className="antialiased sans-serif">
      <div className="">
        <div ref={scroll_ref} className="bg-white rounded-lg w-fit min-w-full">
          <div className="mt-1 flex flex-col md:flex-row gap-6 md:items-center text-xs md:text-base justify-between border-b px-2 md:px-6 mb-4">
            <SelectHeadings
              headings={[
                {
                  label: "Requested",
                  count:
                    events?.filter((val) => val?.status === "PENDING")
                      ?.length ?? 0,
                  value: "PENDING",
                },
                {
                  label: "Approved",
                  count:
                    events?.filter((val) => val?.status === "APPROVED")
                      ?.length ?? 0,
                  value: "APPROVED",
                },
                {
                  label: "Rejected",
                  count:
                    events?.filter((val) => val?.status === "REJECTED")
                      ?.length ?? 0,
                  value: "REJECTED",
                },
              ].map((ele, ele_index) => {
                return {
                  value: ele.value,
                  label: (
                    <span
                      key={ele_index}
                      className={"text-sm font-semibold md:whitespace-pre "}
                      style={{ lineHeight: "1.2em" }}
                    >
                      <span
                        className="relative break-words bottom-px"
                        style={{ lineHeight: "1.2em" }}
                      >
                        {ele.label} Events
                      </span>
                      <span className="text-4xl mr-1 ml-2 relative bottom-1">
                        .
                      </span>
                      <span className="relative bottom-px"> {ele.count}</span>
                    </span>
                  ),
                };
              })}
              selected={events_type_show}
              onChange={(val) => {
                set_events_type_show(val);
              }}
              base_heading_width={180}
              selected_className="text-indigo-600"
              className="relative top-1"
            />
            <div className="flex flex-row justify-end items-center gap-2">
              <button
                onClick={() => {
                  set_page("CALENDER");
                }}
                className="px-3 py-1 text-sm items-center whitespace-pre rounded hover:opacity-90 text-white"
                style={{ backgroundColor: "#2A32FD" }}
              >
                <div className="flex flex-row gap-2 items-center pb-px">
                  <CalenderIcon2 className="h-4" />
                  View Calender
                </div>
              </button>
            </div>
          </div>

          {loading ? (
            <div className="w-full flex justify-center py-10">
              <LoadingCircleIcon className="h-12 text-blue-400" />
            </div>
          ) : (
            <EventList
              {...{ events_type_show, events, members, set_acceptRejectPopup }}
            />
          )}
          <div className="w-full mt-5">
            <Paginator
              currentPage={page.offset / 40 + 1}
              totalPage={page.totalCount}
              entryPerPage={40}
              onChange={(pageNo) => {
                setPage({ ...page, offset: (pageNo - 1) * 40 });
                getEvents((pageNo - 1) * 40);
              }}
            />
          </div>
        </div>
      </div>
      <Popup
        show={acceptRejectPopup?.popup ? true : false}
        setShow={() => set_acceptRejectPopup()}
        className="max-w-xl"
      >
        {acceptRejectPopup?.loading ? (
          <LoadingCircleIcon className="h-9 my-3 text-blue-500 mx-auto" />
        ) : acceptRejectPopup?.popup === "ACCEPT" ? (
          <AcceptPopup
            set_show={set_acceptRejectPopup}
            api_call={acceptRejectPost}
          />
        ) : acceptRejectPopup?.popup === "REJECT" ? (
          <RejectPopup
            data={acceptRejectPopup}
            set_show={set_acceptRejectPopup}
            api_call={acceptRejectPost}
          />
        ) : null}
      </Popup>
    </div>
  );
}
