import { Link, navigate } from "raviger";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { toast } from "react-toastify";

import { getHoas, getOrg } from "../../../api/ApiV2";
import { userAtom } from "../../../store/UserAtom";
import OrgPage from "./components/OrgPage";
import { LoadingAnimated } from "../../common/AppIcons";
import SwipeableScreens from "../../common/SwipableScreens";
import SelectHeadings from "../../common/Form/SelectHeadings";

export default function HoaList() {
  const [orgs, setOrgs] = useState([]);
  const [currentOrg, setCurrentOrg] = useState(0);
  const [rendereds, setRenderers] = useState(false);
  const [loading, set_loading] = useState(true);
  const setUser = useSetRecoilState(userAtom);

  useEffect(() => {
    set_loading(true);
    getOrg({ limit: 300 })
      .then((res) => {
        setOrgs(res.results);

        if (res?.results?.length > 0) {
          set_loading(false);
          setCurrentOrg(0);
        } else {
          getHoas({})
            .then((res) => {
              set_loading(false);
              console.log(res);
              if (res?.results?.length === 0) {
                navigate("/hoa/create");
              }
            })
            .catch((res) => {
              toast.error(res.message ?? "Error fetching properties");
              console.log(res);
              set_loading(false);
            });
        }
      })
      .catch((res) => {
        toast.error(res.message ?? "Error fetching organisation details");
        set_loading(false);
      });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("selectedUserProfile");
    setUser((prev) => ({
      ...prev,
      selectedUserProfile: null,
      selectedUserType: null,
    }));
  }, []);

  return (
    <div className="w-full min-h-screen p-5 sm:p-10 rounded-md shadow-lg bg-white">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            All Properties
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <Link
            href="/hoa/create"
            className="px-5 py-2 text-sm items-center whitespace-pre rounded hover:opacity-80 text-white"
            style={{ backgroundColor: "#2A32FD" }}
          >
            <div className="flex flex-row gap-3 items-center font-semibold pb-px">
              <svg
                className="h-4 relative top-px"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.58759 0.583344C11.7033 0.583344 13.1249 2.06851 13.1249 4.27876V9.42959C13.1249 11.6398 11.7033 13.125 9.58759 13.125H4.12059C2.00484 13.125 0.583252 11.6398 0.583252 9.42959V4.27876C0.583252 2.06851 2.00484 0.583344 4.12059 0.583344H9.58759ZM9.58759 1.45834H4.12059C2.50359 1.45834 1.45825 2.56493 1.45825 4.27876V9.42959C1.45825 11.1434 2.50359 12.25 4.12059 12.25H9.58759C11.2052 12.25 12.2499 11.1434 12.2499 9.42959V4.27876C12.2499 2.56493 11.2052 1.45834 9.58759 1.45834ZM6.85409 4.27427C7.09559 4.27427 7.29159 4.47027 7.29159 4.71177V6.41084L8.99288 6.41096C9.23438 6.41096 9.43038 6.60696 9.43038 6.84846C9.43038 7.08996 9.23438 7.28596 8.99288 7.28596L7.29159 7.28584V8.98585C7.29159 9.22735 7.09559 9.42335 6.85409 9.42335C6.61259 9.42335 6.41659 9.22735 6.41659 8.98585V7.28584L4.71529 7.28596C4.47321 7.28596 4.27779 7.08996 4.27779 6.84846C4.27779 6.60696 4.47321 6.41096 4.71529 6.41096L6.41659 6.41084V4.71177C6.41659 4.47027 6.61259 4.27427 6.85409 4.27427Z"
                  fill="white"
                />
              </svg>
              New Property
            </div>
          </Link>
        </div>
      </div>

      {loading ? (
        <div className="w-full flex justify-center pt-32 pb-20">
          <LoadingAnimated className="h-12" />
        </div>
      ) : (
        <div className="mt-8">
          <SelectHeadings
            headings={[
              ...orgs?.map((val, index) => {
                return { label: val.name, value: index };
              }),
              {
                label: "Personal",
                value: (orgs ?? []).length === 0 ? 0 : (orgs ?? []).length,
              },
            ]}
            selected={currentOrg}
            onChange={(val) => {
              setCurrentOrg(val);
            }}
            className="overflow-x-auto"
            label_className="md:text-xl font-semibold"
          />

          <SwipeableScreens
            rerenderers={[orgs, currentOrg, rendereds]}
            currentSlide={currentOrg + 1}
          >
            {[...(orgs ?? []), { id: null }]?.map((org, orgIndex) => (
              <OrgPage
                key={orgIndex}
                orgId={org.id}
                setRenderers={setRenderers}
              />
            ))}
          </SwipeableScreens>
        </div>
      )}
    </div>
  );
}
