import React from "react";
import {
  JpgPageIcon,
  PdfPageIcon,
  PngPageIcon,
} from "../../../common/AppIcons";

const fields = [
  {
    label: "Name of Contractor",
    value: "contractor_name",
  },
  {
    label: "Planned Start Date",
    value: "planned_start_date",
    type: "date",
  },
  {
    label: "Planned End Date",
    value: "planned_end_date",
    type: "date",
  },
  {
    label: "Actual Start",
    value: "actual_start_date",
    type: "date",
  },
  {
    label: "Actual Completion",
    value: "actual_completion_date",
    type: "date",
  },
  {
    label: "Final Inspection Date",
    value: "final_inspection_date",
    type: "date",
  },
];
export default function ProjectStatus({
  data,
  set_data,
  warning,
  remove_warning,
  read_only,
}) {
  return (
    <div className="mt-12 pl-10 flex flex-col gap-5">
      {fields.map((field, field_index) => (
        <div
          key={field_index}
          className="grid grid-cols-10 gap-4 items-center max-w-xl"
        >
          <span className="col-span-3">{field.label}</span>
          <span className="text-center">:</span>
          <div className="col-span-6 flex flex-col justify-end items-end gap-px relative bottom-1">
            <span
              className={
                "text-xs text-red-600 " +
                (warning[field.value] ? "" : "invisible")
              }
            >
              {warning[field.value] ?? "error"}
            </span>
            <input
              readOnly={read_only}
              type={field.type ?? ""}
              value={data[field.value]}
              onChange={(e) => {
                let val = e.target.value;
                remove_warning(field.value);
                set_data((data) => {
                  return { ...data, [field.value]: val };
                });
              }}
              className="w-full text-gray-800 rounded bg-gray-200 border-none outline-none px-3 py-2 focus:outline-none focus:bg-gray-300"
            />
          </div>
        </div>
      ))}
    </div>
  );
}
