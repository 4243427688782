import { formatPhoneNumber } from "react-phone-number-input";
import { DeleteIcon, ThreeDotsIcon } from "../../../../../common/AppIcons";
import { userType } from "../../../../../../common/constants";

export default function UserRow({ user, error, index, openPopup, setHoaData }) {
  return (
    <div className="w-full flex flex-row gap-2.5 items-center">
      {error && (
        <span className="text-xs font-semibold text-red-600">!Error</span>
      )}
      <div
        onClick={() => {
          openPopup({ ...user, index });
        }}
        className="flex-grow flex flex-row items-center gap-2 bg-gray-100 hover:bg-gray-200 cursor-pointer rounded-md px-3 py-2.5"
      >
        <div className="flex-grow text-sm grid grid-cols-11 items-center">
          <div className="col-span-3 flex flex-row gap-2 items-center">
            <div className="h-6 w-6 uppercase flex flex-row items-center justify-center rounded-full bg-indigo-600 text-white font-semibold">
              {user?.user?.first_name?.charAt(0) ?? ""}
              {user?.user?.last_name?.charAt(0) ?? ""}
            </div>
            <span className="">
              {user?.user?.first_name ?? ""} {user?.user?.last_name ?? ""}
            </span>
          </div>
          <div className="flex items-center col-span-2">
            <div className="p-1.5 w-28 text-center text-indigo-600 bg-indigo-100 rounded capitalize text-sm">
              {userType[user.user_type]?.label}
            </div>
          </div>
          <span className="text-center col-span-3">{user?.user.email}</span>
          <span className="text-center col-span-3">
            {formatPhoneNumber(user?.user.phone) == ""
              ? user?.user.phone
              : formatPhoneNumber(user?.user.phone)}
          </span>
        </div>
        <button className="text-gray-800 hover:text-gray-400">
          <ThreeDotsIcon className="h-4" />
        </button>
      </div>
      <button
        disabled={user.id ? true : false}
        onClick={() => {
          setHoaData((clientData) => ({
            ...clientData,
            members: clientData.members?.filter(
              (val, index1) => index !== index1
            ),
          }));
        }}
        className={
          "text-red-500 hover:text-red-300 " + (user.id ? "invisible" : "")
        }
      >
        <DeleteIcon className="h-4" />
      </button>
    </div>
  );
}
