import { useState, useEffect, useRef } from "react";

import { LoadingCircleIcon } from "../../../../common/AppIcons";
import {
  bulkPostUnits,
  getHoa,
  getOrg,
  memberAddressUnitsDetails,
  patchBuilding,
  patchHoa,
  postBuilding,
  postHoa,
} from "../../../../../api/ApiV2";
import HoaForm from "./components/HoaForm";
import { toast } from "react-toastify";

export default function HoaDetails({
  hoaData,
  setHoaData,
  previousPage,
  nextPage,
  saveHoaCreateStage,
  clientData,
}) {
  const [warnings, setWarnings] = useState({
    name: "",
    street_address_1: "",
    city: "",
    zipcode: "",
  });
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const [orgs, setOrgs] = useState();
  const [onlyOrg, setOnlyOrg] = useState();

  useEffect(() => {
    if (clientData.id) {
      setHoaData((hoaData) => {
        return {
          ...hoaData,
          details: {
            ...(hoaData.details ?? {}),
            managed_by: clientData.id,
          },
        };
      });
      setOnlyOrg(true);
    } else
      getOrg({ all: "True" })
        .then((res) => {
          setOrgs(res.results);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  useEffect(() => {
    !hoaData.hoaId &&
      setHoaData((hoaData) => {
        return {
          ...hoaData,
          details: {
            name: "",
            street_address_1: "",
            city: "",
            zipcode: "",
            state: "CA",
            building_type: null,
            territory: "",
          },
        };
      });

    componentRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, []);

  const delete_warning = (value) => {
    let temp_warning = warnings;
    delete temp_warning[value];
    setWarnings(temp_warning);
  };

  const findWarnings = () => {
    let warning = {};
    if (hoaData?.details.name === "")
      warning = { ...warning, name: "Please Fill this Field" };
    if (hoaData?.details.street_address_1 === "")
      warning = { ...warning, street_address_1: "Please Fill this Field" };
    if (hoaData?.details.city === "")
      warning = { ...warning, city: "Please Fill this Field" };
    if (hoaData?.details.zipcode === "")
      warning = { ...warning, zipcode: "Please Fill this Field" };
    if (hoaData?.details.zipcode?.length > 5)
      warning = {
        ...warning,
        zipcode: "Zipcode can't have more than 5 digits",
      };
    if (
      Number(hoaData?.details.zipcode) < 90001 ||
      Number(hoaData?.details.zipcode) > 96162
    )
      warning = { ...warning, zipcode: "Invalid Zipcode" };
    if (!hoaData?.details?.state || !hoaData?.details?.territory)
      warning = { ...warning, zipcode: "Invalid area zipcode" };
    if (hoaData?.details.state === "")
      warning = { ...warning, state: "Please select a state" };

    setWarnings(warning);
    return warning;
  };

  const submitHoa = () => {
    let tempWarnings = findWarnings();
    if (loading) return;
    if (Object.keys(tempWarnings).length === 0) {
      setLoading(true);

      const apiCall = hoaData.details.existing ? patchHoa : postHoa;
      apiCall({
        external_id: hoaData.hoaId,
        data: {
          ...hoaData.details,
          type: hoaData.type,
        },
      })
        .then((res) => {
          getUserProfiles(res.id);
          setHoaData((hoaData) => {
            return {
              ...hoaData,
              hoaId: res.id,
              existing: true,
            };
          });
        })
        .catch((res) => {
          setWarnings(res);
          toast.error(res?.message ?? "Error in creating hoa");
          console.log(res);
          setLoading(false);
        });
    }
  };

  const submitAddress = (id) => {
    if (hoaData.details?.melissaBaseMak === hoaData.details?.melissa_base_mak) {
      setLoading(false);
      nextPage();
      return;
    }
    const apiCall = hoaData.buildings?.[0]?.id ? patchBuilding : postBuilding;

    apiCall({
      hoaId: id,
      buildingId: hoaData.buildings?.[0]?.id,
      data: {
        street_address_1: hoaData.details.street_address_1,
        melissa_base_mak: hoaData.details.melissa_base_mak,
      },
    })
      .then((res) => {
        console.log(res);

        bulkPostUnits({
          hoaId: id,
          data: {
            units: [
              {
                unit_number: hoaData.details.street_address_1,
                address: res.id,
              },
            ],
          },
        })
          .then((res) => {
            setLoading(false);
            nextPage();

            console.log(res);
          })
          .catch((res) => {
            setLoading(false);
            console.log(res);
            toast.error("Error in creating units");
          });
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
        toast.error("Error in creating building");
      });
  };

  const getUserProfiles = (id) => {
    memberAddressUnitsDetails({ hoaId: id })
      .then((res) => {
        console.log(res);
        res?.[0]?.id &&
          sessionStorage.setItem("selectedUserProfile", res[0]?.id);
        setTimeout(() => {
          saveHoaCreateStage("BUILDING_DETAILS", id);
          if (hoaData.type === "SINGLE_FAMILY") {
            submitAddress(id);
          } else {
            setLoading(false);
            nextPage();
          }
        }, 1000);
      })
      .catch((res) => {
        console.log(res);
        saveHoaCreateStage("BUILDING_DETAILS", id);
        setLoading(false);
        nextPage();
      });
  };

  if (loading)
    return (
      <div className="py-10 m-auto ">
        <LoadingCircleIcon className="h-4" />
      </div>
    );
  return (
    <div
      ref={componentRef}
      className="max-w-4xl w-full flex-grow sm:mx-16 bg-white rounded-md shadow-md mt-12 pt-10 pb-24"
    >
      <div className="h-full w-full m-auto px-4 flex flex-col items-center text-gray-800 max-w-2xl">
        <div className="w-full flex flex-col divide-y">
          <HoaForm
            {...{
              singleFamily: hoaData.type === "SINGLE_FAMILY",
              form: hoaData.details,
              setHoaDetail: (value) =>
                setHoaData((hoaData) => {
                  return {
                    ...hoaData,
                    details: value,
                  };
                }),
              warnings,
              setWarnings,
              delete_warning,
              orgs,
              onlyOrg,
            }}
          />
        </div>

        <div className="w-full mt-10 flex flex-row justify-end items-center gap-3">
          <button
            onClick={previousPage}
            className="text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 h-10 w-28 rounded"
          >
            Go Back
          </button>

          <button
            style={{
              backgroundColor: "#2A32FD",
              border: "2px solid #2A32FD",
            }}
            className="text-sm text-white hover:opacity-80 h-10 w-28 flex items-center justify-center rounded"
            onClick={() => {
              submitHoa();
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
