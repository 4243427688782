import { useState, useEffect, useRef } from "react";

import { getAccounts, postVendor, patchVendor } from "../../../../api/ApiV2";
import { LoadingAnimated } from "../../../common/AppIcons";
import { fieldData } from "./data/fieldData";
import InputField from "./components/InputField";
import NewAccountPopup from "../NewAccountPopup";

export default function CreateVendor({ hoaId, closePopup, className, data }) {
  const [editVendor, setEditVendor] = useState({});
  const [expenseAccounts, setExpenseAccounts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [warning, set_warning] = useState({});
  const [new_account_popup, set_new_account_popup] = useState(false);
  const parentRef = useRef(null);

  const delete_warning = (item) => {
    let temp_warning = warning;

    delete temp_warning[item];

    set_warning(temp_warning);
  };

  useEffect(() => {
    data && setEditVendor(data);
  }, [data]);

  useEffect((_) => {
    fetchAccounts();
  }, []);

  const fetchAccounts = () => {
    getAccounts({ hoaId, type: "EXPENSE" })
      .then((res) => setExpenseAccounts(res.results))
      .catch(console.log);
  };

  const onSubmit = () => {
    let tempWarning = {};

    if (!editVendor.display_name)
      tempWarning = { ...tempWarning, display_name: ["Fill this field"] };

    if (!editVendor.terms)
      tempWarning = { ...tempWarning, terms: ["Fill this field"] };

    set_warning(tempWarning);

    if (loading || Object.keys(tempWarning).length > 0) return;
    if (Object.keys(warning)?.length === 0) {
      setLoading(true);
      editVendor.id
        ? patchVendor({ hoaId, vendorId: editVendor.id, data: editVendor })
            .then((res) => {
              console.log({ res });
              setLoading(false);
              setEditVendor({});
              closePopup && closePopup();
            })
            .catch((res) => {
              setLoading(false);
              set_warning(res);
            })
        : postVendor({ hoaId, data: editVendor })
            .then((res) => {
              console.log({ res });
              setLoading(false);
              setEditVendor({});
              closePopup && closePopup();
            })
            .catch((res) => {
              setLoading(false);
              set_warning(res);
            });
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={
        "w-full flex flex-col gap-4 items-center justify-between overflow-auto " +
        className
      }
      ref={parentRef}
    >
      <div className="mt-2 w-full grid sm:grid-cols-2 gap-4 px-2 items-start">
        {fieldData(expenseAccounts)?.map((value, index) => {
          return (
            <InputField
              key={index}
              value={value}
              warning={warning}
              delete_warning={delete_warning}
              editVendor={editVendor}
              setEditVendor={setEditVendor}
              parentRef={parentRef}
              set_new_account_popup={set_new_account_popup}
            />
          );
        })}
      </div>
      <div className="w-full flex flex-row-reverse mt-2">
        <div className="flex flex-row gap-3">
          <button
            className="focus:outline-none font-medium border-2 border-gray-500 text-gray-600 px-7 py-1 rounded hover:opacity-60 duration-200"
            onClick={() => {
              if (closePopup) closePopup();
            }}
          >
            Cancel
          </button>
          <button
            onClick={(_) => onSubmit()}
            className={
              "w-40 py-1 text-white rounded font-medium flex items-center justify-center duration-200 hover:opacity-70"
            }
            style={{ backgroundColor: "#2A32FD" }}
          >
            {loading ? (
              <LoadingAnimated className="h-5 text-white" />
            ) : editVendor.id ? (
              "Update Vendor"
            ) : (
              "Create Vendor"
            )}
          </button>
        </div>
      </div>
      <div
        className={
          "absolute inset-0 bg-white bg-opacity-60 z-40 flex items-center justify-center " +
          (new_account_popup ? "" : "hidden")
        }
      >
        <NewAccountPopup
          hoaId={hoaId}
          closePopup={(_) => {
            set_new_account_popup(false);
            fetchAccounts();
          }}
          account_status={{ type: "EXPENSE" }}
        />
      </div>
    </div>
  );
}
