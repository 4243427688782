import React from "react";

import { amountFormat } from "../../../../../../common/AmountFormat";

export default function List({ heading, data, total, total_style }) {
  return (
    <div className="bg-white rounded-md">
      <h2
        style={{ color: "#2A32FD" }}
        className="sm:text-xl font-semibold sm:font-bold mt-4 px-5"
      >
        {heading}
      </h2>
      <div className="mt-2 align-middle min-w-full overflow-x-auto overflow-hidden rounded-b-md">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-right text-sm font-semibold text-gray-500 uppercase tracking-wider">
                Balance
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200" x-max="1">
            {data?.length === 0 ? (
              <tr>
                <td
                  colSpan="6"
                  className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500"
                >
                  No accounts exist
                </td>
              </tr>
            ) : (
              data?.map((income, index) => (
                <tr className="bg-white hover:bg-gray-100" key={index}>
                  <td className="min-w-max px-6 py-2 whitespace-nowrap text-gray-900">
                    <span className="text-gray-800 font-semibold">
                      {income.name}
                    </span>
                  </td>
                  <td className="px-6 py-3 text-right whitespace-nowrap text-gray-500">
                    <span className="text-gray-800 font-semibold">
                      ${amountFormat(income.balance)}
                    </span>
                  </td>
                </tr>
              ))
            )}
            <tr className={"bg-gray-500"} style={total_style}>
              <td className="min-w-max w-full px-6 py-2 whitespace-nowrap text-gray-900">
                <span className="text-white font-semibold">
                  Total {heading}
                </span>
              </td>
              <td className="px-6 py-2 text-right whitespace-nowrap text-gray-500">
                <span className="text-white font-semibold">
                  ${amountFormat(total)}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
