import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import {
  getAccounts,
  getStripeSetup,
  updateStripeSetup,
} from "../../../../../api/ApiV2";
import Input from "../../../../common/Form/Input";
import Modal from "../../../../common/Modal";
import { accountsDropdownOptions } from "../../../common/data/accountsDropdownOptions";
import NewAccountPopup from "../../../components/NewAccountPopup";

import { inputFieldData } from "./data/inputFieldData";

export default function StripeSetup({ hoaId }) {
  const [data, setData] = useState({});
  const [info, setInfo] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [newAccountPopup, setNewAccountPopup] = useState(false);
  const parentRef = useRef(null);

  useEffect(() => {
    let temp_info = info;
    setTimeout(() => {
      setInfo((info) => (info === temp_info ? "" : info));
    }, 5000);
  }, [info]);

  useEffect(() => {
    getAccounts({ hoaId: hoaId, page_size: 300 })
      .then((res) => {
        setAccounts(res.results);
      })
      .catch(console.log);

    getStripeSetup({ hoaId })
      .then((res) => {
        setData(res);
      })
      .catch((res) => console.log(res));
  }, [hoaId]);

  const submitData = () => {
    setInfo("Saving...");
    updateStripeSetup({ hoaId, data })
      .then((res) => {
        setInfo("Saved successfully");
      })
      .catch((res) =>
        toast.error(res?.readableMessage ?? res?.message ?? "Error Saving Data")
      );
  };

  return (
    <div ref={parentRef} className="bg-white shadow py-10 px-12 rounded-lg">
      <h1 className="text-xl font-bold" style={{ color: "#2A32FD" }}>
        Stripe Setup
      </h1>
      <span className="mt-3 text-sm text-gray-600">
        Enter your Stripe and Plaid API keys to recieve Home Owner Payments via
        Stripe
      </span>

      <div className="mt-9 flex flex-col gap-7">
        {inputFieldData({
          dropdown_options: [
            {
              label: "Create New Account",
              value: "OPEN_NEW_ACCOUNT_POPUP",
            },
            ...(accountsDropdownOptions(accounts) ?? []),
          ],
        })?.map((field, field_index) => (
          <div className="flex flex-col gap-1" key={field_index}>
            <span className="text-sm">{field.label}</span>
            <Input
              {...field}
              parentRef={parentRef}
              value={data[field.name]}
              className="w-full bg-gray-100 focus:bg-gray-200 rounded px-3 py-2 focus:outline-none"
              onChange={(value) => {
                if (field.type === "dropdown") {
                  if (value === "OPEN_NEW_ACCOUNT_POPUP")
                    setNewAccountPopup(true);
                  else setData((data) => ({ ...data, bank_account: value }));
                } else setData((data) => ({ ...data, [field.name]: value }));
              }}
            />
          </div>
        ))}
      </div>

      <div className="flex flex-row items-center gap-3 my-5">
        <button
          style={{
            backgroundColor: "#2A32FD",
            border: "solid 2px #2A32FD",
          }}
          className="px-6 py-1 rounded shadow-sm text-white hover:opacity-70 focus:outline-none"
          onClick={(_) => submitData()}
        >
          Save
        </button>
        <span className="text-sm text-green-500">{info}</span>
      </div>

      <Modal
        show={newAccountPopup}
        setShow={() => {
          setNewAccountPopup(false);
        }}
      >
        <NewAccountPopup
          hoaId={hoaId}
          closePopup={() => {
            setNewAccountPopup(false);
          }}
        />
      </Modal>
    </div>
  );
}
