import { Link, navigate } from "raviger";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useUserActions } from "../../../actions/UserActions";
import { toast } from "react-toastify";

import { userAtom } from "../../../store/UserAtom";
import {
  CloseEyeIcon,
  LoadingCircleIcon,
  OpenEyeIcon,
} from "../../common/AppIcons";
import FillerImage from "../common/FillerImage";

export default function Login() {
  const [data, set_data] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [warning, set_warning] = useState({});
  const [renderer, set_renderer] = useState(false);
  const [user] = useRecoilState(userAtom);

  const { login } = useUserActions();

  useEffect(() => {
    if (sessionStorage?.getItem("tip")) {
      toast.success(sessionStorage?.getItem("tip"));
      sessionStorage?.removeItem("tip");
    }
    let email = sessionStorage?.getItem("email");
    if (email) {
      set_data((data) => {
        return { ...data, email: email };
      });
      sessionStorage?.removeItem("email");
    }
  }, []);

  const checkFields = () => {
    let temp_warning = {};
    if (data?.email?.length < 5) {
      temp_warning = {
        ...temp_warning,
        email: "email must be at least 5 letters long.",
      };
    }
    if (data?.email === "" || !data?.email) {
      temp_warning = { ...temp_warning, email: "Please fill this field." };
    }

    if (data?.password === "" || !data?.password) {
      temp_warning = { ...temp_warning, password: "Please fill this field." };
    }
    if (Object.keys(temp_warning).length > 0) {
      set_warning(temp_warning);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (user.variant === "loggingIn") return;
    if (checkFields()) {
      login(data.email, data.password);
    }
  };

  const delete_warning = (field) => {
    let temp_warning = warning;

    delete temp_warning[field];

    set_warning(temp_warning);
    set_renderer((renderer) => !renderer);
  };

  return (
    <div className="w-full h-full">
      <div className="h-full min-h-screen w-full flex flex-row items-center lg:grid lg:grid-cols-11">
        <FillerImage className="lg:col-span-5 hidden lg:block" />
        <div className="lg:col-span-6 p-8 h-full w-full flex-grow flex flex-col items-center justify-center text-left">
          <div className="flex flex-col items-start max-w-md w-full lg:mb-20">
            <Link href="/">
              <img
                className="w-20 object-cover"
                alt=""
                src="/logo_register.png"
              />
            </Link>
            <h1
              style={{ color: "#211C52", fontSize: "22px" }}
              className="mt-5 title-font font-semibold"
            >
              Hi, Welcome back to Doorz!
            </h1>

            <form
              noValidate
              onSubmit={(e) => e.preventDefault()}
              className="mt-4 flex w-full flex-col items-start gap-3"
            >
              <div className="w-full">
                <div className="flex flex-row items-center justify-between gap-4">
                  <label htmlFor="hero-field" className="leading-7 text-sm">
                    Email id
                  </label>
                  <span className="text-sm text-red-600">{warning?.email}</span>
                </div>
                <input
                  placeholder="Email"
                  value={data?.email}
                  onChange={(e) => {
                    let value = e.target.value;
                    delete_warning("email");
                    set_data((data) => {
                      return { ...data, email: value };
                    });
                  }}
                  required
                  className="w-full border border-gray-200 rounded outline-none focus:outline-none text-gray-700 py-2 px-4"
                />
              </div>
              <div className="w-full">
                <div className="flex flex-row items-center justify-between gap-4">
                  <label htmlFor="hero-field" className="leading-7 text-sm">
                    Password
                  </label>
                  <span className="text-sm text-red-600">
                    {warning?.password}
                  </span>
                </div>

                <div className="flex flex-row items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="Password"
                    name="Password"
                    placeholder="Password"
                    value={data?.password}
                    onChange={(e) => {
                      let value = e.target.value;
                      delete_warning("password");
                      set_data((data) => {
                        return { ...data, password: value };
                      });
                    }}
                    required
                    className="w-full border border-gray-200 rounded outline-none focus:outline-none text-gray-700 py-2 px-4"
                  />
                  <div
                    className="w-0 overflow-visible relative right-8"
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword === false ? (
                      <OpenEyeIcon className="text-gray-600 w-5 cursor-pointer" />
                    ) : (
                      <CloseEyeIcon className="text-gray-600 w-5 cursor-pointer" />
                    )}
                  </div>
                </div>
              </div>

              <div className="text-sm py-1 w-full flex flex-row flex-wrap items-center justify-end gap-4">
                <Link
                  href="/accounts/password/reset/"
                  style={{ color: "#2A32FD" }}
                  className="hover:opacity-60 hover:underline"
                >
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                style={{
                  background:
                    "linear-gradient(89.97deg, #00D2FF 0.03%, #2A32FD 99.97%)",
                }}
                className="mt-3 w-full h-9 text-white border-0 py-2 px-6 focus:outline-none hover:opacity-70 rounded text-sm flex items-center justify-center"
              >
                {user.variant === "loggingIn" ? (
                  <LoadingCircleIcon className="h-4 text-white" />
                ) : (
                  "Sign in"
                )}
              </button>
            </form>
            <div className="mt-5 flex flex-row items-center gap-2 text-sm">
              <span style={{ color: "#030229" }}>Don’t have an account?</span>
              <Link
                href="/register"
                style={{ color: "#2A32FD" }}
                className="hover:opacity-60 hover:underline"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
