import React from "react";
import SelectableItem from "./components/SelectableItem";
import {
  sidebar_buttons,
  sidebar_heading,
  sidebar_options_buttons,
} from "./data/sidebarData";

export default function Sidebar({ hoaId, page, set_sidebar_open }) {
  return (
    <div
      style={{ boxShadow: "2px 0px 3px rgb(220,220,220)" }}
      className="bg-white h-full pt-5 pb-4 overflow-y-auto flex flex-col"
    >
      {/* heading */}
      <div className="flex items-center px-2 py-2 text-base font-medium rounded-md">
        <div
          style={{ fontWeight: "700", fontSize: "17px", color: "#2A32FD" }}
          className="ml-5 flex items-center flex-shrink-0 cursor-default font-bold"
        >
          {sidebar_heading.label}
        </div>
      </div>
      {/* sidebar buttons */}
      <div className="mt-5 flex flex-col gap-1">
        {sidebar_buttons.map((button, button_index) => (
          <SelectableItem
            key={button_index}
            sidebar_data={button}
            page={page}
            hoaId={hoaId}
            set_sidebar_open={set_sidebar_open}
          />
        ))}
      </div>

      {/* horizontal line */}
      <div className="px-3">
        <div className="h-px w-full bg-gray-300 rounded mt-8 lg:mt-12" />
      </div>

      <div className="mt-7 flex flex-col gap-1">
        {sidebar_options_buttons.map((button, button_index) => (
          <SelectableItem
            key={button_index}
            sidebar_data={button}
            page={page}
            hoaId={hoaId}
            set_sidebar_open={set_sidebar_open}
          />
        ))}
      </div>
    </div>
  );
}
