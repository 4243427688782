import React from "react";
import { CrossCicleIcon } from "../../common/AppIcons";

export default function Failed({}) {
  return (
    <div className="flex flex-col gap-5 items-center">
      <div className="mt-6 mb-4 p-9 bg-red-100 rounded-full">
        <CrossCicleIcon style={{ color: "#EF2929" }} className="h-20 w-20" />
      </div>
      <div className="flex flex-col text-center gap-2">
        <span className="text-2xl font-semibold text-gray-700">
          Payment Setup Failed
        </span>
        <span className="mt-2 text-sm text-gray-600">
          Uh oh! It looks like Payment Setup didn’t work. Don’t panic, it
          happens! Please try again later.
        </span>
      </div>
    </div>
  );
}
