import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../../../store/UserAtom";
import Messages from "./components/Messages";
import NamePlate from "./components/NamePlate";
import Sections from "./components/Sections";
import { getHoa, getNotices } from "../../../api/ApiV2";
import { LoadingCircleIcon2 } from "../../common/AppIcons";

export default function HoaHome({ hoaId }) {
  const [data, set_data] = useState({});
  const [loading, setLoading] = useState(false);
  const [user] = useRecoilState(userAtom);
  const [messages, set_messages] = useState();

  useEffect(() => {
    if (user.selectedUserProfile) {
      get_notices();
      get_hoa();
    }
  }, [user.selectedUserProfile]);

  const get_notices = () => {
    getNotices({ hoaId })
      .then((res) => {
        console.log(res);
        set_messages(res.results);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const get_hoa = () => {
    setLoading(true);
    getHoa({ external_id: hoaId })
      .then((res) => {
        setLoading(false);
        console.log(res);
        set_data(res);
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
      });
  };

  return (
    <div className="flex flex-row flex-wrap gap-7 w-full">
      <div className="flex flex-col gap-4 max-w-3xl w-full">
        <NamePlate
          label={user.firstName + " " + user.lastName}
          hoaId={hoaId}
          messages={messages?.length ?? 0}
          notifications={4}
          compliance_uploads={15}
        />
        {loading ? (
          <LoadingCircleIcon2 className="my-20 h-10 text-blue-500" />
        ) : (
          <Sections hoaId={hoaId} hoaEnabledModules={data?.enabled_features} />
        )}
      </div>
      <Messages hoaId={hoaId} messages={messages} />
    </div>
  );
}
