import React from "react";
import {
  AddBillIcon,
  PayBillIcon,
  CreateAccountIcon,
  AddVendorIcon,
  AddRevenueIcon,
  CreateCheckIcon,
  ViewCheckIcon,
  JournalEntryIcon,
  ReconcileIcon,
  ProfitLossReportIcon,
  RecordsDepositesIcon,
  ReceivePaymentsIcon,
} from "../../../../../../common/AppIcons";
import { getUserData } from "../../../../../../../utils/DOMUtils";
import { navigate } from "raviger";

export const shortcutItems = ({ hoaId }) => [
  {
    title: "Create Account",
    icon: (iconClassName) => (
      <CreateAccountIcon
        className={iconClassName}
        style={{ color: "#342E6D" }}
      />
    ),
    name: "CREATE_ACCOUNT",
  },
  {
    title: "Add Vendor",
    icon: (iconClassName) => (
      <AddVendorIcon className={iconClassName} style={{ color: "#DDA007" }} />
    ),
    name: "ADD_VENDOR",
  },
  {
    title: "Add Revenue",
    icon: (iconClassName) => (
      <AddRevenueIcon className={iconClassName} style={{ color: "#FF8F6B" }} />
    ),
    disable: getUserData() === 5 || getUserData() === 3,
    name: "ADD_REVENUE",
  },

  {
    title: "Add Bill",
    icon: (iconClassName) => (
      <AddBillIcon
        className={iconClassName + " text-blue-500"}
        style={{ color: "" }}
      />
    ),
    name: "BILL",
  },
  {
    title: "Pay Bill",
    disable: getUserData() === 5,
    icon: (iconClassName) => (
      <PayBillIcon className={iconClassName} style={{ color: "#5A38FD" }} />
    ),
    name: "BANK",
  },

  {
    title: "Create Check",
    icon: (iconClassName) => (
      <CreateCheckIcon className={iconClassName} style={{ color: "#37B403" }} />
    ),
    name: "CREATE_CHECK",
  },
  {
    title: "View Checks",
    icon: (iconClassName) => (
      <ViewCheckIcon
        className={iconClassName + " text-indigo-600"}
        style={{ color: "" }}
      />
    ),
    onClick: () => {
      navigate(`/hoa/${hoaId}/transactions/CHECK`);
    },
  },
  {
    title: "Journal Entry",
    icon: (iconClassName) => (
      <JournalEntryIcon
        className={iconClassName + " text-green-600"}
        style={{ color: "" }}
      />
    ),
    onClick: () => {
      navigate(`/hoa/${hoaId}/accounts/journal_entry`);
    },
  },
  {
    title: "Reconcile",
    icon: (iconClassName) => (
      <ReconcileIcon className={iconClassName} style={{ color: "#FF8F6B" }} />
    ),
    onClick: () => {
      navigate(`/hoa/${hoaId}/bankaccounts/`);
    },
  },
  {
    title: "Profit & Loss Report",
    icon: (iconClassName) => (
      <ProfitLossReportIcon
        className={iconClassName}
        style={{ color: "#32BCF1" }}
      />
    ),
    onClick: () => {
      navigate(`/hoa/${hoaId}/accounts/profit_loss`);
    },
  },
  {
    title: "Receive Payments",
    icon: (iconClassName) => (
      <ReceivePaymentsIcon
        className={iconClassName}
        style={{ color: "#FC5D62" }}
      />
    ),
    name: "",
  },
  {
    title: "Record Deposits",
    icon: (iconClassName) => (
      <RecordsDepositesIcon
        className={iconClassName}
        style={{ color: "#30323D" }}
      />
    ),
    name: "",
  },
];
