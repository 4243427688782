import { dateStringFormat } from "../../../../../../utils/DateUtils";
import { amountFormat } from "../../../../../common/AmountFormat";

export const search_transactions_page_data = (value) => {
  return {
    heading: "Transaction Search Results for '" + value + "'",
    table: {
      className: "grid-cols-5",
      headings: [
        { label: "Description", className: "col-span-2" },
        { label: "Type", className: "" },
        { label: "Date", className: "" },
        { label: "Amount", className: "justify-end" },
      ],
      rows: [
        {
          label: value?.description,
          className: "col-span-2",
        },
        {
          label:
            (value?.type ?? "GENERIC").charAt(0) +
            (value?.type ?? "GENERIC").slice(1).toLowerCase(),
        },
        {
          label: dateStringFormat(value?.created_date),
        },
        {
          label:
            "$" +
            amountFormat(
              value?.legs?.reduce(
                (amount, leg) =>
                  leg.amount > 0 ? amount + Number(leg.amount) : amount,
                0
              ) ?? 0
            ),
          className: "justify-end",
        },
      ],
    },
  };
};
