import React from "react";
import Input from "../../../../common/Form/Input";

export default function FormInput({ label, warning, ...props }) {
  return (
    <div
      className={
        "w-full flex flex-col items-start " +
        (warning ? "relative md:bottom-1" : "")
      }
    >
      {warning && <span className="text-xs text-red-600">{warning}</span>}
      <Input {...props} />
    </div>
  );
}
