import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";

import { getAccounts } from "../../api/ApiV2";
import SelectHeadings from "../common/Form/SelectHeadings";
import PaymentSection from "./PaymentSection";
import { amountFormat } from "../common/AmountFormat";
import { userAtom } from "../../store/UserAtom";
import { adminTypes } from "../../common/data/adminTypes";

export default function PaymentMethod({ hoaId }) {
  const [user] = useRecoilState(userAtom);
  const setUser = useSetRecoilState(userAtom);

  const userPrivilage = adminTypes.includes(user.selectedUserType);

  const [accounts, set_accounts] = useState();
  const [selectedTab, setSelectedTab] = useState("HELP_SQUAD");
  const [loading, set_loading] = useState(false);
  const [plaid_data_user, set_plaid_data_user] = useState();
  const [plaid_data_hoa, set_plaid_data_hoa] = useState();
  const [plaid_data_helpsquad, set_plaid_data_helpsquad] = useState();
  const [payment_method_popup, set_payment_method_popup] = useState({
    view: true,
    popup: "PAY_OPTIONS",
    // PAY_OPTIONS, ACCOUNTS, ACH_PAY, CARD_PAY, PROCESSING, REQUIRES_ACTION, SUCCESS,  FAILED
    data: {},
  });
  const [success_message, set_success_message] = useState("");
  const payButtonRef = useRef(null);

  useEffect(() => {
    if (userPrivilage) setSelectedTab("HELP_SQUAD");
  }, [userPrivilage]);

  useEffect(() => {
    if (success_message) {
      let temp_success_message = success_message;
      setTimeout(() => {
        if (success_message === temp_success_message) set_success_message();
      }, 5000);
    }
  }, [success_message]);

  // useEffect(() => {
  //   set_loading(true);
  //   plaidData({ hoaId: hoaId, receiver: "hoa" })
  //     .then((res) => {
  //       console.log("plaidData user response:", res);
  //       set_plaid_data_user(res);
  //       set_loading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       set_loading(false);
  //       toast.error(res?.readableMessage ?? res?.message ?? "Error fetching plaid data");
  //     });
  // }, []);

  // useEffect(() => {
  //   set_loading(true);
  //   plaidData({
  //     hoaId: hoaId,
  //     receiver: "helpsquad",
  //   })
  //     .then((res) => {
  //       console.log("plaidData helpsquad response:", res);
  //       set_plaid_data_helpsquad(res);
  //       set_loading(false);
  //     })
  //     .catch((res) => {
  //       console.log(res);
  //       set_loading(false);
  // toast.error(res?.readableMessage ?? res?.message ?? "Error fetching plaid data");
  //
  //     });
  // }, []);

  // useEffect(() => {
  //   if (
  //     (userPrivilage) &&
  //     payment_method_popup.popup === "ACH_PAY"
  //   ) {
  //     set_loading(true);
  //     plaidDataSR({
  //       hoaId: hoaId,
  //       body: { bank_account_id: payment_method_popup.data.account_id },
  //     })
  //       .then((res) => {
  //         console.log("plaidData hoa response:", res);
  //         set_plaid_data_hoa(res);
  //       })
  //       .catch((res) => {
  //         console.log(res);
  // toast.error(res?.readableMessage ?? res?.message ?? "Error fetching plaid data");
  //
  //       });
  //   }
  // }, [payment_method_popup.popup]);

  useEffect(() => {
    if (userPrivilage && payment_method_popup.popup === "ACH_PAY") {
      setTimeout(() => {
        set_loading(false);
        payButtonRef?.current.click();
      }, 1000);
    }
  }, [plaid_data_hoa]);

  useEffect((_) => {
    fetch_accounts();
  }, []);

  const fetch_accounts = () => {
    getAccounts({ hoaId: hoaId, bank_account: "True" })
      .then((res) => {
        console.log("accounts:", res);
        set_accounts(res.results);
      })
      .catch((res) => {
        console.log(res);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error fetching accounts"
        );
      });
  };

  const headings = () => {
    let temp_heading = [];

    if (userPrivilage)
      temp_heading = [{ label: "Help Squad", value: "HELP_SQUAD" }];
    else
      temp_heading = [
        { label: "Help Squad", value: "HELP_SQUAD" },
        { label: "HOA", value: "HOA" },
      ];
    return temp_heading.map((tab) => {
      return {
        label: tab.label,
        value: tab.value,
      };
    });
  };

  return (
    <div className="w-full">
      {loading && (
        <div className="fixed flex items-center justify-center z-30 inset-0 bg-opacity-80 bg-white">
          <div
            style={{ borderTopColor: "transparent" }}
            className="border-solid animate-spin rounded-full border-blue-400 border-4 h-12 w-12"
          ></div>
        </div>
      )}

      <div className="bg-white p-4 sm:px-8 sm:py-10 border-b border-gray-200 rounded-md">
        <div className="relative sm:pb-0">
          <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center sm:justify-between">
            <div className="flex flex-row items-center">
              <h1
                style={{ color: "#2A32FD", fontSize: "22px" }}
                className="leading-6 font-semibold"
              >
                Payment Methods
              </h1>
            </div>

            <span
              style={{ color: "#2A32FD" }}
              className={
                "px-3 py-1 text-base rounded duration-200 text-center bg-white shadow hover:shadow-md cursor-default"
              }
            >
              {userPrivilage ? "HOA" : "Owner"}
            </span>
          </div>

          <h2
            style={{ fontSize: "18px" }}
            className="mt-6 leading-6 font-semibold text-blue-900"
          >
            Setup a New Payment Method
          </h2>
          <div className="px-4 mt-5 border-b border-gray-200">
            <div className="flex flex-col-reverse items-start md:items-center md:flex-row gap-3 md:justify-between">
              <SelectHeadings
                gap={20}
                headings={headings()}
                selected={selectedTab}
                onChange={(val) => {
                  setSelectedTab(val);
                }}
                width_array={[130, 180, 225, 120]}
                className="relative top-1 flex flex-grow w-full"
              />
            </div>
          </div>

          <div className="w-full my-1 flex items-center justify-center">
            <PaymentSection
              selectedTab={selectedTab}
              hoaId={hoaId}
              payment_method_popup={payment_method_popup}
              set_payment_method_popup={set_payment_method_popup}
              base_plaid_data_user={plaid_data_user}
              plaid_data_hoa={plaid_data_hoa}
              plaid_data_helpsquad={plaid_data_helpsquad}
              accounts={accounts}
              update_data={() => {}}
              amountFormat={amountFormat}
              set_loading={set_loading}
              payButtonRef={payButtonRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
