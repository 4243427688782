import React, { useState } from "react";
import Comment from "./Comment";
import { commentOnDocument } from "../../../../api/Api";
import { LoadingAnimated, SentIcon } from "../../../common/AppIcons";

export default function CommentSection({
  comments,
  comments_to_display,
  hoaId,
  documentId,
  get_document_data,
  show_comment_box,
  show_reply_input,
}) {
  const [comment_text, set_comment_text] = useState("");
  const [loading, set_loading] = useState(false);
  const [reply, set_reply] = useState("");

  const sent_comment = () => {
    if (comment_text !== "") {
      set_loading(true);
      commentOnDocument(hoaId, documentId, {
        title: "Test",
        description: comment_text,
        comment_type: "COMMENT",
      })
        .then((_) => {
          get_document_data();
          set_comment_text("");
          set_loading(false);
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
        });
    }
  };

  const sent_reply = () => {
    if (reply !== "") {
      set_loading(true);

      commentOnDocument(hoaId, documentId, {
        title: "Test",
        description: reply,
        object_id: comments_to_display[0]?.object_id,
        comment_type: "COMMENT",
      })
        .then((_) => {
          get_document_data();
          set_reply("");
          set_loading(false);
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
        });
    }
  };

  return (
    <div className="flex flex-col">
      {show_comment_box && (
        <div className="p-2 flex flex-col gap-2 rounded">
          <input
            value={comment_text}
            onKeyDown={(e) => e.key === "Enter" && sent_comment()}
            onChange={(e) => set_comment_text(e.target.value)}
            className="w-full p-2 bg-gray-100 text-sm border-b border-blue-300 focus:border-blue-500 focus:outline-none"
            placeholder="Type your comment..."
          />
          <div className="flex justify-end">
            <button
              onClick={sent_comment}
              className="focus:outline-none rounded h-7 w-28 text-sm bg-blue-500 hover:bg-blue-600 text-white font-semibold"
            >
              {loading ? (
                <LoadingAnimated className="w-5 h-5 ml-1" />
              ) : (
                "Post Comment"
              )}
            </button>
          </div>
        </div>
      )}
      <div className="flex flex-col divide-y">
        {comments_to_display
          ?.sort(
            (obj1, obj2) =>
              new Date(obj1.created_at) - new Date(obj2.created_at)
          )
          .map((comment, comment_index) => (
            <Comment
              key={comment_index}
              hoaId={hoaId}
              documentId={documentId}
              comment={comment}
              comments={comments}
              get_document_data={get_document_data}
            />
          ))}
      </div>
      {show_reply_input && (
        <div
          className={"w-full mt-3 flex flex-row items-center gap-2 pl-8 pr-3"}
        >
          <input
            value={reply}
            onChange={(e) => set_reply(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && sent_reply()}
            placeholder="Reply..."
            className="w-full p-1 text-sm border-b border-blue-300 focus:border-blue-500 focus:outline-none bg-gray-100"
          />
          <div
            onClick={sent_reply}
            className={
              "mt-2 flex items-center justify-center cursor-pointer h-8 w-8 bg-gray-200 rounded-full text-blue-600 hover:text-blue-700 hover:bg-gray-300"
            }
          >
            {loading ? (
              <LoadingAnimated className="w-5 h-5 ml-1" />
            ) : (
              <SentIcon className="w-5 h-5 ml-1" />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
