import { Transition } from "@tailwindui/react";
import React, { useEffect, useRef, useState } from "react";
import { LeftArrowIcon, PlusIcon } from "../../../../common/AppIcons";

export default function DropdownMenu({
  set_popup,
  set_transaction_popup,
  className,
}) {
  const [showDropdownMenu, setDropdownMenu] = useState(false);
  const ref = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setDropdownMenu(false);
      }
    };
    document?.addEventListener("mousedown", handleClickOutside);
    return () => {
      document?.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} className={"flex flex-col relative " + className}>
      <button
        ref={ref}
        style={{
          color: "#2A32FD",
          border: "2px solid #2A32FD",
        }}
        className="bg-white hover:opacity-70 px-3 py-1 flex flex-row items-center gap-2 rounded"
        onClick={() => setDropdownMenu(!showDropdownMenu)}
      >
        <PlusIcon className="h-5" />
        <span className="font-semibold text-lg">Create New</span>
        <LeftArrowIcon className="transform -rotate-90 h-6" />
      </button>
      <Transition
        show={showDropdownMenu}
        className="absolute w-full z-10 rounded shadow-lg border-2 border-indigo-500"
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        style={{
          marginTop: ref?.current?.offsetHeight + 5,
        }}
      >
        {[
          {
            text: "Bill",
            onClick: (_) => {
              set_transaction_popup({ popup: true, type: "BILL" });
            },
          },

          {
            text: "Vendor",
            onClick: (_) => {
              set_popup({ type: "VENDOR" });
            },
          },
        ].map((item) => {
          return (
            <div
              key={item.text}
              className="w-full px-2 py-2 bg-white hover:bg-gray-200 cursor-pointer"
              onClick={(e) => {
                setDropdownMenu(false);
                item.onClick(e);
              }}
            >
              <span className="font-semibold opacity-80">{item.text}</span>
            </div>
          );
        })}
      </Transition>
    </div>
  );
}
