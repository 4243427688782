import React, { useState, useEffect } from "react";

import AmenityList from "./components/amenityList";
import ReservationList from "./components/reservationList";
import ReservationHistory from "./components/reservationList/components/ReservationHistory";

export default function Reservations({ hoaId }) {
  const [reservation_list, set_reservation_list] = useState(false);
  const [reservation_history, set_reservation_history] = useState();

  return (
    <div
      style={{ minHeight: "120px" }}
      className="bg-white rounded-md w-full max-w-full"
    >
      {reservation_history ? (
        <ReservationHistory
          hoaId={hoaId}
          amenity={reservation_history}
          closePage={() => set_reservation_history()}
        />
      ) : reservation_list ? (
        <ReservationList
          {...{ hoaId }}
          closePage={() => set_reservation_list(false)}
        />
      ) : (
        <AmenityList
          {...{
            hoaId,
            set_reservation_list,
            set_reservation_history,
          }}
        />
      )}
    </div>
  );
}
