import React from "react";
import { CrossCicleIcon, CrossIcon } from "../../common/AppIcons";

export default function PaymentFailed({
  payment_method_popup,
  set_payment_method_popup,
}) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-full flex flex-col items-center justify-start px-10 pt-7 pb-10 rounded-lg bg-white"
    >
      <div className="mt-6 mb-4 p-9 bg-red-100 rounded-full">
        <CrossCicleIcon style={{ color: "#EF2929" }} className="h-20 w-20" />
      </div>
      <span className="mt-5 text-xl font-bold">Payment Failed</span>
      <span className="mt-2 text-sm text-gray-500 max-w-sm text-center">
        Uh oh! It looks like your recent payment setup didn’t work. Don’t panic,
        it happens! Please try again later
      </span>
      <button
        onClick={() => {
          set_payment_method_popup({
            ...payment_method_popup,
            popup: "PAY_OPTIONS",
          });
        }}
        className={
          "mt-9 rounded px-10 py-2 bg-red-200 text-red-500 text-sm hover:opacity-80 font-semibold flex flex-row items-center justify-center gap-4"
        }
      >
        Close
      </button>
    </div>
  );
}
