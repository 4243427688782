import React from "react";
import { CrossIcon, MoneyInEnvalop } from "../../common/AppIcons";

export default function RequiresAction({
  payment_method_popup,
  set_payment_method_popup,
  not_popup,
}) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="max-w-lg w-full flex flex-col items-center justify-start px-10 pt-7 pb-10 rounded-lg bg-white"
    >
      {!not_popup && (
        <div className="flex w-full justify-end mb-4">
          <div
            onClick={() => {
              set_payment_method_popup({
                ...payment_method_popup,
                view: false,
                popup: "",
              });
            }}
            className="p-1 h-5 w-5 cursor-pointer rounded-full border-2 border-red-500 text-red-500 hover:opacity-70 flex items-center justify-center"
          >
            <CrossIcon className="h-3 w-3" />
          </div>
        </div>
      )}
      <MoneyInEnvalop className="h-28 w-28" />
      <span className="mt-5 text-xl font-bold">Micro-deposits initiated</span>
      <span className="text-sm text-gray-500 max-w-sm text-center">
        Expect a $0.01 deposit to your account in 1–2 business days and an email
        with additional instructions to verify your bank account in order to
        complete the payment setup.
      </span>
      <button
        onClick={() => {
          set_payment_method_popup({
            view: false,
          });
        }}
        className={
          "mt-9 rounded px-10 py-2 bg-indigo-100 text-indigo-500 text-sm hover:opacity-80 font-semibold flex flex-row items-center justify-center gap-4"
        }
      >
        Done
      </button>
    </div>
  );
}
