import React from "react";

export const sidebar_buttons = ({
  featuresComponent,
  integrationsComponent,
  pricingComponent,
}) => [
  {
    label: "Features",
    onClick: () => {
      featuresComponent?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  },
  // {
  //   label: "Pricing",
  //   onClick: () => {
  //     pricingComponent?.current?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //     });
  //   },
  // },
  {
    label: "Integrations",
    onClick: () => {
      integrationsComponent?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  },
  {
    label: "Log In",
    link: "/login",
    className:
      "px-4 py-1 focus:outline-none border border-gray-800 rounded duration-300 hover:border-white hover:text-white",
  },

  {
    label: "Sign Up",
    link: "/register",
    className:
      "px-4 py-1 focus:outline-none rounded duration-300 text-white border border-black bg-black hover:font-semibold",
  },
];
