const fields = [
  {
    label: "Submission Date",
    value: "submission_date",
    type: "date",
    readOnly: true,
  },
  {
    label: "Decision Date",
    value: "decision_date",
    type: "date",
    readOnly: true,
  },
  {
    label: "Deposit Amount",
    value: "deposit_amount",
  },
  {
    label: "Refund Amount",
    value: "refund_amount",
  },
];
export default function PlanStatus({
  data,
  read_only,
  set_data,
  warning,
  remove_warning,
}) {
  return (
    <div className="mt-12 pl-10 flex flex-col gap-5">
      {fields.map((field, field_index) => (
        <div
          key={field_index}
          className="grid grid-cols-10 gap-4 items-center max-w-xl"
        >
          <span className="col-span-3">{field.label}</span>
          <span className="text-center">:</span>
          <div className="col-span-6 flex flex-col justify-end items-end gap-px relative bottom-1">
            <span
              className={
                "text-xs text-red-600 " +
                (warning[field.value] ? "" : "invisible")
              }
            >
              {warning[field.value] ?? "no error"}
            </span>
            <input
              readOnly={read_only || field.readOnly}
              type={data[field.value] ? field.type ?? "" : ""}
              value={data[field.value]}
              onChange={(e) => {
                let val = e.target.value;
                remove_warning(field.value);
                set_data((data) => {
                  return { ...data, [field.value]: val };
                });
              }}
              className="w-full text-gray-800 rounded bg-gray-200 px-3 py-2 outline-none border-none focus:outline-none focus:bg-gray-300"
            />
          </div>
        </div>
      ))}
    </div>
  );
}
