import { Link } from "raviger";
import React, { useState, useEffect } from "react";
import { account_type } from "../../../../../common/constants";
import { getBaseData } from "../../../../../utils/DOMUtils";
import { BalanceIcon, LeftArrowIcon } from "../../../../common/AppIcons";
import { getAccounts } from "../../../../../api/ApiV2";
import { toast } from "react-toastify";
import { amountFormat } from "../../../../common/AmountFormat";

export default function TrialBalance({ hoaId }) {
  const [accounts, set_accounts] = useState([]);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = () => {
    getAccounts({ hoaId })
      .then((res) => {
        console.log(res);
        set_accounts(res.results);
      })
      .catch((res) => {
        console.log(res);
        toast.error(
          res.readableMessage ?? res.message ?? "Error fetching account data"
        );
      });
  };

  const account_type_label = (account) => {
    return account_type.find((val) => val.value === account)?.label;
  };

  const ordered_accounts = () => {
    let temp_accounts = [];

    for (let i = 0; i < account_type.length; i++) {
      temp_accounts = [
        ...temp_accounts,
        ...accounts?.filter(
          (account) => account.type === account_type[i].value
        ),
      ];
    }
    temp_accounts = [
      ...temp_accounts,
      ...accounts?.filter((account) => account.type === "RE"),
    ];

    return temp_accounts;
  };
  return (
    <div>
      <div className="mt-8 mb-8 sm:mb-12 flex flex-row items-center gap-2 px-4 sm:px-6 lg:px-8">
        <Link
          href={"/hoa/" + hoaId + "/accounts/reports"}
          className="realtive top-px"
        >
          <LeftArrowIcon className="h-5" />
        </Link>
        <h2 className="text-lg sm:text-xl leading-6 font-semibold text-gray-900">
          Trial Balance Report
        </h2>
      </div>

      <div className="shadow sm:hidden">
        <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
          {accounts?.map((account, account_index) => (
            <li>
              <Link
                href={
                  "/hoa/" + hoaId + "/accounts/" + account.id + "/transactions"
                }
                key={account_index}
                className="block px-4 py-4 bg-white hover:bg-gray-50"
              >
                <span className="flex items-center space-x-4">
                  <span className="flex-1 flex space-x-2 truncate">
                    <BalanceIcon className="flex-shrink-0 h-4 w-4 text-indigo-500 relative top-1" />
                    <span className="flex flex-col text-gray-700 text-sm truncate">
                      <span className="truncate">{account.name}</span>
                      <span className="text-gray-900 font-medium">
                        {"$" + amountFormat(account.current_amount)}
                      </span>
                    </span>
                    <svg
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      x-description="Heroicon name: chevron-right"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="hidden sm:block">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pb-6">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto sm:overflow-hidden">
              <table className="w-full divide-y divide-gray-200">
                <thead className="">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Account
                    </th>
                    <th className="px-6 py-3 text-right text-sm font-semibold text-gray-600 tracking-wider">
                      Type
                    </th>
                    <th className="px-6 py-3 text-right text-sm font-semibold text-gray-600 tracking-wider">
                      Debit
                    </th>
                    <th className="px-6 py-3 text-right text-sm font-semibold text-gray-600 tracking-wider">
                      Credit
                    </th>
                  </tr>
                </thead>

                <tbody
                  className="bg-white divide-y divide-gray-200 sm:rounded-lg shadow-md border"
                  x-max="1"
                >
                  {ordered_accounts()
                    ?.filter((val) => val.current_amount !== 0)
                    .map((account, account_index) => (
                      <tr key={account_index} className="bg-white">
                        <td className="whitespace-nowrap text-sm text-gray-900">
                          <Link
                            href={
                              "/hoa/" +
                              hoaId +
                              "/accounts/" +
                              account.id +
                              "/transactions"
                            }
                            target="_blank"
                          >
                            <div className="flex px-6 py-4">
                              <span className="group inline-flex space-x-2 truncate text-sm">
                                <BalanceIcon
                                  style={{ color: "#2A32FD" }}
                                  className="flex-shrink-0 h-4 w-4 relative top-1"
                                />
                                <span className="text-gray-800 truncate">
                                  {account.name}
                                </span>
                              </span>
                            </div>
                          </Link>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-800">
                          <span className="text-gray-700">
                            {account_type_label(account.type)}
                          </span>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-800">
                          <span className="text-gray-700">
                            {parseFloat(account.current_amount) < 0
                              ? "$0.00"
                              : "$" +
                                parseFloat(
                                  parseFloat(account.current_amount) ?? 0
                                ).toFixed(2)}
                          </span>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-800">
                          <span className="text-gray-700">
                            {parseFloat(account.current_amount) < 0
                              ? "$" +
                                (
                                  -1 * (parseFloat(account.current_amount) ?? 0)
                                ).toFixed(2)
                              : "$0.00"}
                          </span>
                        </td>
                      </tr>
                    ))}

                  <tr className="">
                    <th className="text-gray-800 text-left px-6 sm:px-12 py-3">
                      Total
                    </th>
                    <th></th>
                    <th className="px-6 py-3 text-gray-800 text-sm font-bold text-right whitespace-pre tracking-wider">
                      $
                      {Math.abs(
                        accounts?.reduce((total, val) => {
                          return (
                            total +
                            parseFloat(
                              val.current_amount > 0 ? val.current_amount : 0
                            )
                          );
                        }, 0)
                      ).toFixed(2)}
                    </th>
                    <th className="px-6 py-3 text-gray-800 text-sm font-bold text-right whitespace-pre tracking-wider">
                      $
                      {Math.abs(
                        accounts?.reduce((total, val) => {
                          return (
                            total +
                            parseFloat(
                              val.current_amount < 0 ? val.current_amount : 0
                            )
                          );
                        }, 0)
                      ).toFixed(2)}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
