export default function ConfirmDeletePopup({ property, onSucces, onClose }) {
  return (
    <div className="max-w-lg w-full mx-auto px-6 py-5 bg-white rounded-lg">
      <p>
        Delete Property <span className="font-semibold">{property?.label}</span>{" "}
        ?
      </p>
      <div className="mt-5 w-full flex flex-row-reverse items-center">
        <button
          onClick={onSucces}
          className="rounded-md bg-red-600 hover:bg-red-700 shadow w-24 flex items-center justify-center py-2 text-white font-semibold"
        >
          Yes
        </button>
        <button
          onClick={onClose}
          className="px-4 py-2 text-gray-700 hover:text-gray-900 focus:outline-none font-semibold"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
