export const category = [
  {
    label: "General",
    value: "GENERAL",
  },
  {
    label: "Vendor services",
    value: "VENDOR",
  },
  {
    label: "Management",
    value: "MANAGEMENT",
  },
];
