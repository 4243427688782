import React, { useState } from "react";
import { EditIcon2 } from "../../../../../../common/AppIcons";
import Input from "../../../../../../common/Form/Input";

import { amountFormat } from "../../../../../../common/utility/amountFormat";
import { accountingInputClassName } from "../../../../../common/data/inputClassName";

export default function Summary({ data }) {
  const [modify, set_modify] = useState(false);

  return (
    <div className="w-60 sm:w-72 flex flex-col">
      <div
        style={{ backgroundColor: "#F5F5F5" }}
        className="text-center w-full p-2 text-gray-500 font-bold border-b border-gray-400 flex flex-row items-center justify-between"
      >
        Summary
        <button
          onClick={() => set_modify((modify) => !modify)}
          className={
            "font-semibold focus:outline-none flex flex-row items-center gap-1 text-sm " +
            (modify
              ? "text-indigo-800 hover:text-indigo-400"
              : "hover:text-indigo-800 text-indigo-400")
          }
        >
          <EditIcon2 className="h-3" /> Modify
        </button>
      </div>
      {data?.map((ele, ele_index) => (
        <div
          key={ele_index}
          className="w-full p-2 text-gray-800 font-semibold flex flex-row items-center justify-between border-b border-gray-400"
        >
          <span>{ele.label}</span>
          <span>
            {modify && ele.onChange ? (
              <Input
                type="currency"
                className={
                  accountingInputClassName +
                  " focus:outline-none w-28 p-px pr-2 text-right"
                }
                value={ele.value}
                onChange={ele.onChange}
              />
            ) : (
              amountFormat(ele.value ? Number(ele.value) : 0)
            )}
          </span>
        </div>
      ))}
    </div>
  );
}
