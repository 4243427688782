import React from "react";

import Expense from "../../../accounting/pages/expense";

export default function ExpensesInvoicePage({
  hoaId,
  page,
  previousPage,
  nextPage,
}) {
  return (
    <div className="px-8 pt-8 pb-3 bg-white rounded-md shadow relative z-10">
      <Expense
        hoaId={hoaId}
        source="HOA_CREATION"
        prompt={page}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </div>
  );
}
