import { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { findState } from "../../../../../../utils/findState";
import { findTerritory } from "../../../../../../utils/findTerritory";
import { getMelissaAddress } from "../../../../../../api/ApiV2";
import Input from "../../../../../common/Form/Input";
import { DeleteIcon, LoadingCircleIcon } from "../../../../../common/AppIcons";
import { CheckCircleIcon } from "@heroicons/react/solid";

export default function PropertyForm({
  index,
  form,
  setHoaDetail,
  warnings,
  setWarnings,
  delete_warning,
  deleteProperty,
}) {
  const [address_hints, set_address_hints] = useState([]);
  const [last_address_keyword, set_last_address_keyword] = useState("");
  const [street_address_1_debounce] = useDebounce(form.street_address_1, 700);
  const [address_hint_loading, set_address_hint_loading] = useState(false);
  const cityRef = useRef(null);

  useEffect(() => {
    if (form.zipCode) {
      const newState = findState(form.zipCode);
      const territory = findTerritory(form.zipCode);
      console.log("Setting State to:", newState);
      console.log("Setting Territory to:", territory);
      setTimeout(() => {
        setWarnings((warnings) => {
          return {
            ...warnings,
            zipCode:
              !newState || !territory ? "Invalid area zipcode" : undefined,
          };
        });
      }, 500);
      setHoaDetail({ ...form, state: newState, territory: territory });
    }
  }, [form.zipCode]);

  // address hints

  useEffect(() => {
    if (
      form?.street_address_1?.split(" ")?.length > 0 &&
      form?.street_address_1?.charAt(form?.street_address_1.length - 1) === " "
    ) {
      get_address_hints(form?.street_address_1);
    }
  }, [form?.street_address_1]);

  useEffect(() => {
    if (
      form?.street_address_1 &&
      street_address_1_debounce === form?.street_address_1
    ) {
      get_address_hints(form?.street_address_1);
    }
  }, [form?.street_address_1, street_address_1_debounce]);

  const get_address_hints = (address) => {
    if (!address) return;

    if (address?.trim() !== "" && address?.trim() !== last_address_keyword) {
      set_last_address_keyword(address.trim());
      set_address_hint_loading(true);
      getMelissaAddress({ address })
        .then((res) => {
          console.log("address hints:", res);
          set_address_hints(
            res?.filter(
              (val) =>
                val?.Address?.SubBuilding && val?.Address?.SubBuilding !== ""
            )
          );
          set_address_hint_loading(false);
        })
        .catch((res) => {
          console.log("address hints error:" + res);
          set_address_hint_loading(false);
        });
    }
  };

  const addressDisplay = (address) => {
    let real_address = address?.Address ?? {};

    if (!real_address) return null;
    return (
      (real_address.Address1 ? real_address.Address1 + ", " : "") +
      (real_address.Locality || real_address.SubAdministrativeArea
        ? (real_address.Locality || real_address.SubAdministrativeArea) + ",  "
        : "") +
      (real_address.AdministrativeArea ?? "") +
      (real_address.PostalCodePrimary
        ? " " + real_address.PostalCodePrimary
        : "")
    );
  };

  return (
    <div className="mt-3 w-full">
      <div className="w-full flex flex-row items-end gap-2">
        <span className="p-1">{index + 1}.</span>
        <div className={"flex flex-col gap-1 w-full"}>
          <div className="w-full flex flex-row items-center justify-end gap-4">
            {form.updateStatus === "EDITED" ? (
              <div className="px-2 py-1 rounded bg-yellow-100">
                <span className="text-sm text-yellow-600">Unsaved!</span>{" "}
              </div>
            ) : form.updateStatus === "SAVED" ? (
              <div className="px-2 py-1 rounded bg-green-100 text-green-600 flex flex-row items-center gap-2">
                <CheckCircleIcon className="h-4" />{" "}
                <span className="text-sm">Saved</span>{" "}
              </div>
            ) : form.updateStatus === "LOADING" ? (
              <div className="p-2 rounded bg-blue-100">
                <LoadingCircleIcon className="h-5 text-blue-500" />{" "}
              </div>
            ) : (
              ""
            )}
          </div>
          <span className="text-red-600 text-xs">
            {warnings[form.id]?.["street_address_1"] ?? ""}
          </span>

          <div className="flex gap-3">
            <Input
              value={form.street_address_1}
              onChange={(val) => {
                delete_warning("street_address_1", form.id);

                setHoaDetail({
                  ...form,
                  street_address_1: val,
                });
              }}
              hint={{
                loading: address_hint_loading,
                hints: address_hints?.map((address) => {
                  return {
                    value: address?.Address,
                    label: addressDisplay(address),
                  };
                }),
                onClick: (val) => {
                  delete_warning("street_address_1", form.id);
                  delete_warning("city", form.id);
                  delete_warning("state", form.id);
                  delete_warning("zipCode", form.id);

                  let real_address = val;
                  console.log(real_address, form);
                  setHoaDetail({
                    ...form,
                    street_address_1: real_address?.Address1,
                    city:
                      real_address.Locality ||
                      real_address?.SubAdministrativeArea,
                    state: real_address?.AdministrativeArea,
                    zipCode: real_address?.PostalCodePrimary,
                    geocodeLatitude: real_address?.Latitude,
                    geocodeLongitude: real_address?.Longitude,
                    melissaBaseMak: real_address?.BaseMAK ?? real_address?.MAK,
                    units: real_address?.BaseMAK
                      ? {
                          units: real_address?.SubBuilding.split(",").filter(
                            (val) => val !== ""
                          ),
                          maks: real_address?.MAK.split(",").filter(
                            (val) => val !== ""
                          ),
                        }
                      : null,
                  });

                  set_address_hints();
                  set_last_address_keyword();

                  setTimeout(() => {
                    cityRef?.current?.focus();
                  }, 300);
                },
              }}
              wrapperClassName="flex-grow"
              className={
                "sm:w-full px-3 py-2 sm:tracking-wide text-gray-700 rounded focus:outline-none bg-gray-100 focus:bg-gray-200" +
                (warnings[form.id]?.["street_address_1"] &&
                warnings[form.id]?.["street_address_1"] !== ""
                  ? "border-red-300"
                  : "border-gray-300")
              }
            />
            {deleteProperty && (
              <button onClick={deleteProperty}>
                <DeleteIcon className="h-5 text-red-600 hover:text-red-700" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
