import { Link } from "raviger";
import React from "react";

export default function SelectableItem({ sidebar_data, page, hoaId }) {
  const is_page_open = () => {
    if (page === sidebar_data.link) return true;
    return sidebar_data.pages?.includes(page);
  };
  return (
    <Link
      href={
        page !== sidebar_data.link
          ? sidebar_data.link?.replaceAll(":hoaId", hoaId)
          : "#"
      }
      className={
        "truncate w-full rounded gap-3 flex items-center px-3 py-2 text-sm leading-6 " +
        (is_page_open()
          ? "bg-indigo-100 text-indigo-600"
          : "duration-200 hover:bg-indigo-100 hover:text-indigo-600")
      }
      aria-current="page"
    >
      <sidebar_data.icon
        style={{
          ...(is_page_open() ? { color: "#2A32FD" } : {}),
          height: "1.3rem",
          ...(sidebar_data.style ?? {}),
        }}
        className={"font-normal"}
      />

      {sidebar_data.label}
    </Link>
  );
}
