import { useEffect, useRef, useState } from "react";

import {
  LoadingCircleIcon,
  PlusInCircleIcon2,
} from "../../../../common/AppIcons";
import UserRow from "./components/UserRow";
import AddUserPopup from "./components/AddUserPopup";
import Popup from "../../../../common/Popup";
import { getOrgMembers, postOrgMembersBulk } from "../../../../../api/ApiV2";
import { toast } from "react-toastify";

export const orgUserTypes = [
  {
    label: "Super User",
    value: "SUPERUSER",
  },
  {
    label: "Manager",
    value: "MANAGER",
  },
  {
    label: "Owner",
    value: "OWNER",
  },
];
export default function AddUsers({
  clientData,
  setClientData,
  previousPage,
  nextPage,
}) {
  const [addUserPopup, setAddUserPopup] = useState();
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    console.log("sdcsd", clientData);
    if (!clientData?.id) return;
    setLoading(true);
    getOrgMembers({ id: clientData?.id })
      .then((res) => {
        setClientData((clientData) => ({
          ...clientData,
          users: res.results,
        }));
        setLoading(false);
      })
      .catch((res) => {
        toast.error(res.message ?? "Error fetching members");
        setLoading(false);
      });
  }, [clientData?.id]);

  const submitHoa = () => {
    postOrgMembersBulk({
      id: clientData?.id,
      data: {
        members: clientData?.users.filter((val) => !val.user?.id),
      },
    })
      .then((res) => {
        console.log(res);
        nextPage();
      })
      .catch((res) => {
        toast.error(res.message ?? "Error posting member details");
        console.log(res);

        let savedClients = clientData?.users.filter((val) => val.user?.id);
        setClientData((clientData) => ({
          ...clientData,
          users: [
            ...savedClients,
            ...clientData?.users
              .filter((val) => !val.user?.id)
              .map((val1, index) => {
                return {
                  ...val1,
                  warnings: {
                    ...(res[index]?.type ? { type: res[index]?.type } : {}),
                    ...(res[index]?.user ?? {}),
                  },
                };
              }),
          ],
        }));
      });
  };

  if (loading)
    return (
      <div className="py-10 m-auto ">
        <LoadingCircleIcon className="h-4" />
      </div>
    );
  return (
    <div
      ref={componentRef}
      className="max-w-4xl w-full flex-grow sm:mx-16 bg-white rounded-md shadow-md mt-12 pt-10 pb-24"
    >
      <div className="h-full w-full m-auto px-4 flex flex-col items-center text-gray-800 max-w-3xl">
        <div className="w-full flex flex-col">
          <span
            className="text-lg text-center w-full font-semibold"
            style={{ color: "#2A32FD" }}
          >
            Add Users
          </span>
        </div>
        <div className="w-full mt-6 flex flex-col gap-2.5">
          {clientData.users?.map((user, userIndex) => (
            <UserRow
              key={userIndex}
              error={Object.keys(user.warnings ?? {})?.length ? true : false}
              user={user}
              index={userIndex}
              openPopup={setAddUserPopup}
              setClientData={setClientData}
            />
          ))}
          <div>
            <button
              className="mt-3 text-sm text-gray-500 hover:opacity-70 flex flex-row items-center gap-2"
              onClick={() => {
                setAddUserPopup({});
              }}
            >
              <PlusInCircleIcon2 className="text-green-500 h-2.5" />
              Add New User
            </button>
          </div>
        </div>

        <div className="w-full mt-10 flex flex-row justify-end items-center gap-3">
          <button
            onClick={previousPage}
            className="text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 h-10 w-28 rounded"
          >
            Go Back
          </button>

          <button
            style={{
              backgroundColor: "#2A32FD",
              border: "2px solid #2A32FD",
            }}
            className="text-sm text-white hover:opacity-80 h-10 w-44 flex items-center justify-center rounded"
            onClick={() => {
              submitHoa();
            }}
          >
            Finish Account Setup
          </button>
        </div>
      </div>
      <Popup show={addUserPopup ? true : false} setShow={setAddUserPopup}>
        {addUserPopup && (
          <AddUserPopup
            {...{
              readonly: addUserPopup.id ? true : false,
              data: addUserPopup,
              memberIndex: addUserPopup?.index,
              warnings: addUserPopup?.warnings,
              setUserData: setClientData,
              closePopup: () => {
                setAddUserPopup();
              },
            }}
          />
        )}
      </Popup>
    </div>
  );
}
