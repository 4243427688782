import React from "react";

import { LoadingCircleIcon } from "../../../common/AppIcons";
import JournalEntry from "../../../accounting/pages/journalEntry";

export default function OpeningBalance({ hoaId, baseData, nextPage, loading }) {
  if (loading)
    return (
      <div className="w-full p-4 flex items-center justify-center">
        <LoadingCircleIcon className="h-8" />
      </div>
    );
  else
    return (
      <div className="mt-6 px-8 pt-8 pb-3 bg-white rounded-md shadow">
        {baseData ? (
          <JournalEntry
            source="OPENING_BALANCE"
            hoaId={hoaId}
            baseDataProp={baseData}
            className="relative z-10"
            baseJournalEntry={baseData.map((account) => {
              return {
                accountId: account.id,
                debit: "",
                credit: "",
                description: "",
              };
            })}
            title="Create Opening Balance Entry"
            description="Opening Balance Entry"
            successCB={nextPage}
          />
        ) : (
          <span className="mx-auto font-semibold text-gray-500">
            An error occured while fetching account data
          </span>
        )}
      </div>
    );
}
