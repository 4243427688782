import React, { useEffect, useReducer, useState } from "react";
import { LeftArrowIcon } from "../../../../common/AppIcons";
import SwipeableScreens from "../../../../common/SwipableScreens";

const removeTime = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

const initialState = removeTime(new Date()); // Today without date

const getDays = (calendarState) => {
  let arr = [];
  let weekDay = calendarState.getDay() === 0 ? 7 : calendarState.getDay();
  let date = new Date(calendarState);

  while (weekDay !== 1) {
    weekDay--;
    date.setDate(date.getDate() - 1);
  }
  for (weekDay; weekDay !== 0; date.setDate(date.getDate() + 1)) {
    arr.push(new Date(date));
    weekDay = date.getDay();
  }
  return arr;
};

const isToday = (date) => new Date().toDateString() === date.toDateString();

export default function DatePickerPerWeek({ className, value, onChange }) {
  const [state, set_state] = useState(initialState);
  const [animation_slide, set_animation_slide] = useState(2);
  const [reset_animation, set_reset_animation] = useState(false);

  const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  useEffect(() => {
    setTimeout(() => {
      if (animation_slide !== 2) set_reset_animation(true);
      else set_reset_animation(false);
    }, 300);
  }, [animation_slide]);

  useEffect(() => {
    if (reset_animation) {
      if (animation_slide === 1) {
        set_state((state) => new Date(state.setDate(state.getDate() - 7)));
        set_animation_slide(2);
      }
      if (animation_slide === 3) {
        set_state((state) => new Date(state.setDate(state.getDate() + 7)));
        set_animation_slide(2);
      }
    }
  }, [reset_animation]);

  useEffect(() => {
    value && set_state(removeTime(value));
  }, [value]);

  const week_row = (date_for_weeks) => (
    <div className="flex flex-row items-center">
      {getDays(date_for_weeks)?.map((date, index) => {
        return (
          <div key={index} className="w-full flex items-center justify-center">
            <div
              onClick={() => {
                onChange(date);
              }}
              className={
                "cursor-pointer px-1 py-4 flex flex-col items-center justify-center text-xs sm:text-sm rounded-full leading-loose transition ease-in-out duration-100 hover:opacity-80 " +
                (isToday(date) ? "border-indigo-600 border-2" : "")
              }
              style={
                date.toDateString() === new Date(value).toDateString() &&
                animation_slide === 2
                  ? { backgroundColor: "#2A32FD", color: "white" }
                  : {
                      backgroundColor: "rgba(42, 50, 253, 0.08)",
                      color: "#211C52",
                    }
              }
            >
              <span
                className={
                  "w-8 sm:w-12 text-center uppercase " +
                  (date.toDateString() === new Date(value).toDateString()
                    ? ""
                    : "opacity-60")
                }
              >
                {DAYS[index]}
              </span>
              {date.getDate()}
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={"w-full flex flex-col items-center " + className}>
      <div className="w-full flex flex-row items-center gap-1 mb-2">
        <button
          type="button"
          className={
            "transition ease-in-out duration-100 hidden sm:flex cursor-pointer hover:bg-gray-200 py-4 sm:py-7 px-1 sm:px-3 rounded-full"
          }
          onClick={(e) => {
            set_animation_slide(1);
            e.stopPropagation();
          }}
        >
          <LeftArrowIcon className="h-7" />
        </button>
        <SwipeableScreens
          currentSlide={animation_slide}
          rerenderers={[animation_slide]}
          disableAnimation={reset_animation}
          className="items-center w-full"
        >
          {week_row(new Date(new Date(state).setDate(state.getDate() - 7)))}
          {week_row(state)}
          {week_row(new Date(new Date(state).setDate(state.getDate() + 7)))}
        </SwipeableScreens>

        <button
          type="button"
          className={
            "transition ease-in-out duration-100 hidden sm:flex cursor-pointer hover:bg-gray-200 py-4 sm:py-7 px-1 sm:px-3 rounded-full"
          }
          onClick={(e) => {
            set_animation_slide(3);
            e.stopPropagation();
          }}
        >
          <LeftArrowIcon className="h-7 transform rotate-180" />
        </button>
      </div>
      <div className="sm:hidden flex flex-row items-center gap-4 -mt-3">
        <button
          type="button"
          className={
            "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 py-4 sm:py-7 px-1 sm:px-3 rounded-full"
          }
          onClick={(e) => {
            set_animation_slide(1);
            e.stopPropagation();
          }}
        >
          <LeftArrowIcon className="h-7" />
        </button>
        <button
          type="button"
          className={
            "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 py-4 sm:py-7 px-1 sm:px-3 rounded-full"
          }
          onClick={(e) => {
            set_animation_slide(3);
            e.stopPropagation();
          }}
        >
          <LeftArrowIcon className="h-7 transform rotate-180" />
        </button>
      </div>
    </div>
  );
}
