import { Link } from "raviger";
import { useEffect, useState } from "react";
import { getAccounts } from "../../../../api/ApiV2";
import { account_type } from "../../../../common/constants";
import Paginator from "../../../../common/Paginator";
import { amountFormat } from "../../../common/AmountFormat";
import {
  PlusIcon,
  BalanceIcon,
  LoadingCircleIcon,
} from "../../../common/AppIcons";
import Modal from "../../../common/Modal";
import NewAccountPopup from "../../components/NewAccountPopup";

export default function ChartOfAccounts({ hoaId }) {
  const [accounts, set_accounts] = useState([]);
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [loading, set_loading] = useState(false);
  const [new_account_popup, set_new_account_popup] = useState(false);

  useEffect(() => {
    set_loading(true);
    get_accounts();
  }, [page?.current]);

  const get_accounts = () => {
    getAccounts({ hoaId, page: page.current * 50 })
      .then((res) => {
        console.log({ res });
        setPage({ ...page, total: res.count });
        set_accounts(res?.results);
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
      });
  };
  const account_type_label = (account) => {
    return account_type.find((val) => val.value === account)?.label;
  };

  return (
    <div>
      <div className="mt-8 mb-8 sm:mb-12 flex flex-row items-center justify-between gap-2 px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg sm:text-xl leading-6 font-semibold text-gray-900">
          Accounts
        </h2>

        <button
          onClick={() => {
            set_new_account_popup(true);
          }}
          style={{ color: "#2A32FD", border: "2px solid #2A32FD" }}
          className="bg-white hover:bg-gray-200 font-semibold flex flex-row items-center gap-3 px-4 py-2 rounded"
        >
          <PlusIcon className="h-4" /> Create New Account
        </button>
      </div>

      <div className="hidden sm:block px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto pb-5">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto sm:overflow-hidden">
              {loading ? (
                <LoadingCircleIcon className="my-9 mx-auto h-7 w-7 text-blue-600" />
              ) : (
                <table className="w-full divide-y divide-gray-200">
                  <thead className="">
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600 tracking-wider">
                        Account
                      </th>
                      <th className="px-6 py-3 text-right text-sm font-semibold text-gray-600 tracking-wider">
                        Type
                      </th>
                      <th className="px-6 py-3 text-right text-sm font-semibold text-gray-600 tracking-wider">
                        Balance
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200 sm:rounded-lg shadow-md border">
                    {accounts?.map((account, account_index) => (
                      <tr
                        key={account_index}
                        className="bg-white text-gray-800"
                      >
                        <td className="whitespace-nowrap text-sm">
                          <Link
                            href={
                              "/hoa/" +
                              hoaId +
                              "/accounts/" +
                              account.id +
                              "/transactions"
                            }
                            className="flex px-6 py-4"
                          >
                            <span className="group inline-flex items-center space-x-2 truncate text-sm">
                              <BalanceIcon
                                style={{ color: "#2A32FD" }}
                                className="flex-shrink-0 h-4 w-4"
                              />
                              <span className="truncate hover:underline">
                                {account.name}
                              </span>
                            </span>
                          </Link>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm">
                          <span>{account_type_label(account.type)}</span>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm">
                          <span>
                            {"$" + amountFormat(account.current_amount)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <Paginator
          totalPage={page.total}
          currentPage={page.current + 1}
          entryPerPage={50}
          onChange={(pageNo) => {
            setPage({ ...page, current: pageNo - 1 });
          }}
        />
      </div>
      <Modal
        show={new_account_popup}
        setShow={(_) => {
          set_new_account_popup(false);
        }}
      >
        <NewAccountPopup
          hoaId={hoaId}
          closePopup={(_) => {
            set_new_account_popup(false);
            get_accounts();
          }}
        />
      </Modal>
    </div>
  );
}
