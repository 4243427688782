import { Link, navigate } from "raviger";
import { useState } from "react";
import { toast } from "react-toastify";

import { register } from "../../../api/ApiV2";
import {
  CloseEyeIcon,
  LoadingCircleIcon,
  OpenEyeIcon,
} from "../../common/AppIcons";
import FillerImage from "../common/FillerImage";

export default function Register() {
  const [data, set_data] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, set_loading] = useState(false);
  const [warning, set_warning] = useState({});
  const [renderer, set_renderer] = useState(false);

  const checkFields = () => {
    let temp_warning = {};

    if (data?.password?.length < 8) {
      temp_warning = {
        ...temp_warning,
        password: "Password must be at least 8 charector long",
      };
    }

    if (data?.password !== data.confirm_password) {
      temp_warning = {
        ...temp_warning,
        password: "Passwords don't match",
        confirm_password: "Passwords don't match",
      };
    }

    if (data?.password === "" || !data?.password) {
      temp_warning = { ...temp_warning, password: "Please fill this field." };
    }
    if (data?.confirm_password === "" || !data?.confirm_password) {
      temp_warning = {
        ...temp_warning,
        confirm_password: "Please fill this field.",
      };
    }

    if (Object.keys(temp_warning).length > 0) {
      set_warning(temp_warning);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (loading) return;

    if (checkFields()) {
      set_loading(true);
      register({
        data: data,
      })
        .then((res) => {
          console.log(res);
          set_loading(false);

          sessionStorage.setItem("email", res?.email);
          setTimeout(() => navigate("/user/verifyEmail"), 300);
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
          toast.error(res.detail ?? "Error signing up");
          set_warning(res);
        });
    }
  };

  const delete_warning = (field) => {
    let temp_warning = warning;

    delete temp_warning[field];

    set_warning(temp_warning);
    set_renderer((renderer) => !renderer);
  };

  return (
    <div className="w-full h-full">
      <div className="h-full min-h-screen w-full flex flex-row items-center lg:grid lg:grid-cols-11">
        <FillerImage className="lg:col-span-5 hidden lg:block" />
        <div className="lg:col-span-6 p-8 h-full w-full flex-grow flex flex-col items-center justify-center text-left">
          <div className="sm:mt-8 flex flex-col items-start max-w-md w-full lg:mb-20">
            <img
              className="w-20 object-cover"
              alt=""
              src="/logo_register.png"
            />
            <h1
              style={{ color: "#211C52", fontSize: "22px" }}
              className="mt-5 title-font font-semibold"
            >
              Sign up
            </h1>

            <form
              noValidate
              onSubmit={(e) => e.preventDefault()}
              className="mt-4 flex w-full flex-col items-start gap-3"
            >
              <div className="w-full">
                <div className="flex flex-row items-center justify-between gap-4">
                  <label htmlFor="hero-field" className="leading-7 text-sm">
                    First Name
                  </label>
                  <span className="text-sm text-red-600">
                    {warning?.first_name}
                  </span>
                </div>
                <input
                  placeholder="First Name"
                  value={data?.first_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    delete_warning("first_name");
                    set_data((data) => {
                      return { ...data, first_name: value };
                    });
                  }}
                  required
                  className="w-full border border-gray-200 rounded outline-none focus:outline-none text-gray-700 py-2 px-4"
                />
              </div>
              <div className="w-full">
                <div className="flex flex-row items-center justify-between gap-4">
                  <label htmlFor="hero-field" className="leading-7 text-sm">
                    Last Name
                  </label>
                  <span className="text-sm text-red-600">
                    {warning?.last_name}
                  </span>
                </div>
                <input
                  placeholder="Last Name"
                  value={data?.last_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    delete_warning("last_name");
                    set_data((data) => {
                      return { ...data, last_name: value };
                    });
                  }}
                  required
                  className="w-full border border-gray-200 rounded outline-none focus:outline-none text-gray-700 py-2 px-4"
                />
              </div>
              <div className="w-full">
                <div className="flex flex-row items-center justify-between gap-4">
                  <label htmlFor="hero-field" className="leading-7 text-sm">
                    Email id
                  </label>
                  <span className="text-sm text-red-600">
                    {warning?.email?.[0] === "This field must be unique."
                      ? "This email already exists. Please Log in instead"
                      : warning?.email}
                  </span>
                </div>
                <input
                  placeholder="Email"
                  value={data?.email}
                  onChange={(e) => {
                    let value = e.target.value;
                    delete_warning("email");
                    set_data((data) => {
                      return { ...data, email: value };
                    });
                  }}
                  required
                  className="w-full border border-gray-200 rounded outline-none focus:outline-none text-gray-700 py-2 px-4"
                />
              </div>
              <div className="w-full">
                <div className="flex flex-row items-center justify-between gap-4">
                  <label htmlFor="hero-field" className="leading-7 text-sm">
                    Password
                  </label>
                  <span className="text-sm text-red-600">
                    {warning?.password}
                  </span>
                </div>

                <div className="flex flex-row items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="Password"
                    name="Password"
                    placeholder="Create a Password"
                    value={data?.password}
                    onChange={(e) => {
                      let value = e.target.value;
                      delete_warning("password");
                      set_data((data) => {
                        return { ...data, password: value };
                      });
                    }}
                    required
                    className="w-full border border-gray-200 rounded outline-none focus:outline-none text-gray-700 py-2 px-4"
                  />
                </div>
              </div>

              <div className="w-full">
                <div className="flex flex-row items-center justify-between gap-4">
                  <label htmlFor="hero-field" className="leading-7 text-sm">
                    Confirm Password
                  </label>
                  <span className="text-sm text-red-600">
                    {warning?.confirm_password}
                  </span>
                </div>

                <div className="flex flex-row items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="Password"
                    name="Password"
                    placeholder="Confirm Password"
                    value={data?.confirm_password}
                    onChange={(e) => {
                      let value = e.target.value;
                      delete_warning("confirm_password");
                      set_data((data) => {
                        return { ...data, confirm_password: value };
                      });
                    }}
                    required
                    className="w-full border border-gray-200 rounded outline-none focus:outline-none text-gray-700 py-2 px-4"
                  />
                  <div
                    className="w-0 overflow-visible relative right-8"
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword === false ? (
                      <OpenEyeIcon className="text-gray-600 w-5 cursor-pointer" />
                    ) : (
                      <CloseEyeIcon className="text-gray-600 w-5 cursor-pointer" />
                    )}
                  </div>
                </div>
              </div>

              <button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                style={{
                  background:
                    "linear-gradient(89.97deg, #00D2FF 0.03%, #2A32FD 99.97%)",
                }}
                className="mt-3 w-full h-9 text-white border-0 py-2 px-6 focus:outline-none hover:opacity-70 rounded text-sm flex items-center justify-center"
              >
                {loading ? (
                  <LoadingCircleIcon className="h-4 text-white" />
                ) : (
                  "Sign up"
                )}
              </button>
            </form>
            <div className="mt-5 flex flex-row items-center gap-2 text-sm">
              <span style={{ color: "#030229" }}>Already have an account?</span>
              <Link
                href="/login/"
                style={{ color: "#2A32FD" }}
                className="hover:opacity-60 hover:underline"
              >
                Log in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
