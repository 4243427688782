import React, { useState } from "react";
import { Transition } from "@tailwindui/react";
import { useUserActions } from "../../../../../actions/UserActions";
import { Link } from "raviger";
import { useRecoilState } from "recoil";
import { userAtom } from "../../../../../store/UserAtom";

export default function Profile({ hoaId }) {
  const [open_profile_menu, set_open_profile_menu] = useState(false);
  const { logout } = useUserActions();
  const [user] = useRecoilState(userAtom);

  const menu_buttons = [
    {
      label: "Your Profile",
      href: "/hoa/" + hoaId + "/members/",
    },
    {
      label: "Switch Property",
      href: "/home",
    },

    {
      label: "Logout",
      onClick: () => logout(),
    },
  ];

  return (
    <div
      className="ml-3 relative"
      onKeyDown={(e) =>
        e.key === "Escape" ? set_open_profile_menu(false) : null
      }
    >
      <div>
        <button
          onClick={() =>
            set_open_profile_menu((open_profile_menu) => !open_profile_menu)
          }
          className="max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:bg-gray-200 lg:p-2 lg:rounded-md lg:hover:bg-gray-200"
          id="user-menu"
        >
          <img
            className="h-8 w-8 rounded-full"
            src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
          <span className="hidden ml-3 text-gray-700 text-sm font-semibold lg:block">
            <span className="sr-only"> Open user menu for </span>
            {user?.firstName} {user?.lastName}
          </span>
        </button>
      </div>

      <Transition
        show={open_profile_menu}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="origin-top-right absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
      >
        {menu_buttons.map((button, button_index) => (
          <Link
            key={button_index}
            href={button.href ?? "#"}
            onClick={button.onClick}
            className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            {button.label}
          </Link>
        ))}
      </Transition>
    </div>
  );
}
