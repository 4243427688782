import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";

import BalanceRow from "./BalanceRow";

export default function BalanceTable({
  accounts,
  plaidData,
  set_no_plaid_data_popup,
  setAccount,
  get_plaid_transactions,
  plaidLinkFor,
  startPlaidLink,
  plaidOnSuccess,
}) {
  const [onSuccessFlag, setOnsuccessFlag] = useState(false);

  const onSuccess = (token, metadata) => {
    let flag = false;
    setOnsuccessFlag((onSuccessFlag) => {
      flag = onSuccessFlag;
      return onSuccessFlag;
    });
    if (onSuccess && flag) {
      setOnsuccessFlag(false);
      plaidOnSuccess(token, metadata);
    }
  };

  const config = {
    token: plaidData?.link_token || "",
    onSuccess,
  };

  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (plaidLinkFor) {
      setOnsuccessFlag(true);
      open();
    }
  }, [plaidLinkFor]);

  return (
    <tbody x-max="1">
      {accounts?.map((account) => (
        <BalanceRow
          {...{
            account,
            plaidData,
            set_no_plaid_data_popup,
            setAccount,
            get_plaid_transactions,
            startPlaidLink,
            ready,
          }}
        />
      ))}
    </tbody>
  );
}
