export const availableHours = (availablilities, date) => {
  let ans = "";
  let ranges = availablilities?.filter(
    (val) => val.day === days[new Date(date).getDay()]
  );
  ranges = ranges?.sort(
    (val1, val2) =>
      new Date(val1.availability_range.lower) -
      new Date(val2.availability_range.lower)
  );
  ranges?.map((val, index) => {
    ans +=
      dateToTime(val.availability_range.lower) +
      " - " +
      dateToTime(val.availability_range.upper) +
      (ranges.length - 1 === index ? "" : ", ");
  });
  return ans;
};

export const dateToTime = (pre_date) => {
  let date = new Date(pre_date);
  return (
    (
      "0" +
      (date.getHours() === 0
        ? "12"
        : date.getHours() > 12
        ? date.getHours() - 12
        : date.getHours())
    ).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    (date.getHours() > 11 ? " pm" : " am")
  );
};

const days = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];
