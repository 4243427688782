import React from "react";

import Loading from "./Loading";

export default function LoadingScreen({ show, className }) {
  return (
    <div
      className={
        "absolute bg-gray-400 bg-opacity-40 flex items-center justify-center z-50 inset-0 " +
        (show ? "" : "hidden")
      }
    >
      <Loading className={"h-10 w-10 text-gray-500 " + className} />
    </div>
  );
}
