export const estimateLegalText=`
1. PROJECT TERMS:

This is an estimate only and subject to change. Unforeseen issues or change orders are common with home repairs. If additional labor and materials are required to complete the project, we will notify you and seek approval before continuing.

MATERIALS: This DOES NOT include materials, unless specifically listed.

This estimate is valid for 30 days from the date shown above.

2. PAYMENT TERMS:

All invoices must be paid within 30 days, unless other payment terms are agreed upon in writing. If payment is not received, we retain the right to take legal action to collect payment.

If it becomes necessary to take legal action to collect any balance owed to Help Squad, all costs incurred by Help Squad, including reasonable attorney’s fees, court costs and late fees shall be paid by Client. The contract price is subject to a late payment charge of 1½ % per month, compute on the total unpaid balance.

3. TIME OF PERFORMANCE:

Work will begin at the agreed date after acceptance of the estimate. Any delays dues to delayed material delivery or weather conditions may extend completion time of the job. In addition, unforeseen events or government regulations pertaining to emergency orders may prevent Help Squad from completing the work on schedule. In the case of emergency events, such as a pandemic, work will recommence as soon as reasonably possible.

4. WARRANTY AND DISCLAIMER:

Unless specified in a separate agreement, there is no warranty given for maintenance service.

5. CLIENT’S RESPONSIBILITIES:

Client shall supply free access to building for direct loading and unloading to and from building from contractor or delivery trucks. In the event necessary access is available and is refused to contractor or delivery vehicles, this contract may be canceled, and Client shall pay for all Help Squad’s reasonable costs.

Absent Help Squad’s negligence, the Company shall not be liable or responsible for damaged to driveways, walks, curbs, planting, trees, fences, shrubs, lawns, and walls, or interior furnishing or decorations, which may occur during work.

6. NOTICE TO CLIENT:

Under the Mechanics’ Lien Law, any contractor, subcontractor, laborer, supplier or other person who helps to improve your property but is not paid for his work or supplies, has a right to enforce a claim against your property. This means that, after a court hearing, your property could be sold by a court officer and the proceeds of the sale used to satisfy the indebtedness. This can happen even if you have paid your own contractor in full, if the subcontractor, laborer or supplier remains unpaid.Under the law, you may protect yourself against such claims by filling, before commencing such work of improvement, an original contract for the work of improvement or a modification thereof, in the office of the county recorder of the county where the property is situated and requiring that a contractor’s payment bond be recorded in such office. Said bond shall be in an amount not less than fifty percent. (50%) of the contract price and shall, in addition to any conditions for the performance of the contract, be conditioned for the payment in full of the claims of all persons furnishing labor, services, equipment or materials for the work described in said contracts.

7. STATE LICENSE BOARD:

CONTRACTORS ARE REQUIRED BY LAW TO BE LICENSED AND REGULATED BY THE CONTRACTOR’S STATE LICENSE BOARD. ANY QUESTIONS CONCERNING A CONTRACT MAY BE REFERRED TO THE REGISTRAR OF THE BOARD WHOSE ADDRESS IS:

CONTRACTORS’ STATE LICENSE BOARD 1020 NORTH STREET SACRAMENTO, CA 95814

Help Squad Contractor License number: Contractor License: 1039023

8. CANCELLATION:

YOU, THE BUYER, MAY CANCEL THIS TRANSACTION AT ANY TIME PRIOR TO MIDNIGHT OF THE THIRD BUSINESS DAY AFTER THE DATE OF THIS TRANSACTION.`