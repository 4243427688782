import { useEffect } from "react";
import { useRecoilState } from "recoil";

import { CrossCicleIcon } from "../../../../common/AppIcons";
import Modal from "../../../../common/Modal";
import { category } from "../data/category";
import { userAtom } from "../../../../../store/UserAtom";
import { adminTypes } from "../../../../../common/data/adminTypes";

export default function NewMessagePopup({
  new_notice,
  set_new_notice,
  onSubmit,
}) {
  const [user] = useRecoilState(userAtom);

  const adminPermission = adminTypes.includes(user.selectedUserType);

  useEffect(() => {
    if (!adminPermission)
      set_new_notice({ ...new_notice, category: "GENERAL" });
  }, []);
  return (
    <Modal
      show={new_notice.popup}
      setShow={() => {
        set_new_notice({ ...new_notice, popup: false });
      }}
    >
      <div
        className={
          "text-center py-4 px-6 rounded w-5xl overflow-visible transform duration-200 delay-200 bg-white shadow flex flex-col " +
          (new_notice.popup ? "" : " hidden")
        }
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-full flex flex-row items-center justify-between">
          <span className="text-lg font-semibold">Create New Message</span>
          <div
            onClick={() => set_new_notice({ ...new_notice, popup: false })}
            className="text-red-600 hover:text-red-700 cursor-pointer"
          >
            <CrossCicleIcon className="h-7 w-7" />
          </div>
        </div>

        <div className="mt-6 flex flex-col gap-px">
          <span className="text-left text-gray-700 text-sm">Title</span>
          <input
            className="px-2 py-1.5 w-full focus:outline-none border-none bg-gray-100 rounded"
            onChange={(e) => {
              set_new_notice({ ...new_notice, title: e.target.value });
            }}
          />
        </div>

        <div className="mt-4 flex flex-col gap-px">
          <span className="text-left text-gray-700 text-sm">Category</span>

          <select
            value={new_notice.category}
            onChange={(e) =>
              set_new_notice({ ...new_notice, category: e.target.value })
            }
            className="px-2 py-1.5 w-full focus:outline-none border-none bg-gray-100 rounded"
          >
            <option value="" selected disabled hidden>
              Select Category
            </option>
            {category
              ?.filter((val) => val.value === "GENERAL" || adminPermission)
              .map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        </div>

        <div className="mt-4 flex flex-col gap-px">
          <span className="text-left text-gray-700 text-sm">Description</span>
          <textarea
            className="px-2 py-1 w-full focus:outline-none border-none bg-gray-100 rounded"
            onChange={(e) => {
              set_new_notice({
                ...new_notice,
                description: e.target.value,
              });
            }}
          />
        </div>

        <div className={"w-full mt-2 flex justify-end"}>
          <button
            style={{ backgroundColor: "#2A32FD" }}
            className={
              "px-5 py-2 rounded shadow text-white text-sm font-semibold hover:opacity-70"
            }
            onClick={() => {
              if (
                new_notice.title !== "" &&
                new_notice.category !== "" &&
                new_notice.description !== ""
              ) {
                onSubmit();
                set_new_notice({ ...new_notice, popup: false });
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
}
