import React from "react";
import { RightArrowIcon } from "../../../../common/AppIcons";

export default function PageHeading({ closePage, label }) {
  return (
    <div className="flex flex-row items-center gap-1">
      {closePage && (
        <div
          className="cursor-pointer"
          onClick={() => {
            closePage();
          }}
        >
          <RightArrowIcon className="h-5 w-5 text-gray-700 hover:text-gray-500 transform rotate-180" />
        </div>
      )}
      <h1
        style={{ fontSize: "20px" }}
        className="text-gray-800 font-semibold relative bottom-px tracking-wide"
      >
        {label}
      </h1>
    </div>
  );
}
