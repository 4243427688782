import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "raviger";

import FormElement from "./components/FormElement";
import { defaultPic } from "../../data/defaultPic";
import SlotElement from "./components/SlotElement";
import DatePicker2 from "../../../common/Form/DatePicker2";
import DatePickerPerWeek from "./components/DatePickerPerWeek";
import { hours_array } from "../amenities/data/hours_array";
import {
  getAmenity,
  getAmenityPhoto,
  getAmenityReservationSlots,
  postAmenityReservation,
} from "../../../../api/ApiV2";
import { dateTimeRangeString } from "../reservations/utility/dateTimeRange";
import {
  availableHours,
  dateToTime,
} from "../reservations/utility/availableHours";
import { LeftArrowIcon, LoadingCircleIcon } from "../../../common/AppIcons";
import { userAtom } from "../../../../store/UserAtom";
import { useRecoilState } from "recoil";

const inputClassName =
  "px-3 py-2 border-2 border-white focus:border-indigo-500 rounded outline-none focus:outline-none focus:ring-0 text-sm";
const inputStyle = { backgroundColor: "#F6F6F6", color: "#71717A" };

const find_index_hours_array = (value) => {
  let index = 0;
  hours_array.forEach((ele, ele_index) => {
    if (ele.value === Number(value)) {
      index = ele_index;
    }
  });
  return index;
};

export default function ReservationForm({ hoaId, amenityId }) {
  const [amenity, setAmenity] = useState();
  const [reservationForm, set_reservationForm] = useState({});
  const [error, set_error] = useState("");
  const [loading, set_loading] = useState(false);
  const [reservationLoading, setReservationLoading] = useState(false);
  const [reservation_slots, set_reservation_slots] = useState();
  const [image, setImage] = useState();
  const [user] = useRecoilState(userAtom);

  const selectedProfile = user?.userProfiles?.find(
    (val) => val.id === user.selectedUserProfile
  );

  useEffect(() => {
    getAmenityPhoto({ hoaId, id: amenityId })
      .then((res) => {
        console.log(res);
        setImage(Object.values(res.photos)?.[0]?.url);
      })
      .catch((res) => {
        console.log(res);
        toast.error("Amenity picture fetching failed");
      });
    set_loading(true);
    getAmenity({ hoaId, amenityId })
      .then((res) => {
        setAmenity(res);
        set_reservationForm({
          date: new Date(),
          duration: res.minimum_minutes,
        });
        set_loading(false);
      })
      .catch((res) => {
        set_loading(false);
        toast.error("Amenity fetching failed");
      });
  }, []);

  useEffect(() => {
    if (reservationForm?.date && reservationForm?.duration)
      getReservationSlots();
  }, [reservationForm?.date, reservationForm?.duration]);

  const getReservationSlots = () => {
    let date = new Date(reservationForm?.date);
    setReservationLoading(true);

    let yourDate = new Date(date);
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);

    getAmenityReservationSlots({
      hoaId,
      amenityId,
      data: {
        date: yourDate.toISOString().split("T")[0],
        duration: reservationForm["duration"],
      },
    })
      .then((res) => {
        setReservationLoading(false);
        console.log(res);
        set_reservation_slots(res?.results);
      })
      .catch((res) => {
        setReservationLoading(false);
        console.log(res);
        toast.error(
          res.readableMessage ?? res.message ?? "Error fetching data"
        );
      });
  };

  useEffect(() => {
    set_error((error) => {
      return {
        ...(error ?? {}),
        unit_object: "",
      };
    });
  }, [user.selectedProfile]);

  const submit = () => {
    set_error();
    if (!selectedProfile?.unit_object) {
      set_error((error) => {
        return {
          ...(error ?? {}),
          unit_object: "No unit found for selected user profile",
        };
      });
    } else if (
      !reservationForm?.reservation_time &&
      reservationForm?.reservation_time !== 0
    ) {
      set_error((error) => {
        return {
          ...(error ?? {}),
          reservation_time: "Please select an Available slot to proceed",
        };
      });
    } else if (!reservationForm.agreed) {
      set_error((error) => {
        return {
          ...(error ?? {}),
          agreement: "Please read and agree to the conditions",
        };
      });
    } else {
      set_loading(true);

      postAmenityReservation({
        hoaId,
        amenityId: amenityId,
        data: {
          from_datetime: dateTimeRangeString(reservationForm?.reservation_time),
          to_datetime: dateTimeRangeString(
            new Date(reservationForm?.reservation_time).setMinutes(
              new Date(reservationForm?.reservation_time).getMinutes() +
                Number(reservationForm?.["duration"])
            )
          ),
        },
      })
        .then((res) => {
          set_loading(false);
          toast.success("Amenity reserved successfully");
          getReservationSlots();
        })
        .catch((res) => {
          set_loading(false);
          toast.error("Error submitting reservation");
        });
    }

    setTimeout(() => {
      set_error();
    }, 6000);
  };

  const set_form = (key, value) =>
    set_reservationForm((reservationForm) => {
      return {
        ...reservationForm,
        [key]: value,
      };
    });

  let availableReservationSlots =
    reservation_slots
      ?.filter(
        (ele) =>
          new Date(reservationForm?.date).toDateString() !==
            new Date().toDateString() ||
          new Date() -
            new Date(
              new Date(
                new Date().setHours(new Date(ele.start_time).getHours())
              ).setMinutes(new Date(ele.start_time).getMinutes())
            ) <=
            0
      )
      .map((val) => ({
        ...val,
        start_time: new Date(
          new Date(
            new Date(reservationForm.date).setHours(
              new Date(val.start_time).getHours()
            )
          ).setMinutes(new Date(val.start_time).getMinutes())
        ).toISOString(),
      })) ?? [];

  if (loading)
    return (
      <div className="w-full bg-white rounded flex items-center justify-center py-20">
        <LoadingCircleIcon className="h-10 text-blue-500" />
      </div>
    );
  else if (!amenity)
    return (
      <div className="w-full bg-white rounded flex items-center justify-center py-20">
        <span className="text-sm text-gray-500">No Amenity found</span>
      </div>
    );
  else
    return (
      <div className="bg-white rounded w-full flex flex-col items-start p-3 sm:p-6">
        <span
          className="mt-2 font-semibold text-xl flex flex-row items-center gap-2"
          style={{ color: "#18181B" }}
        >
          <Link
            href={`/hoa/${hoaId}/community/reservations/`}
            className="mt-1 text-gray-800 hover:text-gray-500 border border-white hover:border-gray-400 rounded px-1 py-2"
          >
            <LeftArrowIcon className="h-5 w-5" />
          </Link>{" "}
          {amenity.name ?? ""}
        </span>

        <img
          src={image || defaultPic}
          alt=""
          className={
            "w-full mt-4 object-fit rounded " + (image ? "" : "w-full h-60")
          }
        />

        {amenity.availabilities && (
          <div
            style={{ fontSize: "15px" }}
            className="mt-4 flex flex-row items-center gap-1 flex-wrap text-sm"
          >
            <span style={{ color: "#71717A" }}>Available Hours :</span>
            <span className="text-gray-800">
              {availableHours(amenity.availabilities, reservationForm?.date)}
            </span>
          </div>
        )}

        <div className="my-10 flex flex-col gap-4 items-center w-full">
          {new Date(reservationForm?.date).toLocaleDateString()}
          <DatePicker2
            value={reservationForm?.date}
            onChange={(date) => {
              let today = new Date();
              today.setHours(0);
              today.setMinutes(0);
              today.setSeconds(-1);
              if (new Date(date) >= today) set_form("date", date);
            }}
          />
          <DatePickerPerWeek
            value={reservationForm?.date}
            onChange={(date) => {
              let today = new Date();
              today.setHours(0);
              today.setMinutes(0);
              today.setSeconds(-1);
              if (new Date(date) >= today) set_form("date", date);
            }}
          />
        </div>

        <FormElement label={"Unit"} warning={error?.unit_object}>
          <input
            className={inputClassName}
            style={inputStyle}
            readonly
            value={
              selectedProfile?.unit_object
                ? (selectedProfile.unit_object.unit_number ?? "") +
                  (selectedProfile.unit_object.address_object?.street_address_1
                    ? ", "
                    : "") +
                  (selectedProfile.unit_object.address_object
                    ?.street_address_1 ?? "")
                : "No Unit found"
            }
          />
        </FormElement>

        <FormElement label={"Choose Duration"}>
          <select
            className={inputClassName}
            style={inputStyle}
            value={reservationForm["duration"]}
            onChange={(e) => {
              set_form("duration", e.target.value);
            }}
          >
            <option selected hidden className="text-sm">
              Select
            </option>
            {hours_array
              .slice(
                find_index_hours_array(amenity.minimum_minutes ?? 30),
                find_index_hours_array(amenity.maximum_minutes ?? 1440) + 1
              )
              .map((ele, ele_index) => (
                <option key={ele_index} value={ele.value}>
                  {ele.label}
                </option>
              ))}
          </select>
        </FormElement>

        <br />

        <FormElement label="Available Slots" warning={error?.reservation_time}>
          {reservationLoading ? (
            <div className="flex items-center justify-center w-full py-10">
              <LoadingCircleIcon className="h-5 text-blue-500" />
            </div>
          ) : availableReservationSlots?.length > 0 ? (
            <div className="mb-8 grid grid-cols-2 sm:grid-cols-4 gap-x-3 gap-y-5">
              {availableReservationSlots.map((slot, slot_index) => {
                return (
                  <div className="mt-2 flex items-center justify-center sm:w-full">
                    <SlotElement
                      key={slot_index}
                      slots={slot.availability}
                      onClick={() => {
                        set_form("reservation_time", slot.start_time);
                      }}
                      time={dateToTime(slot.start_time)}
                      checked={
                        reservationForm.reservation_time === slot.start_time
                      }
                      style={inputStyle}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <span className="w-full flex justify-center py-7 text-sm text-gray-500">
              No available slots
            </span>
          )}
        </FormElement>

        <FormElement label="Agreement" warning={error?.agreement}>
          <textarea
            className={"" + inputClassName}
            style={inputStyle}
            value={
              "I acknowledge and agree to all the rules, instructions, limits and conditions to use"
            }
            readOnly
          />
        </FormElement>

        <div className="mt-3 px-2 flex flex-row items-center gap-2 text-sm">
          <input
            type="checkbox"
            id="agreement_checkbox"
            checked={reservationForm.agreed}
            onClick={() =>
              set_reservationForm((reservationForm) => {
                return { ...reservationForm, agreed: !reservationForm.agreed };
              })
            }
            className={"h-3 w-3"}
          />{" "}
          <label htmlFor="agreement_checkbox" style={{ color: "#71717A" }}>
            I Agree to the conditions and rules
          </label>
        </div>
        <div className="mt-8 w-full flex flex-row items-center gap-3 justify-end">
          <button
            className="text-sm text-gray-500 hover:text-gray-600 border-2 border-gray-500 hover:border-gray-600 font-semibold px-6 py-2 rounded"
            onClick={() => set_reservationForm({ show: false })}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              submit();
            }}
            style={{ backgroundColor: "#2A32FD", border: "2px solid #2A32FD" }}
            className="rounded px-6 py-2 text-white hover:opacity-70 text-sm"
          >
            Reserve Amenity
          </button>
        </div>
      </div>
    );
}
