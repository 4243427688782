import React from "react";

import { state, terms } from "../../../../common/Data";
import { amountFormat } from "../../../../common/AmountFormat";

const computeFieldName = (text) =>
  text.replaceAll("/", "_").trim().replaceAll(" ", "_").toLowerCase();

export const fieldData = (expenseAccounts) => {
  return [
    {
      type: "TEXT_FIELD",
      label: "Display Name",
      fieldName: computeFieldName("Display Name"),
    },
    {
      type: "TEXT_FIELD",
      label: "Company Name",
      fieldName: computeFieldName("Company Name"),
    },
    {
      type: "TEXT_FIELD",
      label: "Street Address 1",
      fieldName: computeFieldName("Street Address 1"),
    },
    {
      type: "TEXT_FIELD",
      label: "Street Address 2",
      fieldName: computeFieldName("Street Address 2"),
    },
    {
      type: "TEXT_FIELD",
      label: "City",
      fieldName: computeFieldName("City"),
    },
    {
      type: "DROP_DOWN",
      label: "State",
      fieldName: computeFieldName("State"),
      placeholder: "Select state",
      options: state.map((value) => {
        return {
          label: value.label,
          value: value.value,
        };
      }),
    },
    {
      type: "TEXT_FIELD",
      label: "ZIPCode",
      fieldName: computeFieldName("ZIPCode"),
    },
    {
      type: "TEXT_FIELD",
      label: "Bank Routing",
      fieldName: computeFieldName("Bank Routing"),
    },
    {
      type: "TEXT_FIELD",
      label: "Account No",
      fieldName: computeFieldName("Account No"),
    },
    {
      type: "TEXT_FIELD",
      label: "TIN/SSN",
      fieldName: computeFieldName("TIN/SSN"),
    },
    {
      type: "TEXT_FIELD",
      label: "Contact First Name",
      fieldName: computeFieldName("Contact First Name"),
    },
    {
      type: "TEXT_FIELD",
      label: "Contact Last Name",
      fieldName: computeFieldName("Contact Last Name"),
    },
    {
      type: "TEXT_FIELD",
      label: "Email",
      fieldName: computeFieldName("Email"),
    },
    {
      type: "phoneInput",
      label: "Phone",
      fieldName: computeFieldName("Phone"),
    },
    {
      type: "TEXT_FIELD",
      label: "Landline",
      fieldName: computeFieldName("Landline"),
    },
    {
      type: "DROP_DOWN",
      label: "Terms",
      placeholder: "Select term",
      fieldName: computeFieldName("Terms"),
      options: terms.map((val) => {
        return { label: val.label, value: val.value1 };
      }),
    },
    {
      type: "DROP_DOWN",
      label: "Default Account",
      placeholder: "Select term",
      fieldName: computeFieldName("Default Account"),
      options: [
        {
          word: "Create New Account",
          label: (
            <div className="py-1 w-full text-xs font-semibold">
              Create New Account
            </div>
          ),
          value: "OPEN_NEW_ACCOUNT_POPUP",
        },
        ...(expenseAccounts.map((account) => {
          return {
            word: account.name,
            label: (
              <div className="grid grid-cols-4 w-full text-xs font-semibold">
                <h1 className="col-span-3 truncate">{account.name}</h1>
                <h1 className="text-right break-all">
                  ${amountFormat(account.current_amount)}
                </h1>
              </div>
            ),
            value: account.id,
          };
        }) ?? []),
      ],
    },
  ];
};
