import CreateVendor from ".";
import Popup from "../../../common/Popup";
import PopupHeading from "../../../common/PopupHeading";

export default function CreateVendorPopup({ hoaId, show, setShow, ...props }) {
  if (show)
    return (
      <Popup
        show={show}
        setShow={setShow}
        className="bg-white rounded-md shadow-lg flex flex-col pb-6 pt-4"
      >
        <PopupHeading closePopup={setShow} className="px-7 py-3">
          Create Vendor
        </PopupHeading>
        <CreateVendor
          hoaId={hoaId}
          closePopup={setShow}
          className="px-4 flex-grow"
          {...props}
        />
      </Popup>
    );
  else return null;
}
