import { useEffect, useRef, useState } from "react";

import {
  LoadingCircleIcon,
  PlusInCircleIcon2,
} from "../../../../common/AppIcons";
import UserRow from "./components/UserRow";
import AddUserPopup from "./components/AddUserPopup";
import Popup from "../../../../common/Popup";
import {
  addMultipleHoaMembers,
  bulkPatchUnits,
  getHoaMembers,
  getUnits,
  patchUnits,
} from "../../../../../api/ApiV2";
import { toast } from "react-toastify";

export default function PropertyDetails({
  hoaData,
  setHoaData,
  previousPage,
  nextPage,
}) {
  const [warnings, setWarnings] = useState({
    name: "",
    streetAddress1: "",
    city: "",
    zipCode: "",
  });
  const [addUserPopup, setAddUserPopup] = useState();
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);

  const delete_warning = (value) => {
    let temp_warning = warnings;
    delete temp_warning[value];
    setWarnings(temp_warning);
  };

  useEffect(() => {
    fetchUnits();
  }, []);

  const fetchUnits = () => {
    getUnits({ hoaId: hoaData.hoaId })
      .then((res) => {
        console.log(res);
        fetchMembers();
        setHoaData((hoaData) => {
          return {
            ...hoaData,
            units: res.results,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.message ?? "Error fetching units");
      });
  };

  const fetchMembers = () => {
    getHoaMembers(hoaData.hoaId)
      .then((res) => {
        console.log(res);
        setHoaData((hoaData) => {
          return {
            ...hoaData,
            members: res.results,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.message ?? "Error fetching members");
      });
  };

  const submitHoa = () => {
    setLoading(true);
    let unitApiLoading = "LOADING";

    let membersapiLoading = "LOADING";

    const checkApiLoading = () => {
      if (unitApiLoading !== "LOADING" && membersapiLoading !== "LOADING") {
        setLoading(false);
        if (unitApiLoading !== "FAILED" && membersapiLoading !== "FAILED") {
          toast.success("Units saved successfully");
          nextPage();
        }
      }
    };

    patchUnits({
      hoaId: hoaData.hoaId,
      unitId: hoaData.units[0].id,
      data: hoaData.units[0],
    })
      .then((res) => {
        console.log(res);
        unitApiLoading = "SUCCESS";
        checkApiLoading();
      })
      .catch((err) => {
        toast.error(err.readableMessage ?? err.message ?? "Error saving unit");
        console.log(err);
        unitApiLoading = "FAILED";
        checkApiLoading();
        toast.error(err.readableMessage ?? err.message ?? "Error saving units");
      });

    addMultipleHoaMembers({
      hoaId: hoaData.hoaId,
      data: {
        members: hoaData.members
          ?.filter((val) => !val.id)
          .map((val) => ({ ...val, unit: hoaData.units[0].id })),
      },
    })
      .then((res) => {
        console.log(res);
        membersapiLoading = "SUCCESS";
        checkApiLoading();
      })
      .catch((res) => {
        console.log(res);
        membersapiLoading = "FAILED";
        checkApiLoading();

        let savedClients = hoaData?.members.filter((val) => val.user?.id);
        setHoaData((hoaData) => ({
          ...hoaData,
          members: [
            ...savedClients,
            ...hoaData?.members
              .filter((val) => !val.user?.id)
              .map((val1, index) => {
                return {
                  ...val1,
                  warnings: {
                    ...(res[index]?.type ? { type: res[index]?.type } : {}),
                    ...(res[index]?.user ?? {}),
                  },
                };
              }),
          ],
        }));

        toast.error(
          res.readableMessage ?? res.message ?? "Error saving members"
        );
      });
  };

  if (loading)
    return (
      <div className="py-10 m-auto ">
        <LoadingCircleIcon className="h-4" />
      </div>
    );
  return (
    <div
      ref={componentRef}
      className="max-w-6xl w-full flex-grow sm:mx-16 bg-white rounded-md shadow-md mt-10 pt-10 pb-24"
    >
      <div className="mt-10 h-full w-full m-auto px-4 flex flex-col items-center text-gray-800 max-w-5xl">
        <div className="w-full flex flex-col">
          <span
            className="text-xl text-center w-full font-semibold"
            style={{ color: "#2A32FD" }}
          >
            Add Property Details & Members
          </span>
        </div>
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-3 gap-4 w-full">
          {[
            {
              label: "Property Size(in sq.ft)",
              value: "unit_size",
            },
            {
              label: "Beds",
              value: "beds",
            },
            {
              label: "Baths",
              value: "baths",
            },
          ].map((field, fieldIndex) => (
            <div key={fieldIndex} className="flex flex-col">
              <span className="text-gray-500 text-sm">{field.label}</span>
              <input
                className="rounded-md bg-gray-100 focus:bg-gray-200 px-3 py-2"
                value={hoaData.units?.[0]?.[field.value]}
                type="number"
                onChange={(e) => {
                  setHoaData((hoaData) => ({
                    ...hoaData,
                    units: [
                      {
                        ...(hoaData.units?.[0] ?? {}),
                        [field.value]: e.target.value,
                      },
                    ],
                  }));
                }}
              />
            </div>
          ))}
        </div>
        <span className="mt-6 text-left w-full">Members in this property</span>
        <div className="w-full mt-6 flex flex-col gap-2.5">
          {hoaData.members?.map((user, userIndex) => (
            <UserRow
              error={Object.keys(user.warnings ?? {})?.length ? true : false}
              key={userIndex}
              user={user}
              index={userIndex}
              openPopup={setAddUserPopup}
              setHoaData={setHoaData}
            />
          ))}
          <div>
            <button
              className="mt-3 text-sm text-gray-500 hover:opacity-70 flex flex-row items-center gap-2"
              onClick={() => {
                setAddUserPopup({});
              }}
            >
              <PlusInCircleIcon2 className="text-green-500 h-2.5" />
              Add Member
            </button>
          </div>
        </div>

        <div className="w-full mt-10 flex flex-row justify-end items-center gap-3">
          <button
            onClick={previousPage}
            className="text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 h-10 w-28 rounded"
          >
            Go Back
          </button>

          <button
            style={{
              backgroundColor: "#2A32FD",
              border: "2px solid #2A32FD",
            }}
            className="text-sm text-white hover:opacity-80 h-10 w-44 flex items-center justify-center rounded"
            onClick={() => {
              submitHoa();
            }}
          >
            Continue
          </button>
        </div>
      </div>
      <Popup show={addUserPopup ? true : false} setShow={setAddUserPopup}>
        {addUserPopup && (
          <AddUserPopup
            {...{
              readonly: addUserPopup.id ? true : false,
              data: addUserPopup,
              memberIndex: addUserPopup?.index,
              warnings: addUserPopup?.warnings,
              setUserData: setHoaData,
              closePopup: () => {
                setAddUserPopup();
              },
            }}
          />
        )}
      </Popup>
    </div>
  );
}
