import React from "react";

import { amountFormat } from "../../../../common/utility/amountFormat";

export default function Summary({ show = true, title, data }) {
  return show ? (
    <div className="w-60 sm:w-72 flex flex-col">
      <div
        style={{ backgroundColor: "#F5F5F5" }}
        className="text-center w-full p-2 text-gray-500 font-bold border-b border-gray-400"
      >
        {title}
      </div>
      {data?.map((ele, ele_index) => (
        <div
          key={ele_index}
          className={
            "w-full p-2 text-gray-800 font-semibold flex flex-row items-center justify-between border-b border-gray-400"
          }
        >
          <span>{ele.label}</span>
          <span className={ele.className}>{amountFormat(ele.value)}</span>
        </div>
      ))}
    </div>
  ) : (
    <div />
  );
}
