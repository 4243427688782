import { getCookie, getCsrfToken } from "../utils/DOMUtils";

export const API_BASE_URL = "/api/";

const request = (options, customHeaders = {}, onlyHeader) => {
  const headers = new Headers(
    onlyHeader
      ? {
          ...customHeaders,
        }
      : {
          "Content-Type": "application/json",
          ...customHeaders,
        }
  );

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then((response) =>
    response
      .json()
      .then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      })
      .catch((err) => {
        if (!response.ok) {
          return Promise.reject(err);
        }
        return {
          status: "success",
        };
      })
  );
};

const urlParams = (params) => {
  console.log("Parameterizing", Object.entries(params));
  return Object.entries(params).reduce(
    (url, [key, value]) =>
      value
        ? url === ""
          ? `?${key}=${value}`
          : `${url}&${key}=${value}`
        : url,
    ""
  );
};
// Plaid APIs
// {public_token: public_token, account_id: metadata.accounts[0].id}
export function linkPlaidAccount(hoaId, accountId, data) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/plaidAccount/${accountId}`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function fetchPlaidTransactions(hoaId, accountId) {
  return request({
    url:
      API_BASE_URL +
      `hoa/${hoaId}/plaidAccount/${accountId}/unmatchedTransactions`,
    method: "GET",
  });
}

export function fetchTransaction({ hoaId, transactionId }) {
  return request({
    url: API_BASE_URL + `hoa/${hoaId}/transaction/${transactionId}/`,
    method: "GET",
  });
}

export function saveTransactions(hoaId, transactions, is_check) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/create_multiple_transactions/${urlParams({ is_check })}`,
      method: "POST",
      body: JSON.stringify(transactions),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

// Accounting APIs

export function saveStripeSetup(hoaId, data) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/plaid_stripe_tokens/",
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function updateStripeSetup(hoaId, data, id) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/plaid_stripe_tokens/" + id + "/",
      method: "PATCH",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getStripePaymentSetup({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + "stripePaymentMethods/hoa/" + hoaId + "/",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getStripePaymentSetupHelpsquad() {
  return request(
    {
      url: API_BASE_URL + "stripePaymentMethods/helpsquad/",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getAccounts({
  hoaId,
  type,
  bank_account,
  page = 1,
  page_size,
}) {
  return request({
    url:
      API_BASE_URL +
      `hoa/${hoaId}/getAccounts${urlParams({
        type,
        bank_account,
        page,
        page_size,
      })}`,
    method: "GET",
  });
}

export function getAllTransactions({
  hoaid,
  page = 1,
  type,
  search_field,
  billId,
}) {
  return request({
    url:
      API_BASE_URL +
      `hoa/${hoaid}/transactions${urlParams({
        type,
        page,
        search_field,
        bill: billId,
      })}`,
    method: "GET",
  });
}

export function listTransactions({ hoaId, page = 1, page_size }) {
  return request({
    url:
      API_BASE_URL +
      "hoa/" +
      hoaId +
      `/expenses${urlParams({ page, page_size })}`,
    method: "GET",
  });
}

export function listAmenities({ hoaId, page = 1 }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + `/amenities/`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function postAmenities({ hoaId, data }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + `/amenities/`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function editAmenities({ hoaId, id, data }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + `/amenities/${id}/`,
      method: "PATCH",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function deleteAmenities({ hoaId, id }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + `/amenities/${id}/`,
      method: "DELETE",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function createJournalEntry(hoaId, data) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/journal_entry`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getEvents({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/events/`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function postEvents({ hoaId, data }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/events/`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function postEventRequest({ hoaId, data }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/events/request_for_approval/ `,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function responseToEventRequest({ hoaId, eventId, data }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/events/${eventId}/approve_or_reject/`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function editEvents({ hoaId, eventId, data }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/events/${eventId}/`,
      method: "PATCH",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function editJournalEntry({ hoaId, id, data }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/transactions/${id}/`,
      method: "PATCH",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getEventsRequests({ hoaId, admin }) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/events/${admin ? "list_for_admin" : "list_for_owner"}/`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function createBill(hoaId, data) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/bills/`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getBills({ hoaId, page = 1, invoice, paid, user, page_size }) {
  return request({
    url:
      API_BASE_URL +
      `hoa/${hoaId}/bills/?${urlParams({
        page,
        paid,
        invoice,
        user,
        page_size,
      })}`,
    method: "GET",
  });
}

export function getBill({ hoaId, billId }) {
  return request({
    url: API_BASE_URL + `hoa/${hoaId}/bills/${billId}/`,
    method: "GET",
  });
}

export function getVendorTransactions(hoaId, vendorId, page = 1) {
  return request({
    url:
      API_BASE_URL +
      `hoa/${hoaId}/vendors/${vendorId}/transactions?page=${page}`,
    method: "GET",
  });
}

export function createVendor(hoaId, data) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/vendors/`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function updateVendor(hoaId, vendorId, data) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/vendors/${vendorId}/`,
      method: "PUT",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function listVendors(hoaId, page) {
  return request({
    url:
      API_BASE_URL +
      `hoa/${hoaId}/vendors/${urlParams({ page, page_size: 300 })}`,
    method: "GET",
  });
}

// Maintanence APIs

export function getMarketplaceVendors() {
  return request({
    url: API_BASE_URL + `marketplace_vendors`,
    method: "GET",
  });
}

export function postSR({ hoaId, data }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/srs/`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getSRs(hoaId, queryParams) {
  return request({
    url: API_BASE_URL + `hoa/${hoaId}/srs${urlParams(queryParams)}`,
    method: "GET",
  });
}

export function getSR({ hoaId, srId }) {
  return request({
    url: API_BASE_URL + `hoa/${hoaId}/sr/${srId}`,
    method: "GET",
  });
}

// Compliance APIs
export function getDocuments(hoaId, documentType) {
  return request({
    url: API_BASE_URL + `hoa/${hoaId}/compliance_documents/${documentType}`,
    method: "GET",
  });
}

export function getDocumentsWithComments(hoaId, docId) {
  return request({
    url: API_BASE_URL + `hoa/${hoaId}/compliance_document/all/${docId}/`,
    method: "GET",
  });
}

export function commentOnDocument(hoaId, docId, data) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/compliance_documents/${docId}/add_doc_comment`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function createNewDocument(hoaId, data) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/compliance_document/create`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getYourArchitecturalPlans({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/architectural_plans`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getReviewArchitecturalPlans({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/architectural_plans/list_for_review`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getArchitecturalPlanDetails({ hoaId, id }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/architectural_plans/${id}/`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function patchArchitecturalPlanDetails({ hoaId, id, data }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/architectural_plans/${id}/`,
      method: "PATCH",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function postArchitecturalPlans({ hoaId, data }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/architectural_plans/`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function postArchitecturalPlansFileObject({ hoaId, data, id }) {
  let form_data = new FormData();
  form_data.append("csrfmiddlewaretoken", getCsrfToken());
  Object.keys(data).map((val) => form_data.append(val, data[val]));
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/architectural_plans/${id}/upload_file_object/`,
      method: "POST",
      body: form_data,
    },
    { "X-CSRFToken": getCsrfToken() },
    true
  );
}

export function submitArchitecturalReview({ hoaId, id, data }) {
  return request(
    {
      url:
        API_BASE_URL + `hoa/${hoaId}/architectural_plans/${id}/submit_review/`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function architecturalReviewRecommendationAndComment({ hoaId, data }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/comments/create_for_arch_plan/`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function postArchitecturalReviewDiscussionReply({ hoaId, data }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/comments/reply_for_comment/ `,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getArchitecturalReviewComments({ hoaId, id }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/comments/arch_plan_discussion/${id}`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getArchitecturalReviewRecommendation({ hoaId, id }) {
  return request(
    {
      url:
        API_BASE_URL + `hoa/${hoaId}/comments/arch_plan_recommendations/${id}`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function postArchitecturalReviewResolution({ hoaId, id, data }) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/architectural_plans/${id}/submit_resolution/`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function reconcileTransactions(accountId, endDate, hoaId) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/account/${accountId}/transactions?to_reconcile=true&&end_date=${endDate}`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getTransactions({ hoaId, accountId, page }) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/account/${accountId}/transactions${urlParams({
          page,
        })}`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function updateReconcileTransactions(accountId, bankTransaction, hoaId) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/account/${accountId}/transactions/reconcile`,
      method: "PATCH",
      body: JSON.stringify(bankTransaction),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getProfitLoss({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/accounts/profit_loss/`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function filterProfitLoss({ hoaId, start_date, end_date }) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/accounts/profit_loss/?start_date=${start_date}&end_date=${end_date}`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function postInvoices(hoaId, data) {
  console.log(data);

  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/bills/?invoice=True`,
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function createHOA(data) {
  return request(
    {
      url: API_BASE_URL + "hoas/",
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getHOAList({ page, page_size }) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoas/${urlParams({
          page,
          page_size,
        })}`,

      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function updateHOA({ hoaId, data }) {
  return request(
    {
      url: API_BASE_URL + "hoas/" + hoaId + "/",
      method: "PATCH",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getHOA({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + "hoas/" + hoaId + "/",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getHoaMembers(hoaid, page = 1) {
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaid +
        "/members" +
        `${urlParams({ page, page_size: 300 })}`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function addHoaMembers(hoaId, data, method = "POST") {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/create_multiple_members/",
      method: method,
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function updateMember(hoaId, data, userId) {
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaId +
        "/create_multiple_members/" +
        userId +
        "/",
      method: "PATCH",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function deleteHoaMember({ hoaId, member_id }) {
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaId +
        "/create_multiple_members/" +
        member_id +
        "/",
      method: "DELETE",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function addAccount(hoaId, data) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/accounts/",
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function updateUserDues(hoaId, data) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/update_users_dues/",
      method: "POST",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getNotices({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/notices/",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function PostNotice({ hoaId, data }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/notices/",
      method: "POST",
      body: JSON.stringify({ ...data }),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getInvoice({ hoaId, invoiceId }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/invoices/${invoiceId}/`,
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function enableModulesAccounts({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/accounts/enableModule/`,
      method: "POST",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function enableModuleCompliance({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/compliance_documents/enableModule/`,
      method: "POST",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function payForService({ hoaId, sr_id }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/sr/${sr_id}/pay`,
      method: "POST",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function payForCardPreData({ hoaId, receiver }) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/stripePayments/create` +
        urlParams({ receiver }),
      method: "POST",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function payWithCard({ hoaId, invoice_id }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/invoice/${invoice_id}/pay`,
      method: "POST",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function savePlaid({
  hoaId,
  account_id,
  bank_account_id,
  public_token,
  receiver,
  account,
}) {
  console.log(
    urlParams({
      receiver,
    })
  );
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/plaid/checkOrCreatePlaidStripeToken${urlParams({
          receiver,
        })}`,
      method: "POST",
      body: JSON.stringify({
        bank_account_id,
        account_id,
        public_token,
        account,
      }),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function payInvoice1Hoa({
  hoaId,
  account_id,
  bank_account_id,
  public_token,
  receiver,
  account,
}) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/plaid/checkOrCreatePlaidStripeTokenHoa${urlParams({
          receiver,
        })}`,
      method: "POST",
      body: JSON.stringify({
        bank_account_id,
        account_id,
        public_token,
        account,
      }),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function payInvoice({ hoaId, invoiceId, plaid_item_id }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/invoice/${invoiceId}/payWithAch`,
      method: "POST",
      body: JSON.stringify({
        plaid_item_id,
      }),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function paySR({ hoaId, srId, plaid_item_id }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/sr/${srId}/payWithAch`,
      method: "POST",
      body: JSON.stringify({ plaid_item_id }),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function paySRUser({ hoaId, srId, plaid_item_id }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/sr/${srId}/payWithAchUser`,
      method: "POST",
      body: JSON.stringify({ plaid_item_id }),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function payWithSavedPaymentMethod({
  hoaId,
  external_id,
  payment_method_id,
}) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/sr/${external_id}/payWithSavedPaymentMethod`,
      method: "POST",
      body: JSON.stringify({ payment_method_id }),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function payWithSavedPaymentMethodInvoice({
  hoaId,
  invoice_id,
  payment_method_id,
}) {
  return request(
    {
      url:
        API_BASE_URL +
        `hoa/${hoaId}/invoice/${invoice_id}/payWithSavedPaymentMethod`,
      method: "POST",
      body: JSON.stringify({ payment_method_id }),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function plaidData({ hoaId, body, receiver }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/getLinkToken` + urlParams({ receiver }),
      method: "GET",
      body: JSON.stringify(body),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function plaidDataSR({ hoaId, body }) {
  return request(
    {
      url: API_BASE_URL + `hoa/${hoaId}/getLinkTokenHoa`,
      method: "POST",
      body: JSON.stringify(body),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

// export function plaidDataHelpsquad({ hoaId, body }) {
//   return request(
//     {
//       url: API_BASE_URL + `hoa/${hoaId}/getLinkTokenHelpsquad`,
//       method: "POST",
//       body: JSON.stringify(body),
//     },
//     { "X-CSRFToken": getCsrfToken() }
//   );
// }

export function getplaidDataHoa({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + "plaidItems/hoa/" + hoaId + "/",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getplaidDataHelpsquad({}) {
  return request(
    {
      url: API_BASE_URL + "plaidItems/helpsquad",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getplaidDataHelpsquadFromHoa({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + "plaidItems/hoa/" + hoaId + "/helpsquad_from_hoa",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getBoardMeetings({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/board_meetings/",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getBoardMeeting({ hoaId, boardMeetingId }) {
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaId +
        "/board_meetings/" +
        boardMeetingId +
        "/",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function addBoardMeeting({ hoaId, data, method = "POST", id }) {
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaId +
        "/board_meetings/" +
        (method === "PATCH" ? id + "/" : ""),
      method: method,
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function conductMeeting({ hoaId, boardMeetingId, data }) {
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaId +
        "/board_meeting" +
        "/conduct_meeting/" +
        boardMeetingId +
        "/",
      method: "PATCH",
      body: JSON.stringify(data),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function startBoardMeeting({ hoaId, boardMeetingId }) {
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaId +
        "/board_meetings/" +
        boardMeetingId +
        "/markStart",
      method: "POST",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function endBoardMeeting({ hoaId, boardMeetingId }) {
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaId +
        "/board_meetings/" +
        boardMeetingId +
        "/markComplete",
      method: "POST",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function postBoardMeetingMinutes({ hoaId, boardMeetingId, data }) {
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaId +
        "/board_meetings/" +
        boardMeetingId +
        "/saveMinutes",
      method: "POST",
      body: JSON.stringify({
        minutes: data,
      }),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
export function updateDueDate({ hoaId, dueDate }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/updateDueDate/" + dueDate,
      method: "POST",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function verifyAccountsPayable({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/accounting/verifyAccountsPayable",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function verifyAccountsReceivable({ hoaId }) {
  return request(
    {
      url:
        API_BASE_URL + "hoa/" + hoaId + "/accounting/verifyAccountsReceivable",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getCreateRevision({ hoaId, documentId }) {
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaId +
        "/compliance_document/" +
        documentId +
        "/",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function uploadCreateRevision({ hoaId, documentId, data }) {
  let form_data = new FormData();
  form_data.append("csrfmiddlewaretoken", getCsrfToken());
  Object.keys(data).map((val) => form_data.append(val, data[val]));
  return request(
    {
      url:
        API_BASE_URL +
        "hoa/" +
        hoaId +
        "/compliance_documents/" +
        documentId +
        "/update",
      method: "POST",
      body: form_data,
    },
    { "X-CSRFToken": getCsrfToken() },
    true
  );
}

export function getHoaEnabledModules({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/enabled_features",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getBankAccounts({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + "hoa/" + hoaId + "/getBankAccounts",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getAddressAutoComplete({ address, state = "CA" }) {
  return request(
    {
      url: API_BASE_URL + "address/getMelissaAddresses",
      method: "POST",
      body: JSON.stringify({
        address,
        state,
      }),
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}

export function getHoaAddress({ hoaId }) {
  return request(
    {
      url: API_BASE_URL + "hoas/" + hoaId + "/",
      method: "GET",
    },
    { "X-CSRFToken": getCsrfToken() }
  );
}
