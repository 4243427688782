import React, { useEffect, useState } from "react";

import CommentSection from "./discussionCommentSection/CommentSection";
import { getArchitecturalComment } from "../../../../api/ApiV2";
import { LoadingCircleIcon } from "../../../common/AppIcons";
import { userAtom } from "../../../../store/UserAtom";
import { useRecoilState } from "recoil";

export default function Discussion({ plan_data, hoaId }) {
  const [user] = useRecoilState(userAtom);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState();

  useEffect(() => {
    getRecommendation();
  }, [user.selectedUserProfile]);

  const getRecommendation = () => {
    getArchitecturalComment({ hoaId, id: plan_data.id })
      .then((res) => {
        console.log(res);
        setComments(res.results);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
      });
  };

  if (loading)
    return (
      <div className="w-full py-20 flex justify-center items-center">
        <LoadingCircleIcon className="h-7" />
      </div>
    );
  else
    return (
      <div className="min-w-full flex flex-col mt-10">
        <CommentSection
          read_only={plan_data?.status !== "OPEN"}
          show_comment_box
          member={{
            first_name: plan_data?.created_by?.first_name ?? "",
            last_name: plan_data?.created_by?.last_name ?? "",
          }}
          hoaId={hoaId}
          id={plan_data.id}
          get_discussion_comments={getRecommendation}
          comments={comments}
        />
      </div>
    );
}
