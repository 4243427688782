import React, { useState, useEffect, useRef } from "react";
import { SearchIcon } from "../../../../common/AppIcons";
import { ProfilePic } from "../constants";

export default function SelectAttendeesPopup({
  view,
  set_view,
  eventEditor,
  value,
  onChange,
  members,
}) {
  const [search_params, set_search_params] = useState("");
  const [filtered_members, set_filtered_members] = useState();
  const [selected_members, set_selected_members] = useState(value);
  const [renderer, set_renderer] = useState(false);
  const searchInputRef = useRef(null);

  useEffect(() => {
    set_selected_members(view ? value : []);
  }, [view]);

  useEffect(() => {
    let temp_filtered_members = members;

    if (search_params !== "")
      temp_filtered_members = temp_filtered_members.filter((val) => {
        return (
          search_params.toUpperCase() ===
            val.first_name?.substring(0, search_params.length)?.toUpperCase() ||
          search_params.toUpperCase() ===
            val.last_name?.substring(0, search_params.length)?.toUpperCase()
        );
      });

    set_filtered_members(temp_filtered_members);
  }, [search_params, members]);

  return (
    <div
      className={
        "z-20 fixed inset-0 max-h-full flex items-center justify-center bg-opacity-20 bg-black " +
        (view ? "" : "hidden")
      }
      onClick={() => set_view()}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ maxHeight: "75%" }}
        className="max-w-lg w-full pt-7 rounded-md bg-white flex flex-col"
      >
        <div className="mx-8 flex flex-col gap-2">
          <span className="text-xl font-semibold">Add Mandatory Attendees</span>
          <span className="text-sm text-gray-500">
            Select from the list below
          </span>
        </div>

        <div className="px-8 mt-6 mb-4">
          <div
            onClick={() => {
              searchInputRef?.current.focus();
            }}
            className="w-full flex flex-row items-center px-2 rounded bg-gray-100"
          >
            <SearchIcon className="h-5 w-5" />
            <input
              ref={searchInputRef}
              className="focus:outline-none p-2 text-gray-700 bg-gray-100"
              placeholder="Search for Attendees"
              value={search_params}
              onChange={(e) => set_search_params(e.target.value)}
            />
          </div>
        </div>

        <div className="py-2 flex flex-col overflow-auto">
          {filtered_members?.map((ele, ele_index) => (
            <div
              key={ele_index}
              className="flex flex-row justify-between items-center px-8 py-2"
            >
              <div className="flex flex-row gap-4 items-center">
                <ProfilePic
                  className="h-12 w-12 flex items-center justify-center"
                  member={ele.user}
                />{" "}
                <span className="font-semibold">
                  {(ele.user?.first_name ?? "") +
                    " " +
                    (ele.user?.last_name ?? "")}
                </span>
              </div>
              <button
                onClick={() => {
                  if (
                    ele.user?.id === eventEditor?.secretary ||
                    ele.user?.id === eventEditor?.chairman
                  )
                    return;
                  if (
                    selected_members.find(
                      (val) => val.user?.id === ele.user?.id
                    )
                  ) {
                    let temp_arr = selected_members;
                    let index = null;
                    temp_arr.map((val, index_local) => {
                      if (val.user?.id === ele.user?.id) index = index_local;
                    });
                    temp_arr.splice(index, 1);
                    set_selected_members(temp_arr);
                  } else
                    set_selected_members((selected_members) => [
                      ...selected_members,
                      ele,
                    ]);
                  set_renderer((renderer) => !renderer);
                }}
                className={
                  "outline-none rounded-sm shadow-sm py-1 px-4 border hover:opacity-60 " +
                  (ele?.user?.id === eventEditor?.secretary ||
                  ele.user?.id === eventEditor?.chairman
                    ? "border-green-500 text-green-600 opacity-60 cursor-default"
                    : selected_members.find((val) => val.id === ele.id)
                    ? "border-red-500 text-red-600"
                    : "border-gray-400 text-gray-600")
                }
              >
                {ele.user?.id === eventEditor?.secretary ||
                ele.user?.id === eventEditor?.chairman
                  ? "Added"
                  : selected_members.find(
                      (val) => val.user?.id === ele.user?.id
                    )
                  ? "Remove"
                  : "Add"}
              </button>
            </div>
          ))}
        </div>

        <div className="mx-8 my-4 flex flex-row justify-between gap-6">
          <button
            onClick={(e) => {
              e.stopPropagation();
              set_view();
            }}
            className="outline-none rounded-sm py-2 w-full border border-gray-400 hover:bg-gray-200 text-gray-600 hover:text-gray-700 text-lg"
          >
            Cancel
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onChange(selected_members);
              set_view();
            }}
            className="outline-none rounded-sm py-2 w-full bg-indigo-600 hover:bg-indigo-700 text-white text-lg"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}
