import React, { useState, useEffect, useRef } from "react";

export default function SwipeableScreens({
  className,
  currentSlide,
  children,
  rerenderers = [],
  innerClassName,
}) {
  const [max_height, set_max_height] = useState();
  const currentSlideRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    updateSize();
  }, [currentSlide, ...rerenderers]);

  const updateSize = () => {
    set_max_height(currentSlideRef?.current?.offsetHeight + 40);
  };
  const swipingStyle = (cardNo, slide) => {
    const slideStyle = children.map((_, slide) => {
      return { transform: `translateX(${0 - slide * 100}%)` };
    });

    if (cardNo === slide) return slideStyle[slide - 1];
    else return { ...slideStyle[slide - 1], display: "hidden" };
  };
  if (typeof window !== "undefined")
    return (
      <div
        className={
          "max-w-full flex flex-row items-start overflow-hidden " + className
        }
        style={{
          maxHeight: max_height,
          maxWidth: "100%",
        }}
      >
        {children.map((child, renderIndex) => (
          <div
            key={renderIndex}
            ref={currentSlide === renderIndex + 1 ? currentSlideRef : null}
            className={
              "flex flex-col min-w-full transition duration-300 delay-100 overflow-x-hidden " +
              innerClassName
            }
            style={{
              ...swipingStyle(renderIndex + 1, currentSlide),
              maxWidth: "100%",
            }}
          >
            {child}
          </div>
        ))}
      </div>
    );
  else return null;
}
