import React from "react";
import { TickInBoxIcon2 } from "../../../../common/AppIcons";

export default function AcceptPopup({ set_show, api_call }) {
  return (
    <div className="w-full bg-white rounded-md p-12 pt-20 flex flex-col items-center">
      <div className="p-10 rounded-full bg-green-100 text-green-600">
        <TickInBoxIcon2 className="h-12 w-12" />
      </div>
      <span className="mt-10 text-lg font-semibold">
        Are you sure you want to approve this event ?
      </span>
      <button
        onClick={api_call}
        className="mt-6 px-8 py-3 text-sm bg-green-500 font-semibold text-white rounded hover:opacity-60"
      >
        Yes, Approve event
      </button>
      <button
        onClick={() => set_show()}
        className="py-3 text-sm text-gray-600 font-semibold hover:opacity-60"
      >
        Cancel
      </button>
    </div>
  );
}
