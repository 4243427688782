// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TransitionGen from "./Transition.gen";

var make = TransitionGen.make;

export {
  make ,

}
/* make Not a pure module */
