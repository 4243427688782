import React, { useState } from "react";

import Row from "../../../../../common/Form/table/Row";
import Heading from "../../../../../common/Form/table/Heading";
import FormInput from "../revenueDataForm/component/FormInput";
import { tableHeadings } from "./data/tableHeading";
import { tableRows } from "./data/tableRows";
import { PlusInCircleIcon2 } from "../../../../../common/AppIcons";
import { accountingInputClassName } from "../../../../common/data/inputClassName";

export default function RevenueTable({
  source,
  data,
  set_data,
  accounts,
  deleteItem,
  findItemTotal,
  add_new_row,
  set_create_account_popup,
  readOnly,
}) {
  // true='table' false='amountField'
  const table_or_amountField =
    source !== "HOA_CREATION" &&
    !(readOnly && (!data.items || data.items.length === 0));

  const onChange = (value, name, index) => {
    var a = data?.items;
    a[index][name] = value;
    set_data({ ...data, items: a });
  };

  return (
    <div className="">
      {table_or_amountField ? (
        <div className="font-semibold text-sm">
          <Heading
            headings={tableHeadings}
            style={{ backgroundColor: "#F5F5F5" }}
            className="grid grid-cols-9 gap-5 pl-4 pr-8 py-3 rounded text-gray-500"
            readOnly={readOnly}
          />
          {data.items?.map((value, index) => {
            return (
              <Row
                key={index}
                row={tableRows({
                  value,
                  index,
                  set_create_account_popup,
                  onChange,
                  accounts,
                  findItemTotal,
                })}
                deleteItem={
                  data.items?.length > 1
                    ? () => {
                        deleteItem(value.id);
                      }
                    : null
                }
                readOnly={readOnly}
                className="grid grid-cols-9 gap-5 pl-4 pr-8 py-3 text-gray-800 items-center"
              />
            );
          })}
        </div>
      ) : (
        <div className="w-full flex items-center justify-center">
          <FormInput
            label="Amount"
            type="currency"
            value={parseFloat(data.netTotal)}
            disabled={readOnly}
            onChange={(value) => {
              if (!readOnly) set_data({ ...data, netTotal: value });
            }}
            className={accountingInputClassName + " w-72 py-2 px-3"}
          />
        </div>
      )}

      {!readOnly && source !== "HOA_CREATION" && (
        <button
          className="mt-10 text-gray-700 font-semibold py-2 px-4 text-sm hover:opacity-70 focus:outline-none flex flex-row items-center gap-2"
          // onClick={() => {
          //   if (accounts && accounts.length !== 0) {
          //     set_openModal(!openModal);
          //   }
          // }}
          onClick={add_new_row}
        >
          <PlusInCircleIcon2 className="text-green-500 h-4" />{" "}
          <span style={{ color: "#2A32FD" }}>Add New Item</span>
        </button>
      )}
    </div>
  );
}
