import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ArrowSimpleIcon2, CondoIcon } from "../../../../common/AppIcons";
import UnitDetails from "./UnitsDetails";
import {
  getUnits,
  getBuildings,
  getHoaMembers,
} from "../../../../../api/ApiV2";

export default function UnitsPropertyList({
  hoaData,
  setHoaData,
  previousPage,
  nextPage,
}) {
  const [openUnitForm, setOpenUnitForm] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getBuildings({ hoaId: hoaData.hoaId })
      .then((res) => {
        console.log(res);
        fetchUnits();
        setHoaData((hoaData) => {
          return {
            ...hoaData,
            buildings: res,
          };
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err?.message ?? "Error fetching buildings");
      });
  }, []);

  const fetchUnits = () => {
    getUnits({ hoaId: hoaData.hoaId })
      .then((res) => {
        console.log(res);
        fetchMembers();
        setLoading(false);
        setHoaData((hoaData) => {
          return {
            ...hoaData,
            units: res.results,
          };
        });
        if (hoaData.buildings?.length === 1) {
          setOpenUnitForm(hoaData.buildings[0].id);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.message ?? "Error fetching units");
      });
  };

  const fetchMembers = () => {
    getHoaMembers(hoaData.hoaId)
      .then((res) => {
        console.log(res);
        setHoaData((hoaData) => {
          return {
            ...hoaData,
            members: res.results,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.message ?? "Error fetching members");
      });
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center py-20">
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
          <span className="text-gray-700 font-semibold">Uploading...</span>
        </div>
      </div>
    );
  } else if (openUnitForm)
    return (
      <UnitDetails
        {...{
          buildingId: openUnitForm,
          hoaData,
          setHoaData,
          previousPage: () => {
            hoaData.buildings?.length === 1
              ? previousPage()
              : setOpenUnitForm();
          },
          nextPage: () => {
            if (hoaData.buildings?.length === 1) nextPage();
            else {
              setOpenUnitForm();
              fetchMembers();
              fetchUnits();
            }
          },
        }}
      />
    );
  else
    return (
      <div className="mt-12 max-w-4xl w-full mx-auto flex flex-col gap-4">
        <div className="mb-2 flex flex-row items-center justify-between gap-5">
          <span className="font-semibold text-gray-700">Buildings</span>
        </div>
        {hoaData.buildings?.map((building, buildingIndex) => (
          <div
            key={buildingIndex}
            className="flex flex-col bg-white p-5 rounded"
          >
            <div className="w-full flex flex-row items-center justify-between bg-gray-100">
              <div className="flex flex-row items-center gap-6 px-4 py-3 pr-10">
                <div
                  className="bg-indigo-100 p-3 rounded"
                  style={{ color: "#2A32FD" }}
                >
                  <CondoIcon className="h-10" />
                </div>
                <span className="">{building.street_address_1}</span>
              </div>
              <button
                style={{
                  backgroundColor: "#2A32FD",
                  border: "2px solid #2A32FD",
                }}
                className="mr-3 text-sm text-white hover:opacity-80 py-2 px-6 flex items-center justify-center gap-2 rounded"
                onClick={() => {
                  setOpenUnitForm(building.id);
                }}
              >
                Fill Unit Details of this Building
                <ArrowSimpleIcon2 className="h-3" />
              </button>
            </div>
          </div>
        ))}
        <div className="w-full mt-4 flex flex-row justify-end items-center gap-5">
          <button
            onClick={previousPage}
            className="bg-white text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 py-2 px-6 rounded"
          >
            Go Back
          </button>

          <button
            style={{
              backgroundColor: "#2A32FD",
              border: "2px solid #2A32FD",
            }}
            className="text-sm text-white hover:opacity-80 py-2 px-6 flex items-center justify-center rounded"
            onClick={nextPage}
          >
            Continue
          </button>
        </div>
      </div>
    );
}
