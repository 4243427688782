import { useState } from "react";
import { useRecoilState } from "recoil";

import { dateStringFormat } from "../../../../../utils/DateUtils";
import {
  ClockIcon,
  CrossInBoxIcon,
  TickInBoxIcon,
} from "../../../../common/AppIcons";
import { userAtom } from "../../../../../store/UserAtom";
import { adminTypes } from "../../../../../common/data/adminTypes";

export default function EventList({
  events_type_show,
  events = [],
  members,
  set_acceptRejectPopup,
}) {
  const [hoverBox, set_hoverBox] = useState();
  const [user] = useRecoilState(userAtom);

  const user_privilage = adminTypes.includes(user.selectedUserType);

  const current_events_list =
    events_type_show === "PENDING"
      ? events?.filter((event) => event.status === "PENDING")
      : events_type_show === "APPROVED"
      ? events?.filter((event) => event.status === "APPROVED")
      : events_type_show === "REJECTED"
      ? events?.filter((event) => event.status === "REJECTED")
      : [];

  const events_list = ({ event, index_main }) => (
    <div
      key={index_main}
      className={
        "p-3 bg-gray-100 hover:bg-white border border-gray-100 hover:border-indigo-600 hover:shadow text-gray-800 rounded px-5 flex gap-5 flex-col sm:flex-row sm:items-center justify-between translate-all duration-200 overflow-hidden "
      }
      onMouseEnter={() => set_hoverBox(index_main)}
      onMouseLeave={() => {
        set_hoverBox();
      }}
    >
      <div
        className={
          "flex flex-col py-3 items-start " +
          (event.status !== "PENDING" ? "flex-grow" : "")
        }
      >
        <span className="font-bold mt-1">{event.name}</span>
        <span className="mt-1 text-sm text-gray-600 break-words">
          {event.description}
        </span>
        <div className="mt-1 flex flex-row items-center gap-2 text-sm">
          <ClockIcon className="h-3.5" />
          <span>
            {dateStringFormat(event.start_time) +
              " " +
              new Date(event.start_time).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }) +
              " - " +
              dateStringFormat(event.end_time) +
              " " +
              new Date(event.end_time).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
          </span>
        </div>
        {
          <div className="mt-2 flex flex-row items-center gap-2 text-gray-800 text-sm">
            <span className="text-gray-600">
              {event.status === "PENDING" ? "Requested by" : "Created By"} :{" "}
            </span>
            {event?.created_by?.first_name ?? ""}{" "}
            {event?.created_by?.last_name ?? ""}
          </div>
        }

        {event.decision_taken_by && (
          <div className="mt-1 flex flex-row items-center gap-2 text-gray-800 text-sm">
            <span className="text-gray-600">
              {" "}
              {event.status === "APPROVED"
                ? "Approved by"
                : "Rejected by"} :{" "}
            </span>
            {event.decision_taken_by?.first_name ?? ""}{" "}
            {event.decision_taken_by?.last_name ?? ""}
          </div>
        )}

        {event.reason_for_rejection && (
          <div className="mt-2 bg-white w-full text-sm py-3 px-4 rounded flex flex-row items-start">
            <span className="text-red-600 font-semibold">
              Reason for Rejection:{" "}
            </span>
            <span className="ml-1 flex-grow words-break text-gray-500 italic">
              {event.reason_for_rejection}
            </span>
          </div>
        )}
      </div>

      {user_privilage && events_type_show === "PENDING" && (
        <div
          className={
            "px-4 flex flex-col justify-center gap-3 duration-0 " +
            (hoverBox === index_main ? "" : "invisible")
          }
        >
          {[
            {
              label: "Approve Event",
              className: "bg-green-100 text-green-500",
              icon: TickInBoxIcon,
              value: "ACCEPT",
            },
            {
              label: "Reject Event",
              className: "bg-red-100 text-red-500",
              icon: CrossInBoxIcon,
              value: "REJECT",
            },
          ].map((button, button_index) => {
            let Icon = button.icon;
            return (
              <button
                key={button_index}
                onClick={() =>
                  user_privilage &&
                  set_acceptRejectPopup({ popup: button.value, id: event.id })
                }
                className={
                  "text-sm rounded px-2 py-1 flex flex-row items-center gap-3 duration-300 hover:opacity-60 border " +
                  button.className
                }
              >
                <Icon className="h-4" />
                {button.label}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );

  return (
    <div className="mx-1 mb-4 flex flex-col gap-3 overflow-auto p-3">
      <div className={"flex flex-col px-1 pb-1"}>
        <div
          className={
            "translate-all duration-300 overflow-hidden h-full flex flex-col gap-3"
          }
        >
          {current_events_list.length === 0 ? (
            <span className="w-full text-center text-gray-500 font-semibold capitalize">
              No {events_type_show.toLowerCase()} Events
            </span>
          ) : (
            current_events_list
              ?.sort(
                (ele1, ele2) =>
                  new Date(ele2.start_time) - new Date(ele1.start_time)
              )
              .map((event, index1) => {
                return events_list({
                  event: event,
                  index_main: index1,
                });
              })
          )}
        </div>
      </div>
    </div>
  );
}
