import React from "react";
import { amountFormat } from "../../../common/AmountFormat";
import {
  DollarNegativeIcon,
  DollarPosativeIcon,
} from "../../../common/AppIcons";

export default function BalanceCard({
  label,
  balance,
  orientation = Number(balance) > 0,
  className,
  style = {},
}) {
  return (
    <div
      className={"px-4 py-4 flex flex-col gap-5 bg-white rounded " + className}
      style={style}
    >
      <div className="flex flex-row items-center gap-2">
        <div
          className={
            "rounded p-1.5 " + (orientation ? "bg-green-200" : "bg-red-200")
          }
          style={{
            backgroundColor: orientation
              ? "rgba(250, 199, 166, 0.35)"
              : "rgba(194, 187, 253, 0.41)",
          }}
        >
          {orientation ? (
            <DollarNegativeIcon
              style={{ color: "#FF8D00" }}
              className="h-5 w-5 text-green-500"
            />
          ) : (
            <DollarPosativeIcon
              style={{ color: "#8D7CFE" }}
              className="h-4 w-4 m-px"
            />
          )}
        </div>
        <span className="text-gray-500 font-light text-sm">{label}</span>
      </div>

      <div className="flex flex-row items-end gap-2 font-bold">
        <span className="text-xl">
          {amountFormat(isNaN(balance) ? 0 : balance)}
        </span>
        <span className="text-gray-500 font-semibold relative bottom-px">
          USD
        </span>
      </div>
    </div>
  );
}
