import { Link } from "raviger";
import React from "react";

export default function GetStarted({ className }) {
  return (
    <div
      className={
        "relative sm:pb-4 sm:px-12 h-60 sm:h-96 w-full sm:max-w-6xl mx-auto flex flex-col sm:flex-row items-center justify-center md:justify-around gap-4 " +
        className
      }
    >
      <img
        src="landing_pic_2.png"
        className="h-full w-full object-fill absolute inset-0"
      />
      <div className="w-full relative flex flex-row items-center flex-wrap justify-center gap-3 md:gap-8 lg:gap-16">
        <span
          style={{ fontWeight: "700" }}
          className="relative max-w-xs md:max-w-xl text-white text-sm sm:text-lg md:text-3xl"
        >
          Elevate your Property’s management and decision-making with our tools
        </span>

        <Link href="/register">
          <div
            style={{
              background:
                "linear-gradient(89.97deg, #00D2FF 0.03%, #2A32FD 99.97%)",
            }}
            className="button_animation_2 relative rounded-md px-4 sm:px-10 py-1 sm:py-3 text-sm sm:text-base text-white font-semibold hover:opacity-90"
            data-text="Get Started"
          >
            <span className="whitespace-pre align-middle block">
              Get Started
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
}
