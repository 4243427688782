import React from "react";

import Row from "../../../../../common/Form/table/Row";
import Heading from "../../../../../common/Form/table/Heading";
import FormInput from "../billDataForm/component/FormInput";
import { tableHeadings } from "./data/tableHeading";
import { tableRows } from "./data/tableRows";
import { PlusInCircleIcon2 } from "../../../../../common/AppIcons";
import { accountingInputClassName } from "../../../../common/data/inputClassName";

export default function BillTable({
  source,
  className,
  only_data_total_amount,
  set_only_data_total_amount,
  delete_warning,
  data,
  setData,
  delete_bill_row,
  add_bill_row,
  warning,
  set_warning,
  expenseAccounts,
  readOnly,
}) {
  // true='table' false='amountField'
  const table_or_amountField = data.legs?.length > 0;

  const onChange = (value, name, index) => {
    let temp_warning = warning;
    delete temp_warning?.transaction?.legs?.[index]?.[name];
    set_warning(temp_warning);

    if (!readOnly)
      setData({
        ...data,
        legs: data.legs?.map((leg, mapIndex) =>
          mapIndex === index ? { ...leg, [name]: value } : leg
        ),
      });
  };

  return (
    <div className={className}>
      {table_or_amountField ? (
        <div className="font-semibold text-sm">
          <Heading
            headings={tableHeadings}
            style={{ backgroundColor: "#F5F5F5" }}
            className={
              "w-full grid grid-cols-5 gap-5 py-3 rounded text-gray-500 " +
              (readOnly ? "px-8" : "pr-4 pl-8")
            }
            readOnly={readOnly}
          />
          {data.legs?.map((value, index) => {
            return (
              <Row
                key={index}
                row={tableRows({
                  value,
                  index,
                  onChange,
                  expenseAccounts,
                  warning,
                  set_warning,
                  readOnly,
                })}
                deleteItem={
                  source === "HOA_CREATION" || data.legs?.length > 1
                    ? () => {
                        delete_bill_row(index);
                      }
                    : undefined
                }
                readOnly={readOnly}
                className={
                  "w-full grid grid-cols-5 gap-5 py-3 text-gray-800 " +
                  (readOnly ? "px-8" : "pl-8")
                }
              />
            );
          })}
        </div>
      ) : (
        <div className="w-full flex items-center justify-center">
          <FormInput
            label="Amount"
            type="currency"
            value={parseFloat(only_data_total_amount)}
            disabled={readOnly}
            onChange={(value) => {
              delete_warning("amount");
              if (!readOnly) set_only_data_total_amount(value);
            }}
            className={accountingInputClassName + " w-60 sm:w-80 py-2 px-3"}
          />
        </div>
      )}

      {!readOnly && source !== "HOA_CREATION" && (
        <button
          className="mt-6 text-gray-700 font-semibold py-2 px-4 text-sm hover:opacity-70 focus:outline-none flex flex-row items-center gap-2"
          onClick={add_bill_row}
        >
          <PlusInCircleIcon2 className="text-green-500 h-4" />{" "}
          <span style={{ color: "#2A32FD" }}>Add New Item</span>
        </button>
      )}
    </div>
  );
}
