import { useState, useRef } from "react";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "./linkify.css";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import ReactMarkdown from "react-markdown";
import { useRecoilState } from "recoil";

import {
  board_meeting_details,
  agenda_details,
} from "./markdownContant/board_meeting_details";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Modal from "../../../common/Modal";
import { LoadingCircleIcon2, editIcon } from "../../../common/AppIcons";
import { userAtom } from "../../../../store/UserAtom";
import { editBoardMeeting } from "../../../../api/ApiV2";
import { adminTypes } from "../../../../common/data/adminTypes";

const string_concatenation = (arr) => {
  let string = "";
  for (let i = 0; i < arr?.length; i++) {
    string = string + "" + arr[i] + " ";
  }
  return string;
};

export default function EditMinutes({ hoaId, meeting, members, onClose }) {
  const pdfPageRef = useRef(null);

  const [save_meeting_popup, set_save_meeting_popup] = useState(false);
  const [page, set_page] = useState("VIEW_MINUTES");
  // VIEW_MINUTES, EDIT_MINUTES
  const [loading, setLoading] = useState(false);
  const [editor_state, set_editor_state] = useState(
    EditorState.createWithContent(
      convertFromRaw(
        markdownToDraft(
          meeting.minutes
            ? meeting.minutes
            : " " +
                board_meeting_details({
                  meeting: meeting,
                  members: members,
                }) +
                "    \n ## **Agenda**   " +
                string_concatenation(
                  meeting.agenda?.map((agenda, index2) =>
                    agenda_details({
                      index: index2,
                      agenda: agenda,
                      members: members,
                    })
                  )
                )
        )
      )
    )
  );
  const [user] = useRecoilState(userAtom);

  const enable_save_minutes = adminTypes.includes(user.selectedUserType);

  const handleGeneratePdf = () => {
    setLoading(true);
    const doc = new jsPDF({
      format: "a3",
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(pdfPageRef.current, {
      async callback(doc) {
        doc.output("dataurlnewwindow");
        setLoading(false);
      },
    });
  };

  const post_board_meeting_minutes = () => {
    setLoading(true);
    editBoardMeeting({
      hoaId,
      id: meeting.id,
      data: {
        ...meeting,
        minutes:
          editor_state &&
          draftToMarkdown(convertToRaw(editor_state?.getCurrentContent()), {
            preserveNewlines: true,
          }),
      },
    })
      .then((res) => {
        setLoading(false);
        console.log(res);
        set_save_meeting_popup(false);
        toast.success("Meeting Minutes Saved Successfully");
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
        set_save_meeting_popup(false);
        toast.error("Error Saving Meeting Minutes");
      });
  };

  return (
    <div
      className={
        "bg-gray-100 text-gray-800 border rounded m-1 px-3 py-2 flex flex-col gap-2 translate-all duration-300 overflow-auto"
      }
    >
      <div className="w-full flex flex-row-reverse gap-3">
        <button
          onClick={() => {
            handleGeneratePdf();
          }}
          className="py-1 px-3 mt-2 rounded-md tracking-wide flex items-center shadow border  bg-green-500 hover:bg-green-600 text-white"
        >
          Export to PDF
        </button>
        {page === "VIEW_MINUTES" && (
          <button
            onClick={() => {
              set_page("EDIT_MINUTES");
            }}
            className="py-1 px-3 mt-2 rounded-md tracking-wide flex flex-row gap-1 items-center shadow border bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            {editIcon("h-4 w-4 transform scale-75")} Edit Minutes
          </button>
        )}
      </div>
      <Editor
        readOnly={page === "VIEW_MINUTES"}
        initialEditorState={editor_state}
        editorState={editor_state}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "list",
            "link",
            "emoji",
            "image",
            "remove",
            "history",
          ],
          inline: {
            options: ["bold", "italic", "strikethrough", "monospace"],
          },
        }}
        toolbarClassName={
          "" + (page === "VIEW_MINUTES" ? "h-0 w-0 overflow-hidden" : "")
        }
        wrapperClassName="bg-white border focus:border-2 border-gray-400"
        editorClassName="px-3 py-2"
        onEditorStateChange={(editorState) => {
          console.log(
            editor_state &&
              draftToMarkdown(convertToRaw(editor_state?.getCurrentContent()), {
                preserveNewlines: true,
              })
          );
          set_editor_state(editorState);
        }}
      />

      <div className="w-full flex flex-row gap-2 justify-end">
        <button
          onClick={() => {
            onClose();
          }}
          className="border-none focus:outline-none font-bold text-gray-600 hover:text-gray-800 px-2 py-1 relative top-1"
        >
          Close
        </button>
        {enable_save_minutes && (
          <button
            onClick={() => {
              set_save_meeting_popup(true);
            }}
            className="py-1 px-3 mt-2 rounded-md tracking-wide flex items-center shadow border font-bold bg-blue-500 hover:bg-blue-600 text-white"
          >
            Save
          </button>
        )}
      </div>
      {/* pdf page that prints */}
      <div className="h-0 overflow-hidden w-0">
        <div
          style={{
            width: "630px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          ref={pdfPageRef}
        >
          <div
            style={{
              textAlign: "center",
              fontWeight: "900",
              fontSize: "1.875rem",
              textDecoration: "underline",
            }}
          >
            Board Meeting
          </div>
          <ReactMarkdown className="whitespace-pre-wrap  ">
            {editor_state &&
              draftToMarkdown(convertToRaw(editor_state?.getCurrentContent()), {
                preserveNewlines: true,
              }).replaceAll("#", "##")}
          </ReactMarkdown>
        </div>
      </div>
      <Modal show={loading} setShow={() => {}}>
        <LoadingCircleIcon2 className={"h-10 mx-auto my-5"} />
      </Modal>
      <Modal show={save_meeting_popup} setShow={() => {}}>
        <div className="p-4 flex flex-col gap-5 text-gray-600 font-semibold text-lg">
          Save this board meeting minutes ?
          <div className="flex flex-row w-full justify-end gap-4">
            <button
              onClick={() => {
                set_save_meeting_popup(false);
              }}
              className="px-2 py-1 text-blue-500 hover:text-blue-600 font-semibold"
            >
              Cancel
            </button>
            <button
              onClick={post_board_meeting_minutes}
              className="px-2 py-1 rounded border shadow text-white bg-indigo-500 hover:bg-indigo-600 font-semibold"
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
