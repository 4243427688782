import React from "react";
import { accountingInputClassName } from "../../../../../common/data/inputClassName";

import { amountFormat } from "../../../../../../common/utility/amountFormat";
import { amountFormat as cr_or_dr_amount } from "../../../../../../common/AmountFormat";

const fieldClassName = accountingInputClassName + " w-full";

export const tableRows = ({
  value,
  index,
  onChange,
  expenseAccounts,
  warning,
  set_warning,
  readOnly,
}) => [
  {
    label: value?.description,
    className: "col-span-2",
    input: {
      type: "textarea",
      className: fieldClassName + " px-3 py-2 h-10",
      placeholder: "Enter item name/description",
      warning: warning?.transaction?.legs?.[index]?.description?.[0],
      value: value?.description,
      onChange: (val1) => onChange(val1, "description", index),
    },
  },
  {
    label: expenseAccounts?.filter((account) => {
      return account.id === value?.account ?? value?.expense_account;
    })[0]?.name,
    className: "col-span-2 justify-start",
    input: {
      type: "dropdown",
      className: fieldClassName,
      warning: warning?.transaction?.legs?.[index]?.account?.[0],
      boxClassName: "max-h-40",
      value: value?.expense_account,
      onChange: (value1) => {
        let temp_warning = warning;
        delete temp_warning?.transaction?.legs?.[index]?.account;
        set_warning(temp_warning);
        onChange(value1, "expense_account", index);
      },

      options: expenseAccounts?.map((account) => {
        return {
          word: account.name,
          label: readOnly ? (
            account.name
          ) : (
            <div className="grid grid-cols-4 w-full px-1 text-xs font-normal">
              <span className="col-span-3 break-all">{account.name}</span>
              <span className="break-word text-right">
                {cr_or_dr_amount(account.current_amount)}
              </span>
            </div>
          ),
          value: account.id,
        };
      }),
    },
  },
  {
    label: amountFormat(
      value?.amount
        ? value?.amount
        : Number(value.quantity) * Number(value.unit_price)
    ),
    className: "justify-end",
    input: {
      type: "currency",
      warning: warning?.transaction?.legs?.[index]?.amount?.[0],
      className: fieldClassName + " px-3 py-2",
      value: parseFloat(value?.amount),
      onChange: (value1) => {
        onChange(value1, "amount", index);
      },
    },
  },
];
