import { useEffect, useRef, useState } from "react";
import {
  CheckCircleIcon,
  CrossCicleIcon,
  CrossInCircleIcon,
  ImageIcon2,
  LoadingCircleIcon,
  LoadingCircleIcon2,
} from "../../../../common/AppIcons";
import {
  getAddressPhotos,
  getBuildings,
  uploadAddressPhoto,
} from "../../../../../api/ApiV2";
import { toast } from "react-toastify";

export default function PhotosUpload({ hoaId, previousPage, nextPage }) {
  const [building, setBuilding] = useState([]);
  const [files, setFiles] = useState([
    // {
    //   id: null,
    //   file: null,
    //   uploadStatus: "none" | "uploading" | "uploaded" | "failed"
    // }
  ]);
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [changePage, setChangePage] = useState(false);

  useEffect(() => {
    if (changePage) {
      nextPage();
    }
  }, [changePage]);

  useEffect(() => {
    if (building?.id && building.photos?.photos) {
      getAddressPhotos({ hoaId: hoaId, propertyID: building.id })
        .then((res) => {
          res?.photos &&
            Object.entries(res.photos).forEach(([key, value]) => {
              setFiles((files) =>
                [
                  ...files,
                  !files.find((val) => val.id === key) && {
                    id: key,
                    url: value.url,
                    uploadStatus: "uploaded",
                  },
                ].filter((val) => val)
              );
            });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [building]);

  useEffect(() => {
    setLoading(true);
    getBuildings({ hoaId: hoaId })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setBuilding(res?.[0]);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const uploadPhoto = () => {
    if (!files.filter((val) => val.uploadStatus !== "uploaded").length > 0)
      setChangePage(true);

    let apiLoading = [
      ...Array(files.filter((val) => val.uploadStatus !== "uploaded")),
    ].fill("loading");

    files
      .filter((val) => val.uploadStatus !== "uploaded")
      .forEach((file, index) => {
        setFiles((prev) =>
          prev.map((val) =>
            val.id === file.id ? { ...val, uploadStatus: "uploading" } : val
          )
        );
        uploadAddressPhoto({
          hoaId: hoaId,
          propertyID: building.id,
          data: { document: file.file },
        })
          .then((res) => {
            setFiles((prev) =>
              prev.map((val) =>
                val.id === file.id ? { ...val, uploadStatus: "uploaded" } : val
              )
            );
            apiLoading[index] = "success";
            if (apiLoading.every((val) => val === "success")) {
              setChangePage(true);
            }
            console.log(res);
          })
          .catch((res) => {
            setFiles((prev) =>
              prev.map((val) =>
                val.id === file.id ? { ...val, uploadStatus: "failed" } : val
              )
            );
            apiLoading[index] = "failed";
            if (apiLoading.every((val) => val === "success")) {
              setChangePage(true);
            }
            console.log(res);
            toast.error("Photo upload failed");
          });
      });
  };

  if (loading)
    return (
      <div className="w-full flex justify-center items-center py-20">
        <LoadingCircleIcon2 className="h-8" />
      </div>
    );
  else
    return (
      <div className="mt-10 max-w-3xl w-full mx-auto flex flex-col gap-10">
        <button
          onClick={() => ref.current?.click()}
          className="py-10 rounded border border-dashed text-blue-700 border-indigo-600 bg-indigo-100 flex flex-col items-center justify-center gap-5"
        >
          <ImageIcon2 className="h-10" />
          <div className="text-gray-500 text-sm">
            <button className="text-blue-600 hover:text-blue-400 hover:underline">
              Upload
            </button>{" "}
            some of the best photos of your property
          </div>
        </button>
        <div className="flex flex-row gap-4 flex-wrap">
          {files.map((file, fileIndex) => (
            <div key={fileIndex} className="mt-3">
              {file.uploadStatus !== "uploaded" ? (
                <div className="w-full flex justify-end">
                  <button
                    onClick={() => {
                      setFiles((files) =>
                        files.filter((file1) => file.id !== file1.id)
                      );
                    }}
                    className="relative z-20 top-2 left-2.5 transform duration-300 hover:scale-125"
                  >
                    <CrossCicleIcon className="h-4" />
                  </button>
                </div>
              ) : (
                <div className="h-4" />
              )}
              <a
                href={file.url ?? "#"}
                target="_blank"
                className="relative z-0"
              >
                <img src={file.url} className="h-32 w-60" />
                {file.uploadStatus !== "none" && (
                  <div
                    className={
                      "absolute h-32 w-60 z-10 p-2 inset-0 flex flex-col justify-end items-end"
                    }
                  >
                    <div className="p-1 rounded bg-white">
                      {file.uploadStatus === "uploading" ? (
                        <LoadingCircleIcon className="h-6 text-blue-500" />
                      ) : file.uploadStatus === "uploaded" ? (
                        <CheckCircleIcon className="h-6 text-green-500" />
                      ) : file.uploadStatus === "failed" ? (
                        <CrossInCircleIcon className="h-6 text-red-500" />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
              </a>
            </div>
          ))}
        </div>
        <div className="w-full mt-4 flex flex-row justify-center items-center gap-5">
          <button
            onClick={previousPage}
            className="text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 py-2 px-6 rounded"
          >
            Go Back
          </button>

          <button
            style={{
              backgroundColor: "#2A32FD",
              border: "2px solid #2A32FD",
            }}
            className="text-sm text-white hover:opacity-80 py-2 px-6 flex items-center justify-center rounded"
            onClick={() => {
              uploadPhoto();
            }}
            disabled={files.find((val) => val.uploadStatus === "uploading")}
          >
            {files.find((val) => val.uploadStatus === "uploading") ? (
              <LoadingCircleIcon2 className="h-5" />
            ) : (
              "Continue"
            )}
          </button>
        </div>
        <div className="hidden">
          <input
            type="file"
            multiple
            ref={ref}
            onChange={(e) => {
              let tempFiles = [];

              [...e.target.files]?.forEach((file) => {
                tempFiles.push({
                  id: new Date(),
                  file: file,
                  url: URL.createObjectURL(file),
                  uploadStatus: "none",
                });
              });
              setFiles((files) => [...files, ...tempFiles]);
            }}
          />
        </div>
      </div>
    );
}
