import { terms } from "../../../../../../common/Data";
import { accountingInputClassName } from "../../../../../common/data/inputClassName";

const default_className = accountingInputClassName + " w-64";

export const inputFieldData = (
  data,
  setData,
  vendors,
  delete_warning,
  setVendorPopup,
  warning,
  set_vendor_default_account,
  readOnly
) => {
  return {
    top: [
      {
        label: "Vendor",
        type: "dropdown",
        readOnly: readOnly,
        value: data?.vendor_object?.id ?? data.vendor,
        placeholder: readOnly ? "No vendor Found" : "Select vendor",
        warning: warning && warning["vendor"] && warning["vendor"][0],
        onChange: (tempVal) => {
          const val = tempVal;
          delete_warning("vendor");
          if (!readOnly) {
            if (val === "CREATE_VENDOR") setVendorPopup(true);
            else {
              setData({
                ...data,
                vendor: val,
                terms: terms.find(
                  (term) =>
                    vendors.filter((value) => value.id === val)[0]?.terms ===
                    term.value1
                )?.value,
                legs: data.legs?.map((value) => {
                  return {
                    ...value,
                    expense_account: vendors.filter(
                      (value) => value.id === val
                    )[0]?.default_account,
                  };
                }),
              });
              set_vendor_default_account(
                vendors.filter((value) => value.id === val)[0]?.default_account
              );
            }
          }
        },
        options: [
          { label: "Create Vendor", value: "CREATE_VENDOR" },
          ...vendors.map((vendor) => {
            return { label: vendor.display_name, value: vendor.id };
          }),
        ],
        className: default_className + " px-3 py-2",
      },

      {
        label: "Bill Date",
        type: "date",
        value: data.date ?? data.created_date,
        placeholder: "eg. 17 Feb, 2020",
        warning: warning && warning["date"] && warning["date"][0],
        onChange: (val) => {
          delete_warning("date");
          if (!readOnly) setData({ ...data, date: val });
        },
        readOnly: readOnly,
        className: default_className,
      },

      {
        label: "Term",
        type: "dropdown",
        readOnly: readOnly,
        value: data.terms,
        placeholder: "Select term",
        warning: warning && warning["terms"] && warning["terms"][0],
        onChange: (val) => {
          delete_warning("terms");
          if (!readOnly) setData({ ...data, terms: val });
        },
        options: terms,
        className: default_className + " px-3 py-2",
      },
      {
        label: "Due date",
        type: "date",
        value: data.due_date,
        placeholder: "eg. 17 Mar, 2020",
        warning: warning && warning["due_date"] && warning["due_date"][0],
        onChange: (val) => {
          delete_warning("due_date");
          if (!readOnly) setData({ ...data, due_date: val });
        },
        readOnly: readOnly,
        className: default_className,
      },
    ],
    bottom: [
      {
        label: "Reference Number",
        type: "text",
        value: data.reference_number,
        placeholder: "Add Reference Number...",
        warning:
          warning &&
          warning["reference_number"] &&
          warning["reference_number"][0],
        readOnly: readOnly,
        onChange: (value) => {
          delete_warning("reference_number");
          if (!readOnly) setData({ ...data, reference_number: value });
        },
        className: default_className + " w-max ml-8 py-1.5 px-2.5",
      },
    ],
  };
  return null;
};
