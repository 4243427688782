import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  getAllTransactions,
  getBill,
  getTransactionLegs,
} from "../../../../api/ApiV2";
import { LoadingCircleIcon } from "../../../common/AppIcons";
import AddRevenue from ".";

export default function RevanueDetailPopup({
  hoaId,
  onSuccess,
  billId,
  source,
  remove_pay,
  set_invoice_paid,
  cardPaymentStatus,
}) {
  const [bill, setBill] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (billId) {
      setLoading(true);

      getBill({
        hoaId,
        billId,
      })
        .then((res) => {
          console.log("res", res);
          setBill((bill) => ({ ...res, ...bill }));

          getAllTransactions({ hoaId, billId, type: "INVOICE" })
            .then((res) => {
              console.log("res", res);
              getTransactionLegs({ hoaId, transactionId: res.results?.[0]?.id })
                .then((res) => {
                  console.log("res", res);
                  setBill((bill) => ({
                    ...bill,
                    items: bill.items?.map((item) => ({
                      ...(res.results?.find((ele) => ele.id === item.leg) ??
                        {}),
                      ...item,
                    })),
                  }));
                  setLoading(false);
                })
                .catch((res) => {
                  toast.error("Error fetching transaction legs");
                  setLoading(false);
                  console.log(res);
                });
            })
            .catch((res) => {
              toast.error("Error fetching transaction detail");
              setLoading(false);
              console.log(res);
            });
        })
        .catch((res) => {
          toast.error("Error fetching bill details");
          setLoading(false);
          console.log(res);
        });
    }
  }, [billId]);

  if (loading)
    return (
      <div className="w-full flex items-center justify-center py-16">
        <LoadingCircleIcon className="h-6" />
      </div>
    );
  else
    return (
      <AddRevenue
        setShow={onSuccess}
        readOnly={true}
        invoice={bill}
        {...{
          hoaId,
          source,
          remove_pay,
          set_invoice_paid,
          cardPaymentStatus,
        }}
      />
    );
}
