import React from "react";

export default function SlotElement({ time, slots, style, onClick, checked }) {
  return (
    <div
      style={{
        ...style,
        ...(checked ? { backgroundColor: "#2A32FD", color: "white" } : {}),
      }}
      onClick={() => onClick()}
      className={
        "rounded flex flex-row items-center pl-5 pr-6 py-2 text-sm duration-300 border border-gray-100 hover:border-indigo-500 cursor-pointer"
      }
    >
      {time}{" "}
      <div className="h-px w-px overflow-visible">
        <div className="h-6 w-6 relative top-3 left-3 rounded-full flex items-center justify-center bg-green-600 text-white text-xs">
          {slots}
        </div>
      </div>
    </div>
  );
}
