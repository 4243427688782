import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import { getArchitecturalPlanDetails } from "../../../../api/ApiV2";
import { LeftArrowIcon, LoadingCircleIcon } from "../../../common/AppIcons";
import SelectHeadings from "../../../common/Form/SelectHeadings";
import Discussion from "./Discussion";
import GeneralInformation from "./GeneralInformation";
import Resolution from "./Resolution";
import { userAtom } from "../../../../store/UserAtom";
import { useRecoilState } from "recoil";

const headings = ["General Information", "Resolution", "Discussion"];

export default function ARReview({ hoaId, closePage, id }) {
  const [user] = useRecoilState(userAtom);
  const [page, set_page] = useState("General Information");
  const [plan_data, set_plan_data] = useState({});
  const [loading, set_loading] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    get_plan_details();
  }, [id, user.selectedUserProfile]);

  const get_plan_details = () => {
    getArchitecturalPlanDetails({ hoaId: hoaId, id: id })
      .then((res) => {
        set_loading(false);
        console.log(res);
        set_plan_data(res);
      })
      .catch((res) => {
        set_loading(false);
        console.log(res);
        toast.error(res?.message ?? "Error fetching plan details");
      });
  };

  if (loading)
    return (
      <div className="w-full flex items-center justify-center my-20">
        <LoadingCircleIcon className="h-10" />
      </div>
    );
  return (
    <div className="overflow-auto bg-white py-8 pl-12 pr-12 rounded-md flex flex-row items-start">
      <div className="w-8 flex justify-center pt-1">
        <button
          className="flex items-center relative pt-px top-px"
          onClick={closePage}
        >
          <LeftArrowIcon className="h-5 w-5 text-gray-700 hover:text-gray-500 cursor-pointer" />
        </button>
      </div>
      <div ref={ref} className="flex flex-grow flex-col">
        <div className="flex flex-row justify-between min-w-full items-center">
          <div className="flex flex-col">
            <span className="text-xl text-blue-600 font-bold">
              Architectural Plan Details - {plan_data.title}
            </span>

            <span className="mt-1 text-xs text-gray-500">
              Details for Proposed Architectural Alterations
            </span>
          </div>
        </div>

        <div className="pr-10 mt-10 flex flex-col">
          <SelectHeadings
            headings={headings.map((val) => {
              return { label: val, value: val };
            })}
            selected={page}
            onChange={(val) => {
              set_page(val);
            }}
            base_heading_width={170}
            className="relative top-1"
          />
          <hr />
          {page === "General Information" ? (
            <GeneralInformation hoaId={hoaId} plan_data={plan_data} />
          ) : page === "Resolution" ? (
            <Resolution
              hoaId={hoaId}
              plan_data={plan_data}
              closePage={closePage}
            />
          ) : page === "Discussion" ? (
            <Discussion plan_data={plan_data} hoaId={hoaId} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
