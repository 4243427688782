import React from "react";
import { CrossInBoxIcon } from "../../../../common/AppIcons";

export default function RejectPopup({ data, set_show, api_call }) {
  return (
    <div className="w-full bg-white rounded-md p-8 pt-10 flex flex-col items-center">
      <div className="p-10 rounded-full bg-red-100 text-red-600">
        <CrossInBoxIcon className="h-12 w-12" />
      </div>
      <div className="mt-10 w-full">
        <span className="font-semibold text-gray-700 text-sm">
          Reason For Rejection ?
        </span>
        <textarea
          className="mt-1 w-full h-20 bg-gray-100 focus:bg-gray-200 rounded p-3 border-none focus:outline-none focus:border-none"
          placeholder="Type your reason here..."
          value={data?.reason}
          onChange={(e) =>
            set_show((show) => {
              return { ...show, reason: e.target.value };
            })
          }
        />
      </div>
      <button
        onClick={api_call}
        className="mt-8 px-8 py-3 text-sm bg-red-600 font-semibold text-white rounded hover:opacity-60"
      >
        Reject Event
      </button>
      <button
        onClick={() => set_show()}
        className="py-3 text-sm text-gray-600 font-semiboldhover:opacity-60"
      >
        Cancel
      </button>
    </div>
  );
}
