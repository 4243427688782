import React, { useEffect, useRef, useState } from "react";
import draftToMarkdown from "draftjs-to-markdown";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { arch_review_plan_type } from "../constants";
import { LeftArrowIcon, LoadingAnimated } from "../../common/AppIcons";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { postArchitecturalPlans } from "../../../api/ApiV2";
import { userAtom } from "../../../store/UserAtom";
import { useRecoilState } from "recoil";

const toolbar_options = {
  options: [
    "inline",
    "blockType",
    "list",
    "link",
    "emoji",
    "image",
    "remove",
    "history",
  ],
  inline: {
    options: ["bold", "italic", "strikethrough", "monospace"],
  },
};

const default_editor_state = () => {
  return EditorState.createEmpty();
};

export default function CreateAR({ hoaId, closePage }) {
  const [data, set_data] = useState({
    title: "",
    plan_type: "",
    description: "",
    contractor_name: "",
    contractor_phone: "",
    planned_start_date: "",
    planned_end_date: "",
  });
  const [editor_state, set_editor_state] = useState({
    description: default_editor_state(),
  });
  const [editor_show, set_editor_show] = useState();
  const [warning, set_warning] = useState({});
  const [loading, set_loading] = useState(false);
  const parentRef = useRef(null);
  const [user] = useRecoilState(userAtom);
  const [renderer, setRenderer] = useState(false);

  const selectedProfile = user?.userProfiles?.find(
    (val) => val.id === user.selectedUserProfile
  );

  const is_there_error = () => {
    let temp_errors = {};
    if (data.title === "")
      temp_errors = { ...temp_errors, title: "Please fill this field" };
    if (data.plan_type === "")
      temp_errors = { ...temp_errors, plan_type: "Please select a type" };
    if (data.description === "")
      temp_errors = {
        ...temp_errors,
        description: "Please fill this field",
      };
    // if (!selectedProfile?.unit_object)
    //   temp_errors = {
    //     ...temp_errors,
    //     unit_object: "No unit found for selected user profile",
    //   };

    set_warning({ ...temp_errors });

    if (Object.keys(temp_errors).length === 0) return false;
    else return true;
  };

  const remove_warning = (name) => {
    let temp_warnings = warning;
    delete temp_warnings[name];
    set_warning(temp_warnings);
    setRenderer(!renderer);
  };

  useEffect(() => {
    remove_warning("unit_object");
  }, [selectedProfile?.unit_object]);

  const submit_AR = () => {
    if (!is_there_error() && !loading) {
      set_loading(true);
      let temp_data = {};
      Object.keys(data).map((key) => {
        if (data[key] !== "") temp_data = { ...temp_data, [key]: data[key] };
      });
      temp_data = {
        ...temp_data,
        resolution_description: "No Resolution Description",
      };
      postArchitecturalPlans({ hoaId: hoaId, data: temp_data })
        .then((res) => {
          console.log(res);
          set_loading(false);
          closePage();
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
        });
    }
  };
  return (
    <div
      ref={parentRef}
      className="bg-white md:py-8 md:pl-12 md:pr-20 rounded-md flex flex-row items-start"
    >
      <div className="w-8 flex justify-center pt-1">
        <button
          className="flex items-center relative pt-px top-px"
          onClick={closePage}
        >
          <LeftArrowIcon className="h-5 w-5 text-gray-700 hover:text-gray-500 cursor-pointer" />
        </button>
      </div>
      <div className="flex flex-grow flex-col">
        <span className="text-xl text-blue-600 font-bold">
          New Architectural Review
        </span>

        <span className="mt-1 text-xs text-gray-500">
          Create a new Architectural plan to submit to the ARC committee for
          approval
        </span>

        <div className="mt-10 flex flex-col gap-1 min-w-full">
          <div className="flex flex-row justify-between min-w-full">
            <span className="font-semibold text-sm text-gray-700">Title</span>
            <span className="text-xs text-red-600">{warning.title}</span>
          </div>
          <input
            value={data.title ?? ""}
            onChange={(e) => {
              let val = e.target.value;
              remove_warning("title");
              set_data((data) => {
                return { ...data, title: val };
              });
            }}
            className="min-w-full text-gray-800 rounded bg-gray-200 px-3 py-2 focus:outline-none focus:bg-gray-300"
          />
        </div>

        {selectedProfile?.unit_object && (
          <div className="mt-4 flex flex-col gap-1 min-w-full">
            <div className="flex flex-row justify-between min-w-full">
              <span className="font-semibold text-sm text-gray-700">Unit</span>
              <span className="text-xs text-red-600">
                {warning.unit_object}
              </span>
            </div>
            <input
              value={
                selectedProfile?.unit_object
                  ? (selectedProfile.unit_object.unit_number ?? "") +
                    (selectedProfile.unit_object.address_object
                      ?.street_address_1
                      ? ", "
                      : "") +
                    (selectedProfile.unit_object.address_object
                      ?.street_address_1 ?? "")
                  : "No Unit found"
              }
              className="min-w-full text-gray-800 rounded bg-gray-200 px-3 py-2 focus:outline-none focus:bg-gray-300"
            />
          </div>
        )}

        <div className="mt-4 flex flex-col gap-1 min-w-full">
          <div className="flex flex-row justify-between min-w-full">
            <span className="font-semibold text-sm text-gray-700">Type</span>
            <span className="text-xs text-red-600">{warning.plan_type}</span>
          </div>
          <select
            value={data.plan_type ?? ""}
            onChange={(e) => {
              let val = e.target.value;
              remove_warning("plan_type");
              set_data((data) => {
                return { ...data, plan_type: val };
              });
            }}
            className="min-w-full text-gray-800 rounded bg-gray-200 px-3 py-2 border-none focus:outline-none focus:bg-gray-300"
          >
            <option selected hidden disabled value="">
              Select Type
            </option>
            {arch_review_plan_type
              .filter((val) => val.value !== "")
              .map((type, type_index) => (
                <option
                  className="bg-white"
                  key={type_index}
                  value={type.value}
                >
                  {type.label}
                </option>
              ))}
          </select>
        </div>

        <div className="mt-4 flex flex-col gap-1 min-w-full">
          <div className="flex flex-row justify-between min-w-full">
            <span className="font-semibold text-sm text-gray-700">
              Description
            </span>
            <span className="text-xs text-red-600">{warning.description}</span>
          </div>
          <Editor
            editorState={editor_state.description}
            onFocus={() => {
              set_editor_show("DESCRIPTION");
            }}
            onBlur={() => {
              set_editor_show();
            }}
            toolbar={toolbar_options}
            wrapperClassName=""
            toolbarClassName={
              "shadow-md absolute transform -translate-y-12 " +
              (editor_show === "DESCRIPTION" ? "opacity-100" : "opacity-0")
            }
            editorClassName={
              "appearance-none rounded-lg w-full px-3 py-2 text-gray-700 leading-tight " +
              (editor_show === "DESCRIPTION" ? "bg-gray-300" : "bg-gray-200")
            }
            onEditorStateChange={(editorState) => {
              remove_warning("description");
              set_editor_state({
                ...editor_state,
                description: editorState,
              });
              const newValue = draftToMarkdown(
                convertToRaw(editorState.getCurrentContent())
              );

              set_data((data) => {
                return { ...data, description: newValue };
              });
            }}
          />
        </div>

        <div className="flex flex-col md:flex-row gap-2 md:gap-6 items-start">
          {[
            {
              label: "Contractor Name",
              value: "contractor_name",
            },
            {
              label: "Contractor Phone",
              value: "contractor_phone",
            },
          ].map((ele, ele_index) => (
            <div key={ele_index} className="mt-4 flex flex-col gap-1 w-full">
              <div className="flex flex-row justify-between min-w-full">
                <span className="font-semibold text-sm text-gray-700">
                  {ele.label}
                </span>
                <span className="text-xs text-red-600">
                  {warning[ele.value]}
                </span>
              </div>
              <input
                value={data[ele.value] ?? ""}
                onChange={(e) => {
                  let val = e.target.value;
                  remove_warning([ele.value]);
                  set_data((data) => {
                    return { ...data, [ele.value]: val };
                  });
                }}
                className="min-w-full text-gray-800 rounded bg-gray-200 px-3 py-2 focus:outline-none focus:bg-gray-300"
              />
            </div>
          ))}
        </div>

        <div className="flex flex-col md:flex-row gap-2 md:gap-6 items-start">
          {[
            {
              label: "Planned Start Date",
              value: "planned_start_date",
            },
            {
              label: "Planned End Date",
              value: "planned_end_date",
            },
          ].map((ele, ele_index) => (
            <div key={ele_index} className="mt-4 flex flex-col gap-1 w-full">
              <div className="flex flex-row justify-between min-w-full">
                <span className="font-semibold text-sm text-gray-700">
                  {ele.label}
                </span>
                <span className="text-xs text-red-600">
                  {warning[ele.value]}
                </span>
              </div>
              <input
                type="date"
                value={data[ele.value] ?? ""}
                onChange={(e) => {
                  let val = e.target.value;
                  remove_warning([ele.value]);
                  set_data((data) => {
                    return { ...data, [ele.value]: val };
                  });
                  if (ele.value === "planned_start_date") {
                    let temp_date = new Date(val);
                    temp_date.setMonth(temp_date.getMonth() + 1);
                    set_data((data) => {
                      return {
                        ...data,
                        planned_end_date: temp_date
                          .toISOString()
                          .substring(0, 10),
                      };
                    });
                  }
                }}
                className="min-w-full text-gray-800 rounded bg-gray-200 px-3 py-2 border-none outline-none focus:outline-none focus:bg-gray-300"
              />
            </div>
          ))}
        </div>

        <div className="mt-8 text-right flex flex-row justify-end">
          <button
            type="button"
            className="bg-white hover:bg-gray-200 font-semibold py-1 px-6 border-2 border-gray-500 text-gray-600 rounded-lg shadow-sm mr-2"
            onClick={closePage}
          >
            Cancel
          </button>
          <button
            onClick={submit_AR}
            style={{ backgroundColor: "#2A32FD" }}
            className={
              "text-white font-semibold h-10 w-40 flex items-center justify-center rounded-lg shadow-sm " +
              (Object.keys(warning)?.length === 0
                ? "hover:opacity-80"
                : "opacity-30")
            }
          >
            {loading ? <LoadingAnimated className="h-5" /> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}
