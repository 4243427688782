import React from "react";

import { dateTimeFormat } from "../utility/dateTimeFormat";
import { ProfilePic } from "../../boardMeeting/constants";

export default function MessageCard({ message, set_notice_popup }) {
  const name = () => {
    let name =
      (message?.created_by?.first_name
        ? message?.created_by?.first_name + " "
        : "") + (message?.created_by?.last_name ?? "");

    return name;
  };
  return (
    <div
      className={
        "w-full mt-2 py-2 px-3 cursor-pointer max-h-screen overflow-auto rounded-md bg-white flex flex-row items-start gap-2"
      }
      onClick={() => {
        set_notice_popup(message);
      }}
    >
      <div className="shrink-0">
        <div
          style={{ backgroundColor: "#2A32FD" }}
          className="h-1.5 w-1.5 mt-2 rounded-full"
        />
      </div>
      <div className="w-full max-h-full flex flex-col justify-between gap-1">
        <div className="flex flex-col gap-1">
          <div className="w-full flex flex-row items-start justify-between gap-1">
            <span className="text-sm text-gray-800 font-semibold break-all">
              {message.title ?? ""}
            </span>
            {message?.created_date &&
              new Date().toDateString() ===
                new Date(message.created_date).toDateString() && (
                <div
                  style={{ fontSize: "10px" }}
                  className="px-2 py-.5 bg-green-200 text-green-600 rounded-full"
                >
                  New
                </div>
              )}
          </div>

          {message.image && (
            <img src={message.image} className="object-fit w-full" />
          )}
          <span className="text-xs text-gray-600">
            {(message.description ?? "").length > 80
              ? message.description.split(0, 80) + "..."
              : message.description ?? ""}
          </span>
        </div>

        <div className="mt-1 w-full flex flex-col items-end justify-end font-light">
          <div className="flex flex-row items-center gap-1 text-xs text-gray-400">
            <ProfilePic className="h-6 w-6" member={message.created_by} />
            {name()}
          </div>
          <span style={{ fontSize: "10px" }} className="text-gray-400">
            {dateTimeFormat(message.created_date)}
          </span>
        </div>
      </div>
    </div>
  );
}
