import { navigate } from "raviger";
import { useEffect, useState } from "react";
import { getHoa, getOrg } from "../../../api/ApiV2";
import { useRecoilState } from "recoil";

import { LeftArrowIcon, LoadingCircleIcon } from "../../common/AppIcons";
import SelectHeadings from "../../common/Form/SelectHeadings";
import Settings from "./components/settings";
import UpdateHoaForm from "./components/updateHoaForm";
import { userAtom } from "../../../store/UserAtom";
import SquadhubIntegration from "./components/SquadhubIntegration";
import AdminProfiles from "./components/admins/AdminProfiles";
import { adminTypes } from "../../../common/data/adminTypes";

const profilePageSelecter = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  return params.get("profile");
};

export default function UpdateHoa({ hoaId }) {
  const [data, set_data] = useState({});
  const [selectedTab, setSelectedTab] = useState(
    profilePageSelecter() ? "board_member_and_super_admin" : "hoa"
  );
  // hoa, board_member_and_super_admin, squadhub_integration, settings
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState();

  // settings page values
  const [baseData, setBaseData] = useState();
  const [popup_status, set_popup_status] = useState();
  // "GOTO_OPENING_BALANCE", "OPENING_BALANCE","EXPENSES_PAGE","INVOICE_PAGE","GOTO_COMPLIANCE"

  const [user] = useRecoilState(userAtom);

  const hideAdmin =
    (user.hoaType === "SINGLE_FAMILY" || user.hoaType === "MULTI_FAMILY") &&
    user.selectedUserType === "BOARD_MEMBER";

  const edit_hoa_previlege = adminTypes.includes(user.selectedUserType);

  const headings = [
    {
      label: edit_hoa_previlege ? "Update Property" : "Property Details",
      value: "hoa",
    },
    hideAdmin
      ? undefined
      : {
          label: "Board Member & Super Admin",
          value: "board_member_and_super_admin",
        },
    edit_hoa_previlege
      ? {
          label: "Squadhub Integration",
          value: "squadhub_integration",
        }
      : undefined,
    edit_hoa_previlege
      ? {
          label: "Settings",
          value: "settings",
        }
      : undefined,
  ].filter((val) => val);

  useEffect(() => {
    getOrg({ all: "True" })
      .then((res) => {
        setOrgs(res.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    get_hoa();
  }, []);

  const get_hoa = () => {
    setLoading(true);
    getHoa({ external_id: hoaId })
      .then((res) => {
        setLoading(false);
        console.log(res);
        set_data({
          ...(res ?? {}),
          streetAddress1: res.street_address_1,
          zipCode: res.zipcode,
        });
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
      });
  };

  const return_to_previous = () => {
    navigate("/hoa/" + hoaId + "/");
  };

  return (
    <div className="w-full p-8 rounded-md shadow-lg bg-white">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 flex flex-row gap-3 min-w-0">
          <div
            onClick={return_to_previous}
            className="border border-white hover:border-gray-400 flex items-center justify-center rounded-sm cursor-pointer"
          >
            <LeftArrowIcon className="h-6 relative top-px" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:truncate">
            Association
          </h2>
        </div>
      </div>
      <div className="mt-8 border-b w-full">
        <SelectHeadings
          gap={20}
          headings={headings}
          selected={selectedTab}
          width_array={[140, 250, 180, 100]}
          onChange={(val) => {
            setSelectedTab(val);
          }}
          className="relative top-1"
        />
      </div>
      {loading ? (
        <LoadingCircleIcon className="mx-auto my-20 h-8" />
      ) : selectedTab === "hoa" ? (
        <UpdateHoaForm
          className="mt-8"
          {...{ hoaId, orgs, data, set_data, get_hoa, edit_hoa_previlege }}
        />
      ) : selectedTab === "board_member_and_super_admin" ? (
        <AdminProfiles hoaId={hoaId} />
      ) : selectedTab === "squadhub_integration" ? (
        <SquadhubIntegration {...{ hoaId, data, get_hoa }} />
      ) : selectedTab === "settings" ? (
        <Settings
          className="mt-8"
          {...{
            hoaId,
            data,
            baseData,
            setBaseData,
            popup_status,
            set_popup_status,
            get_hoa,
          }}
        />
      ) : null}
    </div>
  );
}
