import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { dateString } from "../../../../utils/DateUtils";
import {
  getAccounts,
  postBill,
  getVendors,
  postInitialBill,
} from "../../../../api/ApiV2";
import { IInCircleIcon } from "../../../common/AppIcons";
import LoadingScreen from "../../../common/LoadingScreen";
import PopupHeading from "../../../common/PopupHeading";
import BillDataForm from "./components/billDataForm";
import CreateVendorPopup from "../createVendor/CreateVendorPopup";
import BottomButtons from "./components/bottomButtons";
import Summary from "../../common/components/summary";
import BillTable from "./components/billTable";
import PaymentTable from "./components/paymentTable";
import { terms } from "../../../common/Data";

export default function Bill({
  hoaId,
  onSuccess,
  source,
  readOnly = false,
  bill,
}) {
  const [data, setData] = useState(bill);
  const [expenseAccounts, setExpenseAccounts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendorPopup, setVendorPopup] = useState(false);
  const [vendor_default_account, set_vendor_default_account] = useState();
  const [only_data_total_amount, set_only_data_total_amount] = useState("");
  const [loading, setLoading] = useState(false);
  const [warning, set_warning] = useState({});

  const delete_warning = (item) => {
    let temp_warning = warning;

    delete temp_warning[item];

    set_warning(temp_warning);
  };

  const newLeg = {
    description: "",
    expense_account: vendor_default_account,
    amount: "",
  };

  const fetchVendors = () => {
    getVendors({ hoaId })
      .then((res) => {
        // setVendorsPage({ ...vendorsPage, total: res.count });
        setVendors(res.results);
      })
      .catch((_) => {});
  };

  useEffect((_) => {
    console.log("props:", bill);
    if (!data.legs) set_only_data_total_amount(parseFloat(data.amount));
  }, []);

  useEffect(() => {
    get_accounts();
    setLoading(true);
  }, []);

  useEffect(
    (_) => {
      if (vendorPopup === false) fetchVendors();
    },
    [vendorPopup]
  );

  useEffect(() => {
    if (
      data?.terms !== "" &&
      data.terms !== undefined &&
      data.date !== undefined
    ) {
      let date1 = new Date(data.date);
      let newDate = new Date(
        date1.setDate(
          date1.getDate() +
            terms.find((val) => val.value === data.terms)?.value1
        )
      );
      setData({
        ...data,
        due_date: isNaN(
          new Date(
            date1.setDate(
              date1.getDate() +
                terms.find((val) => val.value === data.terms)?.value1
            )
          ).getTime()
        )
          ? null
          : newDate,
      });
    } else setData({ ...data, due_date: undefined });
  }, [data?.terms, data?.date]);

  const get_accounts = () =>
    getAccounts({ hoaId, type: "EXPENSE" })
      .then((res) => {
        console.log("EX accounts", res);
        setExpenseAccounts(res.results);
        setLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });

  const onSubmit = () => {
    if (readOnly) {
      onSuccess();
      return;
    }
    const billVendor = vendors.find(
      (vendor) => vendor.id === Number(data.vendor)
    );
    console.log(`Vendor ${data.vendor}`, billVendor);

    if (!loading) {
      setLoading(true);
      data.legs?.length > 0
        ? postBill({
            hoaId,
            data: {
              is_invoice: false,
              name: "Bill",
              vendor: data.vendor,
              terms: data.terms,
              date: dateString(data.date),
              items:
                data.legs.length > 0
                  ? data.legs.map((val) => {
                      return {
                        account: val?.expense_account,
                        quantity: 1,
                        unit_price: val?.amount,
                        description: val?.description,
                      };
                    })
                  : undefined,
              reference_number: data.reference_number,
              pending_amount: parseFloat(
                Number(
                  data.legs.reduce(
                    (total, leg) => total + Number(leg.amount ?? 0),
                    0
                  )
                ).toFixed(2)
              ),
              transaction: {
                date: dateString(data.date),
                description: data.reference_number,
                vendor: data.vendor,
                legs: data.legs?.map((leg) => {
                  return {
                    account: Number(leg.expense_account),
                    amount: parseFloat(Number(leg.amount).toFixed(2)),
                    description: leg.description,
                  };
                }),
              },
            },
          })
            .then((res) => {
              setLoading(false);
              onSuccess(res.results);
              set_warning({});
            })
            .catch((res) => {
              toast.error(res?.message ?? "Error posting bill");
              console.log(res);
              setLoading(false);
            })
        : postInitialBill({
            hoaId,
            data: {
              is_invoice: false,
              name: "Bill",
              vendor: data.vendor,
              terms: data.terms,
              date: dateString(data.date),
              items:
                data.legs?.length > 0
                  ? data.legs.map((val) => {
                      return {
                        account: val?.expense_account,
                        quantity: 1,
                        unit_price: val?.amount,
                        description: val?.description,
                      };
                    })
                  : undefined,
              reference_number: data.reference_number,
              amount: parseFloat(parseFloat(only_data_total_amount).toFixed(2)),
            },
          })
            .then((res) => {
              setLoading(false);
              onSuccess(res.results);
              set_warning({});
            })
            .catch((res) => {
              set_warning(res);
              toast.error(res?.message ?? "Error submitting data");
              console.log(res);
              setLoading(false);
            });
    }
  };

  const find_total =
    data.legs?.length > 0
      ? data.legs
          ?.reduce(
            (total, leg) =>
              total +
              Number(
                leg.amount && !(readOnly && leg.amount < 0) ? leg.amount : 0
              ),
            0
          )
          .toFixed(2)
      : isFinite(only_data_total_amount) && only_data_total_amount !== ""
      ? parseFloat(only_data_total_amount).toFixed(2)
      : "0.00";

  const add_bill_row = () => {
    if (!readOnly)
      setData((data) => ({ ...data, legs: [...(data.legs ?? []), newLeg] }));
  };

  const delete_bill_row = (index) => {
    if (source === "HOA_CREATION" || data.legs?.length > 1) {
      let arr = data.legs;
      arr.splice(index, 1);
      setData({ ...data, legs: arr });
    }
  };
  return (
    <div className="w-full antialiased sans-serif max-h-screen rounded overflow-auto">
      <LoadingScreen show={loading} />

      <div className="container mx-auto py-4 md:py-6 px-3 md:px-5 bg-white rounded">
        <PopupHeading closePopup={onSuccess} className="mb-5">
          {readOnly ? "Bill" : "Add Bill"}
        </PopupHeading>

        <div className="sm:mt-7 px-3 sm:px-5 w-full max-h-full">
          <BillDataForm
            className="my-8 pl-2 pr-8"
            data={data}
            setData={setData}
            vendors={vendors}
            delete_warning={delete_warning}
            setVendorPopup={setVendorPopup}
            warning={warning}
            set_vendor_default_account={set_vendor_default_account}
            readOnly={readOnly}
          />

          <BillTable
            className="mt-8"
            {...{
              source,
              only_data_total_amount,
              set_only_data_total_amount,
              delete_warning,
              data,
              setData,
              delete_bill_row,
              add_bill_row,
              warning,
              set_warning,
              expenseAccounts,
              readOnly,
            }}
          />

          <div className="mt-8 pr-8 w-full flex flex-col md:flex-row items-center md:items-end justify-end gap-x-6 gap-y-8">
            <Summary
              data={[
                {
                  label: "Sub Total",
                  value: data.amount ?? find_total,
                },
                {
                  label: "Pending amount",
                  value: data.pending_amount,
                  className:
                    Number(data.pending_amount) === 0
                      ? "text-green-600"
                      : "text-red-600",
                },
              ]}
              title="Bill Summary"
            />
          </div>
          {readOnly && (
            <div className="w-full my-12 sm:my-16">
              <h3 className="text-xl font-bold text-gray-800 mb-7">Payments</h3>
              {!data.payments || data.payments?.length === 0 ? (
                <div className="w-full flex flex-row items-center justify-center gap-2 font-semibold text-gray-500 my-5">
                  <IInCircleIcon className="h-6 text-red-500" />
                  No Payments Found
                </div>
              ) : (
                <PaymentTable className="w-full" transactions={data.payments} />
              )}
            </div>
          )}

          <div className="py-2 ml-auto mt-7 md:mt-12">
            <BottomButtons
              {...{
                setShow: () => onSuccess(),
                setLoading,
                onSubmit,
                readOnly,
              }}
            />
          </div>
        </div>
      </div>
      {vendorPopup && (
        <CreateVendorPopup
          hoaId={hoaId}
          show={vendorPopup}
          setShow={(_) => setVendorPopup(false)}
        />
      )}
    </div>
  );
}
