import React from "react";
import { isNumericBlank } from "../../../utils/StringUtils";
import CurrencyInput from "react-currency-input";

export default function CurrencyInputLocal({
  name,
  placeholder,
  value,
  onChange,
  readOnly,
  className,
  ...props
}) {
  const onChangeDispatch = (value) => {
    const updatedValue = value;
    if (isNumericBlank(value) && !readOnly) onChange(updatedValue);
  };
  const random = Math.random();

  return (
    <div>
      {/* <label
        htmlFor="price"
        className="block text-sm font-medium text-gray-700"
      >
        Price
      </label> */}
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <label
            htmlFor={"currency_input_" + random}
            className="text-gray-500 sm:text-sm"
          >
            $
          </label>
        </div>
        <CurrencyInput
          name={name}
          value={value}
          onChange={(event, maskedvalue, floatvalue) => {
            onChangeDispatch(maskedvalue);
          }}
          className={
            className
              ? "pl-7 pr-12 " + className
              : `focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md ${
                  readOnly && "pointer-events-none"
                }`
          }
          placeholder="0.00"
          {...props}
          id={"currency_input_" + random}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label className="sr-only">Currency</label>
          <label
            id="currency"
            name="currency"
            htmlFor={"currency_input_" + random}
            className="flex items-center focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
          >
            USD
          </label>
        </div>
      </div>
    </div>
  );
}
