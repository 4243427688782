import React, { useState, useEffect, useRef } from "react";
import { Link, navigate } from "raviger";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  ArrowTriangleTopIcon,
  CrossIcon,
  LeftArrowIcon,
  LoadingCircleIcon2,
  PlusIcon,
  PlusInCircleIcon,
} from "../../../common/AppIcons";
import { getHoaMembers, addBoardMeeting } from "../../../../api/ApiV2";
import { meeting_types, ProfilePic } from "./constants";
import DatePicker from "../../../common/Form/Datepicker";
import SelectAttendeesPopup from "./popups/SelectAttendeesPopup";
import CreatedBoardMeetingPopup from "./popups/CreatedBoardMeetingPopup";

const toolbar_options = {
  options: [
    "inline",
    "blockType",
    "list",
    "link",
    "emoji",
    "image",
    "remove",
    "history",
  ],
  inline: {
    options: ["bold", "italic", "strikethrough", "monospace"],
  },
};

const default_editor_state = () => {
  return EditorState.createEmpty();
};

const convert_to_editor_state = (value) =>
  EditorState.createWithContent(convertFromRaw(markdownToDraft(value)));

export default function CreateBoardMeeting({ hoaId }) {
  const [eventEditor, setEventEditor] = useState({
    include_all_optional: false,
    include_all_mandatory: false,
    title: "",
    description: "",
    type: "",
    start_time: undefined,
    end_time: undefined,
    secretary: "",
    chairman: "",
    address: "",
    mandatory_attendees: [],
    optional_attendees: [],
    agendas: [],
    agenda: [],
  });

  const [members, set_members] = useState([]);
  const [editor_state, set_editor_state] = useState({
    description: default_editor_state(),
    agendas: [],
  });
  const [editor_show, set_editor_show] = useState();
  const [warnings, set_warnings] = useState({});
  const [attendees_popup, set_attendees_popup] = useState(false);
  // MANDATORY,OPTIONAL
  const [loading, set_loading] = useState(false);
  const [board_meeting_created, set_board_meeting_created] = useState(false);

  useEffect(() => {
    set_loading(true);
    getHoaMembers(hoaId)
      .then((res) => {
        set_loading(false);
        console.log("members : ", res.results);
        let temp_members = [];
        res.results.map((member) => {
          if (!temp_members.find((val) => val.user.id === member.user.id))
            temp_members.push(member);
        });
        set_members(temp_members);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
      });
  }, []);

  const is_there_error = () => {
    let temp_errors = {};
    if (eventEditor.name === "")
      temp_errors = { ...temp_errors, name: "Please fill this field" };
    if (eventEditor.description === "")
      temp_errors = { ...temp_errors, description: "Please fill this field" };
    if (eventEditor.meeting_type === "")
      temp_errors = { ...temp_errors, meeting_type: "Please select a type" };
    if (eventEditor.start_time === "")
      temp_errors = { ...temp_errors, start_time: "Please select a date" };
    if (eventEditor.end_time === "")
      temp_errors = { ...temp_errors, end_time: "Please select a date" };
    if (eventEditor.secretary === "")
      temp_errors = { ...temp_errors, secretary: "Please select a secretary" };
    if (eventEditor.chairman === "")
      temp_errors = { ...temp_errors, chairman: "Please select a chairman" };
    if (eventEditor.location === "")
      temp_errors = { ...temp_errors, location: "Please fill this field" };

    eventEditor.agendas.map((agenda, agenda_index) => {
      if (!agenda.agenda?.title || agenda.agenda?.title === "")
        temp_errors = {
          ...temp_errors,
          ["agenda" + agenda_index + "title"]: "Please fill this field",
        };
      if (!agenda.agenda?.description || agenda.agenda?.description === "")
        temp_errors = {
          ...temp_errors,
          ["agenda" + agenda_index + "description"]: "Please fill this field",
        };

      agenda.motions.map((motion, motion_index) => {
        if (!motion.title || motion.title === "")
          temp_errors = {
            ...temp_errors,
            ["agenda" + agenda_index + "motion" + motion_index + "title"]:
              "Please fill this field",
          };

        if (!motion.proposed_by || motion.proposed_by === "")
          temp_errors = {
            ...temp_errors,
            ["agenda" + agenda_index + "motion" + motion_index + "proposed_by"]:
              "Please fill this field",
          };
        if (!motion.seconded_by || motion.seconded_by === "")
          temp_errors = {
            ...temp_errors,
            ["agenda" + agenda_index + "motion" + motion_index + "seconded_by"]:
              "Please fill this field",
          };
      });
    });

    set_warnings({ ...temp_errors });

    if (Object.keys(temp_errors).length === 0) return false;
    else return true;
  };
  const submit_meeting = () => {
    if (!is_there_error() && !loading) {
      set_loading(true);

      addBoardMeeting({
        id: eventEditor?.id,
        hoaId: hoaId,
        data: {
          ...eventEditor,
          agendas: eventEditor.agendas.map((val, index) => ({
            ...val,
            level: index + 1,
          })),
          mandatory_attendees: eventEditor.mandatory_attendees?.map((ele) => {
            return { user: ele.user.id };
          }),
          optional_attendees: eventEditor.optional_attendees?.map((ele) => {
            return { user: ele.user.id };
          }),
        },
      })
        .then((res) => {
          console.log(res);
          set_board_meeting_created(true);
          set_loading(false);
        })
        .catch((res) => {
          set_loading(false);
          console.log(res);
          set_warnings({ ...warnings, ...res });
        });
    }
  };

  const remove_warning = (name) => {
    let temp_warnings = warnings;
    delete temp_warnings[name];
    set_warnings(temp_warnings);
  };

  if (loading)
    return (
      <div
        className="py-20 bg-white rounded w-full"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <LoadingCircleIcon2 className="h-10 mx-auto" />
      </div>
    );
  else
    return (
      <div
        className="px-2 md:p-4 mx-auto mb-5"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="shadow rounded-lg bg-white w-full block p-4 md:p-8 md:px-10 ">
          <h2 className="font-bold text-2xl mb-6 text-blue-600 border-b pb-2 flex flex-row items-center gap-4">
            <Link
              href={`/hoa/${hoaId}/community/board_meetings/`}
              className="text-gray-800 hover:text-gray-500 cursor-pointer"
            >
              <LeftArrowIcon className="h-6" />
            </Link>
            Create New Meeting
          </h2>

          <div className="mb-5">
            <div className="w-full flex justify-between">
              <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                Meeting Title
              </label>
              <span className="text-xs text-red-400">{warnings.name}</span>
            </div>
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="text"
              name="name"
              value={eventEditor.name}
              onChange={(e) => {
                remove_warning("name");
                const newValue = e.target.value;

                if (!eventEditor.readOnly)
                  setEventEditor((current) => {
                    return { ...current, name: newValue };
                  });
              }}
            />
          </div>

          <div className="mb-5">
            <div className="w-full flex justify-between">
              <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                Description
              </label>
              <span className="text-xs text-red-400">
                {warnings.description}
              </span>
            </div>
            <Editor
              editorState={editor_state.description}
              onFocus={() => {
                set_editor_show("DESCRIPTION");
              }}
              onBlur={() => {
                set_editor_show();
              }}
              toolbar={toolbar_options}
              wrapperClassName=""
              toolbarClassName={
                "fixed top-2 z-30 shadow-md " +
                (editor_show === "DESCRIPTION"
                  ? "opacity-100"
                  : "opacity-0 h-0 overflow-hidden hidden")
              }
              editorClassName="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              onEditorStateChange={(editorState) => {
                remove_warning("description");
                set_editor_state({ ...editor_state, description: editorState });
                const newValue = draftToMarkdown(
                  convertToRaw(editorState.getCurrentContent())
                );

                if (!eventEditor.readOnly)
                  setEventEditor((current) => {
                    return { ...current, description: newValue };
                  });
              }}
            />
          </div>

          <div className="mb-5">
            <div className="w-full flex flex-row justify-between">
              <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                Meeting Date
              </label>
              <span className="text-xs text-red-400">
                {(warnings.start_time ?? "") + " " + (warnings.end_time ?? "")}
              </span>
            </div>
            <div className="flex flex-col md:flex-row gap-5 min-w-full">
              <DatePicker
                input_className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                value={eventEditor.start_time || eventEditor.end_time}
                onChange={(val) => {
                  remove_warning("start_time");
                  remove_warning("end_time");
                  let temp_start_time = new Date(val);
                  if (eventEditor.start_time && temp_start_time) {
                    temp_start_time.setHours(
                      new Date(eventEditor.start_time).getHours()
                    );
                    temp_start_time.setMinutes(
                      new Date(eventEditor.start_time).getMinutes()
                    );
                  }
                  let temp_end_time = new Date(val);
                  if (eventEditor.end_time && temp_end_time) {
                    temp_end_time.setHours(
                      new Date(eventEditor.end_time).getHours()
                    );
                    temp_end_time.setMinutes(
                      new Date(eventEditor.end_time).getMinutes()
                    );
                  }
                  setEventEditor({
                    ...eventEditor,
                    start_time: temp_start_time,
                    end_time: temp_end_time,
                  });
                }}
                showPrompt={false}
              />

              <div className="flex flex-row items-center gap-5">
                <input
                  value={
                    (("0" + new Date(eventEditor.start_time).getHours()).slice(
                      -2
                    ) ?? "00") +
                    ":" +
                    ((
                      "0" + new Date(eventEditor.start_time).getMinutes()
                    ).slice(-2) ?? "00")
                  }
                  onChange={(e) => {
                    if (!e.target.value || e.target.value === "") return;
                    remove_warning("start_time");

                    let temp_start_time = eventEditor.start_time
                      ? new Date(eventEditor.start_time)
                      : new Date();

                    temp_start_time.setHours(e.target.value.split(":")[0]);
                    temp_start_time.setMinutes(e.target.value.split(":")[1]);

                    setEventEditor({
                      ...eventEditor,
                      start_time: temp_start_time,
                    });
                  }}
                  type="time"
                  className="px-2.5 py-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                />
                <span className="text-gray-500 font-semibold text-sm">TO</span>
                <input
                  value={
                    (("0" + new Date(eventEditor.end_time).getHours()).slice(
                      -2
                    ) ?? "00") +
                    ":" +
                    (("0" + new Date(eventEditor.end_time).getMinutes()).slice(
                      -2
                    ) ?? "00")
                  }
                  onChange={(e) => {
                    if (!e.target.value || e.target.value === "") return;
                    remove_warning("end_time");

                    let temp_end_time = eventEditor.end_time
                      ? new Date(eventEditor.end_time)
                      : new Date();

                    temp_end_time.setHours(e.target.value.split(":")[0]);
                    temp_end_time.setMinutes(e.target.value.split(":")[1]);

                    setEventEditor({
                      ...eventEditor,
                      end_time: temp_end_time,
                    });
                  }}
                  type="time"
                  className="px-2.5 py-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          <div className="mb-5 flex flex-row gap-3 w-full">
            <div className="w-full">
              <div className="w-full flex justify-between">
                <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                  Meeting Type
                </label>
                <span className="text-xs text-red-400">
                  {warnings.meeting_type}
                </span>
              </div>
              <select
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                type="text"
                name="name"
                value={eventEditor.meeting_type}
                onChange={(e) => {
                  remove_warning("meeting_type");
                  const newValue = e.target.value;

                  if (!eventEditor.readOnly)
                    setEventEditor((current) => {
                      return { ...current, meeting_type: newValue };
                    });
                }}
              >
                <option
                  disabled
                  selected
                  hidden
                  value={""}
                  className="px-2 py-1"
                >
                  Select
                </option>
                {meeting_types.map((meeting, index) => (
                  <option
                    value={meeting.value}
                    key={index}
                    className="px-2 py-1"
                  >
                    {meeting.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full">
              <div className="w-full flex justify-between">
                <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                  Meeting Location
                </label>
                <span className="text-xs text-red-400">
                  {warnings.location}
                </span>
              </div>

              <input
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                type="text"
                name="name"
                value={eventEditor.location}
                onChange={(e) => {
                  remove_warning("location");

                  if (!eventEditor.readOnly)
                    setEventEditor((current) => {
                      return { ...current, location: e.target.value };
                    });
                }}
              />
            </div>
          </div>

          <div className="mb-5 flex flex-row gap-3 w-full">
            <div className="w-full">
              <div className="w-full flex justify-between">
                <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                  Secretary
                </label>
                <span className="text-xs text-red-400">
                  {warnings.secretary}
                </span>
              </div>
              <select
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                type="text"
                name="name"
                value={eventEditor.secretary}
                onChange={(e) => {
                  remove_warning("secretary");
                  const newValue = e.target.value;

                  if (!eventEditor.readOnly)
                    setEventEditor((current) => {
                      return {
                        ...current,
                        secretary: newValue,
                        mandatory_attendees: [
                          ...(eventEditor.mandatory_attendees?.find(
                            (ele) => ele.user?.id === newValue
                          )
                            ? []
                            : [
                                members?.find(
                                  (ele) => ele.user?.id === newValue
                                ),
                              ]),
                          ...(current?.mandatory_attendees ?? []),
                        ],
                        optional_attendees: current.optional_attendees?.filter(
                          (ele) => ele.user?.id !== newValue
                        ),
                      };
                    });
                }}
              >
                <option
                  disabled
                  selected
                  hidden
                  value={""}
                  className="px-2 py-1"
                >
                  Select Secretary
                </option>
                {members
                  .filter((val) => val.user?.id !== eventEditor.chairman)
                  .map((secretary, index) => (
                    <option
                      value={secretary.user?.id}
                      key={index}
                      className="px-2 py-1"
                    >
                      {secretary.user?.first_name +
                        " " +
                        secretary.user?.last_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-full">
              <div className="w-full flex justify-between">
                <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                  Chairman
                </label>
                <span className="text-xs text-red-400">
                  {warnings.chairman}
                </span>
              </div>
              <select
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                type="text"
                name="name"
                value={eventEditor.chairman}
                onChange={(e) => {
                  remove_warning("chairman");
                  const newValue = e.target.value;

                  if (!eventEditor.readOnly)
                    setEventEditor((current) => {
                      return {
                        ...current,
                        chairman: newValue,
                        mandatory_attendees: [
                          ...(eventEditor.mandatory_attendees?.find(
                            (ele) => ele.user?.id === newValue
                          )
                            ? []
                            : [
                                members?.find(
                                  (ele) => ele.user?.id === newValue
                                ),
                              ]),
                          ...(current?.mandatory_attendees ?? []),
                        ],
                        optional_attendees: current.optional_attendees?.filter(
                          (ele) => ele.user?.id !== newValue
                        ),
                      };
                    });
                }}
              >
                <option
                  disabled
                  selected
                  hidden
                  value={""}
                  className="px-2 py-1"
                >
                  Select Chairman
                </option>
                {members
                  .filter((val) => val.user?.id !== eventEditor.secretary)
                  .map((chairman, index) => (
                    <option
                      value={chairman.user?.id}
                      key={index}
                      className="px-2 py-1"
                    >
                      {chairman.user?.first_name +
                        " " +
                        chairman.user?.last_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="mb-5">
            <div className="w-full flex justify-between">
              <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                Mandatory Attendees
              </label>
              <span className="text-xs text-red-400">
                {typeof warnings.mandatory_attendees === "string"
                  ? warnings.mandatory_attendees
                  : ""}
              </span>
            </div>
            <div className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
              {!eventEditor.mandatory_attendees ||
              eventEditor.mandatory_attendees.length === 0 ? (
                <div
                  onClick={() => set_attendees_popup("MANDATORY")}
                  className="flex flex-row gap-2 hover:opacity-70 cursor-pointer"
                >
                  <PlusInCircleIcon className="text-green-500 h-4" />
                  <span className="text-gray-600">Add Mandatory Attendees</span>
                </div>
              ) : (
                <div className="flex flex-row items-center gap-4 w-full">
                  <div className="flex flex-grow flex-row flex-wrap gap-2">
                    {eventEditor.mandatory_attendees?.map(
                      (attendee, attendee_index) => (
                        <div
                          key={attendee_index}
                          className="flex flex-row gap-2 items-center bg-white px-2 py-1 rounded"
                        >
                          <ProfilePic
                            className="shadow-none border h-7 w-7"
                            member={
                              members?.find((value) => {
                                return value.user?.id === attendee?.user?.id;
                              })?.user
                            }
                          />
                          <h1 className="font-normal text-base relative bottom-px text-gray-600">
                            {members?.find((value) => {
                              return value.user?.id === attendee.user?.id;
                            })?.user?.first_name +
                              " " +
                              members?.find((value) => {
                                return value.user?.id === attendee?.user?.id;
                              })?.user?.last_name}
                          </h1>
                          {!attendee.id && (
                            <button
                              className={
                                "hover:opacity-60 " +
                                (attendee?.user?.id ===
                                  eventEditor?.secretary ||
                                attendee?.user?.id === eventEditor?.chairman
                                  ? "hidden"
                                  : "")
                              }
                              onClick={() => {
                                let temp_arr = eventEditor.mandatory_attendees;
                                let index = null;
                                temp_arr.map((val, index_local) => {
                                  if (val.id === attendee?.id)
                                    index = index_local;
                                });
                                temp_arr.splice(index, 1);
                                setEventEditor((current) => {
                                  return {
                                    ...current,
                                    mandatory_attendees: temp_arr,
                                  };
                                });
                              }}
                            >
                              <CrossIcon className="h-5 text-red-500" />
                            </button>
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <div>
                    <div
                      className="hover:opacity-70 cursor-pointer"
                      onClick={() => set_attendees_popup("MANDATORY")}
                    >
                      <PlusInCircleIcon className="text-green-500 h-5" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <span className="flex flex-row gap-2 mt-2 items-center whitespace-pre text-sm text-gray-700">
              <input
                type="checkbox"
                className="rounded ml-1"
                onChange={(e) => {
                  e.target.checked
                    ? setEventEditor((current) => {
                        return {
                          ...current,
                          mandatory_attendees: [
                            ...members.filter(
                              (member) =>
                                !eventEditor.optional_attendees.includes(member)
                            ),
                          ],
                        };
                      })
                    : setEventEditor((current) => {
                        return {
                          ...current,
                          mandatory_attendees:
                            current.mandatory_attendees?.filter(
                              (ele) =>
                                ele.user?.id === eventEditor.chairman ||
                                ele.user?.id === eventEditor.secretary ||
                                ele.id
                            ),
                        };
                      });
                }}
              />
              Select Everyone on association
            </span>
          </div>

          <div className="mb-5">
            <div className="w-full flex justify-between">
              <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                Optional Attendees
              </label>
              <span className="text-xs text-red-400">
                {typeof warnings.optional_attendees === "string"
                  ? warnings.optional_attendees
                  : ""}
              </span>
            </div>
            <div className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
              {!eventEditor.optional_attendees ||
              eventEditor.optional_attendees?.length === 0 ? (
                <div
                  onClick={() => set_attendees_popup("OPTIONAL")}
                  className="flex flex-row gap-2 hover:opacity-70 cursor-pointer"
                >
                  <PlusInCircleIcon className="text-green-500 h-4" />
                  <span className="text-gray-600">Add Optional Attendees</span>
                </div>
              ) : (
                <div className="flex flex-row items-center gap-4 w-full">
                  <div className="flex flex-grow flex-row flex-wrap gap-2">
                    {eventEditor.optional_attendees?.map(
                      (attendee, attendee_index) => (
                        <div
                          key={attendee_index}
                          className="flex flex-row gap-2 items-center bg-white px-2 py-1 rounded"
                        >
                          <ProfilePic
                            className="shadow-none border h-7 w-7"
                            member={
                              members?.find((value) => {
                                return value.user?.id === attendee?.user?.id;
                              })?.user
                            }
                          />
                          <h1 className="font-normal text-base relative bottom-px text-gray-600">
                            {members?.find((value) => {
                              return value.user?.id === attendee?.user?.id;
                            })?.user?.first_name +
                              " " +
                              members?.find((value) => {
                                return value.user?.id === attendee?.user?.id;
                              })?.user?.last_name}
                          </h1>
                          {!attendee.id && (
                            <button
                              className="hover:opacity-70 p-1 rounded-full"
                              onClick={() => {
                                let temp_arr = eventEditor.optional_attendees;
                                let index = null;
                                temp_arr?.map((val, index_local) => {
                                  if (val.id === attendee?.id)
                                    index = index_local;
                                });
                                temp_arr?.splice(index, 1);
                                setEventEditor((current) => {
                                  return {
                                    ...current,
                                    optional_attendees: temp_arr,
                                  };
                                });
                              }}
                            >
                              <CrossIcon className="h-5 text-red-500" />
                            </button>
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <div>
                    <div
                      className="hover:opacity-70 cursor-pointer"
                      onClick={() => set_attendees_popup("OPTIONAL")}
                    >
                      <PlusInCircleIcon className="text-green-500 h-5" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <span className="flex flex-row gap-2 mt-2 items-center whitespace-pre text-sm text-gray-700">
              <input
                type="checkbox"
                className="rounded ml-1"
                onChange={(e) => {
                  e.target.checked
                    ? setEventEditor((current) => {
                        return {
                          ...current,
                          optional_attendees: [
                            ...members.filter(
                              (member) =>
                                !eventEditor.mandatory_attendees.includes(
                                  member
                                )
                            ),
                          ],
                        };
                      })
                    : setEventEditor((current) => {
                        return {
                          ...current,
                          optional_attendees: [],
                        };
                      });
                }}
              />
              Select Everyone on association
            </span>
          </div>

          <div className="mb-5">
            <div className="flex flex-col gap-2">
              {eventEditor.agendas.map((agenda, index) => (
                <div key={index} className="flex flex-row gap-1 items-baseline">
                  <div className="px-12 py-6 flex flex-col flex-grow bg-gray-100">
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row w-full justify-between">
                        <div className="flex flex-row items-center gap-2">
                          <div className="relative top-px flex flex-col gap-1 text-gray-800">
                            <button
                              onClick={() => {
                                if (index === 0) return;
                                let arr = eventEditor.agendas;

                                arr[index] = arr[index - 1];
                                arr[index - 1] = agenda;
                                setEventEditor((current) => {
                                  return { ...current, agendas: arr };
                                });

                                arr = editor_state;
                                let value = editor_state.agendas[index];
                                arr.agendas[index] = arr.agendas[index - 1];
                                arr.agendas[index - 1] = value;
                                set_editor_state(arr);
                              }}
                              className={
                                "hover:opacity-50 focus:border-none focus:outline-none " +
                                (index === 0 ? "opacity-50" : "")
                              }
                            >
                              <ArrowTriangleTopIcon className="h-2" />
                            </button>

                            <button
                              onClick={() => {
                                if (eventEditor.agendas.length - 1 === index)
                                  return;
                                let arr = eventEditor.agendas;
                                arr[index] = arr[index + 1];
                                arr[index + 1] = agenda;
                                setEventEditor((current) => {
                                  return { ...current, agenda: arr };
                                });

                                arr = editor_state;
                                let value = editor_state.agendas[index];
                                arr.agendas[index] = arr.agendas[index + 1];
                                arr.agendas[index + 1] = value;
                                set_editor_state(arr);
                              }}
                              className={
                                "hover:opacity-50 focus:border-none focus:outline-none " +
                                (eventEditor.agendas.length - 1 === index
                                  ? "opacity-50"
                                  : "")
                              }
                            >
                              <ArrowTriangleTopIcon className="h-2 transform rotate-180" />
                            </button>
                          </div>
                          <span className="font-bold tracking-wide text-lg text-indigo-600">
                            Agenda {index + 1}
                          </span>
                        </div>
                        <button
                          onClick={() => {
                            let arr = eventEditor.agendas;
                            arr.splice(index, 1);
                            setEventEditor((current) => {
                              return { ...current, agendas: arr };
                            });

                            arr = editor_state.agendas;
                            delete arr[index];
                            let temp_arr = {};
                            Object.keys(arr).map((val) => {
                              if (val > index)
                                temp_arr = {
                                  ...temp_arr,
                                  [Number(val) - 1]: arr[val],
                                };
                              else temp_arr = { ...temp_arr, [val]: arr[val] };
                            });
                            set_editor_state({
                              ...editor_state,
                              agendas: temp_arr,
                            });
                          }}
                          className="text-red-500 hover:text-red-400 hover:underline text-sm"
                        >
                          Delete Agenda
                        </button>
                      </div>
                      <div className="w-full mt-4 flex flex-row justify-between">
                        <label className="text-gray-800 block font-semibold text-sm tracking-wide">
                          Title of Agenda
                        </label>
                        <span className="text-xs text-red-400">
                          {warnings["agenda" + index + "title"]}
                        </span>
                      </div>
                      <input
                        className="rounded w-full py-2 px-4 text-gray-700 focus:outline-none bg-white"
                        value={eventEditor.agendas[index].agenda?.title ?? ""}
                        onChange={(e) => {
                          remove_warning("agenda" + index + "title");

                          let array = eventEditor.agendas;
                          array[index].agenda = {
                            ...(array[index].agenda ?? {}),
                            title: e.target.value,
                          };

                          if (!eventEditor.readOnly)
                            setEventEditor((current) => {
                              return { ...current, agendas: array };
                            });
                        }}
                      ></input>
                      <div className="w-full flex flex-row justify-between">
                        <label className="text-gray-800 block font-semibold text-sm tracking-wide">
                          Description
                        </label>
                        <span className="text-xs text-red-400">
                          {warnings["agenda" + index + "description"]}
                        </span>
                      </div>
                      <Editor
                        editorState={editor_state.agendas[index]?.description}
                        onFocus={() => {
                          set_editor_show("AGENDAS_" + index + "_DESCRIPTION");
                        }}
                        onBlur={() => {
                          set_editor_show();
                        }}
                        toolbar={toolbar_options}
                        wrapperClassName=""
                        toolbarClassName={
                          "fixed top-2 z-30 shadow-md " +
                          (editor_show === "AGENDAS_" + index + "_DESCRIPTION"
                            ? "opacity-100"
                            : "opacity-0 h-0 overflow-hidden hidden")
                        }
                        editorClassName="rounded w-full px-2 text-gray-700 focus:outline-none bg-white"
                        onEditorStateChange={(editorState) => {
                          remove_warning("agenda" + index + "description");
                          set_editor_state({
                            ...editor_state,
                            agendas: {
                              ...editor_state.agendas,
                              [index]: {
                                ...editor_state.agendas[index],
                                description: editorState,
                              },
                            },
                          });
                          const newValue = draftToMarkdown(
                            convertToRaw(editorState.getCurrentContent())
                          );
                          let array = eventEditor.agendas;
                          array[index] = {
                            ...array[index],
                            agenda: {
                              ...(array[index]?.agenda ?? {}),
                              description: newValue,
                            },
                          };

                          if (!eventEditor.readOnly)
                            setEventEditor((current) => {
                              return { ...current, agendas: array };
                            });
                        }}
                      />

                      {eventEditor.agendas[index]?.motions.map(
                        (motion, motion_index) => (
                          <div
                            key={motion_index}
                            className="p-2 flex flex-col flex-grow ml-4 gap-1"
                          >
                            <div className="flex flex-row w-full justify-between">
                              <div className="flex flex-row items-center gap-2">
                                <div className="relative top-px flex flex-col gap-1 text-gray-800">
                                  <button
                                    onClick={() => {
                                      if (motion_index === 0) return;

                                      let arr = eventEditor.agendas;
                                      arr[index].motions[motion_index] =
                                        arr[index].motions[motion_index - 1];
                                      arr[index].motions[motion_index - 1] =
                                        motion;
                                      setEventEditor((current) => {
                                        return {
                                          ...current,
                                          agendas: arr,
                                        };
                                      });
                                    }}
                                    className={
                                      "hover:opacity-50 focus:border-none focus:outline-none " +
                                      (motion_index === 0 ? "opacity-50" : "")
                                    }
                                  >
                                    <ArrowTriangleTopIcon className="h-2" />
                                  </button>

                                  <button
                                    onClick={() => {
                                      if (
                                        eventEditor.agendas[index]?.motions
                                          .length -
                                          1 ===
                                        motion_index
                                      )
                                        return;
                                      let arr = eventEditor.agendas;
                                      arr[index].motions[motion_index] =
                                        arr[index].motions[motion_index + 1];
                                      arr[index].motions[motion_index + 1] =
                                        motion;
                                      setEventEditor((current) => {
                                        return {
                                          ...current,
                                          agendas: arr,
                                        };
                                      });
                                    }}
                                    className={
                                      "hover:opacity-50 focus:border-none focus:outline-none " +
                                      (eventEditor.agendas[index]?.motions
                                        .length -
                                        1 ===
                                      motion_index
                                        ? "opacity-50"
                                        : "")
                                    }
                                  >
                                    <ArrowTriangleTopIcon className="h-2 transform rotate-180" />
                                  </button>
                                </div>
                                <span className="font-bold tracking-wide text-lg text-indigo-600">
                                  Motion {motion_index + 1}
                                </span>
                              </div>
                              <button
                                onClick={() => {
                                  let arr = eventEditor.agendas;
                                  arr[index].motions.splice(motion_index, 1);
                                  setEventEditor((current) => {
                                    return { ...current, agendas: arr };
                                  });
                                }}
                                className="text-red-500 hover:text-red-400 hover:underline text-sm"
                              >
                                Delete Motion
                              </button>
                            </div>

                            <div className="w-full mt-5 flex flex-row justify-between">
                              <label className="text-gray-800 block font-semibold text-sm tracking-wide">
                                Title
                              </label>
                              <span className="text-xs text-red-400">
                                {
                                  warnings[
                                    "agenda" +
                                      index +
                                      "motion" +
                                      motion_index +
                                      "title"
                                  ]
                                }
                              </span>
                            </div>
                            <input
                              className="bg-white rounded w-full py-2 px-4 text-gray-700 focus:outline-none"
                              value={motion.title ?? ""}
                              onChange={(e) => {
                                remove_warning(
                                  "agenda" +
                                    index +
                                    "motion" +
                                    motion_index +
                                    "title"
                                );
                                let array = eventEditor.agendas;
                                array[index].motions[motion_index] = {
                                  ...motion,
                                  title: e.target.value,
                                };

                                if (!eventEditor.readOnly)
                                  setEventEditor((current) => {
                                    return {
                                      ...current,
                                      agendas: array,
                                    };
                                  });
                              }}
                            ></input>
                            <div className="flex flex-row w-full gap-1 mt-3">
                              <div className="w-full">
                                <div className="w-full flex justify-between">
                                  <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                                    Proposed by
                                  </label>
                                  <span className="text-xs text-red-400">
                                    {
                                      warnings[
                                        "agenda" +
                                          index +
                                          "motion" +
                                          motion_index +
                                          "proposed_by"
                                      ]
                                    }
                                  </span>
                                </div>
                                <select
                                  className="bg-white rounded w-full py-2 px-4 text-gray-700 focus:outline-none outline-none border-0"
                                  value={motion.proposed_by ?? false}
                                  onChange={(e) => {
                                    remove_warning(
                                      "agenda" +
                                        index +
                                        "motion" +
                                        motion_index +
                                        "proposed_by"
                                    );
                                    let array = eventEditor.agendas;
                                    array[index].motions[motion_index] = {
                                      ...motion,
                                      proposed_by: e.target.value,
                                    };

                                    if (!eventEditor.readOnly)
                                      setEventEditor((current) => {
                                        return {
                                          ...current,
                                          agendas: array,
                                        };
                                      });
                                  }}
                                >
                                  <option
                                    selected
                                    disabled
                                    hidden
                                    value={false}
                                    className="px-2 py-1"
                                  >
                                    Select a member
                                  </option>
                                  {[
                                    ...(eventEditor?.mandatory_attendees ?? []),
                                    ...(eventEditor?.optional_attendees ?? []),
                                  ]
                                    .filter(
                                      (ele) =>
                                        ele.user?.id !==
                                        eventEditor.agendas[index]?.motions[
                                          motion_index
                                        ]?.seconded_by
                                    )
                                    .map((secretary, index) => (
                                      <option
                                        value={secretary.user.id}
                                        key={index}
                                        className="px-2 py-1"
                                      >
                                        {secretary.user.first_name +
                                          " " +
                                          secretary.user.last_name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="w-full">
                                <div className="w-full flex justify-between">
                                  <label className="text-gray-800 block mb-1 font-semibold text-sm tracking-wide">
                                    Seconded by
                                  </label>
                                  <span className="text-xs text-red-400">
                                    {
                                      warnings[
                                        "agenda" +
                                          index +
                                          "motion" +
                                          motion_index +
                                          "seconded_by"
                                      ]
                                    }
                                  </span>
                                </div>
                                <select
                                  className="bg-white rounded w-full py-2 px-4 text-gray-700 focus:outline-none border-0"
                                  type="text"
                                  name="name"
                                  value={motion.seconded_by ?? false}
                                  onChange={(e) => {
                                    remove_warning(
                                      "agenda" +
                                        index +
                                        "motion" +
                                        motion_index +
                                        "seconded_by"
                                    );
                                    let array = eventEditor.agendas;
                                    array[index].motions[motion_index] = {
                                      ...motion,
                                      seconded_by: e.target.value,
                                    };

                                    if (!eventEditor.readOnly)
                                      setEventEditor((current) => {
                                        return {
                                          ...current,
                                          agendas: array,
                                        };
                                      });
                                  }}
                                >
                                  <option
                                    selected
                                    disabled
                                    hidden
                                    value={false}
                                    className="px-2 py-1"
                                  >
                                    Select a member
                                  </option>
                                  {[
                                    ...(eventEditor?.mandatory_attendees ?? []),
                                    ...(eventEditor?.optional_attendees ?? []),
                                  ]
                                    .filter(
                                      (ele) =>
                                        ele.user?.id !==
                                        eventEditor.agendas[index]?.motions[
                                          motion_index
                                        ]?.proposed_by
                                    )
                                    .map((secretary, index) => (
                                      <option
                                        value={secretary.user?.id}
                                        key={index}
                                        className="px-2 py-1"
                                      >
                                        {secretary.user?.first_name +
                                          " " +
                                          secretary.user?.last_name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        )
                      )}

                      <div>
                        <button
                          onClick={() => {
                            setEventEditor((eventEditor) => {
                              let arr = eventEditor.agendas;
                              arr[index] = {
                                ...eventEditor.agendas[index],
                                motions: [
                                  ...eventEditor.agendas[index].motions,
                                  {},
                                ],
                              };
                              return {
                                ...eventEditor,
                                agendas: arr,
                              };
                            });
                          }}
                          className="mt-3 px-2 py-1 focus:outline-none border-2 border-gray-500 text-gray-700 text-sm rounded hover:opacity-70 flex flex-row gap-2 items-center"
                        >
                          <PlusIcon className="h-1" />
                          Add Motion
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div>
                <button
                  onClick={() => {
                    set_editor_state({
                      ...editor_state,
                      agendas: {
                        ...editor_state.agendas,
                        [eventEditor.agendas.length]: {
                          description: default_editor_state(),
                        },
                      },
                    });
                    setEventEditor((eventEditor) => {
                      return {
                        ...eventEditor,
                        agendas: [
                          ...eventEditor.agendas,
                          {
                            agenda: {
                              title: "",
                              description: "",
                            },

                            motions: [],
                          },
                        ],
                      };
                    });
                  }}
                  className="mt-3 px-2 py-1 focus:outline-none border-2 border-blue-500 text-blue-700 text-sm rounded hover:opacity-70 flex flex-row gap-2 items-center"
                >
                  <PlusIcon className="h-3.5" />
                  Add Agenda
                </button>
              </div>
            </div>
          </div>

          <div className="mt-8 text-right flex flex-row justify-end">
            <button
              type="button"
              className="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded-lg shadow-sm mr-2"
              onClick={() => {
                navigate(`/hoa/${hoaId}/community/board_meetings/`);
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                submit_meeting();
              }}
              className={
                "text-white font-semibold h-10 w-52 flex items-center justify-center rounded-lg shadow-sm " +
                (Object.keys(warnings)?.length === 0
                  ? "bg-gray-800 hover:bg-gray-700 border border-gray-700"
                  : "bg-gray-400")
              }
            >
              Schedule Board meeting
            </button>
          </div>
        </div>
        {attendees_popup &&
          (attendees_popup === "MANDATORY" ? (
            <SelectAttendeesPopup
              view={attendees_popup}
              set_view={(val = false) => {
                set_attendees_popup(val);
              }}
              eventEditor={eventEditor}
              value={eventEditor.mandatory_attendees ?? []}
              onChange={(arr) => {
                setEventEditor((current) => {
                  return {
                    ...current,
                    mandatory_attendees: arr,
                  };
                });
              }}
              members={members.filter(
                (member) =>
                  !eventEditor.optional_attendees?.find(
                    (ele) => ele.id === member.id
                  )
              )}
            />
          ) : (
            <SelectAttendeesPopup
              view={attendees_popup}
              set_view={(val = false) => {
                set_attendees_popup(val);
              }}
              value={eventEditor.optional_attendees ?? []}
              onChange={(arr) => {
                setEventEditor((current) => {
                  return {
                    ...current,
                    optional_attendees: arr,
                  };
                });
              }}
              members={members.filter(
                (member) =>
                  !eventEditor.mandatory_attendees?.find(
                    (ele) => ele.id === member.id
                  )
              )}
            />
          ))}
        {board_meeting_created && (
          <CreatedBoardMeetingPopup
            hoaId={hoaId}
            view={board_meeting_created}
            set_view={() => {
              setEventEditor({
                title: "",
                description: "",
                type: "",
                start_time: undefined,
                end_time: undefined,
                secretary: "",
                chairman: "",
                address: "",
                mandatory_attendees: [],
                optional_attendees: [],
                agendas: [],
                agenda: [],
              });
              set_editor_state({
                description: default_editor_state(),
                agendas: [],
              });
              set_board_meeting_created(false);
            }}
          />
        )}
      </div>
    );
}
