import { Transition } from "@tailwindui/react";
import { useEffect, useRef } from "react";

export default function CopyUnitDataDropdown({
  hoaData,
  setHoaData,
  unit,
  copyDropdown,
  setCopyDropdown,
}) {
  const ref = useRef(null);

  useEffect(() => {
    function handleMouseDown(event) {
      if (copyDropdown !== unit) return;
      if (ref.current && !ref.current.contains(event.target))
        setCopyDropdown(null);
    }
    document.addEventListener("mousedown", handleMouseDown);
    return () => document.removeEventListener("mousedown", handleMouseDown);
  }, [ref, copyDropdown, unit]);

  return (
    <Transition
      show={copyDropdown === unit}
      className="absolute z-10 right-5 top-5"
      enter="transform duration-300 overflow-hidden"
      enterFrom="max-h-0"
      enterTo="max-h-96"
      leave="transform duration-300 overflow-hidden"
      leaveFrom="max-h-96"
      leaveTo="max-h-0"
    >
      <div
        ref={ref}
        className="shadow overflow-auto pt-3 w-52 rounded bg-white flex flex-col gap-2"
      >
        <span className="text-sm px-4 text-gray-400">
          Copy value to other units
        </span>
        <div className="w-full px-4 flex flex-row items-center justify-center gap-2">
          <button
            onClick={() => {
              setHoaData((hoaData) => {
                return {
                  ...hoaData,
                  units: hoaData.units.map((unit2) => {
                    return {
                      ...unit,
                      id: unit2.id,
                      unit_number: unit2.unit_number,
                    };
                  }),
                };
              });
            }}
            className="bg-indigo-500 hover:bg-indigo-600 text-white rounded px-4 py-2 text-sm font-semibold"
          >
            Copy to all
          </button>
        </div>
        {hoaData.units
          ?.filter((val) => val.address === unit.address && val.id !== unit.id)
          .map((unit1, unit1Index) => (
            <div
              key={unit1Index}
              className="w-full py-2 px-4 flex flex-row items-center gap-2"
            >
              <input
                type="checkbox"
                onClick={() => {
                  setHoaData((hoaData) => {
                    return {
                      ...hoaData,
                      units: hoaData.units.map((unit2) => {
                        return unit2.id === unit1.id
                          ? {
                              ...unit,
                              id: unit2.id,
                              unit_number: unit2.unit_number,
                            }
                          : unit2;
                      }),
                    };
                  });
                }}
              />
              <span className="text-sm">{unit1.unit_number}</span>
            </div>
          ))}
      </div>
    </Transition>
  );
}
