import { useState, useRef, useEffect } from "react";

import { LoadingCircleIcon } from "../../../../common/AppIcons";
import CompanyForm from "./components/CompanyForm";
import { getOrg, patchOrg, postOrg } from "../../../../../api/ApiV2";
import { toast } from "react-toastify";

export default function CompanyDetails({
  clientData,
  setClientData,
  previousPage,
  nextPage,
}) {
  const [warnings, setWarnings] = useState({
    name: "",
    streetAddress1: "",
    city: "",
    zipCode: "",
  });
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const [orgs, setOrgs] = useState();

  const delete_warning = (value) => {
    let temp_warning = warnings;
    delete temp_warning[value];
    setWarnings(temp_warning);
  };

  useEffect(() => {
    getOrg({ type: "PMC" })
      .then((res) => {
        setOrgs(res.results);
      })
      .catch((res) => {
        toast.error(res.message ?? "Error fetching organisations details");
      });
  }, []);

  const submitHoa = () => {
    setLoading(true);
    const apiCall = clientData.companyDetails?.id ? patchOrg : postOrg;

    apiCall({
      id: clientData.companyDetails?.id,
      data: {
        ...clientData.companyDetails,
        type: clientData.userRole,
      },
    })
      .then((res) => {
        setLoading(false);
        toast.success("Saved Company Details");
        setClientData((clientData) => ({
          ...clientData,
          id: res.id,
        }));
        nextPage();
      })
      .catch((res) => {
        setLoading(false);
        toast.error(res.message ?? "Error saving company details");
        setWarnings(res);
      });
  };

  if (loading)
    return (
      <div className="max-w-4xl w-full flex-grow sm:mx-16 bg-white rounded-md shadow-md mt-12 py-24">
        <LoadingCircleIcon className="h-7 mx-auto" />
      </div>
    );
  return (
    <div
      ref={componentRef}
      className="max-w-4xl w-full flex-grow sm:mx-16 bg-white rounded-md shadow-md mt-12 pt-10 pb-24"
    >
      <div className="h-full w-full m-auto px-4 flex flex-col items-center text-gray-800 max-w-2xl">
        <div className="w-full flex flex-col">
          <span
            className="text-lg text-center w-full font-semibold"
            style={{ color: "#2A32FD" }}
          >
            Fill Company Details
          </span>
          <CompanyForm
            {...{
              orgs,
              clientData,
              form: clientData.companyDetails,
              setClientData: (value) =>
                setClientData((clientData) => {
                  return {
                    ...clientData,
                    companyDetails: value,
                  };
                }),
              warnings,
              setWarnings,
              delete_warning,
            }}
          />
        </div>

        <div className="w-full mt-10 flex flex-row justify-end items-center gap-3">
          <button
            onClick={previousPage}
            className="text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 h-10 w-28 rounded"
          >
            Go Back
          </button>

          <button
            style={{
              backgroundColor: "#2A32FD",
              border: "2px solid #2A32FD",
            }}
            className="text-sm text-white hover:opacity-80 h-10 w-28 sm:w-40 flex items-center justify-center rounded"
            onClick={() => {
              submitHoa();
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
