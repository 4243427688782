import React, { useState, useReducer } from "react";
import { Transition } from "@tailwindui/react";

const removeTime = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDay());

const initialState = removeTime(new Date()); // Today without date

function reducer(state, action) {
  let mutateable = new Date(state);
  switch (action) {
    case "NEXT_MONTH":
      mutateable.setMonth(mutateable.getMonth() + 1);
      return mutateable;
    case "PREVIOUS_MONTH":
      mutateable.setMonth(mutateable.getMonth() - 1);
      return mutateable;
    default:
      throw new Error();
  }
}

const getMonthString = (date) =>
  date.toLocaleString("default", {
    month: "long",
  });

const getBlankDays = (calendarState) => {
  const date = new Date(calendarState.getFullYear(), calendarState.getMonth());
  return [...Array(date.getDay())];
};
const getDays = (calendarState) => {
  let arr = [];
  for (
    let date = new Date(
      calendarState.getFullYear(),
      calendarState.getMonth(),
      1
    );
    date.getMonth() === calendarState.getMonth();
    date.setDate(date.getDate() + 1)
  ) {
    // console.log("Date", date.getDate());
    arr.push(new Date(date));
  }
  //   console.log("Month Days", arr);
  return arr;
};

const isToday = (date) => new Date().toDateString() === date.toDateString();

export default function DatePicker({
  input_className,
  className,
  placeholder,
  value,
  readonly,
  onChange,
  showPrompt = true,
  short = false,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showDatepicker, setShowDatepicker] = useState(false);

  const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const keyHandler = (e) => {
    if (e.keyCode === 27) setShowDatepicker(false);
  };

  // const buttonStyler1 = () => {
  //     if (month === 0) return ' cursor-not-allowed opacity-25 ';
  //     else return '';
  // }

  // const buttonStyler2 = () => {
  //     let a = '';
  //     if (month === 11) return ' cursor-not-allowed opacity-25 ';
  //     else return '';
  // }

  const divStyler = (date, value) => {
    const compare = new Date(value);
    // console.log(compare.toDateString() === date.toDateString());
    if (isToday(date)) return "border-blue-500 border-2";
    else if (date.toDateString() === compare.toDateString())
      return "bg-green-500";
    else return "text-gray-700 hover:bg-blue-200";
  };

  return (
    <div className={short ? "w-40" : "w-64"}>
      {showPrompt ? (
        <label for="datepicker" className="font-bold mb-1 text-gray-700 block">
          Select Date
        </label>
      ) : null}

      <div className={"relative w-48 " + className}>
        <input type="hidden" name="date" value="date"></input>
        <input
          type="text"
          readOnly
          value={(value && new Date(value)?.toDateString()) || ""}
          onClick={() => {
            if (!readonly) setShowDatepicker(!showDatepicker);
          }}
          onKeyDown={(e) => {
            keyHandler(e);
          }}
          className={
            "w-full pl-4 pr-10 py-2 leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium " +
            input_className
          }
          placeholder={placeholder ?? (readonly ? "" : "Select date")}
        ></input>

        <div className="absolute top-0 right-0 px-3 py-2">
          <svg
            className="h-6 w-6 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <Transition
          show={showDatepicker}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="bg-white rounded-lg shadow p-4 absolute z-50"
          style={{ width: "17rem" }}
        >
          <div className="flex justify-between items-center mb-2">
            <div>
              <span className="text-lg font-bold text-gray-800">
                {getMonthString(state)}
              </span>
              <span className="ml-1 text-lg text-gray-600 font-normal">
                {state.getFullYear()}
              </span>
            </div>
            <div>
              <button
                type="button"
                className={
                  "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                }
                onClick={(e) => {
                  dispatch("PREVIOUS_MONTH");
                  e.stopPropagation();
                }}
              >
                <svg
                  className="h-6 w-6 text-gray-500 inline-flex"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <button
                type="button"
                className={
                  "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                }
                onClick={(e) => {
                  dispatch("NEXT_MONTH");
                  e.stopPropagation();
                }}
              >
                <svg
                  className="h-6 w-6 text-gray-500 inline-flex"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="flex flex-wrap mb-3 -mx-1">
            {DAYS.map((value1, index) => {
              return (
                <div key={value1} style={{ width: "14.26%" }} className="px-1">
                  <div className="text-gray-800 font-medium text-center text-xs">
                    {value1}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex flex-wrap -mx-1">
            {getBlankDays(state).map((value1, index) => {
              return (
                <div
                  key={index}
                  style={{ width: "14.28%" }}
                  className="text-center border p-1 border-transparent text-sm"
                ></div>
              );
            })}

            {getDays(state).map((date, index) => {
              //   console.log("Rendering Date", date);
              return (
                <div
                  key={index}
                  style={{ width: "14.28%" }}
                  className="px-1 mb-1"
                >
                  <div
                    onClick={() => {
                      onChange(date);
                      setShowDatepicker(false);
                    }}
                    className={
                      "cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100 " +
                      divStyler(date, value)
                    }
                  >
                    {date.getDate()}
                  </div>
                </div>
              );
            })}
          </div>
        </Transition>
      </div>
    </div>
  );
}
