export const downloadTxtFileUnits = (units) => {
  if (!units?.length > 0) return;

  var CSV = [
    '"Unit","Unit Size","Beds","Baths","Dues",',
    ...(units ?? []).map(
      (unit) =>
        `"${unit.unit_number ?? ""}","${unit.unit_size ?? 0}","${
          unit.beds ?? 0
        }","${unit.baths ?? 0}","${unit.monthly_dues ?? 0}",`
    ),
  ].join("\n");

  var contentType = "text/csv";

  var csvFile = new Blob([CSV], { type: contentType });

  var a = document.createElement("a");
  a.download = "Units data.csv";
  a.href = URL.createObjectURL(csvFile);

  a.dataset.downloadurl = [contentType, a.download, a.href].join(":");

  document.body.appendChild(a);
  a.click();
};

export const downloadTxtFileMembers = (units) => {
  if (!units?.length > 0) return;

  const sampleData = [
    {
      first_name: "John",
      last_name: "Doe",
      phone: "12345678903",
      email: "jhondoe@gmail.com",
      user_type: "SUPER_ADMIN",
    },
    {
      first_name: "Daniel",
      last_name: "Vincent",
      phone: "987654321044",
      email: "danielvincent@gmail.com",
      user_type: "BOARD_MEMBER",
    },
    {
      first_name: "Ben",
      last_name: "Stokes",
      phone: "12345678905",
      email: "benstokes@gmail.com",
      user_type: "OWNER",
    },
    {
      first_name: "Joe",
      last_name: "Root",
      phone: "12345678906",
      email: "joeroot@gmail.com",
      user_type: "TENANT",
    },
  ];

  let units1 = units;
  if (units.length < 4)
    for (let i = 0; i < 4 - units.length; i++) {
      units1 = [...units1, units[0]];
    }
  var CSV = [
    '"First Name","Last Name","Phone","Email","Unit","User Type",',

    ...units1.map(
      (unit, index) =>
        `"${sampleData[index]?.first_name ?? ""}","${
          sampleData[index]?.last_name ?? ""
        }","${sampleData[index]?.phone ?? ""}","${
          sampleData[index]?.email ?? 0
        }","${unit.unit_number ?? 0}","${sampleData[index]?.user_type ?? 0}",`
    ),
  ].join("\n");

  var contentType = "text/csv";

  var csvFile = new Blob([CSV], { type: contentType });

  var a = document.createElement("a");
  a.download = "Members data.csv";
  a.href = URL.createObjectURL(csvFile);

  a.dataset.downloadurl = [contentType, a.download, a.href].join(":");

  document.body.appendChild(a);
  a.click();
};
