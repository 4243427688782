import { dateStringFormat } from "../../../../../../utils/DateUtils";

export const check_transactions_page_data = (value) => {
  return {
    heading: "Check Transactions",
    table: {
      className: "grid-cols-5",
      headings: [
        { label: "Payee Name", className: "" },
        { label: "Due Date", className: "" },
        { label: "Description", className: "col-span-2" },
        { label: "Amount", className: "justify-end" },
      ],
      rows: [
        {
          label: value?.vendor ?? value?.merchant_name ?? "Untracked Vendor",
        },
        {
          label: dateStringFormat(value?.created_date),
        },
        {
          label: value?.description,
          className: "col-span-2",
        },
        {
          label: "$" + value?.computed_transaction_amount,
          className: "justify-end",
        },
      ],
    },
  };
};
