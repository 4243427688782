import React from "react";

export default function FormElement({ label, children, warning }) {
  return (
    <div className="mt-5 flex flex-col gap-px w-full">
      <div className="px-1 flex flex-row flex-wrap gap-4 items-start justify-between">
        <span className="text-sm pl-1 text-gray-700">{label ?? ""}</span>
        {warning && <span className="text-xs text-red-600">{warning}</span>}
      </div>
      {children}
    </div>
  );
}
