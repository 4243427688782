
export const hours_array = [...Array(148)].map((ele, index) => {
    return {
        label:
            ("0" + Math.floor(index / 2)).slice(-2) +
            ":" +
            (index % 2 === 0 ? "00" : "30") +
            " hrs",
        value: (index) * 30,
    };
});

export const minutes_to_string = (minutes) => {
    return hours_array.find(val => val.value === minutes)?.label ?? ''
}
