import {
  ClockIcon,
  DocumentIcon,
  EnvalopIcon,
  FlagIcon,
  FourBoxes,
  GraphIcon,
  PeopleIcon,
  ProfileIcon,
  ThreeDotsInCircleIcon,
  RoutineMaintanenceIcon2,
  MaintanenceIcon2,
  InspectionIcon2,
  GeneralDocumentsIcon,
  PlusInSquareIcon,
} from "../../../../common/AppIcons";

const listType = sessionStorage.getItem("srListType");

export const sidebar_buttons = [
  {
    label: "Order History",
    icon: ClockIcon,
    link: "/hoa/:hoaId/sr/ORDER_HISTORY",
    pages:
      listType === "ORDER_HISTORY"
        ? ["/hoa/:hoaId/sr/:srId", "/hoa/:hoaId/ticket/ticketId"]
        : [],
  },
  {
    label: "Routine Maintenance",
    icon: RoutineMaintanenceIcon2,
    link: "/hoa/:hoaId/sr/ROUTINE_MAINTENANCE",
    style: { height: "1.5rem", marginRight: "-0.2rem" },
    pages: listType === "ROUTINE_MAINTENANCE" ? ["/hoa/:hoaId/sr/:srId"] : [],
  },
  {
    label: "Unscheduled Maintenance",
    icon: MaintanenceIcon2,
    link: "/hoa/:hoaId/sr/UNSCHEDULED_MAINTENANCE",
    style: { height: "1.5rem" },
    pages:
      listType === "UNSCHEDULED_MAINTENANCE" ? ["/hoa/:hoaId/sr/:srId"] : [],
  },
  {
    label: "Inspections",
    icon: InspectionIcon2,
    link: "/hoa/:hoaId/sr/INSPECTIONS",
    pages: listType === "INSPECTIONS" ? ["/hoa/:hoaId/sr/:srId"] : [],
  },
  {
    label: "Estimates",
    icon: DocumentIcon,
    link: "/hoa/:hoaId/sr/estimates",
    pages: ["/hoa/:hoaId/sr/estimates/:estimateId"],
  },
  {
    label: "Messages",
    icon: EnvalopIcon,
    link: "/hoa/:hoaId/sr/messages",
  },
  {
    label: "New Service Request",
    icon: PlusInSquareIcon,
    link: "/hoa/:hoaId/sr/create",
  },
];
