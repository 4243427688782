import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

export default function Paginator({
  totalPage = 1,
  currentPage = 1,
  entryPerPage = 50,
  onChange,
  className,
}) {
  return (
    <div
      className={
        "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 overflow-auto " +
        (Math.ceil(totalPage / entryPerPage) > 1 ? "" : "hidden") +
        " " +
        className
      }
    >
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          onClick={() => {
            if (currentPage !== 1) onChange(currentPage - 1);
          }}
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          onClick={() => {
            if (currentPage !== Math.ceil(totalPage / entryPerPage))
              onChange(currentPage + 1);
          }}
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {(currentPage - 1) * entryPerPage + 1}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {currentPage * entryPerPage > totalPage
                ? totalPage
                : currentPage * entryPerPage}
            </span>{" "}
            of <span className="font-medium">{totalPage}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              onClick={() => {
                if (currentPage !== 1) onChange(currentPage - 1);
              }}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {[...Array(Math.ceil(totalPage / entryPerPage))].map(
              (value, index) => (
                <span
                  key={index}
                  onClick={() => {
                    onChange(index + 1);
                  }}
                  className={
                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-default " +
                    (currentPage === index + 1
                      ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                      : "bg-white border-gray-300 text-gray-500 hover:bg-gray-200")
                  }
                >
                  {index + 1}
                </span>
              )
            )}
            <button
              onClick={() => {
                if (currentPage !== Math.ceil(totalPage / entryPerPage))
                  onChange(currentPage + 1);
              }}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
