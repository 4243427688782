import React from "react";
import {
  CrossInCircleIcon,
  LoadingAnimated,
} from "../../../../common/AppIcons";
import Modal from "../../../../common/Modal";

export default function EndMeeting({
  view,
  set_view,
  loading,
  end_board_meeting,
}) {
  return (
    <Modal show={view} setShow={set_view}>
      <div className="px-8 md:px-16 py-10 md:py-20 flex flex-col items-center gap-4">
        <div className="p-12 rounded-full bg-red-100">
          <CrossInCircleIcon className="text-red-600 h-16 w-16" />
        </div>
        <span className="mt-3 text-gray-800 font-semibold text-xl">
          Are you sure you want to end this meeting?
        </span>
        <button
          onClick={end_board_meeting}
          className="mt-5 text-white bg-red-600 rounded-md hover:opacity-80 h-12 w-48 flex items-center justify-center"
        >
          {loading ? <LoadingAnimated className="h-7" /> : "Yes, End meeting"}
        </button>
        <button
          onClick={set_view}
          className="px-10 py-2 text-gray-700 hover:opacity-80"
        >
          No, Cancel
        </button>
      </div>
    </Modal>
  );
}
