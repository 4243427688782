import React, { useRef, useState } from "react";
import CurrencyInput from "react-currency-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CalenderIcon2, LoadingCircleIcon } from "../AppIcons";
import CurrencyInputLocal from "./CurrencyInput";
import Dropdown from "./Dropdown";
import "./Input.css";

const random = Math.random();

export default function Input({
  type,
  onChange,
  className,
  //   CurrencyInput props
  prefix = "$",
  //   Dropdown props
  subHeadings,
  options,
  hint,
  wrapperClassName,
  ...props
}) {
  const ref = useRef(null);
  const [focused, set_focused] = useState(false);

  if (type === "currency")
    return (
      <CurrencyInput
        prefix={prefix}
        onChange={(event, maskedvalue, floatvalue) => onChange(maskedvalue)}
        className={className}
        {...props}
      />
    );
  else if (type === "dropdown") {
    return (
      <Dropdown
        selectClassName={className}
        onChange={(value) => onChange(value)}
        subHeadings={subHeadings}
        options={options}
        readOnly={props.readonly}
        {...props}
      />
    );
  } else if (type === "textarea")
    return (
      <textarea
        className={className}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      />
    );
  else if (type === "date")
    return (
      <form className="flex flex-row items-center w-min sd-container">
        <input
          id={"calender_input" + random}
          type="date"
          className={"sd " + className}
          onChange={(e) => onChange(e.target.value)}
          {...props}
          value={
            props.value && new Date(props.value).toISOString().slice(0, 10)
          }
        />
        <span className="open-button">
          <CalenderIcon2 className="h-5" />
        </span>
      </form>
    );
  else if (type === "currencyLocal")
    return (
      <CurrencyInputLocal
        className={className}
        onChange={(value) => {
          onChange(value);
        }}
        disabled={props.readOnly}
        {...props}
      />
    );
  else if (type === "phone")
    return (
      <PhoneInput
        country={"us"}
        disabled={props.readOnly}
        onChange={(value) => {
          onChange(value);
        }}
        inputProps={{
          className: className,
        }}
        {...props}
        className="text-xs font-semibold"
      />
    );
  else if (type === "radio")
    return (
      <div className="flex flex-row gap-7">
        {options?.map((ele, ele_index) => (
          <div key={ele_index} className={"flex flex-row gap-1 items-center"}>
            <input
              type={type}
              className={className}
              onChange={(e) => onChange(e.target.value)}
              checked={ele.value === props.value}
              {...props}
              {...ele}
            />
            <span className="ml-1">{ele.label}</span>
          </div>
        ))}
      </div>
    );
  else if (hint)
    return (
      <div className={"relative flex flex-col " + wrapperClassName}>
        <input
          ref={ref}
          autoComplete="off"
          onFocus={(event) => {
            set_focused(true);
            if (event.target.autocomplete) {
              event.target.autocomplete = "whatever";
            }
          }}
          onBlur={() => {
            setTimeout(() => {
              set_focused(false);
            }, 200);
          }}
          type={type}
          className={className}
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
        {focused && (hint.loading || hint.hints?.length > 0) && (
          <div
            className="absolute z-10 bg-white border shadow w-full max-h-60 overflow-auto"
            style={{
              marginTop: ref?.current?.offsetHeight,
            }}
          >
            <div className="flex flex-col w-full">
              {hint.loading && (
                <div className={"flex items-center justify-center w-full py-2"}>
                  <LoadingCircleIcon
                    className="text-blue-400"
                    style={{ height: "17px" }}
                  />
                </div>
              )}
              {hint.hints?.map((hint_ele, hint_index) => (
                <span
                  className="w-full cursor-pointer word-break text-gray-700 px-4 py-2 hover:bg-gray-100"
                  key={hint_index}
                  onClick={() => {
                    hint.onClick(hint_ele.value);
                  }}
                >
                  {hint_ele.label}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  else
    return (
      <input
        type={type}
        className={className}
        onChange={(e) => {
          console.log(props);
          onChange(e.target.value);
        }}
        {...props}
      />
    );
}
