import React from "react";

import { tableHeadings } from "./data/tableHeading";
import FormInput from "../FormInput";
import { PlusInCircleIcon2 } from "../../../../../common/AppIcons";
import { tableRow } from "./data/tableRow";
import Heading from "../../../../../common/Form/table/Heading";
import Row from "../../../../../common/Form/table/Row";
import { tableFooter } from "./data/tableFooter";

export default function FormTable({
  remove_description = false,
  journalEntry,
  setJournalEntry,
  initialEntry,
  setNewAccountPopup,
  baseData,
  className,
  readOnly,
}) {
  const onChangeLeg = (value, name, index) => {
    setJournalEntry((journalEntry) => {
      return {
        ...journalEntry,
        items: journalEntry.items?.map((leg, dataIndex) =>
          dataIndex === index
            ? {
                ...leg,
                [name]: value,
              }
            : leg
        ),
      };
    });
  };

  return (
    <div style={{ minWidth: "500px" }} className={"min-w-full " + className}>
      {!remove_description && (
        <div className="py-3 text-left text-sm font-medium text-gray-600 traacking-wider">
          <span className="text-gray-800 font-semibold text-sm px-1">
            Journal Entry Notes
          </span>
          {console.log("journal entry", journalEntry)}
          <FormInput
            placeholder="Write your notes here...."
            className={
              "mt-2 w-full px-3 py-2 focus:outline-none border-b-2 border-white focus:border-gray-300"
            }
            value={journalEntry.description}
            onChange={(value) => {
              if (!journalEntry.just_display)
                setJournalEntry({
                  ...journalEntry,
                  description: value === "" ? undefined : value,
                });
            }}
            readOnly={readOnly}
          />
        </div>
      )}
      <Heading
        headings={tableHeadings(remove_description)}
        className={
          "mt-6 w-full grid gap-x-5 pl-4 pr-16 rounded text-gray-600 " +
          (remove_description ? "grid-cols-3" : "grid-cols-4")
        }
      />
      <div className="w-full pl-2 pr-10 divide-y bg-white shadow rounded-md">
        {journalEntry.items?.map((leg, renderIndex) => (
          <Row
            key={renderIndex}
            readOnly={readOnly}
            row={tableRow({
              leg,
              renderIndex,
              onChangeLeg,
              baseData,
              setNewAccountPopup,
              remove_description,
              readOnly,
              items: journalEntry.items,
            })}
            deleteItem={() => {
              setJournalEntry({
                ...journalEntry,
                items: journalEntry.items?.filter(
                  (leg, dataIndex) => dataIndex !== renderIndex
                ),
              });
            }}
            className={
              "w-full grid gap-5 py-2 text-gray-800 " +
              (remove_description ? "grid-cols-3" : "grid-cols-4")
            }
            wrapperClassName="px-4"
            label_className="font-semibold px-2 h-full flex items-center"
          />
        ))}

        <Row
          row={tableFooter({
            journalEntry,
          })}
          readOnly={false}
          className={
            "w-full grid gap-5 py-2 text-gray-800 " +
            (remove_description ? "grid-cols-3" : "grid-cols-4")
          }
          wrapperClassName="px-4"
        />
      </div>
      {!readOnly && (
        <div>
          <button
            className="mt-4 text-gray-700 font-semibold py-2 px-4 text-sm hover:opacity-70 focus:outline-none flex flex-row items-center gap-2"
            onClick={(_) =>
              setJournalEntry({
                ...journalEntry,
                items: [
                  ...journalEntry.items,
                  {
                    ...initialEntry,
                    description:
                      journalEntry.items?.[0]?.description ?? undefined,
                  },
                ],
              })
            }
          >
            <PlusInCircleIcon2 className="text-green-500 h-4" />{" "}
            <span style={{ color: "#2A32FD" }}>Add New Item</span>
          </button>
        </div>
      )}
    </div>
  );
}
