import React from 'react'

const default_props = {
  className:
    "px-2 text-sm text-left font-semibold tracking-wider",
};

export const tableHeadings = (remove_description) => [
  {
    label: "Account",
    ...default_props,
  },
  {
    label: "Debit",
    ...default_props,
  },
  {
    label: "Credit",
    ...default_props,
  },
  !remove_description && {
    label: "Description",
    ...default_props,
  },

];
