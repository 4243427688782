import React from "react";

import Modal from "../../../../common/Modal";
import Check from "../../../components/check";
import AddRevenue from "../../../components/addRevenue";
import CreateVendorPopup from "../../../components/createVendor/CreateVendorPopup";
import NewAccountPopup from "../../../components/NewAccountPopup";
import PayBillPopup from "../../../components/payBill/PayBillPopup";
import BillDetailPopup from "../../../components/bill/BillDetailPopup";

export function ShortcutToPopup({ hoaId, shortcut, setShortcut }) {
  return (
    <div>
      {shortcut === "ADD_VENDOR" ? (
        <CreateVendorPopup
          hoaId={hoaId}
          show={shortcut === "ADD_VENDOR"}
          setShow={(_) => setShortcut("")}
        />
      ) : shortcut === "BANK" ? (
        <PayBillPopup
          hoaId={hoaId}
          show={shortcut === "BANK" ? true : false}
          setShow={(_) => {
            setShortcut("");
          }}
        />
      ) : null}

      <Modal
        show={
          shortcut && shortcut !== "ADD_VENDOR" && shortcut !== "BANK"
            ? true
            : false
        }
        setShow={(_) => {
          if (shortcut === "BILL") return;
          else if (shortcut === "CREATE_ACCOUNT_FROM_PAYBILL")
            setShortcut("BANK");
          else setShortcut("");
        }}
        wide={
          shortcut === "BILL" ||
          shortcut === "CREATE_CHECK" ||
          shortcut === "ADD_REVENUE"
        }
      >
        {shortcut ? (
          shortcut === "BILL" ? (
            <BillDetailPopup
              hoaId={hoaId}
              onSuccess={(_) => {
                setShortcut("");
              }}
            />
          ) : shortcut === "CREATE_CHECK" ? (
            <Check
              hoaId={hoaId}
              onSuccess={(_) => {
                setShortcut("");
              }}
            />
          ) : shortcut === "ADD_REVENUE" ? (
            <AddRevenue
              hoaId={hoaId}
              setShow={(_) => {
                setShortcut("");
              }}
            />
          ) : shortcut === "CREATE_ACCOUNT" ? (
            <NewAccountPopup
              hoaId={hoaId}
              closePopup={(_) => {
                setShortcut("");
              }}
            />
          ) : (
            React.null
          )
        ) : (
          React.null
        )}
      </Modal>
    </div>
  );
}
