import { dateStringFormat } from "../../../../../../utils/DateUtils";

export const journal_transactions_page_data = (value) => {
  return {
    heading: "General Ledger History",
    table: {
      className: "grid-cols-2",
      headings: [
        { label: "Date", className: "" },
        { label: "Description", className: "" },
      ],
      rows: [
        {
          label: dateStringFormat(value?.created_date),
        },
        {
          label: value?.description,
          className: "px-1",
        },
      ],
    },
  };
};
