import React, { useState, useEffect } from "react";
import { LeftArrowIcon, DeleteIcon } from "./AppIcons";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";

const file_type = (url) => {
  console.log({ url });
  let file =
    url.split(".").length > 0
      ? url.split(".")[url.split(".").length - 1]
      : null;

  if (
    [
      "png",
      "jpeg",
      "gif",
      "bmp",
      "jpg",
      "apng",
      "svg",
      "bmp ico",
      "png ico",
    ].includes(file)
  )
    return "image";
  else if (
    [
      "mp4",
      "webm",
      "ogg",
      "mov",
      "mkv",
      "flv",
      "avi",
      "wmv",
      "amv",
      "3gp",
    ].includes(file)
  )
    return "video";
  else if (["mp3", "aac", "m4a", "flac", "wav", "wma"].includes(file))
    return "audio";

  return false;
};

export default function ImgPopup({
  view,
  setView,
  files,
  file_index,
  onChange,
  delete_file,
  container_className,
  image_className,
  type,
}) {
  const [render, set_render] = useState(true);

  useEffect(() => {
    console.log({ files, file_index });
    set_render(false);
    setTimeout(() => {
      set_render(true);
    }, 0);
  }, [file_index]);

  if (!view) return null;
  else if (!files[file_index] || !render)
    return (
      <div
        className={
          "bg-white z-20 border shadow rounded-md inset-0 fixed overflow-hidden flex justify-end " +
          container_className
        }
      />
    );
  else
    return (
      <div
        className={
          "bg-white z-20 border shadow rounded-md inset-0 fixed overflow-hidden flex flex-col gap-2 justify-start " +
          container_className
        }
      >
        <div className="w-full flex flex-row-reverse absolute top-0">
          <div
            onClick={() => {
              setView();
            }}
            className={"relative top-3 right-10 flex justify-end delay-200"}
          >
            <span className="px-1 rounded bg-white hover:bg-gray-100 cursor-pointer border shadow text-xl text-red-500 hover:text-red-700">
              &#10005;
            </span>
          </div>
        </div>
        <div className="flex flex-row items-center w-full justify-between">
          <div
            onClick={() => {
              if (file_index !== 0) onChange(file_index - 1);
            }}
            className={
              "h-screen flex items-center justify-center px-10 " +
              (file_index !== 0
                ? "text-gray-900 delay-200 duration-200 hover:bg-gray-800 hover:bg-opacity-30"
                : "text-gray-400")
            }
          >
            <LeftArrowIcon className="h-10" />
          </div>
          <div className="flex flex-col flex-grow gap-4 items-center justify-center h-screen">
            {delete_file && (
              <div className="w-full flex flex-row-reverse">
                <div
                  onClick={() => {
                    delete_file();
                  }}
                  className="text-red-500 hover:text-red-600 cursor-pointer"
                >
                  <DeleteIcon className="h-5 w-5" />
                </div>
              </div>
            )}

            {(
              type ? type === "image" : file_type(files[file_index]) === "image"
            ) ? (
              <img
                src={files[file_index]}
                onClick={(e) => e.stopPropagation()}
                className={"object-scale-down max-h-screen " + image_className}
              />
            ) : (
                type
                  ? type === "video"
                  : file_type(files[file_index]) === "video"
              ) ? (
              <div
                onClick={(e) => e.stopPropagation()}
                className={
                  "object-scale-down max-h-screen transiton duration-200 " +
                  image_className
                }
              >
                <ReactPlayer
                  controls
                  url={files[file_index]}
                  width="100%"
                  height="100%"
                />{" "}
              </div>
            ) : (
                type
                  ? type === "audio"
                  : file_type(files[file_index]) === "audio"
              ) ? (
              <ReactAudioPlayer
                onClick={(e) => e.stopPropagation()}
                src={files[file_index]}
                controls
              />
            ) : null}
          </div>
          <div
            onClick={() => {
              if (file_index !== files.length - 1) onChange(file_index + 1);
            }}
            className={
              "h-screen flex items-center justify-center px-10 " +
              (file_index !== files.length - 1
                ? "text-gray-900 delay-200 duration-200 hover:bg-gray-800 hover:bg-opacity-30"
                : "text-gray-400")
            }
          >
            <LeftArrowIcon className="transform rotate-180 h-10" />
          </div>
        </div>
      </div>
    );
}
