export const arch_review_status = [
  {
    value: "CREATED",
    label: "Created",
    className: "text-yellow-600 bg-yellow-200",
  },
  {
    value: "PENDING",
    label: "Pending",
    className: "text-blue-600 bg-blue-100",
  },
  {
    value: "OPEN",
    label: "Open",
    className: "text-green-600 bg-green-100",
  },
  {
    value: "CLOSED",
    label: "Closed",
    className: "text-red-600 bg-red-100",
  },
];

export const arch_review_resolutions = [
  {
    label: "",
    value: "",
  },
  {
    label: "New",
    value: "NEW",
  },
  {
    label: "Accept-send to ARC",
    value: "ACCEPT-SENT_TO_ARC",
  },
  {
    label: "Approved",
    value: "APPROVED",
  },
  {
    label: "Approved w/Stip",
    value: "APPROVED W/STIP",
  },
  {
    label: "Denied",
    value: "DENIED",
  },
  {
    label: "Disclosure Final",
    value: "DISCLOSURE_FINAL",
  },
  {
    label: "Disclosure Notify",
    value: "DISCLOSURE_NOTIFY",
  },
  {
    label: "Reject - Not Completed",
    value: "REJECT_-_NOT_COMPLETED",
  },
  {
    label: "Under Review - Info Needed",
    value: "UNDER_REVIEW_-_INFO_NEEDED",
  },
];

export const arch_review_plan_type = [
  {
    label: "",
    value: "",
  },
  {
    label: "Arbor",
    value: "ARBOR",
  },
  {
    label: "Fence",
    value: "FENCE",
  },
  {
    label: "Garden",
    value: "GARDEN",
  },
  {
    label: "Landscaping",
    value: "LANDSCAPING",
  },
  {
    label: "Paint",
    value: "PAINT",
  },
  {
    label: "Pool",
    value: "POOL",
  },
  {
    label: "Remodel",
    value: "REMODEL",
  },
  {
    label: "Shed",
    value: "SHED",
  },
];
