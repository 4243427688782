import { navigate } from "raviger";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import {
  getplaidDataHelpsquad,
  getplaidDataHelpsquadFromHoa,
  getStripePaymentSetupHelpsquad,
  payForService,
  paySR,
  paySRUser,
  payWithSavedPaymentMethod,
} from "../../../../../api/Api";
import AccountList from "./payment_popups/AccountList";
import PaymentFailed from "./payment_popups/PaymentFailed";
import PaymentProcessing from "./payment_popups/PaymentProcessing";
import RequiresAction from "./payment_popups/RequiresAction";
import SelectPayOption from "./payment_popups/SelectPayOption";
import Success from "./payment_popups/Success";
import { userAtom } from "../../../../../store/UserAtom";
import { adminTypes } from "../../../../../common/data/adminTypes";

export default function PaymentPopup({
  hoaId,
  payment_method_popup,
  set_payment_method_popup,
  plaid_data_helpsquad,
  plaid_data_hoa,
  accounts,
  update_data,
  set_loading,
  payButtonRef,
}) {
  const [payment_data, set_payment_data] = useState({
    //method:'ACH','CARD','saved_bank','saved_card'
  });
  const [payments_pre_data, set_payments_pre_data] = useState();
  const payButtonACHRef = useRef(null);

  const [user] = useRecoilState(userAtom);

  const userPrivilage = adminTypes.includes(user.selectedUserType);

  useEffect(() => {
    get_payments_pre_data();
  }, []);

  useEffect(() => {
    if (payment_method_popup?.popup === "SUCCESS_SAVING")
      get_payments_pre_data();
  }, [payment_method_popup?.popup]);

  const get_payments_pre_data = () => {
    set_loading(true);
    getStripePaymentSetupHelpsquad({ hoaId: hoaId })
      .then((res) => {
        console.log("payments pre data:", res);
        set_payments_pre_data((payments_pre_data) => {
          return {
            ...payments_pre_data,
            owner: { ...payments_pre_data?.owner, stripe: res },
          };
        });
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
      });

    getplaidDataHelpsquad({ hoaId })
      .then((res) => {
        console.log("payments pre data:", res);
        set_payments_pre_data((payments_pre_data) => {
          return {
            ...payments_pre_data,
            owner: { ...payments_pre_data?.owner, plaid: res },
          };
        });
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error fetching plaid data"
        );
      });

    getplaidDataHelpsquadFromHoa({ hoaId })
      .then((res) => {
        console.log("payments pre data:", res);
        set_payments_pre_data((payments_pre_data) => {
          return {
            ...payments_pre_data,
            hoa: {
              ...payments_pre_data?.hoa,
              plaid: res,
            },
          };
        });
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error fetching plaid data"
        );
      });
  };

  const pay_Service = (sr_id) => {
    set_loading(true);

    payForService({ hoaId: hoaId, sr_id: sr_id })
      .then((res) => {
        console.log(res);

        setTimeout(() => {
          navigate(res.url);
          set_loading(false);
        }, 10);
        // set_payment_method_popup({
        //   ...payment_method_popup,
        //   popup: "SUCCESS",
        // });
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error paying for service"
        );
      });
  };

  const pay_with_saved_payment = () => {
    set_loading(true);
    if (payment_data.method === "stripe")
      payWithSavedPaymentMethod({
        hoaId: hoaId,
        external_id: payment_method_popup.data.external_id,
        payment_method_id: payment_data.id,
      })
        .then((res) => {
          console.log(res);
          set_loading(false);
          update_data && update_data();
          if (res?.payment_status === "succeeded")
            set_payment_method_popup({
              ...payment_method_popup,
              popup: "SUCCESS",
            });
          else if (res?.payment_status === "processing")
            set_payment_method_popup({
              ...payment_method_popup,
              popup: "PROCESSING",
            });
          else if (res?.payment_status === "verify_with_microdeposits")
            set_payment_method_popup({
              ...payment_method_popup,
              popup: "REQUIRES_ACTION",
            });
          else if (res?.payment_status === "payment_failed")
            set_payment_method_popup({
              ...payment_method_popup,
              popup: "FAILED",
            });
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
          toast.error(
            res?.readableMessage ?? res?.message ?? "Error processing payment"
          );
        });
    else if (userPrivilage)
      paySR({
        hoaId: hoaId,
        srId: payment_method_popup.data.external_id,
        plaid_item_id: payment_data.id,
      })
        .then((res) => {
          console.log("used plaid_data_hoa", res);
          set_loading(false);
          set_payment_method_popup({
            ...payment_method_popup,
            popup: "SUCCESS",
          });
          update_data && update_data();
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
          toast.error(
            res?.readableMessage ?? res?.message ?? "Error processing payment"
          );
        });
    else
      paySRUser({
        hoaId: hoaId,
        srId: payment_method_popup.data.external_id,
        plaid_item_id: payment_data.id,
      })
        .then((res) => {
          console.log("used plaid_data_helpsquad", res);
          set_loading(false);
          set_payment_method_popup({
            ...payment_method_popup,
            popup: "SUCCESS",
          });
          update_data && update_data();
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
          toast.error(
            res?.readableMessage ?? res?.message ?? "Error processing payment"
          );
        });
  };

  const on_next = () => {
    if (payment_data?.method === "ACH") {
      if (userPrivilage)
        set_payment_method_popup({
          ...payment_method_popup,
          popup: "ACCOUNTS",
        });
      else payButtonACHRef?.current.click();
    } else if (payment_data?.method === "CARD")
      pay_Service(payment_method_popup.data.external_id);
    else pay_with_saved_payment();
  };

  return (
    <div
      className={
        "bg-gray-500 bg-opacity-70 flex items-center justify-center inset-0 fixed z-10 p-12"
      }
    >
      <div className="flex flex-col items-center max-h-full w-full overflow-auto">
        {payment_method_popup.popup === "PAY_OPTIONS" ? (
          <SelectPayOption
            hoaId={hoaId}
            payment_method_popup={payment_method_popup}
            set_payment_method_popup={set_payment_method_popup}
            payment_data={payment_data}
            set_payment_data={set_payment_data}
            payments_pre_data={
              userPrivilage ? payments_pre_data?.hoa : payments_pre_data?.owner
            }
            on_next={on_next}
            plaid_data={plaid_data_helpsquad}
            set_loading={set_loading}
            payButtonACHRef={payButtonACHRef}
          />
        ) : (payment_method_popup.popup === "ACCOUNTS" ||
            payment_method_popup.popup === "ACH_PAY") &&
          userPrivilage ? (
          <AccountList
            payment_method_popup={payment_method_popup}
            set_payment_method_popup={set_payment_method_popup}
            accounts={accounts}
            hoaId={hoaId}
            plaid_data={plaid_data_hoa}
            set_loading={set_loading}
            payButtonRef={payButtonRef}
          />
        ) : payment_method_popup.popup === "SUCCESS" ||
          payment_method_popup.popup === "SUCCESS_SAVING" ? (
          <Success
            payment_method_popup={payment_method_popup}
            set_payment_method_popup={() => {
              if (payment_method_popup.popup === "SUCCESS")
                set_payment_method_popup({
                  ...payment_method_popup,
                  view: false,
                  popup: "",
                });
              else
                set_payment_method_popup({
                  ...payment_method_popup,
                  popup: "PAY_OPTIONS",
                });
            }}
          />
        ) : payment_method_popup.popup === "PROCESSING" ? (
          <PaymentProcessing
            payment_method_popup={payment_method_popup}
            set_payment_method_popup={set_payment_method_popup}
          />
        ) : payment_method_popup.popup === "FAILED" ? (
          <PaymentFailed
            payment_method_popup={payment_method_popup}
            set_payment_method_popup={set_payment_method_popup}
          />
        ) : payment_method_popup.popup === "REQUIRES_ACTION" ? (
          <RequiresAction
            payment_method_popup={payment_method_popup}
            set_payment_method_popup={set_payment_method_popup}
          />
        ) : null}
      </div>
    </div>
  );
}
