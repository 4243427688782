import { useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import { savePlaid } from "../../../../../../api/Api";
import PayButton from "../../../../../accounting/common/components/PayButton";
import {
  CrossIcon,
  LeftArrowIcon,
  VisaCardIcon,
} from "../../../../../common/AppIcons";
import Transition from "../../../../../tailwind/Transition";
import { userAtom } from "../../../../../../store/UserAtom";
import { adminTypes } from "../../../../../../common/data/adminTypes";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export default function SelectPayOption({
  hoaId,
  payment_method_popup,
  set_payment_method_popup,
  payment_data,
  set_payment_data,
  payments_pre_data,
  on_next,
  plaid_data,
  set_loading,
  payButtonACHRef,
}) {
  const [expand_block, set_expand_block] = useState();
  const [user] = useRecoilState(userAtom);
  const admin = adminTypes.includes(user.selectedUserType);

  const payment = ({ metadata, token }) => {
    set_loading(true);
    savePlaid({
      hoaId,
      public_token: token,
      account_id: metadata.account.id,
      account: metadata?.account,
      receiver: "helpsquad",
    })
      .then((res) => {
        set_loading(false);
        set_payment_method_popup({
          ...payment_method_popup,
          popup: "SUCCESS_SAVING",
        });
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(res?.message ?? "Error saving payment method");
        set_payment_method_popup({
          ...payment_method_popup,
          popup: "FAILED",
        });
      });
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="max-w-lg max-h-screen w-full overflow flex flex-col items-center justify-start overflow-auto rounded-lg bg-white"
    >
      <div className="px-6 pt-4 pb-2 border-b border-gray-200 flex w-full justify-between gap-6">
        <span className="text-gray-800 font-semibold">
          How would you like to pay ?
        </span>
        {
          <div
            onClick={() => {
              set_payment_method_popup({
                ...payment_method_popup,
                view: false,
                popup: "",
              });
            }}
            className="p-1 h-5 w-5 cursor-pointer rounded-full border-2 border-red-500 text-red-500 hover:opacity-70 flex items-center justify-center"
          >
            <CrossIcon className="h-3 w-3" />
          </div>
        }
      </div>
      <div className="w-full px-6 py-3">
        <div className="flex flex-col gap-2 mb-6">
          <span className="font-semibold text-gray-600">Payment Methods</span>
          <div className="border border-gray-200 w-full rounded divide-y">
            {[
              {
                value: "ACH",
                text: (
                  <div className="flex flex-col items-start">
                    <span>Save ACH using Plaid (Recommended)</span>
                    <span
                      style={{ fontSize: "10px" }}
                      className="text-xs opacity-70"
                    >
                      ACH - Using login credentials
                    </span>
                  </div>
                ),
              },
              {
                value: "CARD",
                text: (
                  <div className="flex flex-col items-start">
                    <span>Pay By ACH / Card using Stripe</span>
                    <span
                      style={{ fontSize: "10px" }}
                      className="text-xs opacity-70"
                    >
                      ACH - Using Account number & Routing number
                    </span>
                  </div>
                ),
              },
            ].map((ele, ele_index) => {
              if (!admin || ele.value !== "CARD")
                return (
                  <div
                    key={ele_index}
                    onClick={() => {
                      set_payment_data({
                        ...payment_data,
                        method: ele.value,
                      });
                    }}
                    className="flex flex-row items-start gap-4 w-full px-4 py-3 cursor-pointer"
                  >
                    <input
                      type="radio"
                      className="relative top-1"
                      readOnly
                      checked={ele.value === payment_data.method}
                    />
                    <span
                      className={
                        "text-sm font-semibold " +
                        (ele.value === payment_data.method
                          ? "text-indigo-600"
                          : "text-gray-700")
                      }
                    >
                      {ele.text}
                    </span>
                  </div>
                );
            })}
          </div>
        </div>

        {payments_pre_data?.plaid?.length > 0 && (
          <div className="flex flex-col mb-6">
            <span className="font-semibold text-gray-700">Plaid</span>
            <span className="mt-2 text-sm font-semibold text-gray-600">
              Saved Banks
            </span>

            <div className="border rounded w-full divide-y">
              {payments_pre_data?.plaid?.map((plaid, plaid_index) => {
                return (
                  <div
                    key={plaid_index}
                    className="flex flex-row gap-4 items-start px-4 py-2 w-full"
                  >
                    <input
                      onChange={() => {
                        set_payment_data({
                          ...payment_data,
                          method: "plaid",
                          id: plaid?.id,
                        });
                      }}
                      className="mt-1 cursor-pointer focus:ring-0 focus:ring-white focus:border-none focus:outline-none"
                      type="radio"
                      checked={
                        payment_data?.method === "plaid" &&
                        plaid?.id === payment_data.id
                      }
                    />
                    <div className="flex flex-col flex-grow">
                      <div
                        onClick={() => {
                          expand_block === plaid?.id
                            ? set_expand_block()
                            : set_expand_block(plaid?.id);
                        }}
                        className="cursor-pointer hover:opacity-70 flex flex-row items-center gap-4"
                      >
                        <div className="flex flex-row gap-4 flex-grow items-center text-sm truncate">
                          <div
                            style={{ fontSize: "0.5rem" }}
                            className="rounded bg-black font-bold text-white p-1"
                          >
                            PLAID
                          </div>
                          <span className="font-semibold text-gray-800">
                            {plaid?.metadata?.name}
                          </span>
                          {plaid?.metadata?.mask && (
                            <span className="ml-1 font-semibold text-gray-600">
                              Ending in {plaid?.metadata?.mask}
                            </span>
                          )}
                        </div>
                        <div>
                          <LeftArrowIcon
                            className={
                              "text-gray-600 h-6 w-6 transform -rotate-90"
                            }
                          />
                        </div>
                      </div>

                      <Transition
                        show={expand_block === plaid.id}
                        enter="transition-opacity duration-75"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="text-sm text-gray-600 my-2">
                          <div>Account Name: {plaid?.metadata?.name ?? ""}</div>
                          <div>
                            Ac no:{" "}
                            {plaid?.metadata?.mask
                              ? "xxxx xxxx xxxx " + plaid?.metadata?.mask
                              : ""}
                          </div>
                          <div>
                            Account Type: {plaid?.metadata?.subtype ?? ""}
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {!admin &&
          (payments_pre_data?.stripe?.filter(
            (val) => val.type === "us_bank_account"
          )?.length > 0 ||
            payments_pre_data?.stripe?.filter((val) => val.type === "card")
              ?.length > 0) && (
            <span className="font-semibold text-gray-700">Stripe</span>
          )}

        {!admin &&
          payments_pre_data?.stripe?.filter(
            (val) => val.type === "us_bank_account"
          )?.length > 0 && (
            <div className="flex flex-col gap-2 mt-2">
              <span className="text-sm font-semibold text-gray-600">
                Saved Banks
              </span>
              <div className="border rounded w-full divide-y">
                {payments_pre_data?.stripe
                  ?.filter((val) => val.type === "us_bank_account")
                  .map((account, account_index) => {
                    return (
                      <div
                        key={account_index}
                        className="flex flex-row gap-4 items-start px-4 py-2 w-full"
                      >
                        <input
                          onChange={() => {
                            set_payment_data({
                              ...payment_data,
                              method: "stripe",
                              id: account.id,
                            });
                          }}
                          className="mt-1 relative top-px cursor-pointer focus:ring-0 focus:ring-white focus:border-none focus:outline-none"
                          type="radio"
                          checked={account.id === payment_data.id}
                        />
                        <div className="flex flex-col flex-grow">
                          <div
                            onClick={() => {
                              expand_block === account.id
                                ? set_expand_block()
                                : set_expand_block(account.id);
                            }}
                            className="cursor-pointer hover:opacity-70 flex flex-row items-center gap-4"
                          >
                            <div className="flex flex-row gap-4 flex-grow items-center text-sm truncate">
                              <h1 className="font-semibold text-gray-800">
                                {account.us_bank_account.bank_name}
                              </h1>
                              {expand_block !== account.id && (
                                <h1 className="ml-2 text-gray-600">
                                  Ending in {account.us_bank_account.last4}
                                </h1>
                              )}
                            </div>
                            <div>
                              <LeftArrowIcon
                                className={
                                  "text-gray-600 h-6 w-6 transform -rotate-90"
                                }
                              />
                            </div>
                          </div>

                          <Transition
                            show={expand_block === account.id}
                            enter="transition-opacity duration-75"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="text-sm text-gray-600 my-2">
                              <div>
                                Bank Name:{" "}
                                {account?.us_bank_account?.bank_name ?? ""}
                              </div>
                              <div>
                                Ac. no:{" "}
                                {account?.us_bank_account?.last4
                                  ? "xxxx xxxx xxxx " +
                                    account.us_bank_account.last4
                                  : ""}
                              </div>
                              <div>
                                Account Type:{" "}
                                {account?.us_bank_account?.account_type ?? ""}
                              </div>
                              <div>
                                Routing Number:{" "}
                                {account?.us_bank_account?.routing_number ?? ""}
                              </div>
                            </div>
                          </Transition>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="px-4 py-2 text-sm font-semibold text-gray-500 hover:text-gray-600 cursor-pointer">
                  Add New Bank
                </div> */}
              </div>
            </div>
          )}

        {!admin &&
          payments_pre_data?.stripe?.filter((val) => val.type === "card")
            ?.length > 0 && (
            <div className="flex flex-col gap-2 mt-2">
              <span className="text-sm font-semibold text-gray-600">
                Saved Cards
              </span>

              <div className="border rounded w-full divide-y">
                {payments_pre_data?.stripe
                  ?.filter((val) => val.type === "card")
                  .map((card, card_index) => {
                    return (
                      <div
                        key={card_index}
                        className="flex flex-row gap-4 items-start px-4 py-2 w-full"
                      >
                        <input
                          onChange={() => {
                            set_payment_data({
                              ...payment_data,
                              method: "stripe",
                              id: card.id,
                            });
                          }}
                          className="mt-2 cursor-pointer focus:ring-0 focus:ring-white focus:border-none focus:outline-none"
                          type="radio"
                          checked={card.id === payment_data.id}
                        />
                        <div className="flex flex-col flex-grow">
                          <div
                            onClick={() => {
                              expand_block === card.id
                                ? set_expand_block()
                                : set_expand_block(card.id);
                            }}
                            className="cursor-pointer hover:opacity-70 flex flex-row items-center gap-4"
                          >
                            <div className="flex flex-row gap-4 flex-grow items-center text-sm truncate">
                              {card.card.brand === "visa" ? (
                                <VisaCardIcon className="h-7" />
                              ) : (
                                <div className="border rounded px-2 py-1">
                                  {card.card.brand}
                                </div>
                              )}
                              <h1 className="font-semibold text-gray-800">
                                Ending in {card.card.last4}
                              </h1>
                            </div>
                            <div>
                              <LeftArrowIcon
                                className={
                                  "text-gray-600 h-6 w-6 transform -rotate-90"
                                }
                              />
                            </div>
                          </div>

                          <Transition
                            show={expand_block === card.id}
                            enter="transition-opacity duration-75"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="text-sm text-gray-600 my-2">
                              <div>Card Type: {card?.card?.brand ?? ""}</div>
                              <div>
                                Card Number:{" "}
                                {card?.card?.last4
                                  ? "xxxx xxxx xxxx " + card.card.last4
                                  : ""}
                              </div>
                              <div>
                                Expiry Year: {card?.card?.exp_year ?? ""}
                              </div>
                              <div>
                                Expiry Month:{" "}
                                {card?.card?.exp_month
                                  ? months[card?.card?.exp_month - 1] ?? ""
                                  : ""}
                              </div>
                            </div>
                          </Transition>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="px-4 py-2 text-sm font-semibold text-gray-500 hover:text-gray-600 cursor-pointer">
                    Add New Card
                  </div> */}
              </div>
            </div>
          )}

        <PayButton
          ref={payButtonACHRef}
          payment_api_call={payment}
          data={plaid_data}
          disable={!plaid_data}
          className="h-0 overflow-hidden"
        />

        <div className="m-3 w-full flex flex-row gap-4 justify-end items-center">
          <button
            onClick={() => {
              set_payment_method_popup({
                ...payment_method_popup,
                view: false,
                popup: "",
              });
            }}
            className="border-2 border-gray-600 rounded font-semibold text-gray-600 hover:opacity-70 focus:outline-none px-5 py-2"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              payment_data?.method && on_next();
            }}
            style={{ backgroundColor: "#2A32FD" }}
            className="px-5 py-2 rounded text-white hover:opacity-80 focus:outline-none"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
