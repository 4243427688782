export const addressFormat = (address, className) =>
  address ? (
    <div className={"flex flex-col " + className}>
      <span>{address.streetAddress1 ? address.streetAddress1 + "," : ""}</span>
      <span>
        {address.unitNumber ?? address.streetAddress2 ?? ""}
        {address.unitNumber || address.streetAddress2 ? "," : ""}
      </span>
      <span>
        {(address.city ? address.city + ", " : "") +
          (address.state ?? "") +
          " " +
          (address.zip ?? "")}
      </span>
    </div>
  ) : (
    ""
  );

export const addressFormatLine = (address) =>
  address
    ? (address.streetAddress1 ? address.streetAddress1 : "") +
      (address.streetAddress2 || address.unitNumber
        ? ", " + (address.unitNumber ?? address.streetAddress2)
        : "") +
      (address.city ? ", " + address.city : "")
    : "";

export const addressFormatLine2 = (address) =>
  address
    ? (address.address_object?.street_address_1
        ? address.address_object?.street_address_1
        : "") +
      (address.address_object?.street_address_2 || address.unit_number
        ? ", " +
          (address.unit_number ?? address.address_object?.street_address_2)
        : "") +
      (address.city ? ", " + address.city : "")
    : "";

export const addressFormatLine3 = (address) =>
  address
    ? (address.street_address_1 ? address?.street_address_1 : "") +
      (address?.street_address_2 || address.unit_number
        ? ", " + (address.unit_number ?? address?.street_address_2)
        : "") +
      (address?.city ? ", " + address.city : "") +
      (address?.state ? ", " + address.state : "") +
      " " +
      address.zipcode
    : "";
