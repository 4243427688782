import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { navigate, Link } from "raviger";
import { toast } from "react-toastify";

import { userAtom } from "../../../../store/UserAtom";
import { LeftArrowIcon, LoadingCircleIcon2 } from "../../../common/AppIcons";
import SRBase from "../../srBase";
import { generateNewConversation, getBuildings } from "../../../../api/ApiV2";
import { adminTypes } from "../../../../common/data/adminTypes";

export default function NewChatForm({ url, hoaId }) {
  const [user] = useRecoilState(userAtom);
  const admin = adminTypes.includes(user.selectedUserType);

  const [newChatData, setNewChatData] = useState({});
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState({});
  const [buildings, setBuildings] = useState();

  useEffect(() => {
    getBuildings({ hoaId })
      .then((res) => {
        console.log(res);
        setBuildings(res);
      })
      .catch((res) => {
        console.log(res);
        toast.error("Error fetching address and unit details");
      });
  }, []);

  const generateChat = () => {
    if (!newChatData.title) {
      setWarning({ title: "Fill this field" });
      return;
    }
    if (admin && !newChatData.unit_address_id) {
      setWarning({ unit_address_id: "Fill this field" });
      return;
    }

    if (!newChatData.message) {
      setWarning({ message: "Fill this field" });
      return;
    }
    setLoading(true);
    generateNewConversation({
      data: newChatData,
      hoaId,
    })
      .then((res) => {
        console.log(res);
        navigate(`/hoa/${hoaId}/sr/messages/${res.id}`);
      })
      .catch((res) => {
        console.log(res);
        toast.error(
          res.readableMessage ?? res.message ?? "Failed to generate new chat"
        );
        setLoading(false);
      });
  };

  const removeWarning = (field) => {
    setWarning((warning) => {
      return { ...warning, [field]: "" };
    });
  };

  if (loading)
    return (
      <SRBase hoaId={hoaId} page={url}>
        <div className="w-full py-20 flex justify-center items-center">
          <LoadingCircleIcon2 className="h-8" />
        </div>
      </SRBase>
    );
  else
    return (
      <SRBase hoaId={hoaId} page={url}>
        <div className="w-full bg-white rounded px-5 py-6">
          <div className="flex flex-row items-center gap-2">
            <Link href={"/hoa/" + hoaId + "/sr/messages"}>
              <LeftArrowIcon className="h-6" />
            </Link>
            <span className="font-semibold text-2xl py-3 text-gray-800">
              General Enquiry
            </span>
          </div>
          <div className="w-full pt-7 pb-5 px-6">
            <div className="flex flex-row justify-between items-end">
              <label className="text-sm font-semibold text-gray-600">
                What is it about?
              </label>
              <span className="text-red-600 text-sm">{warning?.title}</span>
            </div>
            <input
              value={newChatData.title}
              onChange={(e) => {
                removeWarning("title");

                setNewChatData((newChatData) => {
                  return { ...newChatData, title: e.target.value };
                });
              }}
              className="mt-1 mb-2 w-full px-3 py-2 border border-gray-300 rounded"
            />

            {admin && (
              <div>
                <div className="flex flex-row justify-between items-end">
                  <label className="text-sm font-semibold text-gray-600">
                    Select Building
                  </label>
                  <span className="text-red-600 text-sm">
                    {warning?.unit_address_id}
                  </span>
                </div>
                <select
                  className="mt-1 mb-2 w-full px-3 py-2 border border-gray-300 rounded"
                  value={newChatData.unit_address_id}
                  onChange={(e) => {
                    removeWarning("unit_address_id");
                    setNewChatData((newChatData) => {
                      return {
                        ...newChatData,
                        unit_address_id: e.target.value,
                      };
                    });
                  }}
                >
                  <option value="" selected disabled>
                    Select Building
                  </option>
                  {buildings?.map((building, index) => (
                    <option key={index} value={building.id}>
                      {building.street_address_1}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="mt-4 flex flex-row justify-between items-end">
              <label className="text-sm font-semibold text-gray-600">
                Leave your message
              </label>
              <span className="text-red-600 text-sm">{warning?.message}</span>
            </div>

            <textarea
              value={newChatData.message}
              onChange={(e) => {
                removeWarning("message");
                setNewChatData((newChatData) => {
                  return { ...newChatData, message: e.target.value };
                });
              }}
              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded"
            />
            <div className="mt-5 w-full flex flex-row justify-end gap-3">
              <Link href={"/hoa/" + hoaId + "/sr/messages"}>
                <button className="px-5 py-2 rounded font-semibold text-white bg-red-600 hover:bg-red-500">
                  Close
                </button>
              </Link>
              <button
                onClick={generateChat}
                className="px-5 py-2 rounded font-semibold text-white bg-indigo-500 hover:bg-indigo-400"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </SRBase>
    );
}
