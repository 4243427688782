import { KeyIcon, PersonIcon } from "../../../../../common/AppIcons";

export const sidebar_buttons = [
  {
    label: "My Profile",
    icon: PersonIcon,
    link: "/profile",
  },
  {
    label: "Reset Password",
    icon: KeyIcon,
    link: "/profile/reset_password",
  },
];
