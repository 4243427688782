import {
    BankingIcon,
    CardIcon2,
    ChartAccountsIcon,
    ClockIcon3,
    DollarIcon2,
    ExitIcon,
    HomeIcon,
    NotepadIcon,
    SettingsIcon,
    StatisticsBarsIcon,
} from "../../../../common/AppIcons";

export const sidebar_heading = {
    label: "ACCOUNTING",
};

export const sidebar_buttons = [{
        label: "Home",
        icon: HomeIcon,
        link: "/hoa/:hoaId/accounting/home",
    },
    {
        label: "Recent",
        icon: ClockIcon3,
        link: "/hoa/:hoaId/recent_transactions/",
    },
    {
        label: "Banking",
        icon: BankingIcon,
        link: "/hoa/:hoaId/bankaccounts/",
    },
    {
        label: "Journal Entry",
        icon: NotepadIcon,
        link: "/hoa/:hoaId/accounts/journal_entry/",
    },
    {
        label: "Revenue",
        icon: DollarIcon2,
        link: "/hoa/:hoaId/invoices/",
    },
    {
        label: "Expenses",
        icon: CardIcon2,
        link: "/hoa/:hoaId/bills/",
        pages:['/hoa/:hoaId/bills/','/hoa/:hoaId/vendors/',]
    },
    {
        label: "Chart of accounts",
        icon: ChartAccountsIcon,
        link: "/hoa/:hoaId/accounts/",
        pages:["/hoa/:hoaId/accounts/",]
    },
    {
        label: "Reports",
        icon: StatisticsBarsIcon,
        link: "/hoa/:hoaId/accounts/reports",
        pages:["/hoa/:hoaId/accounts/reports","/hoa/:hoaId/accounts/profit_loss/","/hoa/:hoaId/trialbalance/"]
    },
];

export const sidebar_options_buttons = [{
        label: "Settings",
        icon: SettingsIcon,
        link: "/hoa/:hoaId/plaid_stripe_tokens/",
    },
    {
        label: "Exit Accounting",
        icon: ExitIcon,
        link: "/hoa/:hoaId/",
    },
];
