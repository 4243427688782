import React from "react";
import { StripeIcon } from "../../common/AppIcons";

export default function Integrations({ integrationsComponent, className }) {
  return (
    <div
      ref={integrationsComponent}
      style={{ backgroundColor: "#F5F5F5" }}
      className={
        "w-full p-10 py-16 flex flex-col items-center rounded-md " + className
      }
    >
      <div
        className="px-4 py-1.5 rounded"
        style={{
          backgroundColor: "rgba(42, 50, 253, 0.07)",
          color: "#2A32FD",
          border: "1px solid #d8d8e7",
        }}
      >
        Integrations
      </div>

      <span
        style={{ fontSize: "26px", fontWeight: "800" }}
        className="mt-5 mx-auto max-w-md text-center"
      >
        Turbo charge your Property management with integrations.
      </span>

      <div className="mt-9 flex flex-row items-center justify-center gap-10 flex-wrap">
        {[
          <StripeIcon className="h-7" />,
          <img className="h-7 object-cover" src="/plaid.png" alt="Plaid" />,
        ].map((ele, index) => (
          <div
            key={index}
            className="rounded border border-dashed border-indigo-600 bg-white px-12 py-2.5"
          >
            {ele}
          </div>
        ))}
      </div>
    </div>
  );
}
