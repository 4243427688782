import React from "react";

export default function TextInput({
  wrapperClassName,
  className,
  name,
  placeholder,
  value,
  onChange,
  label,
  area,
  hint,
}) {
  const onChangeDispatch = (value) => {
    const updatedValue = value;
    onChange(updatedValue);
  };

  return area ? (
    <div className={wrapperClassName ?? "sm:col-span-6"}>
      {label && (
        <label
          htmlFor="about"
          className="block text-sm font-medium text-gray-700"
        >
          label
        </label>
      )}
      <div className="mt-1">
        <textarea
          id={name}
          name={name}
          rows={3}
          onChange={(e) => onChangeDispatch(e.target.value)}
          className={
            "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md " +
            className
          }
          defaultValue={value}
        />
      </div>
      <p className="mt-2 text-sm text-gray-500">{hint}</p>
    </div>
  ) : (
    <div className={wrapperClassName ?? ""}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="mt-1">
        <input
          id={name}
          type="text"
          name={name}
          value={value ?? ""}
          onChange={(e) => onChangeDispatch(e.target.value)}
          className={
            className ??
            "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          }
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
