import { useRecoilState, useSetRecoilState } from "recoil";
import OutsideClickHandler from "react-outside-click-handler";

import { userAtom } from "../store/UserAtom";
import Popup from "../components/common/Popup";
import { CrossInCircleIcon, LogoutIcon } from "../components/common/AppIcons";

export default function UserProfileChangePopup({ show, setShow }) {
  const [user] = useRecoilState(userAtom);
  const setUser = useSetRecoilState(userAtom);

  const selectedProfile = user?.userProfiles?.find(
    (val) => val.id === user.selectedUserProfile
  );

  return (
    <Popup
      show={show}
      setShow={() => setShow(false)}
      wrapperClassName="justify-end sm:justify-center"
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setShow(false);
        }}
      >
        <div className="bg-white max-w-md mx-auto pt-5 pb-7 px-6 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="w-full flex items-center justify-end">
            <button
              onClick={() => setShow(false)}
              type="button"
              className="bg-white rounded-full p-1 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
            >
              <CrossInCircleIcon className="text-red-600 h-5" />
            </button>
          </div>
          <div className="w-full flex flex-col gap-1 items-center">
            <span className="text-xl font-semibold">Switch User type</span>
            <span className="mb-4 text-sm font-light text-gray-400">
              To switch the user type please select from below
            </span>
            {user?.userProfiles?.map((profile, index) => {
              let userType = profile?.user_type?.toLowerCase().replace("_", " ")
                ? profile?.user_type?.toLowerCase().replace("_", " ")
                : "";
              return (
                <div
                  key={index}
                  className="w-full bg-gray-100 rounded-md p-4 flex flex-row justify-between items-center gap-4"
                >
                  <div className="flex flex-col gap-1">
                    <span className="capitalize font-semibold">
                      {(user.hoaType === "SINGLE_FAMILY" ||
                        user.hoaType === "MULTI_FAMILY") &&
                      userType === "board member"
                        ? "owner"
                        : userType}
                    </span>
                    {profile?.unit_object && (
                      <span className="capitalize text-sm text-gray-500">
                        {(profile.unit_object.unit_number ?? "") +
                          (profile.unit_object.address_object?.street_address_1
                            ? ", "
                            : "") +
                          (profile.unit_object.address_object
                            ?.street_address_1 ?? "")}
                      </span>
                    )}
                  </div>
                  {selectedProfile?.id === profile?.id ? (
                    <div className="bg-gray-200 text-green-500 rounded px-2 py-1 text-sm">
                      ACTIVE
                    </div>
                  ) : (
                    <button
                      className="hover:opacity-70 text-white rounded p-2"
                      onClick={() => {
                        setUser({
                          ...user,
                          selectedUserProfile: profile.id,
                          selectedUserType: profile.user_type,
                        });
                        localStorage.setItem("selectedUserProfile", profile.id);
                        sessionStorage.setItem(
                          "selectedUserProfile",
                          profile.id
                        );
                        setShow(false);
                      }}
                      style={{ backgroundColor: "#2A32FD" }}
                    >
                      <LogoutIcon className="h-5" />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </OutsideClickHandler>
    </Popup>
  );
}
