import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { payInvoice1Hoa } from "../../../api/Api";
import PayButton from "../../accounting/common/components/PayButton";
import { CrossIcon, LeftArrowIcon } from "../../common/AppIcons";

const user_id = () => {
  return Number(
    // JSON.parse(document.getElementById("user_object").innerHTML).user
    1
  );
};

export default function AccountList({
  hoaId,
  payment_method_popup,
  set_payment_method_popup,
  accounts,
  amountFormat,
  plaid_data,
  set_loading,
  payButtonRef,
}) {
  const payment = ({ metadata, token }) => {
    console.log({ metadata, token });
    set_loading(true);
    payInvoice1Hoa({
      hoaId: hoaId,
      public_token: token,
      account_id: metadata?.account?.id,
      bank_account_id: payment_method_popup.data?.account_id,
      account: metadata?.account,
    })
      .then((res) => {
        console.log("used plaid_data_hoa");
        set_loading(false);
        set_payment_method_popup({
          ...payment_method_popup,
          popup: "SUCCESS",
        });
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        set_payment_method_popup({
          ...payment_method_popup,
          popup: "FAILED",
        });
        toast.error(res?.message ?? "Error processing payment");
      });
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-full flex flex-col items-center justify-start px-10 pt-5 pb-8 rounded-lg bg-white"
    >
      <div className="w-full flex flex-row items-center gap-1">
        <div
          onClick={() => {
            set_payment_method_popup({
              ...payment_method_popup,
              popup: "PAY_OPTIONS",
            });
          }}
          className="p-1 rounded border-2 border-white hover:border-gray-300 text-gray-800 hover:text-gray-700 cursor-pointer"
        >
          <LeftArrowIcon className="h-5" />
        </div>
        <span className="text-gray-800 text-lg font-semibold">
          Please select an account to pay from
        </span>
      </div>
      <div className="w-full px-1 mt-7 overflow-auto border rounded">
        <div className="w-full">
          {accounts && accounts.length > 0 ? (
            accounts?.map((account, account_index) => (
              <div
                key={account_index}
                onClick={() => {
                  if (payment_method_popup.data?.account_id === account.id) {
                    set_payment_method_popup({
                      ...payment_method_popup,
                      data: {
                        ...payment_method_popup.data,
                        account_id: null,
                      },
                    });
                  } else
                    set_payment_method_popup({
                      ...payment_method_popup,
                      data: {
                        ...payment_method_popup.data,
                        account_id: account.id,
                      },
                    });
                }}
                className={
                  "flex flex-row items-center justify-between px-4 pt-3 pb-2 cursor-pointer border-b " +
                  (account.id === payment_method_popup?.data?.account_id
                    ? "text-blue-700"
                    : "text-gray-600")
                }
              >
                <div className="flex flex-row items-center gap-3">
                  <input
                    className="relative top-px"
                    type="radio"
                    readOnly
                    checked={
                      account.id === payment_method_popup?.data?.account_id
                    }
                  />
                  <h1 className="font-semibold">{account.name}</h1>
                </div>
                <h1 className="font-semibold">
                  ${amountFormat(account.current_amount)}
                </h1>
              </div>
            ))
          ) : (
            <div className="px-8 py-12 font-semibold text-gray-500 flex items-center justify-center w-full">
              No Accounts Present
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end w-full">
        <button
          style={{ backgroundColor: "#2A32FD" }}
          className={
            "mt-5 py-2 px-4 flex items-center justify-center text-white font-semibold rounded " +
            (payment_method_popup?.data?.account_id
              ? "hover:opacity-80"
              : "opacity-70")
          }
          onClick={() => {
            if (payment_method_popup?.data?.account_id) {
              set_payment_method_popup({
                ...payment_method_popup,
                popup: "ACH_PAY",
              });
            }
          }}
        >
          Pay Now
        </button>
      </div>
      {plaid_data && (
        <PayButton
          ref={payButtonRef}
          data={plaid_data}
          disable={!plaid_data}
          on_open={() =>
            set_payment_method_popup({
              ...payment_method_popup,
              popup: "ACCOUNTS",
            })
          }
          payment_api_call={payment}
          className="h-0 overflow-hidden"
        />
      )}
    </div>
  );
}
