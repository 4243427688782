export default function SRPrefix(data) {
  return (
    "#" +
    (data?.ticketId ? data?.ticketId + "-" : "") +
    (data?.isDraft ? "I" : "SR") +
    (data?.serviceRequestIndex ?? "")
  );
}

export function EstimatePrefix(data) {
  let id = data?.ticketId ?? "";
  return "#E" + id;
}

export function ticketPrefix(data) {
  return (
    "#" + (data?.ticketId ? data?.ticketId : "")
    // (data?.isDraft ? "I" : "SR") +
    // (data?.serviceRequestIndex ?? "")
  );
}
