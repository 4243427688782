import { Link } from "raviger";

export default function Messages({ hoaId, messages }) {
  return (
    <section className="max-w-3xl flex-grow">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="p-6">
          <h2
            className="text-base font-medium text-gray-900"
            id="announcements-title"
          >
            Messages
          </h2>
          <div className="flow-root mt-6">
            <ul className="-my-5 divide-y divide-gray-200">
              {messages?.length > 0 ? (
                messages.map((message, message_index) => {
                  return (
                    <li key={message_index} className="py-4">
                      <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                        <h3 className="text-sm font-semibold text-gray-800">
                          <span>
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            {message.title}
                          </span>
                        </h3>
                        <p className="mt-1 text-sm text-gray-600 line-clamp-2">
                          {message.description}
                        </p>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="py-4">
                  <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                    <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                      Your messages will show up here
                    </p>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className="mt-6">
            <Link
              href={"/hoa/" + hoaId + "/community/messages/"}
              className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 duration-200 hover:bg-gray-50"
            >
              {messages?.length > 0 ? "View all" : "Go to Messages"}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
