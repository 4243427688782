import React, { useEffect, useState } from "react";

import { getBill, getBills, getHoaMembers } from "../../api/ApiV2";
import Paginator from "../../common/Paginator";
import Modal from "../common/Modal";
import { amountFormat } from "../common/AmountFormat";
import AddRevenue from "../accounting/components/addRevenue";
import { useRecoilState } from "recoil";
import { userAtom } from "../../store/UserAtom";
import RevanueDetailPopup from "../accounting/components/addRevenue/RevenueDetailPopup";
import { addressFormatLine2 } from "../../utils/addressFormat";
import { Link } from "raviger";

export default function PaymentsSettled({
  hoaId,
  cardPaymentStatus,
  invoiceId,
}) {
  const [user] = useRecoilState(userAtom);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState({ current: 0, total: 0 });
  // const [vendors, setVendors] = useState([]);
  // const [vendorsPage, setVendorsPage] = useState({ current: 1, total: 0 });
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState({ show: false, setting: "NONE", data: {} });

  useEffect(() => {
    if (menu.setting !== "CREATE_INVOICE") {
      fetchBills();
      // fetchVendors();
    }
  }, [menu.setting]);

  useEffect(() => {
    setPage({ total: 0, current: 0 });
    fetchBills(0);
  }, [user.selectedUserProfile]);

  // useEffect(() => {
  //   fetchVendors();
  // }, [vendorsPage.current]);

  useEffect(() => {
    if (cardPaymentStatus && invoiceId) {
      setMenu({
        setting: "INVOICE_POPUP",
        data: {
          id: invoiceId,
          cardPaymentStatus,
        },
      });
    }
  }, []);

  const fetchBills = (current) =>
    getBills({
      hoaId: hoaId,
      page: current ?? page.current,
      invoice: "True",
      paid: "True",
      user: user.id,
    })
      .then((res) => {
        setPage({ ...page, total: res.count });
        setTransactions(res.results);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
      });

  // const fetchVendors = () => {
  //   getHoaMembers(hoaId, vendorsPage.current)
  //     .then((res) => {
  //       setVendorsPage({ ...vendorsPage, total: res.count });
  //       setVendors(res.results);
  //     })
  //     .catch((_) => {});
  // };

  return (
    <div className="pl-3 sm:px-4 lg:px-5 pt-5 pb-7 sm:w-full m-auto bg-gray-100 rounded-lg">
      <div className="flex flex-row font-semibold text-xl w-full justify-start gap-10 ml-1">
        <Link href={"/hoa/" + hoaId + "/invoices/user/unsettled"}>
          <span className="opacity-50 hover:opacity-70 cursor-pointer">
            Unpaid
          </span>
        </Link>

        <span className="opacity-80">Paid</span>
      </div>

      <div className="flex flex-col md:grid md:grid-cols-4">
        <div className="md:col-span-3">
          <div className="h-px w-full bg-gray-400 mt-1" />
          <div className="grid grid-cols-4 gap-2 px-5 mt-3 mb-2 text-sm opacity-50 font-bold">
            {[
              { title: "ADDRESS", class: "" },
              { title: "DESCRIPTION", class: "" },
              { title: "DATE", class: "" },
              { title: "AMOUNT", class: "w-full text-right" },
            ].map((value) => {
              return (
                <span key={value.title} className={value.class}>
                  {value.title}
                </span>
              );
            })}
          </div>
          <div className="overflow-auto">
            {loading ? (
              <div className="text-center font-semibold text-gray-500 my-5">
                Loading...
              </div>
            ) : transactions.length === 0 ? (
              <div className="text-center text-sm text-gray-500 my-5">
                No Transaction Available
              </div>
            ) : (
              transactions.map((value) => {
                return (
                  <div
                    key={value.id}
                    className="grid grid-cols-4 gap-2 border border-gray-500 text-gray-800 rounded-md text-sm font-semibold bg-white hover:bg-blue-300 cursor-pointer px-5 py-3 shadow"
                    onClick={() => {
                      setMenu({
                        setting: "INVOICE_POPUP",
                        data: value,
                      });
                    }}
                  >
                    <span className="break-words">
                      {addressFormatLine2(value.customer_unit_object) ??
                        "Untracked Customer"}
                    </span>
                    <span className="break-words">{value.name}</span>
                    <span className="">{value.date}</span>
                    <div className="w-full flex flex-row-reverse">
                      <span className="break-all">
                        ${Number(value.amount)?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <Paginator
            totalPage={page.total}
            currentPage={page.current + 1}
            entryPerPage={50}
            onChange={(pageNo) => {
              setPage({ ...page, current: pageNo - 1 });
              fetchBills(0);
            }}
          />
        </div>
        <div className="mt-6 md:ml-5 md:pt-6">
          <div className="border border-gray-500 hover:border-blue-500 shadow-inner opacity-90 rounded-lg bg-white flex flex-col w-full pb-3 pt-2 pr-6 pl-4">
            <span className="flex flex-col">
              <h1 className="w-full font-bold text-gray-600">Total Paid</h1>
              {/* <div className="h-px w-20 relative bottom-px bg-gray-500 ml-3" /> */}
            </span>

            <h1
              className={
                "w-full pr-2 text-right relative top-1 text-blue-900 opacity-90 font-bold text-2xl"
              }
            >
              $
              {amountFormat(
                transactions?.reduce((sum, value) => {
                  return sum + Number(value.amount);
                }, 0)
              )}
            </h1>
          </div>
        </div>
      </div>
      <Modal
        wide
        show={menu.setting !== "NONE"}
        setShow={() => {
          setMenu({ ...menu, setting: "NONE" });
        }}
      >
        {menu.setting === "CREATE_INVOICE" ? (
          <AddRevenue
            hoaId={hoaId}
            setShow={() => {
              setMenu({ ...menu, setting: "NONE" });
            }}
          />
        ) : (
          <RevanueDetailPopup
            hoaId={hoaId}
            remove_pay={true}
            onSuccess={() => {
              setMenu({ ...menu, setting: "NONE" });
            }}
            billId={menu.data?.id}
            cardPaymentStatus={menu.data?.cardPaymentStatus}
          />
        )}
      </Modal>
    </div>
  );
}
