import { useState } from "react";
import { postIntegrateSquadhub } from "../../../../api/ApiV2";
import { LoadingCircleIcon } from "../../../common/AppIcons";
import { toast } from "react-toastify";

export default function SquadhubIntegration({ hoaId, data, get_hoa }) {
  const [integrateSquadhubLoading, setIntegrateSquadhubLoading] =
    useState(false);

  const integrateSquadhub = () => {
    if (integrateSquadhubLoading) return;
    setIntegrateSquadhubLoading(true);
    postIntegrateSquadhub({ hoaId })
      .then((res) => {
        setIntegrateSquadhubLoading(false);
        console.log(res);
        get_hoa();
        toast.success("Profile Created in SquadHub");
      })
      .catch((res) => {
        setIntegrateSquadhubLoading(false);
        console.log(res);
        toast.error(
          typeof res === "object" && res?.[0]
            ? res?.[0]
            : "Error Creating Profile in SquadHub"
        );
      });
  };

  return (
    <div className="p-10 flex flex-col gap-5">
      <div className="mt-5 flex flex-row items-center gap-3">
        <span className="">Status</span>:
        {data.external_uuid === null ? (
          <button
            onClick={integrateSquadhub}
            className="px-3 py-2 text-sm rounded bg-blue-500 hover:bg-blue-600 text-white"
          >
            Create Profile in SquadHub
          </button>
        ) : (
          <div className="px-3 py-1.5 text-sm rounded border border-green-500 text-green-500">
            Active
          </div>
        )}
        {integrateSquadhubLoading && <LoadingCircleIcon className="h-5" />}
      </div>
    </div>
  );
}
