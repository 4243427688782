import React from "react";
import { find_file_type } from "../../utils/findFileType";
import { PlayIcon } from "./AppIcons";

export default function ImgThumbnail({
  url,
  onClick,
  className,
  wrapper_className,
}) {
  return (
    <div className={"relative " + wrapper_className}>
      <div
        onClick={onClick}
        className={
          "inset-0 absolute bg-opacity-0 hover:bg-opacity-30 bg-zinc-500 flex items-center justify-center z-10 " +
          (onClick ? "cursor-pointer" : "")
        }
      >
        <ThumbnailIcon url={url} />
      </div>
      <img
        src={url.substr(0, url.lastIndexOf(".")) + ".jpg"}
        className={
          "object-cover rounded border-2 border-gray-100 hover:border-blue-600 cursor-pointer " +
          className
        }
      />
    </div>
  );
}

function ThumbnailIcon({ url }) {
  let file_type = find_file_type(url);

  if (file_type === "video")
    return (
      <div className="rounded-full flex items-center justify-center bg-black bg-opacity-50 shadow h-7 w-7">
        <PlayIcon className="h-4 text-white relative left-px transform translate-x-px" />
      </div>
    );
  else return null;
}
