import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { dateString } from "../../../../utils/DateUtils";
import LoadingScreen from "../../../common/LoadingScreen";
import PopupHeading from "../../../common/PopupHeading";
import BillDataForm from "./components/billDataForm";
import BottomButtons from "./components/bottomButtons";
import Summary from "../../common/components/summary";
import BillTable from "./components/billTable";
import { getAccounts, postCheck } from "../../../../api/ApiV2";

const newLeg = { description: "", expense_account: undefined, amount: "" };

export default function Check({
  hoaId,
  onSuccess,
  readOnly,
  baseData = {
    name: "Bill",
    legs: [newLeg],
    payments: [],
    merchant_name: undefined,
    account: undefined,
    due_date: undefined,
  },
}) {
  const [data, setData] = useState(baseData);
  const [expenseAccounts, setExpenseAccounts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selectAccount, setSelectAccount] = useState(baseData.account);
  const [loading, setLoading] = useState(false);
  const [warning, set_warning] = useState({});

  useEffect(() => {
    setData(baseData);
    setSelectAccount(baseData.account);
  }, [baseData]);

  useEffect((_) => {
    console.log("useEffect", readOnly);
    fetchAccounts();
  }, []);

  const fetchAccounts = () => {
    getAccounts({ hoaId, type: "ASSET", bank_account: true })
      .then((res) => {
        console.log("account", res.results);
        setAccounts(res.results);
      })
      .catch(console.log);

    getAccounts({ hoaId, type: "EXPENSE" })
      .then((res) => {
        console.log("EX accounts", res);
        setExpenseAccounts(res.results);
      })
      .catch(console.log);
  };

  const onSubmit = () => {
    if (readOnly) {
      onSuccess();
      return;
    }

    if (!loading) {
      setLoading(true);
      postCheck({
        hoaId,
        data: {
          check_account: selectAccount,
          payee_name: data.payee_name,
          due_date: dateString(data.due_date),
          items: data.legs,
          description: "Check creation",
          cheque_number: data.cheque_number,
        },
      })
        .then((res) => {
          setLoading(false);
          onSuccess(res.results);
          set_warning({});
        })
        .catch((res) => {
          set_warning(res[0]);
          toast.error(res?.message ?? "Error submitting data");
          console.log(res);
          setLoading(false);
        });
    }
  };

  const add_bill_row = () => {
    setData({ ...data, legs: [...data.legs, newLeg] });
  };

  const delete_bill_row = (index) => {
    if (data.legs?.length > 1) {
      let arr = data.legs;
      arr.splice(index, 1);
      setData({ ...data, legs: arr });
    }
  };

  const delete_warning = (item) => {
    let temp_warning = warning;

    delete temp_warning[item];

    set_warning(temp_warning);
  };

  const find_total = data?.legs
    ?.reduce(
      (total, leg) =>
        total +
        Number(leg.amount && !(readOnly && leg.amount < 0) ? leg.amount : 0),
      0
    )
    ?.toFixed(2);
  console.log("data", data);
  if (data !== undefined)
    return (
      <div className="w-full antialiased sans-serif max-h-screen rounded">
        <LoadingScreen show={loading} />
        <div className="container mx-auto py-4 md:py-6 px-3 md:px-5 bg-white rounded">
          <PopupHeading closePopup={onSuccess} className="mb-5">
            {readOnly ? "Check" : "Create Check"}
          </PopupHeading>

          <div className="sm:mt-7 px-3 sm:px-5 w-full max-h-full">
            <BillDataForm
              className="my-8"
              {...{
                hoaId,
                data,
                setData,
                delete_warning,
                warning,
                setSelectAccount,
                accounts,
                selectAccount,
                fetchAccounts,
                readOnly,
              }}
            />

            <BillTable
              className="mt-8"
              {...{
                readOnly,
                data,
                setData,
                delete_bill_row,
                add_bill_row,
                warning,
                set_warning,
                expenseAccounts,
              }}
            />

            <div className="mt-8 pr-8 w-full flex flex-col md:flex-row items-center md:items-end justify-end gap-x-6 gap-y-8">
              <Summary
                show={Number(find_total) !== 0}
                data={[
                  {
                    label: "Sub Total",
                    value: find_total,
                  },
                ]}
                title="Check Summary"
              />
            </div>

            <div className="py-2 ml-auto mt-7 md:mt-12">
              <BottomButtons
                {...{
                  setShow: () => onSuccess(),
                  readOnly,
                  onSubmit,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  else return null;
}
