import { dateStringFormat } from "../../../../../../../utils/DateUtils";
import { amountFormat } from "../../../../../../common/utility/amountFormat";

export const tableRows = ({ value }) => [
  {
    label: dateStringFormat(value?.created_date),
    className: "col-span-2",
  },
  {
    label: value?.description,
    className: "col-span-2 justify-start",
  },
  {
    label: amountFormat(value?.computed_transaction_amount),
    className: "justify-end",
  },
];
