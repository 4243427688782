import React, { useState, useEffect } from "react";
import { LoadingCircleIcon } from "../../../../common/AppIcons";
import {
  getHoaMembers,
  getBoardMeeting,
  editBoardMeeting,
  getBoardMeetingAttendees,
} from "../../../../../api/ApiV2";
import "../linkify.css";
import ForumPopup from "../ForumPopup";
import { EditorState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";
import "../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MeetingDetails from "./MeetingDetails";
import SelectHeadings from "../../../../common/Form/SelectHeadings";
import MeetingAttendance from "./MeetingAttendance";
import MeetingAgenda from "./MeetingAgenda";
import EndMeeting from "../popups/EndMeeting";
import { navigate } from "raviger";
import { getAgendas } from "../utility/getAgenda";
import { useRecoilState } from "recoil";
import { userAtom } from "../../../../../store/UserAtom";

export default function BoardMeetingStarted({ hoaId, boardMeetingId }) {
  const [user] = useRecoilState(userAtom);
  const [members, set_members] = useState();
  const [meeting, set_meeting] = useState();
  const [end_meeting_popup, set_end_meeting_popup] = useState(false);
  const [forum_popup, set_forum_popup] = useState(false);
  const [editor_state, set_editor_state] = useState({});
  const [page, set_page] = useState("MEETING_DETAILS");
  const [loading, set_loading] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [attendees, setAttendees] = useState();

  useEffect(() => {
    getHoaMembers(hoaId)
      .then((res) => {
        console.log("members : ", res.results);
        set_members(res.results);
      })
      .catch(console.log);
    get_board_meeting();
    getAttendees();
  }, [user.selectedUserProfile]);

  const getAttendees = () => {
    getBoardMeetingAttendees({ hoaId, boardMeetingId })
      .then((res) => {
        setAttendees(res.results?.filter((val) => !val.deleted));
      })
      .catch(console.log);
  };

  const get_board_meeting = () =>
    getBoardMeeting({ hoaId, boardMeetingId })
      .then((res) => {
        console.log("res", res);
        let temp_res = res;
        temp_res.agenda_items = temp_res.agenda_items;
        set_meeting(temp_res);
        res?.agenda?.map((val, index) => {
          set_editor_state((editor_state) => {
            return {
              ...editor_state,
              [index]: {
                description: EditorState.createWithContent(
                  stateFromMarkdown(val.description)
                ),
                note: EditorState.createEmpty(),
              },
            };
          });
        });
        getBoardMeetingAgenda();
      })
      .catch(console.log);

  const getBoardMeetingAgenda = () => {
    setLoadingAgenda(true);
    getAgendas({
      hoaId,
      boardMeetingId,
      onFinish: (agendas) => {
        setLoadingAgenda(false);
        console.log("agenda", agendas);
        set_meeting((meeting) => {
          return {
            ...meeting,
            agenda: agendas,
          };
        });
        agendas?.map((val, index) => {
          set_editor_state((editor_state) => {
            return {
              ...editor_state,
              [index]: {
                description: EditorState.createWithContent(
                  stateFromMarkdown(val.description)
                ),
                note: EditorState.createEmpty(),
              },
            };
          });
        });
      },
    });
  };

  const end_board_meeting = () => {
    set_loading(true);
    editBoardMeeting({
      hoaId,
      id: meeting.id,
      data: { ...meeting, status: "COMPLETED" },
    })
      .then((res) => {
        set_loading(false);
        console.log(res);
        navigate("/hoa/" + hoaId + "/community/board_meetings/");
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
      });
  };

  const headings = () => {
    let headings = ["Meeting Details", "Attendence", "Agenda"];
    return headings.map((val) => {
      return { label: val, value: val.toUpperCase().replaceAll(" ", "_") };
    });
  };

  if (!members || !meeting || loadingAgenda)
    return (
      <div className="w-full flex justify-center">
        <LoadingCircleIcon className="h-12 my-10 text-blue-500" />
      </div>
    );
  else if (forum_popup)
    return (
      <ForumPopup
        show={forum_popup}
        setShow={(value = false) => {
          set_forum_popup(value);
        }}
      />
    );
  return (
    <div className="bg-white rounded flex flex-col gap-3 py-4">
      <div className="px-4 border-b flex flex-row items-baseline justify-between w-full">
        <SelectHeadings
          headings={headings()}
          selected={page}
          onChange={(val) => {
            set_page(val);
          }}
          base_heading_width={130}
          className="relative top-px"
        />
        <div className="h-full flex flex-col justify-start">
          <button
            onClick={() => {
              set_end_meeting_popup(true);
            }}
            className="rounded px-3 py-1 bg-red-200 text-red-600 text-sm font-semibold hover:opacity-70"
          >
            End Meeting
          </button>
        </div>
      </div>
      {page === headings()[0]?.value ? (
        <MeetingDetails
          meeting={meeting}
          members={members}
          attendees={attendees}
        />
      ) : page === headings()[1]?.value ? (
        <MeetingAttendance
          hoaId={hoaId}
          meeting={meeting}
          members={members}
          attendees={attendees}
          setAttendees={setAttendees}
          getAttendees={getAttendees}
          nextPage={() => {
            if (headings()[2]) set_page(headings()[2].value);
          }}
        />
      ) : null}
      <MeetingAgenda
        hidden={page !== headings()[2]?.value}
        hoaId={hoaId}
        meeting={meeting}
        set_meeting={set_meeting}
        editor_state={editor_state}
        set_editor_state={set_editor_state}
        members={members}
        attendees={attendees}
      />

      <EndMeeting
        view={end_meeting_popup}
        set_view={() => set_end_meeting_popup(false)}
        loading={loading}
        end_board_meeting={end_board_meeting}
      />
    </div>
  );
}
