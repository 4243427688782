import { orgUserTypes } from "../AddUsers";

const defaultClassName =
  "sm:w-full px-4 py-2 sm:tracking-wide font-normal text-gray-800 rounded outline-none ring-none focus:outline-none bg-gray-100 focus:bg-gray-200 ";

const warning_text = {
  "This field may not be blank.": "Please fill this field",
};

export const memberFormData = ({ user, setUser, warning, remove_warning }) => [
  {
    label: "First Name",
    value: user?.user?.first_name ?? "",
    warning: warning_text[warning?.first_name ?? ""] ?? warning?.first_name,
    className: defaultClassName,
    onChange: (val) => {
      remove_warning("first_name");
      setUser((user) => {
        return {
          ...user,
          user: { ...(user.user ?? {}), first_name: val },
        };
      });
    },
  },
  {
    label: "Last Name",
    value: user?.user?.last_name ?? "",
    warning: warning_text[warning?.last_name ?? ""] ?? warning?.last_name,
    className: defaultClassName,
    onChange: (val) => {
      remove_warning("last_name");
      setUser((user) => {
        return {
          ...user,
          user: { ...(user.user ?? {}), last_name: val },
        };
      });
    },
  },
  {
    label: "Email",
    value: user?.user?.email ?? "",
    warning: warning_text[warning?.email ?? ""] ?? warning?.email,
    containerClassName: "col-span-2",
    className: defaultClassName,
    onChange: (val) => {
      remove_warning("email");
      setUser((user) => {
        return {
          ...user,
          user: { ...(user.user ?? {}), email: val },
        };
      });
    },
  },
  {
    label: "Phone",
    type: "phone",
    value: user?.user?.phone ?? "",
    warning: warning_text[warning?.phone ?? ""] ?? warning?.phone,
    containerClassName: "col-span-2",
    className: defaultClassName + " pl-12 py-3",
    onChange: (val) => {
      remove_warning("phone");
      setUser((user) => {
        return {
          ...user,
          user: { ...(user.user ?? {}), phone: val },
        };
      });
    },
  },

  {
    label: "Resident Type",
    type: "dropdown",
    value: user?.user_type,
    warning: warning_text[warning?.user_type ?? ""] ?? warning?.user_type,
    containerClassName: "col-span-2",
    className: defaultClassName,
    options: orgUserTypes
      .map((value) => {
        return {
          label: <div className="truncate">{value.label}</div>,
          value: value.value,
          word: value.label,
        };
      })
      .filter((val) => val),
    onChange: (val) => {
      remove_warning("user_type");
      setUser({
        ...user,
        user_type: val,
      });
    },
  },
];
