import React, { useEffect, useState } from "react";
import { VerifyEmailIcon } from "../../common/AppIcons";

export default function VerifyEmail() {
  const [email, set_email] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("email")) {
      set_email(sessionStorage.getItem("email"));
    } else
      setTimeout(() => {
        set_email(sessionStorage.getItem("email"));
      }, 500);
  }, []);

  return (
    <div className="w-full min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-lg border border-gray-100 shadow bg-white rounded px-12 py-10 flex flex-col items-center">
        <VerifyEmailIcon className="h-20" />
        <span className="mt-4 text-lg font-semibold">
          Verify Your E-mail Address
        </span>
        <div className="mt-4 text-sm text-center">
          <span className="text-gray-600">We have sent an e-mail to </span>
          <span className="">{email}</span>
          <span className="text-gray-600">
            {" "}
            for verification. Follow the link provided to complete the signup
            process. Didn’t received the e-mail?
          </span>
        </div>

        <button
          style={{ color: "#2A32FD" }}
          className="mt-4 text-white text-sm px-8 py-2 font-semibold rounded hover:opacity-70 border-none outline-none"
        >
          Send again
        </button>
      </div>
    </div>
  );
}
