import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import {
  LeftArrowIcon,
  LoadingAnimated,
  LoadingCircleIcon,
} from "../../../common/AppIcons";
import SelectHeadings from "../../../common/Form/SelectHeadings";
import PlanInformation from "./PlanInformation";
import RequiredDocuments from "./RequiredDocuments";
import PlanStatus from "./PlanStatus";
import ProjectStatus from "./ProjectStatus";
import SubmitARPopup from "./SubmitARPopup";
import {
  getArchitecturalPlanDetails,
  patchArchitecturalPlanDetails,
} from "../../../../api/ApiV2";

const headings = [
  "Plan Information",
  "Required Documents",
  "Plan Status",
  "Project Status",
];

export default function ARDetails({ hoaId, closePage, id }) {
  const [data, set_data] = useState({});
  const [read_only, set_read_only] = useState(false);
  const [warning, set_warning] = useState({});
  const [loading, set_loading] = useState(false);
  const [page_loading, set_page_loading] = useState(true);
  const [page, set_page] = useState("Plan Information");
  const [submit_plan_popup, set_submit_plan_popup] = useState(false);
  const [fresh_data_flag, set_fresh_data_flag] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (!page_loading) ref?.current.scrollIntoView({ behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    get_AR_details();
  }, []);

  const get_AR_details = () => {
    getArchitecturalPlanDetails({ id: id, hoaId: hoaId })
      .then((res) => {
        set_data(res);
        console.log(res);
        set_fresh_data_flag(true);
        set_read_only(res.status !== "OPEN");
        set_loading(false);
        set_page_loading(false);
      })
      .catch((res) => {
        set_loading(false);
        set_page_loading(false);
        console.log(res);
      });
  };
  const go_back = () => {
    closePage();
  };

  const remove_warning = (name) => {
    let temp_warning = warning;
    delete temp_warning[name];
    set_warning(temp_warning);
  };

  const save_AR = () => {
    let temp_data = data;

    set_loading(true);
    patchArchitecturalPlanDetails({ hoaId: hoaId, id: id, data: temp_data })
      .then((res) => {
        set_data(res);
        console.log(res);
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(res.message ?? "Error posting data");
      });
  };

  const submit_AR = () => {
    set_submit_plan_popup(false);
    set_loading(true);
    patchArchitecturalPlanDetails({
      hoaId: hoaId,
      id: id,
      data: {
        ...data,
        submission_date: new Date().toISOString().split("T")[0],
      },
    })
      .then((res) => {
        console.log(res);
        set_loading(false);
        closePage();
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(res.message ?? "Error posting data");
      });
  };
  if (page_loading)
    return (
      <div className="min-w-full py-16 flex items-center justify-center">
        <LoadingCircleIcon className="h-12" />
      </div>
    );
  else
    return (
      <div className="overflow-auto bg-white md:py-8 md:pl-12 md:pr-12 rounded-md flex flex-row items-start">
        <div className="w-8 flex justify-center pt-1">
          <button
            className="flex items-center relative pt-px top-px"
            onClick={go_back}
          >
            <LeftArrowIcon className="h-5 w-5 text-gray-700 hover:text-gray-500 cursor-pointer" />
          </button>
        </div>
        <div ref={ref} className="flex flex-grow flex-col">
          <div className="flex flex-col md:flex-row gap-6 md:gap-3 justify-between min-w-full items-start md:items-center">
            <div className="flex flex-col">
              <span className="text-lg md:text-xl text-blue-600 font-bold">
                Architectural Review Details - {data.title}
              </span>

              <span className="mt-1 text-xs text-gray-500">
                Details for Proposed Architectural Alterations
              </span>
            </div>

            {!read_only && (
              <div className="flex flex-row gap-2">
                <button
                  onClick={() => {
                    save_AR();
                  }}
                  style={{ color: "#2A32FD", borderColor: "#2A32FD" }}
                  className={
                    "bg-white text-sm md:text-base md:font-semibold py-1 px-6 border-2 rounded shadow-sm mr-2 " +
                    (Object.keys(warning)?.length === 0
                      ? "hover:opacity-60"
                      : "opacity-50")
                  }
                >
                  {loading ? <LoadingAnimated className="h-5" /> : "Save Plan"}
                </button>
                <button
                  onClick={() => {
                    set_submit_plan_popup(true);
                  }}
                  style={{ backgroundColor: "#2A32FD" }}
                  className={
                    "text-white text-sm md:text-base md:font-semibold h-10 w-36 flex items-center justify-center rounded shadow-sm " +
                    (Object.keys(warning)?.length === 0
                      ? "hover:opacity-70"
                      : "opacity-50")
                  }
                >
                  {loading ? (
                    <LoadingAnimated className="h-5" />
                  ) : (
                    "Submit Plan"
                  )}
                </button>
              </div>
            )}
          </div>

          <div className="pr-10 mt-10 flex flex-col">
            <SelectHeadings
              headings={headings.map((val) => {
                return { label: val, value: val };
              })}
              selected={page}
              onChange={(val) => {
                set_page(val);
              }}
              base_heading_width={170}
              className="relative top-1"
            />
            <hr />
            {page === "Plan Information" ? (
              <PlanInformation
                hoaId={hoaId}
                read_only={read_only}
                fresh_data_flag={fresh_data_flag}
                set_fresh_data_flag={set_fresh_data_flag}
                className="max-w-3xl w-full mx-auto"
                next_page={() => set_page("Required Documents")}
                data={data}
                set_data={set_data}
                warning={warning}
                remove_warning={remove_warning}
                submit_plan={() => set_submit_plan_popup(true)}
              />
            ) : page === "Required Documents" ? (
              <RequiredDocuments
                hoaId={hoaId}
                read_only={read_only}
                id={id}
                className="max-w-3xl w-full mx-auto"
                next_page={() => set_page("Plan Status")}
                warning={warning}
                remove_warning={remove_warning}
                set_warning={set_warning}
              />
            ) : page === "Plan Status" ? (
              <PlanStatus
                read_only={read_only}
                data={data}
                set_data={set_data}
                warning={warning}
                remove_warning={remove_warning}
              />
            ) : page === "Project Status" ? (
              <ProjectStatus
                read_only={read_only}
                data={data}
                set_data={set_data}
                warning={warning}
                remove_warning={remove_warning}
              />
            ) : null}
          </div>
        </div>
        {submit_plan_popup && (
          <SubmitARPopup
            view={submit_plan_popup}
            set_view={() => set_submit_plan_popup(false)}
            on_submit={submit_AR}
          />
        )}
      </div>
    );
}
