import { toast } from "react-toastify";
import { patchHoa } from "../../../../api/ApiV2";
import {
  LoadingCircleIcon2,
  NoPetIcon,
  PetIcon,
} from "../../../common/AppIcons";
import { useState } from "react";

export default function PetPolicy({
  hoaData,
  setHoaData,
  previousPage,
  nextPage,
}) {
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    patchHoa({
      external_id: hoaData.hoaId,
      data: {
        details: {
          petPolicy: hoaData.petPolicy,
          allowedPets: hoaData.allowedPets,
          petPolicyDetails: hoaData.petPolicyDetails,
        },
      },
    })
      .then((res) => {
        setLoading(false);
        nextPage();
        console.log(res);
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error updating dues date"
        );
      });
  };

  if (loading)
    return (
      <div className="mt-10 max-w-4xl w-full mx-auto bg-white rounded p-12 flex flex-col items-center">
        <LoadingCircleIcon2 className="h-10" />
        <span className="text-lg mt-4">Updating Pet Policy...</span>
      </div>
    );
  else
    return (
      <div className="mt-10 max-w-4xl w-full mx-auto bg-white rounded p-12 flex flex-col items-center">
        <span className="text-lg">Pet Policy</span>
        <div className="mt-12 flex flex-row gap-6">
          {[
            {
              label: "Pets Allowed",
              value: "ALLOW",
              icon: PetIcon,
            },
            {
              label: "No Pets Allowed",
              value: "NOT_ALLOW",
              icon: NoPetIcon,
            },
          ].map((option, optionIndex) => (
            <div
              key={optionIndex}
              className="w-44 h-52 flex flex-col bg-gray-100 hover:bg-gray-200 p-5"
              onClick={() => {
                setHoaData((hoaData) => {
                  return { ...hoaData, petPolicy: option.value };
                });
              }}
            >
              <div className="flex justify-end">
                <input
                  type="radio"
                  checked={hoaData.petPolicy === option.value}
                  className="h-5 w-5 bg-gray-100 border border-gray-400 rounded-full"
                />
              </div>
              <div className="flex-grow w-full flex flex-col gap-5 items-center justify-center">
                <option.icon className="h-16" />
                <span className="text-sm">{option.label}</span>
              </div>
            </div>
          ))}
        </div>
        {hoaData.petPolicy === "ALLOW" && (
          <div className="mt-8 flex flex-row items-center justify-center gap-8">
            {[
              {
                label: "Large Dogs",
                value: "Large Dogs",
              },
              {
                label: "Small Dogs",
                value: "Small Dogs",
              },
              {
                label: "Cats",
                value: "Cats",
              },
            ].map((option, optionIndex) => (
              <div
                key={optionIndex}
                className="flex flex-row items-center gap-2"
              >
                <input
                  type="checkbox"
                  checked={hoaData.allowedPets?.includes(option.value)}
                  onChange={() => {
                    setHoaData((hoaData) => {
                      return {
                        ...hoaData,
                        allowedPets: hoaData.allowedPets?.includes(option.value)
                          ? hoaData.allowedPets.filter(
                              (val) => val !== option.value
                            )
                          : [...(hoaData.allowedPets ?? []), option.value],
                      };
                    });
                  }}
                />
                {option.label}
              </div>
            ))}
          </div>
        )}
        {hoaData.petPolicy === "ALLOW" && (
          <textarea
            className="mt-8 rounded bg-gray-100 w-full max-w-xl h-24 px-6 py-3"
            placeholder="Add any details, such as maximum dog weight etc."
            value={hoaData.petPolicyDetails}
            onChange={(e) =>
              setHoaData((hoaData) => {
                return {
                  ...hoaData,
                  petPolicyDetails: e.target.value,
                };
              })
            }
          />
        )}
        <div className="w-full mt-10 flex flex-row justify-center items-center gap-5">
          <button
            onClick={previousPage}
            className="bg-white text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 py-2 px-6 rounded"
          >
            Go Back
          </button>

          <button
            style={{
              backgroundColor: "#2A32FD",
              border: "2px solid #2A32FD",
            }}
            className="text-sm text-white hover:opacity-80 py-2 px-6 flex items-center justify-center rounded"
            onClick={onSubmit}
          >
            Continue
          </button>
        </div>
      </div>
    );
}
