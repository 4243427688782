import { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { useSetRecoilState } from "recoil";
import { toast } from "react-toastify";

import { getHoas } from "../../../../api/ApiV2";
import { LoadingAnimated, SearchIcon } from "../../../common/AppIcons";
import Paginator from "../../../common/Form/Paginator";
import HoaTemplate from "../components/hoaTemplate";
import "../index.css";
import { userAtom } from "../../../../store/UserAtom";

export default function OrgPage({ orgId, setRenderers }) {
  const [hoas, set_hoas] = useState();
  const [loading, set_loading] = useState(true);
  const [search_params, set_search_params] = useState("");
  const [total_pages, set_total_pages] = useState(1);
  const [page_no, set_page_no] = useState(1);
  const [search_params_debounce] = useDebounce(search_params, 700);
  const [search_params_focus, set_search_params_focus] = useState(false);
  const searchInputRef = useRef(null);
  const paginatorRef = useRef(null);
  const setUser = useSetRecoilState(userAtom);

  useEffect(() => {
    sessionStorage.removeItem("selectedUserProfile");
    setUser((prev) => ({
      ...prev,
      selectedUserProfile: null,
      selectedUserType: null,
    }));
  }, []);

  useEffect(() => {
    if (hoas) fetch_hoa_list();
  }, [page_no]);

  useEffect(() => {
    if (
      search_params?.split(" ")?.length > 0 &&
      search_params?.charAt(search_params.length - 1) === " "
    ) {
      fetch_hoa_list(1);
      set_page_no(1);
    } else if (search_params === "") {
      fetch_hoa_list();
    }
  }, [search_params]);

  useEffect(() => {
    if (
      search_params_focus &&
      search_params &&
      search_params_debounce === search_params
    ) {
      fetch_hoa_list(1);
      set_page_no(1);
    }
  }, [search_params, search_params_debounce]);

  const fetch_hoa_list = (offset) => {
    set_loading(true);
    getHoas({
      offset: ((offset ?? page_no) - 1) * 10,
      search: search_params,
      org: orgId,
    })
      .then((res) => {
        console.log(res);
        set_hoas(res?.results ?? []);
        set_total_pages(res.count ?? 1);
        setTimeout(() => {
          setRenderers((rendereres) => !rendereres);
        }, 100);
        set_loading(false);
      })
      .catch((res) => {
        toast.error(res.message ?? "Error fetching properties");
        console.log(res);
        set_loading(false);
      });
  };

  return (
    <div className="w-full pb-5">
      {!loading && (
        <div className="w-full flex justify-end">
          <div className="flex flex-row items-center gap-2 px-3 py-1 rounded bg-white border border-gray-400 w-full">
            <div
              onClick={() => {
                searchInputRef?.current.focus();
              }}
            >
              <SearchIcon className="h-6 w-6 text-gray-500" />
            </div>

            <input
              ref={searchInputRef}
              className="focus:outline-none p-2 text-gray-700 flex flex-grow"
              placeholder="Search Properties..."
              value={search_params}
              onChange={(e) => set_search_params(e.target.value)}
              onFocus={() => set_search_params_focus(true)}
              onBlur={() => set_search_params_focus(false)}
            />
          </div>
        </div>
      )}

      <div className="w-full flex justify-center">
        {loading ? (
          <div className="w-full flex justify-center pt-32 pb-20">
            <LoadingAnimated className="h-12" />
          </div>
        ) : (
          <div className="mt-7 grid grid-cols-1 gap-5 sm:grid-cols-2 w-full">
            {hoas?.length > 0 ? (
              hoas?.map((hoa, hoa_index) => (
                <HoaTemplate key={hoa_index} hoa={hoa} />
              ))
            ) : (
              <span className="text-gray-500 sm:col-span-2 font-semibold text-lg m-auto pt-16 w-full flex justify-center">
                No Properties found
              </span>
            )}
          </div>
        )}
      </div>
      {(!loading || hoas) && (
        <div ref={paginatorRef} className="mt-8 w-full flex justify-center">
          <Paginator
            total_pages={Math.ceil(total_pages / 10)}
            value={page_no}
            onChange={(val) => {
              set_page_no(val);
              setTimeout(() => {
                paginatorRef?.current?.scrollIntoView();
              }, 0);
            }}
          />
        </div>
      )}
    </div>
  );
}
