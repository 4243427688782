import { Link } from "raviger";
import React from "react";
import { ConfirmEmailIcon } from "../../common/AppIcons";

export default function VerifiedEmail() {
  return (
    <div className="w-full min-h-screen flex items-center justify-center bg-gray-100">
      <div className="border border-gray-100 shadow bg-white rounded px-12 py-10 flex flex-col items-center">
        <ConfirmEmailIcon className="h-20" />
        <span className="mt-4 text-lg font-semibold">
          E-mail Confirmed Succesfully
        </span>
        <div className="mt-4 text-sm flex flex-row items-center flex-wrap gap-1">
          <span className="text-gray-600">Your email address </span>
        </div>
        <div className="text-sm flex flex-row items-center flex-wrap gap-1">
          <span className="text-gray-600">has been Successfully confirmed</span>
        </div>
        <Link
          href="/login"
          style={{ backgroundColor: "#2A32FD" }}
          className="mt-4 text-white text-sm px-8 py-2 font-semibold rounded hover:opacity-70"
        >
          Login to Doorz
        </Link>
      </div>
    </div>
  );
}
