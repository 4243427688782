import { useState } from "react";

import Comment from "./Comment";
import { LoadingAnimated, SentIcon } from "../../../../common/AppIcons";
import { ProfilePic } from "../../../../community/pages/boardMeeting/constants";
import { postArchitecturalComment } from "../../../../../api/ApiV2";

export default function CommentSection({
  show_comment_box,
  hoaId,
  id,
  get_discussion_comments,
  comments,
  comment,
  member,
  read_only,
  show_reply_input,
}) {
  const [comment_text, set_comment_text] = useState("");
  const [loading, set_loading] = useState(false);
  const [reply, set_reply] = useState("");

  const sent_comment = ({ parentId }) => {
    if (comment_text !== "" || reply !== "") {
      set_loading(true);
      postArchitecturalComment({
        hoaId: hoaId,
        id: id,
        data: {
          parent: parentId,
          description: parentId ? reply : comment_text,
          comment_type: parentId ? "REPLY" : "COMMENT",
        },
      })
        .then((_) => {
          get_discussion_comments();
          parentId ? set_reply("") : set_comment_text("");

          set_loading(false);
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
        });
    }
  };

  return (
    <div className="flex flex-col min-w-full">
      {show_comment_box && !read_only && (
        <div className="p-2 flex flex-col gap-2 rounded">
          <div className="flex flex-row items-center gap-3">
            <ProfilePic
              member={member}
              className="text-lg h-12 w-12 flex items-center justify-center"
            />
            <input
              value={comment_text}
              onKeyDown={(e) => {
                e.key === "Enter" && sent_comment({});
              }}
              onChange={(e) => set_comment_text(e.target.value)}
              placeholder="Start a conversation..."
              className="flex flex-grow px-4 py-3 text-gray-800 rounded bg-gray-100 focus:bg-gray-200 focus:outline-none"
            />
          </div>
          <div className="flex justify-end mt-1">
            <button
              onClick={sent_comment}
              style={{ backgroundColor: "#2A32FD" }}
              className={
                "focus:outline-none rounded h-7 w-28 text-sm hover:opacity-80 text-white font-semibold"
              }
            >
              {loading ? (
                <LoadingAnimated className="w-5 h-5 ml-1" />
              ) : (
                "Post Comment"
              )}
            </button>
          </div>
        </div>
      )}
      <div className={"flex flex-col pt-6 w-full"}>
        {comments
          ?.filter((val) => (comment ? comment.id === val.parent : !val.parent))
          .map((comment, comment_index) => (
            <Comment
              key={comment_index}
              hoaId={hoaId}
              id={id}
              comment={comment}
              comments={comments}
              get_discussion_comments={get_discussion_comments}
              member={member}
              read_only={read_only}
            />
          ))}
      </div>
      {show_reply_input && !read_only && (
        <div className="flex flex-row items-center mt-4 h-12">
          <ProfilePic
            member={member}
            className="shadow-none border h-10 w-10"
          />
          <input
            value={reply}
            onChange={(e) => set_reply(e.target.value)}
            onKeyDown={(e) =>
              e.key === "Enter" && sent_comment({ parentId: comment.id })
            }
            placeholder="Reply..."
            className="ml-3 flex flex-grow px-4 py-3 text-gray-800 rounded bg-gray-100 focus:bg-gray-200 focus:outline-none"
          />
          <div
            onClick={() => sent_comment({ parentId: comment.id })}
            className={
              "flex items-center justify-center cursor-pointer px-3 h-full bg-gray-100 text-blue-600 hover:text-blue-700 hover:bg-gray-200"
            }
          >
            {loading ? (
              <LoadingAnimated className="w-5 h-5 ml-1" />
            ) : (
              <SentIcon className="w-5 h-5 ml-1" />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
