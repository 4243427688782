// Generated by ReScript, PLEASE EDIT WITH CARE


function containerClass(show) {
  if (show) {
    return "z-40";
  } else {
    return "";
  }
}

export {
  containerClass ,

}
/* No side effect */
