import React from "react";
import {
  BooksIcon,
  PeopleIcon2,
  TickInCircleIcon,
  WrenchIcon2,
} from "../../common/AppIcons";

export default function Features({ featuresComponent, className }) {
  return (
    <div
      ref={featuresComponent}
      className={"flex flex-col items-center " + className}
    >
      <div
        className="px-4 py-1.5 rounded"
        style={{
          backgroundColor: "rgba(42, 50, 253, 0.07)",
          color: "#2A32FD",
          border: "1px solid #d8d8e7",
        }}
      >
        Features
      </div>

      <span
        style={{ fontSize: "26px", fontWeight: "800" }}
        className="mt-5 mx-auto max-w-lg text-center"
      >
        Simplify your Property management with our all-in-one platform
      </span>
      <div className="w-full mt-10 flex flex-row justify-between gap-6 flex-wrap">
        {features?.map((val, index) => (
          <Feature key={index} {...val} />
        ))}
      </div>
    </div>
  );
}

const features = [
  {
    icon: <BooksIcon className="h-7" style={{ color: "#06B862" }} />,
    icon_container_style: { backgroundColor: "rgba(6, 184, 98, 0.11)" },
    title: "Accounting",
    content:
      "Manage all Accounting for your Property, simple and straight forward",
  },
  {
    icon: <WrenchIcon2 className="h-7" style={{ color: "#E87500" }} />,
    icon_container_style: { backgroundColor: "rgba(232, 117, 0, 0.2)" },
    title: "Maintenance",
    content: "Manage and track maintenance service requests, all in one place.",
  },
  {
    icon: <TickInCircleIcon className="h-7" style={{ color: "#2A32FD" }} />,
    icon_container_style: { backgroundColor: "rgba(42, 50, 253, 0.13)" },
    title: "Compliance",
    content: "Keep track of compliances and all documents in one place",
  },
  {
    icon: <PeopleIcon2 className="h-7" style={{ color: "#00C0C5" }} />,
    icon_container_style: { backgroundColor: "rgba(0, 192, 197, 0.2)" },
    title: "Community",
    content: "Check up on what's new. Explore other tenants at your apartment",
  },
];

const Feature = ({ icon, icon_container_style, title, content }) => (
  <div
    style={{ backgroundColor: "#F5F5F5" }}
    className="mx-auto px-5 py-7 w-60 h-60 flex flex-col items-start gap-3 rounded shadow-none hover:shadow-md"
  >
    <div className="rounded-md p-3" style={icon_container_style}>
      {icon}
    </div>
    <span className="mt-2 font-semibold text-xl">{title}</span>
    <span className="text-sm font-light">{content}</span>
  </div>
);
