import React from "react";

export default function SelectCard({
  selected,
  Icon,
  label,
  onClick,
  className,
  key,
}) {
  return (
    <div
      onClick={onClick}
      className={
        "p-3 rounded-sm cursor-pointer bg-gray-200 hover:opacity-80 w-40 md:w-44 h-44 md:h-48 flex flex-col items-center gap-4 text-gray-800 " +
        className
      }
      key={key}
    >
      <div className="flex justify-end w-full">
        <div
          className={
            "h-3 w-3 rounded-full " +
            (selected ? "bg-indigo-500 shadow" : "border border-gray-500")
          }
        />
      </div>
      <div className="flex flex-col items-center justify-around h-full w-full">
        {Icon && <Icon className="h-12" />}
        <span className="font-semibold">{label}</span>
      </div>
    </div>
  );
}
