import React from "react";

export default function FillerImage({ className = "" }) {
  return (
    <div
      style={{ backgroundImage: "url(/doorz_registration.png)" }}
      className={"h-full " + className}
    >
      <div className="w-full h-full flex items-center justify-center px-20">
        <div
          style={{ backdropFilter: "blur(8px)" }}
          className="w-full px-8 py-10 pb-16 flex flex-col bg-gray-900 bg-opacity-10"
        >
          <span className="text-white font-bold text-3xl">Cloud based</span>
          <span className="text-white font-bold text-3xl">SaaS for HOAs</span>
          <span className="mt-3 text-white">
            Easily handle all aspects of HOA
          </span>
          <span className="text-white">
            management with our powerful platform
          </span>
        </div>
      </div>
    </div>
  );
}
