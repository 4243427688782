import React from "react";
import { convertTimezone } from "../../../../../utils/convertTimezone";
import { date_format } from "../../../../../utils/dateFormat";
import {
  ClockIcon2,
  WarningIcon,
  WorkerIcon,
} from "../../../../common/AppIcons";
import TaskTicker from "../../../../common/Form/TaskTicker";
import ImgThumbnail from "../../../../common/ImgThumbnail";
import { imageArray } from "../utility/imageArray";

const base_tasks = [
  {
    label: "Assigned",
    value: "ASSIGNED",
  },
  {
    label: "Accepted",
    value: "ACCEPTED",
  },
  {
    label: "Enroute",
    value: "ENROUTE",
  },
  {
    label: "Started",
    value: "STARTED",
  },
  {
    label: "Finished",
    value: "FINISHED",
  },
];

const detail_icon_className = "w-4 text-yellow-500";

export default function SRAppointment({
  appointment,
  sr_data,
  open_pdf,
  set_view_files,
}) {
  return (
    <div className="w-full py-6 flex flex-col gap-5">
      <TaskTicker tasks={base_tasks} last_finished={appointment.status} />
      <div className="mt-3 flex flex-row gap-8 items-center px-2 text-gray-700 text-sm uppercase">
        <ClockIcon2 className={detail_icon_className} />
        <span
          className={
            "px-2 py-1 rounded " +
            (open_pdf ? "relative bottom-2" : "bg-zinc-100")
          }
        >
          {appointment.timelineStart
            ? date_format(
                convertTimezone(appointment.timelineStart, sr_data.timezone)
              ) +
              " " +
              convertTimezone(
                appointment.timelineStart,
                sr_data.timezone
              ).toLocaleTimeString()
            : ""}
        </span>
        <span className={open_pdf ? "relative bottom-2" : ""}>-</span>
        <span
          className={
            "px-2 py-1 rounded " +
            (open_pdf ? "relative bottom-2" : "bg-zinc-100")
          }
        >
          {appointment.timelineEnd
            ? date_format(
                convertTimezone(appointment.timelineEnd, sr_data.timezone)
              ) +
              " " +
              convertTimezone(
                appointment.timelineEnd,
                sr_data.timezone
              ).toLocaleTimeString()
            : ""}
        </span>
      </div>
      <div className="flex flex-row gap-8 items-center px-2 text-sm text-gray-700">
        <WorkerIcon className={detail_icon_className} />
        <span className={open_pdf ? "relative bottom-2" : ""}>
          {appointment.associateFirstName ?? ""}{" "}
          {appointment.associateLastName ?? ""}
        </span>
      </div>
      <div className="w-full mb-5 flex flex-row flex-wrap gap-1">
        {[
          {
            label: "Before",
            pics: [
              ...(appointment?.beforeUrls ?? []),
              ...(sr_data?.mediaUrls ?? []),
            ],
          },
          {
            label: "After",
            pics: appointment.afterUrls,
          },
        ].map((pic, pic_index) => (
          <div
            key={pic_index}
            className="bg-zinc-100 rounded p-5 flex flex-col gap-4"
          >
            <span>{pic.label}</span>
            {pic.pics && pic.pics.length > 0 ? (
              <div
                className={
                  "flex flex-wrap gap-1 items-start justify-start w-full m-auto " +
                  (open_pdf ? "flex-col" : "")
                }
              >
                {[...pic.pics].map((url, url_index) => (
                  <ImgThumbnail
                    key={url_index}
                    url={url}
                    className={open_pdf ? "max-h-28" : "max-h-20"}
                    onClick={() =>
                      set_view_files({
                        show: true,
                        index:
                          imageArray(sr_data)
                            .map((val, index) => {
                              if (val === url) return index;
                            })
                            .filter((val) => val)[0] ?? 0,
                      })
                    }
                  />
                ))}
              </div>
            ) : (
              <div className="w-52 h-40 m-auto rounded flex flex-col gap-4 items-center justify-center text-red-600">
                <WarningIcon className="w-4 h-4" />
                <span>Yet to be updated</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
