import { Link, navigate } from "raviger";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";

import { ClockIcon4, OpenEyeIcon2 } from "../../../../../../common/AppIcons";
import { defaultPic } from "../../../../../data/defaultPic";
import AmenityAvailabilityBadge from "../../../../../utility/AmenityAvailabilityBadge";
import { minutes_to_string } from "../../../../amenities/data/hours_array";
import { getAmenityPhoto } from "../../../../../../../api/ApiV2";
import { userAtom } from "../../../../../../../store/UserAtom";
import { adminTypes } from "../../../../../../../common/data/adminTypes";

export default function AmenityCard({
  amenity,
  className,
  hoaId,
  set_reservation_history,
}) {
  const [user] = useRecoilState(userAtom);
  const [image, setImage] = useState();
  const [hoverHistory, setHoverHistory] = useState(false);

  useEffect(() => {
    getAmenityPhoto({ hoaId, id: amenity?.id })
      .then((res) => {
        console.log(res);
        setImage(Object.values(res.photos)?.[0]?.url);
      })
      .catch((res) => {
        console.log(res);
        toast.error("Amenity picture fetching failed");
      });
  }, [amenity?.id]);

  return (
    <div
      style={{ backgroundColor: "#F8FAFC" }}
      className={"rounded flex flex-col items-start " + className}
    >
      <div className="w-full relative">
        <img src={image ?? defaultPic} className="w-full h-32 rounded-t" />
        {set_reservation_history && (
          <div className="absolute z-20 inset-0 p-1 w-full flex flex-row justify-end items-start h-5">
            <button
              onClick={() => set_reservation_history(amenity)}
              onMouseEnter={() => setHoverHistory(true)}
              onMouseLeave={() => setHoverHistory(false)}
              style={{ color: "#2A32FD" }}
              className={
                "bg-white hover:opacity-60 flex flex-row items-center gap-1 text-sm p-1 rounded"
              }
            >
              <ClockIcon4
                style={{ height: "20px", width: "20px" }}
                className={hoverHistory ? "" : "-mr-1"}
              />
              <span
                style={
                  hoverHistory
                    ? {
                        maxWidth: "10rem",
                        transition: "max-width 0.3s ease-out",
                      }
                    : {
                        maxWidth: "0rem",
                        transition: "max-width 0.3s ease-in",
                      }
                }
                className={"text-sm overflow-hidden"}
              >
                History
              </span>
            </button>
          </div>
        )}
      </div>
      <div className="w-full h-full flex flex-col items-start py-3 px-3">
        <span
          style={{ color: "#18181B", fontSize: "16px" }}
          className="font-semibold"
        >
          {amenity.name ?? ""}
        </span>
        <AmenityAvailabilityBadge
          className="mt-1"
          availability={amenity.amenity_type}
        />
        <div className="flex flex-col items-start pl-1.5">
          {minutes_to_string(amenity.per_day_limit) && (
            <span
              style={{ color: "#71717A", fontSize: "14px" }}
              className="mt-1.5 text-sm"
            >
              Per day limit: {minutes_to_string(amenity.per_day_limit)}
            </span>
          )}
        </div>
        <div className="w-full h-full flex flex-col justify-end">
          {adminTypes.includes(user.selectedUserType) ? (
            <div className="w-full flex flex-row justify-end">
              <Link
                href={`/hoa/${hoaId}/community/reservations/create/${amenity?.id}`}
                style={{
                  backgroundColor: "#2A32FD",
                  border: "2px solid #2A32FD",
                }}
                className="mt-3 rounded px-4 py-1 text-white hover:opacity-70 text-sm"
              >
                Reserve
              </Link>
            </div>
          ) : (
            <div
              className="mt-3 flex flex-row items-center gap-1 cursor-pointer hover:opacity-70 duration-200 text-sm"
              style={{ fontSize: "14px", color: "#2A32FD" }}
              onClick={() => {
                sessionStorage.setItem("view_bookings", amenity?.id);
                setTimeout(() => {
                  navigate("/hoa/" + hoaId + "/community/amenities/");
                }, 200);
              }}
            >
              <OpenEyeIcon2 className="" style={{ width: "15px" }} /> View
              Bookings
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
