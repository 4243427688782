import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "raviger";
import { useDropzone } from "react-dropzone";

import {
  DeleteIcon2,
  JpgPageIcon,
  LoadingCircleIcon,
  PaperClipIcon,
  PdfPageIcon,
  PngPageIcon,
  UploadToCloudIcon,
} from "../../../common/AppIcons";
import { postDocument, uploadCreateRevision } from "../../../../api/ApiV2";

export default function FileInput({
  hoaId,
  read_only,
  id,
  documents,
  warning,
  remove_warning,
  set_warning,
  label,
  value,
  getARDocuments,
}) {
  const [local_files, set_local_files] = useState([]);
  const ref = useRef(null);

  const createDocument = (file) => {
    postDocument(hoaId, {
      document_type: "ARCHITECTURAL_REVIEW",
      item_type: value,
      status: "CREATED",
      architecture_plan: id,
      name: file.name,
    })
      .then((res) => {
        console.log(res);
        uploadFiles(res.id, file);
      })
      .catch((res) => {
        set_local_files((local_files) =>
          local_files.filter((val) => val !== file)
        );
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error uploading file"
        );
      });
  };

  const uploadFiles = (documentId, file) => {
    uploadCreateRevision({
      hoaId: hoaId,
      documentId: documentId,
      data: {
        document_type: "ARCHITECTURAL_REVIEW",
        status: "CREATED",
        document: file,
        item_type: value,
      },
    })
      .then((_) => {
        if (local_files?.length === 1 || local_files?.length === 0) {
          getARDocuments();
        }
        set_local_files((local_files) =>
          local_files.filter((val) => val !== file)
        );
      })
      .catch((res) => {
        set_local_files((local_files) =>
          local_files.filter((val) => val !== file)
        );
        toast.error(res?.detail ?? res?.message ?? "Error uploading file");
      });
  };

  const onDrop = (acceptedFiles) => {
    if (read_only) return;
    remove_warning(value);
    let temp_files = [];
    console.log(acceptedFiles);
    const accepted_file_types = ["jpeg", "pdf", "png"];

    acceptedFiles.map((val) => {
      if (accepted_file_types.includes(val.type.split("/")[1])) {
        if (val.size / (1024 * 1024) < 5) {
          temp_files = [...temp_files, val];
        } else set_warning({ ...warning, [value]: "Maximum file size 5MB" });
      } else
        set_warning({
          ...warning,
          [value]: "Please use file formats PNG, JPG, PDF",
        });
    });
    setTimeout(() => {
      remove_warning(value);
    }, 3000);

    set_local_files((local_files) => [...local_files, ...temp_files]);
    temp_files.map((val) => createDocument(val));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="flex flex-col gap-2 mt-6 md:mt-16">
      <div className="flex flex-row justify-between items-end gap-5">
        <span className="font-semibold text-lg text-gray-800">{label}</span>
        <div className="flex flex-row items-center gap-4">
          <span className="text-xs text-red-600">{warning[value]}</span>
          {!read_only && (documents?.length > 0 || local_files?.length > 0) && (
            <button
              className={
                "mt-2 relative flex flex-row gap-3 items-center px-4 py-2 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 focus:outline-none hover:border-indigo-400 hover:text-indigo-400"
              }
              onClick={() => {
                ref?.current.click();
              }}
            >
              <PaperClipIcon className="h-4" />
              Add More Document
            </button>
          )}
        </div>
      </div>
      <div
        {...getRootProps()}
        ref={ref}
        className={
          "rounded cursor-pointer flex flex-col gap-3 py-2 " +
          (isDragActive ? "opacity-50 " : "") +
          (documents?.length > 0 || local_files?.length > 0
            ? ""
            : "bg-gray-100 hover:bg-gray-200 justify-center items-center py-8 md:py-16")
        }
      >
        <input {...getInputProps()} />
        {documents?.length > 0 || local_files?.length > 0 ? (
          <div className="flex flex-col w-full gap-3 p-1 flex-wrap">
            {[...local_files, ...(documents ?? [])]?.map((file, index) => (
              <div
                key={index}
                className="rounded flex flex-row gap-6 items-center bg-gray-200 text-sm opacity-80 px-6 py-2 "
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {file.name?.split(".")?.[file.name?.split(".").length - 1] && (
                  <span>
                    {file.name?.split(".")?.[
                      file.name?.split(".").length - 1
                    ] === "pdf" ? (
                      <PdfPageIcon className="h-5 text-blue-500" />
                    ) : file.name?.split(".")?.[
                        file.name?.split(".").length - 1
                      ] === "png" ? (
                      <PngPageIcon className="h-5 text-green-500" />
                    ) : file.name?.split(".")?.[
                        file.name?.split(".").length - 1
                      ] === "jpeg" ||
                      file.name?.split(".")?.[
                        file.name?.split(".").length - 1
                      ] === "jpg" ? (
                      <JpgPageIcon className="h-5 text-red-500" />
                    ) : (
                      <span className="text-xs font-semibold text-white bg-red-800 px-1.5 py-1 rounded">
                        {
                          file.name?.split(".")?.[
                            file.name?.split(".").length - 1
                          ]
                        }
                      </span>
                    )}
                  </span>
                )}
                <Link
                  target="_blank"
                  href={
                    file.id
                      ? "/hoa/" + hoaId + "/view_document/" + file.id
                      : URL.createObjectURL(file)
                  }
                  className="flex flex-col flex-grow justify-center gap-px"
                >
                  <span className="font-bold text-gray-800 truncate">
                    {(file.name || file.file_name || file.document_object)
                      .length > 60
                      ? (
                          file.name ||
                          file.file_name ||
                          file.document_object
                        ).substring(0, 60) + "..."
                      : file.name || file.file_name || file.document_object}
                  </span>
                  <span className="text-xs text-gray-500 flex flex-row gap-1 items-center">
                    {file.size && (
                      <div className="flex flex-row items-center gap-1">
                        {(file.size / (1024 * 1024)).toFixed(1)}MB
                      </div>
                    )}
                  </span>
                </Link>
                {local_files.length}
                {local_files.includes(file) && (
                  <LoadingCircleIcon className="h-6" />
                )}
              </div>
            ))}
          </div>
        ) : read_only ? (
          <div className="w-full h-full flex items-center justify-center text-sm text-gray-400">
            No Documents Found
          </div>
        ) : (
          <div
            className={
              "flex flex-col items-center justify-center gap-2 px-3 md:px-10"
            }
          >
            <UploadToCloudIcon className="h-8 text-indigo-600" />

            <div className="mt-2 flex flex-row gap-1 font-semibold">
              <span className="text-indigo-600">Click to upload</span>
              <span className="text-gray-600">or Drag and Drop</span>
            </div>
            <span className="text-gray-500 italic">
              PNG, JPG, PDF (max ~5MB)
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
