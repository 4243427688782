import { useEffect, useState } from "react";
import {
  getAccountsSummary,
  getBills,
  verifyAccountsReceivable,
} from "../../../../api/ApiV2";
import SelectHeadings from "../../../common/Form/SelectHeadings";
import Loading from "../../../common/Loading";
import Modal from "../../../common/Modal";
import SwipeableScreens from "../../../common/SwipableScreens";
import AddRevenue from "../../components/addRevenue";
import TopSection from "./components/TopSection";
import UnpaidTable from "./components/UnpaidTable";
import PaidTable from "./components/PaidTable";
import { LeftArrowIcon, LoadingCircleIcon } from "../../../common/AppIcons";
import Popup from "../../../common/Popup";
import RevanueDetailPopup from "../../components/addRevenue/RevenueDetailPopup";

export default function Revenue({
  hoaId,
  source = null,
  previousPage = null,
  nextPage = null,
  set_height_rerender = null,
}) {
  const [page, set_page] = useState("Unpaid");
  const [popup, set_popup] = useState({
    show: false,
    setting: "NONE",
    data: {},
  });
  const [popup_loading, set_popup_loading] = useState(false);
  const [
    loading_verify_accounts_receivable,
    set_loading_verify_accounts_receivable,
  ] = useState(false);
  const [warning, set_warning] = useState();

  const [unpaid_transactions, set_unpaid_transactions] = useState([]);
  const [unpaid_page, set_unpaid_page] = useState({ current: 0, total: 0 });
  const [unpaid_loading, set_unpaid_loading] = useState(true);

  const [paid_transactions, set_paid_transactions] = useState([]);
  const [paid_page, set_paid_page] = useState({ current: 1, total: 0 });
  const [paid_loading, set_paid_loading] = useState(true);
  const [receivableTotal, setReceivableTotal] = useState(0);

  useEffect(() => {
    fetchTotal();
  }, []);

  const fetchTotal = () => {
    getAccountsSummary({ hoaId })
      .then((res) => {
        console.log(res);
        setReceivableTotal(res.accounts_receivable?.current_amount ?? 0);
      })
      .catch((_) => {});
  };

  useEffect(() => {
    get_unpaid_bill();
  }, [unpaid_page.current]);

  const get_unpaid_bill = () => {
    getBills(
      source === "HOA_USER"
        ? {
            hoaId: hoaId,
            page: unpaid_page.current,
            invoice: "True",
            paid: "False",
            user: "True",
          }
        : {
            hoaId: hoaId,
            page: unpaid_page.current,
            invoice: "True",
            paid: "False",
          }
    )
      .then((res) => {
        set_unpaid_page({ ...unpaid_page, total: res.count });
        console.log("res", res);
        set_unpaid_transactions(res.results);
        set_unpaid_loading(false);
        set_loading_verify_accounts_receivable(false);
        set_height_rerender &&
          set_height_rerender((height_rerender) => !height_rerender);
      })
      .catch((res) => {
        set_unpaid_loading(false);
        set_loading_verify_accounts_receivable(false);
        console.log(res);
      });
  };

  useEffect(() => {
    get_paid_bill();
  }, [paid_page.current]);

  const get_paid_bill = () => {
    getBills(
      source === "HOA_USER"
        ? {
            hoaId: hoaId,
            page: paid_page.current,
            invoice: "True",
            paid: "True",
            user: "True",
          }
        : {
            hoaId: hoaId,
            page: paid_page.current,
            invoice: "True",
            paid: "True",
          }
    )
      .then((res) => {
        set_paid_page({ ...paid_page, total: res.count });
        console.log("res", res);
        set_paid_transactions(res.results);
        set_paid_loading(false);
        set_height_rerender &&
          set_height_rerender((height_rerender) => !height_rerender);
      })
      .catch((res) => {
        set_paid_loading(false);
        console.log(res);
      });
  };

  const invoicePopup = (transaction) => {
    console.log("transaction", transaction);
    set_popup({
      ...popup,
      show: true,
      setting: "INVOICE_POPUP",
      transaction: transaction,
    });
  };

  // if source is hoa creation

  useEffect(() => {
    source === "HOA_CREATION" && verify_accounts_receivable();
  }, []);

  const verify_accounts_receivable = (nextScreen) => {
    if (!loading_verify_accounts_receivable) {
      set_loading_verify_accounts_receivable(true);
      verifyAccountsReceivable({ hoaId: hoaId })
        .then((res) => {
          set_loading_verify_accounts_receivable(false);
          if (Number(res.Balance) === 0.0) {
            set_warning();
            nextScreen && nextPage();
          } else
            set_warning(
              "Please enter existing Revenue for the amount " +
                res.Balance +
                " in order to match the Accounts Receivable entry."
            );
        })
        .catch((res) => {
          console.log(res);
          set_loading_verify_accounts_receivable(false);
        });
    }
  };

  return (
    <div>
      <TopSection {...{ hoaId, popup, set_popup }} total={receivableTotal} />
      <div className="mt-6 border-b border-gray-300 flex flex-row items-center">
        {source === "HOA_CREATION" && (
          <div className="mr-2">
            <div
              onClick={previousPage}
              className="rounded cursor-pointer hover:text-gray-500 px-1 py-2 border-2 border-gray-400 hover:border-gray-300 relative top-px"
            >
              <LeftArrowIcon className="h-5 w-5" />
            </div>
          </div>
        )}
        <SelectHeadings
          headings={[
            "Unpaid",
            ...(source === "HOA_CREATION" ? [] : ["Paid"]),
          ].map((val) => {
            return { label: val, value: val };
          })}
          selected={page}
          onChange={(val) => {
            set_page(val);
          }}
          base_heading_width={90}
          selected_className="text-gray-900"
          className="relative top-1"
          label_className="md:text-xl font-semibold"
        />
      </div>

      <SwipeableScreens
        rerenderers={[
          unpaid_transactions,
          unpaid_loading,
          unpaid_page,
          paid_transactions,
          paid_loading,
          paid_page,
        ]}
        currentSlide={page === "Unpaid" ? 1 : 2}
      >
        <UnpaidTable
          transactions={unpaid_transactions}
          loading={unpaid_loading}
          page={unpaid_page}
          set_page={set_unpaid_page}
          onClick_transaction={invoicePopup}
        />
        <PaidTable
          transactions={paid_transactions}
          loading={paid_loading}
          page={paid_page}
          set_page={set_paid_page}
          onClick_transaction={invoicePopup}
        />
      </SwipeableScreens>

      {source === "HOA_CREATION" &&
        (page === "Unpaid" ? (
          <div className="w-full mt-8 mb-4 flex flex-row-reverse items-end">
            <button
              onClick={() => {
                if (!warning) verify_accounts_receivable(true);
              }}
              style={{
                backgroundColor: "#2A32FD",
                border: "2px solid #2A32FD",
              }}
              className={
                "w-24 h-10 flex items-center justify-center rounded text-white shadow " +
                (warning ? "opacity-70" : "hover:hover:opacity-70")
              }
            >
              {loading_verify_accounts_receivable ? (
                <Loading className="w-4 h-4" />
              ) : (
                "Proceed"
              )}
            </button>
            <span className="text-red-500 text-xs mx-4">{warning}</span>
          </div>
        ) : (
          <div className="w-full my-8 flex flex-row-reverse">
            <button
              onClick={nextPage}
              style={{
                backgroundColor: "#2A32FD",
                border: "2px solid #2A32FD",
              }}
              className={
                "rounded text-white font-medium px-7 py-1 hover:opacity-70"
              }
            >
              Proceed
            </button>
          </div>
        ))}

      <Popup
        show={popup_loading}
        setShow={() => {
          set_popup_loading(false);
        }}
        className="w-full flex items-center justify-center"
      >
        <LoadingCircleIcon className="text-blue-500 h-7 m-3" />
      </Popup>
      <Modal
        wide
        show={popup.setting !== "NONE"}
        setShow={() => {
          if (popup.setting === "CREATE_INVOICE") {
            get_unpaid_bill();
          } else {
            get_paid_bill();
          }
          set_popup({ ...popup, setting: "NONE" });
        }}
      >
        {popup.setting === "CREATE_INVOICE" ? (
          <AddRevenue
            hoaId={hoaId}
            source={source}
            setShow={() => {
              verify_accounts_receivable();
              set_popup({ ...popup, setting: "NONE" });
              get_unpaid_bill();
              fetchTotal();
            }}
          />
        ) : (
          <RevanueDetailPopup
            hoaId={hoaId}
            source={source}
            onSuccess={() => {
              set_popup({ ...popup, setting: "NONE" });
              get_paid_bill();
            }}
            billId={popup.transaction?.id}
            remove_pay={true}
          />
        )}
      </Modal>
    </div>
  );
}
