import React from "react";

export default function BottomButtons({ setShow, readOnly, onSubmit }) {
  return (
    <div className="w-full flex flex-row items-center justify-end gap-6">
      <div className="flex flex-row items-center gap-4">
        {!readOnly && (
          <button
            className="focus:outline-none font-medium border-2 border-gray-400 text-gray-600 px-7 py-1 rounded hover:opacity-60 duration-200"
            onClick={() => {
              setShow();
            }}
          >
            Cancel
          </button>
        )}
        <button
          style={{ backgroundColor: "#2A32FD", border: "2px solid #2A32FD" }}
          className={
            "rounded text-white font-medium px-7 py-1 hover:opacity-70"
          }
          onClick={() => {
            onSubmit();
          }}
        >
          {readOnly ? "Close" : "Create check"}
        </button>
      </div>
    </div>
  );
}
