import React, { useState, useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { LeftArrowIcon, SearchIcon, SearchIcon2 } from "../AppIcons";

// depreciated. don't use

export default function Dropdown({
  wrapperClassName,
  selectClassName,
  value,
  onChange,
  options,
  boxClassName,
  readOnly,
  placeholder,
  noSearchbar,
  parentRef,
  ...props
}) {
  const [keyWord, setKeyWord] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [showSubCategary, setShowSubCategory] = useState({});
  const [dropdown_position, set_dropdown_position] = useState("bottom");
  // bottom,top
  const ref = useRef(null);
  const ele_ref = useRef(null);
  const optionsRef = useRef(new Array());
  const dropdownDisplayFieldRef = useRef(null);

  useEffect(() => {
    for (let i = 0; i < options?.length; i++) {
      if (options[i].heading) {
        setShowSubCategory((showSubCategary) => {
          return { ...showSubCategary, [options[i].value]: true };
        });
      }
    }
  }, [options]);

  useEffect(() => {
    if (!openMenu || !parentRef) return;
    if (
      parentRef?.current?.offsetHeight -
        (ele_ref?.current?.offsetTop + ele_ref?.current?.offsetHeight) <
      320
    )
      set_dropdown_position("top");
    else set_dropdown_position("bottom");
  }, [openMenu]);

  const optionsSet = () => {
    return !keyWord
      ? options
      : options?.filter((option) => {
          let word = option.word ?? option.label;
          return (
            keyWord.toLocaleLowerCase() ===
              word.substring(0, keyWord.length)?.toLocaleLowerCase() &&
            !option.heading
          );
        });
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpenMenu(false);
      }}
    >
      <div
        ref={ele_ref}
        className={
          "relative flex " +
          (dropdown_position === "top" ? "flex-col-reverse " : "flex-col ") +
          wrapperClassName
        }
      >
        <div
          onClick={() => {
            if (readOnly) return;
            ref?.current?.focus();
            setOpenMenu((openMenu) => !openMenu);
          }}
          className={
            "focus:ring-indigo-500 focus:border-indigo-500 hover:text-gray-600 bg-white cursor-default relative h-10 flex items-center rounded-md focus:z-10 sm:text-sm "
          }
          {...props}
          ref={dropdownDisplayFieldRef}
        >
          {!noSearchbar && (
            <div className="w-0 overflow-visible relative left-2">
              <SearchIcon className="h-5 w-5 text-gray-500" />
            </div>
          )}
          <div className={"h-full flex flex-row items-center w-full"}>
            {noSearchbar ? (
              value ? (
                options.find((val) => val.value === value)?.label
              ) : (
                <div className="w-full flex items-center truncate pr-9">
                  {options[0]?.label}
                </div>
              )
            ) : (
              <div className="h-full w-full flex flex-row items-center">
                <input
                  ref={ref}
                  onFocus={props?.onFocus}
                  onChange={(e) => {
                    if (readOnly) return;
                    setKeyWord(e.target.value);
                    onChange("");
                    setOpenMenu(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowDown") optionsRef.current[0]?.focus();
                    else if (e.key === "Enter")
                      setOpenMenu((openMenu) => !openMenu);
                  }}
                  placeholder={placeholder ?? "Select"}
                  value={
                    keyWord
                      ? keyWord
                      : typeof options?.filter(
                          (option) => option.value === value
                        )[0]?.label === "object"
                      ? options?.filter((option) => option.value === value)[0]
                          ?.word
                      : options?.filter((option) => option.value === value)[0]
                          ?.label ?? ""
                  }
                  className={
                    "text-left flex flex-grow truncate w-full h-full px-9 focus:ring-none focus:outline-none " +
                    selectClassName
                  }
                />
              </div>
            )}

            {!readOnly && (
              <div className="relative right-7 w-0 overflow-visible">
                <LeftArrowIcon className="transform duration-100 h-6 w-6 -rotate-90" />
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            ...(dropdown_position === "top"
              ? {
                  marginBottom: dropdownDisplayFieldRef?.current?.offsetHeight,
                }
              : {
                  marginTop: dropdownDisplayFieldRef?.current?.offsetHeight,
                }),
          }}
          className={
            "w-full overflow-auto bg-white flex flex-col border border-gray-300 rounded absolute z-50 " +
            (boxClassName ?? "max-h-64") +
            " " +
            (openMenu ? "" : "hidden")
          }
        >
          {optionsSet()?.map((option, index, option_arr) => (
            <div
              key={index}
              tabIndex="0"
              ref={(ele) => (optionsRef.current[index] = ele)}
              onKeyDown={(e) => {
                if (readOnly) return;
                if (e.key === "ArrowDown" && index < option_arr.length - 1) {
                  optionsRef.current[index + 1]?.focus();
                } else if (e.key === "ArrowUp" && index > 0) {
                  optionsRef.current[index - 1]?.focus();
                } else if (e.key === "ArrowUp" && index === 0)
                  ref?.current?.focus();
                else if (e.key === "Enter" && !option.heading) {
                  onChange(option.value);
                  setOpenMenu(false);
                  setKeyWord();
                }
              }}
              onClick={() => {
                if (!option.heading) {
                  onChange(option.value);
                  setOpenMenu(false);
                  setKeyWord();
                }
              }}
              className={
                "w-full bg-white text-sm focus:bg-blue-600 focus:text-white text-gray-800 p-1 cursor-default " +
                (option.heading
                  ? "pl-4 border-b border-gray-500 bg-gray-300 flex flex-row "
                  : "pl-5 hover:bg-blue-500 hover:text-white ") +
                option.className +
                " " +
                (option.headingKey && !showSubCategary[option.headingKey]
                  ? "hidden"
                  : "")
              }
            >
              <div className="w-full">{option?.label}</div>
              {option.heading && (
                <div
                  className="flex justify-end items-center hover:bg-gray-500 rounded h-6 w-6 mr-3 transform rotate-180"
                  onClick={() => {
                    if (readOnly) return;
                    setShowSubCategory((showSubCategary) => {
                      return {
                        ...showSubCategary,
                        [option.value]: !showSubCategary[option.value],
                      };
                    });
                  }}
                >
                  <LeftArrowIcon
                    className={
                      "transform duration-100 h-6 w-6 opacity-80 " +
                      (showSubCategary[option.value] ? "rotate-90" : "")
                    }
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
}
