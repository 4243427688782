import { useEffect, useState } from "react";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { toast } from "react-toastify";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";

import { LoadingAnimated, LoadingCircleIcon } from "../../../common/AppIcons";
import Recommendation from "./Recommendation";
import {
  getArchitecturalComment,
  postArchitecturalComment,
  postArchitecturalResolution,
} from "../../../../api/ApiV2";
import { useRecoilState } from "recoil";
import { userAtom } from "../../../../store/UserAtom";

const toolbar_options = {
  options: [
    "inline",
    "blockType",
    "list",
    "link",
    "emoji",
    "image",
    "remove",
    "history",
  ],
  inline: {
    options: ["bold", "italic", "strikethrough", "monospace"],
  },
};

const resolution_status_array = [
  {
    value: "NONE",
    label: "None",
  },
  {
    value: "DENIED",
    label: "Denied",
  },
  {
    value: "DISCLOSURE_FINAL",
    label: "Disclosure Final",
  },
  {
    value: "APPROVED",
    label: "Approved",
  },
  {
    value: "APPROVED_W/STIP",
    label: "Approved w/Stip",
  },
];

const recommendation_status_array = [
  {
    value: "APPROVE",
    label: "Accepted",
  },
  {
    value: "DENY",
    label: "Denied",
  },
];

export default function Resolution({ hoaId, plan_data, closePage }) {
  const [user] = useRecoilState(userAtom);
  const [resolution, setResolution] = useState({
    resolution: null,
    description: "",
  });
  const [recommendation, set_recommendation] = useState({
    comment_type: null,
    description: "",
  });
  const [recommendation_list, set_recommendation_list] = useState();

  const [warning, set_warning] = useState({});
  const [loading, set_loading] = useState({
    resolution: false,
    recommendation: false,
    recommendation_list: false,
  });

  const [editor_state, set_editor_state] = useState({
    resolution_description: EditorState.createWithContent(
      convertFromRaw(markdownToDraft(""))
    ),
    recommendation_description: EditorState.createWithContent(
      convertFromRaw(markdownToDraft(""))
    ),
  });
  const [editor_show, set_editor_show] = useState();

  const read_only = plan_data.status !== "OPEN";

  useEffect(() => {
    getRecommendation();
  }, [user.selectedUserProfile]);

  const recommendations_users = () => {
    let users = [];

    recommendation_list
      ?.filter((val) =>
        recommendation_status_array.find(
          (ele) => ele.value === val.comment_type
        )
          ? true
          : false
      )
      ?.map((val) => {
        if (val.created_by && !users.includes(val.created_by))
          users = [...users, val.created_by];
      });

    return users;
  };

  const postResolution = () => {
    if (!resolution.description) {
      set_warning({
        resolution_description: "Please enter a description",
      });
      return;
    }
    if (!resolution.resolution) {
      set_warning({
        resolution_resolution: "Please select a status",
      });
      return;
    }

    set_loading((loading) => {
      return { ...loading, resolution: true };
    });

    postArchitecturalResolution({
      hoaId,
      id: plan_data.id,
      data: {
        resolution: resolution.resolution,
        description: resolution.description,
      },
    })
      .then((res) => {
        console.log(res);

        set_loading((loading) => {
          return { ...loading, resolution: false };
        });

        closePage();
      })
      .catch((res) => {
        set_loading((loading) => {
          return { ...loading, resolution: false };
        });
        console.log(res);
        toast.error(
          res.readableMessage ?? res.message ?? "posting resolution failed"
        );
      });
  };

  const postRecommendation = () => {
    if (!recommendation.description) {
      set_warning({
        recommendation_description: "Please enter a description",
      });
      return;
    }
    if (!recommendation.comment_type) {
      set_warning({
        recommendation_comment_type: "Please select a status",
      });
      return;
    }

    set_loading((loading) => {
      return { ...loading, recommendation: true };
    });

    postArchitecturalComment({
      hoaId,
      id: plan_data.id,
      data: {
        title: "Recommendation",
        comment_type: recommendation.comment_type,
        description: recommendation.description,
      },
    })
      .then((res) => {
        console.log(res);
        set_recommendation({
          comment_type: "",
          description: "",
        });
        set_editor_state((editorState) => {
          return {
            ...editorState,
            recommendation_description: EditorState.createWithContent(
              convertFromRaw(markdownToDraft(""))
            ),
          };
        });
        getRecommendation();
        set_loading((loading) => {
          return { ...loading, recommendation: false };
        });
      })
      .catch((res) => {
        console.log(res);
        toast.error(
          res.readableMessage ?? res.message ?? "posting resolution failed"
        );
        set_loading((loading) => {
          return { ...loading, recommendation: false };
        });
      });
  };

  const getRecommendation = () => {
    set_loading((loading) => {
      return { ...loading, recommendation_list: true };
    });

    getArchitecturalComment({ hoaId, id: plan_data.id })
      .then((res) => {
        console.log(res);
        set_recommendation_list(res.results);

        set_loading((loading) => {
          return { ...loading, recommendation_list: false };
        });
      })
      .catch((res) => {
        set_loading((loading) => {
          return { ...loading, recommendation_list: false };
        });
        console.log(res);
      });
  };

  const removeWarning = (field) => {
    set_warning((warning) => {
      return { ...warning, [field]: null };
    });
  };

  return (
    <div className="flex flex-col min-w-full">
      <div className="mt-10 pt-8 pb-4 flex flex-col w-full bg-gray-100">
        <span
          className="pl-6 font-semibold text-xl"
          style={{ color: "#2A32FD" }}
        >
          Resolution
        </span>
        <div className="px-8 md:px-16 flex flex-col">
          <div className="mt-6 flex flex-col gap-1 min-w-full">
            <div className="flex flex-row justify-between min-w-full">
              <span className="font-semibold text-sm text-gray-700">
                Status
              </span>
              <span className="text-xs text-red-600">
                {warning["resolution_resolution"]}
              </span>
            </div>
            <select
              value={resolution.resolution}
              onChange={(e) => {
                let val = e.target.value;
                removeWarning("resolution_resolution");

                setResolution((resolution) => {
                  return { ...resolution, resolution: val };
                });
              }}
              disabled={read_only}
              className="min-w-full text-gray-800 rounded bg-white px-3 py-2 border-none focus:outline-none"
            >
              <option
                selected
                hidden
                disabled
                value=""
                className="text-gray-400"
              >
                Select a Status
              </option>
              {resolution_status_array
                .filter((val) => val.value !== "")
                .map((type, type_index) => (
                  <option
                    className="bg-white"
                    key={type_index}
                    value={type.value}
                  >
                    {type.label}
                  </option>
                ))}
            </select>
          </div>
          <div className="mt-4 flex flex-col gap-1 min-w-full">
            <div className="flex flex-row justify-between min-w-full">
              <span className="font-semibold text-sm text-gray-700">
                Description
              </span>
              <span className="text-xs text-red-600">
                {warning["resolution_description"]}
              </span>
            </div>
            <Editor
              editorState={editor_state.resolution_description}
              onFocus={() => {
                set_editor_show("resolution_description");
              }}
              onBlur={() => {
                set_editor_show();
              }}
              readOnly={read_only}
              toolbar={toolbar_options}
              wrapperClassName=""
              toolbarClassName={
                "shadow-md absolute transform -translate-y-12 " +
                (editor_show === "resolution_description"
                  ? "opacity-100"
                  : "opacity-0")
              }
              editorClassName={
                "appearance-none rounded-lg w-full px-3 py-2 text-gray-700 leading-tight " +
                (editor_show === "resolution_description"
                  ? "bg-white"
                  : "bg-white")
              }
              onEditorStateChange={(newEditorState) => {
                removeWarning("resolution_description");
                set_editor_state((editorState) => {
                  return {
                    ...editorState,
                    resolution_description: newEditorState,
                  };
                });
                const newValue = draftToMarkdown(
                  convertToRaw(newEditorState.getCurrentContent())
                );

                setResolution((resolution) => {
                  return { ...resolution, description: newValue };
                });
              }}
            />
          </div>

          <div className="mt-4 flex flex-row justify-end w-full gap-2">
            <button
              disabled={read_only}
              onClick={postResolution}
              style={{ backgroundColor: "#2A32FD" }}
              className={
                "text-white font-semibold h-10 w-36 flex items-center justify-center rounded shadow-sm hover:opacity-70"
              }
            >
              {loading.resolution ? (
                <LoadingAnimated className="h-5" />
              ) : (
                "Save decision"
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="mt-4 pt-8 pb-4 flex flex-col w-full bg-gray-100">
        <span
          className="pl-6 font-semibold text-xl"
          style={{ color: "#2A32FD" }}
        >
          Recommendations
        </span>
        <div className="px-8 md:px-16 flex flex-col">
          <div className="mt-6 flex flex-col gap-1 min-w-full">
            <div className="flex flex-row justify-between min-w-full">
              <span className="font-semibold text-sm text-gray-700">
                Recommendation
              </span>
              <span className="text-xs text-red-600">
                {warning["recommendation_comment_type"]}
              </span>
            </div>
            <select
              value={recommendation.comment_type}
              disabled={read_only}
              onChange={(e) => {
                let val = e.target.value;
                removeWarning("recommendation_comment_type");
                set_recommendation((recommendation) => {
                  return { ...recommendation, comment_type: val };
                });
              }}
              className="min-w-full text-gray-800 rounded bg-white px-3 py-2 border-none focus:outline-none"
            >
              <option
                selected
                hidden
                disabled
                value=""
                className="text-gray-400"
              >
                Select a Status
              </option>
              {recommendation_status_array
                .filter((val) => val.value !== "")
                .map((type, type_index) => (
                  <option
                    className="bg-white"
                    key={type_index}
                    value={type.value}
                  >
                    {type.label}
                  </option>
                ))}
            </select>
          </div>
          <div className="mt-4 flex flex-col gap-1 min-w-full">
            <div className="flex flex-row justify-between min-w-full">
              <span className="font-semibold text-sm text-gray-700">
                Description
              </span>
              <span className="text-xs text-red-600">
                {warning["recommendation_description"]}
              </span>
            </div>
            <Editor
              readOnly={read_only}
              editorState={editor_state.recommendation_description}
              onFocus={() => {
                set_editor_show("recommendation_description");
              }}
              onBlur={() => {
                set_editor_show();
              }}
              toolbar={toolbar_options}
              wrapperClassName=""
              toolbarClassName={
                "shadow-md absolute transform -translate-y-12 " +
                (editor_show === "recommendation_description"
                  ? "opacity-100"
                  : "opacity-0")
              }
              editorClassName={
                "appearance-none rounded-lg w-full px-3 py-2 text-gray-700 leading-tight " +
                (editor_show === "recommendation_description"
                  ? "bg-white"
                  : "bg-white")
              }
              onEditorStateChange={(newEditorState) => {
                removeWarning("recommendation_description");
                set_editor_state((editorState) => {
                  return {
                    ...editorState,
                    recommendation_description: newEditorState,
                  };
                });
                const newValue = draftToMarkdown(
                  convertToRaw(newEditorState.getCurrentContent())
                );

                set_recommendation((recommendation) => {
                  return { ...recommendation, description: newValue };
                });
              }}
            />
          </div>

          <div className="mt-4 flex flex-row justify-end w-full gap-2">
            <button
              disabled={read_only}
              onClick={() => {
                postRecommendation();
              }}
              style={{ backgroundColor: "#2A32FD" }}
              className={
                "text-white font-semibold h-10 w-52 flex items-center justify-center rounded shadow-sm hover:opacity-70"
              }
            >
              {loading.recommendation ? (
                <LoadingAnimated className="h-5" />
              ) : (
                "Save Recommendation"
              )}
            </button>
          </div>
        </div>
      </div>
      <span className="mt-8 font-semibold text-xl" style={{ color: "#2A32FD" }}>
        Committee Recommendations
      </span>
      {loading.recommendation_list && (
        <div className="mt-10 mx-auto w-full flex justify-center">
          <LoadingCircleIcon className="h-9" />
        </div>
      )}
      {!loading.recommendation_list && (
        <div className="min-w-full">
          {recommendation_list?.filter((val) =>
            recommendation_status_array.find(
              (ele) => ele.value === val.comment_type
            )
              ? true
              : false
          ) ? (
            <div className="m-6 flex flex-col gap-6">
              {recommendations_users().map((user, user_index) => (
                <div className="flex flex-col gap-3">
                  {recommendation_list
                    ?.filter((val) =>
                      recommendation_status_array.find(
                        (ele) => ele.value === val.comment_type
                      ) && val.created_by === user
                        ? true
                        : false
                    )

                    .sort(
                      (val1, val2) =>
                        new Date(val2.created_at) - new Date(val1.created_at)
                    )
                    .map((recommendation, recommendation_index) => (
                      <Recommendation
                        leading={recommendation_index === 0 ? true : false}
                        recommendation_status_array={
                          recommendation_status_array
                        }
                        recommendation={recommendation}
                      />
                    ))}
                </div>
              ))}
            </div>
          ) : (
            <div className="p-6 mt-8 w-full text-center font-semibold text-gray-400">
              No Recommendations Yet.
            </div>
          )}
        </div>
      )}
    </div>
  );
}
