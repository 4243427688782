import React, { useState, useEffect } from "react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "react-currency-input";

import {
  LoadingCircleIcon2,
  RightArrowIcon,
} from "../../../../common/AppIcons";
import {
  getAccounts,
  getAllTransactions,
  getBills,
  matchPlaidTransaction,
  postBillPayment,
  postPlaidTransaction,
} from "../../../../../api/ApiV2";
import Dropdown from "../../../../common/Form/Dropdown";
import TextInput from "../../../../common/Form/TextInput";
import { objectMatch } from "../../../../../utils/StringUtils";
import { dateString } from "../../../../../utils/DateUtils";

import { genericCategories } from "../../../../../common/constants";
import { amountFormat } from "../../../../common/AmountFormat";
import { toast } from "react-toastify";
import Popup from "../../../../common/Popup";
import NewAccountPopup from "../../../components/NewAccountPopup";

export default function BankFeed({
  hoaId,
  baseData = { title: "Bank Feed", transactions: [] },
  onComplete,
  get_plaid_transactions,
}) {
  const [data, setData] = useState(baseData);
  const [accounts, setAccounts] = useState([]);
  const [unmatchedTransactions, setUnmatchedTransactions] = useState([]);
  const [unpaidBills, setUnpaidBills] = useState([]);
  const [loading, setLoading] = useState();
  const [warning, setWarnings] = useState({});
  const [create_account_popup, set_create_account_popup] = useState(false);

  useEffect(
    (_) => {
      if (accounts.length > 0) {
        console.log("Intelligently Assigning Categories");
        setData((data) => {
          const returnable = {
            ...data,
            transactions: data.transactions.map((transaction) =>
              transaction.account
                ? transaction
                : {
                    ...transaction,
                    account:
                      objectMatch(
                        [
                          ...transaction.name.split(" "),
                          ...transaction.category,
                        ],
                        accounts,
                        "name",
                        genericCategories
                      )?.id ?? null,
                  }
            ),
          };
          console.log("Post Processing", returnable);
          return {
            ...returnable,
            transactions: returnable.transactions.map((transaction) => {
              return { ...transaction, id: undefined };
            }),
          };
        });
      }
    },
    [accounts]
  );

  const fetchAccounts = () => {
    getAccounts({ hoaId: hoaId, page_size: 300 })
      .then((res) => {
        setAccounts(
          res.results.filter((account) =>
            ["EXPENSE", "INCOME"].includes(account.type)
          )
        );
      })
      .catch(console.log);
  };

  useEffect((_) => {
    console.log({ baseData });

    fetchAccounts();

    getAllTransactions({ hoaId, accountId: data.account.id, limit: 300 })
      .then((res) => {
        setUnmatchedTransactions(res.results);
      })

      .catch(console.log);

    getBills({ hoaId, paid: false, page_size: 300 })
      .then((res) => {
        setUnpaidBills(res.results);
      })
      .catch(console.log);
  }, []);

  const saveTransaction = (transaction) => {
    if (transaction.status === "LINK") {
      if (!transaction.billId) {
        setWarnings((warning) => ({
          ...warning,
          [transaction.external_id]: { billId: "Select a Bill" },
        }));
        return;
      }
      setLoading(transaction.external_id);
      postBillPayment({
        hoaId,
        billId: transaction.billId,
        data: {
          plaid_transaction: transaction.external_id,
          from_account: data.account?.id,
        },
      })
        .then((res) => {
          setLoading();
          get_plaid_transactions(data.account);
        })
        .catch((res) => {
          toast.error(res.message ?? "Error saving transaction");
          setLoading();
        });
    } else if (transaction.status === "MATCH") {
      if (!transaction.transactionId) {
        setWarnings((warning) => ({
          ...warning,
          [transaction.external_id]: { transactionId: "Select a transaction" },
        }));
        return;
      }
      setLoading(transaction.external_id);
      matchPlaidTransaction({
        hoaId,
        transactionId: transaction.transactionId,
        data: {
          plaid_transaction: transaction.external_id,
        },
      })
        .then((res) => {
          setLoading();
          get_plaid_transactions(data.account);
        })
        .catch((res) => {
          toast.error(res.message ?? "Error saving transaction");
          setLoading();
        });
    } else {
      if (!transaction.account) {
        setWarnings((warning) => ({
          ...warning,
          [transaction.external_id]: { account: "Select a category" },
        }));
        return;
      }
      setLoading(transaction.external_id);
      postPlaidTransaction({
        hoaId,
        data: {
          plaid_transaction: transaction.external_id,
          account: transaction.account,
        },
      })
        .then((res) => {
          setLoading();
          get_plaid_transactions(data.account);
        })
        .catch((res) => {
          toast.error(res.message ?? "Error saving transaction");
          setLoading();
        });
    }
  };

  const removeWarning = (id) => {
    let tempWarnings = warning;
    delete tempWarnings[id];
    setWarnings(tempWarnings);
  };

  return (
    <div
      style={{ minWidth: "1000px" }}
      className="border border-black flex flex-col max-h-screen"
    >
      <div className=" flex flex-col bg-gray-200 px-5">
        <div className="flex flex-row p-3 text-xl font-semibold">
          <span>{data.title}</span>
        </div>
        <div className="flex flex-col md:flex-row gap-2 justify-between w-full px-3 font-semibold pb-3">
          <span className="relative top-2">Account</span>
          <div className="invisible lg:hidden"></div>
          <span
            name="accounts"
            className="border-2 border-gray-400 bg-white p-2 rounded-lg w-full mx-5"
          >
            {data.account.name}
          </span>
        </div>
      </div>
      <div className="border-t border-black px-5 pt-8 pb-20 bg-white">
        <div className="min-w-full grid grid-cols-8 gap-3 mb-3 pl-16 pr-10 font-semibold text-gray-500">
          <span>DATE</span>
          <span className="col-span-2">DESCRIPTION</span>
          <span className="col-span-3">CATEGORY</span>
          <span>CREDIT</span>
          <span>DEBIT</span>
        </div>
        <div className="overflow-auto">
          {data.transactions.map((transaction, renderIndex, arr) => {
            return (
              <div key={renderIndex} className="flex flex-col">
                <div className="flex flex-row justify-between items-center">
                  <div
                    className={
                      "flex flex-col gap-2 flex-grow rounded-lg border border-gray-400 p-3 px-5 justify-between hover:bg-gray-100 "
                    }
                  >
                    <div className="flex flex-row items-center gap-3">
                      <button
                        className={
                          "w-8 flex-shrink-0 justify-center flex items-center cursor-pointer" +
                          `${
                            transaction.expanded ? " transform rotate-90" : ""
                          }`
                        }
                        onClick={(_) => {
                          setData((data) => {
                            return {
                              ...data,
                              transactions: data.transactions.map(
                                (transaction, mapIndex) => {
                                  return mapIndex === renderIndex
                                    ? {
                                        ...transaction,
                                        expanded: !transaction.expanded,
                                      }
                                    : transaction;
                                }
                              ),
                            };
                          });
                        }}
                      >
                        <RightArrowIcon />
                      </button>
                      <div className="w-full grid grid-cols-8 gap-3 items-center">
                        <span>{transaction.date}</span>
                        <TextInput
                          wrapperClassName="col-span-2"
                          className="px-2.5 py-1 text-sm rounded"
                          value={transaction.description}
                          onChange={(val) => {
                            setData((data) => ({
                              ...data,
                              transactions: data.transactions.map(
                                (val1, index) =>
                                  index === renderIndex
                                    ? { ...val1, description: val }
                                    : val1
                              ),
                            }));
                          }}
                        />
                        <div className="col-span-3 flex flex-col">
                          {transaction.status !== "LINK" &&
                            transaction.status !== "MATCH" && (
                              <span className="text-red-600 text-xs flex justify-end">
                                {warning[transaction.external_id]?.account ??
                                  ""}
                              </span>
                            )}
                          {transaction.status !== "LINK" &&
                            transaction.status !== "MATCH" && (
                              <Dropdown
                                wrapperClassName={"flex flex-1"}
                                selectClassName={
                                  "flex flex-1 border-2 border-gray-400 p-2 rounded-lg w-20 focus:outline-none hover:border-gray-500 focus:border-gray-600"
                                }
                                options={[
                                  {
                                    word: "Create new account",
                                    label: (
                                      <div className="w-full font-medium py-1">
                                        <span className="truncate">
                                          Create new account
                                        </span>
                                      </div>
                                    ),
                                    value: "CREATE_NEW_ACCOUNT",
                                  },
                                  ...accounts.map((account, index) => {
                                    return {
                                      word: account.name,
                                      label: (
                                        <div className="grid grid-cols-4 w-full text-xs font-semibold">
                                          <h1 className="col-span-3 truncate">
                                            {account.name}
                                          </h1>
                                          <h1 className="text-right break-all whitespace-pre">
                                            $
                                            {amountFormat(
                                              account.current_amount
                                            )}
                                          </h1>
                                        </div>
                                      ),
                                      value: account.id,
                                    };
                                  }),
                                ]}
                                value={transaction.account}
                                onChange={(selected) => {
                                  if (selected === "CREATE_NEW_ACCOUNT") {
                                    set_create_account_popup(true);
                                  } else {
                                    removeWarning(transaction.external_id);
                                    setData((data) => {
                                      return {
                                        ...data,
                                        transactions: data.transactions.map(
                                          (transaction, mapIndex) =>
                                            renderIndex === mapIndex
                                              ? {
                                                  ...transaction,
                                                  account: selected,
                                                }
                                              : transaction
                                        ),
                                      };
                                    });
                                  }
                                }}
                              />
                            )}
                        </div>
                        <CurrencyInput
                          value={
                            transaction.amount < 0 ? 0 : transaction.amount
                          }
                          onChange={(event, maskedvalue, floatvalue) =>
                            setData({
                              ...data,
                              transactions: data.transactions.map(
                                (transaction, mapIndex) =>
                                  renderIndex === mapIndex
                                    ? {
                                        ...transaction,
                                        amount: Number(maskedvalue),
                                      }
                                    : transaction
                              ),
                            })
                          }
                          className="px-2.5 py-.5 rounded"
                        />

                        <CurrencyInput
                          value={
                            transaction.amount < 0
                              ? -1 * parseFloat(transaction.amount)
                              : 0
                          }
                          onChange={(event, maskedvalue, floatvalue) =>
                            setData({
                              ...data,
                              transactions: data.transactions.map(
                                (transaction, mapIndex) =>
                                  renderIndex === mapIndex
                                    ? {
                                        ...transaction,
                                        amount: -Number(maskedvalue),
                                      }
                                    : transaction
                              ),
                            })
                          }
                          className="px-2.5 py-.5 rounded"
                        />
                      </div>
                    </div>
                    {transaction.expanded && (
                      <div className="flex flex-row items-center gap-3">
                        <button
                          className="w-20 h-8 text-sm bg-indigo-500 hover:bg-indigo-600 text-white rounded-lg justify-center flex items-center cursor-pointer"
                          onClick={(_) =>
                            setData({
                              ...data,
                              transactions: data.transactions.map(
                                (transaction, mapIndex) =>
                                  renderIndex === mapIndex
                                    ? transaction.status === undefined
                                      ? {
                                          ...transaction,
                                          status: "LINK",
                                        }
                                      : transaction.status === "LINK"
                                      ? {
                                          ...transaction,
                                          status: "MATCH",
                                        }
                                      : {
                                          ...transaction,
                                          status: undefined,
                                        }
                                    : transaction
                              ),
                            })
                          }
                        >
                          {transaction.status === "LINK"
                            ? "LINK"
                            : transaction.status === "MATCH"
                            ? "MATCH"
                            : "CREATE"}
                        </button>
                        <span className="ml-3 px-2 justify-center flex items-center text-sm">
                          {transaction.status === "LINK"
                            ? "Select a Bill to Link this Transaction to"
                            : transaction.status === "MATCH"
                            ? "Select a Transaction to Match this Transaction to"
                            : "This Transaction will be saved as a new Transaction"}
                        </span>
                        <span className="">
                          {transaction.status === "LINK" ? (
                            <div className="col-span-3 flex flex-col">
                              <span className="text-red-600 text-xs flex justify-end">
                                {warning[transaction.external_id]?.billId ?? ""}
                              </span>{" "}
                              <Dropdown
                                wrapperClassName={"flex flex-1"}
                                selectClassName={
                                  "flex flex-1 border-2 border-gray-400 p-2 rounded-lg w-20 focus:outline-none hover:border-gray-500 focus:border-gray-600"
                                }
                                options={unpaidBills.map((bill) => {
                                  return {
                                    label: bill.reference_number,
                                    value: bill.id,
                                  };
                                })}
                                value={transaction.billId}
                                onChange={(selected) =>
                                  setData((data) => {
                                    return {
                                      ...data,
                                      transactions: data.transactions.map(
                                        (transaction, mapIndex) =>
                                          renderIndex === mapIndex
                                            ? {
                                                ...transaction,
                                                billId: selected,
                                              }
                                            : transaction
                                      ),
                                    };
                                  })
                                }
                              />
                            </div>
                          ) : transaction.status === "MATCH" ? (
                            <div className="col-span-3 flex flex-col">
                              <span className="text-red-600 text-xs flex justify-end">
                                {warning[transaction.external_id]
                                  ?.transactionId ?? ""}
                              </span>
                              <Dropdown
                                wrapperClassName={"flex flex-1"}
                                selectClassName={
                                  "flex flex-1 border-2 border-gray-400 p-2 rounded-lg w-20 focus:outline-none hover:border-gray-500 focus:border-gray-600"
                                }
                                options={unmatchedTransactions.map(
                                  (transaction) => {
                                    return {
                                      label: transaction.description,
                                      value: transaction.id,
                                    };
                                  }
                                )}
                                placeholder="Select a Transaction"
                                value={transaction.transactionId}
                                onChange={(selected) => {
                                  removeWarning(transaction.external_id);
                                  setData((data) => {
                                    return {
                                      ...data,
                                      transactions: data.transactions.map(
                                        (transaction, mapIndex) =>
                                          renderIndex === mapIndex
                                            ? {
                                                ...transaction,
                                                transactionId: selected,
                                              }
                                            : transaction
                                      ),
                                    };
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            React.null
                          )}
                        </span>

                        <button
                          onClick={() => {
                            saveTransaction(transaction);
                          }}
                          className="w-20 h-8 text-sm bg-green-500 hover:bg-green-600 text-white rounded-lg justify-center flex items-center cursor-pointer"
                        >
                          {loading === transaction.external_id ? (
                            <LoadingCircleIcon2 className="h-4 text-white" />
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="ml-3 cursor-pointer"
                    onClick={(_) =>
                      setData({
                        ...data,
                        transactions: data.transactions.filter(
                          (transaction, filterIndex) =>
                            renderIndex !== filterIndex
                        ),
                      })
                    }
                  ></FontAwesomeIcon>
                </div>
              </div>
            );
          })}
          <FontAwesomeIcon
            icon={faPlus}
            onClick={() => {
              setData({
                ...data,
                transactions: [
                  ...data.transactions,
                  { date: new Date().toISOString().split("T")[0] },
                ],
              });
            }}
            className="text-sm mt-4 m-3 cursor-pointer"
          ></FontAwesomeIcon>
        </div>
      </div>
      <Popup
        show={create_account_popup}
        setShow={set_create_account_popup}
        className="p-4 flex flex-col items-center justify-center"
      >
        <div className="max-w-xl">
          <NewAccountPopup
            hoaId={hoaId}
            allowedAccounts={["EXPENSE", "INCOME"]}
            closePopup={(_) => {
              set_create_account_popup(false);
              fetchAccounts();
            }}
          />
        </div>
      </Popup>
    </div>
  );
}
