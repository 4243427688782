export const hoaModulesFormData = [
  {
    label: "Accounting",
    name: "ACCOUNTING",
  },
  {
    label: "Compliance",
    name: "COMPLIANCE",
  },
];
