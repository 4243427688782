import React from "react";
import { isNumericBlank } from "../../../utils/StringUtils";

export default function PhoneInputInput({
  name,
  placeholder,
  value,
  onChange,
  className,
  ...props
}) {
  const onChangeDispatch = (value) => {
    const updatedValue = value;
    onChange(updatedValue);
  };

  return (
    <div>
      <label
        htmlFor="phone_number"
        className="block text-sm font-medium text-gray-700"
      >
        Phone Number
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0">
          <label htmlFor="country" className="sr-only">
            Country
          </label>
          <span className="flex items-center focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md">
            US
          </span>
        </div>
        <input
          {...props}
          id={name}
          type="text"
          name={name}
          value={value}
          onChange={(e) => onChangeDispatch(e.target.value)}
          className={
            "focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:text-sm border-gray-300 rounded-md " +
            className
          }
          placeholder="+1 (555) 987-6543"
        />
      </div>
    </div>
  );
}
