export default function TransactionTables({
  label,
  tableTitles,
  tableContent,
  bankTransactions,
  type,
  highlightMarked,
  tableColClass,
  tableRowClassName,
  onCheck,
}) {
  const transactionsToRender = bankTransactions.filter((transaction) => {
    return type === "payments"
      ? Number(transaction.computed_transaction_amount) >= 0
      : Number(transaction.computed_transaction_amount) < 0;
  });
  return (
    <div className="w-full">
      <h1 className="text-lg font-semibold my-2">{label}</h1>
      <div className="rounded" style={{ border: "3px solid #F5F5F5" }}>
        <div
          className="flex flex-row items-center bg-gray-100 w-full px-3 py-2"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <input
            type="checkbox"
            className="rounded-sm focus:ring-0 mx-px"
            checked={transactionsToRender.reduce((sum, val) => {
              if (!val.checked) return false;
              else return sum;
            }, true)}
            onChange={() => {
              let all_checked = true;
              transactionsToRender.map((val) => {
                if (!val.checked) all_checked = false;
              });

              transactionsToRender.map((val) => {
                if (all_checked && val.checked) onCheck(val.id);
                if (!all_checked && !val.checked) onCheck(val.id);
              });
            }}
          />

          <div className={"grid w-full " + tableColClass}>
            {tableTitles.map((value, index) => (
              <span
                key={index}
                className={
                  "text-sm font-semibold text-gray-500 px-1 " +
                  tableRowClassName[index]
                }
              >
                {value}
              </span>
            ))}
          </div>
        </div>
        <div className="flex flex-col divide-y divide-gray-300">
          {transactionsToRender.map((transaction) => (
            <div
              className={
                "flex flex-row items-center px-3 py-1 " +
                (transaction.checked && highlightMarked ? "bg-gray-300" : "") +
                " hover:bg-indigo-200"
              }
              onClick={() => {
                onCheck(transaction.id);
              }}
            >
              <input
                type="checkbox"
                className="rounded-sm focus:ring-0 mx-px"
                checked={transaction.checked}
                onChange={() => {}}
              />

              <div
                className={`grid text-sm font-semibold text-gray-800 w-full ${tableColClass}`}
              >
                {tableContent(transaction, transaction.relavantLeg).map(
                  (colValue, colIndex) => {
                    return (
                      <div
                        key={colIndex}
                        className={
                          "px-1 truncate py-1 " + tableRowClassName[colIndex]
                        }
                      >
                        {colValue}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
