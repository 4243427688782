import React from "react";
import { amenityAvailability } from "../data/amenityAvailability";

export default function AmenityAvailabilityBadge({ availability, className }) {
  const badge_data = amenityAvailability.find(
    (ele) => ele.value === availability
  ) || { label: ("" + availability)?.toLowerCase() };

  return (
    <div
      style={badge_data.style}
      className={"rounded-full px-2.5 py-1 text-xs " + className}
    >
      {" "}
      {badge_data.label}{" "}
    </div>
  );
}
