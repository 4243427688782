import React, { useEffect, useRef, useState } from "react";
import { CrossCicleIcon, CrossInCircleIcon } from "../../AppIcons";
import Input from "../Input";

export default function Row({
  row,
  deleteItem,
  readOnly = true,
  onClick,
  wrapperClassName,
  className,
  label_className,
}) {
  const random = Math.random();

  return (
    <div
      onClick={onClick}
      className={"w-full flex flex-row items-center " + wrapperClassName}
    >
      <div className={"flex-grow py-3 " + className}>
        {row?.map((ele, ele_index) => (
          <div key={ele_index} className={"w-full flex " + ele.className}>
            {!ele.hideLabelForm && (readOnly || ele.input?.readOnly) ? (
              <span
                className={
                  "text-gray-800 break-words " +
                  (readOnly || ele.input?.readOnly
                    ? "cursor-default "
                    : "" + label_className)
                }
              >
                {ele?.input?.type === "currencyLocal" ? "$" : ""}
                {ele.label}
              </span>
            ) : (
              <div className="flex flex-col items-end">
                {ele.input?.warning && (
                  <span className="text-xs font-light text-red-600">
                    {ele.input?.warning}
                  </span>
                )}
                <Input id={"table_input" + ele_index + random} {...ele.input} />
              </div>
            )}
          </div>
        ))}
      </div>

      {!readOnly && (
        <div
          className={
            "ml-3 w-9 py-3 px-1 flex items-center justify-center " +
            (deleteItem ? "" : "invisible")
          }
        >
          <div
            className="text-red-600 hover:text-red-700 cursor-pointer"
            onClick={deleteItem ?? null}
          >
            <CrossCicleIcon className="h-5" />
          </div>
        </div>
      )}
    </div>
  );
}
