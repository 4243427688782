import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import {
  LoadingCircleIcon,
  LoadingCircleIcon2,
  PlusInCircleIcon2,
} from "../../../../common/AppIcons";
import {
  bulkPostUnits,
  getBuildings,
  getHoa,
  getMelissaAddress,
  patchBuilding,
  postBuilding,
} from "../../../../../api/ApiV2";
import PropertyForm from "./components/PropertyForm";

const initialId = new Date().getTime() + "";

export default function BuildingDetails({ hoaData, previousPage, nextPage }) {
  const [buildings, setBuildings] = useState([]);
  // updateStatus = UNEDITED | EDITED | LOADING | SAVED
  const [warnings, setWarnings] = useState({
    initialId: {
      street_address_1: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const [changePage, setChangePage] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);

  useEffect(() => {
    if (changePage) {
      nextPage();
    }
  }, [changePage]);

  useEffect(() => {
    setLoading(true);

    getBuildings({ hoaId: hoaData.hoaId })
      .then((res1) => {
        let res = res1;
        console.log(res);
        res = res.map((val) => {
          return {
            ...val,
            updateStatus: "UNEDITED",
            existing: true,
            old_melissa_base_mak: val.melissa_base_mak,
          };
        });

        let tempBuildings = [];
        if (res?.length > 0) {
          setLoading(false);
          tempBuildings = res;
        } else {
          tempBuildings = [
            ...(res ?? []),
            {
              id: initialId,
              street_address_1: "",
              zipCode: "",
              updateStatus: "EDITED",
            },
          ];
          getHoaDetail();
        }
        setBuildings(tempBuildings);

        componentRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      })
      .catch((err) => {
        getHoaDetail();
        console.log(err);
        setBuildings([
          {
            id: initialId,
            street_address_1: "",
            zipCode: "",
            updateStatus: "EDITED",
          },
        ]);

        componentRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      });
  }, [hoaData.hoaId]);

  const getHoaDetail = () => {
    setLoading(true);
    getHoa({ external_id: hoaData.hoaId })
      .then((res1) => {
        getMelissaAddress({ address: res1.street_address_1 })
          .then((res) => {
            setLoading(false);
            let real_address =
              res?.filter(
                (val) =>
                  val?.Address?.SubBuilding && val?.Address?.SubBuilding !== ""
              )[0] ?? {};
            console.log({ real_address });

            setBuildings((buildings) => [
              {
                updateStatus: "EDITED",
                ...res1,
                street_address_1: real_address?.Address?.Address1,
                city:
                  real_address.Address?.Locality ||
                  real_address?.Address?.SubAdministrativeArea,
                state: real_address?.Address?.AdministrativeArea,
                zipCode: real_address?.Address?.PostalCodePrimary,
                geocodeLatitude: real_address?.Address?.Latitude,
                geocodeLongitude: real_address?.Address?.Longitude,
                melissaBaseMak:
                  real_address?.Address?.BaseMAK ?? real_address?.Address?.MAK,
                units: real_address?.Address?.BaseMAK
                  ? {
                      units: real_address?.Address?.SubBuilding.split(
                        ","
                      ).filter((val) => val !== ""),
                      maks: real_address?.Address?.MAK.split(",").filter(
                        (val) => val !== ""
                      ),
                    }
                  : null,
                id: buildings[0]?.id,
                existing: buildings[0]?.existing,
              },
            ]);
          })
          .catch((res) => {
            setLoading(false);
            console.log("address hints error:" + res);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const delete_warning = (value, id) => {
    let temp_warning = warnings;
    Object.keys(temp_warning).map((val) => {
      if (val === id) {
        delete temp_warning[val][value];
        return temp_warning;
      } else return temp_warning[val];
    });
    setWarnings(temp_warning);
  };

  const findWarnings = () => {
    let tempWarning = {};

    buildings.map((data) => {
      let warning = {};
      if (data.street_address_1 === "")
        warning = { ...warning, street_address_1: "Please Fill this Field" };

      if (
        data.zipCode &&
        (Number(data.zipCode) < 90001 || Number(data.zipCode) > 96162)
      )
        warning = { ...warning, street_address_1: "Invalid Zipcode" };

      tempWarning = { ...tempWarning, [data.id]: warning };
    });

    setWarnings(tempWarning);
    return tempWarning;
  };

  const submitAddress = () => {
    if (submitFlag) return;

    let tempWarnings = findWarnings();
    let flag = true;

    Object.keys(tempWarnings).map((val) => {
      flag = Object.keys(tempWarnings[val]).length > 0 ? false : flag;
    });

    if (!flag) {
      toast.error("Please fill all the required fields");
      return;
    }

    if (!buildings?.find((building) => building.updateStatus === "EDITED")) {
      setSubmitFlag(false);
      setChangePage(true);
    }

    let apiLoading = Array(
      buildings?.filter((building) => building.updateStatus !== "EDITED")
        ?.length
    ).fill(true);

    buildings
      .filter((building) => building.updateStatus !== "UNEDITED")
      .map((building, index) => {
        const apiCall = building.existing ? patchBuilding : postBuilding;

        const changeUpdateStatus = (status) => {
          setBuildings((prev) => {
            return prev.map((val) => {
              if (val.id === building.id) {
                return { ...val, updateStatus: status };
              } else return val;
            });
          });
        };

        changeUpdateStatus("LOADING");

        apiCall({
          hoaId: hoaData.hoaId,
          buildingId: building.id,
          data: {
            street_address_1: building.street_address_1,
            melissa_base_mak: building.melissaBaseMak,
          },
        })
          .then((res) => {
            console.log(res);
            apiLoading[index] = false;
            let unitApiLoading = true;
            console.log(building);
            if (building.melissaBaseMak !== building.old_melissa_base_mak)
              bulkPostUnits({
                hoaId: hoaData.hoaId,
                data: {
                  units: building.units?.units?.map((val) => ({
                    unit_number: val,
                    address: res.id,
                  })),
                  // address: res.id
                },
              })
                .then((res) => {
                  console.log(res);
                  unitApiLoading = false;
                  changeUpdateStatus("SAVED");
                  if (
                    apiLoading.every((val) => val === false) &&
                    unitApiLoading === false
                  ) {
                    setSubmitFlag(false);
                    setChangePage(true);
                  }
                })
                .catch((res) => {
                  console.log(res);
                  toast.error(res.message ?? "Error in creating units");
                  unitApiLoading = false;
                  changeUpdateStatus("EDITED");
                });
            else {
              unitApiLoading = false;
              changeUpdateStatus("SAVED");
              if (
                apiLoading.every((val) => val === false) &&
                unitApiLoading === false
              ) {
                setSubmitFlag(false);
                setChangePage(true);
              }
            }

            if (!building.units?.units?.length > 0) changeUpdateStatus("SAVED");

            if (
              apiLoading.every((val) => val === false) &&
              !building.units?.units?.length > 0
            ) {
              setSubmitFlag(false);
              setChangePage(true);
            }
          })
          .catch((res) => {
            console.log(res);
            apiLoading[index] = false;
            setWarnings((prev) => {
              return {
                ...prev,
                [building.id]: {
                  street_address_1: res.message ?? res.melissa_base_mak,
                },
              };
            });
            toast.error(res.message ?? "Error in creating building");
            changeUpdateStatus("EDITED");
          });
      });
  };

  if (loading)
    return (
      <div className="w-full flex items-center justify-center py-20">
        <LoadingCircleIcon2 className={"w-10 h-10"} />
      </div>
    );
  else
    return (
      <div
        ref={componentRef}
        className="max-w-6xl w-full flex-grow bg-white rounded-md shadow-md mt-12"
      >
        <div className="p-3 grid md:grid-cols-12 md:w-full gap-4 rounded-md">
          <div className="col-span-5 hidden md:block h-full">
            <img
              alt=""
              className="w-full object-left"
              src="/doorz_create_1.png"
            />
          </div>
          <div className="w-full col-span-7 px-10 py-8 md:h-full">
            <div className="h-full w-full m-auto px-4 flex flex-col items-center text-gray-800 max-w-2xl">
              <span className="mt-2 text-xl font-semibold">
                Let's add the resident buildings
              </span>

              <div className="mt-3 w-full flex flex-col">
                {buildings?.map((form, formIndex) => {
                  return (
                    <PropertyForm
                      key={formIndex}
                      index={formIndex}
                      deleteProperty={
                        form.existing
                          ? null
                          : () => {
                              setBuildings((buildings) => {
                                return buildings.filter(
                                  (val) => val.id !== form.id
                                );
                              });
                            }
                      }
                      {...{
                        form,
                        setHoaDetail: (value) =>
                          setBuildings((buildings) => {
                            return buildings.map((building) =>
                              form.id === building.id
                                ? {
                                    id: building.id,
                                    ...value,
                                    updateStatus: "EDITED",
                                  }
                                : building
                            );
                          }),
                        warnings,
                        setWarnings,
                        delete_warning,
                      }}
                    />
                  );
                })}
              </div>
              <div className="flex flex-row w-full justify-start pl-8 mt-8">
                <button
                  className="hover:opacity-60 font-semibold flex flex-row items-center gap-2"
                  onClick={() => {
                    let id = new Date().getTime() + "";
                    setBuildings((buildings) => {
                      return [
                        ...buildings,
                        {
                          id: id,
                          street_address_1: "",
                          zipCode: "",
                          updateStatus: "EDITED",
                        },
                      ];
                    });
                  }}
                >
                  <PlusInCircleIcon2 className="text-green-500 h-3" />
                  Add Building
                </button>
              </div>

              <div className="w-full mt-10 flex flex-row justify-end items-center gap-3">
                <button
                  onClick={previousPage}
                  className="text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 h-10 w-28 rounded"
                >
                  Go Back
                </button>

                <button
                  style={{
                    backgroundColor: "#2A32FD",
                    border: "2px solid #2A32FD",
                  }}
                  className="text-sm text-white hover:opacity-80 h-10 w-28 flex items-center justify-center rounded"
                  onClick={() => {
                    submitAddress();
                  }}
                  disabled={buildings.find(
                    (val) => val.updateStatus === "LOADING"
                  )}
                >
                  {buildings.find((val) => val.updateStatus === "LOADING") ? (
                    <LoadingCircleIcon className="h-4 text-white" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
