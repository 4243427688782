import React from 'react'
import { BalanceSheetIcon, ReportsIcon2, TrialBalanceIcon } from "../../../common/AppIcons"

export const reports = [
  {
    label: "Profit & Loss report",
    icon: <ReportsIcon2 className="h-5" style={{color:'#7075F1'}} />,
    icon_container_className: "bg-blue-100",
    link: "/hoa/:hoaId/accounts/profit_loss/",
  },
  {
    label: "Trial Balance Report",
    icon: <TrialBalanceIcon className="h-5" style={{color:'#720ADA'}} />,
    icon_container_className: "bg-indigo-100",
    link: "/hoa/:hoaId/trialbalance/",
  },
  {
    label: "Balance Sheet",
    icon: <BalanceSheetIcon className="h-6" style={{color:'#DDA007'}} />,
    icon_container_className: "bg-orange-100",
  },
];
