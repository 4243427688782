import { useEffect, useState } from "react";

import UserRow from "./UserRow";
import ProfileBase from "../ProfileBase";
import AddUserPopup from "./AddUserPopup";
import Popup from "../../../common/Popup";
import {
  LoadingCircleIcon2,
  PlusInCircleIcon2,
} from "../../../common/AppIcons";
import { getOrgMembers, postOrgMembersBulk } from "../../../../api/ApiV2";
import { toast } from "react-toastify";

export default function ProfileAssetUsers({ asset }) {
  const [addUserPopup, setAddUserPopup] = useState();
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (!asset) return;
    setLoading(true);
    getMembers();
  }, [asset]);

  const getMembers = () =>
    getOrgMembers({ id: asset })
      .then((res) => {
        setMembers(res.results);
        setLoading(false);
      })
      .catch((res) => {
        toast.error(res.message ?? "Error fetching members");
        setLoading(false);
      });

  const submitUsers = () => {
    setLoading(true);
    postOrgMembersBulk({
      id: asset,
      data: {
        members: members.filter((val) => !val.user?.id),
      },
    })
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully posted members details");
        getMembers();
      })
      .catch((res) => {
        setLoading(false);
        toast.error(res.message ?? "Error posting members details");
        console.log(res);

        setMembers((members) => [
          ...members.filter((val) => val.id),
          ...members
            .filter((val) => !val?.id)
            .map((val1, index) => {
              return {
                ...val1,
                warnings: {
                  ...(res[index]?.type ? { type: res[index]?.type } : {}),
                  ...(res[index]?.user ?? {}),
                },
              };
            }),
        ]);
      });
  };
  return (
    <ProfileBase page={`/profile/${asset}/users`}>
      {loading ? (
        <div className="w-full p-12 flex flex-col items-center justify-center rounded-lg bg-white">
          <LoadingCircleIcon2 className="h-10" />
        </div>
      ) : (
        <div className="w-full p-12 flex flex-col items-center rounded-lg bg-white">
          <div className="max-w-2xl w-full flex flex-col">
            <span className="text-xl font-semibold">Users</span>
            <span className="mt-1 font-light text-gray-600">
              Manage all the users under this org
            </span>
            <div className="w-full mt-4 flex flex-col gap-3">
              {members?.map((user, userIndex) => (
                <UserRow
                  error={
                    Object.keys(user.warnings ?? {})?.length ? true : false
                  }
                  key={userIndex}
                  user={user}
                  index={userIndex}
                  openPopup={setAddUserPopup}
                  setClientData={setMembers}
                />
              ))}
              <div>
                <button
                  className="mt-3 text-sm text-gray-500 hover:opacity-70 flex flex-row items-center gap-2"
                  onClick={() => {
                    setAddUserPopup({});
                  }}
                >
                  <PlusInCircleIcon2 className="text-green-500 h-2.5" />
                  Add New User
                </button>
              </div>
            </div>
          </div>
          {members.find((val) => !val.id) && (
            <div className="w-full mt-10 px-10 flex flex-row justify-end items-center gap-3">
              <button
                style={{
                  backgroundColor: "#2A32FD",
                  border: "2px solid #2A32FD",
                }}
                className="text-sm text-white hover:opacity-80 h-10 w-44 flex items-center justify-center rounded"
                onClick={() => {
                  submitUsers();
                }}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      )}
      <Popup show={addUserPopup ? true : false} setShow={setAddUserPopup}>
        {addUserPopup && (
          <AddUserPopup
            {...{
              readonly: addUserPopup.id ? true : false,
              data: addUserPopup,
              memberIndex: addUserPopup?.index,
              warnings: addUserPopup?.warnings,
              setUserData: setMembers,
              closePopup: () => {
                setAddUserPopup();
              },
            }}
          />
        )}
      </Popup>
    </ProfileBase>
  );
}
