import { Link } from "raviger";
import { ThumbsUpIcon2 } from "../../../common/AppIcons";

export default function UserAdded({ nextPage }) {
  return (
    <div className="max-w-5xl w-full mt-10 bg-white rounded-md flex flex-col justify-center gap-5 py-28 items-center">
      <ThumbsUpIcon2 className="h-36" />
      <div className="flex flex-col text-center items-center gap-2">
        <span className="text-lg font-semibold text-gray-700">
          Congrats, your account is ready
        </span>

        <Link
          href={"/home"}
          style={{
            backgroundColor: "#2A32FD",
            border: "2px solid #2A32FD",
          }}
          className="mt-3 text-xs text-white hover:opacity-80 px-20 py-3 rounded-md"
        >
          Done
        </Link>
        <button
          onClick={nextPage}
          style={{
            color: "#2A32FD",
          }}
          className="text-xs text-white hover:opacity-60 px-7 py-3 rounded"
        >
          Create Your First Property
        </button>
      </div>
    </div>
  );
}
