import { state } from "../../../../common/Data";

const defaultClassName =
  "sm:w-full px-3 py-2 sm:tracking-wide text-gray-700 rounded focus:outline-none bg-gray-100 focus:bg-gray-200";

const addressDisplay = (address) => {
  let real_address = address.Address;

  if (!real_address) return null;
  return (
    (real_address.Address1 ? real_address.Address1 + ", " : "") +
    (real_address.Locality || real_address.SubAdministrativeArea
      ? (real_address.Locality || real_address.SubAdministrativeArea) + ",  "
      : "") +
    (real_address.AdministrativeArea ?? "") +
    (real_address.PostalCodePrimary ? " " + real_address.PostalCodePrimary : "")
  );
};

export const formData = ({
  companyDetail,
  setClientData,
  warnings,
  delete_warning,
  address_hints,
  set_address_hints,
  address_hint_loading,
  set_last_address_keyword,
  cityRef,
  orgs,
}) => [
  {
    label: "Org Name",
    value: companyDetail.name,
    warning: warnings["name"],
    containerClassName: "sm:col-span-2",
    placeholder: "Enter Organisation name",
    className: defaultClassName,
    onChange: (val) => {
      delete_warning("name");

      setClientData({
        ...companyDetail,
        name: val,
      });
    },
  },
  {
    label: "Org Type",
    value: companyDetail.type,
    type: "dropdown",
    options: [
      {
        label: "Manager at a PMC",
        value: "PMC",
      },
      {
        label: "Landlord",
        value: "LANDLORD",
      },
    ],
    readOnly: true,
    containerClassName: "sm:col-span-2",
    placeholder: "Select",
    warning: warnings["type"],
    className: defaultClassName,
    onChange: (val) => {
      delete_warning("type");

      setClientData({
        ...companyDetail,
        type: val,
      });
    },
  },
  {
    label: "Official Billing Address",
    warning: warnings["street_address_1"],
    className: defaultClassName,
    containerClassName: "sm:col-span-2",
    placeholder: "Enter Address 1",
    value: companyDetail.street_address_1,
    onChange: (val) => {
      delete_warning("street_address_1");

      setClientData({
        ...companyDetail,
        street_address_1: val,
      });
    },
    hint: {
      loading: address_hint_loading,
      hints: address_hints?.map((address) => {
        return { value: address.Address, label: addressDisplay(address) };
      }),
      onClick: (val) => {
        delete_warning("street_address_1");
        delete_warning("city");
        delete_warning("state");
        delete_warning("zipCode");

        let real_address = val;
        setClientData({
          ...companyDetail,
          street_address_1: real_address?.Address1,
          city: real_address.Locality || real_address?.SubAdministrativeArea,
          state: real_address?.AdministrativeArea,
          zipcode: real_address?.PostalCodePrimary,
          geocodeLatitude: real_address?.Latitude,
          geocodeLongitude: real_address?.Longitude,
          melissa_base_mak: real_address?.BaseMAK
            ? real_address?.BaseMAK
            : real_address?.MAK,
          units: real_address?.BaseMAK
            ? {
                units: real_address?.SubBuilding.split(",").filter(
                  (val) => val !== ""
                ),
                maks: real_address?.MAK.split(",").filter((val) => val !== ""),
              }
            : null,
        });

        set_address_hints();
        set_last_address_keyword();

        setTimeout(() => {
          cityRef?.current?.focus();
        }, 300);
      },
    },
  },
  {
    label: "Suite",
    value: companyDetail.street_address_2,
    type: companyDetail?.units?.units?.length > 0 ? "dropdown" : null,
    options: companyDetail?.units?.units?.map((val) => ({
      label: val,
      value: val,
    })),
    warning: warnings["street_address_2"],
    className: defaultClassName,
    placeholder: "Suite or Address 2",
    ref: cityRef,
    onChange: (val) => {
      delete_warning("street_address_2");

      setClientData({
        ...companyDetail,
        street_address_2: val,
      });
    },
  },
  {
    label: "City",
    value: companyDetail.city,
    warning: warnings["city"],
    className: defaultClassName,
    placeholder: "City",
    ref: cityRef,
    onChange: (val) => {
      delete_warning("city");

      setClientData({
        ...companyDetail,
        city: val,
      });
    },
  },
  {
    label: "State",
    value: companyDetail.state,
    type: "dropdown",
    options: state,
    readOnly: true,
    placeholder: "State",
    warning: warnings["state"],
    className: defaultClassName,
    onChange: (val) => {
      delete_warning("state");

      setClientData({
        ...companyDetail,
        state: val,
      });
    },
  },
  {
    label: "Zip",
    value: companyDetail.zipcode,
    data: "zipcode",
    placeholder: "Zip",
    warning: warnings["zipcode"],
    className: defaultClassName,
    onChange: (val) => {
      delete_warning("zipcode");

      if (Number(val) || Number(val) === 0) {
        if (val.length < 6)
          setClientData({
            ...companyDetail,
            zipcode: val,
          });
      }
    },
  },
  {
    label: "Primary Email Address",
    value: companyDetail.email,

    warning: warnings["email"],
    className: defaultClassName,
    placeholder: "Email",
    onChange: (val) => {
      delete_warning("email");

      setClientData({
        ...companyDetail,
        email: val,
      });
    },
  },
  {
    label: "Phone",
    value: companyDetail.phone,
    warning: warnings["phone"],
    className: defaultClassName + " pl-12 py-3 font-normal",
    placeholder: "Phone",
    type: "phone",
    onChange: (val) => {
      delete_warning("phone");

      setClientData({
        ...companyDetail,
        phone: val,
      });
    },
  },
  {
    label: "Company Website",
    value: companyDetail.company_website,
    warning: warnings["company_website"],
    className: defaultClassName,
    containerClassName: "sm:col-span-2",
    placeholder: "Enter website address",
    onChange: (val) => {
      delete_warning("company_website");

      setClientData({
        ...companyDetail,
        company_website: val,
      });
    },
  },
  {
    hidden: companyDetail.type !== "LANDLORD",
    label: "Managed by",
    value: companyDetail.managed_by,
    warning: warnings["managed_by"],
    containerClassName: "sm:col-span-2",
    placeholder: "Select an organization to manage this property",
    className: defaultClassName,
    type: "dropdown",
    options: orgs?.map((val) => ({ label: val.name, value: val.id })),
    onChange: (val) => {
      delete_warning("managed_by");

      setClientData({
        ...companyDetail,
        managed_by: val,
      });
    },
  },
];
