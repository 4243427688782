import { useEffect, useRef, useState } from "react";

import Input from "../../../common/Form/Input";
import { LeftArrowIcon, LoadingCircleIcon2 } from "../../../common/AppIcons";
import { findState } from "../../../../utils/findState";
import { getMelissaAddress, patchHoa } from "../../../../api/ApiV2";
import { useDebounce } from "use-debounce";
import { state } from "../../../common/Data";
import { toast } from "react-toastify";

const defaultClassName = "px-3 py-2 rounded bg-gray-100 focus:bg-gray-200";
export default function AssetProperty({
  propertyDetails,
  closePage,
  getHoaList,
}) {
  const [details, setDetails] = useState(propertyDetails);
  const [warnings, setWarnings] = useState({});

  const [address_hints, set_address_hints] = useState([]);
  const [last_address_keyword, set_last_address_keyword] = useState("");
  const [street_address_1_debounce] = useDebounce(
    details.street_address_1,
    700
  );
  const [address_hint_loading, set_address_hint_loading] = useState(false);
  const cityRef = useRef(null);
  const [loading, set_loading] = useState(false);

  const delete_warning = (item) => {
    let temp_warning = warnings;

    delete temp_warning[item];

    setWarnings(temp_warning);
  };
  useEffect(() => {
    if (details.zipCode) {
      const newState = findState(details.zipCode);
      console.log("Setting State to:", newState);
      setTimeout(() => {
        setWarnings((warnings) => {
          return {
            ...warnings,
            zipCode: !newState ? "Invalid area zipcode" : undefined,
          };
        });
      }, 500);
      setDetails({ ...details, state: newState });
    }
  }, [details.zipCode]);

  useEffect(() => {
    if (
      details?.street_address_1?.split(" ")?.length > 0 &&
      details?.street_address_1?.charAt(
        details?.street_address_1?.length - 1
      ) === " "
    ) {
      get_address_hints(details?.street_address_1);
    }
  }, [details?.street_address_1]);

  useEffect(() => {
    if (
      details?.street_address_1 &&
      street_address_1_debounce === details?.street_address_1
    ) {
      get_address_hints(details?.street_address_1);
    }
  }, [details?.street_address_1, street_address_1_debounce]);

  const get_address_hints = (address) => {
    if (!address) return;

    if (address?.trim() !== "" && address?.trim() !== last_address_keyword) {
      set_last_address_keyword(address.trim());
      set_address_hint_loading(true);
      getMelissaAddress({ address })
        .then((res) => {
          console.log("address hints:", res);
          set_address_hints(
            res?.filter(
              (val) =>
                val?.Address?.SubBuilding && val?.Address?.SubBuilding !== ""
            )
          );
          set_address_hint_loading(false);
        })
        .catch((res) => {
          console.log("address hints error:" + res);
          set_address_hint_loading(false);
        });
    }
  };

  const addressDisplay = (address) => {
    let real_address = address?.Address ?? {};

    if (!real_address) return null;
    return (
      (real_address.Address1 ? real_address.Address1 + ", " : "") +
      (real_address.Locality || real_address.SubAdministrativeArea
        ? (real_address.Locality || real_address.SubAdministrativeArea) + ",  "
        : "") +
      (real_address.AdministrativeArea ?? "") +
      (real_address.PostalCodePrimary
        ? " " + real_address.PostalCodePrimary
        : "")
    );
  };

  const onSubmit = () => {
    set_loading(true);
    patchHoa({
      external_id: propertyDetails.id,
      data: details,
    })
      .then((res) => {
        set_loading(false);
        console.log(res);
        toast.success("Property Updated Successfully");
        getHoaList();
      })
      .catch((res) => {
        set_loading(false);
        console.log(res);
        toast.error("Error Updating Property");
        setWarnings(res);
      });
  };

  return (
    <div className="mx-auto max-w-4xl w-full p-12 pb-24 flex flex-col items-center rounded-lg bg-white">
      <div className="max-w-2xl w-full flex flex-col">
        <div className="flex flex-row items-center gap-2">
          <button
            onClick={closePage}
            className="text-gray-700 transform hover:scale-110"
          >
            <LeftArrowIcon className="h-5" />
          </button>
          <span className="text-2xl font-semibold">Edit Property Detials</span>
        </div>

        <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 gap-6">
          {console.log(details.name)}
          {[
            {
              label: "Property Name",
              value: "name",
              warning: warnings["name"],
              className: defaultClassName,
              containerClassName: "sm:col-span-3",
              placeholder: "Property Name",
              onChange: (val) => {
                delete_warning("name");

                setDetails({
                  ...details,
                  name: val,
                });
              },
            },
            {
              label: "Official Billing Address",
              warning: warnings["street_address_1"],
              className: defaultClassName,
              containerClassName: "sm:col-span-3",
              placeholder: "Enter Address 1",
              value: "street_address_1",
              onChange: (val) => {
                delete_warning("street_address_1");

                setDetails({
                  ...details,
                  street_address_1: val,
                });
              },
              hint: {
                loading: address_hint_loading,
                hints: address_hints?.map((address) => {
                  return {
                    value: address.Address,
                    label: addressDisplay(address),
                  };
                }),
                onClick: (val) => {
                  delete_warning("street_address_1");
                  delete_warning("city");
                  delete_warning("state");
                  delete_warning("zipcode");

                  let real_address = val;
                  setDetails({
                    ...details,
                    street_address_1: real_address?.Address1,
                    city:
                      real_address.Locality ||
                      real_address?.SubAdministrativeArea,
                    state: real_address?.AdministrativeArea,
                    zipcode: real_address?.PostalCodePrimary,
                    geocodeLatitude: real_address?.Latitude,
                    geocodeLongitude: real_address?.Longitude,
                    melissa_base_mak:
                      real_address?.BaseMAK ?? real_address?.MAK,
                    units: real_address?.BaseMAK
                      ? {
                          units: real_address?.SubBuilding.split(",").filter(
                            (val) => val !== ""
                          ),
                          maks: real_address?.MAK.split(",").filter(
                            (val) => val !== ""
                          ),
                        }
                      : null,
                  });

                  set_address_hints();
                  set_last_address_keyword();

                  setTimeout(() => {
                    cityRef?.current?.focus();
                  }, 300);
                },
              },
            },

            {
              label: "City",
              value: "city",
              warning: warnings["city"],
              className: defaultClassName,
              ref: cityRef,
              placeholder: "City",
              onChange: (val) => {
                delete_warning("city");

                setDetails({
                  ...details,
                  city: val,
                });
              },
            },
            {
              label: "State",
              value: "state",
              type: "dropdown",
              options: state,
              readOnly: true,
              placeholder: "State",
              warning: warnings["state"],
              className: defaultClassName,
              boxClassName: "max-h-40",
              onChange: (val) => {
                delete_warning("state");

                setDetails({
                  ...details,
                  state: val,
                });
              },
            },
            {
              label: "Zip",
              value: "zipcode",
              placeholder: "Zip",
              warning: warnings["zipcode"],
              className: defaultClassName,
              onChange: (val) => {
                delete_warning("zipcode");

                if (Number(val) || Number(val) === 0) {
                  if (val?.length < 6)
                    setDetails({
                      ...details,
                      zipcode: val,
                    });
                }
              },
            },
          ].map((ele, ele_index) => (
            <div
              key={ele_index}
              className={"flex flex-col " + ele.containerClassName}
            >
              <label className="text-sm">{ele.label}</label>
              <Input
                {...{ ele }}
                boxClassName={ele.boxClassName}
                options={ele.options}
                hint={ele.hint}
                className={
                  "w-full bg-gray-100 hover:bg-gray-200 rounded px-2.5 py-2 " +
                  ele.className
                }
                type={ele.type}
                value={details[ele.value]}
                onChange={(val) =>
                  setDetails((details) => ({ ...details, [ele.value]: val }))
                }
              />
            </div>
          ))}
        </div>
        <div className="mt-8 flex flex-row gap-3 justify-end">
          <button
            onClick={closePage}
            className="bg-white text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 py-1.5 px-6 rounded"
          >
            Go Back
          </button>
          <button
            onClick={() => {
              onSubmit();
            }}
            style={{ backgroundColor: "#2A32FD", border: "1px solid #2A32FD" }}
            className="text-sm hover:opacity-80 text-white border px-10 sm:px-16 h-10 rounded"
          >
            {loading ? <LoadingCircleIcon2 className="h-4" /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}
