import {
  minuteTo24Time,
  minuteToTime,
} from "../../reservations/utility/minuteToTime";

export const time_array = [...Array(49)].map((ele, index) => {
  return {
    label: minuteToTime(index * 30),
    label2: minuteTo24Time(index * 30),
    value: index * 30,
  };
});

export const minutes_to_time_string = (minutes) => {
  return time_array.find((val) => val.value === minutes)?.label ?? "";
};

export const time_string_to_minutes = (time_string) => {
  return (
    time_array.find((val) => val.label === time_string)?.value ??
    time_array.find((val) => val.label2 === time_string)?.value ??
    0
  );
};
