import React from "react";
import Input from "../../../../../../common/Form/Input";

export default function FormInput({ label, ...props }) {
  return (
    <div className={"w-full mb-2 md:mb-1 md:flex items-center"}>
      <label className="w-32 text-gray-700 font-semibold text-sm tracking-wide whitespace-pre flex flex-row items-center">
        {label}
        <span className="hidden md:block">:</span>
      </label>
      <Input {...props} />
    </div>
  );
}
