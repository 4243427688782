import { Link } from "raviger";
import { socialInfo } from "../../../data/socialInfo";

import "../css/buttonAnimation.css";

export default function Footer({ className }) {
  return (
    <div style={{ backgroundColor: "#F5F5F5", color: "#667085" }}>
      <div className="max-w-6xl mx-auto w-full mt-8 pt-10 flex flex-col items-center gap-4">
        <div className="w-full flex flex-row items-start justify-between gap-2">
          <div className="flex flex-col gap-3 max-w-sm">
            Not in California? Doorz is coming to your city soon. Sign up to get
            notified
            <div className="flex flex-row">
              <input
                className="px-3 py-2.5 rounded-l-lg focus:outline-none border border-white focus:border-indigo-700"
                placeholder="example@gmail.com"
              />
              <button
                style={{ backgroundColor: "#2A32FD" }}
                className="text-white px-3 py-2.5 text-sm rounded-r-lg hover:opacity-80"
              >
                Get Notified
              </button>
            </div>
          </div>
          <div className="flex flex-col">
            <span>Email:</span>
            <a
              href="mailto:info@doorz.ai"
              className="hover:underline hover:text-blue-600"
            >
              info@doorz.ai
            </a>
            <span className="mt-4">Phone:</span>
            <a
              href="tel:3104968782"
              className="hover:underline hover:text-blue-600"
            >
              310 496 8782
            </a>
            <div className="mt-8 flex space-x-10">
              {socialInfo.map((item, index) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-600 hover:text-indigo-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-5 w-5" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
        </div>
        <span className="mb-3 text-sm text-gray-400">
          © 2023 Doorz. All rights reserved.
        </span>
      </div>
    </div>
  );
}
