import React, { useState } from "react";
import { CrossIcon, CrossInCircleIcon } from "../../../common/AppIcons";

export default function SubmitARPopup({ view, set_view, on_submit }) {
  return (
    <div
      className={
        "z-20 fixed inset-0 max-h-full flex items-center justify-center bg-opacity-20 bg-black " +
        (view ? "" : "hidden")
      }
      onClick={set_view}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="max-w-lg w-full pl-6 pr-3 pb-6 pt-3 rounded-md bg-white flex flex-col"
      >
        <div className="w-full flex justify-end">
          <div
            onClick={set_view}
            className="cursor-pointer text-red-500 hover:opacity-80"
          >
            <CrossInCircleIcon className="h-6" />
          </div>
        </div>

        <span className="text-lg font-bold" style={{ color: "#2A32FD" }}>
          Submit plan to architectural committee
        </span>

        <div className="my-3 flex flex-col gap-3 italic text-gray-600 text-sm">
          <span>
            Thank you for submitting the architecture plan. We will be reviewing
            it shortly. Please be advised that upon submission of the plan, you
            will no longer will be able to modify it in any way.
          </span>
          <span>
            You are about to submit this plan to the committee to begin the
            review process. Please make sure your plan is complete as you will
            not be able to make change to it while it is being reviewed.
          </span>
        </div>

        <div className="mt-4 px-3 flex flex-row justify-end w-full gap-2">
          <button
            onClick={set_view}
            className="bg-white hover:bg-gray-200 font-semibold py-1 px-6 border-2 border-gray-500 text-gray-600 rounded shadow-sm mr-2"
          >
            Cancel
          </button>
          <button
            onClick={on_submit}
            style={{ backgroundColor: "#2A32FD" }}
            className={
              "text-white font-semibold py-1 px-6 flex items-center justify-center rounded shadow-sm hover:opacity-80"
            }
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
