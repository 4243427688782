import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { getNotices, PostNotice } from "../../../../api/ApiV2";
import { LoadingDots, PlusIcon2 } from "../../../common/AppIcons";
import { category } from "./data/category";
import NewMessagePopup from "./components/NewMessagePopup";
import MessagePopup from "./components/MessagePopup";
import MessageCard from "./components/Messagecard";
import Paginator from "../../../../common/Paginator";

export default function NoticeBoard({ hoaId }) {
  const [notices, set_notices] = useState([]);
  const [notice_popup, set_notice_popup] = useState();
  const [loading, set_loading] = useState(true);
  const [new_notice, set_new_notice] = useState({
    popup: false,
    title: "",
    description: "",
    category: "",
  });
  const [page, setPage] = useState({
    offset: 0,
    totalCount: 0,
  });

  useEffect(() => {
    get_notices();
  }, [page.offset]);

  const get_notices = () => {
    getNotices({ hoaId, offset: page.offset })
      .then((res) => {
        setPage((page) => ({ ...page, totalCount: res.count }));
        console.log(res);
        set_notices(res.results);
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
      });
  };

  const onSubmit = () => {
    PostNotice({ hoaId, data: new_notice })
      .then((res) => {
        console.log(res);
        get_notices();
        set_new_notice({
          popup: false,
          title: "",
          description: "",
          category: "",
        });
      })
      .catch((res) => {
        console.log(res);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error adding message"
        );
      });
  };

  return (
    <div
      className="min-w-full flex justify-between max-h-full"
      onClick={() => console.log(notices)}
    >
      {loading ? (
        <LoadingDots className="m-auto" />
      ) : (
        <div className="flex flex-col w-full">
          <div className="flex justify-end w-full p-4 pb-3">
            <button
              className="px-3 py-1.5 text-sm whitespace-pre rounded hover:opacity-70 flex flex-row gap-2 items-center"
              style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
              onClick={() => set_new_notice({ ...new_notice, popup: true })}
            >
              <PlusIcon2 style={{ height: "20px" }} />
              Add new
            </button>
          </div>
          <div className="mt-7 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 gap-y-6 px-5 w-full">
            {category.map((value) => (
              <div
                style={{ backgroundColor: "#F5F6FF" }}
                className="flex flex-col gap-1 cursor-default px-2 py-4 rounded-md"
              >
                <h3 className="text-gray-600 text-sm mb-2">
                  {value.label +
                    " (" +
                    (notices.filter(
                      (message) => message.category === value.value
                    ).length ?? 0) +
                    ")"}
                </h3>
                {notices
                  .filter((message) => message.category === value.value)
                  .map((message, message_index) => (
                    <MessageCard
                      key={message_index}
                      {...{ message, set_notice_popup }}
                    />
                  ))}
              </div>
            ))}
          </div>
          <div className="w-full mt-5">
            <Paginator
              currentPage={page.offset / 30 + 1}
              totalPage={page.totalCount}
              entryPerPage={30}
              onChange={(pageNo) => {
                setPage({ ...page, offset: (pageNo - 1) * 30 });
              }}
            />
          </div>
        </div>
      )}
      <MessagePopup
        {...{ hoaId, get_notices, notice_popup, set_notice_popup }}
      />

      {/* new notice creation */}
      <NewMessagePopup {...{ new_notice, set_new_notice, onSubmit }} />
    </div>
  );
}
