import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import TransactionTables from "./components/TransactionTables";
import { CrossIcon2, LoadingCircleIcon } from "../../../../../common/AppIcons";
import Input from "../../../../../common/Form/Input";
import { accountingInputClassName } from "../../../../common/data/inputClassName";
import Summary from "./components/Summary";
import LoadingScreen from "../../../../../common/LoadingScreen";
import {
  getAllTransactions,
  updateReconcileTransactions,
} from "../../../../../../api/ApiV2";
import { date_format } from "../../../../../../utils/dateFormat";

const period = new Date();

const countChecked = (bankTransactions, segment, reconcilingAccount) => {
  console.log(bankTransactions);
  return bankTransactions?.reduce((sum, transaction) => {
    return (sum += transaction.checked
      ? segment === "all"
        ? 1
        : segment === "payments" &&
          parseInt(transaction.computed_transaction_amount) >= 0
        ? 1
        : segment === "deposits" &&
          parseInt(transaction.computed_transaction_amount) < 0
        ? 1
        : 0
      : 0);
  }, 0);
};
const arraySum = (bankTransactions, segment, reconcilingAccount) => {
  return bankTransactions.reduce((sum, transaction) => {
    return (sum += transaction.checked
      ? segment === "all"
        ? Number(transaction.computed_transaction_amount) > 0
          ? Number(transaction.computed_transaction_amount)
          : -1 * Number(transaction.computed_transaction_amount)
        : segment === "payments" &&
          Number(transaction.computed_transaction_amount) > 0
        ? Number(transaction.computed_transaction_amount)
        : segment === "deposits" &&
          Number(transaction.computed_transaction_amount) < 0
        ? -1 * Number(transaction.computed_transaction_amount)
        : 0
      : 0);
  }, 0);
};

export default function Reconcile({ hoaId, onComplete, baseData }) {
  const [data, setData] = useState({
    hideTransactions: false,
    highlightMarked: true,
    modify: false,
    beginningBalance: 0,
    serviceCharge: 0,
    intrestEarned: 0,
    endingBalance: 0,
  });
  const [bankTransactions, setBankTransactions] = useState([]);
  const [loading, set_loading] = useState(false);
  const [submit_loading, set_submit_loading] = useState(false);

  useEffect(() => {
    set_loading(true);
    getAllTransactions({
      to_reconcile: true,
      hoaId,
      limit: 1000,
      accountId: baseData.id,
    })
      .then((data) => {
        console.log("response", data);
        set_loading(false);
        setBankTransactions(
          data.results.map((transaction) => ({
            ...transaction,
            checked: false,
          }))
        );
      })
      .catch((data) => {
        set_loading(false);
        console.log("response error data", data);
      });
  }, [baseData]);

  const checkedAll = (value) => {
    setBankTransactions((bankTransactions) =>
      bankTransactions.map((transaction) => ({
        ...transaction,
        checked: value,
      }))
    );
  };

  const checkTransaction = (transactionId) => {
    console.log("Checking Transaction:", transactionId);
    setBankTransactions((bankTransactions) =>
      bankTransactions.map((transaction, localIndex) =>
        transaction.id === transactionId
          ? { ...transaction, checked: !transaction.checked }
          : transaction
      )
    );
  };

  const onSubmit = () => {
    if (
      !bankTransactions?.filter((bankTransaction) => bankTransaction.checked)
        .length > 0
    ) {
      toast.warning("No transactions selected");
      return;
    }
    set_submit_loading(true);
    updateReconcileTransactions({
      accountId: baseData.id,
      data: {
        transactions: bankTransactions
          ?.filter((bankTransaction) => bankTransaction.checked)
          .map((val) => val.id),
      },
      hoaId,
    })
      .then((response) => {
        set_submit_loading(false);
        console.log(response);
        onComplete();
      })
      .catch((res) => {
        toast.error(res?.message ?? "Error while reconciling transactions");
        set_submit_loading(false);
        console.log(res);
      });
  };
  return (
    <div className="flex flex-col gap-2 px-4 md:px-10 py-4 md:py-6 text-sm md:text-base md:font-medium bg-white rounded">
      <LoadingScreen show={submit_loading} />
      <div className="w-full flex justify-end">
        <button
          onClick={onComplete}
          className="p-1 rounded-full bg-red-200 text-red-600 hover:bg-red-300 hover:text-red-700"
        >
          <CrossIcon2 className="h-4 w-4" />
        </button>
      </div>
      <div className="w-full flex flex-row items-center gap-4">
        <span className="text-gray-600 text-sm">For period : </span>
        <Input
          type="date"
          value={period}
          className={accountingInputClassName + " sm:w-60"}
          onChange={() => {}}
          disabled
        />
      </div>
      <div className="mt-2 flex flex-row gap-y-4 flex-wrap items-center justify-between w-full">
        <div className="flex flex-row items-center gap-1">
          <input
            type="checkbox"
            className="focus:ring-0"
            checked={data.hideTransactions}
            onChange={() => {
              setData({ ...data, hideTransactions: !data.hideTransactions });
            }}
          />
          <span className="text-sm text-gray-600">
            Hide transactions after the statement's end date
          </span>
        </div>
        <div className="flex flex-row flex-wrap items-center gap-3">
          {[
            {
              label: "Mark All",
              onClick: () => {
                checkedAll(true);
              },
            },
            {
              label: "Unmark All",
              onClick: () => {
                checkedAll(false);
              },
            },
            {
              label: "Go To",
              onClick: () => {},
            },
            {
              label: "Matched",
              onClick: () => {},
            },
            {
              label: "Columns to Display",
              onClick: () => {},
            },
          ].map((value, index) => (
            <button
              key={index}
              class="bg-white hover:bg-gray-100 text-gray-600 font-semibold py-1 px-4 border border-gray-500 text-sm rounded shadow-sm"
              onClick={value.onClick}
            >
              {value.label}
            </button>
          ))}
        </div>
      </div>
      <div className="w-full py-4 flex flex-col gap-2">
        {loading ? (
          <div className="w-full py-5 flex items-center justify-center">
            <LoadingCircleIcon className="h-8 text-blue-500" />
          </div>
        ) : (
          <div className="lg:grid lg:grid-cols-2 gap-1">
            <TransactionTables
              label="Checks & Payments"
              tableTitles={["DATE", "CHK#", "DESCRIPTION", "AMOUNT"]}
              tableContent={(bankTransaction, leg) => [
                date_format(bankTransaction.created_date),
                bankTransaction.chk,
                bankTransaction.description,
                bankTransaction.computed_transaction_amount,
              ]}
              bankTransactions={bankTransactions}
              type="payments"
              highlightMarked={data.highlightMarked}
              tableColClass="grid-cols-9"
              tableRowClassName={[
                "col-span-2",
                "col-span-2",
                "col-span-3",
                "col-span-2",
              ]}
              onCheck={checkTransaction}
            />
            <TransactionTables
              label="Deposits & Other Credits"
              tableTitles={["Date", "CHK#", "DESCRIPTION", "TYPE", "AMOUNT"]}
              tableContent={(bankTransaction, leg) => [
                date_format(bankTransaction.created_date),
                bankTransaction.chk,
                bankTransaction.description,
                bankTransaction.type,
                bankTransaction.computed_transaction_amount,
              ]}
              bankTransactions={bankTransactions}
              type="deposites"
              highlightMarked={data.highlightMarked}
              tableColClass="grid-cols-6"
              tableRowClassName={["", "", "col-span-2", "", ""]}
              onCheck={checkTransaction}
            />
          </div>
        )}
      </div>
      <div className="mt-2 w-full flex flex-col md:flex-row gap-y-5">
        <div className="md:w-1/2 w-full my-3 md:my-0">
          <div className="flex flex-row items-center gap-1">
            <input
              type="checkbox"
              className="focus:ring-0"
              checked={data.highlightMarked}
              onChange={() => {
                setData({ ...data, highlightMarked: !data.highlightMarked });
              }}
            />

            <span className="text-sm text-gray-600">Highlight Marked</span>
          </div>
          <div className="mt-6 md:mt-8 lg:mt-12 mb-4 w-full flex flex-row font-semibold text-gray-700">
            <span>Beginning Balance : </span>
            <div className="flex-grow">
              <span className="flex flex-row-reverse">
                ${baseData.lastReconciledBalance.toFixed(2)}
              </span>
            </div>
          </div>
          <span className="font-semibold text-gray-700">
            Items you have marked cleared
          </span>
          <div className="mt-1 ml-5 grid grid-cols-7 text-sm">
            <span className="text-gray-600 col-span-4">
              {countChecked(bankTransactions, "deposits", baseData.id)} Deposits
              and Other Credits
            </span>
            <span className="text-gray-600">:</span>
            <span className="col-span-2 flex flex-row-reverse text-gray-700 font-semibold">
              ${arraySum(bankTransactions, "deposits", baseData.id).toFixed(2)}
            </span>
          </div>
          <div className="ml-5 text-sm grid grid-cols-7">
            <span className="text-gray-600 col-span-4">
              {countChecked(bankTransactions, "payments", baseData.id)} Checks
              and Payments
            </span>
            <span className="text-gray-600">:</span>
            <span className="col-span-2 flex flex-row-reverse text-gray-700 font-semibold">
              ${arraySum(bankTransactions, "payments", baseData.id).toFixed(2)}
            </span>
          </div>
        </div>
        <div className="flex-grow flex md:flex-row-reverse w-full">
          <Summary
            data={[
              {
                label: "Service Charge",
                onChange: (value) => {
                  setData({
                    ...data,
                    serviceCharge: value,
                  });
                },
                value: data.serviceCharge,
              },
              {
                label: "Intrest Earned",
                onChange: (value) => {
                  setData({
                    ...data,
                    intrestEarned: value,
                  });
                },
                value: data.intrestEarned,
              },
              {
                label: "Ending Balance",
                onChange: (value) => {
                  setData({
                    ...data,
                    endingBalance: value,
                  });
                },
                value: data.endingBalance,
              },
              {
                label: "Cleared Balance",
                value:
                  baseData.lastReconciledBalance +
                  arraySum(bankTransactions, "deposits", baseData.id).toFixed(
                    2
                  ) -
                  arraySum(bankTransactions, "payments", baseData.id).toFixed(
                    2
                  ),
              },
              {
                label: "Difference",
                value:
                  data.endingBalance -
                  (baseData.lastReconciledBalance +
                    arraySum(bankTransactions, "deposits", baseData.id).toFixed(
                      2
                    ) -
                    arraySum(bankTransactions, "payments", baseData.id).toFixed(
                      2
                    )),
              },
            ]}
          />
        </div>
      </div>
      <div className="w-full flex flex-row justify-end gap-4 mt-8 md:pb-3">
        <button
          class="bg-white hover:text-gray-400 duration-300 text-gray-700 font-semibold py-2 px-4 rounded"
          onClick={() => {
            onComplete();
          }}
        >
          Leave
        </button>
        <button
          style={{ backgroundColor: "#2A32FD" }}
          className="hover:opacity-70 duration-300 text-white rounded px-5 py-2 font-semibold border border-gray-400"
          onClick={() => {
            onSubmit();
          }}
        >
          Reconcile Now
        </button>
      </div>
    </div>
  );
}
