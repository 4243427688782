import React from "react";
import { dateStringFormat } from "../../../utils/DateUtils";

export const timeDifference = (comment_time, days_limit_is_month) => {
  if (!comment_time) return "";
  const today = new Date();
  const endDate = new Date(comment_time);
  const days = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60 * 24));
  const hours = parseInt((Math.abs(endDate - today) / (1000 * 60 * 60)) % 24);
  const minutes = parseInt(
    (Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60)) % 60
  );
  const seconds = parseInt(
    (Math.abs(endDate.getTime() - today.getTime()) / 1000) % 60
  );

  if (days_limit_is_month && days > 0 && days < 30) {
    return days + " Days ago";
  } else if (days > 0 || hours > 12)
    return (
      (days_limit_is_month
        ? ""
        : new Date(comment_time).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })) +
      " " +
      dateStringFormat(comment_time)
    );
  else if (hours > 0) return hours + " hour ago";
  else if (minutes > 0) return minutes + " min ago";
  else return seconds + " second ago";
};
