import React from "react";
import { toast } from "react-toastify";

import TextInput from "../../../../common/Form/TextInput";

export default function DescriptionForm({
  data,
  setData,
  set_form,
  submit_data,
}) {
  return (
    <div className="mt-16 mb-2 max-w-3xl mx-auto">
      <div className="mt-12 font-semibold text-gray-800">
        How would you describe your problem in 3-4 words?
      </div>
      <TextInput
        className="rounded bg-gray-200 border-none focus:outline-none focus:border-0 w-full px-3 py-2"
        type="text"
        value={data.title ?? ""}
        onChange={(e) =>
          setData((data) => {
            return { ...data, title: e };
          })
        }
      />
      <div className="mt-12 font-semibold text-gray-800">
        Please share any additional details you would like to share!
      </div>
      <TextInput
        name="description"
        className="rounded bg-gray-200 border-none focus:outline-none focus:border-0 w-full px-3 py-2"
        value={data.description ?? ""}
        onChange={(value) => setData({ ...data, description: value })}
        area
      />

      <div className="w-full flex justify-center flex-row gap-6 mt-16">
        <button
          onClick={() => {
            set_form("RECURRING_FORM");
          }}
          style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
          className="px-4 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-70"
        >
          Go back
        </button>
        <button
          onClick={() => {
            if (!data.title || data.title === "")
              toast.warning(
                "Please describe your problem above before proceeding"
              );
            else if (!data.description || data.description === "")
              toast.warning(
                "Please write some additional details above before proceeding"
              );
            else submit_data();
          }}
          style={{ backgroundColor: "#2A32FD" }}
          className="px-5 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-80 text-white"
        >
          Place Service Request
        </button>
      </div>
    </div>
  );
}
