export const dateString = (date) => {
  if (!date) return null;
  let dateObj = new Date(date);
  const offset = dateObj.getTimezoneOffset();
  dateObj = new Date(dateObj.getTime() - offset * 60 * 1000);
  return dateObj.toISOString().split("T")[0];
};

export const dateStringFormat = (date) => {
  if (!date) return "";

  return (
    format_numer(new Date(date).getMonth() + 1) +
    "-" +
    format_numer(new Date(date).getDate()) +
    "-" +
    new Date(date).getFullYear()
  );
};

const format_numer = (number) => ("0" + number).slice(-2);
