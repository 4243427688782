import React, { useEffect } from "react";

import { getBaseData } from "../../../../../../utils/DOMUtils";
import { PlusInCircleIcon } from "../../../../../common/AppIcons";
import DocumentLine from "./DocumentLine";

export default function DocumentList({
  hoaId,
  documents,
  documentType,
  setUploadDocument,
  setCreateDocument,
}) {
  return (
    <div className="w-full py-9">
      <div className="w-full flex flex-row items-end justify-between gap-5 flex-wrap mb-5">
        <span className="font-semibold text-lg text-gray-800">
          {documentType.label}
        </span>
        <button
          onClick={() =>
            setCreateDocument({ documentType: documentType.value, name: "" })
          }
          className="text-sm flex flex-row items-center gap-3 text-gray-600 hover:text-gray-700 border border-gray-400 hover:border-gray-700 font-semibold px-4 py-2 rounded"
        >
          <PlusInCircleIcon className="h-4" /> Create Document
        </button>
      </div>
      <div className="flex flex-col gap-2">
        {documents
          ?.filter((doc) => doc.document_type === documentType.value)
          ?.map((document, document_index) => (
            <DocumentLine
              key={document_index}
              {...{ hoaId, document, setUploadDocument }}
            />
          ))}
      </div>
    </div>
  );
}
