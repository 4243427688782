import React from "react";

import { amountFormat } from "../../../../../../common/AmountFormat";
import { accountingInputClassName } from "../../../../../common/data/inputClassName";

const default_className = accountingInputClassName + " w-64";

export const inputFieldData = ({
  data,
  setData,
  delete_warning,
  warning,
  setSelectAccount,
  accounts,
  selectAccount,
  set_new_account_popup,
  readOnly,
}) => {
  const warning_text = (name) => warning && warning[name] && warning[name][0];
  return [
    {
      label: "Cheque Number",
      type: "text",
      value: data?.cheque_number,
      warning: warning_text("cheque_number"),
      readOnly: readOnly ? true : false,
      onChange: (value) => {
        delete_warning("cheque_number");
        setData({ ...data, cheque_number: value });
      },
      className: default_className + " h-10",
    },
    {
      label: "Payee",
      type: "text",
      value: data?.payee_name ?? data?.merchant_name,
      warning: warning_text("payee_name"),
      readOnly: readOnly ? true : false,
      onChange: (value) => {
        delete_warning("payee_name");
        setData({ ...data, payee_name: value });
      },
      className: default_className + " h-10",
    },
    {
      label: "Bank Account",
      type: "dropdown",
      readOnly: readOnly ? true : false,
      value: readOnly
        ? data.legs?.find((val) => Number(val.amount) <= 0)?.account_object?.id
        : selectAccount,
      placeholder: readOnly ? "No account Found" : "Select account",
      warning: warning_text("check_bank_account"),
      onChange: (value) => {
        if (value === "OPEN_NEW_ACCOUNT_POPUP") {
          set_new_account_popup(true);
          return;
        }

        delete_warning("check_bank_account");
        setSelectAccount(value);
      },
      options: [
        {
          word: "Create New Account",
          label: "Create New Account",
          value: "OPEN_NEW_ACCOUNT_POPUP",
        },
        ...(accounts?.map((account) => {
          return {
            word: account.name,
            label: readOnly ? (
              account.name
            ) : (
              <div className="flex flex-row w-full">
                <h1 className="truncate flex-grow">{account.name}</h1>
                <h1 className="text-right whitespace-nowrap">
                  ${amountFormat(account.current_amount)}
                </h1>
              </div>
            ),
            value: account.id,
          };
        }) ?? []),
      ],
      className: default_className + " px-3 py-2",
      wrapperClassName: "w-64",
    },

    {
      label: "Due Date",
      type: "date",
      value: data.due_date,
      placeholder: "eg. 17 Mar, 2020",
      warning: warning_text("date"),
      onChange: (val) => {
        delete_warning("date");
        setData({ ...data, due_date: val });
      },
      readOnly: readOnly ? true : false,
      className: default_className,
    },
  ];
};
