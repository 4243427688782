import { useState } from "react";

import { postAccount } from "../../../api/ApiV2";
import { account_type } from "../../../common/constants";
import { LoadingCircleIcon } from "../../common/AppIcons";
import PopupHeading from "../../common/PopupHeading";

export default function NewAccountPopup({
  hoaId,
  closePopup,
  account_status,
  allowedAccounts,
}) {
  const [data, setData] = useState({
    code: "1",
    account: {
      name: "",
      type: null,
      is_bank_account: false,
      ...account_status,
    },
  });
  const [loading, setLoading] = useState(false);
  const [warning, set_warning] = useState({});

  const delete_warning = (item) => {
    let temp_warning = warning;

    delete temp_warning[item];

    set_warning(temp_warning);
  };

  const onSubmit = () => {
    if (Object.keys(warning)?.length === 0 && !loading) {
      setLoading(true);
      set_warning({});
      postAccount({ hoaId, data: data.account })
        .then((res) => {
          console.log(res);
          setLoading(false);
          closePopup();
        })
        .catch((res) => {
          if (typeof res !== "string") set_warning(res);
          console.log("res", res, typeof res);
          setLoading(false);
        });
    }
  };

  return (
    <div className="bg-white px-7 pt-5 pb-7 flex flex-col gap-3 rounded-lg shadow-lg">
      <PopupHeading
        closePopup={() => {
          closePopup();
        }}
      >
        Create New Account
      </PopupHeading>

      <div className="flex flex-col gap-px mt-3">
        <div className="mx-1 flex flex-row items-center justify-between">
          <span className="font-semibold text-gray-700 text-sm ml-1">Name</span>
          <span className="text-xs text-red-500">
            {warning["name"] && warning["name"][0]}
          </span>
        </div>
        <input
          value={data.account.name}
          placeholder="Enter Account Name"
          style={{ marginTop: "4px" }}
          onChange={(e) => {
            delete_warning("name");
            setData({
              ...data,
              account: { ...data.account, name: e.target.value },
            });
          }}
          className="w-full rounded-md focus:outline-none focus:border-indigo-500 px-3 py-2 text-gray-800 bg-gray-100 focus:bg-gray-200"
        />
      </div>

      <div className="flex flex-col gap-px">
        <div className="mx-1 flex flex-row items-center justify-between">
          <span className="font-semibold text-gray-700 text-sm ml-1">Type</span>
          <span className="text-xs text-red-500">
            {warning["type"] && warning["type"][0]}
          </span>
        </div>
        <select
          value={data.account.type}
          onChange={(e) => {
            delete_warning("type");
            setData({
              ...data,
              account: { ...data.account, type: e.target.value },
            });
          }}
          style={{ marginTop: "4px" }}
          className={
            "w-full rounded-md focus:outline-none border-none focus:ring-0 px-3 py-2 bg-gray-100 focus:bg-gray-200 focus:border-none " +
            (data.account.type ? "text-gray-800" : "text-gray-500")
          }
        >
          <option className="text-gray-500" selected hidden>
            Select Type
          </option>
          {account_type
            .filter(
              (val) => !allowedAccounts || allowedAccounts.includes(val.value)
            )
            .map((value, index) => (
              <option
                className="text-gray-800 bg-white font-sans"
                value={value.value}
                key={index}
              >
                {value.label}
              </option>
            ))}
        </select>
      </div>

      <div className="flex flex-col gap-1 mt-2">
        <div className="mx-1 flex flex-row items-center justify-between">
          <span className="text-xs text-red-500">
            {warning["is_bank_account"] && warning["is_bank_account"][0]}
          </span>
        </div>

        {data?.account?.type === "ASSET" && (
          <div className="flex flex-row items-start gap-2">
            <input
              type="checkbox"
              checked={data.account.is_bank_account}
              onChange={(e) => {
                delete_warning("is_bank_account");
                setData({
                  ...data,
                  account: {
                    ...data.account,
                    is_bank_account: !data.account.is_bank_account,
                  },
                });
              }}
              className="focus:outline-none border bg-gray-100 focus:bg-gray-200 border-gray-500 focus:border-indigo-500 text-gray-800 mt-1"
            />
            <span className="text-xs text-gray-700">
              Is this a bank account. This implies we can import bank statements
              into it.
            </span>
          </div>
        )}
      </div>
      <div className="w-full flex flex-row-reverse mt-2">
        <div className="flex flex-row gap-3">
          <button
            className="focus:outline-none font-medium border-2 border-gray-500 text-gray-600 px-7 py-2 rounded hover:opacity-60 duration-200"
            onClick={() => {
              closePopup();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onSubmit();
            }}
            className={
              "w-40 py-2 text-white rounded font-medium flex items-center justify-center duration-200 " +
              (Object.keys(warning)?.length === 0
                ? "hover:opacity-70"
                : "opacity-60")
            }
            style={{ backgroundColor: "#2A32FD" }}
          >
            {loading ? (
              <LoadingCircleIcon className="h-4 text-white" />
            ) : (
              "Create account"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
