import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { fetchPlaidTransactions } from "../../../../api/Api";
import {
  LoadingCircleIcon,
  PlusIcon,
  RightArrowIcon,
} from "../../../common/AppIcons";
import Modal from "../../../common/Modal";
import BankFeed from "./components/BankFeed";
import Reconcile from "./components/reconcile";
import PlaidDataMissingPopup from "../../components/PlaidDataMissingPopup";
import NewAccountPopup from "../../components/NewAccountPopup";
import BalanceCard from "./components/BalanceCard";
import Popup from "../../../common/Popup";
import BalanceTable from "./components/BalanceTable";
import {
  getAccounts,
  getPlaidBanking,
  getUnmatchedTransactions,
  linkPlaidAccount,
} from "../../../../api/ApiV2";

export default function Balances({ hoaId, closePage = null }) {
  const [accounts, set_accounts] = useState();
  const [plaidData, set_plaidData] = useState(false);
  const [plaidLinkFor, startPlaidLink] = useState();
  const [sync, setSync] = useState();
  const [account, setAccount] = useState();
  const [no_plaid_data_popup, set_no_plaid_data_popup] = useState(false);
  const [new_account_popup, set_new_account_popup] = useState(false);
  const [loading, set_loading] = useState(false);
  const [popup_loading, set_popup_loading] = useState(false);

  useEffect(() => {
    set_loading(true);
    get_bank_accounts();
  }, []);

  const get_bank_accounts = () => {
    getAccounts({ hoaId, bank_account: true })
      .then((res) => {
        console.log(res);
        set_accounts(res?.results);
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(res?.readableMessage ?? res?.message ?? "Error");
      });

    getPlaidBanking({ hoaId })
      .then((res) => {
        console.log(res);
        res?.link_token ? set_plaidData(res) : set_no_plaid_data_popup(true);
      })
      .catch((res) => {
        console.log(res);
        toast.error(res?.readableMessage ?? res?.message ?? "Error");
      });
  };
  const get_plaid_transactions = (account) => {
    set_popup_loading(true);
    setSync();
    getUnmatchedTransactions({ hoaId, accountId: account.id })
      .then((transactions) => {
        set_popup_loading(false);
        setSync({
          account: account,
          transactions: transactions,
        });
      })
      .catch((res) => {
        console.log(res);
        set_popup_loading(false);
        toast.error(res?.readableMessage ?? res?.message ?? "Error");
        setSync({
          account: account,
          transactions: [
            {
              date: new Date().toDateString(),
              description: "description",
              amount: 10,
            },
          ],
        });
      });
  };

  const onSuccess = (token, metadata) => {
    startPlaidLink((plaidLinkFor) => {
      linkPlaidAccount({
        hoaId,
        data: {
          public_token: token,
          account_id: metadata.account.id,
          account_object: metadata.account,
          doorz_account_id: plaidLinkFor,
        },
      })
        .then((_) => {
          window.location?.reload();
        })
        .then((res) => {
          console.log(res);
          toast.error(res?.readableMessage ?? res?.message ?? "Error");
        });
      return undefined;
    });
  };

  return (
    <div className="mt-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pb-6">
        <div className="mt-2 flex flex-row items-center gap-x-6 gap-y-4 flex-wrap">
          <BalanceCard
            label="Account Balance"
            balance={accounts?.reduce(
              (balance, account) =>
                balance + Number(account.simple_balance ?? 0),
              0
            )}
          />

          <button
            onClick={() => set_new_account_popup(true)}
            className="text-gray-600 font-semibold border-2 border-gray-500 rounded"
          >
            <div className="px-9 py-7 flex flex-row items-center justify-center gap-4 h-full w-full transform duration-300 hover:scale-105">
              <PlusIcon className="h-8" /> New Bank Account
            </div>
          </button>
        </div>
      </div>

      <div className="flex flex-row items-center mt-6 mb-4 px-4 sm:px-7">
        {closePage && (
          <div
            className="cursor-pointer mr-4"
            onClick={() => {
              closePage();
            }}
          >
            <RightArrowIcon className="h-6 w-6 text-gray-700 hover:text-gray-500 transform rotate-180 translate-x-3 translate-y-px relative top-px" />
          </div>
        )}
        <h2 className="text-xl leading-6 font-semibold text-gray-900">
          Accounts
        </h2>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-8 pb-6">
        <div className="flex flex-col mt-4">
          <div className="align-middle min-w-full overflow-x-auto overflow-hidden">
            <table
              style={{ borderCollapse: "separate", borderSpacing: "0 .5em" }}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead>
                <tr>
                  <th className="px-6 py-23 bg-gray-50 text-left font-semibold text-gray-600 tracking-wider">
                    Account
                  </th>
                  <th className="px-6 py-2 bg-gray-50"></th>
                  <th className="px-6 py-2 bg-gray-50 text-right font-semibold text-gray-600 tracking-wider">
                    Balance
                  </th>
                  <th className="px-6 py-2 bg-gray-50"></th>
                </tr>
              </thead>

              {loading && (
                <LoadingCircleIcon className="mx-auto h-6 text-blue-500" />
              )}

              {plaidData !== false && (
                <BalanceTable
                  {...{
                    accounts,
                    plaidData,
                    set_no_plaid_data_popup,
                    setAccount,
                    get_plaid_transactions,
                    plaidLinkFor,
                    startPlaidLink,
                    plaidOnSuccess: onSuccess,
                  }}
                />
              )}
            </table>
          </div>
        </div>
      </div>

      <Popup show={popup_loading} setShow={() => {}}>
        <LoadingCircleIcon className="text-blue-500 h-7 mx-auto m-3" />
      </Popup>
      <Modal
        show={new_account_popup ? true : false}
        setShow={(_) => {
          set_new_account_popup();
        }}
      >
        <NewAccountPopup
          hoaId={hoaId}
          closePopup={(_) => {
            get_bank_accounts();
            set_new_account_popup();
          }}
          account_status={{
            is_bank_account: true,
            type: "ASSET",
          }}
        />
      </Modal>

      <Popup
        show={account ? true : false}
        setShow={() => {
          setAccount();
        }}
        className="sm:max-w-7xl"
      >
        {account ? (
          <Reconcile
            hoaId={hoaId}
            onComplete={() => {
              get_bank_accounts();
              setAccount();
            }}
            baseData={{ ...account, lastReconciledBalance: 0 }}
          />
        ) : (
          React.null
        )}
      </Popup>

      <Modal
        show={sync ? true : false}
        setShow={(_) => {
          setSync();
        }}
        wide
      >
        {sync ? (
          <BankFeed
            hoaId={hoaId}
            onComplete={() => setSync()}
            get_plaid_transactions={get_plaid_transactions}
            baseData={{
              title: "Sync",
              account: sync.account,
              transactions: sync.transactions?.map((transaction) => {
                return {
                  ...transaction,
                  description: transaction.name,
                };
              }),
            }}
          />
        ) : (
          React.null
        )}
      </Modal>
      <PlaidDataMissingPopup
        hoaId={hoaId}
        show={no_plaid_data_popup}
        onClose={(_) => {
          set_no_plaid_data_popup(false);
        }}
      />
    </div>
  );
}
