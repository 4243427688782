import { useState, useEffect } from "react";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { toast } from "react-toastify";
import { Link } from "raviger";

import {
  LeftArrowIcon,
  LoadingCircleIcon2,
  PaperClipIcon,
  SentIcon,
} from "../../../common/AppIcons";
import Messages from "./components/Messages";
import { ticketPrefix } from "../../../../utils/SRPrefix";
import { useRecoilState } from "recoil";
import { userAtom } from "../../../../store/UserAtom";
import SRBase from "../../srBase";
import {
  getMessages,
  getTicketDetails,
  postMessage,
} from "../../../../api/ApiV2";

const client_data = {
  online: true,
  name: "Help Squad",
  profile_pic: null,
};

export default function ChatBox({ url, hoaId, ticketId }) {
  const [user] = useRecoilState(userAtom);

  const [chats, set_chats] = useState([]);
  const [new_input_message, set_new_input_message] = useState({
    content: "",
    attachments: [],
    flag: false,
  });
  const [load_chat_params, set_load_chat_params] = useState({
    page_number: 0,
    page_size: 10,
    all_chat_loaded: false,
    loading_chat: true,
    message_senting: false,
  });
  const [flag, set_flag] = useState(false);
  const [ticketData, setTicketData] = useState();

  useEffect(() => {
    get_messages();
  }, [load_chat_params.page_number]);

  useEffect(() => {
    if (!ticketId) return;
    set_load_chat_params({
      page_number: 0,
      page_size: 10,
      all_chat_loaded: false,
      loading_chat: true,
      message_senting: false,
    });
    set_chats([]);
    setTimeout(() => {
      fetchTicketDetails();
    }, 1200);
  }, [ticketId, user.selectedUserProfile]);

  useEffect(() => {
    if (!user.external_uuid) return;

    var sock = new SockJS(process.env.REACT_APP_WEBSOCKET_URL + "/ws-sockjs");
    var client = Stomp.over(sock);
    client.connect("", "", (e) => {
      console.log("web socket connected", e);
      client.subscribe(
        "/notifications/doorz_message/" + user.external_uuid,
        (msg) => {
          console.log(
            "subscribed to /notifications/doorz_message/" + user.external_uuid,
            msg
          );
          get_messages(0);
        }
      );
    });

    client.heartbeat.outgoing = 20000;
    client.heartbeat.incoming = 0;
    return () => {
      try {
        client.disconnect((e) => console.log("disconnected", e));
      } catch {}
    };
  }, [user?.external_uuid]);

  useEffect(() => {
    if (
      !new_input_message.flag &&
      new_input_message.attachments &&
      new_input_message.attachments.length > 0
    ) {
      set_new_input_message((set_new_input_message) => {
        return { ...new_input_message, flag: true };
      });
      sent_message();
    }
  }, [new_input_message.attachments]);

  const fetchTicketDetails = () => {
    getTicketDetails({ hoaId, ticketId })
      .then((res) => {
        console.log(res);
        setTicketData(res);
        get_messages(0);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const sent_message = () => {
    set_flag(false);
    if (new_input_message.content || new_input_message.attachments) {
      set_load_chat_params({ ...load_chat_params, message_senting: true });
      postMessage({
        hoaId,
        ticketId,
        data: {
          content: new_input_message.content,
          vendor_type: "SQUADHUB",
          attachments: new_input_message.attachments,
        },
        type: user?.requestType,
      })
        .then((res) => {
          console.log({ res });
          get_messages(0);
          set_new_input_message({});
        })
        .catch((res) => {
          console.log({ res });
          toast.error("Error sending message");
          set_load_chat_params({
            ...load_chat_params,
            message_senting: false,
          });
        });
    }
  };

  const get_messages = (page) => {
    set_flag(false);
    set_load_chat_params((load_chat_params) => {
      return { ...load_chat_params, loading_chat: true };
    });
    getMessages({
      hoaId,
      ticketId,
      offset: page ?? load_chat_params.page_number,
      limit: load_chat_params.page_size,
    })
      .then((res) => {
        let arr = [...res.content];
        set_chats((chats) => {
          return [
            ...chats,
            ...arr.filter((val) => {
              return chats.find(
                (val2) => val2.communicationId === val.communicationId
              )
                ? false
                : true;
            }),
          ];
        });
        console.log({ res });
        set_load_chat_params({
          ...load_chat_params,
          loading_chat: false,
          message_senting: false,
          all_chat_loaded:
            res.totalPages === load_chat_params.page_number ? true : false,
        });
      })
      .catch((res) => {
        console.log({ res });
        toast.error("Error loading messages");
        set_load_chat_params((load_chat_params) => {
          return { ...load_chat_params, loading_chat: false };
        });
      });
  };

  return (
    <SRBase hoaId={hoaId} page={url}>
      <div className="w-full h-full min-h-screen flex flex-col">
        <div className="mt-3 mb-7 flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-1">
            <Link href={`/hoa/${hoaId}/sr/messages`}>
              <LeftArrowIcon className="h-8" />
            </Link>
            <span className="text-xl">
              Messages{" "}
              {ticketId &&
                "(Ticket " +
                  ticketPrefix({ ticketId: ticketData?.ticketId }) +
                  ")"}
            </span>
          </div>
          {ticketId && (
            <Link href={"/hoa/" + hoaId + "/ticket/" + ticketId}>
              <span className="cursor-pointer rounded-md border-2 border-indigo-700 hover:border-indigo-500 text-indigo-800 hover:text-indigo-600 px-4 py-1.5 text-sm">
                View Ticket
              </span>
            </Link>
          )}
        </div>

        <div className="bg-white rounded-lg border shadow flex flex-col flex-grow justify-between">
          {/* chat display section */}
          <div className="flex flex-grow relative overflow-hidden">
            <Messages
              client_data={client_data}
              chats={chats?.sort((obj1, obj2) => {
                return new Date(obj1.createdAt) - new Date(obj2.createdAt);
              })}
              load_chat_params={load_chat_params}
              set_chats={set_chats}
              call_next_page={() => {
                set_load_chat_params({
                  ...load_chat_params,
                  page_number: load_chat_params.page_number + 1,
                });
              }}
              flag={flag}
              set_flag={set_flag}
            />
          </div>

          {/* bottom bar */}
          <div className="w-full px-4 py-3 shadow border border-gray-100 flex flex-row items-center">
            <div
              onClick={() => document.getElementById("file_input").click()}
              className="hover:bg-zinc-100 p-2 rounded-full cursor-pointer"
            >
              <input
                type="file"
                multiple
                onChange={(e) => {
                  set_load_chat_params({
                    ...load_chat_params,
                    message_senting: true,
                  });
                  let temp_arr = [];
                  [...e.target.files].map((file, index, arr) => {
                    let reader = new FileReader();
                    reader.onloadend = function () {
                      temp_arr = [
                        ...temp_arr,
                        {
                          fileName: file.name,
                          contentType: file.type,
                          encodedFile: reader.result,
                        },
                      ];
                      if (arr.length - 1 === index) {
                        set_new_input_message({
                          ...new_input_message,
                          attachments: temp_arr,
                        });
                      }
                    };
                    reader.readAsDataURL(file);
                  });
                }}
                className="hidden"
                id="file_input"
              />

              <PaperClipIcon className="h-6 w-6 text-gray-500" />
            </div>

            <input
              onKeyDown={(e) => {
                if (e.key === "Enter") sent_message();
              }}
              value={new_input_message.content ?? ""}
              onChange={(e) =>
                set_new_input_message({
                  ...new_input_message,
                  content: e.target.value,
                })
              }
              placeholder="Type a message..."
              className="flex flex-grow text-gray-700 outline-none focus:outline-none bg-zinc-50 focus:bg-zinc-100 rounded px-2 py-1"
            />
            <div
              onClick={sent_message}
              className="hover:bg-zinc-100 p-2 rounded-full cursor-pointer"
            >
              {load_chat_params.message_senting ? (
                <LoadingCircleIcon2
                  style={{ height: "19px", width: "19px" }}
                  className="ml-2 text-blue-500"
                />
              ) : (
                <SentIcon className="h-6 w-6 text-blue-500" />
              )}
            </div>
          </div>
        </div>
      </div>
    </SRBase>
  );
}
