import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";

import AmenityCard from "./components/AmenityCard";
import { listAmenities } from "../../../../../../api/ApiV2";
import { LoadingCircleIcon2 } from "../../../../../common/AppIcons";
import Paginator from "../../../../../../common/Paginator";
import { userAtom } from "../../../../../../store/UserAtom";
import { adminTypes } from "../../../../../../common/data/adminTypes";

export default function AmenityList({
  hoaId,
  set_reservation_history,
  set_reservation_list,
}) {
  const [user] = useRecoilState(userAtom);
  const [amenities, setAmenities] = useState([]);
  const [loading, set_loading] = useState(false);
  const [page, setPage] = useState({
    offset: 0,
    totalCount: 0,
  });

  useEffect(() => {
    fetch_amenities();
  }, [page.offset]);

  const fetch_amenities = () => {
    set_loading(true);
    listAmenities({ hoaId, offset: page.offset })
      .then((res) => {
        setPage((page) => ({ ...page, totalCount: res.count }));
        setAmenities(res.results);
        console.log(res);
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res.readableMessage ?? res.message ?? "Error fetching Amenity"
        );
      });
  };

  if (loading)
    return (
      <div className="w-full py-20 flex items-center justify-center bg-white rounded">
        <LoadingCircleIcon2 className="h-10" />
      </div>
    );
  else
    return (
      <div style={{ minHeight: "200px" }} className="w-full pt-7 pb-10 px-8">
        <div className="w-full flex justify-end gap-2 flex-wrap">
          {adminTypes.includes(user.selectedUserType) && (
            <button
              className="px-3 py-1.5 text-sm whitespace-pre rounded hover:opacity-70 flex flex-row gap-2 items-center"
              style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
              onClick={() => set_reservation_list(true)}
            >
              View my bookings
            </button>
          )}
        </div>
        <div className="mt-4 w-full flex flex-col gap-5">
          {!amenities || amenities.length === 0 ? (
            <div className="mt-12 text-sm w-full text-center text-gray-500">
              No Amenity Found
            </div>
          ) : (
            <div className="w-full flex flex-row flex-wrap sm:grid grid-cols-2 md:grid-cols-3 gap-4">
              {amenities?.map((amenity, amenity_index) => (
                <AmenityCard
                  set_reservation_history={set_reservation_history}
                  key={amenity_index}
                  hoaId={hoaId}
                  className="w-60 sm:w-auto"
                  amenity={amenity}
                />
              ))}
            </div>
          )}
          <Paginator
            currentPage={page.offset / 20 + 1}
            totalPage={page.totalCount}
            entryPerPage={20}
            onChange={(pageNo) => {
              setPage({ ...page, offset: (pageNo - 1) * 20 });
            }}
          />
        </div>
      </div>
    );
}
