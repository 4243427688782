import { useEffect, useRef, useState } from "react";

import Input from "../../../../../common/Form/Input";
import PopupHeading from "../../../../../common/PopupHeading";
import { memberFormData } from "../data/memberFormData";

export default function MemberForm({
  readonly,
  unit,
  data,
  memberIndex,
  warnings,
  setHoaData,
  closePopup,
}) {
  const [warning, setWarning] = useState({});
  const [user, setUser] = useState(
    data ?? {
      user: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      unit_number: unit ?? "",
      user_type: "OWNER",
    }
  );

  useEffect(() => {
    console.log(data);
    if (warnings) {
      console.log(warnings);
      setWarning(warnings);
    }
  }, [warnings]);

  const parentRef = useRef(null);

  const add_member_not_ready = () => {
    let data = {};
    if (user?.user.first_name === "")
      data = { ...data, first_name: "Please fill this field" };
    if (user?.user.last_name === "")
      data = { ...data, last_name: "Please fill this field" };
    if (user?.user.phone === "")
      data = { ...data, phone: "Please fill this field" };
    if (
      user?.user.phone?.length < 2 ||
      (user?.user.phone?.charAt(0) === "1" && user?.user.phone?.length < 11)
    )
      data = { ...data, phone: "Invalid phone number" };
    if (user?.user.email === "")
      data = { ...data, email: "Please fill this field" };

    if (user?.unit_number === "")
      data = { ...data, unit_number: "Please fill this field" };

    if (!user?.user_type)
      data = { ...data, user_type: "Please select user type" };

    if (Object.keys(data).length === 0) return false;
    else return data;
  };

  const addHoaMember = () => {
    setWarning({});

    if (add_member_not_ready()) {
      setWarning(add_member_not_ready());
      return;
    }

    // if (user.newUser)
    const userPayload = {
      ...user,
      user: {
        ...user?.user,
        phone:
          user?.user?.phone && user?.user?.phone !== ""
            ? user?.user?.phone?.trim().charAt(0) === "+"
              ? user?.user.phone?.length < 2
                ? null
                : user?.user.phone
              : "+" + user?.user?.phone
            : null,
      },
      unit: unit.id,
    };

    setHoaData((hoaData) => {
      return {
        ...hoaData,
        members: data
          ? hoaData.members.map((val, index) =>
              index === memberIndex ? userPayload : val
            )
          : [...(hoaData.members ?? []), userPayload],
      };
    });
    setUser({
      user: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      unit_number: unit ?? "",
      user_type: "OWNER",
    });
    closePopup();
  };
  const remove_warning = (field) => {
    let tempWarning = warning;
    delete tempWarning[field];
    setWarning(tempWarning);
  };

  return (
    <div
      ref={parentRef}
      className="rounded-md bg-white mx-auto px-5 duration-200 max-w-lg overflow-y-auto sm:overflow-x-hidden"
    >
      <div className="flex items-center justify-center mb-10 sm:mb-12">
        <div className="w-full px-3">
          <h1 className="flex flex-row gap-3 justify-center text-center text-3xl font-bold my-8 sm:my-10 opacity-90">
            <PopupHeading
              textStyle={{ color: "#000000" }}
              closePopup={() => closePopup()}
            >
              {data ? "Edit Member" : "Add New Member"}
            </PopupHeading>
          </h1>

          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-x-3 gap-y-5">
              {memberFormData({
                user,
                setUser,
                warning,
                remove_warning,
              })?.map((input, input_index) => (
                <div
                  key={input_index}
                  className={
                    "flex flex-col gap-1 w-full " + input.containerClassName
                  }
                >
                  <div className="flex flex-row gap-1 justify-between">
                    <span className="whitespace-pre tracking-wide ml-1 text-sm">
                      {input.label}
                    </span>
                    <span
                      style={{ fontSize: ".65rem" }}
                      className="text-xs text-red-600 text-right relative top-1"
                    >
                      {(typeof input.warning === "string"
                        ? input.warning
                        : input.warning?.[0]) ?? ""}
                    </span>
                  </div>
                  <Input
                    {...input}
                    readonly={readonly}
                    disabled={readonly}
                    className={
                      input.className +
                      " " +
                      (input.warning ? "border-red-300" : "border-gray-300")
                    }
                    parentRef={parentRef}
                  />
                </div>
              ))}
            </div>

            <div className={"mt-10 flex flex-row gap-3 w-full justify-center"}>
              <div
                onClick={() => {
                  closePopup();
                }}
                className="w-full cursor-pointer flex items-center justify-center text-sm text-gray-600 hover:text-gray-400 border border-gray-500 hover:border-gray-400 px-4 py-2 rounded"
              >
                {readonly ? "Close" : "Cancel"}
              </div>
              {!readonly && (
                <div
                  onClick={() => {
                    addHoaMember();
                  }}
                  style={{
                    backgroundColor: "#2A32FD",
                    border: "2px solid #2A32FD",
                  }}
                  className="w-full cursor-pointer flex justify-center items-center gap-2 text-sm text-white hover:opacity-80 px-4 py-2 rounded"
                >
                  {data ? "Edit Member" : "Add Member"}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
