export const terms = [
  {
    label: "15 Days",
    value: "FIFTEEN",
    value1: 15,
  },
  {
    label: "30 Days",
    value: "THIRTY",
    value1: 30,
  },
  {
    label: "60 Days",
    value: "SIXTY",
    value1: 60,
  },
  {
    label: "90 Days",
    value: "NINETY",
    value1: 90,
  },
];

export const territory = [
  {
    label: "Los Angeles",
    value: "LOS_ANGELES",
  },
  {
    label: "San Francisco",
    value: "SAN_FRANCISCO",
  },
];

export const compliance_items = [
  {
    value: "CHECKLIST",
    label: "Checklist",
  },
  {
    value: "DRAWING",
    label: "Drawing",
  },
];

export const building_types = [
  {
    label: "SFH",
    value: "SFH",
  },
  {
    label: "High Rise Apartment",
    value: "HIGH_RISE_APARTMENT",
  },
  {
    label: "Apartment",
    value: "APARTMENT",
  },
  {
    label: "Condo",
    value: "CONDO",
  },
  {
    label: "Office",
    value: "OFFICE",
  },
];

export const state = [
  {
    label: "AL",
    value: "AL",
  },
  {
    label: "AK",
    value: "AK",
  },
  {
    label: "AZ",
    value: "AZ",
  },
  {
    label: "AR",
    value: "AR",
  },
  {
    label: "CA",
    value: "CA",
  },
  {
    label: "CO",
    value: "CO",
  },
  {
    label: "CT",
    value: "CT",
  },
  {
    label: "DE",
    value: "DE",
  },
  {
    label: "DC",
    value: "DC",
  },
  {
    label: "FL",
    value: "FL",
  },
  {
    label: "GA",
    value: "GA",
  },
  {
    label: "HI",
    value: "HI",
  },
  {
    label: "ID",
    value: "ID",
  },
  {
    label: "IL",
    value: "IL",
  },
  {
    label: "IN",
    value: "IN",
  },
  {
    label: "IA",
    value: "IA",
  },
  {
    label: "KS",
    value: "KS",
  },
  {
    label: "KY",
    value: "KY",
  },
  {
    label: "LA",
    value: "LA",
  },
  {
    label: "ME",
    value: "ME",
  },
  {
    label: "MD",
    value: "MD",
  },
  {
    label: "MA",
    value: "MA",
  },
  {
    label: "MI",
    value: "MI",
  },
  {
    label: "MN",
    value: "MN",
  },
  {
    label: "MS",
    value: "MS",
  },
  {
    label: "MO",
    value: "MO",
  },
  {
    label: "MT",
    value: "MT",
  },
  {
    label: "NE",
    value: "NE",
  },
  {
    label: "NV",
    value: "NV",
  },
  {
    label: "NH",
    value: "NH",
  },
  {
    label: "NJ",
    value: "NJ",
  },
  {
    label: "NM",
    value: "NM",
  },
  {
    label: "NY",
    value: "NY",
  },
  {
    label: "NC",
    value: "NC",
  },
  {
    label: "ND",
    value: "ND",
  },
  {
    label: "OH",
    value: "OH",
  },
  {
    label: "OK",
    value: "OK",
  },
  {
    label: "OR",
    value: "OR",
  },
  {
    label: "PA",
    value: "PA",
  },
  {
    label: "RI",
    value: "RI",
  },
  {
    label: "SC",
    value: "SC",
  },
  {
    label: "SD",
    value: "SD",
  },
  {
    label: "TN",
    value: "TN",
  },
  {
    label: "TX",
    value: "TX",
  },
  {
    label: "UT",
    value: "UT",
  },
  {
    label: "VT",
    value: "VT",
  },
  {
    label: "VA",
    value: "VA",
  },
  {
    label: "WA",
    value: "WA",
  },
  {
    label: "WV",
    value: "WV",
  },
  {
    label: "WI",
    value: "WI",
  },
  {
    label: "WY",
    value: "WY",
  },
];
