import { Redirect, useRoutes } from "raviger";

import LandingPage from "../components/landingPage";
import Login from "../components/registrationAndLogin/pages/Login";
import Register from "../components/registrationAndLogin/pages/Register";
import VerifyEmail from "../components/registrationAndLogin/pages/VerifyEmail";
import VerifiedEmail from "../components/registrationAndLogin/pages/VerifiedEmail";
import EmailVerificationFailed from "../components/registrationAndLogin/pages/EmailVerificationFailed";

const routes = {
  "/": () => <LandingPage/> ,
  "/login": () =>  <Login />,
  "/register": () => <Register />,
  "/user/verifyEmail": () => <VerifyEmail />,
  '/user/verified':()=><VerifiedEmail/>,
  '/user/unverified':()=><EmailVerificationFailed/>,
  '/*':()=><Redirect to='/login'/>
};

export default function PublicRouter() {

  let route = useRoutes(routes);
  return route || <div className='w-full py-20 text-center text-gray-700'>Not Found</div>;
}
