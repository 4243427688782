import React from "react";
import { CrossIcon } from "../../../../../common/AppIcons";

export default function Success({
  payment_method_popup,
  set_payment_method_popup,
}) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="max-w-lg max-h-screen w-full flex flex-col items-center justify-start overflow-auto px-10 pt-7 pb-10 rounded-lg bg-white"
    >
      {
        <div className="flex w-full justify-end">
          <div
            onClick={() => {
              set_payment_method_popup({
                ...payment_method_popup,
                view: false,
                popup: "",
              });
            }}
            className="p-1 h-5 w-5 cursor-pointer rounded-full border-2 border-red-500 text-red-500 hover:opacity-70 flex items-center justify-center"
          >
            <CrossIcon className="h-3 w-3" />
          </div>
        </div>
      }
      <img src="/static/img/thumbs_up.png" className="h-36 md:h-48" />
      <span className="mt-5 text-xl font-bold">Success!</span>
      <span className="mt-2 text-sm text-gray-500">
        Thank you. Your payment was successful!
      </span>
      <button
        onClick={() => {
          set_payment_method_popup({
            view: false,
          });
        }}
        className={
          "mt-9 rounded px-10 py-2 bg-indigo-100 text-indigo-500 text-sm hover:opacity-80 font-semibold flex flex-row items-center justify-center gap-4"
        }
      >
        Done
      </button>
    </div>
  );
}
