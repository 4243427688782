import { Link } from "raviger";
import React from "react";
import { People3Icon } from "../../../common/AppIcons";
import { ProfilePic } from "../../../community/pages/boardMeeting/constants";
import { userType } from "../../../../common/constants";

export const user_color = (type) => {
  if (type === "SUPER_ADMIN")
    return {
      bg1: "bg-green-600",
      bg2: "bg-green-200",
      text: "text-green-600",
    };
  else if (type === "BOARD_MEMBER")
    return {
      bg1: "bg-orange-600",
      bg2: "bg-orange-200",
      text: "text-orange-600",
    };
  else if (type === "OWNER")
    return {
      bg1: "bg-indigo-600",
      bg2: "bg-indigo-200",
      text: "text-indigo-600",
    };
  else if (type === "TENANT")
    return { bg1: "bg-red-600", bg2: "bg-red-200", text: "text-red-600" };
  else return { bg1: "bg-gray-600", bg2: "bg-gray-200", text: "text-gray-600" };
};

export default function HoaTemplate({ hoa }) {
  return (
    <Link
      href={
        hoa.setup_stage && hoa.setup_stage !== "FINISHED"
          ? `/hoa/${hoa.id}/create/${hoa.setup_stage}`
          : "/hoa/" + hoa?.id
      }
      className="hoa_card px-5 py-4 flex flex-row gap-4 items-start hover:bg-opacity-0 rounded-md border-2 border-gray-100 hover:border-indigo-500 hover:shadow-md tranform-all delay-100"
    >
      <ProfilePic
        member={{ first_name: hoa?.name }}
        className="h-12 w-12 text-2xl font-semibold shadow-none border"
      />
      <div className="flex flex-col gap-1 w-full">
        <div className="flex flex-row items-center justify-between w-full">
          <span
            style={{ color: "#2A32FD", fontSize: "1.22em" }}
            className="font-semibold"
          >
            {hoa?.name}
          </span>

          <span
            className={
              "px-2 py-1 rounded hover:opacity-90 cursor-default text-sm " +
              user_color(hoa.user_type)?.bg2 +
              " " +
              user_color(hoa.user_type)?.text
            }
          >
            {userType[hoa.user_type]?.label ?? ""}
          </span>
        </div>

        <div className="flex flex-col text-gray-700 text-sm">
          <span>
            {hoa?.street_address_1 ? hoa?.street_address_1 + "," : null}
          </span>
          <span>
            {hoa?.unit_number
              ? hoa?.street_address_1 + ","
              : hoa?.street_address_2
              ? hoa?.street_address_2 + ","
              : null}
          </span>
          <span>
            {hoa?.city ? hoa?.city + ", " : null}{" "}
            {hoa?.state ? hoa?.state : null}{" "}
            {hoa?.zipcode ? hoa?.zipcode : null}
          </span>
        </div>

        <div
          className="mt-4 flex flex-row items-center gap-3"
          style={{ color: "#E87500" }}
        >
          <People3Icon className="h-5" />
          <span>{hoa?.user_count}</span>
          <div className="w-full flex justify-end">
            {hoa.setup_stage && hoa.setup_stage !== "FINISHED" && (
              <span className="px-2 py-1 rounded bg-red-100 text-red-700 text-xs">
                Setup Unfinished
              </span>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
