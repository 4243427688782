import React from "react";
import { accountingInputClassName } from "../../../../../common/data/inputClassName";

import { amountFormat } from "../../../../../../common/utility/amountFormat";
import { amountFormat as cr_or_dr_amount } from "../../../../../../common/AmountFormat";

const fieldClassName = accountingInputClassName + " w-full py-0";

export const tableRows = ({
  value,
  index,
  onChange,
  expenseAccounts,
  warning,
  readOnly,
}) => [
  {
    label: value?.description,
    className: "col-span-2",
    input: {
      type: "textarea",
      className: fieldClassName + " px-3 py-2 h-10",
      placeholder: "Enter Description",
      warning: warning?.legs?.[index]?.description?.[0],
      value: value?.description,
      onChange: (val1) => onChange(val1, "description", index),
    },
  },
  {
    label: expenseAccounts?.filter((account) => {
      return (
        account.id === value?.account ||
        account.id === value?.account_object?.id
      );
    })[0]?.name,
    className: "col-span-2 justify-start",
    input: {
      type: "dropdown",
      warning: warning?.legs?.[index]?.account?.[0],
      className: fieldClassName,
      boxClassName: "max-h-40",
      value: value?.account ?? value?.account_object?.id,
      onChange: (value1) => {
        onChange(value1, "account", index);
      },

      options: expenseAccounts?.map((account) => {
        return {
          word: account.name,
          label: readOnly ? (
            account.name
          ) : (
            <div className="grid grid-cols-4 w-full px-1 text-xs font-light">
              <span className="col-span-3 break-all">{account.name}</span>
              <span className="break-word text-right">
                {cr_or_dr_amount(account.current_amount)}
              </span>
            </div>
          ),
          value: account.id,
        };
      }),
    },
  },
  {
    label: cr_or_dr_amount(value?.amount),
    className: "justify-end",
    input: {
      type: "currency",
      warning: warning?.legs?.[index]?.amount?.[0],
      className: fieldClassName + " px-3 py-2",
      value: amountFormat(value?.amount),
      onChange: (value1) => {
        onChange(value1, "amount", index);
      },
    },
  },
];
