// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Transition from "../tailwind/Transition.bs.js";
import * as TailwindStyling from "../../types/TailwindStyling.bs.js";

function Modal(Props) {
  var show = Props.show;
  var setShow = Props.setShow;
  var children = Props.children;
  var wideOpt = Props.wide;
  var wide = wideOpt !== undefined ? wideOpt : false;
  var containerClass =
    TailwindStyling.containerClass(show) +
    " fixed bottom-0 inset-x-0 sm:inset-0 sm:flex sm:items-center sm:justify-center overflow-auto";
  return React.createElement(Transition.make, {
    show: show,
    children: React.createElement(
      "div",
      {
        className: containerClass,
      },
      React.createElement(Transition.make, {
        enter: "ease-out duration-300",
        enterFrom: "opacity-0",
        enterTo: "opacity-100",
        leave: "ease-in duration-200",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
        children: React.createElement(
          "div",
          {
            className: "fixed inset-0 transition-opacity",
            onClick: function (param) {
              return Curry._1(setShow, false);
            },
          },
          React.createElement("div", {
            className: "absolute inset-0 bg-gray-500 opacity-75",
          })
        ),
      }),
      React.createElement(Transition.make, {
        enter: "ease-out duration-300",
        enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        enterTo: "opacity-100 translate-y-0 sm:scale-100",
        leave: "ease-in duration-200",
        leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
        leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        children: React.createElement(
          "div",
          {
            "aria-modal": true,
            "aria-labelledby": "modal-headline",
            className:
              "z-40 bg-white rounded-lg shadow-xl transform transition-all sm:w-5/6 max-h-full" +
              (wide ? " sm:max-w-6xl" : " sm:max-w-2xl"),
            role: "dialog",
          },
          children
        ),
      })
    ),
  });
}

var make = Modal;

export { make };
/* react Not a pure module */
