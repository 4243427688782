import { inputFieldData } from "./data/inputFieldData";
import FormInput from "./component/FormInput";
import CustomerDropdown from "../../../../../common/Form/CustomersDropdown";
import UnitsDropdown from "../../../../../common/Form/UnitsDropdown";
import { accountingInputClassName } from "../../../../common/data/inputClassName";

export default function RevenueDataForm({
  hoaId,
  data,
  set_data,
  members,
  className,
  readOnly,
}) {
  return (
    <div
      className={
        "w-full flex justify-start flex-row flex-wrap items-center gap-y-5 " +
        className
      }
    >
      <div className={"w-full grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-3"}>
        {inputFieldData(data, set_data, members)?.map((field, field_index) => (
          <FormInput key={field_index} {...field} />
        ))}
        <div className={"w-full mb-2 md:mb-1 md:flex items-center"}>
          <label className="w-32 text-gray-700 font-semibold text-sm tracking-wide whitespace-pre flex flex-row items-center">
            Unit
            <span className="hidden md:block">:</span>
          </label>
          <UnitsDropdown
            hoaId={hoaId}
            value={data.customer_unit ?? data.unit_id}
            onChange={(val) => set_data({ ...data, unit_id: val })}
            readOnly={readOnly}
            inputFieldClassName={accountingInputClassName + " w-64"}
          />
        </div>
      </div>
    </div>
  );
}
