import React from "react";

import Row from "../../../../../common/Form/table/Row";
import Heading from "../../../../../common/Form/table/Heading";
import { tableHeadings } from "./data/tableHeading";
import { tableRows } from "./data/tableRows";

export default function PaymentTable({ transactions, className }) {
  return (
    <div className={className}>
      <div className="font-semibold text-sm">
        <Heading
          readOnly
          headings={tableHeadings}
          style={{ backgroundColor: "#F5F5F5" }}
          className="w-full grid grid-cols-5 gap-5 px-8 py-3 rounded text-gray-500"
        />
        {transactions?.map((value, index) => {
          return (
            <Row
              key={index}
              readOnly
              row={tableRows({
                value,
              })}
              className="w-full grid grid-cols-5 gap-5 px-8 py-3 text-gray-800"
            />
          );
        })}
      </div>
    </div>
  );
}
