import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import {
  editAmenities,
  getAmenityPhoto,
  postAmenities,
  uploadAmenityPhoto,
} from "../../../../../../api/ApiV2";

import {
  EditIcon2,
  LeftArrowIcon,
  LoadingCircleIcon,
  PicIcon,
} from "../../../../../common/AppIcons";
import { amenityAvailability } from "../../../../data/amenityAvailability";
import FormElement from "./components/FormElement";
import WeeklyHours from "./components/WeeklyHours";
import { hours_array } from "../../data/hours_array";
import { time_array, time_string_to_minutes } from "../../data/time_array";

const inputClassName =
  "px-3 py-2 border-2 border-white focus:border-indigo-500 rounded outline-none focus:outline-none focus:ring-0 text-sm";
const inputStyle = { backgroundColor: "#F6F6F6", color: "#71717A" };

const find_index_hours_array = (value) => {
  let index = 0;
  hours_array.map((ele, ele_index) => {
    if (ele.value === Number(value)) {
      index = ele_index;
    }
  });
  return index;
};
export default function ReservationForm({
  hoaId,
  amenityForm,
  set_amenityForm,
}) {
  const ref = useRef(null);
  const [error, set_error] = useState();
  const [show_custom, set_show_custom] = useState(
    Object.entries(amenityForm?.data.schedules)?.find(
      ([key, value]) => value?.time_ranges?.length > 1
    )
      ? true
      : false
  );
  const [loading, set_loading] = useState(false);
  const [image, setImage] = useState();

  useEffect(() => {
    amenityForm?.id &&
      getAmenityPhoto({ hoaId, id: amenityForm?.id })
        .then((res) => {
          console.log(res);
          setImage(Object.values(res.photos)?.[0]?.url);
        })
        .catch((res) => {
          console.log(res);
          toast.error("Amenity picture fetching failed");
        });
  }, [amenityForm?.id]);

  const post_amenity = () => {
    const apiCall = amenityForm.id ? editAmenities : postAmenities;

    set_loading(true);
    const apiSuccess = (res) => {
      set_loading(false);
      console.log(res);
      set_amenityForm();
    };

    apiCall({
      hoaId: hoaId,
      id: amenityForm.id,
      data: amenityForm.data,
    })
      .then((res) => {
        console.log(res);
        if (amenityForm.picture_file) {
          uploadAmenityPhoto({
            hoaId: hoaId,
            id: res.id,
            data: { document: amenityForm.picture_file },
          })
            .then((res) => {
              console.log(res);
              apiSuccess();
            })
            .catch((res) => {
              console.log(res);
              toast.error("Photo upload failed");
              apiSuccess();
            });
        } else apiSuccess();
      })
      .catch((res) => {
        console.log(res);
        set_error(res);
        set_loading(false);
        toast.error(
          res?.name
            ? "Amenity Name : " + (res?.name?.[0] ?? "Error")
            : res?.availabilities
            ? "Available Hours : " +
              (res?.availabilities?.[0]?.availability_range?.[0] ?? "Error")
            : res?.passes
            ? "Capacity : " + (res?.passes?.[0] ?? "Error")
            : res?.readableMessage ?? res?.message ?? amenityForm.id
            ? "Error editing Amenity"
            : "Error creating Amenity"
        );
      });
  };

  const set_form = (key, value) => {
    delete_error(key);
    set_amenityForm((amenityForm) => {
      return {
        ...amenityForm,
        data: { ...amenityForm.data, [key]: value },
      };
    });
  };

  const delete_error = (val) => {
    if (!error) return;

    let temp_error = error;
    delete temp_error[val];
    set_error(temp_error);
  };

  if (loading)
    return (
      <div className="flex flex-col items-center justify-center p-6 rounded w-full bg-white py-20">
        <LoadingCircleIcon className="h-10 text-blue-500" />
      </div>
    );
  else
    return (
      <div className="mx-auto max-w-2xl flex flex-col items-start p-6">
        <span
          className="font-semibold text-xl flex flex-row items-center gap-1"
          style={{ color: "#18181B" }}
        >
          <div
            className="cursor-pointer text-gray-800 hover:text-gray-600"
            onClick={() => set_amenityForm({ show: false })}
          >
            <LeftArrowIcon className="h-5" />
          </div>
          {amenityForm.readOnly
            ? "Amenity"
            : amenityForm?.id
            ? "Edit New Amenity"
            : "Add New Amenity"}
        </span>
        <div
          style={inputStyle}
          className={
            "w-full mt-5 relative rounded " +
            (image || amenityForm.picture_url ? "" : "h-52")
          }
        >
          <input
            ref={ref}
            className="hidden"
            type="file"
            accept="image/*"
            onChange={(e) => {
              let file = e?.target?.files?.[0];
              set_amenityForm((amenityForm) => {
                return {
                  ...amenityForm,
                  picture_url: URL.createObjectURL(file),
                  picture_file: file,
                };
              });
            }}
          />
          {(image || amenityForm.picture_url) && (
            <img
              src={image || amenityForm.picture_url}
              alt=""
              className="w-full object-fit rounded"
            />
          )}
          <div
            className={
              "absolute inset-0 z-10 cursor-pointer w-full border-2 border-white hover:border-indigo-500 rounded " +
              (image || amenityForm.picture_url
                ? "text-gray-400 hover:text-gray-700"
                : "sm:px-16 flex items-center justify-center text-gray-600 hover:text-gray-700")
            }
            onClick={() => {
              if (!amenityForm.readOnly) ref?.current?.click();
            }}
          >
            {image || amenityForm.picture_url ? (
              <div className="h-full flex flex-col justify-between">
                <div className="p-1 w-full flex justify-end">
                  <button className="rounded p-2 bg-white">
                    <EditIcon2 className="h-3" style={{ color: "#2A32FD" }} />
                  </button>
                </div>
                <div className="w-full flex justify-end">
                  <div
                    style={{ backgroundColor: "#2A32FD" }}
                    className="py-px px-2 rounded-tl-md text-white text-xs"
                  >
                    Recommended resolution : 1920 &#x2717; 650
                  </div>{" "}
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center gap-2 mx-auto max-w-xs">
                <PicIcon style={{ height: "45px", width: "45px" }} />
                <span className="text-center text-sm mt-1">
                  Upload an image for amenity from your computer
                </span>
                <span className="text-center text-sm">
                  Recommended resolution : 1920 &#x2717; 650
                </span>
              </div>
            )}
          </div>
        </div>
        <FormElement label="Amenity Name" error={error?.name?.[0]}>
          <input
            className={"" + inputClassName}
            style={inputStyle}
            readOnly={amenityForm.readOnly}
            value={amenityForm.data.name}
            onChange={(e) => set_form("name", e.target.value)}
          />
        </FormElement>
        <div
          style={{ backgroundColor: "#F6F6F6" }}
          className="mt-5 rounded p-1 flex flex-row gap-1"
        >
          {amenityAvailability.map((availability, availability_index) => (
            <span
              key={availability_index}
              onClick={() => {
                if (!amenityForm.readOnly)
                  set_form("amenity_type", availability.value);
              }}
              style={
                amenityForm.data.amenity_type === availability.value
                  ? { color: "#2A32FD" }
                  : {}
              }
              className={
                "px-3 py-1.5 rounded text-sm cursor-pointer " +
                (amenityForm.data.amenity_type === availability.value
                  ? "bg-white font-semibold"
                  : "text-gray-400 hover:text-gray-500")
              }
            >
              {availability.label}
            </span>
          ))}
        </div>
        {
          <FormElement
            label="Available Hours"
            // error={
            //   error?.schedules?.find((val) => val.availability_range)
            //     ?.availability_range
            // }
          >
            <div className="flex flex-row flex-wrap items-center gap-4 sm:grid sm:grid-cols-2">
              {[
                {
                  name: "common_start_time",
                  name1: "start_time",
                  placeholder: "From",
                  array: time_array.slice(0, time_array.length - 1),
                },
                {
                  name: "common_end_time",
                  name1: "end_time",
                  placeholder: "To",
                  array: time_array
                    .slice(1, time_array.length)
                    .filter(
                      (val) =>
                        time_string_to_minutes(
                          amenityForm.data.common_start_time
                        ) < val.value
                    ),
                },
              ].map((ele, ele_index) => (
                <select
                  key={ele_index}
                  className={"" + inputClassName}
                  style={inputStyle}
                  disabled={amenityForm.readOnly}
                  value={amenityForm.data?.[ele.name]}
                  onChange={(e) => {
                    let temp_error = error;
                    delete temp_error?.availabilities;
                    set_error(temp_error);

                    let tempObj = amenityForm.data?.schedules;

                    Object.entries(tempObj).forEach(([key, value]) => {
                      if (value.time_ranges?.length === 1)
                        tempObj[key] = {
                          ...value,
                          time_ranges: value.time_ranges?.map((val) => {
                            return { ...val, [ele.name1]: e.target.value };
                          }),
                        };
                    });

                    set_amenityForm({
                      ...amenityForm,
                      data: {
                        ...amenityForm.data,
                        [ele.name]: e.target.value,
                        schedules: tempObj,
                      },
                    });
                  }}
                >
                  <option hidden defaultChecked>
                    {ele.placeholder}
                  </option>
                  {ele.array.map((ele, index) => (
                    <option key={index} value={ele.label2}>
                      {ele.label}
                    </option>
                  ))}
                </select>
              ))}
            </div>
          </FormElement>
        }
        <span
          style={{ color: "#18181B" }}
          className="pl-.5 mt-5 text-gray-600 text-sm font-semibold"
        >
          Passes:
        </span>
        <div className="w-full -mt-2 flex flex-fow flex-wrap items-center gap-4 sm:grid sm:grid-cols-2">
          {[
            {
              label: "Min hours",
              name: "minimum_minutes",
              array: hours_array.slice(0, find_index_hours_array(390)),
            },
            {
              label: "Max hours",
              name: "maximum_minutes",
              array: hours_array.slice(
                find_index_hours_array(amenityForm.data.minimum_minutes ?? 390),
                find_index_hours_array(1440)
              ),
            },
          ].map((hours, hours_index) => (
            <FormElement key={hours_index} label={hours.label}>
              <select
                className={"" + inputClassName}
                style={inputStyle}
                disabled={amenityForm.readOnly}
                value={amenityForm.data[hours.name]}
                onChange={(e) => {
                  console.log(e.target.value);
                  set_form(hours.name, e.target.value);
                }}
              >
                <option selected hidden className="text-sm">
                  Select
                </option>
                {hours.array.map((ele, ele_index) => (
                  <option key={ele_index} value={ele.value}>
                    {ele.label}
                  </option>
                ))}
              </select>
            </FormElement>
          ))}
        </div>

        <FormElement label="Available Days">
          <div className="flex flex-row items-center gap-4 flex-wrap sm:grid sm:grid-cols-7">
            {Object.entries(amenityForm.data.schedules).map(
              ([week, object], week_index) => (
                <div
                  key={week_index}
                  style={inputStyle}
                  className="sm:w-full p-4 flex flex-col gap-2 items-center justify-center rounded text-sm"
                >
                  <input
                    type="checkbox"
                    className=""
                    checked={object?.is_available}
                    onClick={() => {
                      if (amenityForm.readOnly) return;
                      set_amenityForm({
                        ...amenityForm,
                        data: {
                          ...amenityForm.data,
                          schedules: {
                            ...amenityForm.data?.schedules,
                            [week]: {
                              ...(object ?? {}),
                              time_ranges: [],
                              is_available: object.is_available ? false : true,
                            },
                          },
                        },
                      });
                    }}
                  />
                  <span className="text-gray-600 capitalize">
                    {week?.slice(0, 3)?.toUpperCase()}
                  </span>
                </div>
              )
            )}
          </div>
        </FormElement>
        <WeeklyHours
          {...{
            show_custom,
            set_show_custom,
            amenityForm,
            set_amenityForm,
            inputClassName,
            inputStyle,
            error,
            set_error,
          }}
        />
        <div className="w-full h-px bg-gray-500 mt-6" />
        <span
          className="mt-8 font-semibold text-xl"
          style={{ color: "#18181B" }}
        >
          Rules & Limits
        </span>
        <FormElement label="Capacity" error={error?.max_capacity}>
          <select
            className={"" + inputClassName}
            style={inputStyle}
            value={Number(amenityForm.data?.max_capacity)}
            disabled={amenityForm.readOnly}
            onChange={(e) => set_form("max_capacity", e.target?.value)}
          >
            <option hidden defaultChecked>
              Select capacity
            </option>
            {[...Array(50)].map((ele, index) => (
              <option value={index + 1}>Max {index + 1} passes</option>
            ))}
          </select>
        </FormElement>
        <FormElement label="Additional Rules or Info">
          <textarea
            className={"" + inputClassName}
            style={inputStyle}
            readOnly={amenityForm.readOnly}
            value={amenityForm.data.rules}
            onChange={(e) => {
              set_form("rules", e.target.value);
            }}
          />
        </FormElement>
        <span
          style={{ color: "#18181B" }}
          className="pl-.5 mt-5 text-gray-600 font-semibold text-sm"
        >
          Per apartment limit:
        </span>
        <div className="w-full -mt-2 flex flex-fow flex-wrap items-center gap-x-4 sm:grid sm:grid-cols-2">
          {[
            {
              label: "At once",
              name: "at_once_limit",
              array: hours_array.slice(0, find_index_hours_array(600)),
            },
            {
              label: "Per day limit",
              name: "per_day_limit",
              array: hours_array.slice(
                find_index_hours_array(amenityForm.data.at_once_limit ?? 0),
                find_index_hours_array(1440)
              ),
            },
            {
              label: "Per week limit",
              name: "per_week_limit",
              array: hours_array.slice(
                find_index_hours_array(amenityForm.data.per_day_limit ?? 0),
                hours_array.length
              ),
            },
            {
              label: "Per month limit",
              name: "per_month_limit",
              array: hours_array.slice(
                find_index_hours_array(amenityForm.data.per_week_limit ?? 0),
                hours_array.length
              ),
            },
          ].map((hours, hours_index) => (
            <FormElement key={hours_index} label={hours.label}>
              <select
                className={"" + inputClassName}
                style={inputStyle}
                disabled={amenityForm.readOnly}
                value={amenityForm.data[hours.name]}
                onChange={(e) => {
                  set_form(hours.name, e.target.value);
                }}
              >
                <option selected hidden className="text-sm">
                  No limit
                </option>
                {hours.array.map((ele, ele_index) => (
                  <option key={ele_index} value={ele.value}>
                    {ele.label}
                  </option>
                ))}
              </select>
            </FormElement>
          ))}
        </div>
        <span className="pl-.5 mt-5 text-gray-600 text-sm">
          Please note: Changing these limits will only apply to new passes and
          any existing passes will remain active
        </span>
        <div className="mt-8 w-full flex flex-row items-center gap-3 justify-end">
          <button
            className="text-sm text-gray-500 hover:text-gray-600 border-2 border-gray-500 hover:border-gray-600 font-semibold px-6 py-2 rounded"
            onClick={() => set_amenityForm({ show: false })}
          >
            {amenityForm.readOnly ? "Go Back" : "Cancel"}
          </button>
          {!amenityForm.readOnly && (
            <button
              onClick={() => {
                post_amenity();
              }}
              style={{
                backgroundColor: "#2A32FD",
                border: "2px solid #2A32FD",
              }}
              className="rounded px-6 py-2 text-white hover:opacity-70 text-sm"
            >
              Save Amenity
            </button>
          )}
        </div>
      </div>
    );
}
