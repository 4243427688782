import { navigate } from "raviger";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userAtom, UserState } from "../store/UserAtom";
import {
  getHoa,
  getHoaUser,
  getUser,
  login,
  memberAddressUnitsDetails,
  refreshAccessToken,
  verifyToken,
} from "../api/ApiV2";
import {
  clearLocalStorage,
  getRefreshToken,
  saveTokens,
  updateAccessToken,
} from "../utils/localStorageUtils";
import { toast } from "react-toastify";
import { userType } from "../common/constants";

/*
{
  "email": "user@example.com",
  "first_name": "string",
  "last_name": "string",
  "phone": "string"
} */
export interface UserResponse {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  external_uuid: string;
}

export interface HoaUserResponse {
  id: string;
  unit_object: {
    unit_number: string;
  };
  user_type: string;
  user: {
    external_uuid: string;
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
  };
}

export interface AuthResponse {
  access: string;
  refresh: string;
}

export const useUserActions = () => {
  const setUser = useSetRecoilState(userAtom);
  const [userState] = useRecoilState(userAtom);
  const logout = () => {
    clearLocalStorage();
    setUser({ variant: "loggedOut" });
  };
  const refresh = () => {
    if (userState.variant === "user" || userState.variant === "loading") {
      const refreshToken = getRefreshToken();
      if (!refreshToken) {
        logout();
        return;
      }
      refreshAccessToken({
        token: refreshToken || "",
      })
        .then((res) => {
          updateAccessToken(res.access);
          setUser((user: UserState) => {
            if (user.variant === "loggedOut") {
              navigate("/login");
              return { variant: "loggedOut" };
            } else if (userState.variant === "user") {
              return {
                variant: "user",
                email: userState.email,
                firstName: userState.firstName,
                lastName: userState.lastName,
                accessToken: res.access,
                refreshToken: refreshToken,
                id: userState.id,
                external_uuid: userState.external_uuid,
                selectedUserProfile: userState.selectedUserProfile,
                selectedUserType: userState.selectedUserType,
                userProfiles: userState.userProfiles,
                loading: false,
              };
            } else {
              return {
                variant: "loggedIn",
                accessToken: res.access,
                refreshToken: refreshToken,
              };
            }
          });
        })
        .catch((res) => {
          toast.error(res?.readableMessage ?? res?.message ?? "Token expired");
          logout();
        });
    }
  };
  const me = () => {
    setUser((currentUser: UserState) => {
      return { ...currentUser, loading: true };
    });

    getUser()
      .then((me: UserResponse) => {
        setUser((currentUser: UserState) => {
          if (currentUser.variant === "loggedIn") {
            return {
              variant: "user",
              email: me.email,
              firstName: me.first_name,
              lastName: me.last_name,
              accessToken: currentUser.accessToken,
              refreshToken: currentUser.refreshToken,
              external_uuid: me.external_uuid,
              userProfiles: null,
              selectedUserProfile: null,
              selectedUserType: null,
              id: null,
              user_type: null,
              loading: false,
            };
          }
          return currentUser;
        });
      })
      .catch((res) => {
        toast.error(
          res?.readableMessage ??
            res?.message ??
            "Client data fetching unsuccessful"
        );
        logout();
      });
  };

  const hoaMe = (hoaId: string) => {
    setUser((user) => ({ ...user, loading: true }));

    memberAddressUnitsDetails({ hoaId })
      .then((me: [HoaUserResponse]) => {
        setUser((currentUser: UserState) => {
          if (currentUser.variant === "user") {
            const sortedProfiles = me?.sort(
              (val1, val2) =>
                Object.keys(userType).indexOf(val1.user_type) -
                Object.keys(userType).indexOf(val2.user_type)
            );
            let tempSelectedProfile = localStorage.getItem(
              "selectedUserProfile"
            );
            if (
              !tempSelectedProfile ||
              !sortedProfiles.find((val) => val.id === tempSelectedProfile)
            )
              tempSelectedProfile = sortedProfiles[0].id;

            sessionStorage.setItem("selectedUserProfile", tempSelectedProfile);

            return {
              variant: "user",
              hoaType: null,
              email: sortedProfiles[0].user.email,
              firstName: sortedProfiles[0].user.first_name,
              lastName: sortedProfiles[0].user.last_name,
              accessToken: currentUser.accessToken,
              refreshToken: currentUser.refreshToken,
              external_uuid: currentUser.external_uuid,
              selectedUserProfile: tempSelectedProfile,
              selectedUserType:
                sortedProfiles?.find((val) => val.id === tempSelectedProfile)
                  ?.user_type ?? null,
              userProfiles: sortedProfiles ?? [],
              id: sortedProfiles[0].user.id,
              loading: false,
            };
          }
          return currentUser;
        });
        setTimeout(() => {
          getHoa({ external_id: hoaId })
            .then((res) => {
              setUser((currentUser: UserState) => {
                if (currentUser.variant === "user") {
                  return {
                    ...currentUser,
                    hoaType: res.type,
                  };
                }
                return currentUser;
              });
            })
            .catch((res) => {
              toast.error(
                res?.readableMessage ??
                  res?.message ??
                  "Client data fetching unsuccessful"
              );
            });
        }, 100);
      })
      .catch((res) => {
        setUser((user) => ({ ...user, loading: false }));
        console.log(res);
        toast.error(
          res?.readableMessage ??
            res?.message ??
            "Client data fetching unsuccessful"
        );
      });
  };
  return {
    logout,
    login: (email: string, password: string) => {
      setUser((_currentUser: any) => ({ variant: "loggingIn" }));
      login(email, password)
        .then((res: AuthResponse) => {
          saveTokens(res.access, res.refresh);
          setUser((_currentUser: any) => ({
            variant: "loggedIn",
            accessToken: res.access,
            refreshToken: res.refresh,
          }));
        })
        .catch((res) => {
          setUser((_currentUser: any) => ({ variant: "loggedOut" }));
          toast.error(
            res?.readableMessage ?? res?.message ?? "Login unsuccessful"
          );
        });
    },
    refresh,
    verify: () => {
      if (userState.variant !== "loading") {
        return;
      }
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        refresh();
        return;
      }
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        logout();
        return;
      }
      verifyToken({ token: accessToken })
        .then((res) => {
          setUser((_user: UserState) => {
            return {
              variant: "loggedIn",
              accessToken: accessToken,
              refreshToken: refreshToken,
            };
          });
        })
        .catch((res) => {
          refresh();
        });
    },
    me,
    hoaMe,
  };
};
