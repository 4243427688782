import React, { useState } from "react";
import { toast } from "react-toastify";

import { enableModule } from "../../../../api/ApiV2";
import {
  CheckBoxIcon,
  LoadingCircleIcon,
  NegativeBoxIcon,
} from "../../../common/AppIcons";

export default function PermissionPage({
  hoaId,
  page,
  nextPage,
  set_module_enabled,
  get_accounts,
}) {
  const [loading, setLoading] = useState(false);

  const goto_opening_balance = () => {
    setLoading(true);
    enableModule({ external_id: hoaId, module: "ACCOUNTING" })
      .then((res) => {
        get_accounts();
        nextPage();
        setLoading(false);
        set_module_enabled((module_enabled) => {
          return { ...module_enabled, accounting: true };
        });
      })
      .catch((res) => {
        toast.error(
          res?.readableMessage ??
            res?.message ??
            "Enabling Accounting module failed"
        );

        console.log(res);
        setLoading(false);
      });
  };

  const goto_complience = () => {
    setLoading(true);
    enableModule({ external_id: hoaId, module: "COMPLIANCE" })
      .then((res) => {
        setLoading(false);
        nextPage();
        set_module_enabled((module_enabled) => {
          return { ...module_enabled, compliance: true };
        });
      })
      .catch((res) => {
        toast.error(
          res?.readableMessage ??
            res?.message ??
            "Enabling Compliance module failed"
        );

        console.log(res);
        setLoading(false);
      });
  };

  return (
    <div className="mx-auto max-w-4xl w-full h-full mt-10 px-8 pt-12 pb-16 bg-white rounded-md shadow flex flex-col items-center justify-center">
      {page === "SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE" ||
      page === "SKIPPED_COMPLIANCE" ? (
        <NegativeBoxIcon className="h-16 text-red-100" />
      ) : (
        <CheckBoxIcon className="h-16 text-green-100" />
      )}
      <h2 className="mt-6 text-lg font-bold">
        {page === "GOTO_OPENING_BALANCE"
          ? "Members Data Saved"
          : page === "SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE"
          ? "Accounting Module Skipped"
          : page === "GOTO_COMPLIANCE"
          ? "Accounting Module Enabled"
          : page === "SKIPPED_COMPLIANCE"
          ? "Compliance Module Skipped"
          : ""}
      </h2>
      <p className="mt-2 text-sm text-gray-600">
        {page === "GOTO_OPENING_BALANCE"
          ? "Click on “Continue” to enable Doorz Accounting Module"
          : page === "SKIPPED_COMPLIANCE"
          ? "Click on “Continue” to complete your Property setup."
          : "Click on “Continue” to to enable Doorz Compliance Module"}
      </p>
      <div className="mt-6 flex flex-row item-center gap-3">
        <button
          style={{
            backgroundColor: "#2A32FD",
            border: "2px solid #2A32FD",
          }}
          className="text-sm text-white hover:opacity-80 px-7 py-2 rounded"
          onClick={() => {
            if (loading) return;
            if (page === "SKIPPED_COMPLIANCE") nextPage();
            else if (page === "GOTO_OPENING_BALANCE") goto_opening_balance();
            else goto_complience();
          }}
        >
          {loading ? (
            <LoadingCircleIcon className="h-3" />
          ) : page === "GOTO_COMPLIANCE" ||
            page === "SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE" ? (
            "Continue to Compliance"
          ) : (
            "Continue"
          )}
        </button>
        {page !== "SKIPPED_COMPLIANCE" && (
          <button
            onClick={() => {
              if (loading) return;

              nextPage(true);
            }}
            className="text-sm text-gray-500 hover:text-gray-600 border-2 border-gray-500 hover:border-gray-600 font-semibold px-7 py-2 rounded"
          >
            {page === "GOTO_COMPLIANCE" ||
            page === "SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE"
              ? "Skip Compliance"
              : "Skip"}
          </button>
        )}
      </div>
    </div>
  );
}
