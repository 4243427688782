import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";

import { hoaModulesFormData } from "./data/formData";

export default function ModulesUnloackedStatus({
  hoaEnabledModules,
  set_popup_status,
}) {
  const [hoaModules, set_hoaModules] = useState({});

  useEffect(() => {
    if (hoaEnabledModules)
      set_hoaModules({
        ACCOUNTING: hoaEnabledModules?.includes("ACCOUNTING"),
        COMPLIANCE: hoaEnabledModules?.includes("COMPLIANCE"),
      });
  }, [hoaEnabledModules]);
  return (
    <div
      className={
        "my-8 sm:my-12 pt-6 flex flex-col gap-8 sm:flex-row items-start sm:items-center justify-start sm:justify-around"
      }
    >
      {hoaModulesFormData?.map((field, field_index) => (
        <div key={field_index} className="flex flex-row items-center gap-4">
          <span className="font-semibold">{field.label}</span>

          <Toggle
            checked={hoaModules[field.name]}
            disabled={!hoaEnabledModules || hoaModules[field.name]}
            icons={{
              checked: null,
              unchecked: null,
            }}
            onChange={() => {
              if (!hoaModules[field.name]) {
                set_hoaModules({
                  ...hoaModules,
                  [field.name]: true,
                });

                if (field.name === "ACCOUNTING")
                  set_popup_status("GOTO_OPENING_BALANCE");
                else if (field.name === "COMPLIANCE")
                  set_popup_status("GOTO_COMPLIANCE");
              }
            }}
          />
        </div>
      ))}
    </div>
  );
}
