import React from "react";

import { amountFormat } from "../../../common/AmountFormat";

export const accountsDropdownOptions = (baseData, removeAmount = false) => {
  let accounts = {
    AS: "Asset",
    LI: "Liability",
    IN: "Income",
    EX: "Expense",
    EQ: "Equity",
  };
  let keys = Object.keys(accounts);
  let options = [];

  for (let i = 0; i < keys.length; i++) {
    options = [
      ...options,
      {
        label: accounts[keys[i]],
        value: accounts[keys[i]]?.toUpperCase(),
        heading: true,
      },
    ];

    options = [
      ...options,
      ...baseData
        ?.filter(({ type }) => {
          return type === accounts[keys[i]].toUpperCase();
        })
        .map((account) => {
          return {
            word: account.name,
            label: (
              <div className="w-full flex flex-row gap-1 items-center justify-between px-1 text-xs font-light">
                <span className="">{account.name}</span>
                {!removeAmount && (
                  <span className="">
                    ${amountFormat(account.current_amount)}
                  </span>
                )}
              </div>
            ),
            value: account.id,
            headingKey: accounts[keys[i]].toUpperCase(),
          };
        }),
    ];
  }
  return options;
};
