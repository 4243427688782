import { useEffect, useState } from "react";
import { getUserData } from "../../../../../utils/DOMUtils";
import { amountFormat } from "../../../../common/AmountFormat";
import { FourBoxIcon, PlusIcon } from "../../../../common/AppIcons";
import Popup from "../../../../common/Popup";
import BalanceCard from "../../../common/components/BalanceCard";
import BalanceSummaryPopup from "../../../common/components/BalanceSummaryPopup";
import { getUnitsBalances } from "../../../../../api/ApiV2";

const entryPerPage = 10;

export default function TopSection({ hoaId, total, popup, set_popup }) {
  const [summary_popup, set_summary_popup] = useState(false);
  const [units, setUnits] = useState([]);
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [popupLoading, setPopupLoading] = useState(false);

  useEffect(() => {
    setPopupLoading(true);
    getUnitsBalances({
      hoaId,
      offset: page.current * entryPerPage,
      limit:
        page.total !== 0 &&
        page.current >= Math.floor(page.total / entryPerPage)
          ? page.total % entryPerPage
          : entryPerPage,
    })
      .then((res) => {
        setPopupLoading(false);
        console.log(res);
        setPage((page) => ({ ...page, total: res.count }));
        setUnits(res.results);
      })
      .catch((res) => {
        setPopupLoading(false);
        console.log(res);
      });
  }, [page.current]);

  return (
    <div className="flex flex-col sm:flex-row items-center sm:justify-between gap-6 relative z-10">
      <div className="flex flex-wrap flex-row gap-4">
        <BalanceCard
          className="pr-20"
          label={"Total Receivable"}
          orientation={true}
          balance={total}
        />
        <button
          onClick={() => set_summary_popup(true)}
          className="text-gray-600 font-semibold border-2 border-gray-500 rounded"
        >
          <div className="px-5 py-7 flex flex-row items-center gap-4 h-full w-full transform duration-300 hover:scale-105">
            <FourBoxIcon className="h-8" /> See Revenue Summary
          </div>
        </button>
      </div>
      {!(getUserData() === 3 || getUserData() === 5) && (
        <button
          onClick={() => {
            set_popup({
              ...popup,
              show: !popup.show,
              setting: "CREATE_INVOICE",
            });
          }}
          style={{ color: "#2A32FD", border: "2px solid #2A32FD" }}
          className="bg-white hover:bg-gray-200 font-semibold flex flex-row items-center gap-3 px-4 py-2 rounded"
        >
          <PlusIcon className="h-4" /> Create New Revenue
        </button>
      )}
      <Popup
        show={summary_popup}
        setShow={set_summary_popup}
        className="max-w-md rounded"
      >
        <BalanceSummaryPopup
          title="Revenue Summary"
          subTitle={["Total Receivable", amountFormat(total)]}
          data={{
            heading: ["Name", "Receivable"],

            body: units?.map((ele) => [
              ele.unit_number ?? "",
              amountFormat(ele.pending_balance),
            ]),
          }}
          onClose={() => set_summary_popup(false)}
          page={page}
          set_page={setPage}
          entryPerPage={entryPerPage}
          loading={popupLoading}
        />
      </Popup>
    </div>
  );
}
