import { useEffect, useState } from "react";
import ProfileBase from "./ProfileBase";
import Input from "../../common/Form/Input";
import { toast } from "react-toastify";
import { getUser, patchUser } from "../../../api/ApiV2";
import { LoadingCircleIcon2 } from "../../common/AppIcons";

export default function Profile({}) {
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [warnings, setWarnings] = useState({});

  const getUserData = () => {
    getUser()
      .then((res) => {
        setDetails(res);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        toast.error(res.message ?? "Error fetching client details");
      });
  };

  const postUserData = () => {
    setLoading(true);
    patchUser({ userId: details.id, data: details })
      .then((res) => {
        setDetails(res);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        toast.error(res.message ?? "Error updating client details");
        setWarnings(res);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <ProfileBase page="/profile">
      {loading ? (
        <div className="w-full p-12 flex flex-col items-center justify-center rounded-lg bg-white">
          <LoadingCircleIcon2 className="h-10" />
        </div>
      ) : (
        <div className="w-full p-12 flex flex-col items-center rounded-lg bg-white">
          <div className="max-w-2xl w-full flex flex-col">
            <span className="text-2xl font-semibold">My Profile</span>
            <div className="mt-6 flex flex-row flex-wrap items-center gap-5">
              <img
                src="https://easydrawingguides.com/wp-content/uploads/2023/08/Low-Res-Goku_goku-drawing-tutorial.png"
                className="h-28 w-28 rounded-full border"
              />
              <button className="px-3 py-2 rounded border border-gray-200 shadow hover:shadow-lg text-gray-600 hover:bg-gray-100 hover:text-gray-700">
                Upload new picture
              </button>
              <button className="px-3 py-2 rounded border border-gray-200 shadow hover:shadow-lg text-gray-600 hover:bg-gray-100 hover:text-gray-700">
                Remove
              </button>
            </div>
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-6">
              {[
                {
                  label: "First Name",
                  value: "first_name",
                  warnings: warnings?.first_name,
                },
                {
                  label: "Last Name",
                  value: "last_name",
                  warnings: warnings?.last_name,
                },
                {
                  label: "Email",
                  value: "email",
                  type: "email",
                  warnings: warnings?.email,
                },
                {
                  label: "Phone",
                  value: "phone",
                  type: "phone",
                  warnings: warnings?.phone,

                  className: "pl-12 font-medium",
                },
              ].map((ele, ele_index) => (
                <div key={ele_index} className="flex flex-col">
                  <div className="flex flex-row items-center justify-between">
                    <span className="text-sm text-gray-600">{ele.label}</span>
                    {ele.warnings && (
                      <span className="text-xs text-red-500 ml-2">
                        {ele.warnings}
                      </span>
                    )}
                  </div>
                  <Input
                    className={
                      "w-full bg-gray-100 hover:bg-gray-200 rounded px-2.5 py-2 " +
                      ele.className
                    }
                    type={ele.type}
                    value={details?.[ele.value]}
                    onChange={(val) =>
                      setDetails((details) => ({
                        ...details,
                        [ele.value]: val,
                      }))
                    }
                  />
                </div>
              ))}
            </div>
            <div className="mt-6 flex flex-row justify-end">
              <button
                onClick={postUserData}
                style={{ backgroundColor: "#2A32FD" }}
                className="text-sm hover:opacity-80 text-white border px-10 sm:px-16 py-2 rounded"
              >
                Save Profile
              </button>
            </div>
          </div>
        </div>
      )}
    </ProfileBase>
  );
}
