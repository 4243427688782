import React from 'react';
import { getCsrfToken } from '../../utils/DOMUtils';

var csrftoken = getCsrfToken();

const CSRFToken = () => {
    return (
        <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />
    );
};
export default CSRFToken;
