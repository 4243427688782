import { useEffect, useState } from "react";
import {
  AddPeopleIcon,
  BellIcon,
  BoardMeetingIcon,
  BooksIcon,
  CalenderIcon3,
  HoaIcon,
  HoaIcon2,
  HoaIcon3,
  LensIcon,
  ListIcon3,
  MoneyIcon,
  OwnerPortalIcon,
  PeopleIcon2,
  PeopleIcon4,
  TickInCircleIcon,
  WrenchIcon2,
} from "../../common/AppIcons";

const features = [
  {
    label: "HOA",
    icon: HoaIcon,
    className: "transform -translate-x-12",
    featureList: [
      [
        { icon: BellIcon, label: "Notifications" },
        { icon: AddPeopleIcon, label: "Add Members" },
        { icon: CalenderIcon3, label: "Calenders" },
      ],
      [
        { icon: BooksIcon, label: "Accounting" },
        { icon: WrenchIcon2, label: "Maintenance" },
      ],
      [
        { icon: PeopleIcon2, label: "Community" },
        { icon: CalenderIcon3, label: "Calenders" },
        { icon: MoneyIcon, label: "Payments" },
      ],
      [
        { icon: MoneyIcon, label: "Payments" },
        { icon: BoardMeetingIcon, label: "Board Meetings" },
      ],
      [
        { icon: TickInCircleIcon, label: "Compliance" },
        { icon: OwnerPortalIcon, label: "Owner Portal" },
        { icon: WrenchIcon2, label: "Maintenance" },
      ],
    ],
  },
  {
    label: "Multifamily Rental",
    icon: HoaIcon2,
    className: "transform -translate-x-32",
    featureList: [
      [
        { icon: BellIcon, label: "Notifications" },
        { icon: ListIcon3, label: "Listing Management" },
        { icon: CalenderIcon3, label: "Calenders" },
      ],
      [
        { icon: BooksIcon, label: "Accounting" },
        { icon: WrenchIcon2, label: "Maintenance(coming soon)" },
      ],
      [
        { icon: PeopleIcon2, label: "Community" },
        { icon: CalenderIcon3, label: "Calenders" },
        { icon: LensIcon, label: "Tenant Screening" },
      ],
      [
        { icon: MoneyIcon, label: "Payments Processing" },
        { icon: HoaIcon3, label: "Lease Management" },
      ],
      [
        { icon: TickInCircleIcon, label: "Compliance" },
        { icon: PeopleIcon4, label: "Tenant Portal" },
        { icon: WrenchIcon2, label: "Maintenance" },
      ],
    ],
  },
];
export default function OtherFeatures({ className }) {
  const [onHover, setOnHover] = useState();
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className={"flex flex-col items-center " + className}>
      <div
        className="px-4 py-1.5 rounded"
        style={{
          backgroundColor: "rgba(42, 50, 253, 0.07)",
          color: "#2A32FD",
          border: "1px solid #d8d8e7",
        }}
      >
        Other Features
      </div>

      <span
        style={{ fontSize: "26px", fontWeight: "800" }}
        className="mt-5 text-center px-3"
      >
        Doorz is so much more than maintenance
      </span>

      <div className="w-full mt-10 flex flex-col md:flex-row items-center justify-center gap-5">
        {features.map((feature, index) => (
          <div
            key={index}
            style={{
              border: "1px solid rgba(42, 50, 253, 0.19)",
              backgroundColor: "#FAFBFC",
              width:
                windowSize > 768
                  ? onHover === index
                    ? "590px"
                    : "400px"
                  : "fit-content",
            }}
            className={
              "px-6 duration-300 flex flex-col items-start gap-6 pt-8 pb-5 rounded-lg overflow-hidden"
            }
            onMouseEnter={() => setOnHover(index)}
            onMouseLeave={() => setOnHover(null)}
          >
            <feature.icon className="w-16 h-16" style={{ color: "#2A32FD" }} />
            <span className="font-semibold text-xl">{feature.label}</span>
            <div
              className={
                "flex flex-col items-center gap-3 " +
                (onHover === index || windowSize <= 768
                  ? "transform translate--x-20"
                  : feature.className)
              }
            >
              {feature.featureList.map((featureList, index) => (
                <div key={index} className="flex flex-row items-center gap-4">
                  {featureList.map((feature, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#F5F5F5",
                        border: "1px solid rgba(42, 50, 253, 0.5)",
                      }}
                      className="px-4 py-3 rounded-full flex flex-row items-center gap-2"
                    >
                      <feature.icon className="w-4 sm:w-5" />
                      <span className="text-sm sm:text-base text-gray-600 whitespace-pre">
                        {feature.label}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
