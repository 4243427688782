import React from "react";
import { toast } from "react-toastify";

import DatePicker from "../../../../common/Form/Datepicker";

export default function RecurringForm({ data, setData, set_form }) {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="mt-16 mb-2">
        <span className="font-semibold text-gray-800">
          {" "}
          Do you need this to be a recurring Service Request?
        </span>
        <div className="flex flex-row mt-4 items-center">
          {[
            { label: "Yes", value: true },
            {
              label: "No",
              value: false,
            },
          ].map((ele, ele_index) => (
            <div
              key={ele_index}
              className="mr-5 flex flex-row gap-2 items-center text-sm"
            >
              <input
                type="radio"
                className="focus:ring-0"
                checked={data.repeated === ele.value}
                onChange={() => {
                  setData({ ...data, repeated: ele.value });
                }}
              />
              <span>{ele.label}</span>
            </div>
          ))}
        </div>
        <div
          className={
            "mt-6 flex flex-col items-start gap-1 " +
            (data.repeated ? "" : "invisible")
          }
        >
          <span className="text-sm font-semibold text-gray-700">
            Select Date
          </span>
          <DatePicker
            className="w-full"
            showPrompt={false}
            value={
              data.repeat_on_every
                ? data.recurringIntervalSelected
                  ? data.repeat_on_every
                  : new Date().setDate(new Date(data.repeat_on_every).getDate())
                : null
            }
            onChange={(value) => {
              setData({
                ...data,
                repeat_on_every: value,
                recurringIntervalSelected: true,
              });
            }}
          />
        </div>
      </div>

      <div className="w-full flex justify-center flex-row gap-6 mt-8">
        <button
          onClick={() => {
            set_form("ADDRESS_UNIT_SELECTION");
          }}
          style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
          className="px-4 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-70"
        >
          Go back
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (data.repeated) {
              if (data.repeat_on_every) {
                // setData((data) => {
                //   return {
                //     ...data,
                //     recurring: new Date(data.repeat_on_every).getDate(),
                //   };
                // });
                set_form("DESCRIPTION_FORM");
              } else toast.warning("Please fill in date");
            } else set_form("DESCRIPTION_FORM");
          }}
          style={{ backgroundColor: "#2A32FD" }}
          className="px-5 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-80 text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
}
