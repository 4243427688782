import React from "react";
import {
  AdobeIcon,
  PrintIcon2,
  TickIcon,
} from "../../../../../common/AppIcons";
import Loading from "../../../../../common/Loading";
import PaymentError from "./components/PaymentError";

export default function BottomButtons({
  pay_button,
  invoice,
  remove_pay,
  set_payment_method_popup,
  setShow,
  data,
  set_loading,
  onSubmit,
  payButtonRef,
  readOnly,
}) {
  const show_pay_button = invoice && !remove_pay;
  return (
    <div className="w-full flex flex-row items-center justify-end gap-6">
      <div className="flex flex-row items-center gap-4">
        <PaymentError show={pay_button === "DISABLE"} />

        {invoice?.id &&
          (data.is_paid ? (
            <div className="flex flex-row items-center gap-1.5 px-4 py-1.5 rounded bg-green-100 border-2 border-green-100 text-green-600">
              <TickIcon className="h-4" />
              <span className="text-green-700">Paid</span>
            </div>
          ) : (
            <span className="text-red-600">Unpaid</span>
          ))}

        <button
          ref={payButtonRef}
          onClick={() => {
            set_payment_method_popup({
              view: true,
              popup: "PAY_OPTIONS",
            });
          }}
          className={
            "rounded bg-green-700 text-white font-medium px-7 py-1 " +
            (show_pay_button ? "" : "hidden")
          }
        >
          {pay_button === "LOADING" ? <Loading className="h-4" /> : "Pay"}
        </button>
        <button
          className="focus:outline-none font-medium border-2 border-gray-400 text-gray-600 px-7 py-1 rounded hover:opacity-60 duration-200"
          onClick={() => {
            setShow();
          }}
        >
          {readOnly ? "Close" : "Cancel"}
        </button>
        {!readOnly && (
          <button
            style={{ backgroundColor: "#2A32FD", border: "2px solid #2A32FD" }}
            className={
              "rounded text-white font-medium px-7 py-1 " +
              (data.items && data.items.length !== 0
                ? "hover:opacity-70"
                : "opacity-70")
            }
            onClick={() => {
              set_loading(true);
              onSubmit();
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}
