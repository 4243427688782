import SelectableItem from "./components/SelectableItem";
import { sidebar_buttons } from "./data/sidebarData";

export default function Sidebar({ hoaId, page }) {
  return (
    <div className="p-3 bg-white h-screen sm:h-full overflow-y-auto flex flex-col rounded">
      {/* sidebar buttons */}
      <div className="mt-1 flex flex-col gap-1">
        {sidebar_buttons.map((button, button_index) => (
          <SelectableItem
            key={button_index}
            sidebar_data={button}
            page={page}
            hoaId={hoaId}
          />
        ))}
      </div>
    </div>
  );
}
