import { useState } from "react";
import { toast } from "react-toastify";

import { DownloadIcon2, LeftArrowIcon } from "../../../../common/AppIcons";
import CsvUpload from "./components/CsvUpload";
import {
  downloadTxtFileMembers,
  downloadTxtFileUnits,
} from "./utility/DownloadCsvSample";
import UnitForm from "./components/UnitForm";
import {
  addMultipleHoaMembers,
  bulkPatchUnits,
} from "../../../../../api/ApiV2";

export default function UnitDetails({
  buildingId,
  hoaData,
  setHoaData,
  previousPage,
  nextPage,
}) {
  const [loading, setLoading] = useState(false);
  const [memberWarning, setMemberWarning] = useState([]);

  const unitsUnderBuilding = hoaData.units?.filter(
    (unit) => unit.address === buildingId
  );

  const saveUnit = () => {
    setLoading(true);
    let unitApiLoading = "LOADING";

    let membersapiLoading = "LOADING";

    const checkApiLoading = () => {
      if (unitApiLoading !== "LOADING" && membersapiLoading !== "LOADING") {
        setLoading(false);
        if (unitApiLoading !== "FAILED" && membersapiLoading !== "FAILED") {
          toast.success("Units saved successfully");
          nextPage();
        }
      }
    };

    bulkPatchUnits({
      hoaId: hoaData.hoaId,
      data: {
        updates: unitsUnderBuilding.map((val) => ({
          ...val,
          unit: val.id,
          dues: val.monthly_dues,
        })),
      },
    })
      .then((res) => {
        console.log(res);
        unitApiLoading = "SUCCESS";
        checkApiLoading();
      })
      .catch((err) => {
        toast.error(err.readableMessage ?? err.message ?? "Error saving unit");
        console.log(err);
        unitApiLoading = "FAILED";
        checkApiLoading();
        toast.error(err.readableMessage ?? err.message ?? "Error saving units");
      });

    addMultipleHoaMembers({
      hoaId: hoaData.hoaId,
      data: { members: hoaData.members?.filter((val) => !val.id) },
    })
      .then((res) => {
        console.log(res);
        membersapiLoading = "SUCCESS";
        checkApiLoading();
      })
      .catch((err) => {
        console.log(err);
        membersapiLoading = "FAILED";
        checkApiLoading();

        let tempWarnings = [];

        Array.isArray(err) &&
          err.map((warnings) => {
            let tempObject = {
              first_name: warnings?.user?.first_name,
              last_name: warnings?.user?.last_name,
              phone: warnings?.user?.phone,
              email: warnings?.user?.email,
              unit_number: warnings?.unit_number,
              user_type: warnings?.user_type,
            };
            Object.keys(tempObject).map((key) => {
              if (!tempObject[key]) {
                delete tempObject[key];
              }
            });
            tempWarnings.push(tempObject);
          });

        setMemberWarning(tempWarnings);
        toast.error(
          err.readableMessage ?? err.message ?? "Error saving members"
        );
      });
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center py-20">
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
          <span className="text-gray-700 font-semibold">Uploading...</span>
        </div>
      </div>
    );
  } else
    return (
      <div className="bg-white mt-12 py-10 px-6 max-w-4xl w-full mx-auto flex flex-col gap-4">
        <div className="mb-2 pr-8 flex flex-row items-center justify-between gap-5">
          <div className="flex flex-row items-center gap-1">
            <button onClick={previousPage}>
              <LeftArrowIcon className="h-4" />
            </button>
            <span className="font-semibold text-gray-700">
              We’ve found units in this Building
            </span>
          </div>

          <div className="flex flex-row items-center gap-3">
            <button
              className="flex flex-row items-center gap-2 text-sm bg-white text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 px-4 py-2 rounded"
              onClick={() => {
                downloadTxtFileUnits(unitsUnderBuilding);
                downloadTxtFileMembers(unitsUnderBuilding);
              }}
            >
              <DownloadIcon2 className="h-4 w-4 shrink-0" />
              Download Template Sheets
            </button>

            <CsvUpload setHoaData={setHoaData} units={unitsUnderBuilding} />
          </div>
        </div>

        <UnitForm
          {...{
            memberWarning,
            buildingId,
            hoaData,
            setHoaData,
          }}
        />

        <div className="w-full mt-4 flex flex-row justify-end items-center gap-5">
          <button
            onClick={previousPage}
            className="bg-white text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 py-2 px-6 rounded"
          >
            Go Back
          </button>

          <button
            style={{
              backgroundColor: "#2A32FD",
              border: "2px solid #2A32FD",
            }}
            className="text-sm text-white hover:opacity-80 py-2 px-6 flex items-center justify-center rounded"
            onClick={saveUnit}
          >
            Save
          </button>
        </div>
      </div>
    );
}
