import React, { Component, useState, useEffect, useRef } from "react";
import Linkify from "react-linkify";
import "./linkify.css";

import {
  ClockIcon,
  LocationIcon,
  LocationIcon2,
} from "../../../common/AppIcons";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { meeting_color } from "./constants";
import { navigate } from "raviger";
import { meeting_types } from "./constants";
import { dateStringFormat } from "../../../../utils/DateUtils";

export default function BoardMeetingList({
  hoaId,
  meeting_type_show,
  board_meetings,
}) {
  const meeting_list = ({ meeting, index_main }) => (
    <button
      onClick={() => {
        sessionStorage.setItem("meeting_type_show", meeting_type_show);
        navigate("/hoa/" + hoaId + "/community/board_meetings/" + meeting.id);
      }}
      key={index_main}
      className={
        "cursor-pointer bg-gray-100 hover:bg-white border border-gray-100 hover:border-indigo-600 hover:shadow text-gray-800 rounded px-5 flex flex-col gap-1 translate-all duration-200 overflow-hidden "
      }
    >
      <div className="w-full flex flex-col-reverse gap-4 sm:flex-row items-baseline text-xs">
        <div className="flex flex-col sm:flex-row md:items-center gap-4 md:gap-10 mb-3 md:mb-0">
          <div className="flex flex-row items-center gap-2">
            <div
              className={
                "h-2 w-2 rounded-full " +
                meeting_color(meeting.meeting_type)?.bg1
              }
            />
            <span>
              {new Date(meeting.start_time).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }) +
                " - " +
                new Date(meeting.end_time).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
            </span>
          </div>

          <span className="ml-4 md:ml-0 flex flex-row items-center gap-1.5">
            <ClockIcon className="h-3.5 text-indigo-400" />
            {dateStringFormat(meeting.start_time)}{" "}
          </span>

          <div className="flex flex-row items-center gap-1.5">
            <LocationIcon className="text-blue-500 h-4" />
            <Linkify className="text-sm whitespace-pre">
              {meeting.location}
            </Linkify>
          </div>
        </div>

        <div className="pt-4 flex flex-grow justify-end">
          <span
            className={
              "px-3 py-1 rounded hover:opacity-90 text-sm " +
              meeting_color(meeting.meeting_type)?.bg2 +
              " " +
              meeting_color(meeting.meeting_type)?.text
            }
          >
            {
              meeting_types.find(
                (value) => value.value === meeting.meeting_type
              )?.label
            }
          </span>
        </div>
      </div>
      <span className="font-bold mt-2">{meeting.name}</span>
      <span className="whitespace-pre-line break-words">
        <MarkdownPreview
          className="mt-1 mb-3 text-gray-700"
          source={meeting.description}
        />
      </span>
    </button>
  );

  return (
    <div className="mx-1 mb-4 flex flex-col gap-3 overflow-auto p-3">
      <div className={"flex flex-col px-1 pb-1"}>
        <div
          className={
            "translate-all duration-300 overflow-hidden h-full flex flex-col gap-3"
          }
        >
          {board_meetings.filter(
            (meeting) => meeting.status === meeting_type_show
          ).length === 0 ? (
            <span className="w-full text-center text-gray-500 font-semibold text-sm capitalize">
              No {meeting_type_show.toLowerCase()} Meetings
            </span>
          ) : (
            board_meetings
              .filter((meeting) => meeting.status === meeting_type_show)
              ?.sort(
                (ele1, ele2) =>
                  new Date(ele2.start_time) - new Date(ele1.start_time)
              )
              .map((meeting1, index1) => {
                return meeting_list({
                  meeting: meeting1,
                  index1,
                });
              })
          )}
        </div>
      </div>
    </div>
  );
}
