export const dateTimeRangeString = (pre_date) => {
  let date = new Date(pre_date);
  console.log(pre_date);
  // date.setMilliseconds(new Date() - new Date(new Date().toISOString().split('Z')[0]))
  return date.toISOString();
  // return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + formatTime(date.getHours()) + ':' + formatTime(date.getMinutes()) + ':00'
};

const formatTime = (str) => {
  return ("0" + str).slice(-2);
};
