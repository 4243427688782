import * as React from "react";

export default function EstimateTable({
  rows,
  rows_className,
  headings,
  doller_symbol = [5, 6, 7],
  grid,
  print_style,
  client_data,
  set_client_data,
}) {
  const rows_section = () => {
    let arr = rows ?? [];

    let ans = [];

    ans = [
      ...ans,
      {
        label: "Received",
        rows: arr?.filter((ele) => ele.estimateStatus === "SENT"),
      },
    ];
    arr = arr.filter((ele) => ele.estimateStatus !== "SENT");

    ans = [
      ...ans,
      {
        label: "Accepted",
        rows: arr?.filter((ele) => !ele.isDraft),
      },
    ];
    arr = arr.filter((ele) => ele.isDraft);

    ans = [
      ...ans,
      {
        label: "Change Order",
        rows: arr?.filter((ele) => ele.isDraft && ele.isChangeOrder),
      },
    ];
    arr = arr.filter((ele) => !ele.isDraft || !ele.isChangeOrder);

    ans = [
      ...ans,
      {
        label: "Rejected",
        rows: arr?.filter((ele) => ele.isDraft && !ele.isChangeOrder),
      },
    ];

    return ans;
  };

  if (rows.length === 0) return <div />;
  else
    return (
      <div className={"overflow-auto py-2"}>
        {rows_section()
          .filter((ele) => ele.rows?.length > 0)
          .map((section, section_index) => (
            <div
              key={section_index}
              className={
                "w-full mt-5 shadow-md border border-gray-100 rounded " +
                (print_style ? "border-gray-200" : "")
              }
            >
              <div className="px-4 w-full mt-1 pt-3 pb-4 border-b-2 border-gray-300">
                <span className="text-gray-600 font-semibold text-base">
                  {section.label}
                </span>
                {(section.label === "Received" ||
                  section.label === "Change Order") && (
                  <span className="ml-1 text-xs text-red-500">
                    (Please select the items you want to proceed with)
                  </span>
                )}
              </div>
              <div className="flex flex-row items-center w-full p-3 px-5">
                <span className="pl-12 pr-5 font-semibold text-gray-500 text-sm">
                  #
                </span>
                <div className={"flex-grow w-full grid gap-2 " + grid}>
                  {headings.map((val, index) => (
                    <span
                      key={index}
                      className={
                        "p-1 py-2 text-gray-500 text-sm " +
                        (index === 1 ? "col-span-2" : "") +
                        " " +
                        (index > 1 ? "text-right" : "")
                      }
                      style={{ fontWeight: "500" }}
                    >
                      {val}
                    </span>
                  ))}
                </div>
              </div>
              <hr />
              {section?.rows?.map((row, row_index) => {
                return (
                  <div className="w-full flex flex-col p-3 px-5 border-b border-gray-200">
                    <div className={"flex flex-row w-full items-center"}>
                      <input
                        type="checkbox"
                        readOnly={!row.isDraft}
                        className={
                          "mx-3 h-5 w-5 rounded-sm border border-gray-500 " +
                          (row.isDraft ? "" : "invisible") +
                          " " +
                          (print_style ? "invisible" : "")
                        }
                        checked={client_data.acceptedEstimateIds?.includes(
                          row.id
                        )}
                        onChange={() => {
                          let acceptedEstimateIds =
                            client_data.acceptedEstimateIds ?? [];

                          if (client_data.acceptedEstimateIds?.includes(row.id))
                            acceptedEstimateIds.splice(
                              acceptedEstimateIds.indexOf(row.id),
                              1
                            );
                          else
                            acceptedEstimateIds = [
                              ...acceptedEstimateIds,
                              row.id,
                            ];
                          console.log(row);
                          set_client_data((client_data) => {
                            return {
                              ...client_data,
                              acceptedEstimateIds: acceptedEstimateIds,
                            };
                          });
                        }}
                      />
                      <span className="w-10 break-words pl-2 mr-3 text-gray-700 text-sm">
                        {row_index + 1}
                      </span>
                      <div
                        className={
                          "w-full flex-grow grid gap-2 items-center " +
                          grid +
                          " " +
                          (section.label === "Rejected"
                            ? "opacity-40 line-through"
                            : "")
                        }
                      >
                        {row.row.map((col, col_index) => (
                          <span
                            className={
                              "p-1 py-2 break-all text-gray-700 text-sm whitespace-pre-wrap " +
                              (col_index === 1 ? "col-span-2" : "") +
                              " " +
                              (col_index > 1 ? "text-right" : "") +
                              " " +
                              (rows_className && rows_className[col_index]
                                ? row.isDraft && !row.isChangeOrder
                                  ? ""
                                  : rows_className[col_index]
                                : "")
                            }
                          >
                            {doller_symbol.includes(col_index + 1) &&
                            col &&
                            col !== ""
                              ? "$ "
                              : ""}
                            {col}
                          </span>
                        ))}
                      </div>
                    </div>
                    {row?.materials?.length > 0 && (
                      <div className="w-full flex flex-col pl-12 opacity-70">
                        <div className="text-gray-600 font-semibold text-sm pt-1 w-full">
                          Materials
                        </div>
                        {row.materials?.map((material, material_index) => (
                          <div
                            key={material_index}
                            className={"flex flex-row w-full items-center"}
                          >
                            <span className="w-10 break-words pr-2 text-gray-700 text-sm">
                              {row_index + 1}.{material_index + 1}
                            </span>
                            <div
                              className={
                                "w-full flex-grow grid gap-2 items-center " +
                                grid +
                                " " +
                                (section.label === "Rejected"
                                  ? "opacity-40 line-through"
                                  : "")
                              }
                            >
                              {[
                                material.name,
                                material.description,
                                material.rate
                                  ? Number(material.rate).toFixed(2)
                                  : "",
                                material.quantity,
                                material.amount
                                  ? Number(material.amount).toFixed(2)
                                  : "",
                                "",
                                "",
                              ].map((col, col_index) => (
                                <span
                                  className={
                                    "p-1 py-2 break-all text-gray-700 text-sm " +
                                    (col_index === 1 ? "col-span-2" : "") +
                                    " " +
                                    (col_index > 1 ? "text-right" : "") +
                                    " " +
                                    (rows_className && rows_className[col_index]
                                      ? row.isDraft && !row.isChangeOrder
                                        ? ""
                                        : rows_className[col_index]
                                      : "")
                                  }
                                >
                                  {doller_symbol.includes(col_index + 1) &&
                                  col &&
                                  col !== ""
                                    ? "$ "
                                    : ""}
                                  {col}
                                </span>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    );
}
