import {
  HOAIcon,
  IInCircleIcon2,
  PersonIcon2,
  PersonIcon3,
  SingleFamilyIcon,
} from "../../../common/AppIcons";

export default function UserRole({ clientData, setClientData, nextPage }) {
  return (
    <div className="bg-white w-full flex flex-col items-center -mt-10 rounded-t pt-28 pb-32 gap-8">
      <span className="text-lg text-gray-700 text-center">
        Which of the following best describes your role?
      </span>
      <div className="flex flex-row flex-wrap justify-center gap-4">
        {[
          {
            label: "Manager at a PMC",
            value: "PMC",
            icon: HOAIcon,
          },
          {
            label: "Landlord",
            value: "LANDLORD",
            icon: SingleFamilyIcon,
          },

          {
            label: "Board Member at an HOA",
            value: "BM_OF_HOA",
            icon: PersonIcon2,
          },

          {
            label: "Primary Residence",
            value: "PRIMARY_RESIDENCE",
            icon: PersonIcon3,
          },
        ].map((type, typeIndex) => (
          <div
            key={typeIndex}
            className="w-40 h-48 flex flex-col bg-gray-100 hover:bg-gray-200 p-5 cursor-pointer"
            onClick={() => {
              setClientData((clientData) => {
                return { ...clientData, userRole: type.value };
              });
            }}
          >
            <div className="flex justify-end">
              <input
                type="radio"
                checked={clientData.userRole === type.value}
                className="h-4 w-4 bg-gray-100"
              />
            </div>
            <div className="mt-5 flex-grow w-full flex flex-col sm:grid sm:grid-rows-2 gap-5 items-center justify-center">
              <type.icon className="h-10 w-10 mx-auto" />

              <span className="text-sm text-center">{type.label}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-row items-center gap-2 p-2 rounded text-xs bg-gray-100">
        <IInCircleIcon2 className="h-4" />
        If you’re a resident at an HOA, you need to reach out to your HOA Board
        to get onboarded to Doorz
      </div>{" "}
      <button
        onClick={() => {
          if (clientData.userRole) nextPage();
        }}
        style={{ backgroundColor: "#2A32FD" }}
        className="text-sm hover:opacity-80 text-white border px-10 sm:px-16 py-2 rounded"
      >
        Continue
      </button>
    </div>
  );
}
