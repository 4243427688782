import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { LoadingCircleIcon } from "../common/AppIcons";
import { getCreateRevision } from "../../api/ApiV2";
import { navigate } from "raviger";

export default function DocumentView({ hoaId, documentId }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDocument();
  }, []);

  const getDocument = () => {
    setLoading(true);
    getCreateRevision({ hoaId, documentId })
      .then((res) => {
        console.log(res);
        navigate(res.file, { replace: true });
      })
      .catch((res) => {
        toast.error(
          res.readableMessage ?? res.message ?? "Error getting document"
        );
        setLoading(false);
      });
  };
  return (
    <div className="w-fill h-full bg-white flex items-center justify-center rounded overflow-hidden">
      {loading ? (
        <LoadingCircleIcon className="my-20 h-8" />
      ) : (
        <div className="w-full py-20 bg-white rounded text-center text-sm text-gray-400">
          Error Fetching Documents
        </div>
      )}
    </div>
  );
}
