import { atom } from "recoil";

export interface VerifiedUser {
  variant: "user";
  email: string;
  firstName: string;
  lastName: string;
  accessToken: string;
  refreshToken: string;
  external_uuid: string | null;
  selectedUserProfile: string | null;
  selectedUserType: string | null;
  userProfiles: Array<object> | null;
  id: string | null;
  loading: boolean;
}

export interface UserLoading {
  variant: "loading";
  accessToken: string;
  refreshToken: string;
}

export interface UserLoggedOut {
  variant: "loggedOut";
}
export interface UserLoggingIn {
  variant: "loggingIn";
}
export interface UserLoggedIn {
  variant: "loggedIn";
  accessToken: string;
  refreshToken: string;
}

export type UserState =
  | VerifiedUser
  | UserLoading
  | UserLoggedOut
  | UserLoggingIn
  | UserLoggedIn;

const initialState: UserState = localStorage.getItem("accessToken")
  ? {
      variant: "loading",
      accessToken: localStorage.getItem("accessToken") || "",
      refreshToken: localStorage.getItem("refreshToken") || "",
    }
  : {
      variant: "loggedOut",
    };

const userAtom = atom<UserState>({
  key: "user",
  default: initialState,
});

export { userAtom };
