import { StarIcon } from "../../common/AppIcons";

const reviews = [
  {
    rating: 5,
    text: '"We have been able to streamline our property maintenance operations and cut down on costs since we started using Doorz."',
    name: "Brenda",
    role: "Property Manager",
    profilePic: "/review/review_1.webp",
  },
  {
    rating: 5,
    text: '"Tracking maintenance requests in real-time and receive notifications has helped us provide more efficient and responsive service to our tenants."',
    name: "Sam",
    role: "HOA Board Member",
    profilePic: "/review/review_2.webp",
  },
  {
    rating: 5,
    text: '"Doorz vendor management tools have made it so much easier to communicate with our vendors and ensure they are completing tasks to our standards."',
    name: "Harry",
    role: "Multifamily Owner",
    profilePic: "/review/review_3.webp",
  },
];
export default function Testimonials({ className }) {
  return (
    <div
      style={{ backgroundColor: "rgba(242, 246, 248, 0.42)" }}
      className={"py-20 " + className}
    >
      <div className="flex flex-col items-center gap-3 max-w-6xl mx-auto px-6">
        <div
          className="px-4 py-1.5 rounded"
          style={{
            backgroundColor: "rgba(42, 50, 253, 0.07)",
            color: "#2A32FD",
            border: "1px solid #d8d8e7",
          }}
        >
          Testimonials
        </div>
        <span
          style={{ fontSize: "26px", fontWeight: "800" }}
          className="text-center"
        >
          Don’t just take our words
        </span>

        <div className="w-full mt-5 flex items-center md:items-start flex-col md:grid md:grid-cols-3 gap-4">
          {reviews.map((review, index) => (
            <ReviewBlock key={index} {...review} />
          ))}
        </div>
      </div>
    </div>
  );
}

const ReviewBlock = ({ rating, text, name, role, profilePic }) => {
  return (
    <div className="h-full rounded-md bg-white flex flex-col justify-center items-start gap-7 px-4 md:px-7 py-6 max-w-xs mx-auto shadow-none hover:shadow-md">
      {rating && (
        <div className="flex flex-row gap-3 mt-3">
          {[...Array(rating)].map((val, index) => (
            <StarIcon
              key={index}
              style={{ color: "#2A32FD" }}
              className="h-4"
            />
          ))}
        </div>
      )}
      {text && (
        <span className="mb-3 text-sm md:text-base text-left opacity-80">
          {text}
        </span>
      )}
      <div className="mt-auto flex flex-row items-center gap-4">
        <img className="h-10 w-10 rounded-lg object-cover" src={profilePic} />
        <div className="flex flex-col">
          <span className="font-semibold capitalize">{name}</span>
          <span className="text-sm font-light">{role}</span>
        </div>
      </div>
    </div>
  );
};
