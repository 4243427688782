export const minuteToTime = (minutes) => {
  if (!minutes && minutes !== 0) return "";
  let date = new Date("2022-01-01");
  date.setMinutes(0);
  date.setHours(0);
  date.setSeconds(0);
  date.setMinutes(minutes);
  return (
    (
      "0" +
      (date.getHours() === 0
        ? "12"
        : date.getHours() > 12
        ? date.getHours() - 12
        : date.getHours())
    ).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    (date.getHours() > 11 ? " pm" : " am")
  );
};

export const minuteTo24Time = (minutes) => {
  if (!minutes && minutes !== 0) return "";
  let date = new Date("2022-01-01");
  date.setMinutes(0);
  date.setHours(0);
  date.setSeconds(0);
  date.setMinutes(minutes);
  return (
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":00"
  );
};
