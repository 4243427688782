import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { editJournalEntry } from "../../../../api/Api";
import {
  getAccounts,
  getTransactionDetails,
  getTransactionLegs,
} from "../../../../api/ApiV2";
import Modal from "../../../common/Modal";
import NewAccountPopup from "../../components/NewAccountPopup";
import FormTable from "./components/formTable";
import { LoadingCircleIcon2 } from "../../../common/AppIcons";

export default function EditJournalEntryPopup({
  hoaId,
  onSuccess,
  transactionId,

  readOnly = false,
}) {
  const initialEntry = {
    account: undefined,
    debit: undefined,
    credit: undefined,
    description: undefined,
  };

  const [baseData, setBaseData] = useState([]);
  const [newAccountPopup, setNewAccountPopup] = useState(false);
  const [journalEntry, setJournalEntry] = useState({
    default: true,
    id: 0,
    description: "",
    items: [{ account: 0, amount: "0.00", description: "" }],
  });
  const [loading, set_loading] = useState(false);

  useEffect(() => {
    console.log("transaction id", transactionId);

    if (transactionId) {
      console.log("transaction id", transactionId);
      set_loading(true);
      let apiLoading = {
        details: true,
        legs: true,
      };

      const checkAPILoading = () => {
        if (apiLoading.details === false && apiLoading.legs === false)
          set_loading(false);
      };

      getTransactionDetails({
        hoaId: hoaId,
        transactionId: transactionId,
      })
        .then((res) => {
          apiLoading.details = false;
          checkAPILoading();

          setJournalEntry((journalEntry) => ({
            ...journalEntry,
            ...res,
          }));
        })
        .catch((res) => {
          apiLoading.details = false;
          checkAPILoading();
          console.log(res);
        });

      getTransactionLegs({ hoaId, transactionId })
        .then((res) => {
          apiLoading.legs = false;
          checkAPILoading();

          setJournalEntry((journalEntry) => ({
            ...journalEntry,
            items: res.results?.map((leg) => ({
              account_object: leg.account_object,
              debit:
                Number(leg.amount) > 0
                  ? Number(leg.amount).toFixed(2)
                  : undefined,
              credit:
                Number(leg.amount) < 0
                  ? (-1 * Number(leg.amount)).toFixed(2)
                  : undefined,
              description: leg.description,
            })),
          }));
        })
        .catch((res) => {
          apiLoading.legs = false;
          checkAPILoading();
          console.log(res);
        });
    }
  }, [transactionId]);

  useEffect(() => {
    if (newAccountPopup === false) {
      setTimeout(() => {
        getAccounts({ hoaId: hoaId, page_size: 300 })
          .then((res) => {
            setBaseData(res.results);
          })
          .catch(console.log);
      }, 100);
    }
  }, [newAccountPopup]);

  const submitData = () => {
    if (readOnly) return;

    if (
      journalEntry.items?.filter(
        (leg) => leg.account && (leg.debit || leg.credit)
      )?.length === 0
    )
      toast.warning("Fill data before proceeding");
    else {
      set_loading(true);
      editJournalEntry({
        hoaId,
        id: transactionId,
        data: {
          ...journalEntry,
          items: journalEntry.items
            ?.filter((leg) => leg.account && (leg.debit || leg.credit))
            .map((leg) => {
              console.log(leg.credit);
              return {
                account: Number(leg.account),
                amount:
                  Number(leg.credit) === 0 || leg.debit
                    ? Number(leg.debit)
                    : -1 * Number(leg.credit),
                description: leg.description,
              };
            }),
        },
      })
        .then((_) => {
          set_loading(false);
          toast.success("Your Journal Entry was Saved");

          onSuccess && onSuccess();
        })
        .catch((err) => {
          set_loading(false);
          toast.error(err?.message ?? err?.[0] ?? "Error Saving Journal Entry");

          console.log("Error");
        });
    }
  };

  if (loading)
    return (
      <div className="w-full py-20 flex justify-center items-center">
        <LoadingCircleIcon2 className="h-10" />
      </div>
    );
  else
    return (
      <div className="flex flex-col bg-gray-100 px-5 pb-4 pt-6 rounded-md">
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <h3 className="ml-5 text-3xl leading-6 font-semibold text-gray-900 flex-grow">
              General Ledger Entry
            </h3>
            <FormTable
              className="pl-8 pr-3 mt-6"
              {...{
                journalEntry,
                setJournalEntry,
                initialEntry,
                setNewAccountPopup,
                baseData,
                readOnly,
              }}
            />
            <div
              colspan="5"
              className="w-full flex gap-3 items-center justify-end mt-4 pr-3"
            >
              <button
                onClick={(_) => onSuccess && onSuccess()}
                type="button"
                className="border-2 border-gray-400 text-gray-600 hover:text-gray-700 font-semibold bg-white hover:bg-gray-200 focus:outline-none px-7 py-1 rounded text-sm"
              >
                {readOnly ? "Close" : "Cancel"}
              </button>
              {!readOnly && (
                <button
                  type="button"
                  style={{
                    backgroundColor: "#2A32FD",
                    border: "solid 2px #2A32FD",
                  }}
                  className="px-8 py-1 rounded shadow-sm text-white hover:opacity-70 focus:outline-none text-sm"
                  onClick={(_) => submitData()}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={newAccountPopup}
          setShow={() => {
            setNewAccountPopup(false);
          }}
        >
          <NewAccountPopup
            hoaId={hoaId}
            closePopup={() => {
              setNewAccountPopup(false);
            }}
          />
        </Modal>
      </div>
    );
}
