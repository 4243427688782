import { useEffect, useState } from "react";

import {
  getAccounts,
  getAllTransactions,
  getTransactionDetails,
  getTransactionLegs,
} from "../../../../../api/ApiV2";
import Paginator from "../../../../../common/Paginator";
import { dateStringFormat } from "../../../../../utils/DateUtils";
import { amountFormat } from "../../../../common/AmountFormat";
import { LoadingCircleIcon } from "../../../../common/AppIcons";
import Popup from "../../../../common/Popup";
import TransactionLegsPopup from "../../../components/transactionLegsPopup";
import Modal from "../../../../common/Modal";
import BillDetailPopup from "../../../components/bill/BillDetailPopup";
import RevanueDetailPopup from "../../../components/addRevenue/RevenueDetailPopup";
import CheckDetailPopup from "../../../components/check/CheckDetailPopup";
import EditJournalEntryPopup from "../../journalEntry/EditJournalEntryPopup";
import { toast } from "react-toastify";

const entryPerPage = 25;

export default function Transactions({ hoaId, accountId }) {
  const [accounts, set_accounts] = useState();
  const [transactions, set_transactions] = useState([]);
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [loading, set_loading] = useState(false);
  const [popup, setPopup] = useState({
    popup: false,
    data: {},
    transaction: {},
  });

  useEffect(() => {
    get_transactions();
  }, [page?.current]);

  useEffect(() => {
    getAccounts({ hoaId, page_size: 300 })
      .then((res) => {
        console.log({ res });
        set_accounts(res?.results);
      })
      .catch((res) => {
        console.log(res);
      });
  }, []);

  const get_transactions = () => {
    set_loading(true);
    getTransactionLegs({
      hoaId,
      account: accountId,
      page: page.current * entryPerPage,
      limit: entryPerPage,
    })
      .then((res) => {
        console.log({ res });
        setPage({ ...page, total: res.count });
        set_transactions(
          res?.results.sort(
            (val1, val2) => new Date(val2.date) - new Date(val1.date)
          )
        );
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
      });
  };

  const openPopup = (transaction) => {
    set_loading(true);
    getTransactionDetails({ hoaId, transactionId: transaction.transaction })
      .then((res) => {
        if (res.type === "JOURNAL") {
          setPopup((popup) => {
            return {
              ...popup,
              popup: true,
              type: "JOURNAL",
              transaction: transaction,
            };
          });
        } else
          res.type === "INVOICE"
            ? setPopup({
                popup: true,
                type: "INVOICE_LEGS",
                transaction: { ...transaction, ...res },
              })
            : res.type === "BILL"
            ? setPopup({
                popup: true,
                type: "BILL_LEGS",
                transaction: { ...transaction, ...res },
              })
            : res.type === "CHECK"
            ? setPopup({
                popup: true,
                type: "CHECK_LEGS",
                transaction: { ...transaction, ...res },
              })
            : setPopup({
                popup: true,
                type: "EXPENSE",
                transaction: transaction,
              });
        set_loading(false);
      })
      .catch((res) => {
        toast.error(res.message ?? "Error fetching transaction detail");
        set_loading(false);
      });
  };

  const fetch_data_open_popup = () => {
    popup.type === "INVOICE_LEGS"
      ? setPopup((popup) => ({ ...popup, type: "INVOICE" }))
      : popup.type === "BILL_LEGS"
      ? setPopup((popup) => ({ ...popup, type: "BILL" }))
      : setPopup((popup) => ({ ...popup, type: "CHECK" }));
  };

  const table = () => {
    return transactions?.map((transaction, transaction_index) => {
      return (
        <tr
          onClick={() => {
            openPopup(transaction);
          }}
          key={transaction_index}
          className="bg-white hover:bg-gray-100 cursor-pointer text-gray-800"
        >
          <td className="max-w-0 whitespace-nowrap text-sm">
            <span className="flex px-6 py-4 truncate text-sm">
              {dateStringFormat(transaction.created_date)}
            </span>
          </td>

          <td className="px-6 py-4 text-left whitespace-nowrap text-sm">
            <span>{transaction.description}</span>
          </td>

          <td className="px-6 py-4 text-right whitespace-nowrap text-sm">
            <span>${amountFormat(transaction.amount)}</span>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <div className="mt-8 mb-8 sm:mb-12 px-4 sm:px-6 lg:px-8 flex flex-row items-center gap-1">
        <h1 className="text-lg sm:text-xl leading-6 font-semibold text-gray-900">
          Transactions{" "}
          {accounts?.find((val) => val.id === accountId)?.name
            ? " of " + accounts?.find((val) => val.id === accountId)?.name
            : ""}
        </h1>
      </div>

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto pb-5">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto overflow-hidden">
              {loading ? (
                <LoadingCircleIcon className="my-9 mx-auto h-7 w-7 text-blue-600" />
              ) : (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="">
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600 tracking-wider">
                        Date
                      </th>

                      <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600 tracking-wider">
                        Description
                      </th>

                      <th className="px-6 py-3 text-right text-sm font-semibold text-gray-600 tracking-wider">
                        Amount
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    className="bg-white divide-y divide-gray-200 sm:rounded-lg shadow-md border"
                    x-max="1"
                  >
                    {table()}
                  </tbody>
                </table>
              )}
              {(!transactions || transactions.length === 0) && !loading && (
                <tr className="w-full flex justify-center py-9 font-semibold text-sm text-gray-500">
                  No Transactions Found
                </tr>
              )}
            </div>
          </div>
        </div>
        <Paginator
          totalPage={page.total}
          currentPage={page.current + 1}
          entryPerPage={entryPerPage}
          onChange={(pageNo) => {
            setPage({ ...page, current: pageNo - 1 });
          }}
        />
      </div>
      <Popup
        show={
          popup.popup &&
          !(
            popup.type === "INVOICE" ||
            popup.type === "BILL" ||
            popup.type === "CHECK" ||
            popup.type === "JOURNAL"
          )
        }
        setShow={() => {
          setPopup({ ...popup, popup: false });
        }}
        className="sm:max-w-4xl flex items-center justify-center p-2"
      >
        <TransactionLegsPopup
          type={popup.type}
          transactionId={popup.transaction.transaction}
          openDetailsPopup={fetch_data_open_popup}
          closePopup={() => setPopup({ ...popup, popup: false })}
          hoaId={hoaId}
        />
      </Popup>
      <Modal
        show={
          popup.popup &&
          (popup.type === "INVOICE" ||
            popup.type === "BILL" ||
            popup.type === "CHECK" ||
            popup.type === "JOURNAL")
        }
        setShow={() => {
          if (popup.type === "INVOICE")
            setPopup({ ...popup, type: "INVOICE_LEGS" });
          else if (popup.type === "BILL") return;
          else if (popup.type === "CHECK")
            setPopup({ ...popup, type: "CHECK_LEGS" });
        }}
        wide
      >
        {popup.type === "BILL" ? (
          <BillDetailPopup
            hoaId={hoaId}
            onSuccess={() => {
              setPopup({ ...popup, type: "BILL_LEGS" });
            }}
            billId={popup.transaction.bill}
          />
        ) : popup.type === "INVOICE" ? (
          <RevanueDetailPopup
            hoaId={hoaId}
            onSuccess={() => {
              setPopup({ ...popup, type: "INVOICE_LEGS" });
            }}
            transactionId={popup.transaction.transaction}
            billId={popup.transaction.bill}
            remove_pay={true}
          />
        ) : popup.type === "CHECK" ? (
          <CheckDetailPopup
            hoaId={hoaId}
            onSuccess={() => {
              setPopup({ ...popup, type: "CHECK_LEGS" });
            }}
            transactionId={popup.transaction.transaction}
          />
        ) : popup.type === "JOURNAL" ? (
          <EditJournalEntryPopup
            hoaId={hoaId}
            onSuccess={() => {
              setPopup({ ...popup, popup: false });
            }}
            readOnly
            transactionId={popup.transaction.transaction}
          />
        ) : null}
      </Modal>
    </div>
  );
}
