import { useUserActions } from "../../../../../actions/UserActions";
import {
  HoaIcon4,
  LogoutIcon,
  People5Icon,
  SuitcaseIcon,
} from "../../../../common/AppIcons";
import SelectableItem from "./components/SelectableItem";
import { sidebar_buttons } from "./data/sidebarData";

export default function Sidebar({ assets, page }) {
  const { logout } = useUserActions();

  return (
    <div className="bg-white h-screen sm:h-full overflow-y-auto flex flex-col rounded px-4">
      {/* sidebar buttons */}
      <div className="flex flex-col gap-1 py-4">
        {sidebar_buttons.map((button, button_index) => (
          <SelectableItem
            key={button_index}
            sidebar_data={button}
            page={page}
          />
        ))}
      </div>
      <div className="flex flex-col divide-y">
        <div></div>
        {assets?.map((asset, assetIndex) => (
          <div className="py-4 flex flex-col" key={assetIndex}>
            <span className="ml-1 mb-3">{asset.name}</span>
            {[
              {
                label: "Users",
                icon: People5Icon,
                link: `/profile/${asset.id}/users`,
              },
              {
                label: "Org Profile",
                icon: SuitcaseIcon,
                link: `/profile/${asset.id}/profile`,
              },
              {
                label: "Properties",
                icon: HoaIcon4,
                link: `/profile/${asset.id}/properties`,
              },
            ].map((button, button_index) => (
              <SelectableItem
                key={button_index}
                sidebar_data={button}
                page={page}
              />
            ))}
          </div>
        ))}
        <div></div>
      </div>
      <div className="py-4">
        {[
          {
            label: "Log Out",
            icon: LogoutIcon,
            onClick: () => logout(),
            link: "#",
          },
        ].map((button, button_index) => (
          <SelectableItem
            key={button_index}
            sidebar_data={button}
            page={page}
          />
        ))}
      </div>
    </div>
  );
}
