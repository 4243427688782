import Input from "../../../../common/Form/Input";

export default function InputSection({
  label,
  warning,
  containerClassName = "",
  className,
  ...props
}) {
  return (
    <div className={"mt-2 flex flex-col gap-1 w-full " + containerClassName}>
      <div className="w-full flex flex-row items-center justify-between text-right">
        <span className="tracking-wide ml-1 text-sm">{label}</span>
        <span className="text-red-600 text-xs">{warning ?? ""}</span>
      </div>
      <Input
        {...props}
        className={
          className +
          " " +
          (warning && warning !== "" ? "border-red-300" : "border-gray-300")
        }
      />
    </div>
  );
}
