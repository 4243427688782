import { accountingInputClassName } from "../../../../../common/data/inputClassName";

const default_className = accountingInputClassName + " w-64";

export const inputFieldData = (data, set_data, members) => [
  {
    label: "Reference No",
    type: "text",
    value: data.reference_number ?? data.invoiceNumber,
    placeholder: "Enter reference number",
    onChange: (val) => {
      if (!data.just_display) set_data({ ...data, invoiceNumber: val });
    },
    className: default_className + " h-10",
  },

  {
    label: "Revenue Date",
    type: "date",
    value: data.date ?? data.invoiceDate,
    placeholder: "eg. 17 Feb, 2020",
    onChange: (val) => {
      if (!data.just_display) set_data({ ...data, invoiceDate: val });
    },
    autoComplete: "off",
    readOnly: data.just_display,
    className: default_className,
  },
  {
    label: "Due date",
    type: "date",
    value: data.due_date ?? data.invoiceDueDate,
    placeholder: "eg. 17 Mar, 2020",
    onChange: (val) => {
      if (!data.just_display) set_data({ ...data, invoiceDueDate: val });
    },
    autoComplete: "off",
    readOnly: data.just_display,
    className: default_className,
  },
];
