import React from "react";
import { toast } from "react-toastify";

import PhoneInputInput from "../../../../common/Form/PhoneInput";
import TextInput from "../../../../common/Form/TextInput";

export default function ExternalVendorDetails({ set_form, data, setData }) {
  return (
    <div>
      <div className="w-full text-center mt-10 mb-6 text-gray-800 text-lg font-semibold items-center my-3">
        How can we contact them with your Service Request?
      </div>
      <div className="mt-10 flex flex-row w-full justify-center gap-4 p-4 items-center">
        <PhoneInputInput
          className="px-3 py-1.5"
          value={data.external_vendor_phone}
          onChange={(value) =>
            setData({ ...data, external_vendor_phone: value })
          }
        />
        <TextInput
          className="px-3 py-1.5"
          value={data.external_vendor_email}
          onChange={(value) =>
            setData({ ...data, external_vendor_email: value })
          }
          placeholder="john@example.com"
          label="Email"
        />
      </div>

      <div className="w-full flex justify-center flex-row gap-6 mt-5">
        <button
          onClick={() => {
            set_form("VENDOR_SELECTION");
          }}
          style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
          className="px-4 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-70"
        >
          Go back
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            data.external_vendor_phone && data.external_vendor_email
              ? set_form("JOB_SELECTION")
              : toast.warning("Please fill your details");
          }}
          style={{ backgroundColor: "#2A32FD" }}
          className="px-5 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-80 text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
}
