import { Link } from "raviger";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRecoilState } from "recoil";

import Paginator from "../../../../common/Paginator";
import { date_format } from "../../../../utils/dateFormat";
import {
  CalenderIcon5,
  LoadingCircleIcon2,
  SearchIcon,
} from "../../../common/AppIcons";
import SRBase from "../../srBase";
import { EstimatePrefix } from "../../../../utils/SRPrefix";
import { addressFormatLine } from "../../../../utils/addressFormat";
import { userAtom } from "../../../../store/UserAtom";
import { getEstimates } from "../../../../api/ApiV2";

const entry_per_page = 15;

export default function Estimates({ hoaId, url }) {
  const [user] = useRecoilState(userAtom);

  const [estimates_arr, set_estimates_arr] = useState();
  const [page, set_page] = useState(1);
  const [show_estimates, set_show_estimates] = useState();
  const [datepicker_onfocus, set_datepicker_onfocus] = useState(false);
  const [loading, set_loading] = useState(false);
  const [search_params, set_search_params] = useState({
    status: "ALL",
    date: "",
    keyword: "",
  });

  const status_options = [
    {
      label: "All",
      value: "ALL",
    },
    ...[
      ...new Set(
        estimates_arr?.map((ele) => ele.estimateStatus)?.filter((ele) => ele)
      ),
    ].map((ele) => {
      return {
        label:
          ele === "SENT"
            ? "Received"
            : ele
                ?.split("_")
                ?.map(
                  (ele1) =>
                    ele1.charAt(0).toUpperCase() + ele1.slice(1)?.toLowerCase()
                )
                .join(" "),
        value: ele,
      };
    }),
  ];

  const status_badge = (status) => {
    return status_options.find((val) => val.value === status)?.label ? (
      <div
        className={
          "w-40 text-center text-xs rounded-full py-3.5 " +
          (status === "ACCEPTED"
            ? "bg-green-100 text-green-600"
            : status === "ESTIMATE_RECEIVED" || status === "SENT"
            ? "bg-yellow-100 text-yellow-600"
            : status === "REJECTED"
            ? "bg-red-100 text-red-600"
            : "bg-zinc-100 text-gray-600")
        }
      >
        {status_options.find((val) => val.value === status)?.label}
      </div>
    ) : (
      ""
    );
  };

  useEffect(() => {
    set_loading(true);
    getEstimates({
      hoaId,
    })
      .then((res) => {
        set_estimates_arr(
          res?.sort(
            (obj1, obj2) =>
              new Date(obj2.estimateDate) - new Date(obj1.estimateDate)
          )
        );
        console.log({ res });
        set_loading(false);
      })
      .catch((res) => {
        console.log({ res });
        toast.error(res?.message ?? "Estimates fetch failed");
        set_loading(false);
      });
  }, [user.selectedUserProfile]);

  useEffect(() => {
    let temp_estimates_arr = estimates_arr;

    if (search_params.status !== "" && search_params.status !== "ALL")
      temp_estimates_arr = temp_estimates_arr.filter(
        (val) => find_status(val) === search_params.status
      );

    if (search_params.date !== "" && search_params.date !== null)
      temp_estimates_arr = temp_estimates_arr.filter(
        (val) =>
          new Date(val.estimateDate).toDateString() ===
          new Date(search_params.date).toDateString()
      );

    if (search_params.keyword !== "")
      temp_estimates_arr = temp_estimates_arr.filter((val) => {
        return (
          search_params.keyword.toUpperCase() ===
            val.ticketDto.title
              .substring(0, search_params.keyword.length)
              .toUpperCase() ||
          search_params.keyword.toUpperCase() ===
            val.ticketDto.streetAddress1
              ?.substring(0, search_params.keyword.length)
              ?.toUpperCase() ||
          search_params.keyword.toUpperCase() ===
            val.ticketDto.streetAddress2
              ?.substring(0, search_params.keyword.length)
              ?.toUpperCase() ||
          search_params.keyword.toUpperCase() ===
            val.ticketDto.city
              ?.substring(0, search_params.keyword.length)
              ?.toUpperCase() ||
          search_params.keyword.toUpperCase() ===
            ("" + val.total)
              .substring(0, search_params.keyword.length)
              .toUpperCase() ||
          search_params.keyword.toUpperCase() ===
            EstimatePrefix(val)
              .substring(0, search_params.keyword.length)
              .toUpperCase()
        );
      });

    set_show_estimates(temp_estimates_arr);
  }, [
    search_params.keyword,
    search_params.status,
    search_params.date,
    estimates_arr,
  ]);

  const find_status = (obj) => {
    return obj.estimateStatus;
  };

  const Row_format = ({ row }) => (
    <div className="mt-1 w-full text-sm md:text-base">
      <Link href={"/hoa/" + hoaId + "/ticket/estimate/" + row.id}>
        <div className="px-3 md:px-10 py-3 text-sm rounded-md grid grid-cols-11 gap-5 w-full bg-white shadow-sm hover:shadow-lg border border-white hover:border-indigo-800 cursor-pointer items-center">
          <span className="">{EstimatePrefix(row)}</span>
          <span className="col-span-2 font-normal">
            {row.ticketDto?.title ?? ""}
          </span>
          <span className="col-span-3">{addressFormatLine(row.ticketDto)}</span>
          <span className="flex justify-center col-span-2">
            {status_badge(find_status(row))}
          </span>
          <span className="col-span-2 flex flex-row items-center justify-center gap-2">
            <CalenderIcon5 className="h-4 w-4 text-blue-800" />{" "}
            {date_format && (date_format(row.estimateDate) ?? "")}
          </span>
          <span className="flex justify-end">
            {row.total && "$" + Number(row.total).toFixed(2)}
          </span>
        </div>
      </Link>
    </div>
  );

  if (loading)
    return (
      <SRBase hoaId={hoaId} page={url}>
        <div className="w-full h-full py-20 flex items-center justify-center">
          <LoadingCircleIcon2 className="h-10" />
        </div>
      </SRBase>
    );
  else if (!estimates_arr?.length)
    return (
      <SRBase hoaId={hoaId} page={url}>
        <div className="w-full py-24 flex items-center justify-center">
          <span className="text-2xl text-gray-500">No Estimates Found</span>
        </div>
      </SRBase>
    );
  else
    return (
      <SRBase style={{ minWidth: "1000px" }} hoaId={hoaId} page={url}>
        <div
          style={{ minHeight: "500px" }}
          className="flex flex-col w-full overflow-x-auto"
        >
          <div className="flex flex-row w-full items-center justify-between gap-4">
            <span className="font-semibold text-2xl py-3 text-gray-800">
              Estimates List
            </span>
            <div className="bg-white flex flex-row items-center px-2 rounded-md border border-gray-100">
              <input
                id="search_input"
                className="focus:outline-none p-2 text-gray-700"
                placeholder="Search"
                value={search_params.keyword}
                onChange={(e) =>
                  set_search_params({
                    ...search_params,
                    keyword: e.target.value,
                  })
                }
              />
              <div
                onClick={() => {
                  document.getElementById("search_input").focus();
                }}
              >
                <SearchIcon className="h-5 w-5" />
              </div>
            </div>
          </div>

          <div className="mt-2 flex flex-col items-end md:items-center md:flex-row gap-4 md:gap-5 md:w-full md:grid md:grid-cols-11">
            <select
              onChange={(e) =>
                set_search_params({ ...search_params, status: e.target.value })
              }
              value={search_params.status}
              className="py-2 px-1 md:col-start-7 md:col-span-2 rounded-md border border-gray-100 outline-none text-gray-700 capitalize"
            >
              <option
                value=""
                className="text-gray-700"
                defaultValue
                hidden
                disabled
              >
                Any Status
              </option>
              {status_options.map((status, status_index) => (
                <option
                  key={status_index}
                  className="capitalize"
                  value={status.value}
                >
                  {status.label}
                </option>
              ))}
            </select>

            <div className="md:col-span-2 flex flex-row items-center relative">
              <DatePicker
                id="date_picker"
                placeholderText="Any Date"
                selected={
                  search_params.date ? new Date(search_params.date) : undefined
                }
                onChange={(val) =>
                  set_search_params({ ...search_params, date: val })
                }
                onFocus={() => set_datepicker_onfocus(true)}
                onBlur={() => set_datepicker_onfocus(false)}
                onCalendarClose={() => set_datepicker_onfocus(false)}
                className={
                  "relative p-2 rounded-md border border-gray-100 outline-none text-gray-700 w-full " +
                  (datepicker_onfocus ? "left-4 ml-0 " : "ml-4")
                }
              />
              <div
                onClick={() => {
                  document.getElementById("date_picker").focus();
                }}
                className="relative z-10"
              >
                <CalenderIcon5 className="h-5 w-5 text-blue-800 relative right-3" />
              </div>
            </div>
          </div>

          <div>
            <div className="px-5 md:px-10 mt-10 mb-2 w-full grid grid-cols-11 gap-5 text-sm text-gray-400">
              <span className="text-gray-600">Id</span>
              <span className="text-gray-600 col-span-2">Ticket Title</span>
              <span className="text-gray-600 col-span-3">Building</span>
              <span className="text-gray-600 text-center col-span-2">
                Status
              </span>
              <span className="text-gray-600 col-span-2 text-center">Date</span>
              <span className="text-gray-600 flex justify-end">Amount</span>
            </div>

            {(!show_estimates || show_estimates?.length === 0) && (
              <span className="w-full flex justify-center text-gray-400 my-20">
                No Results Available.
              </span>
            )}

            {show_estimates
              ?.slice((page - 1) * entry_per_page, page * entry_per_page)
              ?.map((sr_data, sr_data_index) => (
                <Row_format key={sr_data_index} row={sr_data} />
              ))}
          </div>

          <div className="my-8">
            <Paginator
              entryPerPage={entry_per_page}
              totalPage={show_estimates?.length}
              currentPage={page}
              onChange={set_page}
            />
          </div>
        </div>
      </SRBase>
    );
}
