import React, { useState } from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import {
  CalenderIcon2,
  ClockIcon,
  CloseEyeIcon,
  LocationIcon,
  LocationIcon2,
  OpenEyeIcon,
} from "../../../../common/AppIcons";
import Linkify from "react-linkify";
import "../linkify.css";
import { meeting_color, ProfilePic } from "../constants";
import AttendeesPopup from "../popups/AttendeesPopup";
import { meeting_types } from "../constants";
import { dateStringFormat } from "../../../../../utils/DateUtils";

export default function MeetingDetails({ meeting, members, attendees }) {
  const [open_agenda, set_open_agenda] = useState({});
  const [attendees_popup, set_attendees_popup] = useState(false);

  return (
    <div
      className={
        "bg-white text-gray-800 rounded p-5 px-9 flex flex-col min-w-full items-start gap-1 translate-all duration-300 overflow-hidden"
      }
    >
      <div className="flex flex-row gap-3 items-start w-full">
        <h1 className="font-bold flex flex-grow text-xl text-gray-900">
          {meeting.name}
        </h1>

        <span
          className={
            "px-3 py-1 rounded hover:opacity-90 cursor-default " +
            meeting_color(meeting.meeting_type)?.bg2 +
            " " +
            meeting_color(meeting.meeting_type)?.text
          }
        >
          {
            meeting_types.find((value) => value.value === meeting.meeting_type)
              ?.label
          }
        </span>
      </div>
      <span className="text-gray-600 break-words mt-3">
        <MarkdownPreview className="break-words" source={meeting.description} />
      </span>
      <div className="w-full">
        <div className="flex flex-col md:flex-row md:items-center gap-1 md:gap-6 mt-3 mb-3 md:mb-0 text-sm font-semibold text-gray-700">
          <div className="flex flex-row items-center gap-2">
            <CalenderIcon2 className="h-4 text-indigo-400" />
            {dateStringFormat(meeting.start_time)}
          </div>

          <div className="flex flex-row items-center gap-2">
            <ClockIcon className="h-4 text-indigo-400" />
            <span>
              {new Date(meeting.start_time).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }) +
                " - " +
                new Date(meeting.end_time).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
            </span>
          </div>

          <div className="flex flex-row items-center gap-2">
            <LocationIcon className="text-indigo-400 h-4" />
            <Linkify className="text-sm whitespace-pre">
              {meeting.location}
            </Linkify>
          </div>
        </div>

        <div className="mt-8 flex flex-col gap-3 text-sm font-semibold">
          <div className="flex flex-row items-center gap-2">
            Secretary :
            <div className="bg-gray-100 rounded-md p-1 px-2 flex flex-row items-center gap-1">
              <ProfilePic
                member={
                  members?.find((value) => {
                    return value.user?.id === meeting.secretary;
                  })?.user
                }
              />
              <h1 className="font-normal text-base relative bottom-px text-gray-600">
                {members?.find((value) => {
                  return value.user?.id === meeting.secretary;
                })?.user?.first_name +
                  " " +
                  members?.find((value) => {
                    return value.user?.id === meeting.secretary;
                  })?.user?.last_name}
              </h1>
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            Chairman :
            <div className="bg-gray-100 rounded-md p-1 px-2 flex flex-row items-center gap-1">
              <ProfilePic
                member={
                  members?.find((value) => value.user?.id === meeting.chairman)
                    ?.user
                }
              />
              <h1 className="font-normal text-base relative bottom-px text-gray-600">
                {members?.find((value) => value.user?.id === meeting.chairman)
                  ?.user?.first_name +
                  " " +
                  members?.find((value) => value.user?.id === meeting.chairman)
                    ?.user?.last_name}
              </h1>
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-1 mt-7">
          {(attendees?.length > 8 ? attendees?.slice(0, 7) : attendees)?.map(
            (member) => (
              <ProfilePic className="h-8 w-8" member={member.user_object} />
            )
          )}
          <button
            onClick={() => set_attendees_popup(true)}
            className="ml-3 focus:outline-none text-sm font-semibold text-indigo-600 hover:text-indigo-700"
          >
            See all
          </button>
        </div>
        <div className="flex flex-row text-sm gap-1 mt-2 ml-4 items-end">
          <span className="font-semibold">{attendees?.length}</span>
          <span className="text-gray-600">People attending</span>
        </div>

        {meeting.agenda?.length > 0 && (
          <div className="flex flex-col gap-1 mt-4 items-start w-full">
            <div className="flex w-full px-2 flex-col">
              {meeting.agenda?.map((agenda, index2) => (
                <div
                  key={index2}
                  className="w-full bg-white px-1 py-1 flex flex-col"
                >
                  <div className="w-full flex flex-row justify-between">
                    <span
                      className="font-semibold py-1"
                      style={{ color: "#2A32FD" }}
                    >
                      Agenda {index2 + 1}
                    </span>
                  </div>

                  <div
                    className={
                      "bg-gray-100 " +
                      (open_agenda[meeting.id + "" + index2]
                        ? "px-5 py-4"
                        : "px-4 py-2")
                    }
                  >
                    <div className="flex flex-row gap-1 items-start justify-between">
                      <span
                        className={
                          "font-semibold text-gray-800 " +
                          (open_agenda[meeting.id + "" + index2]
                            ? "text-xl"
                            : "text-base")
                        }
                      >
                        {agenda.title}
                      </span>
                      <div
                        className={
                          "mt-1 flex flex-row items-center gap-2 cursor-pointer text-sm whitespace-pre " +
                          (open_agenda[meeting.id + "" + index2]
                            ? "text-red-500"
                            : "text-indigo-500")
                        }
                        onClick={() => {
                          if (open_agenda[meeting.id + "" + index2]) {
                            set_open_agenda({
                              ...open_agenda,
                              [meeting.id + "" + index2]: false,
                            });
                          } else
                            set_open_agenda({
                              ...open_agenda,
                              [meeting.id + "" + index2]: true,
                            });
                        }}
                      >
                        {open_agenda[meeting.id + "" + index2] ? (
                          <CloseEyeIcon className="h-3" />
                        ) : (
                          <OpenEyeIcon className="h-3" />
                        )}
                        {open_agenda[meeting.id + "" + index2] ? (
                          <span>Close Agenda</span>
                        ) : (
                          <span>View Agenda</span>
                        )}
                      </div>
                    </div>

                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className={
                        "overflow-hidden text-sm mt-1 transition-all duration-500 ease-in-out " +
                        (open_agenda[meeting.id + "" + index2]
                          ? "max-h-full pt-1"
                          : "max-h-0")
                      }
                    >
                      <MarkdownPreview
                        style={{ fontSize: "14px" }}
                        className="text-gray-600"
                        source={agenda.description}
                      />

                      {agenda.agenda_motions?.map(
                        (agenda_motion, agenda_motion_index) => (
                          <div
                            key={agenda_motion_index}
                            className="flex flex-col gap-1 mt-5 flex-grow rounded p-2"
                          >
                            <span className="w-full text-gray-600 mb-3 text-lg font-semibold">
                              Motion {agenda_motion_index + 1}
                            </span>
                            <div className="flex flex-col gap-1 w-full">
                              <label className="text-gray-800 font-semibold block text-sm tracking-wide">
                                Title
                              </label>
                              {agenda_motion.proposed_by && (
                                <span className="bg-white appearance-none rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
                                  {agenda_motion.title}
                                </span>
                              )}
                            </div>
                            <div className="flex flex-row gap-2 w-full justify-around">
                              <div className="flex flex-col gap-1 w-full">
                                <label className="text-gray-800 font-semibold block text-sm tracking-wide">
                                  Proposed by
                                </label>
                                {agenda_motion.proposed_by && (
                                  <span className="bg-white appearance-none rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
                                    {members?.find(
                                      (value1) =>
                                        value1.user?.id ===
                                        agenda_motion.proposed_by
                                    )?.user?.first_name +
                                      " " +
                                      members?.find(
                                        (value1) =>
                                          value1.user?.id ===
                                          agenda_motion.proposed_by
                                      )?.user?.last_name}
                                  </span>
                                )}
                              </div>
                              <div className="flex flex-col gap-1 w-full">
                                <label className="text-gray-800 font-semibold block text-sm tracking-wide">
                                  Seconded by
                                </label>
                                {agenda_motion.seconded_by && (
                                  <span className="bg-white appearance-none rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
                                    {members?.find(
                                      (value1) =>
                                        value1.user?.id ===
                                        agenda_motion.seconded_by
                                    )?.user?.first_name +
                                      " " +
                                      members?.find(
                                        (value1) =>
                                          value1.user?.id ===
                                          agenda_motion.seconded_by
                                      )?.user?.last_name}
                                  </span>
                                )}
                              </div>
                            </div>
                            {agenda_motion?.agenda_motion_votes &&
                              agenda_motion.agenda_motion_votes.length > 0 && (
                                <div className="flex flex-col gap-1 w-full mt-2">
                                  <label className="text-gray-800 font-semibold block text-sm tracking-wide">
                                    Votes
                                  </label>
                                  <span className="bg-white appearance-none flex flex-row gap-4 border border-gray-200 rounded-lg w-full p-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
                                    {agenda_motion.agenda_motion_votes?.map(
                                      (votes, votes_index) => (
                                        <div
                                          key={votes_index}
                                          className={
                                            "rounded-lg px-2 py-1 text-sm font-semibold text-white " +
                                            (votes.vote === "YES"
                                              ? "bg-green-400"
                                              : votes.vote === "NO"
                                              ? "bg-red-400"
                                              : "bg-gray-600")
                                          }
                                        >
                                          {votes.user_object?.first_name ?? ""}{" "}
                                          {votes.user_object?.last_name ?? ""}
                                        </div>
                                      )
                                    )}
                                  </span>
                                </div>
                              )}
                            {agenda_motion?.resolution && (
                              <div className="flex flex-row items-center gap-1 mt-2">
                                <span className="text-gray-800 font-semibold block text-sm whitespace-pre">
                                  Resolution :
                                </span>
                                <MarkdownPreview
                                  source={agenda_motion.resolution}
                                />
                              </div>
                            )}
                          </div>
                        )
                      )}
                      {agenda.action_items &&
                        agenda.action_items.length !== 0 && (
                          <div className="flex flex-col gap-2 w-full mb-2 mt-5">
                            <label className="text-gray-800 font-semibold block text-sm tracking-wide">
                              Action Items
                            </label>

                            {agenda.action_items?.map((item, item_index) => (
                              <div
                                key={item_index}
                                className="flex flex-col items-center gap-1 px-4 flex-grow mt-4"
                              >
                                <span className="flex flex-row gap-4 whitespace-pre w-full">
                                  <span className="relative top-px">
                                    {item_index + 1}.{" "}
                                  </span>
                                  <MarkdownPreview
                                    className="relative bottom-px"
                                    source={item.title}
                                  />
                                </span>
                                {
                                  <div className="flex flex-col gap-1 w-full px-9">
                                    <label className="text-gray-800 font-semibold block text-sm tracking-wide">
                                      Responsible Member
                                    </label>
                                    <span className="bg-white appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
                                      &nbsp;
                                      {item?.responsible_member_object
                                        ?.first_name ?? ""}{" "}
                                      {item?.responsible_member_object
                                        ?.last_name ?? ""}
                                    </span>
                                  </div>
                                }
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <AttendeesPopup
        view={attendees_popup}
        set_view={(val = false) => {
          set_attendees_popup(val);
        }}
        mandatory_attendees={attendees
          ?.filter((ele) => ele.attendee_type === "MANDATORY")
          .map((ele) => ele.user_object)}
        optional_attendees={attendees
          ?.filter((ele) => ele.attendee_type === "OPTIONAL")
          .map((ele) => ele.user_object)}
      />
    </div>
  );
}
