import React from "react";
import { CrossIcon } from "../../common/AppIcons";

export default function Success({
  payment_method_popup,
  set_payment_method_popup,
}) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-full flex flex-col items-center justify-start px-10 py-10 rounded-lg bg-white"
    >
      <img src="/static/img/thumbs_up.png" className="h-36 md:h-48" />
      <span className="mt-5 text-xl font-bold">Success!</span>
      <span className="mt-2 text-sm text-gray-500">
        Thank you. Your payment setup was successful!
      </span>
      <button
        onClick={() => {
          set_payment_method_popup({
            ...payment_method_popup,
            popup: "PAY_OPTIONS",
          });
        }}
        className={
          "mt-9 rounded px-10 py-2 bg-indigo-100 text-indigo-500 text-sm hover:opacity-80 font-semibold flex flex-row items-center justify-center gap-4"
        }
      >
        Done
      </button>
    </div>
  );
}
