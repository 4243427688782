import React from "react";
import { ArrowInCircleIcon } from "../../../../common/AppIcons";

export default function CatogarySelection({
  handymanSubCategory,
  data,
  setData,
  set_form,
}) {
  return (
    <div>
      <div className="w-full text-center mt-10 mb-6 text-gray-800 text-lg font-semibold items-center my-3">
        What type of Handyman would you like to request?
      </div>
      <div className="mt-10 w-full grid grid-cols-2 md:grid-cols-4 gap-4 px-3 max-w-3xl mx-auto">
        {handymanSubCategory.map((job, index) => {
          return (
            <div
              key={index}
              onClick={(_) => {
                setData((data) => {
                  return { ...data, sub_category: job.data };
                });
              }}
              style={{
                backgroundColor: "#F4F4F4",
                color: "#211C52",
              }}
              className={
                "w-full px-5 py-2 rounded-md bg-gray-200 cursor-pointer overflow-hidden flex flex-row hover:shadow-xl hover:opacity-80 border-2 " +
                (data.sub_category === job.data
                  ? "border-indigo-500"
                  : "border-white")
              }
            >
              <div className="flex flex-col justify-around py-2 h-full gap-6 flex-grow">
                {job.icon}
                <div className="flex flex-row items-center gap-3">
                  <span className="whitespace-pre relative bottom-px text-xs">
                    {job.label}
                  </span>
                  <ArrowInCircleIcon className="h-4 w-4 relative top-px" />
                </div>
              </div>
              <div className="flex justify-end items-center relative left-10">
                <div className="absolute flex justify-end items-center overflow-hidden">
                  {job.bgIcon}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-full flex justify-center flex-row gap-6 mt-5">
        <button
          onClick={() => {
            set_form("JOB_SELECTION");
          }}
          style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
          className="px-4 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-70"
        >
          Go back
        </button>
        <button
          onClick={() => {
            data.sub_category && set_form("ADDRESS_UNIT_SELECTION");
          }}
          style={{ backgroundColor: "#2A32FD" }}
          className="px-5 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-80 text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
}
