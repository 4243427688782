import { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { Link } from "raviger";

import {
  Arrow,
  CrossIcon,
  DocumentIcon4,
  LoadingIcon2,
  SentIcon,
  LoadingCircleIcon,
} from "../../../common/AppIcons";
import { EstimatePrefix } from "../../../../utils/SRPrefix";
import EstimateTable from "./EstimateTable";
import { estimateLegalText } from "../../../../data/estimateLegalText";
import { date_format } from "../../../../utils/dateFormat";
import SRBase from "../../srBase";
import { userAtom } from "../../../../store/UserAtom";
import { useRecoilState } from "recoil";
import { addressFormat } from "../../../../utils/addressFormat";
import { getEstimateDetails, postEstimateAccept } from "../../../../api/ApiV2";

const headings = [
  "Product Name",
  "Product Description",
  "Rate",
  "QTY",
  "Amount",
  "Accepted By",
  "Accepted On",
];

export default function Estimate({ hoaId, estimateId, url }) {
  const [user] = useRecoilState(userAtom);

  const pdfPageRef = useRef(null);
  const pdfLoadingRef = useRef(null);
  const [loading, set_loading] = useState(true);
  const [estimate_data, set_estimate_data] = useState();
  const [client_data, set_client_data] = useState({
    name: "",
    date: new Date().toISOString().split("T")[0],
    acceptedEstimateIds: [],
  });
  const [warning, set_warning] = useState({
    success_warning: false,
    name_not_filled: false,
  });
  const [open_pdf, set_open_pdf] = useState(null);
  const [new_page, set_new_page] = useState();

  useEffect(() => {
    get_estimate_details();
  }, [user.selectedUserProfile]);

  useEffect(() => {
    if (open_pdf) {
      downloadPDF();

      set_loading(true);
    } else if (open_pdf === null) return;
    else set_loading(false);
  }, [open_pdf]);

  const downloadPDF = () => {
    new_page.document.body.appendChild(pdfLoadingRef.current);

    let page = pdfPageRef.current;

    var HTML_Width = page.offsetWidth;
    var HTML_Height = page.offsetHeight;
    var top_left_margin = 3;
    var PDF_Width = HTML_Width + top_left_margin * 2;
    var PDF_Height = HTML_Height * 1.5 + top_left_margin * 2;
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    const pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height], true);

    html2canvas(page, { scale: 2, useCORS: true }).then(function (canvas) {
      canvas.getContext("2d");

      let imgData = canvas.toDataURL("image/png");
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );

      setTimeout(() => {
        new_page.location.href = pdf.output("bloburl", "Estimate");
        set_open_pdf(false);
      }, 1000);
    });
  };

  const get_estimate_details = () => {
    set_loading(true);
    getEstimateDetails({
      hoaId,
      estimateId,
    })
      .then((res) => {
        set_estimate_data(res);
        console.log({
          res,
        });
        set_client_data({
          name: res.acceptedName ?? "",
          date:
            res.acceptedDate && res.acceptedDate !== ""
              ? new Date(res.acceptedDate).toISOString().split("T")[0]
              : new Date().toISOString().split("T")[0],
          acceptedEstimateIds: [],
        });
        set_loading(false);
      })
      .catch((res) => {
        console.log({ res });
        set_loading(false);
        toast.error(res.message ?? "Error fetching estimate details");
        setTimeout(() => set_warning({}), 4000);
      });
  };

  const table = () => {
    if (!estimate_data || !estimate_data.lines) return [];

    return estimate_data?.lines.map((val) => {
      return {
        id: val.id,
        isDraft: val.isDraft,
        isChangeOrder: val.isChangeOrder,
        estimateStatus: estimate_data.estimateStatus,
        materials: val.materials,
        row: [
          val.title ?? "",
          val.description ?? "",
          val.rate ? Number(val.rate).toFixed(2) : "",
          val.quantity ?? "",
          val.amount ? Number(val.amount).toFixed(2) : "",
          val.acceptedName ?? "",
          val.acceptedDate ? date_format(val.acceptedDate) : "",
        ],
      };
    });
  };

  const post_estimate = ({ reject = false }) => {
    if (!estimate_data?.lines?.find((val) => val.isDraft)) {
      toast.error("You’ve already responded to this Estimate.");

      return;
    }

    if (
      estimate_data?.expirationDate &&
      new Date(estimate_data?.expirationDate) < new Date()
    ) {
      toast.error("Sorry, the Estimate has expired.");

      return;
    }

    if (loading) return;

    if (!reject && client_data.acceptedEstimateIds.length === 0) {
      toast.warning("Please select atleast one item to proceed.");
      return;
    }

    if (client_data.name === "" || !client_data.name) {
      set_warning((warning) => {
        return { ...warning, name_not_filled: true };
      });
      return;
    }
    if (client_data.date === "" || !client_data.date) {
      set_warning((warning) => {
        return { ...warning, date_not_filled: true };
      });
      return;
    }
    set_loading(true);
    set_loading(true);
    postEstimateAccept({
      hoaId,
      estimateId: estimate_data.id,
      data: {
        estimate_id: estimate_data.id,
        acceptName: client_data.name,
        acceptDate: client_data.date,
        acceptIds: reject ? [] : client_data.acceptedEstimateIds,
      },
    })
      .then((res) => {
        toast.success(
          client_data.acceptedEstimateIds?.length > 0
            ? "Estimate Accepted Successfully"
            : "Estimate Rejected Successfully"
        );

        setTimeout(() => set_warning({}), 4000);
        get_estimate_details();
        set_loading(false);
        console.log({ res });
      })
      .catch((res) => {
        set_loading(false);
        toast.error(res.message ?? "Error accepting estimate");
        console.log({ res });
      });
  };

  const estimateTotal =
    "$" +
    Number(
      estimate_data?.lines
        ?.filter(
          (ele) =>
            (ele.estimateStatus !== "SENT" && !ele.isDraft) ||
            client_data?.acceptedEstimateIds?.includes(ele.id)
        )
        ?.reduce(
          (sum, val) =>
            sum +
            (Number(val?.amount ?? 0) +
              Number(
                val?.materials?.reduce(
                  (sum, ele) => sum + Number(ele.amount ?? 0),
                  0
                ) ?? 0
              )),
          0
        )
        ? estimate_data?.lines
            ?.filter(
              (ele) =>
                (ele.estimateStatus !== "SENT" && !ele.isDraft) ||
                client_data?.acceptedEstimateIds?.includes(ele.id)
            )
            ?.reduce(
              (sum, val) =>
                sum +
                (Number(val?.amount ?? 0) +
                  Number(
                    val?.materials?.reduce(
                      (sum, ele) => sum + Number(ele.amount ?? 0),
                      0
                    ) ?? 0
                  )),
              0
            )
        : 0
    ).toFixed(2);

  if (loading)
    return (
      <SRBase hoaId={hoaId} page={url}>
        <div className="w-full py-24 flex items-center justify-center">
          <LoadingCircleIcon className="h-12 text-blue-500" />
        </div>
      </SRBase>
    );
  else if (!estimate_data)
    return (
      <SRBase hoaId={hoaId} page={url}>
        <div className="w-full py-24 flex items-center justify-center">
          <h1 className="text-2xl text-gray-500">Estimate Not Found</h1>
        </div>
      </SRBase>
    );
  else
    return (
      <SRBase hoaId={hoaId} page={url}>
        <div
          className={"min-w-full " + (open_pdf ? "p-3" : "")}
          style={open_pdf ? { minWidth: "1200px" } : {}}
          ref={pdfPageRef}
        >
          <div className="hidden">
            <div
              id="pdf_popup_loading"
              style={{ width: "100%", textAlign: "center", paddingTop: "50px" }}
            >
              <img
                src="https://media.tenor.com/DPEfCqnChk0AAAAi/loading-slow-net.gif"
                style={{ height: "100px" }}
              />
            </div>
          </div>

          <div className={"bg-white rounded"}>
            <div className="p-5 rounded-sm">
              <div className="sm:w-full flex flex-row items-start justify-between">
                <span className="text-gray-700 mt-2 font-semibold text-xl flex flex-row items-center gap-1 whitespace-pre">
                  {!open_pdf && (
                    <Link href={"/hoa/" + hoaId + "/sr/estimates"}>
                      <div className="text-gray-900 hover:text-gray-500 cursor-pointer mr-1">
                        <Arrow className="h-3 transform rotate-180" />
                      </div>
                    </Link>
                  )}
                  <span>Estimate</span>&nbsp;
                  <span className="relative top-px whitespace-pre">
                    {EstimatePrefix(estimate_data)}
                  </span>
                </span>
                {open_pdf ? (
                  <img
                    src={"/doorz_logo.png"}
                    alt="Logo"
                    className="mt-20 w-48"
                  />
                ) : (
                  <img
                    src={"/doorzLogo.svg"}
                    alt="Logo"
                    className="mt-20 w-48"
                  />
                )}
                <div className="mt-36 mr-4 flex flex-col text-gray-700 text-sm text-right">
                  {[
                    "11693 San Vicente Blvd Suite 508",
                    "Los Angeles, CA 90049",
                    "310-804-5567",
                    "service@helpsquad.us",
                  ].map((text, text_index) => (
                    <span key={text_index}>{text}</span>
                  ))}
                </div>
              </div>
              <div className="mt-12 flex flex-row sm:w-full justify-between gap-3">
                <div className="flex flex-col gap-8">
                  <div className="ml-5 flex flex-col">
                    <span className="font-semibold">Bill To</span>
                    {estimate_data?.ticketDto?.createdUnderContactId !==
                    null ? (
                      <div
                        style={{ minHeight: "45px", minWidth: "200px" }}
                        className={
                          "flex flex-col gap-px px-3 py-2 bg-zinc-50 rounded mt-1 text-sm text-gray-700"
                        }
                      >
                        <span className={open_pdf ? "relative bottom-1 " : ""}>
                          {estimate_data?.ticketDto?.primaryContact
                            ?.firstName ?? ""}{" "}
                          {estimate_data?.ticketDto?.primaryContact?.lastName ??
                            ""}
                        </span>
                        <span className={open_pdf ? "relative bottom-1 " : ""}>
                          {estimate_data?.ticketDto?.hoaName ?? ""}
                        </span>
                        {estimate_data?.ticketDto &&
                          addressFormat(
                            estimate_data?.ticketDto,
                            open_pdf ? "relative bottom-1 " : ""
                          )}
                      </div>
                    ) : estimate_data?.ticketDto?.createdUnderClientId !==
                      null ? (
                      <div
                        style={{ minHeight: "45px", minWidth: "200px" }}
                        className={
                          "flex flex-col gap-px px-3 py-2 bg-zinc-50 rounded mt-1 text-sm text-gray-700"
                        }
                      >
                        <span className={open_pdf ? "relative bottom-1 " : ""}>
                          {estimate_data?.ticketDto?.hoaName ?? ""}
                        </span>
                        <span className={open_pdf ? "relative bottom-1 " : ""}>
                          {estimate_data?.ticketDto?.streetAddress1 ?? ""}
                        </span>
                        <span className={open_pdf ? "relative bottom-1 " : ""}>
                          {estimate_data?.ticketDto?.streetAddress2 ?? ""}
                        </span>
                        <span className={open_pdf ? "relative bottom-1 " : ""}>
                          {estimate_data?.ticketDto?.city
                            ? estimate_data?.ticketDto?.city + ","
                            : ""}{" "}
                          {estimate_data?.ticketDto?.state ?? ""}{" "}
                          {estimate_data?.ticketDto?.zip ?? ""}
                        </span>
                        <span className={open_pdf ? "relative bottom-1 " : ""}>
                          <span className="font-semibold">Attention To: </span>{" "}
                          {estimate_data?.ticketDto?.primaryContact
                            ?.firstName ?? ""}{" "}
                          {estimate_data?.ticketDto?.primaryContact?.lastName ??
                            ""}
                          {estimate_data?.ticketDto?.primaryContact
                            ?.firstName ||
                          estimate_data?.ticketDto?.primaryContact?.lastName
                            ? ", "
                            : ""}
                          {estimate_data?.ticketDto?.clientName ?? ""}
                        </span>
                      </div>
                    ) : (
                      <div
                        style={{ minHeight: "45px", minWidth: "200px" }}
                        className={
                          "flex flex-col gap-px px-3 py-2 bg-zinc-50 rounded mt-1 text-sm text-gray-700"
                        }
                      >
                        <span className={open_pdf ? "relative bottom-1 " : ""}>
                          {estimate_data?.ticketDto?.streetAddress1 ?? ""}
                        </span>
                        <span className={open_pdf ? "relative bottom-1 " : ""}>
                          {estimate_data?.ticketDto?.streetAddress2 ?? ""}
                        </span>
                        <span className={open_pdf ? "relative bottom-1 " : ""}>
                          {estimate_data?.ticketDto?.city
                            ? estimate_data?.ticketDto?.city + ","
                            : ""}{" "}
                          {estimate_data?.ticketDto?.state ?? ""}{" "}
                          {estimate_data?.ticketDto?.zip ?? ""}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-4 mr-5 flex flex-col gap-8 text-right">
                  <div className="flex flex-col justify-end">
                    <span className="font-semibold">Estimate Date</span>
                    <span
                      className={
                        "text-gray-700 px-3 py-2 bg-zinc-50 rounded mt-1 text-sm"
                      }
                    >
                      <span className={open_pdf ? "relative bottom-1" : ""}>
                        {" "}
                        {date_format &&
                          (date_format(estimate_data?.estimateDate) ?? "")}
                      </span>
                    </span>
                  </div>

                  <div className="flex flex-col justify-end">
                    <span className="font-semibold">Expiration Date</span>
                    <span
                      className={
                        "text-gray-700 px-3 py-2 bg-zinc-50 rounded mt-1 text-sm"
                      }
                    >
                      <span className={open_pdf ? "relative bottom-1" : ""}>
                        {" "}
                        {date_format &&
                          (date_format(estimate_data?.expirationDate) ?? "")}
                      </span>
                    </span>
                  </div>

                  <div className="sm:mt-5 text-gray-700 flex flex-col">
                    <span className="font-semibold text-lg">Amount</span>
                    <span className="font-bold text-2xl text-green-600">
                      {estimateTotal}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-5 w-full bg-white">
                <EstimateTable
                  print_style={open_pdf}
                  grid="grid-cols-8"
                  rows={table()}
                  headings={headings}
                  doller_symbol={[3, 5]}
                  rows_className={[
                    "text-blue-600",
                    "",
                    "",
                    "",
                    "text-green-600",
                    "",
                    "",
                  ]}
                  {...{ client_data, set_client_data }}
                />
              </div>
            </div>

            <div
              className={
                "mt-5 flex flex-col-reverse gap-10 w-full p-2 " +
                (open_pdf ? "" : "md:flex-row")
              }
            >
              <p
                className={
                  "text-xs text-gray-600 mt-10 whitespace-pre-line " +
                  (open_pdf ? "" : "ml-2 max-h-72 overflow-auto")
                }
                style={open_pdf ? { fontSize: "10px" } : {}}
              >
                {estimateLegalText}
              </p>
              <div className="w-full flex flex-col gap-3 items-end mx-2 sm:mr-10">
                <div
                  className={
                    "flex flex-col my-5 gap-4 items-end text-gray-700 " +
                    (open_pdf ? "mb-16" : "mb-12")
                  }
                >
                  <span className="text-lg font-semibold whitespace-pre">
                    SubTotal
                    <span className="ml-2 sm:ml-8">{estimateTotal}</span>
                  </span>

                  <div className="flex flex-row whitespace-pre gap-8 font-semibold text-lg">
                    Estimate Total
                    <span>{estimateTotal}</span>
                  </div>
                </div>

                {[
                  {
                    label: "Name",
                    type: "",
                    placeholder: "Enter name",
                    value: "name",
                    warning: "name_not_filled",
                  },
                ].map((input, input_index) =>
                  open_pdf ? (
                    <span className="ml-3 px-2 py-1 focus:bg-zinc-200 border-0 text-gray-600">
                      {client_data[input.value]}
                    </span>
                  ) : (
                    <div className="flex flex-col items-end">
                      <span className="text-xs text-red-500">
                        {warning[input.warning] ? "Fill this field" : ""}
                      </span>
                      <div className="flex flex-row-reverse items-center gap-3 w-full">
                        <input
                          type={input.type}
                          key={input_index}
                          value={client_data[input.value] ?? ""}
                          onChange={(e) => {
                            set_warning({ ...warning, [input.warning]: false });
                            set_client_data({
                              ...client_data,
                              [input.value]: e.target.value,
                            });
                          }}
                          placeholder={input.placeholder}
                          className={
                            "ml-3 p-3 rounded bg-zinc-100 focus:bg-zinc-200 border-0 text-gray-600 focus:border-0 focus:ring-0 w-80 focus:outline-none " +
                            (warning[input.warning]
                              ? "border border-red-300"
                              : "")
                          }
                        />
                        <span className="whitespace-pre text-gray-600 font-semibold text-sm">
                          {input.label}
                        </span>
                      </div>
                    </div>
                  )
                )}

                <div className="mb-3 flex flex-row-reverse items-center gap-4 w-full">
                  <div className="flex flex-col items-end">
                    <span className="text-xs text-red-500">
                      {warning.date_not_filled ? "Fill this field" : ""}
                    </span>
                    <div className="flex flex-row items-center">
                      <span className="whitespace-pre text-gray-600 font-semibold text-sm mr-px">
                        Date
                      </span>
                      {open_pdf ? (
                        <span className="ml-3 px-2 py-1 focus:bg-zinc-200 border-0 text-gray-600">
                          {date_format(client_data.date)}
                        </span>
                      ) : (
                        <DatePicker
                          selected={
                            client_data.date
                              ? new Date(client_data.date)
                              : undefined
                          }
                          onChange={(val) => {
                            set_warning({ ...warning, date_not_filled: false });
                            set_client_data({
                              ...client_data,
                              date: val,
                            });
                          }}
                          className={
                            "p-3 ml-5 rounded bg-zinc-100 focus:bg-zinc-200 border-0 text-gray-600 focus:border-0 focus:ring-0 w-80 focus:outline-none " +
                            (warning?.date_not_filled
                              ? "border border-red-300 "
                              : "")
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>

                {!open_pdf && (
                  <div className="w-full flex justify-end">
                    <button
                      onClick={() => post_estimate({})}
                      className={
                        "dashboard_accept_estimate bg-green-600 hover:bg-green-700 mt-4 text-xs text-white shadow flex flex-row justify-center items-center gap-2 px-4 py-3 w-80 rounded"
                      }
                    >
                      I ACCEPT <SentIcon className="h-3 w-3" />
                    </button>
                  </div>
                )}
                {!open_pdf && (
                  <div className="-mt-1 w-full flex justify-end">
                    <button
                      onClick={() => post_estimate({ reject: true })}
                      className={
                        "text-xs text-white shadow flex flex-row justify-center items-center gap-2 px-4 py-3 w-80 rounded bg-red-600 hover:bg-red-700"
                      }
                    >
                      REJECT <CrossIcon className="h-4" />
                    </button>
                  </div>
                )}

                <div className="-mt-1 w-full flex justify-end">
                  {!open_pdf && (
                    <button
                      onClick={() => {
                        set_new_page(window.open("", "_blank"));
                        set_open_pdf(true);
                      }}
                      className="bg-indigo-800 hover:bg-indigo-900 text-xs text-white shadow flex flex-row justify-center items-center gap-2 px-4 py-3 w-80 rounded"
                    >
                      {open_pdf ? (
                        <LoadingIcon2 className="h-4 relative bottom-2" />
                      ) : (
                        <span className="dashboard_estimate_open_pdf flex flex-row items-center gap-1">
                          View as PDF <DocumentIcon4 className="h-3 w-3" />
                        </span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {open_pdf && (
            <div
              style={{ backgroundColor: "rgba(4,0,85)", padding: "2px" }}
              className="w-full text-white text-center mt-8"
            >
              <span className="relative bottom-2">© 2023 Doorz</span>
            </div>
          )}
        </div>
      </SRBase>
    );
}
