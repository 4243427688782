import React from "react";

export default function SearchBar({ hoaId }) {
  return (
    <form
      className="w-full flex md:ml-0"
      action={"/hoa/" + hoaId + "/transactions_search/"}
      method="GET"
    >
      <label for="search_field" className="sr-only">
        Search
      </label>
      <div className="relative w-full text-gray-400 focus-within:text-gray-600 rounded">
        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-4">
          <svg
            className="h-4 w-4"
            fill="currentColor"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0916 15.9083L13.9999 12.8416C15.2 11.3453 15.7812 9.44606 15.6239 7.5344C15.4667 5.62273 14.583 3.84397 13.1545 2.56385C11.7261 1.28374 9.86143 0.599573 7.94402 0.65204C6.02662 0.704507 4.20219 1.48962 2.84587 2.84593C1.48956 4.20225 0.704446 6.02668 0.651979 7.94408C0.599512 9.86149 1.28368 11.7261 2.56379 13.1546C3.84391 14.583 5.62267 15.4667 7.53434 15.624C9.446 15.7812 11.3453 15.2001 12.8416 14L15.9083 17.0666C15.9857 17.1448 16.0779 17.2068 16.1794 17.2491C16.281 17.2914 16.3899 17.3131 16.4999 17.3131C16.6099 17.3131 16.7189 17.2914 16.8204 17.2491C16.9219 17.2068 17.0141 17.1448 17.0916 17.0666C17.2418 16.9113 17.3257 16.7036 17.3257 16.4875C17.3257 16.2714 17.2418 16.0637 17.0916 15.9083ZM8.16659 14C7.01286 14 5.88505 13.6579 4.92576 13.0169C3.96647 12.3759 3.2188 11.4649 2.77729 10.399C2.33578 9.33307 2.22026 8.16018 2.44534 7.02862C2.67042 5.89707 3.22599 4.85767 4.0418 4.04186C4.8576 3.22605 5.897 2.67048 7.02856 2.4454C8.16012 2.22032 9.333 2.33584 10.3989 2.77735C11.4648 3.21886 12.3759 3.96654 13.0168 4.92582C13.6578 5.88511 13.9999 7.01292 13.9999 8.16665C13.9999 9.71375 13.3853 11.1975 12.2914 12.2914C11.1974 13.3854 9.71368 14 8.16659 14Z"
              fill="#71717A"
            />
          </svg>
        </div>
        <input
          id="search_field"
          name="search_field"
          className="block rounded w-full h-full pl-12 pr-3 py-4 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
          placeholder="Search transactions"
          type="search"
        />
      </div>
    </form>
  );
}
