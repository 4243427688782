import React from "react";

import { accountingInputClassName } from "../../../../../common/data/inputClassName";

const fieldClassName = accountingInputClassName + " w-full";

export const tableFooter = ({ journalEntry }) => [
  { label: "", input: { readOnly: true } },
  {
    hideLabelForm: true,
    input: {
      readOnly: true,
      type: "currencyLocal",
      className: fieldClassName + " py-2",
      value: journalEntry.items?.reduce(
        (sum, leg) => sum + (isNaN(Number(leg.debit)) ? 0 : Number(leg.debit)),
        0
      ),
    },
  },
  {
    hideLabelForm: true,

    input: {
      readOnly: true,
      type: "currencyLocal",
      className: fieldClassName + " py-2",
      value: journalEntry.items?.reduce(
        (sum, leg) =>
          sum + (isNaN(Number(leg.credit)) ? 0 : Number(leg.credit)),
        0
      ),
    },
  },
  { label: "", input: { readOnly: true } },
];
