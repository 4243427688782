import { usePlaidLink } from "react-plaid-link";
import { useState } from "react";
import Modal from "../../../common/Modal";

export default function PayButton({
  id,
  ref,
  data,
  children,
  className,
  disable,
  on_open,
  on_close,
  payment_api_call,
}) {
  const [confirm_popup, set_confirm_popup] = useState(false);
  const [onSuccessFlag, setOnsuccessFlag] = useState(false);

  const onSuccess = (token, metadata) => {
    if (payment_api_call && onSuccessFlag) {
      setOnsuccessFlag(false);
      payment_api_call({ metadata, token });
    }
  };

  const onClick = () => {
    on_open && on_open();
    if (data.link_token === "") {
      set_confirm_popup(true);
    } else {
      setOnsuccessFlag(true);
      open();
    }
  };

  const pay_without_plaid = () => {
    set_confirm_popup(false);
    if (payment_api_call) payment_api_call({});
  };

  let config = {
    token: data?.link_token || "",
    onSuccess,
  };
  const { open, ready, error } = usePlaidLink(config);

  return (
    <div>
      <button
        id={id}
        ref={ref}
        className={
          className ??
          "rounded text-white font-bold text-lg h-10 w-20 " +
            (disable
              ? "bg-gray-600 text-gray-200"
              : "bg-green-500 hover:bg-green-600")
        }
        onClick={() => {
          if (!disable) onClick();
        }}
      >
        {children ? children : "Pay"}
      </button>
      <Modal
        show={confirm_popup}
        setShow={() => {
          set_confirm_popup(false);
          on_close && on_close();
        }}
        lean
      >
        <div className="flex flex-col gap-4 px-8 pb-4 pt-7">
          <span className="text-gray-700">
            A Plaid connection with your Bank Account already exists. Would you
            like to proceed with the payment?
          </span>
          <div className="w-full flex flex-row justify-end gap-4 font-semibold">
            <button
              onClick={() => {
                set_confirm_popup(false);
                on_close && on_close();
              }}
              className="focus:outline-none py-1 hover:opacity-70 text-indigo-700"
            >
              Cancel
            </button>
            <button
              onClick={pay_without_plaid}
              className="px-4 py-1 rounded text-white bg-green-600 hover:opacity-80 focus:outline-none"
            >
              Pay
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
