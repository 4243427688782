import { useEffect, useState } from "react";

import {
  getAccountsSummary,
  getAllTransactions,
  getBills,
  getVendorBalances,
  getVendors,
  getVendorTransactions,
  verifyAccountsPayable,
} from "../../../../api/ApiV2";
import SelectHeadings from "../../../common/Form/SelectHeadings";
import Modal from "../../../common/Modal";
import SwipeableScreens from "../../../common/SwipableScreens";
import TopSection from "./components/TopSection";
import { LoadingCircleIcon } from "../../../common/AppIcons";
import Transactions from "./components/Transactions";
import Popup from "../../../common/Popup";
import TransactionLegsPopup from "../../components/transactionLegsPopup";
import CreateVendorPopup from "../../components/createVendor/CreateVendorPopup";
import DropdownMenu from "./components/DropdownMenu";
import Vendors from "./components/vendors";
import Revenue from "../revenue";
import CheckDetailPopup from "../../components/check/CheckDetailPopup";
import BillDetailPopup from "../../components/bill/BillDetailPopup";

const transactionLimitPerPage = 25;
export default function Expense({
  hoaId,
  source = null,
  previousPage = null,
  nextPage = null,
  prompt = "",
  set_height_rerender = null,
}) {
  const [page, set_page] = useState(
    source === "HOA_CREATION" ? "Vendors" : "Transaction"
  );
  // "Transaction", "Vendors"
  const [popup, set_popup] = useState();
  // type : BILL, VENDOR
  const [warning, set_warning] = useState();

  const [vendors, setVendors] = useState();
  const [vendorsPage, setVendorsPage] = useState({ current: 0, total: 0 });
  const [vendor_transactions, vendor_setTransactions] = useState({
    vendorId: undefined,
    data: [],
  });
  const [vendor_transactionPage, vendor_setTransactionPage] = useState({
    current: 0,
    total: 0,
  });
  const [vendor_transaction_loading, set_vendor_transaction_loading] =
    useState(false);

  const [transaction_list, set_transaction_list] = useState([]);
  const [transaction_list_page, set_transaction_list_page] = useState({
    current: 0,
    total: 0,
  });
  const [transaction_list_loading, set_transaction_list_loading] =
    useState(true);
  const [transaction_popup, set_transaction_popup] = useState({});
  const [popup_loading, set_popup_loading] = useState(false);
  const [payableTotal, setPayableTotal] = useState(0);

  useEffect(() => {
    fetchAccountsSummary();
  }, []);

  const fetchAccountsSummary = () => {
    getAccountsSummary({ hoaId })
      .then((res) => {
        console.log(res);
        setPayableTotal(res.accounts_payable?.current_amount ?? 0);
      })
      .catch((_) => {});
  };

  // fetch vendor list
  useEffect(() => {
    fetchVendors();
  }, [vendorsPage.current]);

  const fetchVendors = () => {
    getVendorBalances({
      hoaId,
      page: vendorsPage.current,
    })
      .then((res) => {
        setVendorsPage((vendorsPage) => {
          return { ...vendorsPage, total: res.count };
        });
        setVendors(res.results);
        set_height_rerender &&
          set_height_rerender((height_rerender) => !height_rerender);
      })
      .catch((_) => {});
  };

  const fetchVendorTransactions = () => {
    if (hoaId && vendor_transactions.vendorId) {
      set_vendor_transaction_loading(true);
      getBills({
        hoaId,
        vendor: vendor_transactions.vendorId,
        page: vendor_transactionPage.current,
        page_size: transactionLimitPerPage,
      })
        .then((data) => {
          set_vendor_transaction_loading(false);
          vendor_setTransactionPage((vendor_transactionPage) => {
            return {
              ...vendor_transactionPage,
              total: data.count,
            };
          });
          vendor_setTransactions((vendor_transactions) => {
            return {
              ...vendor_transactions,
              data: data.results,
            };
          });
          set_height_rerender &&
            set_height_rerender((height_rerender) => !height_rerender);
        })
        .catch((res) => {
          console.log(res);
          set_vendor_transaction_loading(false);
        });
    }
  };

  // fetch transaction list
  useEffect(() => {
    fetch_transaction();
    getVendorTransactions(hoaId);
  }, [transaction_list_page.current]);

  const fetch_transaction = () => {
    getAllTransactions({
      hoaId: hoaId,
      page: transaction_list_page.current * transactionLimitPerPage,
      limit: transactionLimitPerPage,
    })
      .then((res) => {
        set_transaction_list_page((transaction_list_page) => {
          return {
            ...transaction_list_page,
            total: res.count,
          };
        });
        console.log("res transactions", res.results);
        set_transaction_list(res.results);
        set_transaction_list_loading(false);
        set_height_rerender &&
          set_height_rerender((height_rerender) => !height_rerender);
      })
      .catch((res) => {
        console.log(res);
        set_transaction_list_loading(false);
      });
  };

  // if source is hoa creation

  useEffect(() => {
    source === "HOA_CREATION" && verify_accounts_payable();
  }, []);

  const verify_accounts_payable = (nextScreen) => {
    if (!popup_loading) {
      set_popup_loading(true);
      verifyAccountsPayable({ hoaId: hoaId })
        .then((res) => {
          set_popup_loading(false);
          if (Number(res.Balance) === 0.0) {
            set_warning();
            nextScreen && nextPage();
          } else
            set_warning(
              "Please enter existing Bills for the amount " +
                res.Balance +
                " in order to match the Accounts Payable entry."
            );
        })
        .catch((res) => {
          console.log(res);
          set_popup_loading(false);
        });
    }
  };

  if (prompt === "INVOICE_PAGE")
    return (
      <Revenue
        hoaId={hoaId}
        source={source}
        previousPage={previousPage}
        nextPage={nextPage}
        set_height_rerender={set_height_rerender}
      />
    );
  else
    return (
      <div>
        {source === "HOA_CREATION" && (
          <h1 className="text-2xl mt-5 mb-8 font-semibold text-indigo-600">
            Add unpaid bills
          </h1>
        )}

        <TopSection
          {...{ vendors }}
          total={payableTotal}
          show={page === "Transaction"}
        />

        <div className="border-b border-gray-300 flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <SelectHeadings
              headings={(source === "HOA_CREATION"
                ? ["Vendors"]
                : ["Transaction", "Vendors"]
              ).map((val) => {
                return { label: val, value: val };
              })}
              selected={page}
              onChange={(val) => {
                set_page(val);
              }}
              base_heading_width={120}
              className="relative top-1"
              label_className="md:text-xl font-semibold"
            />
          </div>
          <DropdownMenu
            {...{ set_popup, set_transaction_popup }}
            className="mb-1"
          />
        </div>

        <SwipeableScreens
          rerenderers={[
            vendors,
            vendor_transactions,
            vendor_transactionPage,
            vendor_transaction_loading,
            transaction_list,
            transaction_list_loading,
            transaction_list_page,
          ]}
          currentSlide={page === "Transaction" ? 1 : 2}
        >
          <Transactions
            transactions={transaction_list}
            loading={transaction_list_loading}
            page={transaction_list_page}
            set_page={set_transaction_list_page}
            {...{
              transactionLimitPerPage,
              set_transaction_popup,
            }}
          />
          <Vendors
            {...{
              transactionLimitPerPage,
              vendors,
              vendorsPage,
              setVendorsPage,
              set_popup,
              set_transaction_popup,
              vendor_transactions,
              vendor_setTransactions,
              vendor_transactionPage,
              vendor_setTransactionPage,
              fetchVendorTransactions,
              vendor_transaction_loading,
            }}
          />
        </SwipeableScreens>

        {source === "HOA_CREATION" && (
          <div className="w-full mt-8 mb-4 flex flex-row-reverse items-center">
            <button
              onClick={() => {
                if (!warning) verify_accounts_payable(true);
              }}
              style={{
                backgroundColor: "#2A32FD",
                border: "2px solid #2A32FD",
              }}
              className={
                "w-24 h-10 flex items-center justify-center rounded text-white shadow " +
                (warning ? "opacity-70" : "hover:hover:opacity-70")
              }
            >
              Proceed
            </button>

            <span className="text-red-600 text-xs mx-4">{warning}</span>
          </div>
        )}

        {/* popups */}

        <CreateVendorPopup
          hoaId={hoaId}
          show={popup?.type === "VENDOR"}
          setShow={(_) => {
            fetchVendors();
            set_popup();
          }}
          data={popup?.data}
        />

        <Popup
          show={
            popup_loading ||
            (transaction_popup.popup &&
              (transaction_popup.type === "BILL_NORMAL" ||
                transaction_popup.type === "CHECK_NORMAL" ||
                transaction_popup.type === "EXPENSE"))
              ? true
              : false
          }
          setShow={() => {
            if (!popup_loading)
              set_transaction_popup({ ...transaction_popup, popup: false });
          }}
          className={"sm:max-w-4xl"}
        >
          {popup_loading ? (
            <div className="w-full py-20 flex items-center justify-center">
              <LoadingCircleIcon
                className="text-blue-500"
                style={{ height: "30px" }}
              />
            </div>
          ) : transaction_popup.popup ? (
            <TransactionLegsPopup
              type={transaction_popup.type}
              transactionId={transaction_popup.transaction?.id}
              openDetailsPopup={() => {
                if (transaction_popup.type === "BILL_NORMAL")
                  set_transaction_popup((transaction_popup) => ({
                    ...transaction_popup,
                    type: "BILL",
                  }));
                else {
                  set_transaction_popup((transaction_popup) => ({
                    ...transaction_popup,
                    type: "CHECK",
                  }));
                }
              }}
              closePopup={() =>
                set_transaction_popup({ ...popup, popup: false })
              }
              hoaId={hoaId}
            />
          ) : null}
        </Popup>

        <Modal
          show={
            transaction_popup.popup &&
            (transaction_popup.type === "INVOICE" ||
              transaction_popup.type === "BILL" ||
              transaction_popup.type === "CHECK")
              ? true
              : false
          }
          setShow={() => {
            if (transaction_popup.type === "BILL") return;
            else if (transaction_popup.type === "CHECK")
              set_transaction_popup({
                ...transaction_popup,
                type: "CHECK_NORMAL",
              });
            else set_transaction_popup({ ...transaction_popup, popup: false });
          }}
          wide
        >
          {transaction_popup.type === "BILL" ? (
            <BillDetailPopup
              hoaId={hoaId}
              source={source}
              billId={transaction_popup.transaction?.bill}
              onSuccess={() => {
                if (
                  transaction_popup.transaction?.bill &&
                  page === "Transaction"
                )
                  set_transaction_popup({
                    ...transaction_popup,
                    type: "BILL_NORMAL",
                  });
                else {
                  source === "HOA_CREATION" && verify_accounts_payable();
                  fetchVendors();
                  fetchVendorTransactions();
                  fetch_transaction();
                  fetchAccountsSummary();
                  set_transaction_popup({
                    ...transaction_popup,
                    popup: false,
                  });
                }
              }}
            />
          ) : transaction_popup.type === "CHECK" ? (
            <CheckDetailPopup
              hoaId={hoaId}
              onSuccess={() => {
                set_transaction_popup({
                  ...transaction_popup,
                  type: "CHECK_NORMAL",
                });
                fetchVendors();
              }}
              transactionId={transaction_popup.transaction?.id}
            />
          ) : null}
        </Modal>
      </div>
    );
}
