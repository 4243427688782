import React, { useEffect, useState } from "react";

import "../css/buttonAnimation.css";
import { Link } from "raviger";

export default function Welcome({ className }) {
  return (
    <div
      className={"min-h-screen flex flex-row items-center gap-20 " + className}
    >
      <div className="flex flex-col items-center sm:items-start justify-center text-center sm:text-left gap-4">
        <span
          style={{ lineHeight: "3rem" }}
          className="font-bold text-2xl sm:text-4xl"
        >
          Doorz is the gateway to managing & maintaining your property
        </span>
        <span className="font-light text-lg">
          Doorz maintenance is FREE to use for lifetime. We have great other
          features to unlock when you’re ready
        </span>
        <Link href="/register">
          <div
            style={{
              backgroundColor: "#2A32FD",
              color: "white",
            }}
            className="button_animation_1 whitespace-pre flex flex-row items-center gap-1 relative rounded-md px-10 py-3 text-white font-semibold overflow-hidden"
            data-text={"Book Service"}
          >
            {"Book Service".split(" ").map((word, word_index) => (
              <span key={word_index} className="align-middle">
                {word}
              </span>
            ))}
          </div>
        </Link>
      </div>
      <img src="/hero_door.png" className="lg:mr-20 hidden md:block w-60" />
    </div>
  );
}
