import React, { useState } from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";

import { LoadingAnimated, SentIcon } from "../../../../common/AppIcons";
import CommentSection from "./CommentSection";
import { postArchitecturalReviewDiscussionReply } from "../../../../../api/Api";
import { ProfilePic } from "../../../../community/pages/boardMeeting/constants";
import { dateStringFormat } from "../../../../../utils/DateUtils";
import { postArchitecturalComment } from "../../../../../api/ApiV2";

// const find_time_difference = (comment_time) => {
//   const today = new Date();
//   const endDate = new Date(comment_time);
//   const days = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60 * 24));
//   const hours = parseInt((Math.abs(endDate - today) / (1000 * 60 * 60)) % 24);
//   const minutes = parseInt(
//     (Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60)) % 60
//   );
//   const seconds = parseInt(
//     (Math.abs(endDate.getTime() - today.getTime()) / 1000) % 60
//   );

//   if (days > 0 || hours > 12)
//     return (
//       new Date(comment_time).toLocaleTimeString([], {
//         hour: "2-digit",
//         minute: "2-digit",
//       }) +
//       " " +
//       dateStringFormat(comment_time)
//     );
//   else if (hours > 0) return hours + " hour ago";
//   else if (minutes > 0) return minutes + " min ago";
//   else return seconds + " second ago";
// };
export default function Comment({
  key,
  comment,
  comments,
  hoaId,
  id,
  get_discussion_comments,
  member,
  read_only,
}) {
  const [reply, set_reply] = useState("");
  const [show_reply, set_show_reply] = useState(true);
  const [show_reply_input, set_show_reply_input] = useState(false);
  const [loading, set_loading] = useState(false);

  const find_replys = () => comments.filter((val) => val.parent === comment.id);

  const sent_reply = () => {
    if (reply !== "") {
      set_loading(true);
      postArchitecturalComment({
        hoaId: hoaId,
        id: id,
        data: {
          parent: comment?.id,
          description: reply,
          comment_type: "REPLY",
        },
      })
        .then((_) => {
          get_discussion_comments();
          set_reply("");
          set_show_reply_input(false);
          set_loading(false);
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
        });
    }
  };

  return (
    <div key={key} className="flex flex-row gap-4 p-1 min-w-full">
      <div className="flex flex-col items-center">
        <ProfilePic
          className="shadow-none w-10 h-10 border"
          member={{
            first_name: comment?.created_by?.first_name ?? "",
            last_name: comment?.created_by?.last_name ?? "",
          }}
        />
        <div
          className={
            find_replys()?.length > 0
              ? "my-3 flex flex-grow w-px bg-gray-300 rounded"
              : ""
          }
        >
          &nbsp;
        </div>
      </div>

      <div className="flex flex-col items-start w-full">
        <div className="flex flex-row items-center gap-2">
          <span className="font-bold text-gray-800">
            {comment?.created_by?.first_name ?? ""}{" "}
            {comment?.created_by?.last_name ?? ""}
          </span>
        </div>
        {comment.comment_type === "APPROVE" ||
        comment.comment_type === "DENY" ? (
          <MarkdownPreview
            className="mt-2 break-words w-full text-gray-600 border-none outline-none py-px"
            source={comment.description ?? ""}
          />
        ) : (
          <span className="mt-2 text-gray-600">{comment.description}</span>
        )}

        {!read_only && (
          <button
            onClick={() => {
              if (show_reply_input) set_reply("");
              set_show_reply_input((show_reply_input) => !show_reply_input);
            }}
            style={{ color: "#2A32FD" }}
            className={
              "mt-2 border-0 outline-none focus:outline-none font-bold text-xs " +
              (show_reply_input
                ? "hidden "
                : read_only
                ? "opacity-50"
                : "hover:opacity-70")
            }
          >
            Reply
          </button>
        )}
        <div className="flex flex-col gap-2 w-full">
          {!read_only && (
            <div
              className={
                "flex flex-row items-center mt-3 " +
                (show_reply_input ? "h-min" : "h-0 invisible")
              }
            >
              <ProfilePic
                member={member}
                className="shadow-none border h-10 w-10"
              />
              <input
                value={reply}
                onChange={(e) => set_reply(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && sent_reply()}
                placeholder="Reply..."
                className="ml-3 flex flex-grow px-4 py-3 text-gray-800 rounded bg-gray-100 focus:bg-gray-200 focus:outline-none"
              />
              <button
                onClick={sent_reply}
                className={
                  "flex items-center justify-center cursor-pointer px-3 h-full bg-gray-100 text-blue-600 hover:text-blue-700 hover:bg-gray-200"
                }
              >
                {loading ? (
                  <LoadingAnimated className="w-5 h-5 ml-1" />
                ) : (
                  <SentIcon className="w-5 h-5 ml-1" />
                )}
              </button>
              <button
                onClick={() => {
                  if (show_reply_input) set_reply("");
                  set_show_reply_input((show_reply_input) => !show_reply_input);
                }}
                className={
                  "ml-2 flex items-center justify-center cursor-pointer px-4 h-full bg-gray-100 font-bold hover:bg-gray-200 text-red-300 hover:text-400"
                }
              >
                <span className="relative bottom-px">&#10005;</span>
              </button>
            </div>
          )}

          {find_replys() &&
            find_replys().length > 0 &&
            (show_reply ? (
              <div className="flex flex-col">
                <CommentSection
                  comments={comments}
                  comment={comment}
                  hoaId={hoaId}
                  id={id}
                  get_discussion_comments={get_discussion_comments}
                  show_reply_input
                  member={member}
                  read_only={read_only}
                />
              </div>
            ) : (
              <div
                onClick={() => set_show_reply(true)}
                className="flex flex-row gap-4 items-center cursor-pointer hover:opacity-80"
              >
                <div className="flex flex-row items-center gap-0">
                  {find_replys().map((ele, ele_index) => (
                    <div key={ele_index} className="w-3 overflow-visible">
                      <ProfilePic
                        className="shadow-none w-6 h-6 border"
                        member={{
                          first_name: ele.first_name ?? "",
                          last_name: ele.last_name ?? "",
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className="font-semibold text-gray-500 text-sm">
                  {find_replys()?.length}{" "}
                  {find_replys().length === 1 ? " Reply" : " Replies"}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
