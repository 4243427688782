import React from "react";

export default function Heading({
  headings,
  className,
  style,
  readOnly = true,
}) {
  return (
    <div className={readOnly ? "" : "mr-8"}>
      <div className={"py-3 " + className} style={style}>
        {headings?.map((heading, heading_index) => (
          <span
            key={heading_index}
            className={"w-full tracking-wide flex " + heading.className}
          >
            {heading.label}
          </span>
        ))}
      </div>
    </div>
  );
}
