import { useEffect, useState } from "react";
import { Link } from "raviger";
import { useRecoilState } from "recoil";

import {
  AppointmentsIcon,
  Arrow,
  ArrowSimpleIcon,
  ImageIcon,
  LoadingCircleIcon,
  LocationIcon,
  PersonIcon,
  PhoneIcon2,
} from "../../../common/AppIcons";
import ImgPopup from "../../../common/ImgPopup";
import ImgThumbnail from "../../../common/ImgThumbnail";
import SRPrefix, { ticketPrefix } from "../../../../utils/SRPrefix";
import SRBase from "../../srBase";
import { userAtom } from "../../../../store/UserAtom";
import { getSRsUnderTicket, getTicketDetails } from "../../../../api/ApiV2";

const detail_icon_className = "w-5 h-5 text-indigo-600";
const detail_icon__style = { height: "1.5rem" };

export default function TicketDetails({ ticketId, hoaId, url }) {
  const [user] = useRecoilState(userAtom);

  const [loading, setLoading] = useState(true);
  const [ticketData, setTicketData] = useState();
  const [srLoading, setSrLoading] = useState(true);
  const [srData, setSrData] = useState();
  const [view_files, set_view_files] = useState({
    show: false,
    index: 0,
  });

  useEffect(() => {
    fetchTickets();
  }, [user.selectedUserProfile]);

  const fetchTickets = () => {
    getTicketDetails({ hoaId, ticketId })
      .then((res) => {
        console.log(res);
        setTicketData(res);
        fetchSRs();
        setLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });
  };

  const fetchSRs = () => {
    getSRsUnderTicket({ hoaId, ticketId })
      .then((res) => {
        console.log(res);
        setSrData(res);
        setSrLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setSrLoading(false);
      });
  };

  if (loading)
    return (
      <SRBase page={url} hoaId={hoaId}>
        <div className="w-full h-full py-20 flex items-center justify-center">
          <LoadingCircleIcon className="h-12 text-blue-600" />
        </div>
      </SRBase>
    );
  else if (!ticketData)
    return (
      <SRBase page={url} hoaId={hoaId}>
        <div className="w-full h-full py-20 flex items-center justify-center">
          <span className="text-gray-500 text-2xl">Ticket Not Found</span>
        </div>
      </SRBase>
    );
  else
    return (
      <SRBase page={url} hoaId={hoaId}>
        <div className="h-full bg-zinc-100 flex flex-col items-start gap-6">
          <div className="nunito px-12 py-8 w-full bg-white rounded flex flex-col gap-5">
            <div className="w-full flex flex-row items-center flex-wrap gap-5 justify-between">
              <div className="flex flex-row items-center gap-2">
                <Link
                  href={
                    "/hoa/" +
                    hoaId +
                    "/sr/" +
                    sessionStorage?.getItem("srListType")
                  }
                  className="text-gray-800 hover:text-gray-500"
                >
                  <Arrow
                    style={{ height: "16px" }}
                    className="transform rotate-180 text-gray-800"
                  />
                </Link>
                <span className="text-gray-700 font-semibold text-xl">
                  Ticket - {ticketPrefix(ticketData)}
                </span>
              </div>
              <div className="flex flex-row items-center gap-3">
                <Link href={"/hoa/" + hoaId + "/sr/messages/" + ticketId}>
                  <span className="cursor-pointer rounded-md border border-indigo-700 hover:border-indigo-500 text-indigo-800 hover:text-indigo-600 px-6 py-2 text-sm">
                    Quick Message
                  </span>
                </Link>
                {ticketData.estimateId &&
                ticketData.estimatestatus &&
                ticketData.estimatestatus !== "UNSENT" ? (
                  <Link
                    href={
                      "/hoa/" +
                      hoaId +
                      "/ticket/estimate/" +
                      ticketData.estimateId
                    }
                  >
                    <span className="rounded-lg border border-gray-400 text-sm px-7 py-2 text-gray-700 bg-zinc-100 hover:bg-zinc-200">
                      View Estimate
                    </span>
                  </Link>
                ) : (
                  <span className="rounded-lg border border-gray-400 text-sm px-7 py-2 text-gray-700 bg-zinc-100">
                    No Estimate
                  </span>
                )}
              </div>
            </div>
            <div className="mt-6 flex flex-col gap-7">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col items-start gap-3">
                  <span className="capitalize text-gray-800">Ticket Title</span>
                  <span className="p-2 pl-4 pr-8 w-full rounded bg-zinc-50 text-gray-600 text-sm">
                    {ticketData.title}
                  </span>
                </div>
              </div>
              <div className="mt-5 flex flex-col gap-3">
                {[
                  {
                    icon: (
                      <PersonIcon
                        className={detail_icon_className}
                        style={{ height: "1.3rem" }}
                      />
                    ),
                    label: ticketData.clientName ?? "",
                  },
                  {
                    icon: (
                      <LocationIcon
                        className={detail_icon_className}
                        style={detail_icon__style}
                      />
                    ),
                    label:
                      (ticketData.streetAddress1
                        ? ticketData.streetAddress1 + ", "
                        : "") +
                        (ticketData.streetAddress2
                          ? ticketData.streetAddress2 + ", "
                          : "") +
                        ticketData.city ?? "",
                  },
                  {
                    icon: (
                      <PhoneIcon2
                        className={detail_icon_className}
                        style={detail_icon__style}
                      />
                    ),
                    label:
                      (ticketData.primaryContact?.firstName ?? "") +
                      " " +
                      (ticketData.primaryContact?.lastName ?? ""),
                  },
                  {
                    icon: (
                      <PhoneIcon2
                        className={detail_icon_className}
                        style={detail_icon__style}
                      />
                    ),
                    label:
                      (ticketData.alternateContact?.firstName ?? "") +
                      " " +
                      (ticketData.alternateContact?.lastName ?? ""),
                    warning:
                      ticketData.alternateContact?.firstName ||
                      ticketData.alternateContact?.lastName
                        ? null
                        : "No alternate contact",
                  },
                ].map((ele, ele_index) => (
                  <div
                    key={ele_index}
                    className={
                      "flex flex-row items-center gap-3 text-sm text-gray-600"
                    }
                  >
                    {ele.icon}
                    {ele.warning ? (
                      <span className={"text-xs text-red-400"}>
                        {ele.warning}
                      </span>
                    ) : (
                      ele.label
                    )}
                  </div>
                ))}
              </div>
            </div>
            {ticketData.ticketMedia?.length > 0 && (
              <div className="w-full mt-4 flex flex-col gap-2 text-gray-800">
                Ticket Media
                <div
                  className={"flex flex-wrap gap-2 items-start w-full mx-auto"}
                >
                  {ticketData.ticketMedia?.map((url, url_index) => (
                    <ImgThumbnail
                      key={url_index}
                      url={url}
                      className="max-h-32"
                      onClick={() =>
                        set_view_files({
                          show: true,
                          index: url_index,
                        })
                      }
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="mt-6 flex flex-col gap-4">
              <span className="text-gray-800">Service Requests</span>
              {srLoading ? (
                <div className="w-full py-12 flex items-center justify-center">
                  <LoadingCircleIcon className="h-4 text-blue-400" />
                </div>
              ) : srData?.length > 0 ? (
                <div className="flex flex-col gap-3">
                  {srData.map((sr, sr_index) => (
                    <SRComponent
                      sr={sr}
                      hoaId={hoaId}
                      key={sr_index}
                      ticketId={ticketId}
                    />
                  ))}
                </div>
              ) : (
                <div className="w-full py-12 flex items-center justify-center">
                  <span className="text-gray-400">
                    No service requests found
                  </span>
                </div>
              )}
            </div>
          </div>
          <ImgPopup
            view={view_files.show ?? false}
            setView={() => {
              set_view_files({ show: false });
            }}
            files={ticketData.ticketMedia}
            file_index={view_files.index}
            onChange={(val) => set_view_files({ ...view_files, index: val })}
          />
        </div>
      </SRBase>
    );
}

const SRComponent = ({ hoaId, sr, ticketId }) => (
  <div
    style={{ maxWidth: "700px", color: "#130951" }}
    className="w-full px-6 py-4 flex flex-col gap-5 border border-gray-300 rounded-md"
  >
    <div className="flex flex-row items-center flex-wrap gap-3 justify-between">
      <div className="flex flex-row items-center gap-3">
        {SRPrefix(sr)}
        <div
          style={{ backgroundColor: "#F0F2FB" }}
          className={"ml-3 px-3 py-1 rounded bg-opacity-20 text-sm capitalize"}
        >
          {sr.jobType?.toLowerCase()}
        </div>

        <div
          style={{ backgroundColor: "#FAE4E8" }}
          className={"px-3 py-1 rounded bg-opacity-20 text-sm capitalize"}
        >
          {sr.subCategory?.toLowerCase()}
        </div>
      </div>
      <div className="flex flex-row items-center gap-3">
        <div
          className="flex flex-row items-center gap-2 rounded px-3 py-1 text-sm"
          style={{
            color: "#ED6C02",
            backgroundColor: "rgba(237, 108, 2, 0.16)",
          }}
        >
          <AppointmentsIcon className="h-3.5" />
          {sr.appointments?.length ?? 0}
        </div>
        <div
          className="flex flex-row items-center gap-2 rounded px-3 py-1 text-sm"
          style={{
            color: "#7B61FF",
            backgroundColor: "#F0EDFF",
          }}
        >
          <ImageIcon className="h-3.5" />
          {sr.mediaUrls?.length ?? 0}
        </div>
      </div>
    </div>
    <div className="flex flex-row flex-wrap items-end gap-5 justify-between w-full">
      <div className="flex flex-col gap-2 text-sm">
        <span className="flex flex-row items-center gap-1">
          <span className="text-gray-500 font-light"> Booking Status :</span>{" "}
          <span className="capitalize">{sr.bookingStatus?.toLowerCase()}</span>
        </span>
        <span className="flex flex-row items-center gap-1 uppercase">
          <span className="text-gray-500 font-light capitalize">
            {" "}
            Date & Time :
          </span>{" "}
          {sr.createdAt
            ? new Date(sr.createdAt).toLocaleDateString() +
              " " +
              new Date(sr.createdAt).toLocaleString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            : ""}
        </span>
      </div>
      {sr.serviceRequestId && (
        <Link
          href={
            "/hoa/" +
            hoaId +
            "/ticket/" +
            ticketId +
            "/sr/" +
            sr.serviceRequestId
          }
          className="text-sm flex flex-row items-center gap-2 text-gray-500 bg-zinc-100 hover:bg-zinc-200 rounded px-4 py-1.5"
        >
          View Details <ArrowSimpleIcon className="h-2" />
        </Link>
      )}
    </div>
  </div>
);
