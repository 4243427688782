import React, { useState } from "react";
import { CrossIcon } from "../../../../common/AppIcons";
import { ProfilePic } from "../constants";

export default function AttendeesPopup({
  view,
  set_view,
  mandatory_attendees,
  optional_attendees,
}) {
  const [attendees_type, set_attendees_type] = useState("MANDATORY");
  // MANDATORY, OPTIONAL

  return (
    <div
      className={
        "z-20 fixed inset-0 max-h-full flex items-center justify-center bg-opacity-20 bg-black " +
        (view ? "" : "hidden")
      }
      onClick={() => set_view()}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ maxHeight: "75%" }}
        className="max-w-lg w-full pt-7 rounded-md bg-white flex flex-col"
      >
        <div className="mx-8 flex flex-row justify-between items-center">
          <span className="text-xl font-semibold">See all attendees</span>
          <button
            onClick={() => set_view()}
            className="h-7 w-7 rounded-full flex items-center justify-center text-sm font-bold text-red-600 hover:text-red-400 transform duration-200"
          >
            <CrossIcon className="h-6" />
          </button>
        </div>
        <div className="px-8 grid grid-cols-2 mt-8">
          {[
            {
              value: "MANDATORY",
              label: "Mandatory Attendees",
            },
            {
              value: "OPTIONAL",
              label: "Optional Attendees",
            },
          ].map((ele, ele_index) => (
            <span
              key={ele_index}
              onClick={() => set_attendees_type(ele.value)}
              className={
                "w-full text-center cursor-pointer " +
                (ele.value === attendees_type
                  ? "text-indigo-700 font-bold"
                  : "text-gray-500")
              }
            >
              {ele.label}
            </span>
          ))}
        </div>
        <div className={"w-full px-8 mt-1 flex "}>
          <div
            className="h-1 transform-all duration-300 bg-indigo-700 rounded"
            style={
              attendees_type === "MANDATORY"
                ? { marginLeft: "1%", width: "50%" }
                : { marginLeft: "50%", width: "50%" }
            }
          />
        </div>
        <div className="w-full h-px bg-gray-300" />
        <div className="py-2 pb-4 flex flex-col overflow-auto">
          {(attendees_type === "MANDATORY"
            ? mandatory_attendees
            : optional_attendees
          )?.map((ele, ele_index) => (
            <div
              key={ele_index}
              className="flex flex-row gap-4 items-center px-8 py-2"
            >
              <ProfilePic className="h-12 w-12" member={ele} />{" "}
              <span className="font-semibold">
                {(ele?.first_name ?? "") + " " + (ele?.last_name ?? "")}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
