import React, { useState } from "react";
import { Transition } from "@tailwindui/react";

import Sidebar from "./sidebar";
import { CrossIcon2, ThreeLinesIcon } from "../../common/AppIcons";

export default function Base({
  featuresComponent,
  integrationsComponent,
  pricingComponent,
  children,
  style = {},
  className = "",
}) {
  const [sidebar_open, set_sidebar_open] = useState(true);

  return (
    <div
      className={"flex items-start bg-gray-100 rounded " + className}
      style={style}
      onKeyDown={(e) =>
        e.key === "Escape"
          ? set_sidebar_open((sidebar_open) => !sidebar_open)
          : null
      }
    >
      {/* <!-- Static sidebar for desktop --> */}

      <div className="w-full bg-white">{children}</div>

      <button
        onClick={() => {
          set_sidebar_open(true);
        }}
        className="fixed bg-gray-400 right-6 top-7 rounded p-1.5 text-white hover:text-gray-300 focus:outline-none sm:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <ThreeLinesIcon className="h-8 w-8" />
      </button>

      <div className="sm:hidden">
        {
          <div
            className={
              "fixed inset-0 flex z-40 " + (sidebar_open ? "" : "hidden")
            }
          >
            <Transition
              show={sidebar_open}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed inset-0"
              onClick={() => set_sidebar_open(false)}
            >
              <div
                className="absolute inset-0 bg-gray-600 opacity-75"
                aria-hidden="true"
              ></div>
            </Transition>

            <Transition
              show={sidebar_open}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
              className="absolute right-0 flex-1 flex flex-row max-w-xs w-full"
            >
              <div className="pt-2">
                <button
                  onClick={() => set_sidebar_open(false)}
                  className="ml-1 text-white hover:scale-110 duration-200 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Close sidebar</span>
                  <CrossIcon2 className="h-6 w-6" />
                </button>
              </div>

              <Sidebar
                featuresComponent={featuresComponent}
                integrationsComponent={integrationsComponent}
                pricingComponent={pricingComponent}
                closeSidebar={() => set_sidebar_open(false)}
              />
            </Transition>

            <div className="flex-shrink-0 w-14">
              {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
            </div>
          </div>
        }
      </div>
    </div>
  );
}
