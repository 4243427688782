import React from "react";
import SelectCard from "./common/SelectCard";

export default function VendorSelection({ vendors, data, setData, set_form }) {
  return (
    <div>
      <div className="w-full text-center mt-10 mb-6 text-gray-800 text-lg font-semibold items-center my-3">
        Please Select a Vendor
      </div>
      <div className="flex gap-4 p-4 items-center justify-center">
        {vendors.map((vendor, index) => {
          return (
            <SelectCard
              onClick={(_) => {
                setData((data) => {
                  return { ...data, vendor_type: vendor.value };
                });
              }}
              key={index}
              Icon={vendor.icon}
              label={vendor.label}
              selected={data?.vendor_type === vendor.value}
            />
          );
        })}
      </div>
      <div className="w-full flex justify-center mt-5">
        <button
          onClick={() => {
            if (data?.vendor_type === "EXTERNAL")
              set_form("EXTERNAL_VENDOR_DETAILS");
            else {
              setData({
                ...data,
                external_vendor_phone: null,
                external_vendor_email: null,
              });
              if (data?.vendor_type === "MARKETPLACE_VENDOR")
                set_form("MARKETPLACE_VENDOR_SELECTION");
              else if (data?.vendor_type === "SQUADHUB")
                set_form("JOB_SELECTION");
            }
          }}
          style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
          className="px-4 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-70"
        >
          Next
        </button>
      </div>
    </div>
  );
}
