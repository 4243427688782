import { Link } from "raviger";

import { complianceDocumentsType } from "../../common/constants";
import { DocumentIcon3 } from "../common/AppIcons";
import { useEffect, useState } from "react";
import { getHoa } from "../../api/ApiV2";
import { toast } from "react-toastify";

export default function ComplianceHome({ hoaId }) {
  const [hoaDetail, setHoaDetail] = useState(null);

  useEffect(() => {
    getHoa({ external_id: hoaId })
      .then((response) => {
        setHoaDetail(response);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in fetching Property details");
      });
  }, []);

  return (
    <div className="bg-white relative rounded-md py-12 px-8 shadow-md">
      <div className="flex flex-col items-center w-full">
        <h3 className="text-3xl font-semibold text-gray-900 tracking-tight">
          Compliance Documents
        </h3>
        <p className="mt-1 text-gray-400">
          Keep track of all Compliance documents.
        </p>

        <div className="w-full mt-10 divide-y divide-gray-100">
          {complianceDocumentsType
            .filter(
              (val) =>
                val.value !== "ARCHITECTURAL_REVIEW" ||
                hoaDetail?.type === "HOA" ||
                !hoaDetail?.type
            )
            .map((documentType) => {
              const Icon = documentType.icon;
              return (
                <div className="flex flex-row items-center gap-5 justify-between px-4 py-6 w-full">
                  <div className="flex flex-row items-center gap-4">
                    <div
                      style={{ backgroundColor: "#2A32FD" }}
                      className="p-2 rounded-md text-white"
                    >
                      <Icon className="h-5 w-5" />
                    </div>
                    <p className="text-lg leading-6 font-medium text-gray-900">
                      {documentType.label}
                    </p>
                  </div>

                  <Link
                    href={
                      "/hoa/" +
                      hoaId +
                      "/compliance_documents/" +
                      documentType.value
                    }
                  >
                    <div className="flex flex-row items-center gap-1 text-sm text-gray-500 hover:text-gray-800 cursor-pointer">
                      <DocumentIcon3 className="h-5" />
                      View Documents
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
