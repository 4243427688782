import { dateStringFormat } from "../../../../../../utils/DateUtils";

export const recent_transactions_page_data = (value) => {
  return {
    heading: "Recent Transaction History",
    table: {
      className: "grid-cols-5",
      headings: [
        { label: "Description", className: "col-span-2" },
        { label: "Date", className: "" },
        { label: "Type", className: "" },
        { label: "Amount", className: "justify-end" },
      ],
      rows: [
        {
          label: value?.description,
          className: "col-span-2",
        },
        {
          label: dateStringFormat(value?.created_date),
        },
        {
          label:
            (value?.type ?? "GENERIC").charAt(0) +
            (value?.type ?? "GENERIC").slice(1).toLowerCase(),
        },
        {
          label:
            value?.type === "JOURNAL"
              ? "---"
              : "$" + value?.computed_transaction_amount,
          className: "justify-end",
        },
      ],
    },
  };
};
