import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { navigate } from "raviger";

import {
  getStripePaymentSetup,
  payInvoice,
  payWithSavedPaymentMethodInvoice,
  savePlaid,
} from "../../api/Api";
import PaymentFailed from "../sr/pages/srDetail/components/payment_popups/PaymentFailed";
import PaymentProcessing from "../sr/pages/srDetail/components/payment_popups/PaymentProcessing";
import RequiresAction from "../sr/pages/srDetail/components/payment_popups/RequiresAction";
import Success from "../sr/pages/srDetail/components/payment_popups/Success";
import SelectPayOption from "./payment_popups/SelectPayOption";
import Loading from "../common/Loading";
import PayButton from "../accounting/common/components/PayButton";
import {
  linkPlaidAccount,
  getplaidDataHoa,
  payWithPlaid,
  payWithCard,
} from "../../api/ApiV2";

export default function PaymentPopup({
  hoaId,
  payment_method_popup,
  set_payment_method_popup,
  plaid_data,
  update_data,
  reload_pay_button,
  payId,
}) {
  const [payment_data, set_payment_data] = useState({
    //method:'ACH','CARD','saved_bank','saved_card'
  });
  const [payments_pre_data, set_payments_pre_data] = useState({
    plaid: null,
    stripe: null,
  });

  const [loading, set_loading] = useState(false);
  const payButtonACHRef = useRef(null);

  useEffect(() => {
    get_payments_pre_data();
  }, []);

  const get_payments_pre_data = () => {
    set_loading(true);
    // getStripePaymentSetup({ hoaId: hoaId })
    //   .then((res) => {
    //     console.log("payments pre data stripe:", res);
    //     set_payments_pre_data((payments_pre_data) => {
    //       return { ...payments_pre_data, stripe: res };
    //     });
    //     set_loading(false);
    //   })
    //   .catch((res) => {
    //     console.log(res);
    //     set_loading(false);
    //     toast.error(
    //       res?.readableMessage ?? res?.message ?? "Error fetching plaid data"
    //     );
    //   });

    getplaidDataHoa({ hoaId })
      .then((res) => {
        console.log("payments pre data plaid:", res);
        set_payments_pre_data((payments_pre_data) => {
          return { ...payments_pre_data, plaid: res?.results };
        });
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error fetching plaid data"
        );
      });
  };

  const pay_with_saved_payment = () => {
    set_loading(true);
    // if (payment_data.method === "stripe")
    //   payWithSavedPaymentMethodInvoice({
    //     hoaId: hoaId,
    //     invoice_id: payId,
    //     payment_method_id: payment_data.id,
    //   })
    //     .then((res) => {
    //       console.log(res);
    //       set_loading(false);
    //       update_data && update_data();
    //       if (res?.payment_status === "succeeded")
    //         set_payment_method_popup({
    //           ...payment_method_popup,
    //           popup: "SUCCESS",
    //         });
    //       else if (res?.payment_status === "processing")
    //         set_payment_method_popup({
    //           ...payment_method_popup,
    //           popup: "PROCESSING",
    //         });
    //       else if (res?.payment_status === "verify_with_microdeposits")
    //         set_payment_method_popup({
    //           ...payment_method_popup,
    //           popup: "REQUIRES_ACTION",
    //         });
    //       else if (res?.payment_status === "payment_failed")
    //         set_payment_method_popup({
    //           ...payment_method_popup,
    //           popup: "FAILED",
    //         });
    //     })
    //     .catch((res) => {
    //       console.log(res);
    //       set_loading(false);
    //       toast.error(
    //         res?.readableMessage ?? res?.message ?? "Error processing payment"
    //       );
    //     });
    // else
    payWithPlaid({
      hoaId: hoaId,
      billId: payId,
      data: { plaid_item_metadata: payment_data.id },
    })
      .then((res) => {
        console.log(res);
        set_loading(false);
        update_data && update_data();
        set_payment_method_popup({
          ...payment_method_popup,
          popup: "SUCCESS",
        });
        // if (res?.payment_status === "succeeded")
        //   set_payment_method_popup({
        //     ...payment_method_popup,
        //     popup: "SUCCESS",
        //   });
        // else if (res?.payment_status === "processing")
        //   set_payment_method_popup({
        //     ...payment_method_popup,
        //     popup: "PROCESSING",
        //   });
        // else if (res?.payment_status === "verify_with_microdeposits")
        //   set_payment_method_popup({
        //     ...payment_method_popup,
        //     popup: "REQUIRES_ACTION",
        //   });
        // else if (res?.payment_status === "payment_failed")
        // set_payment_method_popup({
        //   ...payment_method_popup,
        //   popup: "FAILED",
        // });
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error processing payment"
        );
        set_payment_method_popup({
          ...payment_method_popup,
          popup: "FAILED",
        });
      });
  };

  const pay_with_card = () => {
    set_loading(true);

    payWithCard({ hoaId, billId: payId })
      .then((res) => {
        console.log(res);

        setTimeout(() => {
          navigate(res.url);
          set_loading(false);
        }, 10);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error processing payment"
        );
      });
  };

  const save_plaid = ({ metadata, token }) => {
    console.log("plaid", metadata, token);
    set_loading(true);
    linkPlaidAccount({
      hoaId: hoaId,
      data: {
        public_token: token,
        account_id: metadata.account.id ?? null,
        account_object: metadata.account,
      },
      receiver: "hoa",
    })
      .then((res) => {
        console.log(res);
        if (res.message === "success") {
          toast.success("Plaid account Linked Successfully!");

          set_loading(false);
          get_payments_pre_data();
        } else {
          reload_pay_button(res);
          set_loading(false);
        }
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(res?.message ?? "Error. Try again.");
      });
  };

  const on_next = () => {
    if (payment_data?.method === "ACH") {
      // payButtonACHRef?.current.click();
      document?.getElementById("pay_button_from_payment_popup")?.click();
    } else if (payment_data?.method === "CARD") pay_with_card();
    else pay_with_saved_payment();
  };

  return (
    <div className="bg-gray-500 bg-opacity-70 flex flex-col items-center justify-center inset-0 fixed z-10">
      <div
        className={
          "fixed bg-gray-100 bg-opacity-40 flex items-center justify-center z-50 inset-0 " +
          (loading ? "" : "hidden")
        }
      >
        <Loading className="h-10 w-10 text-gray-500" />
      </div>

      {payment_method_popup.popup === "PAY_OPTIONS" ? (
        <SelectPayOption
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
          payment_data={payment_data}
          set_payment_data={set_payment_data}
          payments_pre_data={payments_pre_data}
          on_next={on_next}
        />
      ) : payment_method_popup.popup === "SUCCESS" ? (
        <Success
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
        />
      ) : payment_method_popup.popup === "PROCESSING" ? (
        <PaymentProcessing
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
        />
      ) : payment_method_popup.popup === "FAILED" ? (
        <PaymentFailed
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
        />
      ) : payment_method_popup.popup === "REQUIRES_ACTION" ? (
        <RequiresAction
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
        />
      ) : null}

      {plaid_data && (
        <PayButton
          id="pay_button_from_payment_popup"
          ref={payButtonACHRef}
          reload_pay_button={reload_pay_button}
          data={plaid_data}
          on_close={() => {
            set_loading(false);
          }}
          payment_api_call={save_plaid}
          className="h-0 overflow-hidden"
        />
      )}
    </div>
  );
}
