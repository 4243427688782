export const dateTimeFormat = (pre_date) => {
  if (!pre_date) return "";

  return (
    dateFormat(pre_date) +
    new Date(pre_date).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })
  );
};

const dateFormat = (pre_date) => {
  let date = new Date(pre_date);

  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();

  if (new Date().getMonth() === month && new Date().getFullYear() === year) {
    if (day === new Date().getDate()) return "Today, ";
    if (day + 1 === new Date().getDate()) return "Yesterday, ";
    if (new Date().getDate() - day <= 7)
      return new Date().getDate() - day + " days ago, ";
  }
  return (
    date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear() + " "
  );
};
