import React, { useState } from "react";
import Modal from "../../../common/Modal";
import { LeftArrowIcon } from "../../../common/AppIcons";

const user = {
  name: "user 145",
  profilePic: null,
};
const value = [
  {
    title: "title 1",
    discussions: [
      "For this, need to show like a comment from a particular user. So name and profile pic as initial would be better",
      "ssssssss",
      "dddddd",
      "fffffffff",
    ],
  },
  {
    title: "title 2",
    discussions: [
      "aaaaaaa",
      "ssssssss",
      "dddddd",
      "fffffffff",
      "ssssssss",
      "dddddd",
      "fffffffff",
    ],
  },
];
export default function ForumPopup({ show, setShow, onChange }) {
  const [select_chat, set_select_chat] = useState(false);

  const find_chat = (chat_index) =>
    value.find((chat, index) => index === chat_index);

  return (
    <div
      className={
        "bg-white rounded-md m-1 shadow flex flex-col flex-grow " +
        (show ? "" : "hidden")
      }
    >
      <span className="font-bold text-2xl px-2 p-2 bg-indigo-400 shadow rounded-t text-white">
        {select_chat !== false ? (
          <div className="flex flex-row items-center gap-1">
            {" "}
            <span
              className="p-2 rounded-full relative top-px hover:bg-indigo-500"
              onClick={() => {
                set_select_chat(false);
              }}
            >
              <LeftArrowIcon className="h-6 w-6 text-white hover:text-gray-200" />
            </span>
            {find_chat(select_chat).title}
          </div>
        ) : (
          "Forum"
        )}
      </span>
      {select_chat !== false ? (
        <div classname="flex flex-col">
          <div
            style={{
              backgroundImage: "url(/static/img/chat_background.png)",
            }}
            className="bg-gray-300 bg-cover py-3 flex flex-col gap-3 bg-no-repeat overflow-auto items-baseline"
          >
            {find_chat(select_chat).discussions.map(
              (conversation, conversation_index) => (
                <div
                  key={conversation_index}
                  className="flex flex-col justify-between bg-white shadow mx-2 rounded"
                >
                  <div className="flex flex-row text-xs bg-gray-400 font-semibold text-white rounded-t gap-3 items-center py-1 pl-1 pr-3">
                    {user.profilePic ? (
                      <img
                        className="h-5 w-5 rounded-full object-cover"
                        src={user.profilePic}
                      />
                    ) : (
                      <div className="h-5 w-5 rounded-full font-bold uppercase flex items-center justify-center bg-white text-indigo-500">
                        {user.name.trim().charAt(0)}
                      </div>
                    )}
                    {user.name}
                  </div>
                  <span className="mx-3 py-2">{conversation}</span>
                </div>
              )
            )}
          </div>
          <div className="flex flex-row items-center gap-1 w-full py-1 px-2 bg-gray-300 shadow">
            <input className="w-full px-2 py-2 rounded-lg border focus:outline-none" />
            <div className="p-2 rounded-full bg-green-400 hover:bg-green-500">
              <LeftArrowIcon className="transform rotate-180 h-6 w-6 text-white" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-1 py-5 px-2">
          {value.map((chat, chat_index) => (
            <div
              key={chat_index}
              onClick={() => {
                set_select_chat(chat_index);
              }}
              className="flex flex-row items-center justify-between gap-5 w-full border shadow bg-gray-100 hover:bg-gray-200 py-2 px-3 rounded"
            >
              <h1 className="flex flex-grow font-semibold">{chat.title}</h1>
              <span className="flex flex-row gap-3 p-1 items-center">
                <div className="rounded-full h-8 w-8 flex items-center justify-center bg-blue-400 font-bold text-white">
                  {chat.discussions.length > 99
                    ? "99+"
                    : chat.discussions.length}
                </div>
                <LeftArrowIcon className="transform rotate-180 h-6 w-6 hover:text-gray-600" />
              </span>
            </div>
          ))}
        </div>
      )}
      {select_chat === false && (
        <div className="flex flex-row-reverse w-full my-2 pr-3">
          <button
            className="px-3 py-1 text-lg tracking-wide rounded bg-indigo-500 hover:bg-indigo-600 text-white font-semibold"
            onClick={() => setShow()}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
}
