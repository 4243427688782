import React, { useState } from "react";
import { Transition } from "@tailwindui/react";

import Sidebar from "./sidebar";
import { CrossIcon2, ThreeLinesIcon } from "../../common/AppIcons";

export default function SRBase({
  hoaId,
  page,
  children,
  style = {},
  className = "",
  sr_type,
  set_sr_type,
}) {
  const [sidebar_open, set_sidebar_open] = useState(true);

  return (
    <div
      className={
        "w-full flex items-start bg-gray-100 gap-4 p-4 pl-7 rounded " +
        className
      }
      onKeyDown={(e) =>
        e.key === "Escape"
          ? set_sidebar_open((sidebar_open) => !sidebar_open)
          : null
      }
    >
      <div className="lg:hidden">
        {
          <div
            className={
              "fixed inset-0 flex z-40 " + (sidebar_open ? "" : "hidden")
            }
          >
            <Transition
              show={sidebar_open}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed inset-0"
              onClick={() => set_sidebar_open(false)}
            >
              <div
                className="absolute inset-0 bg-gray-600 opacity-75"
                aria-hidden="true"
              ></div>
            </Transition>

            <Transition
              show={sidebar_open}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              className="relative flex-1 flex flex-col max-w-xs w-full"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  onClick={() => set_sidebar_open(false)}
                  className="ml-1 text-white hover:scale-110 duration-200 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Close sidebar</span>
                  <CrossIcon2 className="h-6 w-6" />
                </button>
              </div>

              <Sidebar
                hoaId={hoaId}
                page={page}
                {...{ sr_type, set_sr_type }}
              />
            </Transition>

            <div className="flex-shrink-0 w-14">
              {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
            </div>
          </div>
        }
      </div>

      {/* <!-- Static sidebar for desktop --> */}
      <div className="mt-3 hidden lg:flex lg:flex-shrink-0 bg-indigo-400">
        <div className="flex flex-col">
          <Sidebar hoaId={hoaId} page={page} {...{ sr_type, set_sr_type }} />
        </div>
      </div>

      <button
        onClick={() => {
          set_sidebar_open(true);
        }}
        className="-ml-8 rounded px-2 border-r border-gray-200 text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <ThreeLinesIcon className="h-6 w-6" />
      </button>

      <div
        className="-ml-1 w-full overflow-auto flex flex-col focus:outline-none"
        tabindex="0"
      >
        <main
          style={style}
          className="relative z-0 overflow-y-auto overflow-x-hidden"
        >
          <div className="mt-3 mx-auto">{children}</div>
        </main>
      </div>
    </div>
  );
}
