import React, { useEffect, useState } from "react";
import { getDocumentsWithComments } from "../../../api/Api";
import { getFileName } from "../../../utils/StringUtils";

import {
  LeftArrowIcon,
  LoadingAnimated,
  PaperClipIcon,
} from "../../common/AppIcons";
import CSRFToken from "../../common/CSRFToken";
import Modal from "../../common/Modal";
import { Link } from "raviger";
import CommentSection from "./commentSection/CommentSection";
import { compliance_items } from "../../common/Data";
import { userAtom } from "../../../store/UserAtom";
import { useRecoilState } from "recoil";

export default function ArchitecturalReview({
  hoaId,
  documentId,
  documentType,
}) {
  const [user] = useRecoilState(userAtom);
  const [arcReview, setArcReview] = useState({ comments: [] });
  const [uploadDocument, setUploadDocument] = useState();

  useEffect(() => {
    if (documentId !== "update") get_document_data();
  }, [documentId, user]);

  const get_document_data = () =>
    getDocumentsWithComments(hoaId, documentId)
      .then((res) => {
        setArcReview(res);
        console.log({ res });
      })
      .catch((res) => console.log(res));

  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="flex flex-row items-center gap-2 px-4 py-5 sm:px-6">
          <Link
            href={`/hoa/${hoaId}/compliance_documents/${documentType}`}
            className="text-gray-600 hover:text-gray-900"
          >
            <LeftArrowIcon className="h-10" />
          </Link>
          <div className="">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Architectural Review Details
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Details for Proposed Architectural Alterations
            </p>
          </div>
          <div className="flex flex-grow flex-row-reverse">
            <Link
              href={`/hoa/${hoaId}/compliance_documents/${documentId}/update`}
              className="inline-flex items-center my-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Create Revision
            </Link>
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          {arcReview?.documents ? (
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Applicant</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {arcReview?.documents && arcReview?.documents[0]?.first_name}{" "}
                  {arcReview?.documents && arcReview?.documents[0]?.last_name}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {arcReview?.documents && arcReview?.documents[0]?.name}
                </dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Description
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {arcReview?.documents[0]?.description}
                </dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Item Type</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {
                    compliance_items.find(
                      (val) => val.value === arcReview?.documents[0]?.item_type
                    )?.label
                  }
                </dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Attachments
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {arcReview.documents?.map((document) =>
                      document.document_object ? (
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <PaperClipIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {getFileName(document.document_object)}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              href={document.document_object}
                              className="font-medium text-indigo-600 hover:text-indigo-500 hover:underline"
                              target="_blank"
                            >
                              view
                            </Link>
                          </div>
                        </li>
                      ) : (
                        React.null
                      )
                    )}
                    {arcReview?.documents && (
                      <li
                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm cursor-pointer"
                        onClick={(_) =>
                          setUploadDocument(
                            `/hoa/${hoaId}/compliance_documents/${arcReview?.documents[0]?.doc_id}/update`
                          )
                        }
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <PaperClipIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">
                            Add File
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0"></div>
                      </li>
                    )}
                  </ul>
                </dd>
              </div>
            </dl>
          ) : (
            <div className="sm:my-10 md:my-20">
              <LoadingAnimated className="h-12 sm:h-24 bg-white text-blue-400" />
            </div>
          )}
        </div>
      </div>
      <div className="antialiased mx-auto max-w-screen-md p-2 mt-4">
        <h3 className="my-3 text-lg font-semibold text-gray-700">Comments</h3>

        <CommentSection
          comments_to_display={arcReview?.comments?.filter(
            (comment) => !comment.object_id
          )}
          comments={arcReview?.comments?.filter((comment) => comment.object_id)}
          hoaId={hoaId}
          documentId={documentId}
          get_document_data={get_document_data}
          show_comment_box
        />
      </div>

      {uploadDocument && (
        <Modal
          show={uploadDocument !== null}
          setShow={(_) => setUploadDocument(null)}
        >
          <form
            action={uploadDocument}
            method="POST"
            enctype="multipart/form-data"
            className="px-5 pt-4 flex flex-col gap-1"
          >
            <CSRFToken />
            <label className="block text-sm font-medium text-gray-700">
              Select File to Upload
            </label>
            <div className="mt-1">
              <input
                type="file"
                name="document_object"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              {arcReview.documents &&
                (arcReview.documents.length > 1 ||
                  arcReview.documents[0]?.document_object != null) && (
                  <input
                    type="checkbox"
                    name="is_additional_file"
                    className="mt-2 focus:outline-none focus:border-0"
                    checked
                  />
                )}
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="relative inline-flex items-center my-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Upload
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
