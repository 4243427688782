import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getAllReservation } from "../../../../../../api/Api";
import {
  CalenderIcon2,
  ClockIcon,
  LeftArrowIcon,
  LoadingCircleIcon,
} from "../../../../../common/AppIcons";
import { defaultPic } from "../../../../data/defaultPic";
import AmenityAvailabilityBadge from "../../../../utility/AmenityAvailabilityBadge";
import { dateToTime } from "../../../reservations/utility/availableHours";
import { minuteTo24Time } from "../../../reservations/utility/minuteToTime";
import { time_array } from "../../data/time_array";
import {
  getAmenityPhoto,
  getAmenityReservation,
} from "../../../../../../api/ApiV2";
import Paginator from "../../../../../../common/Paginator";

export default function ReservationListAdmin({
  hoaId,
  amenity,
  edit_amenity,
  closePage,
}) {
  const [reservations, set_reservations] = useState();
  const [filters, set_filters] = useState({
    filter_start_time: 0,
    filter_end_time: 1410,
    filter_date: new Date().toISOString().split("T")[0],
  });
  const [loading, set_loading] = useState(false);
  const [image, setImage] = useState();
  const [page, setPage] = useState({
    offset: 0,
    totalCount: 0,
  });

  useEffect(() => {
    getAmenityPhoto({ hoaId, id: amenity?.id })
      .then((res) => {
        console.log(res);
        setImage(Object.values(res.photos)?.[0]?.url);
      })
      .catch((res) => {
        console.log(res);
        toast.error("Amenity picture fetching failed");
      });
  }, [amenity?.id]);

  useEffect(() => {
    if (
      filters.filter_date &&
      filters.filter_end_time &&
      (filters.filter_start_time || filters.filter_start_time === 0)
    )
      get_reservations();
  }, [
    filters.filter_date,
    filters.filter_end_time,
    filters.filter_start_time,
    page.offset,
  ]);

  const get_reservations = () => {
    set_loading(true);
    getAmenityReservation({
      hoaId,
      amenityId: amenity.id,
      offset: page.offset,
      datetime_before:
        filters.filter_date &&
        filters.filter_end_time &&
        filters.filter_start_time
          ? new Date(
              new Date(new Date(filters.filter_date).setHours(0)).setMinutes(
                filters.filter_start_time
              )
            ).toISOString()
          : undefined,
      datetime_after:
        filters.filter_date &&
        filters.filter_end_time &&
        filters.filter_start_time
          ? new Date(
              new Date(new Date(filters.filter_date).setHours(0)).setMinutes(
                filters.filter_end_time
              )
            ).toISOString()
          : undefined,
    })
      .then((res) => {
        setPage((page) => ({ ...page, totalCount: res.count }));
        set_loading(false);
        console.log(res);
        set_reservations(res?.results);
      })
      .catch((res) => {
        set_loading(false);
        toast.error(res.message ?? "Error fetching reservations");
      });
  };
  return (
    <div className="mx-auto flex flex-col items-start p-6">
      <div className="flex flex-row w-full items-center gap-5 justify-between flex-wrap">
        <span
          className="font-semibold text-lg flex flex-row items-center gap-1"
          style={{ color: "#18181B" }}
        >
          <div
            className="cursor-pointer text-gray-800 hover:text-gray-600"
            onClick={() => closePage()}
          >
            <LeftArrowIcon className="h-5" />
          </div>
          Bookings
        </span>
        <button
          className="px-3 py-1.5 text-sm whitespace-pre rounded hover:opacity-70 flex flex-row gap-2 items-center"
          style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
          onClick={() => edit_amenity()}
        >
          Edit Amenity Settings
        </button>
      </div>

      <div className="mt-6 flex flex-row items-start gap-4 h-20">
        <img src={image ?? defaultPic} alt="" className="h-full" />
        <div className="flex flex-col h-full justify-center items-start gap-3 pb-3">
          <span className="font-semibold text-lg">{amenity.name}</span>
          <AmenityAvailabilityBadge availability={amenity.amenity_type} />
        </div>
      </div>

      <div className="mt-5 w-full flex flex-row items-center justify-end gap-4 flex-wrap">
        <div className="flex flex-col gap-2">
          <span className="text-xs text-gray-600">Filter by time</span>
          <div className="flex flex-row items-center gap-1">
            <select
              style={{ fontWeight: "500" }}
              className="bg-gray-100 pl-2 pr-8 py-1 rounded border-none text-sm text-gray-700"
              value={filters?.filter_start_time}
              onChange={(e) => {
                let val = e.target.value;
                set_filters((filters) => {
                  return { ...filters, filter_start_time: val };
                });
              }}
            >
              <option hidden defaultChecked>
                Select Time
              </option>
              {time_array.slice(0, time_array.length - 1).map((ele, index) => (
                <option key={index} value={ele.value}>
                  {ele.label}
                </option>
              ))}
            </select>
            -
            <select
              style={{ fontWeight: "500" }}
              className="bg-gray-100 pl-2 pr-8 py-1 rounded border-none text-sm text-gray-700"
              value={filters?.filter_end_time}
              onChange={(e) => {
                let val = e.target.value;
                set_filters((filters) => {
                  return { ...filters, filter_end_time: val };
                });
              }}
            >
              <option hidden defaultChecked>
                Select Time
              </option>
              {time_array
                .slice(1, time_array.length - 1)
                .filter(
                  (val, index) => (filters?.filter_start_time ?? 0) < val.value
                )
                ?.map((ele, index) => (
                  <option key={index} value={ele.value}>
                    {ele.label}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-xs text-gray-600">Filter by date</span>

          <div className="flex flex-row items-center">
            <input
              type="date"
              style={{ fontWeight: "500" }}
              value={filters.filter_date}
              onChange={(e) => {
                let val = e.target.value;
                set_filters((filters) => {
                  return { ...filters, filter_date: val };
                });
              }}
              className="bg-gray-100 text-sm text-gray-700 border border-gray-100 focus:border-indigo-600 py-1 pl-2 rounded"
            />
            <CalenderIcon2
              style={{ height: "16px" }}
              className="relative right-5 bg-white pointer-events-none"
            />
          </div>
        </div>
      </div>
      <div className="mt-7 px-4 flex flex-col gap-1 w-full">
        {!loading && (!reservations || reservations.length === 0) && (
          <span className="mt-6 text-sm w-full text-center text-gray-500 py-8">
            No Reservations Found
          </span>
        )}
        {loading ? (
          <div className="w-full flex items-center justify-center">
            <LoadingCircleIcon className="h-7 mx-auto my-10" />
          </div>
        ) : (
          reservations
            ?.sort(
              (val1, val2) =>
                new Date(val2?.from_datetime) - new Date(val1?.to_datetime)
            )
            ?.map((reservation, reservation_index) => (
              <ReservationRow
                reservation={reservation}
                key={reservation_index}
              />
            ))
        )}
        <div className="w-full mt-5">
          <Paginator
            currentPage={page.offset / 20 + 1}
            totalPage={page.totalCount}
            entryPerPage={20}
            onChange={(pageNo) => {
              setPage({ ...page, offset: (pageNo - 1) * 20 });
            }}
          />
        </div>
      </div>
    </div>
  );
}

function ReservationRow({ reservation }) {
  return (
    <div className="rounded border-2 bg-white w-full grid grid-cols-3 gap-4 px-3 py-2">
      <div className="flex flex-row items-center gap-1 text-sm">
        {reservation.created_by.user?.first_name ?? ""}{" "}
        {reservation.created_by.user?.last_name ?? ""} (
        {reservation.created_by?.unit_object?.unit_number})
      </div>
      <div
        className="flex flex-row items-center justify-center gap-1 text-sm"
        style={{ color: "#71717A" }}
      >
        <ClockIcon style={{ height: "16px" }} />{" "}
        {dateToTime(reservation?.from_datetime) +
          " - " +
          dateToTime(reservation?.to_datetime)}
      </div>
      <div
        className="flex flex-row gap-1 items-center justify-end text-sm"
        style={{ color: "#71717A" }}
      >
        <CalenderIcon2 style={{ height: "16px" }} />
        {new Date(reservation.from_datetime).toLocaleDateString("en-GB")}
      </div>
    </div>
  );
}
