import React from "react";

export const meeting_types = [
  {
    label: "Board Meeting",
    value: "BOARD",
  },
  {
    label: "Annual Meeting",
    value: "ANNUAL",
  },
  {
    label: "Executive Session",
    value: "EXECUTIVE",
  },
  {
    label: "Committee Meeting",
    value: "COMMITTEE",
  },
  {
    label: "Emergency Meeting",
    value: "EMERGENCY",
  },
];

export const meeting_color = (type) => {
  if (type === "BOARD")
    return { bg1: "bg-red-600", bg2: "bg-red-200", text: "text-red-600" };
  else if (type === "ANNUAL")
    return {
      bg1: "bg-green-600",
      bg2: "bg-green-200",
      text: "text-green-600",
    };
  else if (type === "EXECUTIVE")
    return { bg1: "bg-blue-600", bg2: "bg-blue-200", text: "text-blue-600" };
  else if (type === "COMMITTEE")
    return {
      bg1: "bg-yellow-600",
      bg2: "bg-yellow-200",
      text: "text-yellow-600",
    };
  else if (type === "EMERGENCY")
    return {
      bg1: "bg-orange-600",
      bg2: "bg-orange-200",
      text: "text-orange-600",
    };
  else return { bg1: "bg-gray-600", bg2: "bg-gray-200", text: "text-gray-600" };
};

export const ProfilePic = ({ className, member }) => (
  <div className={"shadow-md border border-gray-100 rounded-full " + className}>
    {member?.pic ? (
      <img
        className={"rounded-full " + (className ?? "h-7 w-7")}
        src={member.pic}
      />
    ) : (
      <div
        className={
          "bg-white truncate rounded-full flex flex-row gap-px items-center justify-center font-semibold text-indigo-600 uppercase " +
          (className ?? "h-7 w-7")
        }
      >
        {(member?.first_name ?? "").charAt(0)}
        {member?.last_name?.charAt(0)}
      </div>
    )}
  </div>
);
