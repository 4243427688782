import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  CondoIcon,
  DeleteIcon,
  EditIcon3,
  LoadingCircleIcon2,
} from "../../../common/AppIcons";
import ProfileBase from "../ProfileBase";
import Popup from "../../../common/Popup";
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import AssetProperty from "./AssetProperty";
import {
  getHoa,
  getHoas,
  memberAddressUnitsDetails,
} from "../../../../api/ApiV2";
import Paginator from "../../../../common/Paginator";
import {
  addressFormatLine,
  addressFormatLine2,
  addressFormatLine3,
} from "../../../../utils/addressFormat";

const entryPerPage = 25;

export default function AssetPropertyList({ asset }) {
  const [properties, setProperties] = useState();
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [openPropertyDetails, setOpenPropertyDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [deleteProperty, setDeleteProperty] = useState();
  const [loadingProperty, setLoadingProperty] = useState();

  useEffect(() => {
    setLoading(true);
    getHoaList();
  }, []);

  const getHoaList = () => {
    getHoas({
      org: asset,
      page: page.current * entryPerPage,
      limit: entryPerPage,
    })
      .then((res) => {
        setPage({ ...page, total: res.count });
        console.log(res);
        setLoading(false);
        setProperties(res.results);
        if (res?.buildings?.length === 1) {
          setOpenPropertyDetails(res[0].id);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err?.message ?? "Error fetching Properties");
      });
  };

  const openProperty = (property) => {
    setLoadingProperty(property.id);
    memberAddressUnitsDetails({ hoaId: property.id })
      .then((res) => {
        console.log(res);
        let admin = res?.find(
          (res) =>
            res.user_type === "SUPER_ADMIN" || res.user_type === "BOARD_MEMBER"
        );
        if (admin) {
          sessionStorage.setItem("selectedUserProfile", admin.id);
          setLoadingProperty();
          setOpenPropertyDetails(property);
        } else {
          setLoadingProperty();
          toast.error("You are not authorized to edit this Property.");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingProperty();
        toast.error(err?.message ?? "Error fetching Property Details");
      });
  };

  if (loading) {
    return (
      <ProfileBase page={`/profile/${asset}/properties`}>
        <div className="flex flex-col items-center justify-center py-20">
          <div className="flex flex-col items-center justify-center gap-2">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            <span className="text-gray-700 font-semibold">Uploading...</span>
          </div>
        </div>
      </ProfileBase>
    );
  } else if (openPropertyDetails)
    return (
      <ProfileBase page={`/profile/${asset}/properties`}>
        <AssetProperty
          propertyDetails={openPropertyDetails}
          closePage={() => setOpenPropertyDetails()}
          getHoaList={getHoaList}
        />
      </ProfileBase>
    );
  else
    return (
      <ProfileBase page={`/profile/${asset}/properties`}>
        <div className="bg-white rounded-lg p-12 max-w-3xl w-full mx-auto flex flex-col gap-2">
          <div className="mb-2 flex flex-row items-center justify-between gap-5">
            <span className="font-semibold text-xl">
              Properties under this org
            </span>
          </div>
          <div className="mt-4 flex flex-col gap-5">
            {properties?.length === 0 && (
              <span className="text-sm text-gray-400 mx-auto">
                No Properties Found
              </span>
            )}
            {properties?.map((building, buildingIndex) => (
              <div
                key={buildingIndex}
                className="w-full flex flex-row items-center justify-between bg-gray-100 rounded-md"
              >
                <div className="flex flex-row items-center gap-6 px-4 py-3 pr-10">
                  <div
                    className="bg-indigo-100 p-3 rounded"
                    style={{ color: "#2A32FD" }}
                  >
                    <CondoIcon className="h-10" />
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="font-semibold">{building.name}</span>
                    <span className="text-sm">
                      {addressFormatLine3(building)}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-center gap-8 px-8">
                  {loadingProperty === building.id ? (
                    <LoadingCircleIcon2 className="h-5 text-blue-600" />
                  ) : (
                    <button
                      onClick={() => openProperty(building)}
                      className="text-gray-500 hover:text-blue-600"
                    >
                      <EditIcon3 className="h-5 transform scale-105" />
                    </button>
                  )}
                  <button
                    onClick={() => {
                      setDeleteProperty(building);
                    }}
                    className="text-gray-500 hover:text-red-600"
                  >
                    <DeleteIcon className="w-5" />
                  </button>
                </div>
              </div>
            ))}
            <Paginator
              totalPage={page.total}
              currentPage={page.current + 1}
              entryPerPage={entryPerPage}
              onChange={(pageNo) => {
                setPage({ ...page, current: pageNo - 1 });
              }}
            />
          </div>
        </div>
        <Popup
          show={deleteProperty ? true : false}
          setShow={() => setDeleteProperty()}
        >
          <ConfirmDeletePopup
            property={deleteProperty}
            onSucces={() => setDeleteProperty()}
            onClose={() => setDeleteProperty()}
          />
        </Popup>
      </ProfileBase>
    );
}
