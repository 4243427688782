import React from "react";

export default function PaymentError({ show }) {
  return (
    <span
      className={
        "text-xs text-red-700 relative top-3 " + (show ? "" : "hidden")
      }
    >
      Please ask your HOA to enable payments before you could proceed.
    </span>
  );
}
