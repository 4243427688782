import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const downloadPDF = (set_open_pdf, set_loading, sr_data, ref) => {
  const page = ref?.current;
  var HTML_Width = page.offsetWidth;
  var HTML_Height = page.offsetHeight;
  var top_left_margin = 3;
  var PDF_Width = HTML_Width + top_left_margin * 2;
  var PDF_Height = HTML_Height + top_left_margin * 2;
  // var PDF_Height = HTML_Width * 1.5 + top_left_margin * 2;
  var canvas_image_width = HTML_Width;
  var canvas_image_height = HTML_Height;

  var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

  const pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height], true);

  html2canvas(page, { scale: 2, useCORS: true }).then(function (canvas) {
    canvas.getContext("2d");

    let imgData = canvas.toDataURL("image/png");
    pdf.addImage(
      imgData,
      "JPG",
      top_left_margin,
      top_left_margin,
      canvas_image_width,
      canvas_image_height
    );

    setTimeout(() => {
      window?.open(
        pdf.output(
          "bloburl",
          "service request " + sr_data?.serviceRequestId ?? ""
        ),
        "_blank"
      );

      set_open_pdf(false);
      set_loading(false);
    }, 1000);
  });
};
