import { useState, useEffect, useCallback } from "react";
import { navigate } from "raviger";

import {
  getAccounts,
  getBuildings,
  getHoa,
  getHoas,
  getOrg,
  memberAddressUnitsDetails,
  postHoaCreateStage,
} from "../../../api/ApiV2";
import Compliance from "./components/compliance";
import ExpensesInvoicePage from "./components/ExpensesInvoice";
import Finished from "./components/Finished";
import HoaDetails from "./components/hoaDetails";
import OpeningBalance from "./components/OpeningBalance";
import PermissionPage from "./components/PermissionPage";
import MultiplePropertyPhotosUpload from "./components/photos/MultiplePropertyPhotosUpload";
import PetPolicy from "./components/PetPolicy";
import UnitsPropertyList from "./components/units/UnitsPropertyList";
import BuildingDetails from "./components/buildingDetails";
import Amenity from "./components/amenityForm/Amenity";
import { LoadingCircleIcon2 } from "../../common/AppIcons";
import CreateHoaWrapper from "./wrapper/CreateHoaWrapper";
import UserRole from "./components/UserRole";
import CompanyDetails from "./components/companyDetails";
import AddUsers from "./components/addUsers/AddUsers";
import UserAdded from "./components/UserAdded";
import PropertyType from "./components/PropertyType";
import PropertyDetails from "./components/propertyDetails/PropertyDetails";
import TaskProgress from "./components/TaskProgress";
import { toast } from "react-toastify";

export default function CreateHoa({ hoaId, setupStage }) {
  const [clientData, setClientData] = useState({
    userRole: null,
    // 'PMC','LANDLORD','BM_OF_HOA','PRIMARY_RESIDENCE'
    companyDetails: {},
    users: [],
  });
  const [hoaData, setHoaData] = useState({
    hoaId: hoaId,
    // "662067f1-6c42-4900-9d66-b47d74941e90",
    type: null,
    // HOA, SINGLE_FAMILY, MULTY_FAMILY
    selectedManagedBy: false,
    details: {},
    buildings: [],
    units: [],
    members: [],
    amenities: [],
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [page, setPage] = useState(null);
  /*  USER_ROLE, COMPANY_DETAILS, ADD_USERS, USER_ADDED, PROPERTY_TYPE, HOA_DETAILS, BUILDING_DETAILS, PHOTOS, PROPERTY_DETAILS, UNITS, AMENITIES, PET_POLICY,
  
       GOTO_OPENING_BALANCE,
       OPENING_BALANCE,EXPENSES_PAGE,INVOICE_PAGE,
       SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE,GOTO_COMPLIANCE,SKIPPED_COMPLIANCE,
       COMPLIANCE,
       FINISHED */

  const [baseData, setBaseData] = useState();
  const [loading, set_loading] = useState(false);
  const [modules_enabled, set_module_enabled] = useState({
    accounting: false,
    compliance: false,
  });

  useEffect(() => {
    if (hoaId && setupStage) {
      setPageLoading(true);
      memberAddressUnitsDetails({ hoaId })
        .then((res) => {
          console.log(res);
          res?.[0]?.id &&
            sessionStorage.setItem("selectedUserProfile", res[0]?.id);

          setTimeout(() => {
            fetchData();
          }, 1000);
        })
        .catch((res) => {
          console.log(res);
          fetchData();
        });
    } else {
      setPageLoading(true);
      getOrg({})
        .then((res) => {
          if (res.results?.length > 0) {
            setPage("PROPERTY_TYPE");
            setPageLoading(false);
          } else
            getHoas({})
              .then((res) => {
                if (res.results?.length > 0) {
                  setPage("PROPERTY_TYPE");
                  setPageLoading(false);
                } else {
                  setPage("USER_ROLE");
                  setPageLoading(false);
                }
              })
              .catch((res) => {
                setPage("USER_ROLE");
                setPageLoading(false);
                toast.error(res.message ?? "Error fetching Property details");
              });
        })
        .catch((res) => {
          toast.error(res.message ?? "Error fetching organisation details");
        });
    }
  }, [hoaId, setupStage]);

  const fetchData = () => {
    getHoa({ external_id: hoaData?.hoaId })
      .then((res) => {
        setHoaData((hoaData) => {
          return {
            ...hoaData,
            type: res?.type,
            details: {
              ...hoaData?.details,
              ...res,
              existing: true,
              melissaBaseMak: res.melissa_base_mak,
            },
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
    if (
      setupStage === "MULTIPLE_BUILDING_DETAILS" ||
      setupStage === "MULTIPLE_PHOTOS"
    ) {
      setPageLoading(false);
      setHoaData((hoaData) => ({ ...hoaData, hoaId: hoaId }));
      setPage(
        setupStage === "MULTIPLE_BUILDING_DETAILS"
          ? "BUILDING_DETAILS"
          : "PHOTOS"
      );
    } else {
      getBuildings({ hoaId: hoaId })
        .then((res) => {
          setPageLoading(false);
          setHoaData((hoaData) => ({
            ...hoaData,
            hoaId: hoaId,
            buildings: res,
          }));

          setPage(setupStage);
        })
        .catch((err) => {
          setPageLoading(false);
          console.log(err);
          setHoaData((hoaData) => ({
            ...hoaData,
            hoaId: hoaId,
          }));
          setPage(setupStage);
        });
    }
  };

  const progress_points = (
    clientData.userRole === "PRIMARY_RESIDENCE" ||
    hoaData.type === "SINGLE_FAMILY"
      ? [
          "Property Details",
          "Photos",
          "Members",
          "Accounting",
          "Compliance",
          "Finished",
        ]
      : [
          "Property Details",
          "Building Details",
          "Photos",
          "Units",
          "Amenities",
          "Accounting",
          "Compliance",
          "Finished",
        ]
  ).map((ele, ele_index) => {
    return {
      label: ele,
      value: ele_index + 1,
      icon:
        (ele === "Accounting" &&
          page !== "GOTO_OPENING_BALANCE" &&
          !modules_enabled.accounting) ||
        (ele === "Compliance" &&
          ![
            "SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE",
            "GOTO_COMPLIANCE",
          ].includes(page) &&
          !modules_enabled.compliance)
          ? "CROSS"
          : "TICK",
    };
  });

  const progress_points_client = [
    clientData.userRole === "PMC" ? "PMC Details" : "Landlord Details",
    "Add Users",
    "Finish",
  ].map((ele, ele_index) => {
    return {
      label: ele,
      value: ele_index + 1,
    };
  });

  const get_accounts = useCallback(() => {
    set_loading(true);
    getAccounts({ hoaId: hoaData.hoaId, page_size: 300 })
      .then((res) => {
        console.log("base data:", res);
        setBaseData(res.results);
        set_loading(false);
      })
      .catch((res) => {
        console.log("base data fetching error:", res);
        set_loading(false);
      });
  }, [hoaData.hoaId]);

  useEffect(() => {
    page === "OPENING_BALANCE" && hoaData.hoaId && get_accounts();
  }, [page, hoaData.hoaId, get_accounts]);

  const saveHoaCreateStage = (stage, hoaId) => {
    if (hoaData.hoaId || hoaId)
      postHoaCreateStage({
        hoaId: hoaData.hoaId ? hoaData.hoaId : hoaId,
        data: {
          setup_stage:
            stage === "BUILDING_DETAILS" && hoaData.buildings?.length > 1
              ? "MULTIPLE_BUILDING_DETAILS"
              : stage === "PHOTOS" && hoaData.buildings?.length > 1
              ? "MULTIPLE_PHOTOS"
              : stage,
        },
      })
        .then((res) => {
          console.log("saveHoaCreateStage", res);
        })
        .then((err) => {
          console.log("saveHoaCreateStage", err);
        });
  };

  const nextPage = (skip) => {
    setPage((page) => {
      switch (page) {
        case "USER_ROLE":
          return clientData.userRole === "BM_OF_HOA" ||
            clientData.userRole === "PRIMARY_RESIDENCE"
            ? "HOA_DETAILS"
            : "COMPANY_DETAILS";
        case "COMPANY_DETAILS":
          return "ADD_USERS";
        case "ADD_USERS":
          return "USER_ADDED";
        case "USER_ADDED":
          return "PROPERTY_TYPE";
        case "PROPERTY_TYPE":
          return "HOA_DETAILS";
        case "HOA_DETAILS":
          return hoaData.type === "SINGLE_FAMILY"
            ? "PHOTOS"
            : "BUILDING_DETAILS";
        case "BUILDING_DETAILS":
          return "PHOTOS";
        case "PHOTOS":
          return hoaData.type === "SINGLE_FAMILY"
            ? "PROPERTY_DETAILS"
            : "UNITS";
        case "PROPERTY_DETAILS":
          return "GOTO_OPENING_BALANCE";
        case "UNITS":
          return "AMENITIES";
        case "AMENITIES":
          return "PET_POLICY";
        case "PET_POLICY":
          return "GOTO_OPENING_BALANCE";
        case "GOTO_OPENING_BALANCE":
          return skip
            ? "SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE"
            : "OPENING_BALANCE";
        case "OPENING_BALANCE":
          return "EXPENSES_PAGE";
        case "EXPENSES_PAGE":
          return "INVOICE_PAGE";
        case "INVOICE_PAGE":
          return "GOTO_COMPLIANCE";

        case "SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE":
          return skip ? "SKIPPED_COMPLIANCE" : "COMPLIANCE";
        case "GOTO_COMPLIANCE":
          return skip ? "SKIPPED_COMPLIANCE" : "COMPLIANCE";

        case "COMPLIANCE":
          return "FINISHED";

        case "SKIPPED_COMPLIANCE":
          return "FINISHED";

        default:
          return page;
      }
    });
    setPage((page) => {
      if (
        page !== "PROPERTY_TYPE" &&
        page !== "BUILDING_COUNT" &&
        page !== "HOA_DETAILS" &&
        page !== "BUILDING_DETAILS"
      )
        saveHoaCreateStage(page);

      return page;
    });
  };

  const previousPage = () => {
    if (page === "PROPERTY_TYPE" && !clientData.userRole) {
      navigate("/login");
    }
    setPage((page) => {
      switch (page) {
        case "PROPERTY_TYPE":
          return "ADD_USERS";
        case "HOA_DETAILS":
          return clientData.userRole === "BM_OF_HOA" ||
            clientData.userRole === "PRIMARY_RESIDENCE"
            ? "ADD_USERS"
            : "PROPERTY_TYPE";
        case "ADD_USERS":
          return "COMPANY_DETAILS";
        case "COMPANY_DETAILS":
          return "USER_ROLE";
        case "BUILDING_DETAILS":
          return "HOA_DETAILS";
        case "PHOTOS":
          return clientData.userRole === "PRIMARY_RESIDENCE" ||
            hoaData.type === "SINGLE_FAMILY"
            ? "HOA_DETAILS"
            : "BUILDING_DETAILS";
        case "UNITS":
          return "PHOTOS";
        case "AMENITIES":
          return "UNITS";
        case "PET_POLICY":
          return "AMENITIES";
        case "PROPERTY_DETAILS":
          return "PHOTOS";
        case "GOTO_OPENING_BALANCE":
          return clientData.userRole === "PRIMARY_RESIDENCE" ||
            hoaData.type === "SINGLE_FAMILY"
            ? "PROPERTY_DETAILS"
            : "PET_POLICY";
        case "OPENING_BALANCE":
          return "PET_POLICY";
        case "EXPENSES_PAGE":
          return "OPENING_BALANCE";
        case "INVOICE_PAGE":
          return "EXPENSES_PAGE";
        default:
          return page;
      }
    });
  };

  let hoa_current_checkpoint =
    clientData.userRole === "PRIMARY_RESIDENCE" ||
    hoaData.type === "SINGLE_FAMILY"
      ? page === "HOA_DETAILS"
        ? 1
        : page === "PHOTOS"
        ? 2
        : page === "PROPERTY_DETAILS"
        ? 3
        : page === "GOTO_OPENING_BALANCE"
        ? 4
        : page === "OPENING_BALANCE"
        ? 4
        : page === "EXPENSES_PAGE"
        ? 4
        : page === "INVOICE_PAGE"
        ? 4
        : page === "SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE"
        ? 5
        : page === "GOTO_COMPLIANCE"
        ? 5
        : page === "SKIPPED_COMPLIANCE"
        ? 5
        : page === "COMPLIANCE"
        ? 5
        : page === "FINISHED"
        ? 6
        : null
      : page === "HOA_DETAILS"
      ? 1
      : page === "BUILDING_DETAILS"
      ? 2
      : page === "PHOTOS"
      ? 3
      : page === "UNITS"
      ? 4
      : page === "AMENITIES"
      ? 5
      : page === "PET_POLICY"
      ? 5
      : page === "GOTO_OPENING_BALANCE"
      ? 6
      : page === "OPENING_BALANCE"
      ? 6
      : page === "EXPENSES_PAGE"
      ? 6
      : page === "INVOICE_PAGE"
      ? 6
      : page === "SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE"
      ? 7
      : page === "GOTO_COMPLIANCE"
      ? 7
      : page === "SKIPPED_COMPLIANCE"
      ? 7
      : page === "COMPLIANCE"
      ? 7
      : page === "FINISHED"
      ? 8
      : null;

  let client_current_checkpoint =
    page === "COMPANY_DETAILS"
      ? 1
      : page === "ADD_USERS"
      ? 2
      : page === "USER_ADDED"
      ? 3
      : null;

  return (
    <CreateHoaWrapper hoaId={hoaId} hoaDetails={hoaData.details}>
      <div className="flex flex-col items-center w-full bg-gray-100 rounded pt-10">
        {(hoa_current_checkpoint || client_current_checkpoint) && (
          <TaskProgress
            className={`grid-cols-${
              (hoa_current_checkpoint
                ? progress_points
                : progress_points_client
              ).length - 1
            }`}
            tasks={
              hoa_current_checkpoint ? progress_points : progress_points_client
            }
            last_finished={
              hoa_current_checkpoint
                ? hoa_current_checkpoint
                : client_current_checkpoint
            }
          />
        )}
        {pageLoading ? (
          <div className="py-20 flex flex-col items-center justify-center w-full h-full">
            <LoadingCircleIcon2 className="h-10 text-blue-600" />
          </div>
        ) : page === "USER_ROLE" ? (
          <UserRole {...{ clientData, setClientData, nextPage }} />
        ) : page === "COMPANY_DETAILS" ? (
          <CompanyDetails
            {...{
              clientData,
              setClientData,
              previousPage,
              nextPage,
            }}
          />
        ) : page === "ADD_USERS" ? (
          <AddUsers
            {...{ clientData, setClientData, previousPage, nextPage }}
          />
        ) : page === "USER_ADDED" ? (
          <UserAdded nextPage={nextPage} />
        ) : page === "PROPERTY_TYPE" ? (
          <PropertyType
            nextPage={nextPage}
            {...{ previousPage, hoaData, setHoaData }}
          />
        ) : page === "HOA_DETAILS" ? (
          <HoaDetails
            {...{
              previousPage,
              nextPage,
              hoaData,
              setHoaData,
              saveHoaCreateStage,
              clientData,
            }}
          />
        ) : page === "BUILDING_DETAILS" ? (
          <BuildingDetails
            {...{
              previousPage,
              nextPage,
              hoaData,
            }}
          />
        ) : page === "PHOTOS" ? (
          <MultiplePropertyPhotosUpload
            {...{
              hoaId: hoaData.hoaId,
              previousPage,
              nextPage,
            }}
          />
        ) : page === "PROPERTY_DETAILS" ? (
          <PropertyDetails
            {...{ hoaData, setHoaData, previousPage, nextPage }}
          />
        ) : page === "UNITS" ? (
          <UnitsPropertyList
            {...{
              hoaData,
              setHoaData,
              previousPage,
              nextPage,
            }}
          />
        ) : page === "AMENITIES" ? (
          <Amenity
            {...{
              hoaData,
              setHoaData,
              hoaId: hoaData.hoaId,
              previousPage,
              nextPage,
            }}
          />
        ) : page === "PET_POLICY" ? (
          <PetPolicy
            {...{
              hoaData,
              setHoaData,
              previousPage,
              nextPage,
            }}
          />
        ) : page === "GOTO_OPENING_BALANCE" ||
          page === "SKIPPED_OPENING_BALANCE_GOTO_COMPLIANCE" ||
          page === "GOTO_COMPLIANCE" ||
          page === "SKIPPED_COMPLIANCE" ? (
          <PermissionPage
            {...{
              hoaId: hoaData.hoaId,
              page,
              nextPage,
              set_module_enabled,
              get_accounts,
            }}
          />
        ) : page === "OPENING_BALANCE" ? (
          <OpeningBalance
            {...{ hoaId: hoaData.hoaId, baseData, nextPage, loading }}
          />
        ) : page === "EXPENSES_PAGE" || page === "INVOICE_PAGE" ? (
          <ExpensesInvoicePage
            {...{ hoaId: hoaData.hoaId, page, previousPage, nextPage }}
          />
        ) : page === "COMPLIANCE" ? (
          <Compliance {...{ hoaId: hoaData.hoaId, nextPage }} />
        ) : page === "FINISHED" ? (
          <Finished hoaId={hoaData.hoaId} {...{ setHoaData, setPage }} />
        ) : null}
      </div>
    </CreateHoaWrapper>
  );
}
