import { Link } from "raviger";
import React from "react";

export default function SelectableItem({
  sidebar_data,
  page,
  hoaId,
  set_sidebar_open,
}) {
  const is_page_open = () => {
    if (page === sidebar_data.link) return true;
    return sidebar_data.pages?.includes(page);
  };
  return (
    <Link
      href={
        page !== sidebar_data.link
          ? sidebar_data.link?.replaceAll(":hoaId", hoaId)
          : "#"
      }
      onClick={() =>
        setTimeout(() => {
          set_sidebar_open && set_sidebar_open(false);
        }, 100)
      }
      className={
        "truncate pl-5 pr-3 gap-5 flex items-center px-2 py-2 text-sm leading-6 border-l-4 bg-gradient-to-r from-white to-white " +
        (is_page_open()
          ? "border-indigo-600 font-bold from-indigo-100"
          : "duration-200 border-white hover:border-indigo-600 hover:from-indigo-100")
      }
      aria-current="page"
    >
      {is_page_open() ? (
        <sidebar_data.icon
          style={{ color: "#2A32FD" }}
          className={"h-5 w-5 font-normal"}
        />
      ) : (
        <sidebar_data.icon className={"h-5 w-5 font-normal"} />
      )}

      {sidebar_data.label}
    </Link>
  );
}
