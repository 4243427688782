import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";

import { LoadingCircleIcon2, PlusIcon2 } from "../../../../../common/AppIcons";
import AmenityCard from "./components/AmenityCard";
import { week_days } from "../../data/week_days";
import { getAmenityPhoto, listAmenities } from "../../../../../../api/ApiV2";
import Paginator from "../../../../../../common/Paginator";
import { userAtom } from "../../../../../../store/UserAtom";
import { adminTypes } from "../../../../../../common/data/adminTypes";

export default function AmenityList({
  hoaId,
  set_amenityForm,
  initialState,
  set_reservationList,
}) {
  const [amenities, setAmenities] = useState([]);
  const [user] = useRecoilState(userAtom);
  const [loading, set_loading] = useState(true);
  const [page, setPage] = useState({
    offset: 0,
    totalCount: 0,
  });

  useEffect(() => {
    fetch_amenities();
  }, [page.offset]);

  const fetch_amenities = () => {
    set_loading(true);
    listAmenities({ hoaId, offset: page.offset })
      .then((res) => {
        setPage((page) => ({ ...page, totalCount: res.count }));
        setAmenities(res.results);
        res.results?.forEach((val) => {
          if (Object.keys(val.photos?.photos ?? {}).length > 0)
            fetchPicture(val.id);
        });
        console.log(res);
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error fetching amenities"
        );

        set_loading(false);
      });
  };

  const fetchPicture = (id) => {
    getAmenityPhoto({ hoaId, id })
      .then((res) => {
        console.log(res);
        setAmenities((amenities) =>
          amenities.map((val) =>
            val.id === id
              ? {
                  ...val,
                  picture_url: Object.values(res.photos)?.[0]?.url ?? "",
                }
              : val
          )
        );
      })
      .catch((res) => {
        console.log(res);
        toast.error("Amenity picture fetching failed");
      });
  };

  if (loading)
    return (
      <div className="w-full py-20 bg-white rounded flex items-center justify-center">
        <LoadingCircleIcon2 className="h-10 text-blue-500" />
      </div>
    );
  else
    return (
      <div style={{ minHeight: "200px" }} className="w-full pt-7 pb-10 px-8">
        <div className="w-full flex justify-end gap-3 flex-wrap">
          {adminTypes.includes(user.selectedUserType) && (
            <button
              className="px-3 py-1.5 text-sm whitespace-pre rounded hover:opacity-70 flex flex-row gap-2 items-center"
              style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
              onClick={() => set_amenityForm(initialState)}
            >
              <PlusIcon2 style={{ height: "20px" }} />
              Add New Amenity
            </button>
          )}
        </div>
        <div className="mt-4 w-full flex flex-col gap-5">
          {!amenities || amenities.length === 0 ? (
            <div className="w-full mt-12 text-sm text-center text-gray-500">
              No Amenity Found
            </div>
          ) : (
            <div className="w-full flex flex-row flex-wrap sm:grid grid-cols-2 md:grid-cols-3 gap-4">
              {amenities?.map((amenity, amenity_index) => (
                <AmenityCard
                  key={amenity_index}
                  className="w-60 sm:w-auto"
                  edit_amenity={(readOnly) => {
                    let tempSchedules = {};
                    week_days.forEach(
                      (value) =>
                        (tempSchedules = {
                          ...tempSchedules,
                          [value.toLocaleLowerCase()]:
                            amenity?.availability[value.toLocaleLowerCase()],
                        })
                    );
                    set_amenityForm({
                      show: true,
                      data: {
                        ...amenity,
                        existing: true,
                        schedules: tempSchedules,
                      },
                      picture_url: amenity.picture_url,
                      id: amenity.id ?? 1,
                      readOnly: readOnly ? true : false,
                    });
                  }}
                  {...{ hoaId, amenity, set_reservationList }}
                />
              ))}
            </div>
          )}
          <Paginator
            currentPage={page.offset / 20 + 1}
            totalPage={page.totalCount}
            entryPerPage={20}
            onChange={(pageNo) => {
              setPage({ ...page, offset: (pageNo - 1) * 20 });
            }}
          />
        </div>
      </div>
    );
}
