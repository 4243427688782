import React, { useState } from "react";
import { toast } from "react-toastify";

import { payInvoice1Hoa, paySR } from "../../../../../../api/Api";
import PayButton from "../../../../../accounting/common/components/PayButton";
import { amountFormat } from "../../../../../common/AmountFormat";
import { CrossIcon, LeftArrowIcon } from "../../../../../common/AppIcons";

export default function AccountList({
  hoaId,
  payment_method_popup,
  set_payment_method_popup,
  accounts,
  plaid_data,
  set_loading,
  payButtonRef,
}) {
  const payment = ({ metadata, token }) => {
    set_loading(true);
    payInvoice1Hoa({
      hoaId,
      public_token: token,
      account_id: metadata.account.id,
      bank_account_id: payment_method_popup.data?.account_id,
      account: metadata?.account,
    })
      .then((res) => {
        set_loading(false);
        set_payment_method_popup({
          ...payment_method_popup,
          popup: "SUCCESS_SAVING",
        });
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(res?.message ?? "Error processing payment");
        set_payment_method_popup({
          ...payment_method_popup,
          popup: "FAILED",
        });
      });
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="max-w-lg max-h-screen w-full flex flex-col items-center justify-start overflow-auto px-10 pt-5 pb-8 rounded-lg bg-white"
    >
      {
        <div className="flex w-full justify-end">
          <div
            onClick={() => {
              set_payment_method_popup({
                ...payment_method_popup,
                view: false,
                popup: "",
              });
            }}
            className="p-1 h-5 w-5 cursor-pointer rounded-full border-2 border-red-500 text-red-500 hover:opacity-70 flex items-center justify-center"
          >
            <CrossIcon className="h-3 w-3" />
          </div>
        </div>
      }
      <div className="w-full flex flex-row items-center gap-1">
        <div
          onClick={() => {
            set_payment_method_popup({
              ...payment_method_popup,
              popup: "PAY_OPTIONS",
            });
          }}
          className="p-1 rounded border-2 border-white hover:border-gray-300 text-gray-800 hover:text-gray-700 cursor-pointer"
        >
          <LeftArrowIcon className="h-5" />
        </div>
        <span className="text-gray-800 font-semibold">
          Please select an account to pay from
        </span>
      </div>
      <div className="w-full px-1 mt-7 overflow-auto">
        <div className="w-full border border-gray-300 rounded">
          {accounts && accounts.length > 0 ? (
            accounts?.map((account, account_index) => (
              <div
                key={account_index}
                onClick={() => {
                  if (payment_method_popup.data?.account_id === account.id) {
                    set_payment_method_popup({
                      ...payment_method_popup,
                      data: {
                        ...payment_method_popup.data,
                        account_id: null,
                      },
                    });
                  } else
                    set_payment_method_popup({
                      ...payment_method_popup,
                      data: {
                        ...payment_method_popup.data,
                        account_id: account.id,
                      },
                    });
                }}
                className={
                  "flex flex-row items-center justify-between px-4 pt-3 pb-2 cursor-pointer border-b border-gray-300 " +
                  (account.id === payment_method_popup?.data?.account_id
                    ? "text-blue-700"
                    : "text-gray-600")
                }
              >
                <div className="flex flex-row text-sm items-center gap-2">
                  <input
                    className="relative top-px"
                    type="radio"
                    readOnly
                    checked={
                      account.id === payment_method_popup?.data?.account_id
                    }
                  />
                  <h1 className="font-semibold">{account.name}</h1>
                </div>
                <h1 className="font-semibold">
                  ${amountFormat(account.current_amount)}
                </h1>
              </div>
            ))
          ) : (
            <div className="px-8 py-12 font-semibold text-gray-500 flex items-center justify-center w-full">
              No Accounts Present
            </div>
          )}
        </div>
      </div>
      <button
        style={{ backgroundColor: "#2A32FD" }}
        className={
          "mt-5 w-full py-2 flex items-center justify-center text-white font-semibold rounded " +
          (payment_method_popup?.data?.account_id
            ? "hover:opacity-80"
            : "opacity-70")
        }
        onClick={() => {
          payment_method_popup?.data?.account_id &&
            set_payment_method_popup({
              ...payment_method_popup,
              popup: "ACH_PAY",
            });
        }}
      >
        Pay Now
      </button>
      {plaid_data && (
        <PayButton
          ref={payButtonRef}
          data={plaid_data}
          disable={!plaid_data}
          payment_api_call={payment}
          className="h-0 overflow-hidden"
        />
      )}
    </div>
  );
}
