import React from "react";
import PhoneInput from "react-phone-input-2";

import Dropdown from "../../../../common/Form/Dropdown";

export default function InputField({
  value,
  warning,
  delete_warning,
  editVendor,
  setEditVendor,
  parentRef,
  set_new_account_popup,
}) {
  const onChange = (value, name) => {
    if (value === "OPEN_NEW_ACCOUNT_POPUP") {
      set_new_account_popup(true);
      return;
    }
    delete_warning(name);

    setEditVendor((editVendor) => {
      return { ...editVendor, [name]: value };
    });
  };

  return (
    <div className="w-full">
      <div className="w-full flex flex-row justify-between my-1">
        <span className="text-xs font-semibold">{value.label}</span>
        <span className="text-red-600 text-xs">
          {warning[value.fieldName] && warning[value.fieldName][0]}
        </span>
      </div>
      {value.type === "DROP_DOWN" ? (
        <Dropdown
          parentRef={parentRef}
          selectClassName={
            "bg-gray-100 w-full rounded-md focus:outline-none border border-gray-100 focus:border-indigo-500 " +
            (warning[value.fieldName] && warning[value.fieldName][0]
              ? "border-red-500"
              : "")
          }
          options={value.options}
          name={value.fieldName}
          value={editVendor[value.fieldName] ?? ""}
          onChange={(val) => onChange(val, value.fieldName)}
          placeholder={value.placeholder}
        />
      ) : value.type === "phoneInput" ? (
        <PhoneInput
          country={"us"}
          value={editVendor[value.fieldName] ?? ""}
          onChange={(phone) => {
            onChange(phone, value.fieldName);
          }}
          className="text-xs font-semibold"
          inputProps={{
            className:
              "pl-12 border-none px-3 py-2.5 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-indigo-100 focus:indigo-200 w-full rounded",
          }}
        />
      ) : (
        <input
          className={
            "w-full bg-gray-100 px-2 py-1 h-10 rounded-md focus:outline-none border border-gray-100 focus:border-indigo-500 " +
            (warning[value.fieldName] && warning[value.fieldName][0]
              ? "border-red-500"
              : "")
          }
          value={editVendor[value.fieldName] ?? ""}
          onChange={(e) => onChange(e.target.value, value.fieldName)}
        />
      )}
    </div>
  );
}
