import React from "react";

import { accounting, compliances } from "./data/text";

export default function ConfirmPopup({ type, onSucces, onClose }) {
  const data_file = () => {
    if (type === "ACCOUNTING") return accounting;
    else return compliances;
  };
  return (
    <div className="px-6 py-5">
      <p>{data_file()?.text}</p>
      <div className="mt-5 w-full flex flex-row-reverse items-center">
        <button
          onClick={onSucces}
          className="rounded-md bg-blue-600 hover:bg-blue-700 shadow w-24 flex items-center justify-center py-2 text-white font-semibold"
        >
          Submit
        </button>
        <button
          onClick={onClose}
          className="px-4 py-2 text-gray-700 hover:text-gray-900 focus:outline-none font-semibold"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
