import { Link } from "raviger";
import React, { useState } from "react";
import { TickInCircleIcon3 } from "../../../../common/AppIcons";

export default function CreatedBoardMeetingPopup({
  edit_meeting,
  hoaId,
  view,
  set_view,
}) {
  const [attendees_type, set_attendees_type] = useState("MANDATORY");
  // MANDATORY, OPTIONAL

  return (
    <div
      className={
        "z-20 fixed inset-0 max-h-full flex items-center justify-center bg-opacity-20 bg-black " +
        (view ? "" : "hidden")
      }
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ maxHeight: "75%" }}
        className="max-w-2xl w-full pt-6 pb-16 rounded-md bg-white flex flex-col items-center"
      >
        <TickInCircleIcon3
          style={{ color: "#2A32FD" }}
          className="h-32 mt-4 mb-8"
        />
        <span className="text-xl font-semibold text-gray-800">
          You have successfully {edit_meeting ? "edited" : "created"} the
          meeting
        </span>
        <Link
          href={`/hoa/${hoaId}/community/board_meetings/`}
          className="mt-12 py-3 px-7 rounded-md text-white cursor-pointer hover:opacity-80"
          style={{ backgroundColor: "#2A32FD" }}
        >
          Go to board meetings
        </Link>
        <button
          onClick={set_view}
          className="mt-2 py-3 px-5 text-blue-600 hover:opacity-70"
        >
          {edit_meeting
            ? "Go back to meeting details"
            : "Create another meeting"}
        </button>
      </div>
    </div>
  );
}
