import React, { useEffect, useState } from "react";

import { getHoaMembers } from "../../../../api/ApiV2";
import Calendar from "./Calendar";
import EventListSection from "./eventList.jsx";

export default function Events({ hoaId }) {
  const [page, set_page] = useState("CALENDER");
  // CALENDER, LIST
  const [events_type_show, set_events_type_show] = useState("PENDING");
  // REQUESTED, APPROVED, REJECTED
  const [members, set_members] = useState();

  const getMembers = () =>
    getHoaMembers(hoaId)
      .then((res) => {
        console.log("members : ", res.results);
        set_members(res?.results);
      })
      .catch(console.log);

  useEffect(() => {
    getMembers();
  }, []);

  if (page === "CALENDER") return <Calendar {...{ hoaId, set_page }} />;
  else
    return (
      <EventListSection
        {...{
          hoaId,
          set_page,
          events_type_show,
          set_events_type_show,
          members,
        }}
      />
    );
}
