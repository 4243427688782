// AppRouter using Raviger

import { useEffect } from "react";
import { useRoutes, navigate } from "raviger";

import CreateHoa from "../components/hoa/createHoa";
import HoaList from "../components/hoa/hoaList";
import UpdateHoa from "../components/hoa/updateHoa";
import CardPaymentAfterScreen from "../components/payment_methods/CardPaymentAfterScreen";
import PaymentMethod from "../components/payment_methods/PaymentMethod";
import MemberProfiles from "../components/members/MemberProfiles";
import UnitList from "../components/hoa/units/UnitList";
import PropertyList from "../components/hoa/properties/PropertiesList";
import ComplianceHome from "../components/compliance/ComplianceHome";
import DocumentList from "../components/compliance/DocumentList";
import ArchitecturalReview from "../components/compliance/archetecturalReview/ArchitecturalReview";
import CommunityBase from "../components/community/communityBase";
import CreateBoardMeeting from "../components/community/pages/boardMeeting/CreateBoardMeeting";
import BoardMeetingDetails from "../components/community/pages/boardMeeting/BoardMeetingDetails";
import BoardMeeting from "../components/community/pages/boardMeeting/BoardMeeting";
import BoardMeetingStarted from "../components/community/pages/boardMeeting/boardMeetingStarted/BoardMeetingStarted";
import Events from "../components/community/pages/events";
import Reservations from "../components/community/pages/reservations";
import NoticeBoard from "../components/community/pages/messages/Index";
import AccountingBase from "../components/accounting/accounting_base";
import ChartOfAccounts from "../components/accounting/pages/chartOfAccounts";
import Transactions from "../components/accounting/pages/chartOfAccounts/pages/Transactions";
import Balances from "../components/accounting/pages/banking";
import Expense from "../components/accounting/pages/expense";
import Home from "../components/accounting/pages/home";
import JournalEntry from "../components/accounting/pages/journalEntry";
import ProfitLoss from "../components/accounting/pages/reports/pages/profitLoss";
import Revenue from "../components/accounting/pages/revenue";
import PaymentsUnsettled from "../components/payments/PaymentsUnsettled";
import TransactionList from "../components/accounting/pages/transactionList";
import Reports from "../components/accounting/pages/reports";
import StripeSetup from "../components/accounting/pages/settings/stripeSetup";
import TrialBalance from "../components/accounting/pages/reports/pages/TrialBalance";
import BaseWrapper from "../baseWrapper";
import HoaHome from "../components/hoa/hoaHome";
import Amenities from "../components/community/pages/amenities/index";
import SRDetail from "../components/sr/pages/srDetail";
import CreateSR from "../components/sr/pages/createSR";
import Estimates from "../components/sr/pages/estimates";
import Estimate from "../components/sr/pages/estimate";
import TicketDetails from "../components/sr/pages/ticketDetails";
import DocumentView from "../components/compliance/DocumentView";
import SelectChat from "../components/sr/pages/messageComponent/SelectChat";
import NewChatForm from "../components/sr/pages/messageComponent/NewChatForm";
import ChatBox from "../components/sr/pages/messageComponent/ChatBox";
import TicketList from "../components/sr/pages/TicketList";
import EditBoardMeeting from "../components/community/pages/boardMeeting/EditBoardMeeting";
import ReservationForm from "../components/community/pages/reservationForm";
import PaymentsSettled from "../components/payments/PaymentsSettled";
import Profile from "../components/members/profile/Profile";
import ProfileBaseWrapper from "../components/members/profile/ProfileWrapper";
import ResetPassword from "../components/members/profile/ResetPassword";
import ProfileAssetUsers from "../components/members/profile/profileAssetUsers/ProfileAssetUsers";
import ProfileAssetProfile from "../components/members/profile/ProfileAssetProfile";
import AssetPropertyList from "../components/members/profile/ProfileAssetProperties/AssetPropertyList";

const routes = {
  "/profile": () => (
    <ProfileBaseWrapper>
      <Profile />
    </ProfileBaseWrapper>
  ),

  "/profile/reset_password": () => (
    <ProfileBaseWrapper>
      <ResetPassword />
    </ProfileBaseWrapper>
  ),

  "/profile/:asset/users": ({ asset }: { asset: string }) => (
    <ProfileBaseWrapper>
      <ProfileAssetUsers asset={asset} />
    </ProfileBaseWrapper>
  ),

  "/profile/:asset/profile": ({ asset }: { asset: string }) => (
    <ProfileBaseWrapper>
      <ProfileAssetProfile asset={asset} />
    </ProfileBaseWrapper>
  ),

  "/profile/:asset/properties": ({ asset }: { asset: string }) => (
    <ProfileBaseWrapper>
      <AssetPropertyList asset={asset} />
    </ProfileBaseWrapper>
  ),

  // hoa urls
  "/home": () => (
    <BaseWrapper>
      <HoaList />
    </BaseWrapper>
  ),
  "/hoa/create": () => <CreateHoa hoaId={undefined} setupStage={null} />,

  "/hoa/:hoaId/create/:setupStage": ({
    hoaId,
    setupStage,
  }: {
    hoaId: string;
    setupStage: string;
  }) => <CreateHoa {...{ hoaId, setupStage }} />,

  "/hoa/:hoaId/update": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <UpdateHoa hoaId={hoaId} />
    </BaseWrapper>
  ),
  "/hoa/:hoaId": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <HoaHome hoaId={hoaId} />
    </BaseWrapper>
  ),

  // service request
  "/hoa/:hoaId/sr/ORDER_HISTORY": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
      hoaId={hoaId}
    >
      <TicketList
        selectedTab="ORDER_HISTORY"
        url="/hoa/:hoaId/sr/ORDER_HISTORY"
        hoaId={hoaId}
      />
    </BaseWrapper>
  ),
  "/hoa/:hoaId/sr/ROUTINE_MAINTENANCE": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
      hoaId={hoaId}
    >
      <TicketList
        selectedTab="ROUTINE_MAINTENANCE"
        url="/hoa/:hoaId/sr/ROUTINE_MAINTENANCE"
        hoaId={hoaId}
      />
    </BaseWrapper>
  ),
  "/hoa/:hoaId/sr/UNSCHEDULED_MAINTENANCE": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
      hoaId={hoaId}
    >
      <TicketList
        selectedTab="UNSCHEDULED_MAINTENANCE"
        url="/hoa/:hoaId/sr/UNSCHEDULED_MAINTENANCE"
        hoaId={hoaId}
      />
    </BaseWrapper>
  ),
  "/hoa/:hoaId/sr/INSPECTIONS": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
      hoaId={hoaId}
    >
      <TicketList
        selectedTab="INSPECTIONS"
        url="/hoa/:hoaId/sr/INSPECTIONS"
        hoaId={hoaId}
      />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/sr/messages": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper
      hoaId={hoaId}
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
    >
      <SelectChat url="/hoa/:hoaId/sr/messages" hoaId={hoaId} />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/sr/messages/generalEnquiry": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper
      hoaId={hoaId}
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
    >
      <NewChatForm url="/hoa/:hoaId/sr/messages/generalEnquiry" hoaId={hoaId} />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/sr/messages/:ticketId": ({
    hoaId,
    ticketId,
  }: {
    hoaId: string;
    ticketId: string;
  }) => (
    <BaseWrapper
      hoaId={hoaId}
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
    >
      <ChatBox
        url="/userDashboard/messages/:ticketId"
        hoaId={hoaId}
        ticketId={ticketId}
      />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/sr/estimates": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper
      hoaId={hoaId}
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
    >
      <Estimates url="/hoa/:hoaId/sr/estimates" hoaId={hoaId} />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/ticket/estimate/:estimateId": ({
    hoaId,
    estimateId,
  }: {
    hoaId: string;
    estimateId: string;
  }) => (
    <BaseWrapper
      hoaId={hoaId}
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
    >
      <Estimate
        url="/hoa/:hoaId/sr/estimate/:estimateId"
        hoaId={hoaId}
        estimateId={estimateId}
      />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/sr/create": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper
      hoaId={hoaId}
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
    >
      <CreateSR url="/hoa/:hoaId/sr/create" hoaId={hoaId} />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/sr/:srId/pay/success": ({
    hoaId,
    srId,
  }: {
    hoaId: string;
    srId: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      <CardPaymentAfterScreen hoaId={hoaId} status="success" />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/ticket/:ticketId": ({
    hoaId,
    ticketId,
  }: {
    hoaId: string;
    ticketId: string;
  }) => (
    <BaseWrapper
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
      hoaId={hoaId}
    >
      <TicketDetails
        url="/hoa/:hoaId/ticket/:ticketId"
        ticketId={ticketId}
        hoaId={hoaId}
      />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/ticket/:ticketId/sr/:srId": ({
    hoaId,
    ticketId,
    srId,
  }: {
    hoaId: string;
    ticketId: string;
    srId: string;
  }) => (
    <BaseWrapper
      className="px-0 sm:px-0 rounded-none"
      widthClassName="w-full"
      hoaId={hoaId}
    >
      <SRDetail
        url="/hoa/:hoaId/sr/:srId"
        sr_id={srId}
        ticketId={ticketId}
        hoaId={hoaId}
      />
    </BaseWrapper>
  ),

  // payment method
  "/hoa/:hoaId/payment_methods": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <PaymentMethod hoaId={hoaId} />
    </BaseWrapper>
  ),
  "/hoa/:hoaId/stripePayments/create/:status": ({
    hoaId,
    status,
  }: {
    hoaId: string;
    status: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      <CardPaymentAfterScreen hoaId={hoaId} status={status} />
    </BaseWrapper>
  ),

  // members
  "/hoa/:hoaId/members": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <MemberProfiles hoaId={hoaId} />
    </BaseWrapper>
  ),

  // members
  "/hoa/:hoaId/units": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <UnitList hoaId={hoaId} />
    </BaseWrapper>
  ),

  // members
  "/hoa/:hoaId/buildings": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <PropertyList hoaId={hoaId} />
    </BaseWrapper>
  ),

  // compliance
  "/hoa/:hoaId/compliance_documents": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <ComplianceHome hoaId={hoaId} />
    </BaseWrapper>
  ),
  "/hoa/:hoaId/compliance_documents/:documentType": ({
    hoaId,
    documentType,
  }: {
    hoaId: string;
    documentType: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      <DocumentList documentType={documentType} hoaId={hoaId} />
    </BaseWrapper>
  ),
  "/hoa/:hoaId/compliance_documents/:documentType/:documentId": ({
    hoaId,
    documentType,
    documentId,
  }: {
    hoaId: string;
    documentType: string;
    documentId: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      <ArchitecturalReview
        documentType={documentType}
        hoaId={hoaId}
        documentId={documentId}
      />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/view_document/:documentId": ({
    hoaId,
    documentId,
  }: {
    hoaId: string;
    documentId: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      <DocumentView hoaId={hoaId} documentId={documentId} />
    </BaseWrapper>
  ),

  // community
  "/hoa/:hoaId/community/board_meetings/create": ({
    hoaId,
  }: {
    hoaId: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      <CommunityBase
        page="/hoa/:hoaId/community/board_meetings/create/"
        hoaId={hoaId}
      >
        <CreateBoardMeeting hoaId={hoaId} />
      </CommunityBase>
    </BaseWrapper>
  ),
  "/hoa/:hoaId/community/board_meetings/:boardMeetingId/edit": ({
    hoaId,
    boardMeetingId,
  }: {
    hoaId: string;
    boardMeetingId: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      <CommunityBase
        page="/hoa/:hoaId/community/board_meetings/:boardMeetingId/edit"
        hoaId={hoaId}
      >
        <EditBoardMeeting hoaId={hoaId} boardMeetingId={boardMeetingId} />
      </CommunityBase>
    </BaseWrapper>
  ),

  "/hoa/:hoaId/community/board_meetings/:boardMeetingId": ({
    hoaId,
    boardMeetingId,
  }: {
    hoaId: string;
    boardMeetingId: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      <CommunityBase
        page="/hoa/:hoaId/community/board_meetings/:boardMeetingId"
        hoaId={hoaId}
      >
        <BoardMeetingDetails hoaId={hoaId} boardMeetingId={boardMeetingId} />
      </CommunityBase>
    </BaseWrapper>
  ),
  "/hoa/:hoaId/community/board_meetings": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      {" "}
      <CommunityBase page="/hoa/:hoaId/community/board_meetings/" hoaId={hoaId}>
        <BoardMeeting hoaId={hoaId} />
      </CommunityBase>
    </BaseWrapper>
  ),
  "/hoa/:hoaId/community/board_meetings/:boardMeetingId/conduct": ({
    hoaId,
    boardMeetingId,
  }: {
    hoaId: string;
    boardMeetingId: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      <CommunityBase
        page="/hoa/:hoaId/community/board_meetings/:boardMeetingId/conduct"
        hoaId={hoaId}
      >
        <BoardMeetingStarted hoaId={hoaId} boardMeetingId={boardMeetingId} />
      </CommunityBase>
    </BaseWrapper>
  ),
  "/hoa/:hoaId/community/events": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <CommunityBase page="/hoa/:hoaId/community/events/" hoaId={hoaId}>
        <Events hoaId={hoaId} />
      </CommunityBase>
    </BaseWrapper>
  ),
  "/hoa/:hoaId/community/amenities": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <CommunityBase page="/hoa/:hoaId/community/amenities/" hoaId={hoaId}>
        <Amenities hoaId={hoaId} />
      </CommunityBase>
    </BaseWrapper>
  ),
  "/hoa/:hoaId/community/reservations": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <CommunityBase page="/hoa/:hoaId/community/reservations/" hoaId={hoaId}>
        <Reservations hoaId={hoaId} />
      </CommunityBase>
    </BaseWrapper>
  ),
  "/hoa/:hoaId/community/reservations/create/:amenityId": ({
    hoaId,
    amenityId,
  }: {
    hoaId: string;
    amenityId: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      <CommunityBase
        page="/hoa/:hoaId/community/reservations/:amenityId"
        hoaId={hoaId}
      >
        <ReservationForm hoaId={hoaId} amenityId={amenityId} />
      </CommunityBase>
    </BaseWrapper>
  ),
  "/hoa/:hoaId/community/messages": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <CommunityBase page="/hoa/:hoaId/community/messages/" hoaId={hoaId}>
        <NoticeBoard hoaId={hoaId} />
      </CommunityBase>
    </BaseWrapper>
  ),

  // accounting
  "/hoa/:hoaId/accounts": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/accounts/" hoaId={hoaId}>
      <ChartOfAccounts hoaId={hoaId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/accounts/:accountId/transactions": ({
    hoaId,
    accountId,
  }: {
    hoaId: string;
    accountId: string;
  }) => (
    <AccountingBase
      page="/hoa/:hoaId/accounts/:accountId/transactions"
      hoaId={hoaId}
    >
      <Transactions hoaId={hoaId} accountId={accountId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/bankaccounts": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/bankaccounts/" hoaId={hoaId}>
      <Balances hoaId={hoaId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/bills": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/bills/" hoaId={hoaId}>
      <Expense hoaId={hoaId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/accounting/home": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/accounting/home" hoaId={hoaId}>
      <Home hoaId={hoaId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/accounts/journal_entry": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/accounts/journal_entry/" hoaId={hoaId}>
      <JournalEntry hoaId={hoaId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/accounts/profit_loss": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/accounts/profit_loss/" hoaId={hoaId}>
      <ProfitLoss hoaId={hoaId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/invoices": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/invoices/" hoaId={hoaId}>
      <Revenue hoaId={hoaId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/invoices/user/unsettled": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <PaymentsUnsettled
        hoaId={hoaId}
        cardPaymentStatus={null}
        invoiceId={null}
      />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/invoices/user/settled": ({ hoaId }: { hoaId: string }) => (
    <BaseWrapper hoaId={hoaId}>
      <PaymentsSettled
        hoaId={hoaId}
        cardPaymentStatus={null}
        invoiceId={null}
      />
    </BaseWrapper>
  ),

  "/hoa/:hoaId/invoice/:invoiceId/pay/:cardPaymentStatus": ({
    hoaId,
    invoiceId,
    cardPaymentStatus,
  }: {
    hoaId: string;
    invoiceId: string;
    cardPaymentStatus: string;
  }) => (
    <BaseWrapper hoaId={hoaId}>
      {cardPaymentStatus === "success" ? (
        <PaymentsSettled
          hoaId={hoaId}
          cardPaymentStatus={"success"}
          invoiceId={invoiceId}
        />
      ) : (
        <PaymentsUnsettled
          hoaId={hoaId}
          cardPaymentStatus={cardPaymentStatus}
          invoiceId={invoiceId}
        />
      )}
    </BaseWrapper>
  ),

  // need base wrapper for above component

  "/hoa/:hoaId/recent_transactions": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/recent_transactions/" hoaId={hoaId}>
      <TransactionList type={"RECENT"} hoaId={hoaId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/accounts/reports": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/accounts/reports" hoaId={hoaId}>
      <Reports hoaId={hoaId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/plaid_stripe_tokens": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/plaid_stripe_tokens/" hoaId={hoaId}>
      <StripeSetup hoaId={hoaId} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/transactions_search": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/transactions_search/" hoaId={hoaId}>
      <TransactionList hoaId={hoaId} type="SEARCH" />
    </AccountingBase>
  ),
  "/hoa/:hoaId/transactions/:type": ({
    hoaId,
    type,
  }: {
    hoaId: string;
    type: string;
  }) => (
    <AccountingBase page="/hoa/:hoaId/transactions_search/" hoaId={hoaId}>
      <TransactionList hoaId={hoaId} type={type} />
    </AccountingBase>
  ),
  "/hoa/:hoaId/trialbalance": ({ hoaId }: { hoaId: string }) => (
    <AccountingBase page="/hoa/:hoaId/trialbalance/" hoaId={hoaId}>
      <TrialBalance hoaId={hoaId} />
    </AccountingBase>
  ),
};

export default function PrivateRouter() {
  let route = useRoutes(routes);

  useEffect(() => {
    if (!route) {
      navigate("/home");
    }
  }, [route]);

  return (
    route || (
      <div className="w-full py-20 text-center text-gray-700">Not Found</div>
    )
  );
}
