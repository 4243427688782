import { useState } from "react";
import { Transition } from "@tailwindui/react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import { dateTimeFormat } from "../utility/dateTimeFormat";
import {
  DeleteIcon,
  LoadingCircleIcon,
  TickInBoxIcon2,
} from "../../../../common/AppIcons";
import { userAtom } from "../../../../../store/UserAtom";
import { deleteNotice } from "../../../../../api/ApiV2";
import { ProfilePic } from "../../boardMeeting/constants";
import { adminTypes } from "../../../../../common/data/adminTypes";

export default function MessagePopup({
  hoaId,
  get_notices,
  notice_popup,
  set_notice_popup,
}) {
  const [delete_popup, set_delete_popup] = useState();
  // POPUP, LOADING, SUCCESS
  const [user] = useRecoilState(userAtom);

  const adminPermission = adminTypes.includes(user.selectedUserType);

  const name = () => {
    let name =
      (notice_popup?.created_by?.first_name
        ? notice_popup?.created_by?.first_name + " "
        : "") + (notice_popup?.created_by?.last_name ?? "");

    return name;
  };

  const delete_notice = () => {
    set_delete_popup("LOADING");
    deleteNotice({ hoaId, noticeId: notice_popup?.id })
      .then((res) => {
        set_delete_popup("SUCCESS");
        get_notices();
        setTimeout(() => {
          set_delete_popup();
          set_notice_popup();
        }, 2500);
      })
      .catch((res) => {
        console.log(res);
        set_delete_popup("POPUP");
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error deleting message"
        );
      });
  };
  return (
    <Transition
      className={
        "fixed bg-gray-400 bg-opacity-40 z-40 inset-0 transform flex flex-col items-center justify-center duration-300"
      }
      show={notice_popup !== undefined ? true : false}
      enter="transition-all ease-in-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-all ease-in-out duration-0"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      onClick={() => {
        set_notice_popup();
        set_delete_popup(false);
      }}
    >
      <div
        className={
          "pt-2 pb-5 pl-8 pr-4 bg-white overflow-auto max-w-lg w-full transform duration-200 delay-200 rounded " +
          (notice_popup !== undefined ? "" : " hidden")
        }
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="h-px w-full flex justify-end">
          <div
            onClick={() => {
              set_notice_popup();
              set_delete_popup(false);
            }}
            className="transform hover:scale-110 duration-100 text-gray-800 hover:text-red-600 cursor-pointer text-lg"
          >
            &#10006;
          </div>
        </div>

        <div
          className={
            "w-full flex flex-col mt-4 mr-4 duration-300 " +
            (delete_popup ? "opacity-40" : "")
          }
        >
          <div className="mb-2 w-full flex flex-row items-center gap-3">
            <h1 className="text-lg font-semibold text-gray-800 overflow-ellipsis">
              {notice_popup?.title}
            </h1>
            {notice_popup?.created_date &&
              new Date().toDateString() ===
                new Date(notice_popup.created_date).toDateString() && (
                <div
                  style={{ fontSize: "10px" }}
                  className="px-2 py-.5 bg-green-200 text-green-600 rounded-full"
                >
                  New
                </div>
              )}
          </div>

          {notice_popup?.image && (
            <img src={notice_popup?.image} className="object-fit w-full" />
          )}
          <span className="text-gray-800">{notice_popup?.description}</span>

          <div className="mt-4 w-full flex flex-row items-end justify-between gap-2">
            <div className="flex flex-row items-center gap-2 text-xs text-gray-500">
              <ProfilePic
                member={notice_popup?.created_by}
                className="h-10 w-10 text-base"
              />

              <div className="flex flex-col items-start">
                <span>{name()}</span>
                <span style={{ fontSize: "10px" }}>
                  {dateTimeFormat(notice_popup?.created_date)}
                </span>
              </div>
            </div>

            <div className="flex flex-row items-center gap-4">
              {adminPermission && (
                <button
                  onClick={() => set_delete_popup("POPUP")}
                  className={
                    "p-1 font-semibold text-sm text-red-600 rounded " +
                    (delete_popup ? "cursor-default" : "hover:text-red-400")
                  }
                >
                  <DeleteIcon className="h-5" />
                </button>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            "transform-all duration-500 overflow-hidden " +
            (delete_popup ? "max-h-96 mt-6" : "max-h-0 mt-0")
          }
        >
          {delete_popup === "POPUP" ? (
            <div>
              <span className="text-gray-800">
                Are you sure you want to delete above Message?
              </span>
              <div className="mt-3 flex flex-row items-center gap-1 w-full justify-end">
                <button
                  onClick={() => set_delete_popup()}
                  className="px-4 py-1.5 font-semibold text-sm text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    delete_notice();
                  }}
                  className="px-4 py-1.5 font-semibold text-sm text-white bg-red-600 hover:bg-red-700 rounded"
                >
                  Yes
                </button>
              </div>
            </div>
          ) : delete_popup === "SUCCESS" ? (
            <div className="w-full flex flex-col items-center gap-1">
              <span className="text-gray-800">
                Message deleted successfully
              </span>
              <TickInBoxIcon2 className="text-green-600 h-7" />
            </div>
          ) : (
            <div className="w-full py-6 flex flex-col items-center">
              <LoadingCircleIcon className="h-7" />
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
}
