import { Link } from "raviger";

export default function SelectableItem({ sidebar_data, page }) {
  const is_page_open = () => {
    if (page === sidebar_data.link) return true;
    return sidebar_data.pages?.includes(page);
  };
  return (
    <Link
      href={page !== sidebar_data.link ? sidebar_data.link : "#"}
      onClick={sidebar_data.onClick}
      className={
        "truncate w-full rounded gap-5 flex items-center px-4 py-2 text-sm leading-6 " +
        (is_page_open()
          ? " bg-indigo-100 text-indigo-600"
          : "font-light duration-200 hover:bg-indigo-100 hover:text-indigo-600")
      }
      aria-current="page"
    >
      {is_page_open() ? (
        <sidebar_data.icon
          style={{ color: "#2A32FD" }}
          className={"h-4 w-4 font-normal"}
        />
      ) : (
        <sidebar_data.icon className={"h-4 w-4 text-gray-600"} />
      )}

      {sidebar_data.label}
    </Link>
  );
}
