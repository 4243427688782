import { useState, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { navigate } from "raviger";

import {
  LeftArrowIcon,
  RightArrowIcon,
  MenuIcon,
  CalenderIcon2,
  LoadingCircleIcon,
} from "../../../common/AppIcons";
import { getBoardMeetings } from "../../../../api/ApiV2";
import BoardMeetingList from "./BoardMeetingList";
import BoardMeetingStarted from "./boardMeetingStarted/BoardMeetingStarted";
import { userAtom } from "../../../../store/UserAtom";
import Paginator from "../../../../common/Paginator";
import { adminTypes } from "../../../../common/data/adminTypes";

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const isToday = (date) => {
  const today = new Date();
  const d = new Date(date);

  return today.toDateString() === d.toDateString() ? true : false;
};

const getBlankDays = (calendarState) => {
  const date = new Date(calendarState.year, calendarState.month);
  let arr = [];
  for (let i = 0; i < date.getDay(); i++) {
    arr.push(null);
  }
  return arr;
};

const getDays = (calendarState) => {
  let date = new Date(calendarState.year, calendarState.month);
  let arr = [];
  for (
    ;
    date.getMonth() === calendarState.month;
    date.setDate(date.getDate() + 1)
  ) {
    arr.push(date.getDate());
  }
  return arr;
};

const todayStyle = (date) => {
  if (isToday(date)) return "bg-blue-500 text-white";
  else return "text-gray-700 hover:bg-blue-200";
};

export default function BoardMeeting({ hoaId }) {
  const scroll_ref = useRef(null);
  const eventTopRef = useRef(new Array());
  const eventBottomRef = useRef(new Array());

  const [user] = useRecoilState(userAtom);
  const [calendarState, setCalendarState] = useState({
    date: new Date(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [show_event_arrows, set_show_event_arrows] = useState({});
  const [tooltip, set_tooltip] = useState({
    add_meeting: false,
    meeting_menu: false,
    meeting_calender: false,
  });
  const [meeting_started, set_meeting_started] = useState({ show: false });
  const [board_meetings, set_board_meetings] = useState([]);
  const [contant, set_contant] = useState("LIST");
  //CALENDER,LIST
  const [meeting_type_show, set_meeting_type_show] = useState(() => {
    let item = sessionStorage.getItem("meeting_type_show");
    sessionStorage.removeItem("meeting_type_show");
    if (item) {
      return item;
    } else return "DRAFT";
  });
  // DRAFT, IN_PROGRESS, COMPLETED
  const [loading, set_loading] = useState(true);
  const [page, setPage] = useState({
    offset: 0,
    totalCount: 0,
  });

  const user_privilage = adminTypes.includes(user.selectedUserType);

  const get_board_meetings = (offset) => {
    getBoardMeetings({ hoaId, page: offset ?? page.offset })
      .then((res) => {
        setPage((page) => ({ ...page, totalCount: res.count }));
        set_board_meetings(res.results);
        console.log(res);
        set_loading(false);
        setTimeout(() => {
          scroll_ref.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
      });
  };

  useEffect(() => {
    get_board_meetings();
  }, []);

  const monthOffset = (change) => {
    setCalendarState((state) => {
      const currentDate = new Date(state.year, state.month + change);
      return {
        ...state,
        date: currentDate,
        month: currentDate.getMonth(),
        year: currentDate.getFullYear(),
      };
    });
  };

  function isInViewport(el) {
    if (el === undefined || el === null) return true;
    var rect = el.getBoundingClientRect(),
      top = rect.top,
      height = rect.height,
      el = el.parentNode;
    // Check if bottom of the element is off the page
    if (rect.bottom < 0) return false;
    // Check its within the document viewport
    if (top > document?.documentElement.clientHeight) return false;
    do {
      rect = el.getBoundingClientRect();
      if (top <= rect.bottom === false) return false;
      // Check if the element is out of view due to a container scrolling
      if (top + height <= rect.top) return false;
      el = el.parentNode;
    } while (el != document?.body);
    return true;
  }

  const get_events_of_day = (date) => {
    return board_meetings.filter((event) => {
      return (
        new Date(
          new Date(event.start_time).getFullYear(),
          new Date(event.start_time).getMonth(),
          new Date(event.start_time).getDate()
        ).toDateString() ===
        new Date(
          new Date(date).getFullYear(),
          new Date(date).getMonth(),
          new Date(date).getDate()
        ).toDateString()
      );
    });
  };
  if (meeting_started.show)
    return (
      <BoardMeetingStarted
        hoaId={hoaId}
        meeting={meeting_started.meeting}
        onClose={() => {
          get_board_meetings();
          set_meeting_started({ show: false });
        }}
      />
    );
  else
    return (
      <div className="antialiased sans-serif">
        <div className="px-2 md:px-4 py-2">
          <div
            ref={scroll_ref}
            className="bg-white rounded-lg border shadow overflow-hidden"
          >
            <div className="flex flex-col md:flex-row md:items-center text-xs md:text-base justify-between border-b px-2 md:px-6 mb-4 relative top-px">
              <div className="flex flex-row gap-1 items-center justify-between w-full">
                {contant === "LIST" ? (
                  <div className="flex flex-col relative top-1 flex-grow mt-3 md:mt-0 w-full">
                    <div
                      className="flex flex-row items-center w-full mb-1"
                      style={{ gap: "10px" }}
                    >
                      {[
                        {
                          label: "Upcoming Meetings",
                          value: "DRAFT",
                        },
                        {
                          label: "In progress Meetings",
                          value: "IN_PROGRESS",
                        },
                        {
                          label: "Completed Meetings",
                          value: "COMPLETED",
                        },
                      ].map((ele, ele_index) => (
                        <div
                          key={ele_index}
                          onClick={() => set_meeting_type_show(ele.value)}
                          style={{ width: "33%" }}
                          className={
                            "flex flex-col items-center gap-px cursor-pointer"
                          }
                        >
                          <span
                            className={
                              "text-sm font-semibold md:whitespace-pre " +
                              (ele.value === meeting_type_show
                                ? "text-indigo-600"
                                : "text-gray-600")
                            }
                            style={{ lineHeight: "1.2em" }}
                          >
                            <span
                              className="relative break-words bottom-px"
                              style={{ lineHeight: "1.2em" }}
                            >
                              {ele.label}
                            </span>
                            <span className="text-4xl mr-1 ml-2 relative bottom-1">
                              .
                            </span>
                            <span className="relative bottom-px">
                              {
                                board_meetings?.filter(
                                  (val) => val.status === ele.value
                                )?.length
                              }
                            </span>
                          </span>
                        </div>
                      ))}
                    </div>
                    <div
                      className="h-1 bg-indigo-500 rounded relative transform-all duration-200 "
                      style={
                        meeting_type_show === "DRAFT"
                          ? { width: "33%" }
                          : meeting_type_show === "IN_PROGRESS"
                          ? {
                              width: "33%",
                              marginLeft: "33%",
                              left: "6px",
                            }
                          : {
                              width: "33%",
                              marginLeft: "66%",
                              left: "12px",
                            }
                      }
                    />
                  </div>
                ) : (
                  <div>
                    <span className="text-lg font-bold text-gray-800">
                      {calendarState.date.toLocaleString("default", {
                        month: "long",
                      })}
                    </span>
                    <span className="ml-1 leading-3 text-lg text-gray-600 font-normal">
                      {calendarState.year}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-row justify-end items-center mt-3 gap-2">
                <div
                  className={
                    "border border-gray-400  flex flex-row rounded-lg px-1 pt-1 " +
                    (contant === "LIST" ? "hidden" : "")
                  }
                >
                  <button
                    type="button"
                    className={
                      "leading-none rounded-lg transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 items-center"
                    }
                    onClick={() => {
                      monthOffset(-1);
                    }}
                  >
                    <LeftArrowIcon className="h-6 w-6 text-gray-500 inline-flex leading-none" />
                  </button>
                  <div className="border-r border-gray-400 inline-flex h-6"></div>
                  <button
                    type="button"
                    className={
                      "leading-none rounded-lg transition ease-in-out duration-100 inline-flex items-center cursor-pointer hover:bg-gray-200 p-1"
                    }
                    onClick={() => {
                      monthOffset(1);
                    }}
                  >
                    <RightArrowIcon className="h-6 w-6 text-gray-500 inline-flex leading-none" />
                  </button>
                </div>
                <button
                  onMouseEnter={() => {
                    set_tooltip(
                      contant === "LIST"
                        ? { meeting_calender: true }
                        : { meeting_menu: true }
                    );
                  }}
                  onMouseLeave={() => {
                    set_tooltip({});
                  }}
                  onClick={() => {
                    set_tooltip({});
                    set_contant(contant === "LIST" ? "CALENDER" : "LIST");
                  }}
                  className="px-2 py-2 mx-2 rounded-lg hover:bg-gray-200 text-gray-700 hover:text-gray-900"
                >
                  <span
                    className={
                      "absolute transform -translate-x-5 translate-y-7 rounded bg-gray-600 text-white px-1 pb-px text-xs " +
                      (tooltip.meeting_menu || tooltip.meeting_calender
                        ? ""
                        : "hidden")
                    }
                  >
                    {tooltip.meeting_menu
                      ? "Show Board Meeting List"
                      : "Show Board Meeting Calender"}
                  </span>
                  {contant === "LIST" ? (
                    <CalenderIcon2 classname="h-4" />
                  ) : (
                    <MenuIcon />
                  )}
                </button>
                {user_privilage && (
                  <button
                    onMouseEnter={() => {
                      set_tooltip({ add_meeting: true });
                    }}
                    onMouseLeave={() => {
                      set_tooltip({ add_meeting: false });
                    }}
                    onClick={() => {
                      sessionStorage.setItem(
                        "meeting_type_show",
                        meeting_type_show
                      );
                      navigate(
                        "/hoa/" + hoaId + "/community/board_meetings/create/"
                      );
                    }}
                    className="px-3 py-1 text-sm items-center whitespace-pre rounded hover:opacity-90 text-white"
                    style={{ backgroundColor: "#2A32FD" }}
                  >
                    <div className="flex flex-row gap-2 items-center pb-px">
                      <svg
                        className="h-4 relative top-px"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.58759 0.583344C11.7033 0.583344 13.1249 2.06851 13.1249 4.27876V9.42959C13.1249 11.6398 11.7033 13.125 9.58759 13.125H4.12059C2.00484 13.125 0.583252 11.6398 0.583252 9.42959V4.27876C0.583252 2.06851 2.00484 0.583344 4.12059 0.583344H9.58759ZM9.58759 1.45834H4.12059C2.50359 1.45834 1.45825 2.56493 1.45825 4.27876V9.42959C1.45825 11.1434 2.50359 12.25 4.12059 12.25H9.58759C11.2052 12.25 12.2499 11.1434 12.2499 9.42959V4.27876C12.2499 2.56493 11.2052 1.45834 9.58759 1.45834ZM6.85409 4.27427C7.09559 4.27427 7.29159 4.47027 7.29159 4.71177V6.41084L8.99288 6.41096C9.23438 6.41096 9.43038 6.60696 9.43038 6.84846C9.43038 7.08996 9.23438 7.28596 8.99288 7.28596L7.29159 7.28584V8.98585C7.29159 9.22735 7.09559 9.42335 6.85409 9.42335C6.61259 9.42335 6.41659 9.22735 6.41659 8.98585V7.28584L4.71529 7.28596C4.47321 7.28596 4.27779 7.08996 4.27779 6.84846C4.27779 6.60696 4.47321 6.41096 4.71529 6.41096L6.41659 6.41084V4.71177C6.41659 4.47027 6.61259 4.27427 6.85409 4.27427Z"
                          fill="white"
                        />
                      </svg>
                      New Meeting
                    </div>
                  </button>
                )}
              </div>
            </div>

            {loading ? (
              <div className="w-full flex justify-center py-10">
                <LoadingCircleIcon className="h-12 text-blue-400" />
              </div>
            ) : contant === "LIST" ? (
              <BoardMeetingList
                hoaId={hoaId}
                meeting_type_show={meeting_type_show}
                board_meetings={board_meetings}
              />
            ) : (
              <div className="mx-1 mb-1">
                <div className="grid grid-cols-7">
                  {DAYS.map((value, index) => {
                    return (
                      <div className="px-2 py-2 ">
                        <div className="text-gray-600 text-sm uppercase tracking-wide font-bold text-center">
                          {value}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="border-t border-l grid grid-cols-7">
                  {getBlankDays(calendarState).map((_) => {
                    return (
                      <div className="text-center border-r border-b px-4 pt-2 h-28"></div>
                    );
                  })}
                  {getDays(calendarState).map((value, index1) => {
                    return (
                      <div
                        id="cale"
                        className="pt-2 border-r border-b relative h-28"
                      >
                        <div
                          className={
                            "inline-flex w-6 h-6 mx-4 items-center justify-center text-center leading-none rounded-full transition ease-in-out duration-100 " +
                            todayStyle(
                              new Date(
                                calendarState.year,
                                calendarState.month,
                                value
                              )
                            )
                          }
                        >
                          {value}
                        </div>

                        <div
                          className={
                            "absolute transform -translate-y-1 w-full py-px flex items-center justify-center bg-white bg-opacity-60 border border-gray-100 rounded transition duration-300 " +
                            (show_event_arrows["event_" + value + "_top"]
                              ? "opacity-100 "
                              : "opacity-10 ") +
                            (get_events_of_day(
                              new Date(
                                calendarState.year,
                                calendarState.month,
                                value
                              )
                            ).length > 0
                              ? ""
                              : "hidden")
                          }
                        >
                          <LeftArrowIcon className="h-3 transform rotate-90" />
                        </div>
                        <div
                          onScroll={() => {
                            set_show_event_arrows({
                              ...show_event_arrows,
                              ["event_" + value + "_top"]: !isInViewport(
                                eventTopRef.current[value]
                              ),
                              ["event_" + value + "_bottom"]: !isInViewport(
                                eventBottomRef.current[value]
                              ),
                            });
                          }}
                          className="overflow-y-auto h-16 mt-1"
                        >
                          {get_events_of_day(
                            new Date(
                              calendarState.year,
                              calendarState.month,
                              value
                            )
                          ).map((event, index) => {
                            return (
                              <p
                                key={index}
                                className={
                                  "text text-white text-sm px-1 mt-px font-thin break-words leading-4 pb-px rounded-sm hover:bg-gray-700 bg-blue-500 cursor-pointer transition duration-300 "
                                }
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "meeting_type_show",
                                    meeting_type_show
                                  );
                                  navigate(
                                    "/hoa/" +
                                      hoaId +
                                      "/community/board_meetings/" +
                                      event.id
                                  );
                                }}
                              >
                                {(index === 0 ||
                                  index ===
                                    get_events_of_day(
                                      new Date(
                                        calendarState.year,
                                        calendarState.month,
                                        value
                                      )
                                    ).length -
                                      1) && (
                                  <div
                                    className="h-px w-px"
                                    ref={(ele) => {
                                      index === 0
                                        ? (eventTopRef.current[value] = ele)
                                        : (eventBottomRef.current[value] = ele);
                                    }}
                                  />
                                )}
                                {(new Date(event.start_time).getHours() > 12 ||
                                new Date(event.start_time).getHours() === 0
                                  ? (
                                      "0" +
                                      Math.abs(
                                        new Date(event.start_time).getHours() -
                                          12
                                      )
                                    ).slice(-2)
                                  : (
                                      "0" +
                                      new Date(event.start_time).getHours()
                                    ).slice(-2)) +
                                  ":" +
                                  (
                                    "0" +
                                    new Date(event.start_time).getMinutes()
                                  ).slice(-2) +
                                  " " +
                                  (new Date(event.start_time).getHours() > 11
                                    ? " PM "
                                    : " AM ") +
                                  event.name}
                              </p>
                            );
                          })}
                        </div>
                        <div
                          className={
                            "absolute transform -translate-y-1 w-full py-px flex items-center justify-center bg-white bg-opacity-60 border border-gray-100 rounded " +
                            (show_event_arrows["event_" + value + "_bottom"]
                              ? "opacity-100 "
                              : "opacity-10 ") +
                            (get_events_of_day(
                              new Date(
                                calendarState.year,
                                calendarState.month,
                                value
                              )
                            ).length > 0
                              ? ""
                              : "hidden")
                          }
                        >
                          <LeftArrowIcon className="h-3 transform -rotate-90" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="w-full mt-5">
              <Paginator
                currentPage={page.offset / 40 + 1}
                totalPage={page.totalCount}
                entryPerPage={40}
                onChange={(pageNo) => {
                  setPage({ ...page, offset: (pageNo - 1) * 40 });
                  get_board_meetings((pageNo - 1) * 40);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
}
