const BASE_URL = "/api/v1";

const jsonErrors = [
  `Unexpected token '<', "<!DOCTYPE "... is not valid JSON`,
  `Unexpected token 'K', "KeyError a"... is not valid JSON`,
];

export const request = (
  url,
  options,
  customHeaders = {},
  onlyHeader = false
) => {
  let headers = onlyHeader
    ? {
        ...customHeaders,
      }
    : {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...customHeaders,
      };

  const token = localStorage.getItem("accessToken");
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const selectedUserProfile = sessionStorage.getItem("selectedUserProfile");

  if (selectedUserProfile) {
    headers["x-hoauser"] = selectedUserProfile;
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(url, options).then((response) =>
    response
      .json()
      .then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      })
      .catch((err) => {
        if (!response.ok) {
          return jsonErrors.includes(err.message)
            ? Promise.reject({ message: "An error has occured" })
            : Promise.reject(err);
        }
        return {
          status: "success",
        };
      })
  );
};

const urlParams = (params) => {
  console.log("Parameterizing", Object.entries(params));
  return Object.entries(params).reduce(
    (url, [key, value]) =>
      value || value === 0 || value === false
        ? url === ""
          ? `?${key}=${value}`
          : `${url}&${key}=${value}`
        : url,
    ""
  );
};

export const login = (email, password) => {
  return request(
    `${BASE_URL}/auth/login/`,
    {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
    },
    {}
  );
};

export const register = ({ data }) => {
  return request(
    `${BASE_URL}/users/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    },
    {}
  );
};

export const patchUser = ({ userId, data }) => {
  return request(`${BASE_URL}/users/${userId}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
};

export const getUser = () => {
  return request(`${BASE_URL}/users/me/`, {
    method: "GET",
  });
};

export const changePassword = ({ userId, data }) => {
  return request(`${BASE_URL}/users/${userId}/update_password/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const getHoaUser = (hoaId) => {
  return request(`${BASE_URL}/hoa/${hoaId}/members/me/`, {
    method: "GET",
  });
};

export const getUserDetails = ({ id }) => {
  return request(`${BASE_URL}/users/${id}`, {
    method: "GET",
  });
};

export const refreshAccessToken = ({ token }) => {
  return request(`${BASE_URL}/auth/token/refresh/`, {
    method: "POST",
    body: JSON.stringify({ refresh: token }),
  });
};

export const verifyToken = ({ token }) => {
  return request(`${BASE_URL}/auth/token/verify/`, {
    method: "POST",
    body: JSON.stringify({ token }),
  });
};

export const getHoas = ({ offset, search, limit = 10, org }) => {
  return request(
    `${BASE_URL}/hoa/${urlParams({ limit, offset, search, org })}`,
    {
      method: "GET",
    }
  );
};

export const getHoa = ({ external_id }) => {
  return request(`${BASE_URL}/hoa/${external_id}/`, {
    method: "GET",
  });
};

export const postHoa = ({ data }) => {
  return request(`${BASE_URL}/hoa/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const postHoaCreateStage = ({ hoaId, data }) => {
  return request(`${BASE_URL}/hoa/${hoaId}/update_stage/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const postIntegrateSquadhub = ({ hoaId }) => {
  return request(`${BASE_URL}/hoa/${hoaId}/create_squadhub_profile/`, {
    method: "POST",
  });
};

export const patchHoa = ({ external_id, data }) => {
  return request(`${BASE_URL}/hoa/${external_id}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
};

export const postBuilding = ({ hoaId, data }) => {
  return request(`${BASE_URL}/hoa/${hoaId}/addresses/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const patchBuilding = ({ hoaId, buildingId, data }) => {
  return request(`${BASE_URL}/hoa/${hoaId}/addresses/${buildingId}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
};

export const getBuildings = ({ hoaId }) => {
  return request(
    `${BASE_URL}/hoa/${hoaId}/addresses/${urlParams({ limit: 1000 })}`,
    {
      method: "GET",
    }
  );
};

export const enableModule = ({ external_id, module }) => {
  // module = ACCOUNTING || COMPLIANCE
  return request(`${BASE_URL}/hoa/${external_id}/enable_module/`, {
    method: "POST",
    body: JSON.stringify({ module }),
  });
};

export const getMelissaAddress = ({ address, state = "CA" }) => {
  return request(`${BASE_URL}/hoa/melissa_address/`, {
    method: "POST",
    body: JSON.stringify({
      address,
      state,
    }),
  });
};

export function addHoaMembers(hoaId, data) {
  return request(`${BASE_URL}/hoa/${hoaId}/members/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function addHoaUnit(hoaId, data) {
  return request(`${BASE_URL}/hoa/${hoaId}/units/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function addMultipleHoaMembers({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/members/bulk_create/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getHoaMembers(hoaid, filters, search, page = 0) {
  return request(
    `${BASE_URL}/hoa/${hoaid}/members/${urlParams({
      offset: page,
      limit: 300,
      search,
      ...filters,
    })}`,
    {
      method: "GET",
    }
  );
}

export function getHoaUnits(hoaid, page = 0, search, filters) {
  return request(
    `${BASE_URL}/hoa/${hoaid}/units/${urlParams({
      offset: page,
      limit: 300,
      search,
      ...filters,
    })}`,
    {
      method: "GET",
    }
  );
}

export function getHoaProperties(hoaid, page = 0, search) {
  return request(
    `${BASE_URL}/hoa/${hoaid}/addresses/${urlParams({
      offset: page,
      limit: 300,
      search,
    })}`,
    {
      method: "GET",
    }
  );
}

export function getHoaAddresses(hoaid) {
  return request(`${BASE_URL}/hoa/${hoaid}/addresses/`, {
    method: "GET",
  });
}

export function updateProperty(hoaId, data, propertyID) {
  return request(`${BASE_URL}/hoa/${hoaId}/addresses/${propertyID}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function uploadAddressPhoto({ hoaId, propertyID, data }) {
  let form_data = new FormData();
  Object.keys(data).map((val) => form_data.append(val, data[val]));

  return request(
    `${BASE_URL}/hoa/${hoaId}/addresses/${propertyID}/upload_photo/`,
    {
      method: "POST",
      body: form_data,
    },
    {},
    true
  );
}

export function getAddressPhotos({ hoaId, propertyID }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/addresses/${propertyID}/get_photos`,
    {
      method: "GET",
    }
  );
}

export function updateMember(hoaId, data, userId) {
  return request(`${BASE_URL}/hoa/${hoaId}/members/${userId}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function getOrg({ type, all, limit }) {
  return request(`${BASE_URL}/org/${urlParams({ all, type, limit })}`, {
    method: "GET",
  });
}

export function getOrgDetails({ id }) {
  return request(`${BASE_URL}/org/${id}/`, {
    method: "GET",
  });
}

export function postOrg({ data }) {
  return request(`${BASE_URL}/org/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function patchOrg({ id, data }) {
  return request(`${BASE_URL}/org/${id}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function getOrgMembers({ id }) {
  return request(`${BASE_URL}/org/${id}/members`, {
    method: "GET",
  });
}

export function postOrgMembersBulk({ id, data }) {
  return request(`${BASE_URL}/org/${id}/members/bulk_create/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function updateUnit(hoaId, data, unitId) {
  return request(`${BASE_URL}/hoa/${hoaId}/units/${unitId}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function deleteHoaMember({ hoaId, member_id }) {
  return request(`${BASE_URL}/hoa/${hoaId}/members/${member_id}/`, {
    method: "DELETE",
  });
}

export function postUnit({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/units/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function bulkPostUnits({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/units/bulk_create/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getUnits({ hoaId, offset, limit = 4000 }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/units/${urlParams({ offset, limit })}`,
    {
      method: "GET",
    }
  );
}

export function getUnitsBalances({ hoaId, offset, limit = 4000 }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/bills/get_unit_balances/${urlParams({
      offset,
      limit,
    })}`,
    {
      method: "GET",
    }
  );
}

export function patchUnits({ hoaId, unitId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/units/${unitId}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function bulkPatchUnits({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/members/bulk_dues_update/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function memberAddressUnitsDetails({ hoaId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/members/my_profiles/`, {
    method: "GET",
  });
}

export function postAccount({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/accounts/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getAccounts({
  hoaId,
  type,
  bank_account,
  page = 0,
  page_size,
  role,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/accounts/${urlParams({
      type,
      is_bank_account: bank_account,
      offset: page,
      limit: page_size,
      role,
      with_balance: true,
    })}`,
    {
      method: "GET",
    }
  );
}

export function getAccountDetails({ hoaId, accountId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/accounts/${accountId}/`, {
    method: "GET",
  });
}

export function getAccountsSummary({ hoaId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/accounts/account_summary/`, {
    method: "GET",
  });
}

export function getProfitLoss({ hoaId, start_date, end_date }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/accounts/profit_loss_report/${urlParams({
      start_date,
      end_date,
    })}`,
    {
      method: "GET",
    }
  );
}

export function updateStripeSetup({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/update_plaid_stripe_key/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getStripeSetup({ hoaId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/get_plaid_stripe_key/`, {
    method: "GET",
  });
}

export function linkPlaidAccount({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/plaid/exchange_public_token/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getPlaidBanking({ hoaId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/plaid/create_link_token/`, {
    method: "POST",
  });
}

export function getplaidDataHoa({ hoaId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/plaid/`, {
    method: "GET",
  });
}

export function payWithPlaid({ hoaId, billId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/bills/${billId}/pay_revenue/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function payWithCard({ hoaId, billId }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/bills/${billId}/create_stripe_checkout_url/`,
    {
      method: "GET",
    }
  );
}

export function getUnmatchedTransactions({ hoaId, accountId }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/plaid/unmatched_plaid_transactions/${accountId}/`,
    {
      method: "GET",
    }
  );
}

export function postCheck({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/transactions/create_check/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getBills({
  hoaId,
  page = 0,
  page_size,
  invoice,
  paid,
  user,
  vendor,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/bills/${urlParams({
      offset: page,
      limit: page_size,
      is_paid: paid,
      is_invoice: invoice,
      unit: user,
      vendor,
    })}`,
    {
      method: "GET",
    }
  );
}

export function postBillPayment({ hoaId, data, billId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/bills/${billId}/pay_bill/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function postBill({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/bills/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function postInitialBill({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/bills/initial_bill/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getBill({ hoaId, billId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/bills/${billId}/`, {
    method: "GET",
  });
}

export function postVendor({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/vendors/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function patchVendor({ hoaId, vendorId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/vendors/${vendorId}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function getVendors({ hoaId, page = 1 }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/vendors/${urlParams({ page, page_size: 300 })}`,
    {
      method: "GET",
    }
  );
}

export function getVendorBalances({ hoaId, page = 0, page_size = 300 }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/bills/get_vendor_balances/${urlParams({
      offset: page,
      limit: page_size,
    })}`,
    {
      method: "GET",
    }
  );
}

export function getVendorTransactions(hoaId) {
  return request(`${BASE_URL}/hoa/${hoaId}/bills/get_unique_pending_vendors/`, {
    method: "GET",
  });
}

export function postDocument(hoaId, data) {
  return request(`${BASE_URL}/hoa/${hoaId}/compliance_documents/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getDocuments(hoaId, documentType) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/compliance_documents/${urlParams({
      document_type: documentType,
    })}`,
    {
      method: "GET",
    }
  );
}

// export function getDocumentsWithComments(hoaId, docId) {
//   return request({
//     url: `${BASE_URL}/hoa/${hoaId}/compliance_document/all/${docId}/`,
//     method: "GET",
//   });
// }

// export function commentOnDocument(hoaId, docId, data) {
//   return request(
//     {
//       url:
//         API_BASE_URL +
//         `hoa/${hoaId}/compliance_documents/${docId}/add_doc_comment`,
//       method: "POST",
//       body: JSON.stringify(data),
//     },
//     { "X-CSRFToken": getCsrfToken() }
//   );
// }

export function getCreateRevision({ hoaId, documentId }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/compliance_documents/${documentId}/get_file/`,
    {
      method: "GET",
    }
  );
}

export function uploadCreateRevision({ hoaId, documentId, data }) {
  let form_data = new FormData();
  Object.keys(data).map((val) => form_data.append(val, data[val]));

  return request(
    `${BASE_URL}/hoa/${hoaId}/compliance_documents/${documentId}/upload_file/`,
    {
      method: "POST",
      body: form_data,
    },
    {},
    true
  );
}

// from here

export function listAmenities({ hoaId, offset = 0, limit = 20 }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/amenities/${urlParams({
      offset,
      limit,
    })}`,
    {
      method: "GET",
    }
  );
}

export function getAmenity({ hoaId, amenityId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/amenities/${amenityId}/`, {
    method: "GET",
  });
}

export function postAmenities({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/amenities/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function postAmenitiesBulk({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/amenities/bulk_create/`, {
    method: "POST",
    body: JSON.stringify({ amenities: data }),
  });
}

export function editAmenities({ hoaId, id, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/amenities/${id}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function uploadAmenityPhoto({ hoaId, id, data }) {
  let form_data = new FormData();
  Object.keys(data).map((val) => form_data.append(val, data[val]));

  return request(
    `${BASE_URL}/hoa/${hoaId}/amenities/${id}/upload_photo/`,
    {
      method: "POST",
      body: form_data,
    },
    {},
    true
  );
}

export function getAmenityPhoto({ hoaId, id }) {
  return request(`${BASE_URL}/hoa/${hoaId}/amenities/${id}/get_photos`, {
    method: "GET",
  });
}

// export function deleteAmenities({ hoaId, id }) {
//   return request(`${BASE_URL}/hoa/${hoaId}/amenities/${id}/`, {
//     method: "DELETE",
//   });
// }

export function getMyAmenityReservation({
  hoaId,
  offset = 0,
  limit = 20,
  start_datetime,
  end_datetime,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/amenities/view_all_reservations/${urlParams({
      offset,
      limit,
      start_datetime,
      end_datetime,
    })}`,
    {
      method: "GET",
    }
  );
}

export function getAmenityReservation({
  hoaId,
  amenityId,
  datetime_before,
  datetime_after,
  limit = 20,
  offset = 0,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/amenities/${amenityId}/reservation/${urlParams({
      datetime_before,
      datetime_after,
      offset,
      limit,
    })}`,
    {
      method: "GET",
    }
  );
}

export function getAmenityReservationSlots({ hoaId, amenityId, data }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/amenities/${amenityId}/reservation/available_timeslots/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function postAmenityReservation({ hoaId, amenityId, data }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/amenities/${amenityId}/reservation/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function deleteReservation({ hoaId, amenityId, reservationId }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/amenities/${amenityId}/reservation/${reservationId}/`,
    {
      method: "DELETE",
    }
  );
}

export function getBoardMeetings({ hoaId, page = 0, limit = 40 }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${urlParams({
      offset: page,
      limit: limit,
    })}`,
    {
      method: "GET",
    }
  );
}

export function addBoardMeeting({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/board_meetings/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function editBoardMeeting({ hoaId, data, id }) {
  return request(`${BASE_URL}/hoa/${hoaId}/board_meetings/${id}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function getBoardMeeting({ hoaId, boardMeetingId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/`, {
    method: "GET",
  });
}

export function getBoardMeetingAttendees({ hoaId, boardMeetingId }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/attendees/`,
    {
      method: "GET",
    }
  );
}

export function editBoardMeetingAttendee({
  hoaId,
  boardMeetingId,
  attendeeId,
  data,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/attendees/${attendeeId}/`,
    {
      method: "PATCH",
      body: JSON.stringify(data),
    }
  );
}

export function postBoardMeetingAttendee({ hoaId, boardMeetingId, data }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/attendees/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function getBoardMeetingAgendas({ hoaId, boardMeetingId }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/`,
    {
      method: "GET",
    }
  );
}

export function postBoardMeetingAgendas({ hoaId, boardMeetingId, data }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function editBoardMeetingAgendas({
  hoaId,
  boardMeetingId,
  agendaId,
  data,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/${agendaId}/`,
    {
      method: "PATCH",
      body: JSON.stringify(data),
    }
  );
}

export function getBoardMeetingAgendaMotions({
  hoaId,
  boardMeetingId,
  agendaId,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/${agendaId}/motions/`,
    {
      method: "GET",
    }
  );
}

export function postBoardMeetingAgendaMotion({
  hoaId,
  boardMeetingId,
  agendaId,
  data,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/${agendaId}/motions/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function editBoardMeetingAgendaMotion({
  hoaId,
  boardMeetingId,
  agendaId,
  motionId,
  data,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/${agendaId}/motions/${motionId}/`,
    {
      method: "PATCH",
      body: JSON.stringify(data),
    }
  );
}

export function getBoardMeetingAgendaMotionVotes({
  hoaId,
  boardMeetingId,
  agendaId,
  motionId,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/${agendaId}/motions/${motionId}/votes/`,
    {
      method: "GET",
    }
  );
}

export function postBoardMeetingAgendaMotionVotes({
  hoaId,
  boardMeetingId,
  agendaId,
  motionId,
  data,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/${agendaId}/motions/${motionId}/votes/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function editBoardMeetingAgendaMotionVotes({
  hoaId,
  boardMeetingId,
  agendaId,
  motionId,
  voteId,
  data,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/${agendaId}/motions/${motionId}/votes/${voteId}/`,
    {
      method: "PATCH",
      body: JSON.stringify(data),
    }
  );
}

export function getBoardMeetingAgendaActionItems({
  hoaId,
  boardMeetingId,
  agendaId,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/${agendaId}/action_items/`,
    {
      method: "GET",
    }
  );
}

export function postBoardMeetingAgendaActionItem({
  hoaId,
  boardMeetingId,
  agendaId,
  data,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/${agendaId}/action_items/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function editBoardMeetingAgendaActionItem({
  hoaId,
  boardMeetingId,
  agendaId,
  actionItemId,
  data,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/board_meetings/${boardMeetingId}/agendas/${agendaId}/action_items/${actionItemId}/`,
    {
      method: "PATCH",
      body: JSON.stringify(data),
    }
  );
}

export function postTicket({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/tickets/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getTicket({ hoaId, ...props }) {
  return request(`${BASE_URL}/hoa/${hoaId}/tickets/${urlParams(props)}`, {
    method: "GET",
  });
}

export function getSRsUnderTicket({ hoaId, ticketId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/tickets/${ticketId}/srs/`, {
    method: "GET",
  });
}

export function getSRDetail({ hoaId, ticketId, srId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/tickets/${ticketId}/srs/${srId}/`, {
    method: "GET",
  });
}

export function getTicketDetails({ hoaId, ticketId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/tickets/${ticketId}/`, {
    method: "GET",
  });
}

export function getEstimates({ hoaId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/tickets/estimates/`, {
    method: "GET",
  });
}

export function postEstimateAccept({ hoaId, estimateId, data }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/tickets/estimates/${estimateId}/accept/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function getEstimateDetails({ hoaId, estimateId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/tickets/estimates/${estimateId}/`, {
    method: "GET",
  });
}

export function getMessages({ hoaId, ticketId, limit, offset }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/tickets/${ticketId}/messages/${urlParams({
      limit,
      offset,
    })}`,
    {
      method: "GET",
    }
  );
}

export function postMessage({ hoaId, ticketId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/tickets/${ticketId}/send_message/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function generateNewConversation({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/tickets/new_conversation/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getEvents({ hoaId, limit = 40, offset = 0 }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/events/${urlParams({
      limit,
      offset,
    })}`,
    {
      method: "GET",
    }
  );
}

export function postEvents({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/events/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function respondToEventRequest({ hoaId, eventId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/events/${eventId}/change_status/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

// export function postEventRequest({ hoaId, data }) {
//   return request(
//     {
//       url: API_BASE_URL + `hoa/${hoaId}/events/request_for_approval/ `,
//       method: "POST",
//       body: JSON.stringify(data),
//     },
//     { "X-CSRFToken": getCsrfToken() }
//   );
// }

// export function responseToEventRequest({ hoaId, event_id, data }) {
//   return request(
//     {
//       url: API_BASE_URL + `hoa/${hoaId}/events/${event_id}/approve_or_reject/`,
//       method: "POST",
//       body: JSON.stringify(data),
//     },
//     { "X-CSRFToken": getCsrfToken() }
//   );
// }

// export function getEventsRequests({ hoaId, admin }) {
//   return request(
//     {
//       url:
//         API_BASE_URL +
//         `hoa/${hoaId}/events/${admin ? "list_for_admin" : "list_for_owner"}/`,
//       method: "GET",
//     },
//     { "X-CSRFToken": getCsrfToken() }
//   );
// }

export function editEvents({ hoaId, eventId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/events/${eventId}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function getNotices({ hoaId, limit = 30, offset = 0 }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/message_board/${urlParams({
      limit,
      offset,
    })}`,
    {
      method: "GET",
    }
  );
}

export function PostNotice({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/message_board/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function deleteNotice({ hoaId, noticeId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/message_board/${noticeId}/`, {
    method: "DELETE",
  });
}

export function getAllTransactions({
  hoaId,
  page = 0,
  type,
  search_field,
  billId,
  accountId,
  limit,
  to_reconcile,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/transactions${urlParams({
      type,
      offset: page,
      search_field,
      bill: billId,
      account: accountId,
      limit,
      to_reconcile,
    })}`,
    {
      method: "GET",
    }
  );
}

export function updateReconcileTransactions({ hoaId, accountId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/accounts/${accountId}/reconcile/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getTransactionDetails({ hoaId, transactionId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/transactions/${transactionId}/`, {
    method: "GET",
  });
}

export function getTransactionLegs({
  hoaId,
  transactionId,
  account,
  page,
  limit,
}) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/legs/${urlParams({
      account,
      transaction: transactionId,
      page,
      limit,
    })}`,
    {
      method: "GET",
    }
  );
}

export function postPlaidTransaction({ hoaId, data }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/transactions/create_plaid_transaction/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function matchPlaidTransaction({ hoaId, transactionId, data }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/transactions/${transactionId}/match_plaid_transaction/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function createJournalEntry(hoaId, data) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/transactions/create_journal_entry/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function verifyAccountsPayable({ hoaId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/bills/validate_payable/`, {
    method: "GET",
  });
}

export function verifyAccountsReceivable({ hoaId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/bills/validate_receivable/`, {
    method: "GET",
  });
}

export function getYourArchitecturalPlans({ hoaId }) {
  return request(`${BASE_URL}/hoa/${hoaId}/architecture_plan/`, {
    method: "GET",
  });
}

// export function getReviewArchitecturalPlans({ hoaId }) {
//   return request(
//     {
//       url: API_BASE_URL + `hoa/${hoaId}/architectural_plans/list_for_review`,
//       method: "GET",
//     },
//     { "X-CSRFToken": getCsrfToken() }
//   );
// }

export function getArchitecturalPlanDetails({ hoaId, id }) {
  return request(`${BASE_URL}/hoa/${hoaId}/architecture_plan/${id}/`, {
    method: "GET",
  });
}

export function patchArchitecturalPlanDetails({ hoaId, id, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/architecture_plan/${id}/`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export function postArchitecturalPlans({ hoaId, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/architecture_plan/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function postArchitecturalResolution({ hoaId, id, data }) {
  return request(
    `${BASE_URL}/hoa/${hoaId}/architecture_plan/${id}/plan_resolution/`,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
}

export function postArchitecturalComment({ hoaId, id, data }) {
  return request(`${BASE_URL}/hoa/${hoaId}/architecture_plan/${id}/comment/`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export function getArchitecturalComment({ hoaId, id }) {
  return request(`${BASE_URL}/hoa/${hoaId}/architecture_plan/${id}/comment/`, {
    method: "GET",
  });
}
