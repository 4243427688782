import { Link } from "raviger";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

import { EditIcon4, OpenEyeIcon2 } from "../../../../../../common/AppIcons";
import { defaultPic } from "../../../../../data/defaultPic";
import AmenityAvailabilityBadge from "../../../../../utility/AmenityAvailabilityBadge";
import { minutes_to_string } from "../../../data/hours_array";
import { userAtom } from "../../../../../../../store/UserAtom";
import { adminTypes } from "../../../../../../../common/data/adminTypes";

export default function AmenityCard({
  hoaId,
  amenity,
  edit_amenity,
  set_reservationList,
  className,
}) {
  const [user] = useRecoilState(userAtom);

  useEffect(() => {
    if (sessionStorage.getItem("view_bookings") === amenity.id) {
      sessionStorage.removeItem("view_bookings");
      set_reservationList(amenity);
    }
  }, []);

  const adminPrivileges = adminTypes.includes(user.selectedUserType);
  return (
    <div
      style={{ backgroundColor: "#F8FAFC" }}
      className={"rounded flex flex-col items-start " + className}
    >
      <img
        src={amenity.picture_url ?? defaultPic}
        className="w-full h-32 rounded-t"
      />
      <div className="w-full h-full flex flex-col items-start py-3 px-3">
        <span
          style={{ color: "#18181B", fontSize: "16px" }}
          className="font-semibold"
        >
          {amenity.name ?? ""}
        </span>
        {amenity.amenity_type && (
          <AmenityAvailabilityBadge
            className="mt-1"
            availability={amenity.amenity_type}
          />
        )}
        <div className="w-full h-full flex flex-col items-start pl-1.5">
          {minutes_to_string(amenity.per_day_limit) && (
            <span
              style={{ color: "#71717A", fontSize: "14px" }}
              className="mt-1.5 text-sm"
            >
              Per day limit: {minutes_to_string(amenity.per_day_limit)}
            </span>
          )}
          {amenity.max_capacity && (
            <span
              style={{ color: "#71717A", fontSize: "14px" }}
              className="text-sm"
            >
              Passes per slot: {amenity.max_capacity ?? ""}
            </span>
          )}
          <div className="h-full flex flex-col justify-end">
            <div
              className={
                "mt-3 w-full flex flex-row items-center flex-wrap gap-2 " +
                (!adminPrivileges ? "justify-end" : "justify-between")
              }
            >
              {adminPrivileges ? (
                <div
                  className="flex flex-row items-center gap-1 cursor-pointer hover:opacity-70 duration-200 text-sm"
                  style={{ color: "#2A32FD", fontSize: "14px" }}
                  onClick={() => edit_amenity()}
                >
                  <EditIcon4 className="rounded" style={{ width: "15px" }} />{" "}
                  Edit Amenity
                </div>
              ) : (
                <div
                  className="rounded px-4 py-1 flex flex-row items-center gap-2 cursor-pointer hover:opacity-70 duration-200 text-sm"
                  style={{
                    color: "#2A32FD",
                    fontSize: "14px",
                    border: "1px solid #2A32FD",
                  }}
                  onClick={() => edit_amenity(true)}
                >
                  View Amenity
                </div>
              )}

              {adminPrivileges ? (
                <div
                  className="flex flex-row items-center gap-1 cursor-pointer hover:opacity-70 duration-200 text-sm text-gray-600"
                  style={{ fontSize: "14px" }}
                  onClick={() => set_reservationList(amenity)}
                >
                  <OpenEyeIcon2 className="" style={{ width: "15px" }} /> View
                  Bookings
                </div>
              ) : (
                <Link
                  href={`/hoa/${hoaId}/community/reservations/create/${amenity?.id}`}
                  style={{
                    backgroundColor: "#2A32FD",
                    border: "2px solid #2A32FD",
                  }}
                  className="rounded px-4 py-1 text-white hover:opacity-70 text-sm"
                >
                  Reserve
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
