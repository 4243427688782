import React, { useState, useEffect } from "react";

import AmenityForm from "./components/amenityForm";
import AmenityList from "./components/amenityList";
import ReservationListAdmin from "./components/reservationListAdmin";
import { week_days } from "./data/week_days";

const defaultSchedulesObject = () => {
  let tempObj = {};
  week_days.forEach((val) => {
    tempObj = {
      ...tempObj,
      [val.toLocaleLowerCase()]: {
        is_available: true,
        time_ranges: [{ start_time: null, end_time: null }],
      },
    };
  });
  return tempObj;
};

let initialState = {
  show: true,
  data: {
    description: "amenity",
    amenity_type: "REQUIRES_PASS",
    schedules: defaultSchedulesObject(),
    updatedStatus: "EDITED",
    //EDITED, UNCHANGED, SAVED, LOADING,
  },
};
export default function Amenities({ hoaId }) {
  const [amenityForm, set_amenityForm] = useState({});
  const [reservationList, set_reservationList] = useState();

  return (
    <div style={{ minHeight: "500px" }} className="bg-white rounded-md w-full">
      {amenityForm?.show ? (
        <AmenityForm
          {...{
            hoaId,
            amenityForm,
            set_amenityForm,
          }}
        />
      ) : reservationList ? (
        <ReservationListAdmin
          closePage={() => set_reservationList()}
          amenity={reservationList}
          edit_amenity={() => {
            let tempSchedules = {};
            week_days.forEach(
              (value) =>
                (tempSchedules = {
                  ...tempSchedules,
                  [value.toLocaleLowerCase()]:
                    reservationList?.availability[value.toLocaleLowerCase()],
                })
            );
            set_amenityForm({
              show: true,
              data: {
                ...reservationList,
                existing: true,
                schedules: tempSchedules,
              },
              picture_url: reservationList.picture_url,
              id: reservationList.id ?? 1,
            });
          }}
          {...{ hoaId }}
        />
      ) : (
        <AmenityList
          {...{
            hoaId,
            set_amenityForm,
            initialState,
            set_reservationList,
          }}
        />
      )}
    </div>
  );
}
