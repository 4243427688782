import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { userAtom } from "../../../store/UserAtom";
import { getYourArchitecturalPlans } from "../../../api/ApiV2";
import { LoadingCircleIcon } from "../../common/AppIcons";
import ARList from "./ARList";
import ARDetails from "./ar_Details/ARDetails";
import ARReview from "./ar_review/ARReview";
import CreateAR from "./CreateAR";
import { adminTypes } from "../../../common/data/adminTypes";

export default function ArchetecturalReview({ hoaId, documentType }) {
  const [user] = useRecoilState(userAtom);
  const admin = adminTypes.includes(user.selectedUserType);
  const [review_documents, set_review_documents] = useState([]);
  const [your_documents, set_your_documents] = useState([]);
  const [loading, set_loading] = useState({
    your_plans: true,
  });
  const [page, set_page] = useState({ page: "ar_list" });
  // ar_list,create_ar,your_AR_details,ar_review

  useEffect(() => {
    if (page.page === "ar_list") {
      get_your_plans();
    }
  }, [page, user.selectedUserProfile]);

  const get_your_plans = () => {
    getYourArchitecturalPlans({ hoaId: hoaId })
      .then((res) => {
        set_your_documents(
          res.results?.filter(
            (val) =>
              (!val.submission_date || val.status === "CLOSED") &&
              (admin || val?.created_by?.email === user.email)
          )
        );
        set_review_documents(
          res.results?.filter(
            (val) =>
              val.status !== "CLOSED" &&
              val.submission_date &&
              (admin || val?.created_by?.email === user.email)
          )
        );
        console.log(res);
        set_loading((loading) => {
          return { ...loading, your_plans: false };
        });
      })
      .catch((res) => {
        console.log(res);
        set_loading((loading) => {
          return { ...loading, your_plans: false };
        });
      });
  };

  if (loading.your_plans)
    return (
      <div className="min-w-full py-16 flex items-center justify-center">
        <LoadingCircleIcon className="h-12" />
      </div>
    );
  else if (page.page === "ar_list")
    return (
      <ARList
        documentType={documentType}
        your_documents={your_documents}
        review_documents={review_documents}
        create_ar={() => set_page({ page: "create_ar" })}
        hoaId={hoaId}
        open_AR_details={(id) => {
          set_page({ page: "your_AR_details", id: id });
        }}
        open_AR_review={(id) => {
          set_page({ page: "ar_review", id: id });
        }}
      />
    );
  else if (page.page === "create_ar")
    return (
      <CreateAR
        hoaId={hoaId}
        closePage={() => {
          set_page({ page: "ar_list" });
          get_your_plans();
          // if (uploadDocumentPrivilage()) get_review_plans();
        }}
      />
    );
  else if (page.page === "your_AR_details")
    return (
      <ARDetails
        hoaId={hoaId}
        id={page.id}
        closePage={() => set_page({ page: "ar_list" })}
      />
    );
  else if (page.page === "ar_review")
    return (
      <ARReview
        hoaId={hoaId}
        id={page.id}
        closePage={() => set_page({ page: "ar_list" })}
      />
    );
  else return null;
}
