import { Link } from "raviger";
import React from "react";
import { IInCircleIcon } from "../../common/AppIcons";

import Popup from "../../common/Popup";

export default function PlaidDataMissingPopup({ show, onClose, hoaId }) {
  return (
    <Popup show={show} setShow={onClose}>
      <div className="rounded-md bg-white py-6 sm:py-10 px-6 sm:px-10 flex flex-col items-center w-full max-w-xl">
        <IInCircleIcon className="h-12 w-12 text-red-600 bg-red-100 rounded-full" />
        <span className="mt-6 text-gray-800 font-semibold text-center">
          Please setup the Plaid and Stripe in order to use the Banking
          features.
        </span>
        <div className="mt-6 flex flex-row gap-6 items-center">
          <button
            className="px-7 py-2 border-2 border-gray-400 text-gray-600 hover:text-gray-700 font-semibold bg-white hover:bg-gray-200 focus:outline-none rounded text-sm"
            onClick={onClose}
          >
            Cancel
          </button>
          <Link
            style={{
              backgroundColor: "#2A32FD",
              border: "solid 2px #2A32FD",
            }}
            className="px-8 py-2 rounded shadow-sm text-white hover:opacity-70 focus:outline-none text-sm"
            href={"/hoa/" + hoaId + "/plaid_stripe_tokens/"}
          >
            Setup Now
          </Link>
        </div>
      </div>
    </Popup>
  );
}
