import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePath } from "raviger";

import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import { userAtom } from "../store/UserAtom";
import { LoadingCircleIcon } from "../components/common/AppIcons";
import "../utils/interFont.css";
import { useUserActions } from "../actions/UserActions";

function AppRouter() {
  const [user] = useRecoilState(userAtom);
  const { verify, me, hoaMe, refresh } = useUserActions();
  const path = usePath();

  useEffect(() => {
    if (user.variant === "loading") {
      console.log("Effect: loading");
      verify();
    } else if (user.variant === "loggedIn") {
      console.log("Effect: loggedIn");
      me();
    }
  }, [user?.accessToken, user.variant]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refresh();
    }, 1000 * 60 * 60 * 24);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  useEffect(() => {
    let pathStrings = path?.split("/").filter((val) => val);

    user?.variant === "user" &&
      pathStrings[0] === "hoa" &&
      pathStrings[1] !== "create" &&
      !user.selectedUserType &&
      hoaMe(pathStrings[1]);
  }, [path, user?.variant]);

  return (
    <div className="inter-font">
      {user.variant === "loading" ||
      user.variant === "loggedIn" ||
      user.loading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <LoadingCircleIcon className="h-12" />
        </div>
      ) : user.variant === "user" ? (
        <PrivateRouter />
      ) : (
        <PublicRouter />
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default AppRouter;
