import { useState } from "react";
import { useRecoilState } from "recoil";

import { PersonChangeIcon, ThreeLinesIcon } from "../../../common/AppIcons";
import Profile from "./components/Profile";
import SearchBar from "./components/SearchBar";
import { userAtom } from "../../../../store/UserAtom";
import UserProfileChangePopup from "../../../../baseWrapper/UserProfileChangePopup";

export default function Topbar({ hoaId, set_sidebar_open }) {
  const [user] = useRecoilState(userAtom);
  const [selectProfilePopup, setSelectProfilePopup] = useState(false);

  const selectedProfile = user?.userProfiles?.find(
    (val) => val.id === user.selectedUserProfile
  );

  let userType = selectedProfile?.user_type?.toLowerCase().replace("_", " ")
    ? selectedProfile?.user_type?.toLowerCase().replace("_", " ")
    : "";

  return (
    <div className="flex flex-col">
      <div className="relative flex-shrink-0 flex border-b border-gray-200 lg:border-none">
        <button
          onClick={() => {
            set_sidebar_open(true);
          }}
          className="px-2 border-r border-gray-200 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
        >
          <span className="sr-only">Open sidebar</span>
          <ThreeLinesIcon className="h-6 w-6" />
        </button>

        <div className="w-full flex flex-col px-4 sm:px-6 lg:px-8 py-3 md:pt-4">
          <div className="w-full flex-1 flex justify-between lg:max-w-6xl lg:mx-auto gap-6">
            <div className="flex flex-1 gap-2">
              <SearchBar hoaId={hoaId} />
              <button
                onClick={() => setSelectProfilePopup(true)}
                style={{ maxWidth: "200px" }}
                className="hidden md:flex flex-shrink-0 px-2.5 py-1.5 flex-row justify-start items-center gap-1 text-gray-800 rounded bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <PersonChangeIcon className="h-4 flex-shrink-0" />
                <span className="capitalize text-xs truncate">
                  {(user.hoaType === "SINGLE_FAMILY" ||
                    user.hoaType === "MULTI_FAMILY") &&
                  userType === "board member"
                    ? "owner"
                    : userType}
                  {selectedProfile?.unit_object &&
                    "(" +
                      (selectedProfile.unit_object.unit_number ?? "") +
                      (selectedProfile.unit_object.address_object
                        ?.street_address_1
                        ? ", "
                        : "") +
                      (selectedProfile.unit_object.address_object
                        ?.street_address_1 ?? "") +
                      ")"}
                </span>
              </button>
            </div>
            <div className="flex items-center">
              {/* <Notifications hoaId={hoaId} /> */}

              <Profile hoaId={hoaId} />
            </div>
          </div>
          <div>
            <button
              onClick={() => setSelectProfilePopup(true)}
              className="mt-2 max-w-full flex md:hidden flex-shrink-0 px-6 py-4 flex-row justify-start items-center gap-1 text-gray-800 rounded bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-white"
            >
              <PersonChangeIcon className="h-4 flex-shrink-0" />
              <span className="capitalize text-sm truncate">
                {(user.hoaType === "SINGLE_FAMILY" ||
                  user.hoaType === "MULTI_FAMILY") &&
                userType === "board member"
                  ? "owner"
                  : userType}
                {selectedProfile?.unit_object &&
                  "(" +
                    (selectedProfile.unit_object.unit_number ?? "") +
                    (selectedProfile.unit_object.address_object
                      ?.street_address_1
                      ? ", "
                      : "") +
                    (selectedProfile.unit_object.address_object
                      ?.street_address_1 ?? "") +
                    ")"}
              </span>
            </button>
          </div>
        </div>
        <UserProfileChangePopup
          show={selectProfilePopup}
          setShow={setSelectProfilePopup}
        />
      </div>
    </div>
  );
}
