import {
  DocumentIcon,
  EnvalopIcon,
  FlagIcon,
  FourBoxes,
  GraphIcon,
  PeopleIcon,
  ProfileIcon,
  ThreeDotsInCircleIcon,
} from "../../../../common/AppIcons";

export const sidebar_buttons = [
  {
    label: "Events",
    icon: FourBoxes,
    link: "/hoa/:hoaId/community/events/",
  },
  {
    label: "Messages",
    icon: EnvalopIcon,
    link: "/hoa/:hoaId/community/messages/",
  },
  {
    label: "Reservations",
    icon: FlagIcon,
    link: "/hoa/:hoaId/community/reservations/",
  },
  {
    label: "Amenities",
    icon: GraphIcon,
    link: "/hoa/:hoaId/community/amenities/",
  },
  {
    label: "Board Meetings",
    icon: PeopleIcon,
    link: "/hoa/:hoaId/community/board_meetings/",
    pages: [
      "/hoa/:hoaId/community/board_meetings/create/",
      "/hoa/:hoaId/community/board_meetings/:boardMeetingId",
      "/hoa/:hoaId/community/board_meetings/:boardMeetingId/conduct",
    ],
  },
];
