import { useEffect, useRef, useState } from "react";
import { formData } from "../data/formData";
import InputSection from "./InputSection";
import { useDebounce } from "use-debounce";
import { findState } from "../../../../../../utils/findState";
import { findTerritory } from "../../../../../../utils/findTerritory";
import { getMelissaAddress } from "../../../../../../api/ApiV2";

export default function CompanyForm({
  form,
  setClientData,
  warnings,
  setWarnings,
  delete_warning,
  readOnly,
  orgs,
  clientData,
}) {
  const [address_hints, set_address_hints] = useState([]);
  const [last_address_keyword, set_last_address_keyword] = useState("");
  const [streetAddress1_debounce] = useDebounce(form.streetAddress1, 700);
  const [address_hint_loading, set_address_hint_loading] = useState(false);
  const cityRef = useRef(null);

  useEffect(() => {
    if (form.zipCode) {
      const newState = findState(form.zipCode);
      const territory = findTerritory(form.zipCode);
      console.log("Setting State to:", newState);
      console.log("Setting Territory to:", territory);
      setTimeout(() => {
        setWarnings((warnings) => {
          return {
            ...warnings,
            zipCode:
              !newState || !territory ? "Invalid area zipcode" : undefined,
          };
        });
      }, 500);
      setClientData({ ...form, state: newState, territory: territory });
    }
  }, [form.zipCode]);

  // address hints

  useEffect(() => {
    if (
      form?.streetAddress1?.split(" ")?.length > 0 &&
      form?.streetAddress1?.charAt(form?.streetAddress1.length - 1) === " "
    ) {
      get_address_hints(form?.streetAddress1);
    }
  }, [form?.streetAddress1]);

  useEffect(() => {
    if (
      form?.streetAddress1 &&
      streetAddress1_debounce === form?.streetAddress1
    ) {
      get_address_hints(form?.streetAddress1);
    }
  }, [form?.streetAddress1, streetAddress1_debounce]);

  const get_address_hints = (address) => {
    if (!address) return;

    if (address?.trim() !== "" && address?.trim() !== last_address_keyword) {
      set_last_address_keyword(address.trim());
      set_address_hint_loading(true);
      getMelissaAddress({ address })
        .then((res) => {
          console.log("address hints:", res);
          set_address_hints(res);
          set_address_hint_loading(false);
        })
        .catch((res) => {
          console.log("address hints error:" + res);
          set_address_hint_loading(false);
        });
    }
  };

  return (
    <div className="py-3 grid grid-cols-1 sm:grid-cols-2 sm:gap-4 mt-4 w-full">
      {formData({
        companyDetail: form,
        setClientData,
        warnings: warnings ?? {},
        delete_warning: (val) => delete_warning(val),
        address_hints,
        set_address_hints,
        address_hint_loading,
        set_last_address_keyword,
        cityRef,
        orgs,
        clientData,
      })
        ?.filter((val) => !val.hidden)
        .map((ele, ele_index) => (
          <InputSection key={ele_index} {...ele} readOnly={readOnly} />
        ))}
    </div>
  );
}
