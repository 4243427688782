import React from "react";
import { CrossIcon2 } from "./AppIcons";

export default function PopupHeading({
  closePopup,
  className,
  textStyle,
  children,
}) {
  return (
    <div
      className={
        "w-full flex flex-col sm:flex-row-reverse justify-between items-center gap-3 " +
        className
      }
    >
      <div className="w-full sm:w-min flex justify-end">
        <button
          onClick={closePopup}
          className="p-1 rounded-full bg-red-200 text-red-600 hover:bg-red-300 hover:text-red-700"
        >
          <CrossIcon2 className="h-4 w-4" />
        </button>
      </div>
      <h1
        className="text-xl font-bold"
        style={textStyle ?? { color: "#2A32FD" }}
      >
        {children}
      </h1>
    </div>
  );
}
