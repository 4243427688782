import React from "react";
import { accountingInputClassName } from "../../../../../common/data/inputClassName";

import { amountFormat } from "../../../../../../common/utility/amountFormat";
import { amountFormat as posNegAmountFormat } from "../../../../../../common/AmountFormat";

const fieldClassName = accountingInputClassName + " w-full";

export const tableRows = ({
  value,
  index,
  set_create_account_popup,
  onChange,
  accounts,
  findItemTotal,
}) => [
  {
    label: value?.description ?? value?.name,
    className: "col-span-3",
    input: {
      type: "textarea",
      className: fieldClassName + " h-10",
      value: value?.name,
      onChange: (val1) => onChange(val1, "name", index),
    },
  },
  {
    label:
      accounts?.filter((account) => {
        return account.id === value?.account;
      })[0]?.name ?? value.account_object?.name,
    className: "col-span-3 justify-start",
    input: {
      type: "dropdown",
      className: fieldClassName + " py-0",
      boxClassName: "max-h-40",
      value: value?.account,
      onChange: (value1) => {
        if (value1 === "CREATE_NEW_ACCOUNT") {
          set_create_account_popup(true);
        } else onChange(value1, "account", index);
      },
      subHeadings: {
        AS: "Asset",
        LI: "Liability",
        IN: "Income",
        EX: "Expense",
        EQ: "Equity",
        TR: "Currency Trading",
      },
      options: [
        {
          word: "Create new account",
          label: (
            <div className="w-full font-medium py-1">
              <span className="truncate">Create new account</span>
            </div>
          ),
          value: "CREATE_NEW_ACCOUNT",
        },
        ...accounts?.map((account) => {
          return {
            word: account.name,
            label: (
              <div className="grid grid-cols-4 w-full text-xs font-normal py-1">
                <span className="col-span-3 break-all">{account.name}</span>
                <span className="text-right break-all">
                  {amountFormat(account.current_amount)}
                </span>
              </div>
            ),
            value: account.id,
            type: account.type,
          };
        }),
      ],
    },
  },
  {
    label: value?.quantity ?? value?.qty,
    input: {
      type: "text",
      className: fieldClassName + " text-right py-1",
      value: value?.qty,
      onChange: (value1) => {
        if (!Number(value1) && value1 !== "") return;
        onChange(Number(value1), "qty", index);
        onChange(Number(value1) * value?.rate, "total", index);
        setTimeout(() => findItemTotal(), 500);
      },
    },
  },
  {
    label: amountFormat(value?.unit_price ?? value?.rate),
    input: {
      type: "currency",
      className: fieldClassName + " text-right py-1",
      value: parseFloat(value?.rate),
      onChange: (val1) => {
        onChange(val1, "rate", index);
        onChange(value?.qty * val1, "total", index);
        setTimeout(() => findItemTotal(), 500);
      },
    },
  },
  {
    label: amountFormat(
      value?.total
        ? value?.total
        : Number(value.quantity) * Number(value.unit_price)
    ),
    input: {
      type: "currency",
      className: fieldClassName + " text-right py-1",
      value: parseFloat(value?.qty * value?.rate),
      onChange: (val1) => {},
      readOnly: true,
    },
  },
];
