let defaultTimeZone = "America/Los_Angeles"; // los angeles timezone

export const convertTimezone = (pre_date, toTimeZone = defaultTimeZone) => {
  let date = new Date(pre_date.split("Z")[0]);

  let timezoneDifferenceinSeconds =
    (new Date(
      new Date().toLocaleString("en-US", {
        timeZone: toTimeZone,
      })
    ) -
      new Date(new Date().toISOString().split("Z")[0])) /
    1000;

  return new Date(
    date.setSeconds(date.getSeconds() + timezoneDifferenceinSeconds)
  );
};
