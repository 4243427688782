import { useState } from "react";
import { Link } from "raviger";

import {
  BuildingIcon2,
  CrossIcon,
  FamilyIcon,
  FamilyIcon2,
  HoaIcon2,
} from "../../../common/AppIcons";
import Popup from "../../../common/Popup";

export default function PropertyType({
  previousPage,
  nextPage,
  hoaData,
  setHoaData,
}) {
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <div className="bg-white w-full flex flex-col items-center -mt-10 rounded-t pt-28 pb-32 gap-8">
      <div className="flex flex-col lg:flex-row gap-24">
        <div className="bg-indigo-50 pt-8 h-80">
          <BuildingIcon2 className="h-full" />
        </div>
        <div className="h-full flex flex-col justify-between gap-4">
          <span className="text-lg text-gray-700 font-semibold">
            What type of property do you have?
          </span>
          <div className="flex flex-row flex-wrap justify-center gap-4">
            {[
              {
                label: "HOA",
                value: "HOA",
                icon: HoaIcon2,
              },
              {
                label: "Single Family",
                value: "SINGLE_FAMILY",
                icon: FamilyIcon,
              },

              {
                label: "MultiFamily",
                value: "MULTI_FAMILY",
                icon: FamilyIcon2,
              },
            ].map((type, typeIndex) => (
              <div
                key={typeIndex}
                className="w-40 h-48 flex flex-col bg-gray-100 hover:bg-gray-200 p-5 cursor-pointer"
                onClick={() => {
                  setHoaData((hoaData) => {
                    return { ...hoaData, type: type.value };
                  });
                }}
              >
                <div className="flex justify-end">
                  <input
                    type="radio"
                    checked={hoaData.type === type.value}
                    className="h-4 w-4 bg-gray-100"
                  />
                </div>
                <div className="mt-5 flex-grow w-full flex flex-col sm:grid sm:grid-rows-2 gap-5 items-center justify-center">
                  <type.icon className="h-10 w-10 mx-auto" />

                  <span className="text-sm text-center">{type.label}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full mt-6 flex flex-row items-center gap-5">
            <button
              onClick={previousPage}
              className="bg-white text-sm text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 py-2 px-6 rounded"
            >
              Go Back
            </button>

            <button
              style={{
                backgroundColor: "#2A32FD",
                border: "2px solid #2A32FD",
              }}
              className="text-sm text-white hover:opacity-80 py-2 px-10 flex items-center justify-center rounded"
              onClick={() => {
                if (hoaData.type) {
                  if (hoaData.type === "HOA") setOpenPopup(true);
                  else nextPage();
                }
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      <Popup show={openPopup} setShow={setOpenPopup}>
        <OnlyBoardMemberPopup
          nextPage={nextPage}
          closePopup={() => setOpenPopup(false)}
        />
      </Popup>
    </div>
  );
}

const OnlyBoardMemberPopup = ({ nextPage, closePopup }) => (
  <div className="rounded-md bg-white mx-auto px-5 duration-200 max-w-xl overflow-y-auto sm:overflow-x-hidden">
    <div className="flex items-center justify-center mb-10 sm:mb-12 mt-4">
      <div className="w-full px-3">
        <div className={"w-full flex flex-row-reverse gap-3"}>
          <button
            onClick={closePopup}
            className="rounded-full text-red-600 hover:text-red-700"
          >
            <CrossIcon className="h-6 w-6" />
          </button>
        </div>

        <div className="-mt-3 flex flex-col items-center gap-4">
          <img src={"/buildingIcon.png"} className="h-36 w-36" />
          <span className="font-semibold text-center">
            Only Board Members are allowed to create HOAs. If you are a Board
            Member, click Proceed. Otherwise click Cancel.
          </span>
          <div className="mt-2 w-full flex flex-col items-center gap-2">
            <button
              style={{
                backgroundColor: "#2A32FD",
                border: "2px solid #2A32FD",
              }}
              className="text-sm text-white hover:opacity-80 py-2 px-16 flex items-center justify-center rounded"
              onClick={nextPage}
            >
              Proceed
            </button>
            <Link
              href="/home"
              className="bg-white text-sm text-gray-500 hover:text-gray-600 py-1"
            >
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);
