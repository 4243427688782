import React, { useEffect, useState } from "react";
import {
  ExclamationIcon,
  LoadingCircleIcon,
  PaperClipIcon,
} from "../../../common/AppIcons";
import { arch_review_plan_type } from "../../constants";

import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Link } from "raviger";
import { toast } from "react-toastify";
import { getDocuments } from "../../../../api/ApiV2";

const toolbar_options = {
  options: [
    "inline",
    "blockType",
    "list",
    "link",
    "emoji",
    "image",
    "remove",
    "history",
  ],
  inline: {
    options: ["bold", "italic", "strikethrough", "monospace"],
  },
};

export default function PlanInformation({
  hoaId,
  read_only,
  className,
  next_page,
  data,
  set_data,
  warning,
  remove_warning,
  submit_plan,
  fresh_data_flag,
  set_fresh_data_flag,
}) {
  const [editor_show, set_editor_show] = useState();
  const [editor_state, set_editor_state] = useState(
    EditorState.createWithContent(
      convertFromRaw(markdownToDraft(data?.description ?? ""))
    )
  );
  const [documents, setDocuments] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fresh_data_flag) {
      set_fresh_data_flag(false);
      set_editor_state(
        EditorState.createWithContent(
          convertFromRaw(markdownToDraft(data?.description ?? ""))
        )
      );
    }
  }, [fresh_data_flag]);

  useEffect(() => {
    getARDocuments();
  }, []);

  const getARDocuments = () => {
    setLoading(true);
    getDocuments(hoaId, "ARCHITECTURAL_REVIEW")
      .then((res) => {
        setLoading(false);
        console.log(
          res.results?.filter((val) => val.architecture_plan === data.id)
        );
        setDocuments(
          res.results?.filter((val) => val.architecture_plan === data.id)
        );
      })
      .catch((res) => {
        setLoading(false);

        toast.error(res.message ?? "Error getting documents");
      });
  };

  const fields = [
    {},
    {
      label: "Title of Review",
      value: "title",
    },
    {
      label: "Description",
      value: "description",
    },
    {
      label: "Status",
      value: "status",
      Submitted: data?.status !== "OPEN",
      reason:
        read_only ||
        (documents?.find((val) => val.item_type === "CHECKLIST") &&
          documents?.find((val) => val.item_type === "DRAWING"))
          ? ""
          : "Additional documents are needed before the plan can be submitted",
    },
    {
      label: "Type",
      value: "plan_type",
      array: arch_review_plan_type,
    },
    {
      label: "Address",
      value: "address",
    },
  ];

  return (
    <div className={"flex flex-col " + className}>
      <div className="mt-10 flex flex-col gap-1 min-w-full">
        <div className="flex flex-row justify-between min-w-full">
          <span className="font-semibold text-sm text-gray-700">
            Name of Applicant
          </span>
          <span className="text-xs text-red-600">
            {warning?.contractor_name}
          </span>
        </div>
        <input
          readOnly
          value={data?.contractor_name ?? ""}
          onChange={(e) => {
            let val = e.target.value;
            remove_warning("contractor_name");

            set_data((data) => {
              return {
                ...data,
                contractor_name: val,
              };
            });
          }}
          className="min-w-full text-gray-800 rounded bg-gray-200 px-3 py-2 focus:outline-none focus:bg-gray-300"
        />
      </div>

      <div className="mt-4 flex flex-col gap-1 min-w-full">
        <div className="flex flex-row justify-between min-w-full">
          <span className="font-semibold text-sm text-gray-700">
            {fields[1].label}
          </span>
          <span className="text-xs text-red-600">
            {warning[fields[1].value]}
          </span>
        </div>
        <input
          readOnly={read_only}
          value={data[fields[1].value]}
          onChange={(e) => {
            let val = e.target.value;
            remove_warning(fields[1].value);
            set_data((data) => {
              return { ...data, [fields[1].value]: val };
            });
          }}
          className="min-w-full text-gray-800 rounded bg-gray-200 px-3 py-2 focus:outline-none focus:bg-gray-300"
        />
      </div>

      <div className="mt-4 flex flex-col gap-1 min-w-full">
        <div className="flex flex-row justify-between min-w-full">
          <span className="font-semibold text-sm text-gray-700">
            {fields[2].label}
          </span>
          <span className="text-xs text-red-600">
            {warning[fields[2].value]}
          </span>
        </div>
        <Editor
          readOnly={read_only}
          editorState={editor_state}
          onFocus={() => {
            set_editor_show(fields[2].value);
          }}
          onBlur={() => {
            set_editor_show();
          }}
          toolbar={toolbar_options}
          wrapperClassName=""
          toolbarClassName={
            "shadow-md absolute transform -translate-y-12 " +
            (editor_show === fields[2].value ? "opacity-100" : "opacity-0")
          }
          editorClassName={
            "appearance-none rounded-lg w-full px-3 py-2 text-gray-700 leading-tight " +
            (editor_show === fields[2].value ? "bg-gray-300" : "bg-gray-200")
          }
          onEditorStateChange={(editorState) => {
            remove_warning(fields[2].value);
            set_editor_state(editorState);
            const newValue = draftToMarkdown(
              convertToRaw(editorState.getCurrentContent())
            );

            set_data((data) => {
              return { ...data, [fields[2].value]: newValue };
            });
          }}
        />
      </div>

      <div className="mt-4 flex flex-col gap-1 min-w-full">
        <div className="flex flex-row justify-between min-w-full">
          <span className="font-semibold text-sm text-gray-700">
            {fields[3].label}
          </span>
          <span className="text-xs text-red-600">
            {warning[fields[3].value]}
          </span>
        </div>
        <span className="min-w-full rounded bg-gray-200 text-sm px-3 py-2">
          {data[fields[3].value] === "CLOSED" ? (
            <span className="text-red-600">CLOSED</span>
          ) : fields[3].Submitted ? (
            <span className="text-green-600">Submitted</span>
          ) : (
            <button
              readOnly={read_only}
              onClick={submit_plan}
              className="text-blue-600 hover:text-blue-500 focus:outline-none underline cursor-pointer"
            >
              Submit plan to the committee
            </button>
          )}
        </span>
        {!fields[3].Submitted && (
          <div className="mt-1 flex flex-row items-center gap-2 text-red-600 text-xs">
            <ExclamationIcon className="h-3" />
            This plan has not yet been submitted to the architectural committee
          </div>
        )}
        {fields[3].reason && fields[3].reason !== "" && (
          <div className="mt-1 flex flex-row items-center gap-2 text-red-600 text-xs">
            <ExclamationIcon className="h-3" />
            {fields[3].reason}
          </div>
        )}
      </div>

      <div className="mt-4 flex flex-col gap-1 min-w-full">
        <div className="flex flex-row justify-between min-w-full">
          <span className="font-semibold text-sm text-gray-700">
            {" "}
            {fields[4].label}
          </span>
          <span className="text-xs text-red-600">
            {warning[fields[4].value]}
          </span>
        </div>
        <select
          disabled={read_only}
          value={data[fields[4].value]}
          onChange={(e) => {
            let val = e.target.value;
            remove_warning(fields[4].value);
            set_data((data) => {
              return { ...data, [fields[4].value]: val };
            });
          }}
          className="min-w-full text-gray-800 rounded bg-gray-200 px-3 py-2 border-none focus:outline-none focus:bg-gray-300"
        >
          <option selected hidden disabled value="">
            Select Type
          </option>
          {fields[4].array
            .filter((val) => val.value !== "")
            .map((type, type_index) => (
              <option className="bg-white" key={type_index} value={type.value}>
                {type.label}
              </option>
            ))}
        </select>
      </div>

      <div className="mt-4 flex flex-col gap-1 min-w-full">
        <div className="flex flex-row justify-between min-w-full">
          <span className="font-semibold text-sm text-gray-700">
            {fields[5].label}
          </span>
          <span className="text-xs text-red-600">
            {warning[fields[5].value]}
          </span>
        </div>
        <input
          readOnly={read_only}
          value={data[fields[5].value]}
          onChange={(e) => {
            let val = e.target.value;
            remove_warning(fields[5].value);
            set_data((data) => {
              return { ...data, [fields[5].value]: val };
            });
          }}
          className="min-w-full text-gray-800 rounded bg-gray-200 px-3 py-2 focus:outline-none focus:bg-gray-300"
        />
      </div>

      <div className="mt-4">
        <div className="flex flex-row justify-between min-w-full">
          <span className="font-semibold text-sm text-gray-700">Documents</span>
          <span className="text-xs text-red-600">{warning["documents"]}</span>
        </div>
        <div className="my-2 flex flex-col items-start ml-1 gap-2">
          {loading && <LoadingCircleIcon className="h-5 mx-auto" />}
          {documents?.length > 0 ? (
            documents.map((ele, ele_index) => (
              <div className="w-full bg-gray-100 border rounded px-5 py-2 text-sm">
                <Link
                  target="_blank"
                  href={"/hoa/" + hoaId + "/view_document/" + ele.id}
                  key={ele_index}
                  className="text-gray-700 truncate w-40 sm:w-60 md:w-96 hover:text-blue-600 hover:underline"
                >
                  {ele.name || ""}
                </Link>
              </div>
            ))
          ) : (
            <div className="mt-1 flex flex-row items-center gap-2 text-red-600 text-xs">
              <ExclamationIcon className="h-3" />
              There are no documents attched to this plan
            </div>
          )}
        </div>

        {!read_only && (
          <button
            className={
              "mt-2 relative flex flex-row items-center gap-3 px-4 py-2 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 focus:outline-none hover:border-indigo-400 hover:text-indigo-400"
            }
            onClick={next_page}
          >
            <PaperClipIcon className="h-4" />
            Add Document
          </button>
        )}
      </div>
    </div>
  );
}
