import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getAllTransactions, getBill } from "../../../../api/ApiV2";
import { LoadingCircleIcon } from "../../../common/AppIcons";
import Bill from ".";

export default function BillDetailPopup({ hoaId, onSuccess, billId, source }) {
  const [bill, setBill] = useState({
    reference_number: "",
    name: "Bill",
    legs: [
      {
        description: "",
        expense_account: undefined,
        amount: "",
      },
    ],
    payments: [],
    is_paid: false,
    vendor: undefined,
    date: undefined,
    terms: undefined,
    due_date: undefined,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (billId) {
      setLoading(true);
      let apiLoading = {
        detail: true,
        payments: true,
      };
      const billAPILoading = () => {
        if (apiLoading.detail === false && apiLoading.payments === false) {
          setTimeout(() => {
            setLoading(false);
          }, 10);
        }
      };
      getBill({
        hoaId,
        billId,
      })
        .then((res) => {
          console.log("res", res);
          setBill((bill) => ({ ...bill, ...res, legs: res.items }));
          apiLoading.detail = false;
          billAPILoading();
        })
        .catch((res) => {
          toast.error("Error fetching bill details");
          apiLoading.detail = false;
          billAPILoading();
          console.log(res);
        });

      getAllTransactions({ hoaId, billId })
        .then((res) => {
          console.log("res", res);
          setBill((bill) => ({
            ...bill,
            payments: res.results?.filter((val) => val.type === "PAYMENT"),
          }));
          apiLoading.payments = false;
          billAPILoading();
        })
        .catch((res) => {
          apiLoading.payments = false;
          billAPILoading();
          console.log(res);
        });
    }
  }, [billId]);

  if (loading)
    return (
      <div className="w-full flex items-center justify-center py-16">
        <LoadingCircleIcon className="h-6" />
      </div>
    );
  else
    return (
      <Bill
        hoaId={hoaId}
        onSuccess={onSuccess}
        readOnly={billId ? true : false}
        bill={
          source === "HOA_CREATION"
            ? {
                reference_number: "",
                name: "Bill",
                legs: [],
                payments: [],
              }
            : bill
        }
        source={source}
      />
    );
}
