import { Link } from "raviger";
import { useRecoilState } from "recoil";

import {
  ArrowIcon,
  AccountingIcon,
  ComplianceIcon,
  CommunityIcon,
  PaymentsIcon,
} from "../../../common/AppIcons";
import { userAtom } from "../../../../store/UserAtom";
import { adminTypes } from "../../../../common/data/adminTypes";

const sections = [
  {
    href: "/accounting/home",
    label: "Accounting",
    module: "ACCOUNTING",
    icon: AccountingIcon,
    iconClassName: "text-yellow-700",
    description:
      "Manage all accounting for your Property, Simple and straightforward.",
  },
  {
    href: "/sr/ORDER_HISTORY",
    label: "Maintenance",
    icon: PaymentsIcon,
    iconClassName: "text-teal-700",
    description:
      " Manage and Track Maintenance Service Requests, all in one place.",
  },
  {
    href: "/compliance_documents",
    label: "Compliance",
    module: "COMPLIANCE",
    icon: ComplianceIcon,
    iconClassName: "text-purple-700",
    description: "Keep track of all compliances in one place.",
  },
  {
    href: "/community/events",
    label: "Community",
    icon: CommunityIcon,
    iconClassName: "text-blue-700",
    description:
      "Check up on what's new. Explore other tenants at your Apartment.",
  },
  {
    href: "/invoices/user/unsettled",
    label: "Payments",
    module: "ACCOUNTING",
    icon: PaymentsIcon,
    iconClassName: "text-teal-700",
    description: "Manage and Pay for your Dues.",
  },
];
export default function Sections({ hoaId, hoaEnabledModules }) {
  const [user] = useRecoilState(userAtom);
  const admin = adminTypes.includes(user.selectedUserType);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-px rounded-lg overflow-hidden">
      {sections
        .filter(
          (val) =>
            !val.module || hoaEnabledModules?.find((ele) => ele === val.module)
        )
        .filter((val) => val.label !== "Accounting" || admin)
        .filter((val) => val.label !== "Payments" || !admin)
        .map((section, section_index) => (
          <Link
            key={section_index}
            href={"/hoa/" + hoaId + section.href}
            class="relative group bg-white hover:bg-gray-50 p-7 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500"
          >
            <section.icon className={"ml-2 h-6 " + section.iconClassName} />
            <div class="mt-10">
              <h3 class="text-lg font-medium">
                <span class="absolute inset-0"></span>
                {section.label}
              </h3>
              <p class="mt-2 text-sm text-gray-500">{section.description}</p>
            </div>
            <span class="absolute top-6 right-6 text-gray-300 group-hover:text-gray-400">
              <ArrowIcon className="h-6 w-6" />
            </span>
          </Link>
        ))}
    </div>
  );
}
