import React from "react";
import Dropdown from "./Dropdown";

export default function UserDueDate({
  className,
  value,
  onChange,
  readOnly,
  selectClassName,
}) {
  return (
    <div
      className={
        "flex flex-row gap-px items-center whitespace-pre " + className
      }
    >
      <Dropdown
        selectClassName={
          "p-1 rounded-lg focus:outline-none  " + selectClassName
        }
        readOnly={readOnly}
        placeholder="Select a Day"
        options={[...Array(31)].map((number, index) => {
          return {
            word: "" + (index + 1),
            label: index + 1,
            value: new Date(2016, 12, index + 1, 0, 0, 0, 0).toISOString(),
          };
        })}
        value={value}
        onChange={(selected) => onChange(selected)}
      />
      <span className="text-gray-700 text-xs relative bottom-4">th</span>
      <span className="text-gray-700 text-sm"> day of every month</span>
    </div>
  );
}
