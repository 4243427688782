import React from "react";
import { ThumbsUpIcon } from "../../common/AppIcons";

export default function Success({ text }) {
  return (
    <div className="flex flex-col gap-5 py-8 items-center">
      <div className="bg-gray-100 rounded-full w-28 h-28 flex items-center justify-center">
        <ThumbsUpIcon className="text-indigo-600 h-20 w-20" />
      </div>
      <div className="flex flex-col text-center gap-2">
        <span className="text-2xl font-semibold text-gray-700">
          Payment Setup Success
        </span>
        <span className="mt-2 text-sm text-gray-600">
          {text ??
            "New Payment Method added successfully. Visit the Payment Methods screen by clicking the button below."}
        </span>
      </div>
    </div>
  );
}
