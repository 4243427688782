import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Link } from "raviger";
import { useRecoilState } from "recoil";

import Modal from "../common/Modal";
import { complianceDocumentsType } from "../../common/constants";
import {
  getDocuments,
  postDocument,
  uploadCreateRevision,
} from "../../api/ApiV2";
import {
  LeftArrowIcon,
  LoadingAnimated,
  LoadingCircleIcon2,
  PlusInCircleIcon,
  UploadIcon,
  UploadIcon2,
} from "../common/AppIcons";
import ArchetecturalReview from "./archetecturalReview/NewArchetecturalReview";
import { userAtom } from "../../store/UserAtom";
import { adminTypes } from "../../common/data/adminTypes";

export default function DocumentList({ documentType, hoaId }) {
  const [user] = useRecoilState(userAtom);
  const admin = adminTypes.includes(user.selectedUserType);
  const fileInput = useRef(null);
  const [documents, setDocuments] = useState([]);
  const [createDocument, setCreateDocument] = useState(null);
  const [uploadDocument, setUploadDocument] = useState({
    view: false,
    file: null,
    documentId: null,
  });
  const [loading, set_loading] = useState(false);

  const createDocumentMeta =
    documentType === "ARCHITECTURAL_REVIEW"
      ? {
          title: "Request Architectural Review",
          placeholder: "Room Addition",
          buttonText: "New Review",
        }
      : {
          title: "Create Document",
          placeholder: "Property Governance Document",
          buttonText: "Create Document",
        };
  useEffect(() => {
    console.log({ documentType });
    get_documents();
  }, [hoaId, documentType]);

  const get_documents = () => {
    if (documentType !== "ARCHITECTURAL_REVIEW") {
      set_loading(true);
      getDocuments(hoaId, documentType)
        .then((res) => {
          set_loading(false);
          setDocuments(res.results);
        })
        .catch((res) => {
          toast.error(res?.message ?? "Error uploading file");
          console.log(res);
          set_loading(false);
        });
    }
  };

  const upload_document = () => {
    set_loading(true);

    uploadCreateRevision({
      hoaId: hoaId,
      documentId: uploadDocument.documentId,
      data: { document: uploadDocument.file },
    })
      .then((res) => {
        console.log(res);
        set_loading(false);
        toast.success("uploaded file successfully");
        setUploadDocument();
        get_documents();
      })
      .catch((res) => {
        toast.error(res?.message ?? "Error uploading file");
        console.log(res);
        set_loading(false);
        setUploadDocument();
      });
  };

  return (
    <div className="bg-white px-4 py-7 border-b border-gray-200 shadow sm:rounded-md sm:px-6">
      <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
        {documentType !== "ARCHITECTURAL_REVIEW" && (
          <div className="ml-4 mt-4">
            <h3 className="flex flex-row items-center gap-2 text-lg leading-6 font-medium text-gray-900">
              <Link
                className="flex items-center relative top-px"
                href={"/hoa/" + hoaId + "/compliance_documents/"}
              >
                <LeftArrowIcon className="h-5 w-5 text-gray-700 hover:text-gray-500 cursor-pointer" />
              </Link>
              <span className="font-semibold text-xl">
                {complianceDocumentsType.find(
                  (doc) => doc.value === documentType
                )?.label || "Documents"}
              </span>
            </h3>
            <p className="mt-1 text-sm text-gray-500"></p>
          </div>
        )}
        <div className="ml-4 mt-4 flex-shrink-0">
          {admin && documentType !== "ARCHITECTURAL_REVIEW" && (
            <button
              type="button"
              className="flex flex-row gap-1 items-center px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 focus:outline-none"
              onClick={(_) => {
                setCreateDocument({ document_type: documentType, name: "" });
              }}
            >
              <PlusInCircleIcon style={{ height: "16px" }} />
              {createDocumentMeta.buttonText}
            </button>
          )}
        </div>
      </div>
      <div className="px-3 mt-4 bg-white overflow-hidden">
        {loading ? (
          <div className="py-20 w-full flex items-center justify-center">
            <LoadingCircleIcon2 className="h-10 text-blue-500" />
          </div>
        ) : documentType === "ARCHITECTURAL_REVIEW" ? (
          <ArchetecturalReview hoaId={hoaId} documentType={documentType} />
        ) : (
          <ul className="space-y-2">
            {documents.map((document) => (
              <li key={document.id}>
                <div className="border border-gray-300 rounded-md pl-7 pr-3 py-2.5 flex flex-row items-center justify-between">
                  <span className="text-gray-500 text-sm">
                    {document?.name}
                  </span>
                  <div className="ml-2 flex-shrink-0 flex items-center gap-x-3 gap-y-1">
                    {document.document_key && (
                      <Link
                        href={`/hoa/${hoaId}/view_document/${document.id}`}
                        className="px-4 py-1.5 inline-flex text-xs leading-5 rounded bg-green-100 text-green-700 hover:opacity-70"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        View
                      </Link>
                    )}
                    {
                      <span
                        onClick={(_) => {
                          if (admin)
                            setUploadDocument({
                              view: true,
                              documentId: document.id,
                            });
                        }}
                        style={
                          document.document_key
                            ? {
                                backgroundColor: "rgba(221, 160, 7, 0.18)",
                                color: "#DDA007",
                              }
                            : {}
                        }
                        className={
                          "px-4 py-1.5 flex flex-row items-center gap-2 text-sm leading-5 rounded " +
                          (admin ? "hover:opacity-70 cursor-pointer" : "") +
                          " " +
                          (document.document_key
                            ? ""
                            : "bg-indigo-100 text-indigo-600 border-dashed border-2 border-indigo-600")
                        }
                      >
                        {admin && !document.document_key && (
                          <UploadIcon2 className="h-3" />
                        )}
                        {admin
                          ? document.document_key
                            ? "Upload Revision"
                            : "Upload"
                          : " Unavailable"}
                      </span>
                    }
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      {createDocument && (
        <Modal
          show={createDocument !== null}
          setShow={(_) => setCreateDocument(null)}
        >
          <form
            className="p-4"
            onSubmit={(e) => {
              e.preventDefault();

              set_loading(true);
              postDocument(hoaId, createDocument)
                .then((res) => {
                  console.log(createDocument, res);
                  toast.success("Document Created Successfully");
                  setCreateDocument(null);
                  getDocuments(hoaId, documentType)
                    .then((res) => setDocuments(res.results))
                    .catch(console.log);
                  set_loading(false);
                })
                .catch((res) => {
                  toast.error(res?.message ?? "Error creating document");
                  set_loading(false);
                });
            }}
          >
            <label
              htmlFor="email"
              className="block text-lg mb-3 font-medium text-gray-700"
            >
              {createDocumentMeta.title}
            </label>
            <div className="mt-1">
              <input
                type="text"
                value={createDocument.name}
                onChange={(e) =>
                  setCreateDocument({
                    ...createDocument,
                    name: e.target.value,
                  })
                }
                className="px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder={createDocumentMeta.placeholder}
              />
            </div>
            <span className="block text-sm text-red-500">
              {createDocument.errors}
            </span>
            <button
              type="submit"
              className="relative inline-flex items-center my-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {loading ? (
                <LoadingAnimated className="mx-4 h-4" />
              ) : (
                "Create Document"
              )}
            </button>
          </form>
        </Modal>
      )}

      <Modal
        show={uploadDocument?.view}
        setShow={(_) => setUploadDocument(null)}
      >
        <div className="p-4 flex flex-col items-start">
          <label className="block text-lg mb-3 font-medium text-gray-700">
            Select File to Upload
          </label>
          <div className="mt-1">
            <div className="flex flex-row items-center ml-1 gap-4">
              <button
                onClick={() => {
                  fileInput?.current?.click();
                }}
                className="px-2 py-1 bg-green-600 hover:bg-green-700 rounded-lg text-white flex flex-row gap-2"
              >
                <UploadIcon className="h-4 mr-1" />
                Select File
              </button>
              <span className="text-gray-700">
                {uploadDocument?.file?.name}
              </span>
            </div>
            <input
              name="photo"
              ref={fileInput}
              className="hidden"
              type="file"
              onChange={(event) => {
                //   URL.createObjectURL(event.target.files[0])
                if (event.target.files && event.target.files[0]) {
                  setUploadDocument({
                    ...uploadDocument,
                    file: event.target.files[0],
                  });
                }
              }}
            />
          </div>
          <button
            onClick={upload_document}
            className="relative mt-4 flex items-center justify-center h-8 w-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? <LoadingAnimated className="mx-4 h-4" /> : "Upload"}
          </button>
        </div>
      </Modal>
    </div>
  );
}
