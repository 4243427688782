import { Link, navigate } from "raviger";
import { useState, useEffect, useRef } from "react";
import { useDebounce } from "use-debounce";
import { useRecoilState } from "recoil";

import {
  getHoaProperties,
  getMelissaAddress,
  updateProperty,
} from "../../../api/ApiV2";
import { PlusIcon } from "../../common/AppIcons";
import Loading from "../../common/Loading";
import AddpropertyPopup from "./AddPropertyPopup";
import { userAtom } from "../../../store/UserAtom";
import { findState } from "../../../utils/findState";
import { findTerritory } from "../../../utils/findTerritory";
import Input from "../../common/Form/Input";
import { adminTypes } from "../../../common/data/adminTypes";

export default function PropertyList({ hoaId }) {
  const [properties, set_properties] = useState();
  const [property_focus, set_property_focus] = useState();
  const [add_property_popup, set_add_property_popup] = useState(false);
  const [user] = useRecoilState(userAtom);

  const [member_focus, set_member_focus] = useState();
  const [loading, set_loading] = useState(true);
  const [warning, set_warning] = useState({});

  const [address_hints, set_address_hints] = useState([]);
  const [last_address_keyword, set_last_address_keyword] = useState("");
  const [street_address_1_debounce] = useDebounce(
    property_focus?.street_address_1,
    700
  );
  const [address_hint_loading, set_address_hint_loading] = useState(false);
  const cityRef = useRef(null);

  const adminPermissions = adminTypes.includes(user.selectedUserType);

  useEffect(() => {
    if (property_focus?.zipCode) {
      const newState = findState(property_focus?.zipCode);
      const territory = findTerritory(property_focus?.zipCode);
      console.log("Setting State to:", newState);
      console.log("Setting Territory to:", territory);
      setTimeout(() => {
        set_warning((warnings) => {
          return {
            ...warnings,
            street_address_1:
              !newState || !territory ? "Invalid area zipcode" : undefined,
          };
        });
      }, 500);
      set_property_focus({
        ...property_focus,
        state: newState,
        territory: territory,
      });
    }
  }, [property_focus?.zipCode]);

  // address hints

  useEffect(() => {
    if (
      property_focus?.street_address_1?.split(" ")?.length > 0 &&
      property_focus?.street_address_1?.charAt(
        property_focus?.street_address_1.length - 1
      ) === " "
    ) {
      get_address_hints(property_focus?.street_address_1);
    }
  }, [property_focus?.street_address_1]);

  useEffect(() => {
    if (
      property_focus?.street_address_1 &&
      street_address_1_debounce === property_focus?.street_address_1
    ) {
      get_address_hints(property_focus?.street_address_1);
    }
  }, [property_focus?.street_address_1, street_address_1_debounce]);

  const get_address_hints = (address) => {
    if (!address) return;

    if (address?.trim() !== "" && address?.trim() !== last_address_keyword) {
      set_last_address_keyword(address.trim());
      set_address_hint_loading(true);
      getMelissaAddress({ address })
        .then((res) => {
          console.log("address hints:", res);
          set_address_hints(
            res?.filter(
              (val) =>
                val?.Address?.SubBuilding && val?.Address?.SubBuilding !== ""
            )
          );
          set_address_hint_loading(false);
        })
        .catch((res) => {
          console.log("address hints error:" + res);
          set_address_hint_loading(false);
        });
    }
  };

  const addressDisplay = (address) => {
    let real_address = address.Address;

    if (!real_address) return null;
    return (
      (real_address.Address1 ? real_address.Address1 + ", " : "") +
      (real_address.Locality || real_address.SubAdministrativeArea
        ? (real_address.Locality || real_address.SubAdministrativeArea) + ",  "
        : "") +
      (real_address.AdministrativeArea ?? "") +
      (real_address.PostalCodePrimary
        ? " " + real_address.PostalCodePrimary
        : "")
    );
  };

  useEffect(() => {
    if (properties && !property_focus) {
      set_property_focus(properties[0]);
    }
  }, [properties]);

  useEffect(() => {
    if (add_property_popup === false) {
      get_properties();
    }
  }, [add_property_popup]);

  const onSave = () => {
    set_loading(true);
    updateProperty(hoaId, property_focus, property_focus?.id)
      .then((res) => {
        set_loading(false);
        get_properties();
      })
      .catch((res) => {
        if (typeof res.message === "string") {
          let temp_error = {};
          res?.message?.split("],").map((val) => {
            if (val.split("'")[1] && val.split("'")[3])
              temp_error = {
                ...temp_error,
                [val.split("'")[1]]: ["" + val.split("'")[3]],
              };
          });
          set_warning(temp_error);
        } else if (res) set_warning(res);
        else set_warning({ overall: ["User uploading failed."] });
        console.log(res);
        set_loading(false);
      });
  };

  const get_properties = (searchQuery) => {
    getHoaProperties(hoaId, 0, searchQuery).then((res) => {
      set_properties(res);
      set_loading(false);
    });
  };

  const search_properties = (keyword) => {
    get_properties(keyword);
  };

  const remove_warning = (key) => {
    let tempWarning = warning;
    delete tempWarning[key];
    set_warning(tempWarning);
  };

  return (
    <div className="pb-8 max-w-3xl mx-auto lg:max-w-7xl rounded-xl shadow-lg bg-white">
      <div
        className={
          "fixed bg-gray-100 bg-opacity-40 flex items-center justify-center z-50 inset-0 " +
          (loading ? "" : "hidden")
        }
      >
        <Loading className="h-10 w-10 text-gray-500" />
      </div>
      <div className="mx-auto relative z-0 flex overflow-hidden rounded-md">
        <main
          className={
            "flex-1 relative z-0 overflow-y-auto focus:outline-none md:order-last " +
            (property_focus ? "" : "hidden xl:flex-1")
          }
          // tabindex="0"
          // x-data=""
          // x-init="$el.focus()"
        >
          {/* <!-- Breadcrumb --> */}
          <nav
            className="flex items-start px-4 py-3 sm:px-6 lg:px-8 md:hidden"
            aria-label="Breadcrumb"
          >
            <div className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
              <div
                onClick={() => set_member_focus()}
                className="text-gray-400 hover:text-gray-600 cursor-pointer"
              >
                <svg
                  className="-ml-2 h-5 w-5"
                  //   x-description="Heroicon name: chevron-left"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <span>Buildings</span>
            </div>
          </nav>

          {property_focus ? (
            <article>
              {/* <!-- Profile header --> */}

              {/* <!-- Tabs --> */}
              <div className="mt-6 sm:mt-2 2xl:mt-5">
                <div className="border-b border-gray-200">
                  <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      <Link
                        href="#"
                        className="border-pink-500 text-gray-900 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        aria-current="page"
                      >
                        Building Details
                      </Link>
                    </nav>
                  </div>
                </div>
              </div>

              {/* <!-- Description list --> */}
              <div className="mt-6 max-w-5xl mx-auto px-4 py-2 sm:px-6 lg:px-8">
                <span className="text-red-400 mb-8 text-xs">
                  {warning["overall"] && warning["overall"][0]}
                </span>
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 pb-8">
                  <div key="street_address_1" className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500 w-full flex flex-row justify-between">
                      Street Address 1
                      <span className="text-red-400 relative top-2 text-xs"></span>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <Input
                        value={property_focus?.street_address_1}
                        readOnly={!adminPermissions}
                        onChange={(val) => {
                          remove_warning("street_address_1");

                          set_property_focus({
                            ...property_focus,
                            street_address_1: val,
                          });
                        }}
                        hint={
                          adminPermissions && {
                            loading: address_hint_loading,
                            hints: address_hints?.map((address) => {
                              return {
                                value: address.Address,
                                label: addressDisplay(address),
                              };
                            }),
                            onClick: (val) => {
                              remove_warning("street_address_1");
                              remove_warning("city");
                              remove_warning("state");
                              remove_warning("zipCode");

                              let real_address = val;
                              set_property_focus((property_focus) => ({
                                ...property_focus,

                                street_address_1: real_address?.Address1,
                                city:
                                  real_address.Locality ||
                                  real_address?.SubAdministrativeArea,
                                state: real_address?.AdministrativeArea,
                                zipCode: real_address?.PostalCodePrimary,
                                geocodeLatitude: real_address?.Latitude,
                                geocodeLongitude: real_address?.Longitude,
                                melissaBaseMak:
                                  real_address?.BaseMAK ?? real_address?.MAK,
                                units: real_address?.BaseMAK
                                  ? {
                                      units: real_address?.SubBuilding.split(
                                        ","
                                      ).filter((val) => val !== ""),
                                      maks: real_address?.MAK.split(",").filter(
                                        (val) => val !== ""
                                      ),
                                    }
                                  : null,
                              }));

                              set_address_hints();
                              set_last_address_keyword();

                              setTimeout(() => {
                                cityRef?.current?.focus();
                              }, 300);
                            },
                          }
                        }
                        className="border-none px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-indigo-100 focus:indigo-200 w-full rounded"
                      />
                    </dd>
                  </div>
                  <div key="street_address_2" className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500 w-full flex flex-row justify-between">
                      Street Address 2
                      <span className="text-red-400 relative top-2 text-xs"></span>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <input
                        className="border-none px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-indigo-100 focus:indigo-200 w-full rounded"
                        readOnly
                        value={property_focus.street_address_2}
                        onChange={(e) => {
                          set_property_focus({
                            ...property_focus,
                            street_address_2: e.target.value,
                          });
                        }}
                      />
                    </dd>
                  </div>
                </dl>
                <div className="w-full flex justify-between mb-4">
                  <button
                    onClick={() => {
                      navigate(
                        `/hoa/${hoaId}/units/?property=${property_focus.id}`
                      );
                    }}
                    className="text-white px-2 py-1 font-semibold bg-yellow-500 hover:bg-yellow-600 rounded shadow"
                  >
                    View Units
                  </button>
                  {adminPermissions && (
                    <button
                      onClick={onSave}
                      className="text-white px-2 py-1 font-semibold bg-blue-500 hover:bg-blue-600 rounded shadow"
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </article>
          ) : (
            <div className="h-full w-full" />
          )}
        </main>
        <aside
          className={
            "md:order-first flex-shrink-0 w-96 md::border-r md::border-gray-200 " +
            (member_focus ? "hidden md:flex md:flex-col" : "flex flex-col")
          }
        >
          <div className="px-6 pt-6 pb-4">
            <div className="flex flex-row gap-2 justify-between w-full">
              <div className="flex flex-col">
                <h2 className="text-lg font-medium text-gray-900">Buildings</h2>
                <p className="mt-1 text-sm text-gray-600">
                  Search directory of {properties?.length} buildings
                </p>
              </div>
              <div>
                {adminPermissions && (
                  <button
                    className="p-1 rounded bg-blue-600 hover:bg-blue-700"
                    style={{ boxShadow: "1px 1px 3px" }}
                    onClick={() => set_add_property_popup(true)}
                  >
                    <PlusIcon className="h-4 w-4 text-white" />
                  </button>
                )}
              </div>
            </div>
            <form className="mt-6 flex space-x-4" action="#">
              <div className="flex-1 min-w-0">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    {/* <!-- Heroicon name: mail --> */}
                    <svg
                      className="h-5 w-5 text-gray-400"
                      // x-description="Heroicon name: search"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    onChange={(e) => {
                      search_properties(e.target.value);
                    }}
                    className="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 px-3 py-2 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Search"
                  />
                </div>
              </div>
            </form>
          </div>
          {/* <!-- list --> */}
          <div className="px-6 pt-6 pb-4">
            <nav
              className="flex-1 min-h-0 md:max-h-screen relative overflow-y-auto"
              aria-label="Buildings"
            >
              <ul className="relative z-0 divide-y divide-gray-200">
                {properties?.map((property, property_index) => (
                  <li
                    key={property_index}
                    onClick={() => {
                      set_property_focus(property);
                    }}
                  >
                    <div
                      className={
                        "relative px-6 py-5 flex items-center space-x-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 " +
                        (property_focus?.id && property_focus.id === property.id
                          ? "bg-gray-300"
                          : "hover:bg-gray-200 bg-gray-50  border-1")
                      }
                    >
                      <div className="flex-1 min-w-0">
                        <div className="focus:outline-none">
                          {/* <!-- Extend touch target to entire panel --> */}
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          ></span>
                          <p className="text-sm font-medium text-gray-900">
                            {property?.street_address_1}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </aside>
      </div>
      <AddpropertyPopup
        hoaId={hoaId}
        show={add_property_popup}
        setShow={() => {
          set_add_property_popup(false);
        }}
      />
    </div>
  );
}
