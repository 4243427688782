import { useEffect, useState } from "react";

import { amountFormat } from "../../../../common/AmountFormat";
import { LeftArrowIcon } from "../../../../common/AppIcons";
import Dropdown from "../../../../common/Form/Dropdown";
import Input from "../../../../common/Form/Input";
import Loading from "../../../../common/Loading";
import PopupHeading from "../../../../common/PopupHeading";
import { accountingInputClassName } from "../../../common/data/inputClassName";
import NewAccountPopup from "../../NewAccountPopup";

export default function BillDetail({
  hoaId,
  onSubmit,
  bills,
  data,
  setData,
  accounts,
  expenseAccounts,
  selectAccount,
  setSelectAccount,
  get_asset_accounts,
  payLoading,
  parentRef,
}) {
  const [new_account_popup, set_new_account_popup] = useState(false);

  useEffect(() => {
    if (!new_account_popup) get_asset_accounts();
  }, [new_account_popup]);

  return (
    <div className={"w-full flex flex-col"}>
      <div className={new_account_popup ? "invisible" : ""}>
        <PopupHeading
          closePopup={() => {
            setData({ ...data, popup: "BILL_LIST", billNo: 0 });
          }}
        >
          Bill Transactions
        </PopupHeading>

        <div className="px-10 pt-4 w-full">
          <div
            onClick={() => {
              setData({ ...data, popup: "BILL_LIST", billNo: 0 });
            }}
            className="my-4 -mx-3 font-semibold flex flex-row items-center gap-1 text-gray-500 hover:text-gray-600 cursor-pointer text-sm"
          >
            <LeftArrowIcon className="h-3" /> Go back to Bills
          </div>
          {bills[data.billNo]?.items?.length > 0 ? (
            <div
              style={{ backgroundColor: "#F5F5F5", marginTop: "4px" }}
              className="grid grid-cols-3 font-semibold tracking-wider py-3 px-8 text-gray-600"
            >
              <div>Expense Account</div>
              <div>Description</div>
              <div className="flex flex-row-reverse w-full">Amount</div>
            </div>
          ) : (
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="w-full flex flex-col items-center justify-center gap-1 font-semibold text-gray-600"
            >
              <span>Amount</span>$
              {Number(bills?.[data.billNo]?.pending_amount ?? 0).toFixed(2)}
            </div>
          )}
          {bills?.[data.billNo]?.items?.map((transaction, index) => {
            return (
              <div
                className={
                  "grid grid-cols-3 w-full cursor-default px-8 py-2 border-b border-gray-400"
                }
              >
                <div className="break-all">
                  {
                    expenseAccounts?.filter(
                      (value) => value.id == transaction.account
                    )[0]?.name
                  }
                </div>
                <div className="break-all">{transaction.description}</div>
                <div className="text-right break-all">
                  ${Number(transaction.unit_price).toFixed(2)}
                </div>
              </div>
            );
          })}
          <div className="py-2 px-8 flex flex-row items-center gap-3 justify-end text-sm text-gray-600">
            Pending amount :{" "}
            <span
              className={
                Number(bills?.[data.billNo]?.pending_amount) === 0
                  ? "text-green-600"
                  : "text-red-600"
              }
            >
              {" "}
              ${Number(bills?.[data.billNo]?.pending_amount).toFixed(2)}
            </span>
          </div>
          <div className="w-full flex flex-row items-center flex-wrap gap-8 mt-4 p-2 px-5 py-4">
            <div className="flex flex-row items-center gap-2">
              <span className="text-sm text-gray-600">Accounts : </span>
              <Dropdown
                parentRef={parentRef}
                selectClassName={accountingInputClassName + " w-48 py-0"}
                boxClassName="max-h-40"
                onChange={(value) => {
                  if (value === "OPEN_NEW_ACCOUNT_POPUP")
                    set_new_account_popup(true);
                  else setSelectAccount(value);
                }}
                value={selectAccount}
                options={[
                  {
                    word: "Create New Account",
                    label: "Create New Account",
                    value: "OPEN_NEW_ACCOUNT_POPUP",
                  },

                  ...accounts?.map((account) => {
                    return {
                      word: account.name,
                      label: (
                        <div className="w-full flex flex-row gap-1 items-center justify-between px-1 text-xs">
                          <span className="break-all">{account.name}</span>
                          <span className="break-all">
                            ${amountFormat(account.current_amount)}
                          </span>
                        </div>
                      ),
                      value: account.id,
                    };
                  }),
                ]}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <span className="text-sm text-gray-600">Amount paying : </span>

              <Input
                type="currency"
                value={parseFloat(data.amountPaying)}
                onChange={(value) => {
                  setData({ ...data, amountPaying: value });
                }}
                className={accountingInputClassName + " w-48"}
              />
            </div>
          </div>
          <div className="mt-8 w-full flex flex-row items-center justify-end gap-4">
            <button
              className="focus:outline-none font-medium border-2 border-gray-400 text-gray-600 px-7 py-1 rounded hover:opacity-60 duration-200"
              onClick={() => {
                setData({ ...data, popup: "BILL_LIST", billNo: 0 });
              }}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: "#2A32FD",
                border: "2px solid #2A32FD",
              }}
              className={
                "rounded text-white font-medium px-7 py-1 hover:opacity-70"
              }
              onClick={() => {
                onSubmit();
              }}
            >
              {payLoading ? <Loading className="h-6 w-6" /> : "Pay"}
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          "absolute inset-0 bg-white bg-opacity-60 z-40 flex items-center justify-center " +
          (new_account_popup ? "" : "hidden")
        }
      >
        <NewAccountPopup
          hoaId={hoaId}
          closePopup={(_) => {
            set_new_account_popup(false);
          }}
          account_status={{ type: "ASSET", is_bank_account: true }}
        />
      </div>
    </div>
  );
}
