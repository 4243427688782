import { useEffect, useState } from "react";
import { amountFormat } from "../../../../common/AmountFormat";

import { FourBoxIcon } from "../../../../common/AppIcons";
import Popup from "../../../../common/Popup";
import BalanceCard from "../../../common/components/BalanceCard";
import BalanceSummaryPopup from "../../../common/components/BalanceSummaryPopup";

export default function TopSection({ total, vendors, show, className }) {
  const [summary_popup, set_summary_popup] = useState(false);

  return (
    <div
      className={
        "flex flex-col sm:flex-row items-center sm:justify-between gap-6 relative z-10 " +
        className
      }
    >
      <div
        className={
          "w-full flex flex-wrap flex-row gap-4 transform duration-300 overflow-auto " +
          (show ? "max-h-96 mb-8" : "max-h-0")
        }
      >
        <BalanceCard
          className="pr-20 bg-gray-200"
          label={"Total Payable"}
          orientation={false}
          balance={total}
        />
        <button
          onClick={() => set_summary_popup(true)}
          className="text-gray-600 font-semibold border-2 border-gray-500 rounded"
        >
          <div className="px-5 py-7 flex flex-row items-center gap-4 h-full w-full transform duration-300 hover:scale-105">
            <FourBoxIcon className="h-8" /> See Expense Summary
          </div>
        </button>
      </div>

      <Popup
        show={summary_popup}
        setShow={set_summary_popup}
        className="max-w-md rounded"
      >
        <BalanceSummaryPopup
          title="Expense Summary"
          subTitle={["Total Payable", amountFormat(total), ,]}
          data={{
            heading: ["Name", "Balance"],
            body: vendors?.map((ele) => [
              ele.display_name ?? "",
              amountFormat(ele.pending_balance),
            ]),
          }}
          onClose={() => set_summary_popup(false)}
        />
      </Popup>
    </div>
  );
}
