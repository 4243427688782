import { Link } from "raviger";

import Failed from "./cardPaymentAfterScreens/Failed";
import Success from "./cardPaymentAfterScreens/Success";

export default function CardPaymentAfterScreen({ hoaId, status }) {
  return (
    <div className="w-full min-h-screen flex">
      <div className="flex flex-col sm:gap-10 flex-grow py-12 items-center p-6 bg-white sm:m-8 rounded">
        <div className="max-w-xl text-center mx-auto">
          <div className="flex flex-col text-center items-center gap-4">
            {status === "success" ? (
              <Success />
            ) : status === "failed" ? (
              <Failed />
            ) : null}
            <Link
              href={"/hoa/" + hoaId + "/payment_methods/"}
              className="mt-3 rounded px-3 py-2 text-white bg-indigo-500 hover:bg-indigo-600"
            >
              View Payment Methods
            </Link>
            <Link
              className="rounded p-2 text-indigo-700 hover:text-indigo-800"
              href={"/hoa/" + hoaId + ""}
            >
              Property Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
