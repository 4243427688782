import React from "react";
import {
  CheckIcon,
  CrossCicleIcon,
  CrossIcon,
  CrossIcon2,
} from "../../../common/AppIcons";

export default function TaskProgress({ tasks, last_finished, className }) {
  const task_finished = (task) => {
    let flag = false;
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].value === task.value) {
        return flag ? false : true;
      }
      if (tasks[i].value === last_finished) flag = true;
    }
    return tasks.slice(0, tasks.indexOf(last_finished) + 1)?.includes(task);
  };

  return (
    <div className="w-full max-w-4xl flex flex-row items-center px-5 sm:px-10 pt-2 pb-8">
      <div className="flex flex-row items-center">
        <div
          className={
            "p-2 sm:p-3 flex flex-col items-center rounded-full " +
            (task_finished(tasks[0])
              ? tasks[0]?.icon === "CROSS"
                ? "bg-red-600 text-white"
                : "bg-green-600 text-white"
              : "border border-gray-300")
          }
        >
          {task_finished(tasks[0]) ? (
            tasks[0]?.icon === "CROSS" ? (
              <CrossIcon2 className="h-3 sm:h-5 w-3 sm:w-5" />
            ) : (
              <CheckIcon className="h-3 sm:h-5 w-3 sm:w-5" />
            )
          ) : (
            <div className="h-3 sm:h-5 w-3 sm:w-5 rounded-full bg-gray-300" />
          )}
          <span
            className={
              "absolute transform translate-y-10 text-xs sm:text-sm " +
              (task_finished(tasks[0]) ? "text-gray-700" : "text-gray-400")
            }
          >
            {tasks[0].label}
          </span>
        </div>
      </div>
      <div className={"flex-grow items-center grid  " + className}>
        {tasks?.slice(1, tasks?.length).map((task, task_index) => (
          <div key={task_index} className="flex flex-row items-center">
            <div
              className={
                "flex-grow " +
                (task_finished(task)
                  ? "bg-green-600 h-0.5"
                  : "bg-gray-300 h-px")
              }
            />
            <div
              className={
                "p-2 sm:p-3 flex flex-col items-center rounded-full " +
                (task_finished(task)
                  ? task.icon === "CROSS"
                    ? "bg-red-600 text-white"
                    : "bg-green-600 text-white"
                  : "border border-gray-300")
              }
            >
              {task_finished(task) ? (
                task.icon === "CROSS" ? (
                  <CrossIcon2 className="h-3 sm:h-5 w-3 sm:w-5" />
                ) : (
                  <CheckIcon className="h-3 sm:h-5 w-3 sm:w-5" />
                )
              ) : (
                <div className="h-3 sm:h-5 w-3 sm:w-5 rounded-full bg-gray-300" />
              )}
              <span
                className={
                  "absolute transform translate-y-10 text-xs sm:text-sm " +
                  (task_finished(task) ? "text-gray-700" : "text-gray-400")
                }
              >
                {task.label}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
