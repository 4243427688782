import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";

import { LoadingCircleIcon } from "../../../common/AppIcons";
import CompanyForm from "./components/CompanyForm";
import ProfileBase from "../ProfileBase";
import { getOrg, getOrgDetails, patchOrg } from "../../../../api/ApiV2";

export default function ProfileAssetProfile({ asset }) {
  const [data, setData] = useState({});
  const [warnings, setWarnings] = useState({});
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState();
  const componentRef = useRef(null);

  useEffect(() => {
    getOrg({ type: "PMC" })
      .then((res) => {
        setOrgs(res.results);
      })
      .catch((res) => {
        toast.error(res.message ?? "Error fetching organisations details");
      });
  }, []);

  const delete_warning = (value) => {
    let temp_warning = warnings;
    delete temp_warning[value];
    setWarnings(temp_warning);
  };

  useEffect(() => {
    asset && getOrgData();
  }, [asset]);

  const getOrgData = () => {
    setLoading(true);
    getOrgDetails({ id: asset })
      .then((res) => {
        setData(res);
        setLoading(false);
        setWarnings({});
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong");
      });
  };
  const submitHoa = () => {
    setLoading(true);

    patchOrg({
      id: asset,
      data: data,
    })
      .then((res) => {
        setLoading(false);
        toast.success("Successfully updated");
      })
      .catch((err) => {
        setLoading(false);
        setWarnings(err);
        toast.error("Something went wrong");
      });
  };

  if (loading)
    return (
      <ProfileBase page={`/profile/${asset}/profile`}>
        <div className="py-10 w-full">
          <LoadingCircleIcon className="h-8 mx-auto" />
        </div>
      </ProfileBase>
    );
  return (
    <ProfileBase page={`/profile/${asset}/profile`}>
      <div
        ref={componentRef}
        className="w-full p-12 flex flex-col items-center rounded-lg bg-white"
      >
        <div className="max-w-2xl w-full flex flex-col">
          <div className="h-full w-full m-auto px-4 flex flex-col items-center text-gray-800 max-w-2xl">
            <div className="w-full flex flex-col">
              <span className="text-2xl font-semibold">Org Profile</span>
              <div className="mt-6 flex flex-row flex-wrap items-center gap-5">
                <img
                  src="https://m.media-amazon.com/images/M/MV5BOTE5ZTRjYTgtOTRjNy00NTEzLWEzZTctNDliY2Q3NGRhMGIxXkEyXkFqcGdeQXVyNzgxMzc3OTc@._V1_.jpg"
                  className="h-28 w-28 rounded-full border"
                />
                <button className="px-3 py-2 rounded border border-gray-200 shadow hover:shadow-lg text-gray-600 hover:bg-gray-100 hover:text-gray-700">
                  Upload new picture
                </button>
                <button className="px-3 py-2 rounded border border-gray-200 shadow hover:shadow-lg text-gray-600 hover:bg-gray-100 hover:text-gray-700">
                  Remove
                </button>
              </div>
              <CompanyForm
                {...{
                  form: data,
                  setClientData: setData,
                  warnings,
                  setWarnings,
                  delete_warning,
                  orgs,
                }}
              />
            </div>

            <div className="w-full mt-10 flex flex-row justify-end items-center gap-3">
              <button
                style={{
                  backgroundColor: "#2A32FD",
                  border: "2px solid #2A32FD",
                }}
                className="text-sm text-white hover:opacity-80 h-10 w-28 sm:w-40 flex items-center justify-center rounded"
                onClick={() => {
                  submitHoa();
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </ProfileBase>
  );
}
