import React from "react";
import Paginator from "../../../../../common/Paginator";
import { LoadingCircleIcon } from "../../../../common/AppIcons";
import { dateStringFormat } from "../../../../../utils/DateUtils";

export default function UnpaidTable({
  transactions,
  loading,
  page,
  set_page,
  onClick_transaction,
}) {
  return (
    <div className="my-8">
      <div className="grid grid-cols-4 gap-2 px-5 sm:px-10 mt-3 mb-2 text-sm text-gray-600 font-semibold">
        {[
          { title: "Unit", class: "" },
          { title: "Date", class: "" },
          { title: "Reference Number", class: "" },
          { title: "Amount Outstanding", class: "w-full text-right" },
        ].map((value) => {
          return (
            <span key={value.title} className={value.class}>
              {value.title}
            </span>
          );
        })}
      </div>
      <div className="mt-3 overflow-auto rounded-md bg-white">
        {loading ? (
          <div className="w-full flex justify-center items-center my-8">
            <LoadingCircleIcon className="h-7 text-blue-600" />
          </div>
        ) : transactions.length === 0 ? (
          <div className="text-center font-semibold text-gray-600 my-8">
            No Transaction Available
          </div>
        ) : (
          transactions.map((value) => {
            return (
              <div
                key={value.id}
                className="grid grid-cols-4 gap-2 border-b border-gray-300 text-gray-800 text-sm hover:bg-gray-200 cursor-pointer px-5 sm:px-10 py-3"
                onClick={() => {
                  onClick_transaction(value);
                }}
              >
                <span className="break-words">
                  {value.customer_unit_object
                    ? value.customer_unit_object.unit_number +
                      " " +
                      value.customer_unit_object.address_object
                        ?.street_address_1
                    : "Untracked Customer"}
                </span>
                <span className="">{dateStringFormat(value.created_date)}</span>
                <span className="break-words">{value.reference_number}</span>
                <div className="w-full flex flex-row-reverse">
                  <span className="break-all">
                    {" "}
                    ${Number(value.amount)?.toFixed(2)}
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>
      <Paginator
        currentPage={page.current}
        totalPage={page.total}
        onChange={(pageNo) => {
          set_page({ ...page, current: pageNo });
        }}
      />
    </div>
  );
}
