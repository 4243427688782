import React, { useEffect, useState } from "react";

import { getVendors } from "../../../../../api/ApiV2";
import Paginator from "../../../../../common/Paginator";
import Loading from "../../../../common/Loading";
import PopupHeading from "../../../../common/PopupHeading";
import { LoadingCircleIcon2 } from "../../../../common/AppIcons";
import { dateStringFormat } from "../../../../../utils/DateUtils";

export default function BillList({
  hoaId,
  data,
  setData,
  bills,
  expenseAccounts,
  accounts,
  loading,
  setLoading,
  onSubmit,
  onSuccess,
}) {
  const [vendors, setVendors] = useState();

  useEffect(() => {
    setLoading(true);
    getVendors({ hoaId })
      .then((res) => {
        setLoading(false);
        console.log("vendor", res.results, res.results?.[0]?.id);
        setVendors(res.results);
        setData((data) => ({ ...data, vendor_selected: res.results?.[0]?.id }));
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });
  }, []);

  return (
    <div className={"w-full flex flex-col"}>
      <PopupHeading closePopup={onSuccess}>Pay Bills</PopupHeading>

      {loading ? (
        <div className="m-auto py-8 sm:py-12 text-lg">
          <LoadingCircleIcon2 className="h-7 text-blue-500" />
        </div>
      ) : !bills || bills.length === 0 ? (
        <div className="text-center py-8 opacity-40 font-semibold">
          No Unpaid Bills
        </div>
      ) : (
        <div className="px-3 sm:px-10 pt-12 pb-10 w-full grid grid-cols-7">
          {/* vendors section */}
          <div className="flex flex-col col-span-2 h-full border-r border-gray-400 overflow-auto">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="font-semibold tracking-wider py-3 px-8 text-gray-600"
            >
              Vendors
            </div>
            {vendors?.map((value, index) => (
              <div
                key={index}
                onClick={() => {
                  setData({ ...data, vendor_selected: value.id });
                }}
                className={
                  "w-full py-2 px-8 font-semibold cursor-default break-words bg-gradient-to-r from-white to-white " +
                  (data.vendor_selected === value?.id
                    ? "from-indigo-100 via-indigo-100 text-indigo-600"
                    : "cursor-pointer duration-200 hover:from-indigo-100 hover:via-indigo-100 hover:text-indigo-600")
                }
              >
                {value.display_name}
              </div>
            ))}
          </div>

          {/* bill list */}
          <div className="col-span-5 overflow-auto">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="grid grid-cols-3 font-semibold tracking-wider py-3 px-8 text-gray-600"
            >
              <div>Date</div>
              <div>Reference Number</div>
              <div className="flex flex-row-reverse w-full">Amount</div>
            </div>
            {bills?.filter((value) => {
              return value.vendor_object?.id === data.vendor_selected;
            }).length > 0 ? null : (
              <div className="text-center py-8 opacity-40 font-semibold">
                No bills left to pay
              </div>
            )}
            {bills?.map((bill, index) => {
              if (bill.vendor_object?.id === data.vendor_selected)
                return (
                  <div
                    className={
                      "grid grid-cols-3 hover:bg-gray-200 w-full cursor-pointer px-8 py-2 border-b border-gray-400"
                    }
                    onClick={() => {
                      if (expenseAccounts && accounts && vendors && bills)
                        setData({
                          ...data,
                          popup: "BILL_DETAIL",
                          billNo: index,
                          amountPaying: bills[index]?.pending_amount,
                        });
                    }}
                  >
                    <div className="break-all">
                      {dateStringFormat(bill.date)}
                    </div>
                    <div className="break-all">{bill.reference_number}</div>
                    <div className="text-right break-all">
                      ${bill.pending_amount}
                    </div>
                  </div>
                );
            })}
            {data.totalPage && (
              <Paginator
                totalPage={data.totalPage}
                currentPage={data.billPage}
                entryPerPage={50}
                onChange={(pageNo) => {
                  setData((data) => ({ ...data, billPage: pageNo }));
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
