import React, { useState, useEffect } from "react";
import {
  PlusIcon,
  DeleteIcon2,
  LoadingAnimated,
  LoadingCircleIcon2,
} from "../../../../common/AppIcons";
import { conductMeeting } from "../../../../../api/Api";
import "../linkify.css";

import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";
import draftToMarkdown from "draftjs-to-markdown";
import "../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ProfilePic } from "../constants";
import SelectHeadings from "../../../../common/Form/SelectHeadings";
import { submitAgenda } from "../utility/submitAgenda";
import { toast } from "react-toastify";
import { getBoardMeetingAgendas } from "../../../../../api/ApiV2";

const toolbar_options = {
  options: [
    "inline",
    "blockType",
    "list",
    "link",
    "emoji",
    "image",
    "remove",
    "history",
  ],
  inline: {
    options: ["bold", "italic", "strikethrough", "monospace"],
  },
};
export default function MeetingAgenda({
  hoaId,
  meeting,
  set_meeting,
  editor_state,
  set_editor_state,
  members,
  attendees,
  hidden,
}) {
  const [force_render, toggle_force_rerender] = useState(true);
  const [editor_show, set_editor_show] = useState();
  const [page, set_page] = useState("AGENDA_1");
  const [warnings, set_warnings] = useState({});
  const [loading, set_loading] = useState([]);
  const [created_new_page, set_created_new_page] = useState(false);

  useEffect(() => {
    if (created_new_page && meeting?.agenda) {
      set_created_new_page(false);
      set_page(headings()[headings().length - 1]?.value);
    }
  }, [meeting?.agenda]);

  const headings = () => {
    let headings = [];
    meeting?.agenda?.map((val, index) => {
      headings = [...headings, "Agenda " + (index + 1)];
    });
    return headings.map((val, index) => {
      return {
        label: val,
        value: val.toUpperCase().replaceAll(" ", "_"),
        index: index,
      };
    });
  };

  const nextPage = () => {
    let current_index = headings()
      .map((val, index) => {
        if (val.value === page) return index;
      })
      ?.filter((val) => val)[0];
    if (headings()[current_index + 1])
      set_page(headings()[current_index + 1].value);
  };

  const remove_warning = (name) => {
    let temp_warnings = warnings;
    delete temp_warnings[name];
    set_warnings(temp_warnings);
  };

  useEffect(() => {
    getBoardMeetingAgendas({ hoaId, boardMeetingId: meeting.id })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }, [meeting.id]);

  const submit_agenda = () => {
    const submitAgendaIndex = headings().find(
      (val) => val.value === page
    )?.index;
    if (loading.includes(submitAgendaIndex)) return;
    set_loading([...loading, submitAgendaIndex]);
    console.log(
      meeting?.agenda?.find((val, index) => index === submitAgendaIndex)
    );
    submitAgenda({
      hoaId,
      boardMeetingId: meeting?.id,
      agendaBase: meeting?.agenda?.find(
        (val, index) => index === submitAgendaIndex
      ),
      agenda_index: submitAgendaIndex,
      set_warnings: (warnings) =>
        set_warnings((preWarnings) => ({ ...preWarnings, ...warnings })),
      onFinish: (val) => {
        console.log(val);
        set_meeting({
          ...meeting,
          agenda: meeting?.agenda?.map((agenda, index) => {
            if (index === submitAgendaIndex) return val;
            else return agenda;
          }),
        });
        set_loading(loading.filter((val) => val !== submitAgendaIndex));
        nextPage();
        toast.success("Agenda submitted successfully");
      },
      onError: () => {
        set_loading(loading.filter((val) => val !== submitAgendaIndex));
        toast.error("Error submitting agenda");
      },
    });
  };

  return (
    <div className={"flex flex-col rounded " + (hidden ? "hidden" : "")}>
      <div className="flex justify-between items-baseline gap-3 w-full">
        <SelectHeadings
          headings={headings()}
          selected={page}
          onChange={(val) => {
            set_page(val);
          }}
          base_heading_width={100}
          className="mx-2 max-w-full overflow-x-auto"
        />
        <button
          onClick={() => {
            set_created_new_page(true);
            set_meeting({
              ...meeting,
              agenda: [...meeting.agenda, {}],
            });
          }}
          className="px-2 py-1 whitespace-pre focus:outline-none border-2 border-indigo-500 text-indigo-700 text-sm rounded hover:opacity-70 flex flex-row gap-2 items-center"
        >
          <PlusIcon className="h-1" />
          Create new agenda
        </button>
      </div>

      {meeting?.agenda?.map((agenda, index2) => {
        if (Number(page.split("_")[1]) === index2 + 1) {
          return loading.includes(index2) ? (
            <div className="w-full bg-white px-7 py-20 flex flex-col">
              <LoadingCircleIcon2 className="h-10" />
            </div>
          ) : (
            <div
              key={index2}
              className="w-full bg-white px-7 py-1 flex flex-col"
            >
              <div className={"bg-gray-100 px-6 py-5"}>
                <div className="flex justify-end min-w-full"></div>
                <div className="flex flex-col items-end gap-1">
                  <span className="text-xs text-red-400">
                    {warnings["agenda" + index2 + "title"]}
                  </span>
                  <input
                    className="w-full font-semibold bg-gray-100 text-gray-800 text-xl focus:outline-none truncate"
                    placeholder="Enter agenda title"
                    value={agenda.title ?? ""}
                    onChange={(e) => {
                      remove_warning("agenda" + index2 + "title");
                      let arr = meeting;
                      arr.agenda[index2].title = e.target.value;
                      set_meeting(arr);
                      toggle_force_rerender(!force_render);
                    }}
                  />
                </div>
                <Editor
                  editorState={editor_state[index2]?.description}
                  placeholder="Enter agenda description..."
                  onFocus={() => {
                    set_editor_show("DESCRIPTION" + index2);
                  }}
                  onBlur={() => {
                    set_editor_show();
                  }}
                  toolbar={toolbar_options}
                  wrapperClassName="min-w-full mt-1"
                  toolbarClassName={
                    "fixed top-2 z-30 shadow-md " +
                    (editor_show === "DESCRIPTION" + index2
                      ? "opacity-100"
                      : "opacity-0 h-0 overflow-hidden hidden")
                  }
                  editorClassName="px-3 min-w-full focus:outline-none text-gray-700 border border-gray-100 focus:border-gray-200 bg-white"
                  onEditorStateChange={(editorState) => {
                    remove_warning("agenda" + index2 + "description");
                    set_editor_state({
                      ...editor_state,
                      [index2]: {
                        ...editor_state[index2],
                        description: editorState,
                      },
                    });
                    const newValue = draftToMarkdown(
                      convertToRaw(editorState.getCurrentContent())
                    );

                    let arr = meeting;
                    arr.agenda[index2].description = newValue;
                    set_meeting(arr);
                    toggle_force_rerender(!force_render);
                  }}
                />
                <div className="w-full flex justify-end">
                  <span className="text-xs text-red-400">
                    {warnings["agenda" + index2 + "description"]}
                  </span>
                </div>

                <div
                  className={
                    "overflow-hidden text-sm mt-1 pt-1 transition-all duration-500 ease-in-out"
                  }
                >
                  <div className="mt-6 flex w-full justify-end">
                    <button
                      onClick={() => {
                        let arr = meeting;
                        arr.agenda[index2].agenda_motions
                          ? (arr.agenda[index2].agenda_motions = [
                              ...arr.agenda[index2].agenda_motions,
                              { agenda_motion_votes: [] },
                            ])
                          : (arr.agenda[index2].agenda_motions = [
                              { agenda_motion_votes: [] },
                            ]);
                        set_meeting(arr);
                        toggle_force_rerender(!force_render);
                      }}
                      className="px-2 py-1 whitespace-pre focus:outline-none border-2 border-indigo-400 text-indigo-600 text-sm rounded hover:opacity-70 flex flex-row gap-2 items-center"
                    >
                      <PlusIcon className="h-1" />
                      Create new motion
                    </button>
                  </div>

                  {agenda.agenda_motions?.map(
                    (agenda_motion, agenda_motion_index) => (
                      <div
                        key={agenda_motion_index}
                        className="flex flex-col gap-1 flex-grow rounded mb-10"
                      >
                        <span
                          style={{ color: "#2A32FD" }}
                          className="font-bold text-lg tracking-wide"
                        >
                          Motion {agenda_motion_index + 1}
                        </span>
                        <div className="flex flex-col gap-1 w-full">
                          <div className="w-full mt-5 flex flex-row justify-between">
                            <label className="text-gray-800 font-semibold block text-sm tracking-wide">
                              Title
                            </label>
                            <span className="text-xs text-red-400">
                              {
                                warnings[
                                  "agenda" +
                                    index2 +
                                    "motion" +
                                    agenda_motion_index +
                                    "title"
                                ]
                              }
                            </span>
                          </div>

                          <input
                            className="bg-white appearance-none rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                            value={agenda_motion.title ?? ""}
                            onChange={(e) => {
                              remove_warning(
                                "agenda" +
                                  index2 +
                                  "motion" +
                                  agenda_motion_index +
                                  "title"
                              );
                              let arr = meeting;
                              arr.agenda[index2].agenda_motions[
                                agenda_motion_index
                              ].title = e.target.value;
                              set_meeting(arr);
                              toggle_force_rerender(!force_render);
                            }}
                          />
                        </div>
                        <div className="flex flex-row gap-2 w-full justify-around">
                          <div className="flex flex-row gap-2 mt-5 w-full justify-around">
                            <div className="flex flex-col gap-1 w-full">
                              <div className="w-full flex justify-between">
                                <label className="block text-sm tracking-wide font-semibold text-gray-700">
                                  Proposed by
                                </label>
                                <span className="text-xs text-red-400">
                                  {
                                    warnings[
                                      "agenda" +
                                        index2 +
                                        "motion" +
                                        agenda_motion_index +
                                        "proposed_by"
                                    ]
                                  }
                                </span>
                              </div>

                              <select
                                className="w-full text-gray-700 bg-white rounded px-3 py-2 border border-gray-100 focus:border-blue-400 focus:outline-none focus:ring-none"
                                value={agenda_motion.proposed_by ?? false}
                                onChange={(e) => {
                                  remove_warning(
                                    "agenda" +
                                      index2 +
                                      "motion" +
                                      agenda_motion_index +
                                      "proposed_by"
                                  );
                                  let arr = meeting;
                                  arr.agenda[index2].agenda_motions[
                                    agenda_motion_index
                                  ].proposed_by = e.target.value;
                                  set_meeting(arr);
                                  toggle_force_rerender(!force_render);
                                }}
                              >
                                <option
                                  value={false}
                                  disabled
                                  selected
                                  hidden
                                  className="px-2 py-1"
                                >
                                  Select Member
                                </option>
                                {attendees?.map((member, index) => (
                                  <option
                                    value={member.user}
                                    key={index}
                                    className="px-2 py-1"
                                  >
                                    {member.user_object?.first_name +
                                      " " +
                                      member.user_object?.last_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="flex flex-col gap-1 w-full">
                              <div className="w-full flex justify-between">
                                <label className="block text-sm tracking-wide font-semibold text-gray-700">
                                  Seconded by
                                </label>
                                <span className="text-xs text-red-400">
                                  {
                                    warnings[
                                      "agenda" +
                                        index2 +
                                        "motion" +
                                        agenda_motion_index +
                                        "seconded_by"
                                    ]
                                  }
                                </span>
                              </div>
                              <select
                                className="w-full text-gray-700 bg-white rounded px-3 py-2 border border-gray-100 focus:border-blue-400 focus:outline-none focus:ring-none"
                                value={agenda_motion.seconded_by ?? false}
                                onChange={(e) => {
                                  remove_warning(
                                    "agenda" +
                                      index2 +
                                      "motion" +
                                      agenda_motion_index +
                                      "seconded_by"
                                  );
                                  let arr = meeting;
                                  arr.agenda[index2].agenda_motions[
                                    agenda_motion_index
                                  ].seconded_by = e.target.value;
                                  set_meeting(arr);
                                  toggle_force_rerender(!force_render);
                                }}
                              >
                                <option
                                  value={false}
                                  disabled
                                  selected
                                  hidden
                                  className="px-2 py-1"
                                >
                                  Select Member
                                </option>
                                {attendees?.map((member, index) => (
                                  <option
                                    value={member.user}
                                    key={index}
                                    className="px-2 py-1"
                                  >
                                    {member.user_object?.first_name +
                                      " " +
                                      member.user_object?.last_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="mt-12 grid grid-cols-6 gap-2">
                          {[
                            {
                              label: "Votes",
                              className: "col-span-3 px-5",
                            },
                            {
                              label: "Yes",
                              className: "flex justify-center min-w-full",
                            },
                            {
                              label: "No",
                              className: "flex justify-center min-w-full",
                            },
                            {
                              label: "Abstain",
                              className: "flex justify-center min-w-full",
                            },
                          ].map((ele, ele_index) => (
                            <span
                              key={ele_index}
                              className={
                                "font-semibold text-gray-600 " + ele.className
                              }
                            >
                              {ele.label}
                            </span>
                          ))}
                        </div>

                        <div className="flex flex-col gap-2 mt-3 min-w-full">
                          {attendees
                            ?.filter((member) => member.attended)
                            .map((member, index7) => (
                              <div
                                key={index7}
                                className="bg-white py-2 rounded grid grid-cols-6 gap-2 items-center min-w-full"
                              >
                                <div className="col-span-3 px-3 flex flex-row gap-2 items-center">
                                  <ProfilePic
                                    className="shadow-none border h-9 w-9"
                                    member={member.user_object}
                                  />
                                  <span className="text-gray-600">
                                    {member.user_object?.first_name +
                                      " " +
                                      member.user_object?.last_name}
                                  </span>
                                </div>
                                {["YES", "NO", "ABSTAIN"].map(
                                  (ele, ele_index) => (
                                    <div
                                      key={ele_index}
                                      className="flex justify-center w-full"
                                    >
                                      <input
                                        type="checkbox"
                                        className="rounded shadow bg-gray-100"
                                        checked={
                                          agenda_motion.agenda_motion_votes?.find(
                                            (vote) => vote.user === member.user
                                          )?.vote === ele ?? false
                                        }
                                        onClick={() => {
                                          let arr = meeting;

                                          if (
                                            !arr.agenda[index2].agenda_motions[
                                              agenda_motion_index
                                            ].agenda_motion_votes
                                          ) {
                                            arr.agenda[index2].agenda_motions[
                                              agenda_motion_index
                                            ].agenda_motion_votes = [];
                                          }

                                          if (
                                            arr.agenda[index2].agenda_motions[
                                              agenda_motion_index
                                            ].agenda_motion_votes?.find(
                                              (vote) =>
                                                vote.user === member.user
                                            )
                                          ) {
                                            arr.agenda[index2].agenda_motions[
                                              agenda_motion_index
                                            ].agenda_motion_votes[
                                              agenda_motion.agenda_motion_votes
                                                .map((vote, index) => {
                                                  if (vote.user === member.user)
                                                    return index;
                                                })
                                                .find(
                                                  (val5) => val5 !== undefined
                                                )
                                            ].vote = ele;
                                          } else {
                                            arr.agenda[index2].agenda_motions[
                                              agenda_motion_index
                                            ].agenda_motion_votes = [
                                              ...arr.agenda[index2]
                                                .agenda_motions[
                                                agenda_motion_index
                                              ].agenda_motion_votes,
                                              {
                                                user: member.user,
                                                vote: ele,
                                              },
                                            ];
                                          }
                                          set_meeting(arr);
                                          toggle_force_rerender(!force_render);
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            ))}
                        </div>

                        <div
                          className={
                            "mt-7 ml-1 flex flex-row items-center font-semibold " +
                            (agenda_motion.is_passed
                              ? "text-indigo-600"
                              : "text-gray-700")
                          }
                        >
                          <input
                            type="checkbox"
                            className="mr-3"
                            checked={agenda_motion.is_passed ?? false}
                            onChange={() => {
                              let arr = meeting;
                              arr.agenda[index2].agenda_motions[
                                agenda_motion_index
                              ].is_passed = !agenda_motion.is_passed;
                              set_meeting(arr);
                              toggle_force_rerender(!force_render);
                            }}
                          />
                          Mark as passed
                        </div>
                        {agenda_motion.is_passed && (
                          <div className="flex flex-col gap-1 mt-5">
                            <span className="font-semibold text-gray-700">
                              Resolution
                            </span>
                            <input
                              className="w-full text-gray-700 bg-white border border-gray-100 focus:border-gray-300 px-3 py-2 focus:outline-none rounded"
                              value={agenda_motion.resolution ?? ""}
                              onChange={(e) => {
                                let arr = meeting;
                                arr.agenda[index2].agenda_motions[
                                  agenda_motion_index
                                ].resolution = e.target.value;
                                set_meeting(arr);
                                toggle_force_rerender(!force_render);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )
                  )}

                  <div className="flex flex-col gap-1 w-full mb-2 mt-12">
                    <div className="w-full flex justify-between mb-7">
                      <label
                        style={{ color: "#2A32FD" }}
                        className="font-bold text-lg tracking-wide"
                      >
                        Action Items
                      </label>

                      <button
                        onClick={() => {
                          let arr = meeting;
                          arr.agenda[index2].action_items
                            ? (arr.agenda[index2].action_items = [
                                ...arr.agenda[index2].action_items,
                                {},
                              ])
                            : (arr.agenda[index2].action_items = [{}]);
                          set_meeting(arr);
                          toggle_force_rerender(!force_render);
                        }}
                        className="px-2 py-1 whitespace-pre focus:outline-none border-2 border-indigo-400 text-indigo-600 text-sm rounded hover:opacity-70 flex flex-row gap-2 items-center"
                      >
                        <PlusIcon className="h-1" />
                        Create new action item
                      </button>
                    </div>

                    {agenda.action_items?.map((action_item, item_index) => (
                      <div
                        key={item_index}
                        className="text-gray-700 flex flex-row items-center gap-2 px-1 w-full"
                      >
                        <span className="px-2 text-gray-800">
                          {item_index + 1}.
                        </span>
                        <div className="flex flex-col relative bottom-1 w-full">
                          <span className="text-xs text-red-400">
                            {warnings["action_item" + index2 + "title"] ?? ""}
                          </span>
                          <input
                            value={action_item.title ?? ""}
                            className="w-full bg-white rounded px-3 py-2 border border-gray-100 focus:border-blue-400 focus:outline-none"
                            onChange={(e) => {
                              remove_warning("action_item" + index2 + "title");
                              let arr = meeting;
                              arr.agenda[index2].action_items[
                                item_index
                              ].title = e.target.value;
                              set_meeting(arr);
                              toggle_force_rerender(!force_render);
                            }}
                          />
                        </div>
                        <div className="flex flex-col relative bottom-1 w-full">
                          <span className="text-xs text-red-400">
                            {warnings[
                              "action_item" + index2 + "responsible_member"
                            ] ?? ""}
                          </span>
                          <select
                            className="w-full bg-white rounded px-3 py-2 border border-gray-100 focus:border-blue-400 focus:outline-none focus:ring-none"
                            value={action_item.responsible_member ?? false}
                            onChange={(e) => {
                              remove_warning(
                                "action_item" + index2 + "responsible_member"
                              );
                              let arr = meeting;
                              arr.agenda[index2].action_items[
                                item_index
                              ].responsible_member = e.target.value;
                              set_meeting(arr);
                              toggle_force_rerender(!force_render);
                            }}
                          >
                            <option
                              value={false}
                              selected
                              disabled
                              className="px-2 py-1 text-sm"
                            >
                              Select responsible member
                            </option>
                            {members?.map((member, index) => (
                              <option
                                value={member.user?.id}
                                key={index}
                                className="px-2 py-1 text-sm"
                              >
                                {member.user?.first_name +
                                  " " +
                                  member.user?.last_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <span className="pl-4 pr-2">
                          <span
                            onClick={() => {
                              if (action_item.id) return;

                              let arr = meeting;

                              arr.agenda[index2].action_items.splice(
                                item_index,
                                1
                              );
                              set_meeting(arr);
                              toggle_force_rerender(!force_render);
                            }}
                            className={
                              "cursor-pointer rounded-full text-red-600 hover:text-gray-500 " +
                              (action_item.id ? "invisible" : "")
                            }
                          >
                            <DeleteIcon2 className="h-5" />
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
      <div className="w-full flex justify-end px-4 my-3">
        <button
          onClick={() => {
            submit_agenda();
          }}
          style={{ backgroundColor: "#2A32FD" }}
          className="hover:opacity-80 font-semibold text-lg text-white rounded shadow h-10 w-40 flex items-center justify-center"
        >
          Submit Agenda
        </button>
      </div>
    </div>
  );
}
