import { Link } from "raviger";
import { TickInCircleIcon2 } from "../../common/AppIcons";

const pricingData = [
  {
    text1: "$0",
    text2: "Maintenance",
    text3: "Billed annually.",
    link: "/register",
    linkLabel: "Book Service",
    benefits: [
      "Dashboard",
      "Create Property Profile",
      "Submit Service Requests",
      "Track Service Requests",
      "Review & Accept Estimates",
      "Full Access to Squadhub Pro",
      "Verified and Hand-picked Pros",
    ],
  },
  {
    text1: "$35/HOA",
    text2: "Essential plan",
    text3: "Billed annually.",
    link: "/register",
    linkLabel: "Get started",
    benefits: [
      "Everything in Maintenance plus",
      "Board Meetings",
      "Compliance",
      "Community Messaging",
      "Amenity reservations",
      "Notice board",
      "Message by SMS or email",
      "Keep your amenity fully informed on common area maintenance with tracking",
    ],
  },
  {
    text1: "$75/HOA",
    text2: "Premium plan",
    text3: "Billed annually.",
    link: "/register",
    linkLabel: "Get started",
    benefits: [
      "Everything in Essential plus",
      "White cloves maintenance Services",
      "Walkthroughs",
      "Maintenance reports",
      "Accounting",
      "Accounting",
      "Full tools for self managing your HOA",
    ],
  },
];

export default function Pricing({ pricingComponent, className }) {
  return (
    <div
      ref={pricingComponent}
      className={"flex flex-col items-center gap-3 " + className}
    >
      <div
        className="px-4 py-1.5 rounded"
        style={{
          backgroundColor: "rgba(42, 50, 253, 0.07)",
          color: "#2A32FD",
          border: "1px solid #d8d8e7",
        }}
      >
        Pricing
      </div>
      <span
        style={{ fontSize: "26px", fontWeight: "800" }}
        className="text-center"
      >
        Simple, transparent pricing
      </span>
      <span className="max-w-xl mx-auto text-center font-light">
        Remember use of the maintenance portal will always be FREE. HOAs can
        unlock the starter pack for three months at 50% & includes onboarding
        and unlimited support
      </span>
      <div className="w-full mt-5 flex items-center md:items-start flex-col md:grid md:grid-cols-3 gap-2">
        {pricingData.map((plan, plan_index) => (
          <PlanCard key={plan_index} {...plan} />
        ))}
      </div>
    </div>
  );
}

const PlanCard = ({ text1, text2, text3, link, linkLabel, benefits }) => (
  <div
    style={{
      boxShadow: "2px 2px 9px #edebeb",
      maxWidth: "300px",
    }}
    className="rounded-2xl flex flex-col items-center gap-1 p-6 pt-12"
  >
    <span className="text-3xl font-semibold">{text1}</span>
    <span className="font-semibold">{text2}</span>
    <span className="text-sm text-gray-500">{text3}</span>
    <div className="mt-8 w-full flex flex-col items-start gap-2.5">
      {benefits.map((benefit, benefit_index) => (
        <div
          key={benefit_index}
          className="text-sm text-gray-500 flex flex-row items-start gap-3"
        >
          <div>
            <TickInCircleIcon2 className="h-5 mt-0.5" />
          </div>
          {benefit}
        </div>
      ))}
    </div>
    <Link
      href={link}
      style={{ background: "#2A32FD" }}
      className="mt-10 w-full px-4 py-2 text-center focus:outline-none rounded-lg text-white duration-300 hover:scale-105"
    >
      {linkLabel}
    </Link>
  </div>
);
