import React from "react";

export default function FormElement({ label, children, error, className }) {
  return (
    <div className={"mt-5 flex flex-col gap-1 w-full " + className}>
      <div className="flex flex-row items-center justify-between gap-4 flex-wrap">
        <span className="text-sm pl-1 text-gray-700">{label ?? ""}</span>
        <span className="text-xs text-red-500">{error ?? ""}</span>
      </div>
      {children}
    </div>
  );
}
