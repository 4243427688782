import { useEffect, useState } from "react";

import Input from "../../common/Form/Input";
import ProfileBase from "./ProfileBase";
import { changePassword, getUser, patchUser } from "../../../api/ApiV2";
import { LoadingCircleIcon2 } from "../../common/AppIcons";
import { toast } from "react-toastify";
import { useUserActions } from "../../../actions/UserActions";

export default function ResetPassword({}) {
  const [password, setPassword] = useState({
    password: null,
    confirm_password: null,
    current_password: null,
  });
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState({});
  const { logout } = useUserActions();

  useEffect(() => {
    setLoading(true);
    getUser()
      .then((res) => {
        setLoading(false);
        setUser(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const submit = () => {
    if (password.password !== password.confirm_password) {
      setWarning({
        password: "Password does not match",
      });
      return;
    }
    setLoading(true);
    changePassword({
      userId: user?.id,
      data: password,
    })
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Password changed successfully");
        setTimeout(() => {
          logout();
          toast.success("Use new password to login");
        }, [1000]);
      })
      .catch((err) => {
        setWarning(err);
        setLoading(false);
        console.log(err);
      });
  };
  if (loading)
    return (
      <ProfileBase page="/profile/reset_password">
        <div className="w-full px-10 py-20 flex flex-col items-center rounded-lg bg-white">
          <LoadingCircleIcon2 className="h-10" />
        </div>
      </ProfileBase>
    );
  else
    return (
      <ProfileBase page="/profile/reset_password">
        <div className="w-full px-10 py-20 flex flex-col items-center rounded-lg bg-white">
          <div className="max-w-lg w-full flex flex-col">
            <span className="text-2xl font-semibold">Reset Password</span>
            <span className="mt-1 font-light text-gray-600">
              Your new password should be different from the passwords
              previously used
            </span>

            <div className="mt-8 flex flex-col gap-4">
              {[
                {
                  label: "Current password",
                  value: "current_password",
                  type: "password",
                },
                {
                  label: "Password",
                  value: "password",
                },
                {
                  label: "Confirm your new password",
                  value: "confirm_password",
                  warning: "password",
                },
              ].map((ele, ele_index) => (
                <div key={ele_index} className="flex flex-col gap-1">
                  <div className="flex flex-row justify-between">
                    <label className="font-light">{ele.label}</label>
                    <span className="text-red-500 text-sm">
                      {warning[ele.warning ?? ele.value]}
                    </span>
                  </div>
                  <Input
                    className={
                      "w-full bg-gray-100 hover:bg-gray-200 rounded px-2.5 py-2 " +
                      ele.className
                    }
                    type={ele.type}
                    value={password[ele.value]}
                    onChange={(val) => {
                      setWarning((warning) => ({
                        password: null,
                        confirm_password: null,
                      }));
                      setPassword((password) => ({
                        ...password,
                        [ele.value]: val,
                      }));
                    }}
                  />
                </div>
              ))}
            </div>
            <button
              onClick={submit}
              style={{ backgroundColor: "#2A32FD" }}
              className="mt-8 text-sm hover:opacity-80 text-white border px-10 sm:px-16 py-2 rounded"
            >
              Confirm
            </button>
          </div>
        </div>
      </ProfileBase>
    );
}
