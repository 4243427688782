import React, { useState, useEffect } from "react";
import { navigate } from "raviger";

import { getAccounts } from "../../../../../api/ApiV2";
import JournalEntry from "../../../../accounting/pages/journalEntry";
import Expense from "../../../../accounting/pages/expense";
import { LoadingCircleIcon } from "../../../../common/AppIcons";
import Modal from "../../../../common/Modal";
import ConfirmPopup from "./components/confirmPopup";
import ModulesUnlockedStatus from "./components/modulesUnlockedStatus";
import { enableModule } from "../../../../../api/ApiV2";

export default function Settings({
  hoaId,
  data,
  className,
  baseData,
  setBaseData,
  popup_status,
  set_popup_status,
  get_hoa,
}) {
  const [loading, setLoading] = useState(false);

  const get_accounts = () => {
    getAccounts({ hoaId: hoaId, page_size: 300 })
      .then((res) => {
        console.log("base data:", res);
        setBaseData(res.results);
      })
      .catch(console.log);
  };

  useEffect(() => {
    get_accounts();
  }, []);

  const goto_opening_balance = () => {
    setLoading(true);
    enableModule({ external_id: hoaId, module: "ACCOUNTING" })
      .then((res) => {
        get_accounts();
        set_popup_status("OPENING_BALANCE");
        setLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });
  };

  const goto_complience = () => {
    setLoading(true);
    enableModule({ external_id: hoaId, module: "COMPLIANCE" })
      .then((res) => {
        setLoading(false);
        set_popup_status();
        navigate(`/hoa/${hoaId}/compliance_documents/`);
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });
  };

  return (
    <div className={className}>
      {!data && (
        <div className="flex items-center justify-center fixed inset-0 z-10 bg-opacity-60 bg-gray-700">
          <LoadingCircleIcon className="h-7" />
        </div>
      )}
      {!popup_status ||
      ["GOTO_OPENING_BALANCE", "GOTO_COMPLIANCE"].includes(popup_status) ? (
        <ModulesUnlockedStatus
          {...{ hoaEnabledModules: data?.enabled_features, set_popup_status }}
        />
      ) : baseData ? (
        popup_status === "EXPENSES_PAGE" || popup_status === "INVOICE_PAGE" ? (
          <div className="px-4 py-3">
            <Expense
              hoaId={hoaId}
              source="HOA_CREATION"
              prompt={popup_status}
              set_prompt={(val) => {
                set_popup_status(val);
              }}
              nextPage={() => {
                set_popup_status();
                navigate("/hoa/" + hoaId + "/accounting/home");
              }}
            />
          </div>
        ) : (
          <div className="px-6 py-5">
            <JournalEntry
              id="OPENING_BALANCE"
              source="OPENING_BALANCE"
              hoaId={hoaId}
              baseDataProp={baseData}
              baseJournalEntry={baseData.map((account) => {
                return {
                  accountId: account.id,
                  debit: "",
                  credit: "",
                  description: "",
                };
              })}
              title="Create Opening Balance Entry"
              description="Opening Balance Entry"
              successCB={() => set_popup_status("EXPENSES_PAGE")}
            />
          </div>
        )
      ) : (
        <div
          style={{ borderTopColor: "transparent" }}
          className="border-solid animate-spin m-auto rounded-full border-blue-400 border-8 h-64 w-64"
        ></div>
      )}
      <Modal
        show={
          popup_status === "GOTO_OPENING_BALANCE" ||
          popup_status === "GOTO_COMPLIANCE"
        }
        setShow={() => {}}
      >
        {loading ? (
          <div className="py-10 sm:py-16 w-full flex items-center justify-center">
            <LoadingCircleIcon className="h-7" />
          </div>
        ) : (
          <ConfirmPopup
            type={
              popup_status === "GOTO_OPENING_BALANCE"
                ? "ACCOUNTING"
                : "COMPLIANCES"
            }
            onSucces={() => {
              if (popup_status === "GOTO_OPENING_BALANCE")
                goto_opening_balance();
              else goto_complience();
            }}
            onClose={() => {
              set_popup_status();
              get_hoa();
            }}
          />
        )}
      </Modal>
    </div>
  );
}
