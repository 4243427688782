import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  CalenderIcon2,
  LeftArrowIcon,
  LoadingCircleIcon,
} from "../../../../../common/AppIcons";
import Popup from "../../../../../common/Popup";
import Reservation, { ReservationRow } from "./components/Reservation";
import {
  deleteReservation,
  getMyAmenityReservation,
} from "../../../../../../api/ApiV2";
import Paginator from "../../../../../../common/Paginator";
import { time_array } from "../../../amenities/data/time_array";

export default function ReservationList({ hoaId, closePage }) {
  const [reservations, set_reservations] = useState();
  const [loading, set_loading] = useState(false);
  const [delete_reservation_popup, set_delete_reservation_popup] = useState();
  const [page, setPage] = useState({
    offset: 0,
    totalCount: 0,
  });
  const [filters, set_filters] = useState({
    filter_start_time: null,
    filter_end_time: null,
    filter_date: null,
  });

  useEffect(() => {
    getReservation();
  }, [page.offset]);

  useEffect(() => {
    ((filters.filter_date &&
      filters.filter_end_time &&
      filters.filter_start_time) ||
      (!filters.filter_date &&
        !filters.filter_end_time &&
        !filters.filter_start_time)) &&
      getReservation();
  }, [filters.filter_date, filters.filter_end_time, filters.filter_start_time]);

  const getReservation = () => {
    set_loading(true);

    getMyAmenityReservation({
      hoaId,
      offset: page.offset,
      start_datetime:
        filters.filter_date &&
        filters.filter_end_time &&
        filters.filter_start_time
          ? new Date(
              new Date(new Date(filters.filter_date).setHours(0)).setMinutes(
                filters.filter_start_time
              )
            ).toISOString()
          : undefined,
      end_datetime:
        filters.filter_date &&
        filters.filter_end_time &&
        filters.filter_start_time
          ? new Date(
              new Date(new Date(filters.filter_date).setHours(0)).setMinutes(
                filters.filter_end_time
              )
            ).toISOString()
          : undefined,
    })
      .then((res) => {
        setPage((page) => ({ ...page, totalCount: res.count }));
        set_reservations(res?.results ?? []);
        set_loading(false);
        console.log(res);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res.readableMessage ?? res.message ?? "Error fetching reservations"
        );
      });
  };

  const delete_reservation = (amenityId, reservationId) => {
    set_loading(true);
    set_delete_reservation_popup();
    deleteReservation({ hoaId, amenityId, reservationId })
      .then((res) => {
        getReservation();
        set_loading(false);
        console.log(res);
        toast.success("Reservation deleted successfully");
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res.readableMessage ?? res.message ?? "Error deleting reservation"
        );
      });
  };

  return (
    <div className="mx-auto max-w-4xl flex flex-col items-start p-6">
      <div className="w-full flex flex-row justify-between flex-wrap items-start">
        <span
          className="font-semibold text-lg flex flex-row items-center gap-1"
          style={{ color: "#18181B" }}
        >
          <div
            className="cursor-pointer text-gray-800 hover:text-gray-600"
            onClick={() => closePage()}
          >
            <LeftArrowIcon className="h-5" />
          </div>
          Your Bookings
        </span>
        <div className="flex flex-grow flex-row items-end justify-end gap-x-4 gap-y-3 flex-wrap">
          <div className="flex flex-col gap-2">
            <span className="text-xs text-gray-600">Filter by time</span>
            <div className="flex flex-row items-center gap-1">
              <select
                style={{ fontWeight: "500" }}
                className="bg-gray-100 pl-2 pr-8 py-1 rounded border-none text-sm text-gray-700"
                value={filters?.filter_start_time ?? ""}
                onChange={(e) => {
                  let val = e.target.value;
                  set_filters((filters) => {
                    return { ...filters, filter_start_time: val };
                  });
                }}
              >
                <option hidden selected value={""}>
                  Select Time
                </option>
                {time_array
                  .slice(0, time_array.length - 1)
                  .map((ele, index) => (
                    <option key={index} value={ele.value}>
                      {ele.label}
                    </option>
                  ))}
              </select>
              -
              <select
                style={{ fontWeight: "500" }}
                className="bg-gray-100 pl-2 pr-8 py-1 rounded border-none text-sm text-gray-700"
                value={filters?.filter_end_time ?? ""}
                onChange={(e) => {
                  let val = e.target.value;
                  set_filters((filters) => {
                    return { ...filters, filter_end_time: val };
                  });
                }}
              >
                <option hidden selected value={""}>
                  Select Time
                </option>
                {time_array
                  .slice(1, time_array.length - 1)
                  .filter(
                    (val, index) =>
                      (filters?.filter_start_time ?? 0) < val.value
                  )
                  ?.map((ele, index) => (
                    <option key={index} value={ele.value}>
                      {ele.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-xs text-gray-600">Filter by date</span>

            <div className="flex flex-row items-center">
              <input
                type="date"
                style={{ fontWeight: "500" }}
                value={filters.filter_date ?? ""}
                onChange={(e) => {
                  let val = e.target.value;
                  set_filters((filters) => {
                    return { ...filters, filter_date: val };
                  });
                }}
                className="bg-gray-100 text-sm text-gray-700 border border-gray-100 focus:border-indigo-600 py-1 pl-2 rounded"
              />
              <CalenderIcon2
                style={{ height: "16px" }}
                className="relative right-5 bg-white pointer-events-none"
              />
            </div>
          </div>
          <button
            onClick={() => {
              set_filters({
                filter_start_time: null,
                filter_end_time: null,
                filter_date: null,
              });
            }}
            className="px-3 py-1.5 text-sm bg-indigo-600 text-white whitespace-pre rounded hover:opacity-70 flex flex-row gap-2 items-center"
          >
            Clear Filters
          </button>
        </div>
      </div>

      {loading ? (
        <div className="w-full py-20 flex items-center justify-center bg-white">
          <LoadingCircleIcon className="h-9 mx-auto my-20" />
        </div>
      ) : (
        <div className="mt-7 px-4 flex flex-col gap-4 w-full">
          {(!reservations || reservations.length === 0) && (
            <span className="mt-5 text-sm w-full text-center text-gray-500 py-8">
              No Reservations Found
            </span>
          )}
          <Reservation
            hoaId={hoaId}
            set_delete_reservation_popup={set_delete_reservation_popup}
            reservation_detail={reservations}
          />
          <Paginator
            currentPage={page.offset / 20 + 1}
            totalPage={page.totalCount}
            entryPerPage={20}
            onChange={(pageNo) => {
              setPage({ ...page, offset: (pageNo - 1) * 20 });
            }}
          />
        </div>
      )}
      <Popup
        show={delete_reservation_popup ? true : false}
        setShow={() => set_delete_reservation_popup()}
      >
        {delete_reservation_popup && (
          <div className="px-5 py-6 rounded bg-white flex flex-col">
            <span className="mb-4 font-semibold text-xl">
              Delete Reservation
            </span>

            <ReservationRow
              reservation={delete_reservation_popup}
              hoaId={hoaId}
            />
            <div className="mt-4 flex flex-row items-center gap-3 justify-end">
              <button
                className="text-sm text-gray-500 hover:text-gray-600 border-2 border-gray-500 hover:border-gray-600 font-semibold px-5 py-1.5 rounded"
                onClick={() => set_delete_reservation_popup()}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  delete_reservation(
                    delete_reservation_popup.amenity.id,
                    delete_reservation_popup.id
                  );
                }}
                className="bg-red-500 border-2 border-red-500 rounded px-5 py-1.5 text-white hover:opacity-70 text-sm"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}
