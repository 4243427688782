import Paginator from "../../../../../common/Paginator";
import { LoadingCircleIcon } from "../../../../common/AppIcons";
import { dateStringFormat } from "../../../../../utils/DateUtils";

export default function Transactions({
  transactions,
  loading,
  page,
  set_page,
  set_transaction_popup,
  transactionLimitPerPage,
}) {
  const open_transaction_popup = (transaction) => {
    console.log("popup data", transaction);
    transaction.type === "BILL"
      ? set_transaction_popup({
          popup: true,
          type: "BILL_NORMAL",
          transaction: transaction,
        })
      : transaction.type === "CHECK"
      ? set_transaction_popup({
          popup: true,
          type: "CHECK_NORMAL",
          transaction: transaction,
        })
      : set_transaction_popup({
          popup: true,
          type: "EXPENSE",
          transaction: transaction,
        });
  };

  return (
    <div className="my-8">
      <div className="grid grid-cols-5 gap-2 px-5 sm:px-10 mt-3 mb-2 text-sm text-gray-600 font-semibold">
        {[
          { title: "Name", class: "" },
          { title: "Date", class: "" },
          { title: "Description", class: "" },
          { title: "Type", class: "" },
          { title: "Amount Outstanding", class: "w-full text-right" },
        ].map((value) => {
          return (
            <span key={value.title} className={value.class}>
              {value.title}
            </span>
          );
        })}
      </div>
      <div className="mt-3 overflow-auto rounded-md bg-white">
        {loading ? (
          <div className="w-full flex justify-center items-center my-8">
            <LoadingCircleIcon className="h-7 text-blue-600" />
          </div>
        ) : transactions.length === 0 ? (
          <div className="text-center font-semibold text-gray-600 my-8">
            No Transaction Available
          </div>
        ) : (
          transactions.map((value) => {
            return (
              <div
                key={value.id}
                className="grid grid-cols-5 gap-2 border-b border-gray-300 text-gray-800 text-sm hover:bg-gray-200 cursor-pointer px-5 sm:px-10 py-3"
                onClick={() => {
                  open_transaction_popup(value);
                }}
              >
                <span className="break-words">
                  {value.vendor_name ?? value.merchant_name}
                </span>
                <span className="">
                  {dateStringFormat(value?.created_date)}
                </span>
                <span className="break-words">{value.description}</span>
                <span className="">
                  {value.type === "BILL"
                    ? "Bill"
                    : value.type === "CHECK"
                    ? "Check"
                    : "Expense"}
                </span>
                <div className="w-full flex flex-row-reverse">
                  <span className="break-all">
                    {value.type === "JOURNAL"
                      ? "---"
                      : "$" + value.computed_transaction_amount}
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>
      <Paginator
        currentPage={page.current + 1}
        totalPage={page.total}
        entryPerPage={transactionLimitPerPage}
        onChange={(pageNo) => {
          set_page({ ...page, current: pageNo - 1 });
        }}
      />
    </div>
  );
}
