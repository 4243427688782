import { navigate } from "raviger";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { payForCardPreData, savePlaid } from "../../api/Api";
import PayButton from "../accounting/common/components/PayButton";
import AccountList from "./payment_popups/AccountList";
import PaymentFailed from "./payment_popups/PaymentFailed";
import PaymentProcessing from "./payment_popups/PaymentProcessing";
import RequiresAction from "./payment_popups/RequiresAction";
import SelectPayOption from "./payment_popups/SelectPayOption";
import Success from "./payment_popups/Success";
import { userAtom } from "../../store/UserAtom";
import { adminTypes } from "../../common/data/adminTypes";

export default function PaymentSection({
  hoaId,
  payment_method_popup,
  set_payment_method_popup,
  base_plaid_data_user,
  plaid_data_helpsquad,
  plaid_data_hoa,
  accounts,
  update_data,
  amountFormat,
  set_loading,
  selectedTab,
  payButtonRef,
}) {
  const [plaid_data_user, set_plaid_data_user] = useState(base_plaid_data_user);
  const [payment_data, set_payment_data] = useState({
    //method:'ACH','CARD','saved_bank','saved_card'
  });
  const [payments_pre_data, set_payments_pre_data] = useState({
    hoa: { plaid: null, stripe: null },
    helpsquad: {},
    helpsquad_from_hoa: {},
  });
  const payButtonACHOwnerHoaRef = useRef(null);
  const payButtonACHRef = useRef(null);

  const [user] = useRecoilState(userAtom);

  const userPrivilage = adminTypes.includes(user.selectedUserType);

  useEffect(() => {
    // set_loading(true);
    get_payments_pre_data();
  }, []);

  useEffect(() => {
    if (payment_method_popup?.popup === "SUCCESS") get_payments_pre_data();
  }, [payment_method_popup?.popup]);

  useEffect(() => {
    if (userPrivilage === false)
      set_payment_method_popup({
        ...payment_method_popup,
        popup: "PAY_OPTIONS",
      });
  }, [userPrivilage]);

  useEffect(() => {
    set_plaid_data_user(base_plaid_data_user);
  }, [base_plaid_data_user]);

  const reload_pay_button = (res) => {
    let temp_plaid_data_user = plaid_data_user;
    set_loading(true);
    setTimeout(() => {
      set_loading(false);
      set_plaid_data_user(res ?? temp_plaid_data_user);
    }, 1000);
  };
  const get_payments_pre_data = () => {
    //   getStripePaymentSetup({ hoaId })
    //     .then((res) => {
    //       console.log("payments pre data:", res);
    //       set_payments_pre_data((payments_pre_data) => {
    //         return {
    //           ...payments_pre_data,
    //           hoa: { ...payments_pre_data?.hoa, stripe: res },
    //         };
    //       });
    //       set_loading(false);
    //     })
    //     .catch((res) => {
    //       console.log(res);
    //       set_loading(false);
    //       toast.error(res?.readableMessage ?? res?.message ?? "Error fetching plaid data");
    //     });
    //   getStripePaymentSetupHelpsquad()
    //     .then((res) => {
    //       console.log("payments pre data:", res);
    //       set_payments_pre_data((payments_pre_data) => {
    //         return {
    //           ...payments_pre_data,
    //           helpsquad: { ...payments_pre_data?.helpsquad, stripe: res },
    //         };
    //       });
    //       set_loading(false);
    //     })
    //     .catch((res) => {
    //       console.log(res);
    //       set_loading(false);
    //       toast.error(res?.readableMessage ?? res?.message ?? "Error fetching plaid data");
    //     });
    //   getplaidDataHoa({ hoaId })
    //     .then((res) => {
    //       console.log("payments pre data:", res);
    //       set_payments_pre_data((payments_pre_data) => {
    //         return {
    //           ...payments_pre_data,
    //           hoa: { ...payments_pre_data?.hoa, plaid: res },
    //         };
    //       });
    //       set_loading(false);
    //     })
    //     .catch((res) => {
    //       console.log(res);
    //       set_loading(false);
    //     toast.error(res?.readableMessage ?? res?.message ?? "Error fetching plaid data");
    //     });
    //   getplaidDataHelpsquad({ hoaId })
    //     .then((res) => {
    //       console.log("payments pre data:", res);
    //       set_payments_pre_data((payments_pre_data) => {
    //         return {
    //           ...payments_pre_data,
    //           helpsquad: { ...payments_pre_data?.helpsquad, plaid: res },
    //         };
    //       });
    //       set_loading(false);
    //     })
    //     .catch((res) => {
    //       console.log(res);
    //       set_loading(false);
    //      toast.error(res?.readableMessage ?? res?.message ?? "Error fetching plaid data");
    //     });
    //   getplaidDataHelpsquadFromHoa({ hoaId })
    //     .then((res) => {
    //       console.log("payments pre data:", res);
    //       set_payments_pre_data((payments_pre_data) => {
    //         return {
    //           ...payments_pre_data,
    //           helpsquad_from_hoa: {
    //             ...payments_pre_data?.helpsquad_from_hoa,
    //             plaid: res,
    //           },
    //         };
    //       });
    //       set_loading(false);
    //     })
    //     .catch((res) => {
    //       console.log(res);
    //       set_loading(false);
    //       toast.error(res?.readableMessage ?? res?.message ?? "Error fetching plaid data");
    //     });
  };

  const pay_Service = () => {
    set_loading(true);

    payForCardPreData({
      hoaId: hoaId,
      receiver: selectedTab === "HOA" ? "hoa" : "helpsquad",
    })
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          navigate(res.url);
          set_loading(false);
        }, 10);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error paying for card"
        );
      });
  };

  const save_plaid = ({ metadata, token }) => {
    console.log(metadata, token);
    set_loading(true);
    savePlaid(
      metadata?.account?.id && token
        ? {
            hoaId: hoaId,
            account_id: metadata?.account?.id,
            public_token: token,
            account: metadata?.account,
            receiver: "hoa",
          }
        : { hoaId: hoaId, public_token: token, receiver: "hoa" }
    )
      .then((res) => {
        console.log("used plaid_data_user", res);
        if (res.message === "success") {
          set_payment_method_popup({
            ...payment_method_popup,
            popup: "SUCCESS",
          });
        } else {
          reload_pay_button(res);
        }
        set_loading(false);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(
          res?.readableMessage ?? res?.message ?? "Error saving plaid data"
        );
      });
  };

  const on_next = () => {
    if (payment_data?.method === "ACH") {
      if (userPrivilage)
        set_payment_method_popup({
          ...payment_method_popup,
          popup: "ACCOUNTS",
        });
      else {
        if (selectedTab === "HOA") payButtonACHOwnerHoaRef?.current.click();
        else payButtonACHRef?.current.click();
        // fetch_plaid_for_owner();
      }
    } else if (payment_data?.method === "CARD") pay_Service();
  };

  return (
    <div className={"w-full px-8"}>
      {payment_method_popup.popup === "PAY_OPTIONS" ? (
        <SelectPayOption
          update_data={update_data}
          hoaId={hoaId}
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
          payment_data={payment_data}
          set_payment_data={set_payment_data}
          payments_pre_data={
            userPrivilage
              ? payments_pre_data?.helpsquad_from_hoa
              : selectedTab === "HOA"
              ? payments_pre_data?.hoa
              : payments_pre_data?.helpsquad
          }
          on_next={on_next}
          plaid_data={plaid_data_helpsquad}
          set_loading={set_loading}
          payButtonACHRef={payButtonACHRef}
        />
      ) : (payment_method_popup.popup === "ACCOUNTS" ||
          payment_method_popup.popup === "ACH_PAY") &&
        userPrivilage ? (
        <AccountList
          selectedTab={selectedTab}
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
          accounts={accounts}
          amountFormat={amountFormat}
          hoaId={hoaId}
          plaid_data={plaid_data_hoa}
          set_loading={set_loading}
          payButtonRef={payButtonRef}
        />
      ) : payment_method_popup.popup === "SUCCESS" ? (
        <Success
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
        />
      ) : payment_method_popup.popup === "PROCESSING" ? (
        <PaymentProcessing
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
        />
      ) : payment_method_popup.popup === "FAILED" ? (
        <PaymentFailed
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
        />
      ) : payment_method_popup.popup === "REQUIRES_ACTION" ? (
        <RequiresAction
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
        />
      ) : null}

      <PayButton
        ref={payButtonACHOwnerHoaRef}
        disable={!plaid_data_user}
        hoaId={hoaId}
        reload_pay_button={reload_pay_button}
        data={plaid_data_user}
        on_close={() => {
          set_loading(false);
        }}
        payment_api_call={save_plaid}
        className="h-0 overflow-hidden"
      />
    </div>
  );
}
