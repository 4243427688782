import { useState } from "react";

import {
  CopyIcon,
  DeleteIcon,
  ExclamationIcon,
  HouseIcon,
  PlusInCircleIcon,
} from "../../../../../common/AppIcons";
import Popup from "../../../../../common/Popup";
import MemberForm from "./MemberForm";
import CopyUnitDataDropdown from "./CopyUnitDataDropdown";
import Input from "../../../../../common/Form/Input";

export default function UnitForm({
  memberWarning,
  buildingId,
  hoaData,
  setHoaData,
}) {
  const [userPopup, setUserPopup] = useState();
  const [copyButton, setCopyButton] = useState(null);
  const [copyDropdown, setCopyDropdown] = useState(null);

  const setUnits = (units) => {
    setHoaData((hoaData) => {
      return {
        ...hoaData,
        units: units,
      };
    });
  };

  return (
    <div className="mt-4 pl-8 w-full">
      <div
        className={
          "w-full pr-9 grid gap-4 " +
          (hoaData.type === "HOA" ? "grid-cols-5" : "grid-cols-4")
        }
      >
        {["Unit Number", "Unit Size (in sqft)", "Beds", "Baths"].map(
          (ele, eleIndex) => (
            <span key={eleIndex} className="text-gray-500 text-sm text-center">
              {ele}
            </span>
          )
        )}
        {hoaData.type === "HOA" && (
          <span className="text-red-500 text-sm text-center">Dues</span>
        )}
      </div>
      <div className="mt-5 w-full flex flex-col">
        {hoaData.units
          ?.filter((unit) => unit.address === buildingId)
          .map((unit, unitIndex, unitArray) => (
            <div
              key={unitIndex}
              onMouseEnter={() => setCopyButton(unit)}
              onMouseLeave={() => setCopyButton(null)}
              className="flex flex-col"
            >
              <div key={unitIndex} className="flex flex-row items-center">
                <div
                  className={
                    "w-full grid gap-4 " +
                    (hoaData.type === "HOA" ? "grid-cols-5" : "grid-cols-4")
                  }
                >
                  <div
                    style={{ backgroundColor: "#F0F2FB", color: "#130951" }}
                    className="px-4 py-2 rounded text-sm truncate flex flex-row items-center justify-center gap-2"
                  >
                    <HouseIcon className="h-4" /> {unit.unit_number}
                  </div>
                  {[
                    ...[
                      { value: "unit_size", type: "text" },
                      { value: "beds", type: "number" },
                      { value: "baths", type: "number" },
                      ,
                    ],
                    ...[
                      hoaData.type === "HOA"
                        ? { value: "monthly_dues", type: "currency" }
                        : {},
                    ],
                  ]
                    .filter((val) => val?.value)
                    .map((field, fieldIndex) => (
                      <Input
                        key={fieldIndex}
                        value={unit?.[field.value] ?? ""}
                        type={field.type}
                        onChange={(value) => {
                          setUnits(
                            unitArray.map((unit1) => {
                              return unit1.id === unit.id &&
                                typeof Number(value) === "number" &&
                                isFinite(Number(value))
                                ? {
                                    ...unit1,
                                    [field.value]: value,
                                  }
                                : unit1;
                            })
                          );
                        }}
                        className={
                          "rounded px-4 py-2 text-sm bg-gray-100 " +
                          (field.value === "units"
                            ? "border border-gray-300"
                            : "")
                        }
                      />
                    ))}
                </div>
                <div className="relative flex flex-col items-start">
                  <button
                    onClick={() => {
                      setCopyDropdown(unit);
                    }}
                    className={
                      "text-gray-500 hover:text-gray-700 mx-3 " +
                      (copyButton === unit ? "" : "invisible")
                    }
                  >
                    <CopyIcon className="h-4" />
                  </button>
                  <CopyUnitDataDropdown
                    {...{
                      hoaData,
                      setHoaData,
                      unit,
                      copyDropdown,
                      setCopyDropdown,
                    }}
                  />
                </div>
              </div>
              <div className="mt-4 pr-9 flex flex-row items-center gap-3 flex-wrap">
                <button
                  className="flex flex-row items-center gap-2 text-sm text-gray-500 border border-gray-400 hover:opacity-70 px-2 py-1.5 rounded"
                  onClick={() => {
                    setUserPopup({ unit, data: null });
                  }}
                >
                  <PlusInCircleIcon className="" style={{ height: "15px" }} />{" "}
                  Add member
                </button>
                {hoaData.members?.map((member, memberIndex) =>
                  member.unit !== unit.id ? (
                    <></>
                  ) : (
                    <div className="flex flex-row">
                      <button
                        key={memberIndex}
                        onClick={() => {
                          setUserPopup({
                            unit,
                            readonly: member.id ? true : false,
                            data: member,
                            index: memberIndex,
                            warnings:
                              memberWarning?.[
                                hoaData.members
                                  ?.filter((val) => !val.id)
                                  ?.findIndex(
                                    (ele) =>
                                      ele.user.email === member.user.email &&
                                      ele.unit === member.unit &&
                                      ele.user.phone === member.user.phone
                                  )
                              ],
                          });
                        }}
                        className={
                          "flex flex-row items-center gap-2 px-3 py-1.5 bg-blue-50 rounded hover:bg-blue-100"
                        }
                      >
                        <div className="h-6 w-6 text-xs uppercase flex flex-row items-center justify-center rounded-full bg-indigo-600 text-white font-semibold">
                          {member.user?.first_name?.charAt(0) ?? ""}
                          {member.user?.last_name?.charAt(0) ?? ""}
                        </div>
                        <span className="text-sm">
                          {member.user?.first_name ?? ""}{" "}
                          {member.user?.last_name ?? ""}
                        </span>
                        {Object.keys(
                          memberWarning?.[
                            hoaData.members
                              ?.filter((val) => !val.id)
                              ?.findIndex(
                                (ele) =>
                                  ele.user.email === member.user.email &&
                                  ele.unit === member.unit &&
                                  ele.user.phone === member.user.phone
                              )
                          ] ?? {}
                        ).length > 0 ? (
                          <ExclamationIcon className="h-3 text-red-500" />
                        ) : (
                          !member.id && (
                            <div className="h-1.5 w-1.5 bg-yellow-400 rounded-full" />
                          )
                        )}
                      </button>
                      {!member.id && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setHoaData((hoaData) => {
                              return {
                                ...hoaData,
                                members: hoaData.members.filter(
                                  (ele) => ele !== member
                                ),
                              };
                            });
                          }}
                          className="text-red-500 hover:text-red-600 px-2 rounded-r bg-blue-50 hover:bg-blue-100"
                        >
                          <DeleteIcon className="h-4" />
                        </button>
                      )}
                    </div>
                  )
                )}
              </div>
              {hoaData.units?.find((ele) => ele.id === buildingId?.id)?.length -
                1 !==
                unitIndex && (
                <div className="w-full my-4 pr-4">
                  <div className="w-full h-px bg-gray-100 rounded" />
                </div>
              )}
            </div>
          ))}
      </div>
      <Popup show={userPopup ? true : false} setShow={setUserPopup}>
        {userPopup && (
          <MemberForm
            {...{
              readonly: userPopup?.readonly,
              unit: userPopup?.unit,
              data: userPopup?.data,
              memberIndex: userPopup?.index,
              warnings: userPopup?.warnings,
              setHoaData,
              closePopup: () => {
                setUserPopup();
              },
            }}
          />
        )}
      </Popup>
    </div>
  );
}
