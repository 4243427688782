import React, { useState } from "react";
import {
  LeftArrowIcon,
  LoadingAnimated,
  SentIcon,
} from "../../../common/AppIcons";
import CommentSection from "./CommentSection";
import { commentOnDocument } from "../../../../api/Api";
import { dateStringFormat } from "../../../../utils/DateUtils";

const profile_pic = ({ pic, name, small }) => {
  if (pic)
    return (
      <img
        src={pic}
        className={
          "rounded-full object-cover border " + (small ? "h-5 w-5" : "h-8 w-8")
        }
      />
    );
  else
    return (
      <div
        className={
          "flex items-center justify-center border border-gray-400 rounded-full bg-indigo-600 font-semibold text-white uppercase " +
          (small ? "h-5 w-5 text-sm" : "h-8 w-8 text-xl")
        }
      >
        {name ? name.charAt(0) : "c"}
      </div>
    );
};

export default function Comment({
  key,
  comment,
  comments,
  hoaId,
  documentId,
  get_document_data,
}) {
  const [reply, set_reply] = useState("");
  const [show_reply, set_show_reply] = useState(false);
  const [show_reply_input, set_show_reply_input] = useState(false);
  const [loading, set_loading] = useState(false);

  const find_replys = () =>
    comments.filter((ele) => ele.object_id === comment.id);

  const sent_reply = () => {
    if (reply !== "") {
      set_loading(true);

      commentOnDocument(hoaId, documentId, {
        title: "Test",
        description: reply,
        object_id: comment.id,
        comment_type: "COMMENT",
      })
        .then((_) => {
          get_document_data();
          set_reply("");
          set_show_reply_input(false);
          set_loading(false);
        })
        .catch((res) => {
          console.log(res);
          set_loading(false);
        });
    }
  };

  return (
    <div key={key} className="flex flex-row items-start gap-4 p-1">
      {profile_pic({ name: comment.first_name ?? comment.last_name })}

      <div className="flex flex-col flex-grow">
        <div className="flex flex-row items-end gap-2">
          <span className="font-semibold text-sm">
            {comment.first_name ?? ""} {comment.last_name ?? ""}
          </span>
          <span className="text-xs text-gray-500 relative bottom-px">
            {new Date(comment.created_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
            {"  "}
            <span className="ml-2">
              {new Date(comment.created_at).toLocaleDateString() ===
              new Date().toLocaleDateString()
                ? ""
                : dateStringFormat(comment.created_at)}
            </span>
          </span>
        </div>
        <div className="flex flex-row gap-2 justify-between items-center">
          <span className="text-gray-800">{comment.description ?? ""}</span>
          <button
            onClick={() => {
              if (show_reply_input) set_reply("");
              set_show_reply_input((show_reply_input) => !show_reply_input);
            }}
            className={
              "border-0 outline-none focus:outline-none font-semibold text-xs " +
              (show_reply_input
                ? "hidden"
                : "text-blue-300 hover:text-blue-400")
            }
          >
            Reply
          </button>
        </div>

        <div className="flex flex-col gap-2">
          <div
            className={
              "w-full flex flex-row items-center gap-2 " +
              (show_reply_input ? "h-min" : "h-0 invisible")
            }
          >
            <input
              value={reply}
              onChange={(e) => set_reply(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && sent_reply()}
              placeholder="Reply..."
              className="w-full p-1 text-sm border-b border-blue-300 focus:border-blue-500 focus:outline-none bg-gray-100"
            />
            <button
              onClick={() => {
                if (show_reply_input) set_reply("");
                set_show_reply_input((show_reply_input) => !show_reply_input);
              }}
              className={
                "mt-2 flex items-center justify-center cursor-pointer h-8 w-8 bg-gray-200 font-bold rounded-full hover:bg-gray-300 text-red-300 hover:text-400"
              }
            >
              <span className="relative bottom-px">&#10005;</span>
            </button>
            <div
              onClick={sent_reply}
              className={
                "mt-2 flex items-center justify-center cursor-pointer h-8 w-8 bg-gray-200 rounded-full text-blue-600 hover:text-blue-700 hover:bg-gray-300"
              }
            >
              {loading ? (
                <LoadingAnimated className="w-5 h-5 ml-1" />
              ) : (
                <SentIcon className="w-5 h-5 ml-1" />
              )}
            </div>
          </div>
          {find_replys() &&
            find_replys().length > 0 &&
            (show_reply ? (
              <div className="flex flex-col">
                <div
                  onClick={() => set_show_reply(false)}
                  className="mb-1 border-b border-gray-400 hover:border-blue-300 text-gray-500 hover:text-blue-400 cursor-pointer flex flex-row items-center text-sm font-semibold gap-1"
                >
                  <LeftArrowIcon className="h-4 transform rotate-90" /> Hide{" "}
                  {find_replys()?.length}{" "}
                  {find_replys().length === 1 ? " Reply" : " Replies"}
                </div>
                <CommentSection
                  comments={comments.filter(
                    (ele) => ele.object_id !== comment.id
                  )}
                  comments_to_display={find_replys()}
                  hoaId={hoaId}
                  documentId={documentId}
                  get_document_data={get_document_data}
                  show_reply_input
                />
              </div>
            ) : (
              <div
                onClick={() => set_show_reply(true)}
                className="flex flex-row gap-4 items-center cursor-pointer hover:opacity-80"
              >
                <div className="flex flex-row items-center gap-0">
                  {find_replys().map((ele, ele_index) => (
                    <div key={ele_index} className="w-3 overflow-visible">
                      {profile_pic({
                        name: ele.first_name ?? ele.last_name,
                        small: true,
                      })}
                    </div>
                  ))}
                </div>
                <div className="font-semibold text-gray-500 text-sm">
                  {find_replys()?.length}{" "}
                  {find_replys().length === 1 ? " Reply" : " Replies"}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
