import React from "react";

import {  EmailVerificationFailedIcon } from "../../common/AppIcons";

export default function EmailVerificationFailed() {
  return (
    <div className="w-full min-h-screen flex items-center justify-center bg-gray-100">
      <div className="border border-gray-100 shadow bg-white rounded px-12 py-10 flex flex-col items-center">
        <EmailVerificationFailedIcon className="h-20" />
        <span className="mt-4 text-lg font-semibold">
        Hmm… that link doesn't look valid
        </span>
        <div className="mt-4 text-sm flex flex-row items-center flex-wrap gap-1">
          <span className="text-gray-600">Please confirm that  </span>
          <span className="">joelsajichacko@gmail.com</span>
        </div>
        <div className="text-sm flex flex-row items-center flex-wrap gap-1">
          <span className="text-gray-600">
          is an email address for the user
          </span>
          <span className="">Joel</span>
        </div>
        <button
          style={{ backgroundColor: "#2A32FD" }}
          className="mt-4 text-white text-sm px-8 py-2 font-semibold rounded hover:opacity-70"
        >
          Try Again
        </button>
      </div>
    </div>
  );
}
