import React, { useEffect, useState } from "react";

import { getAccounts, getAccountsSummary } from "../../../../api/ApiV2";
import Shortcuts from "./components/shortcuts";
import AccountsBalance from "./components/AccountsBalance";
import BalanceCard from "../../common/components/BalanceCard";
import { ShortcutToPopup } from "./router/ShortcutToPopup";

const entryPerPage = 25;
export default function Home({ hoaId }) {
  const [shortcut, setShortcut] = useState("");
  const [accounts, setAccounts] = useState();
  const [accountsPage, setAccountsPage] = useState({ current: 0, total: 0 });
  const [payables_receivables, setPayables_receivables] = useState();

  useEffect(() => {
    fetchAccounts();
  }, [accountsPage.current]);

  useEffect(() => {
    if (shortcut === "" || !shortcut) {
      fetchAccountsSummary();
      fetchAccounts();
    }
  }, [shortcut]);

  const fetchAccountsSummary = () => {
    getAccountsSummary({ hoaId })
      .then((res) => {
        console.log(res);
        setPayables_receivables({
          accounts_payable: res.accounts_payable?.current_amount,
          accounts_receivable: res.accounts_receivable?.current_amount,
        });
      })
      .catch((_) => {});
  };

  const fetchAccounts = () => {
    getAccounts({
      hoaId,
      page: accountsPage.current * entryPerPage,
      page_size: entryPerPage,
      bank_account: "True",
    })
      .then((res) => {
        setAccountsPage({ ...accountsPage, total: res.count });
        setAccounts(res.results);
      })
      .catch((_) => {});
  };

  return (
    <div className="flex flex-col gap-7">
      <div className="mt-5 grid gap-4 grid-cols-2 md:grid-cols-4">
        {[
          {
            label: "Accounts Payable",
            value: payables_receivables?.accounts_payable,
            orientation: false,
          },
          {
            label: "Accounts Receivable",
            value: payables_receivables?.accounts_receivable,
            orientation: true,
          },
        ].map((value, index) => (
          <BalanceCard
            key={index}
            label={value.label}
            balance={value.value}
            orientation={value.orientation}
          />
        ))}
      </div>

      <Shortcuts setShortcut={setShortcut} hoaId={hoaId} />

      <div className="w-full flex flex-row gap-4">
        <AccountsBalance
          entryPerPage={entryPerPage}
          hoaId={hoaId}
          accounts={accounts}
          accountsPage={accountsPage}
          setAccountsPage={setAccountsPage}
        />
      </div>
      <ShortcutToPopup
        hoaId={hoaId}
        shortcut={shortcut}
        setShortcut={setShortcut}
      />
    </div>
  );
}
