import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "raviger";

import { ProfilePic } from "../../community/pages/boardMeeting/constants";
import {
  arch_review_status,
  arch_review_resolutions,
  arch_review_plan_type,
} from "../constants";
import { LeftArrowIcon, PlusIcon } from "../../common/AppIcons";
import SelectHeadings from "../../common/Form/SelectHeadings";
import { dateStringFormat } from "../../../utils/DateUtils";
import { userAtom } from "../../../store/UserAtom";
import { adminTypes } from "../../../common/data/adminTypes";

const headings = ["Your Plans", "Review Plans"];

export default function ARList({
  hoaId,
  create_ar,
  your_documents,
  review_documents,
  open_AR_details,
  open_AR_review,
}) {
  const [filtered_review_documents, set_filtered_review_documents] = useState(
    []
  );
  const [filtered_your_documents, set_filtered_your_documents] = useState([]);
  const [search_params, set_search_params] = useState({
    status: "",
    resolution: "",
  });
  const [page, set_page] = useState("Your Plans");
  // Your Plans, Review Plans

  const [user] = useRecoilState(userAtom);
  const admin = adminTypes.includes(user.selectedUserType);

  useEffect(() => {
    let temp_filtered_your_documents = your_documents;

    if (search_params.status !== "" && search_params.status !== "ALL")
      temp_filtered_your_documents = temp_filtered_your_documents.filter(
        (val) => {
          return search_params.status === val.status;
        }
      );

    if (search_params.resolution !== "" && search_params.resolution !== "ALL")
      temp_filtered_your_documents = temp_filtered_your_documents.filter(
        (val) => {
          return search_params.resolution === val.resolution;
        }
      );

    set_filtered_your_documents(temp_filtered_your_documents);
  }, [search_params, your_documents]);

  useEffect(() => {
    let temp_filtered_review_documents = review_documents;

    if (search_params.status !== "" && search_params.status !== "ALL")
      temp_filtered_review_documents = temp_filtered_review_documents.filter(
        (val) => {
          return search_params.status === val.status;
        }
      );

    if (search_params.resolution !== "" && search_params.resolution !== "ALL")
      temp_filtered_review_documents = temp_filtered_review_documents.filter(
        (val) => {
          return search_params.resolution === val.resolution;
        }
      );

    set_filtered_review_documents(temp_filtered_review_documents);
  }, [search_params, review_documents]);

  useEffect(() => {
    if (!admin) set_page("Your Plans");
  }, [user.selectedUserType]);

  return (
    <div className="flex flex-col">
      <div className="ml-4 mt-4 mb-8">
        <h3 className="flex flex-row items-center gap-2 text-lg leading-6 font-medium text-gray-900">
          <Link
            className="flex items-center relative top-px"
            href={"/hoa/" + hoaId + "/compliance_documents/"}
          >
            <LeftArrowIcon className="h-5 w-5 text-gray-700 hover:text-gray-500 cursor-pointer" />
          </Link>
          <span className="font-bold text-blue-600 text-xl">
            Architectural Review
          </span>
        </h3>
        <p className="mt-1 text-sm text-gray-500"></p>
      </div>
      {admin && (
        <div className="flex flex-col mt-4 mb-8">
          <SelectHeadings
            headings={headings.map((val) => {
              return { label: val, value: val };
            })}
            selected={page}
            onChange={(val) => {
              set_page(val);
            }}
            base_heading_width={120}
            className="relative top-1"
          />
          <hr />
        </div>
      )}
      <div className="px-4 mt-6 flex flex-row gap-10 items-center">
        <select
          value={search_params.status}
          onChange={(e) =>
            set_search_params({ ...search_params, status: e.target.value })
          }
          className="px-3 py-2 rounded bg-gray-100 border-none text-gray-700"
        >
          <option value="" hidden selected disabled>
            Status
          </option>
          {[
            {
              value: "ALL",
              label: "All",
            },
            ...arch_review_status,
          ].map((status, status_index) => (
            <option key={status_index} value={status.value}>
              {status.label}
            </option>
          ))}
        </select>

        <select
          value={search_params.resolution}
          onChange={(e) =>
            set_search_params({
              ...search_params,
              resolution: e.target.value,
            })
          }
          className="px-3 py-2 rounded bg-gray-100 border-none text-gray-700"
        >
          <option value="" hidden selected disabled>
            Resolution
          </option>
          {[
            {
              value: "ALL",
              label: "All",
            },
            ...arch_review_resolutions.filter((val) => val.value !== ""),
          ].map((status, status_index) => (
            <option key={status_index} value={status.value}>
              {status.label}
            </option>
          ))}
        </select>

        <div className="flex flex-grow justify-end">
          {user.selectedUserType !== "TENANT" && page === "Your Plans" && (
            <button
              className="relative flex flex-row gap-3 items-center px-4 py-2 border border-indigo-600 hover:border-indigo-400 shadow-sm text-sm font-medium rounded-md text-indigo-600 hover:text-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={create_ar}
            >
              <PlusIcon className="h-4" />
              New Review
            </button>
          )}
        </div>
      </div>
      <div className="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <div className="p-1 min-w-full">
              <div className="py-3 px-5 bg-gray-100 rounded-md min-w-full grid grid-cols-6 gap-4">
                {[
                  {
                    label: "Name",
                    className: "",
                  },
                  {
                    label: "Title",
                    className: "",
                  },
                  {
                    label: "Status",
                    className: "flex justify-center",
                  },
                  {
                    label: "Plan type",
                    className: "",
                  },
                  {
                    label: "Resolution",
                    className: "",
                  },
                  {
                    label: "Deadline",
                    className: "text-right",
                  },
                ].map((head) => (
                  <th
                    scope="col"
                    className={
                      "text-left text-sm font-semibold text-gray-600 tracking-wider " +
                      head.className
                    }
                  >
                    {head.label}
                  </th>
                ))}
              </div>
              {(page === "Your Plans"
                ? filtered_your_documents
                : filtered_review_documents
              ).map((review, reviewIdx) => (
                <button
                  // href={`/hoa/${hoaId}/compliance_your_documents/${documentType}/${review.doc_id}`}
                  onClick={() => {
                    if (page === "Your Plans") open_AR_details(review.id);
                    else if (page === "Review Plans" && admin)
                      open_AR_review(review.id);
                  }}
                  key={review.email}
                  className={
                    "mt-3 py-3 px-5 bg-gray-100 rounded-md min-w-full grid grid-cols-6 gap-4 items-center text-gray-700 text-sm border border-gray-100 hover:border-indigo-400 hover:text-indigo-700 hover:bg-white cursor-pointer duration-200"
                  }
                >
                  <div className="whitespace-nowrap truncate flex flex-row gap-2 items-center">
                    <ProfilePic
                      className="h-8 w-8 text-sm shadow-none"
                      member={review.created_by}
                    />
                    {review.created_by?.first_name ?? ""}{" "}
                    {review.created_by?.last_name ?? ""}
                  </div>
                  <span className="text-left pl-1 whitespace-nowrap truncate">
                    {review.title ?? ""}
                  </span>
                  <div className="flex justify-center whitespace-nowrap truncate">
                    <span
                      className={
                        "px-5 py-2 rounded-full " +
                        arch_review_status.find(
                          (val) => val.value === (review.status ?? "PENDING")
                        )?.className
                      }
                    >
                      {
                        arch_review_status.find(
                          (val) => val.value === (review.status ?? "PENDING")
                        )?.label
                      }
                    </span>
                  </div>
                  <span className="text-left pl-1 whitespace-nowrap truncate">
                    {
                      arch_review_plan_type.find(
                        (val) => val.value === (review.plan_type ?? "")
                      )?.label
                    }
                  </span>
                  <span className="text-left pl-1 whitespace-nowrap truncate">
                    {
                      arch_review_resolutions.find(
                        (val) => val.value === (review.resolution ?? "")
                      )?.label
                    }
                  </span>
                  <span className="whitespace-nowrap text-right truncate">
                    {review.decision_date
                      ? dateStringFormat(review.decision_date)
                      : "Not found"}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
