import React, { useState, useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { LeftArrowIcon, SearchIcon } from "../AppIcons";
import { userType } from "../../../common/constants";
import { getHoaUnits } from "../../../api/ApiV2";

export default function UnitsDropdown({
  hoaId = "",
  value,
  onChange,
  readOnly,
  parentRef,
  inputFieldClassName,
}) {
  const [keyWord, setKeyWord] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [dropdown_position, set_dropdown_position] = useState("bottom");
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState({});
  // bottom,top
  const ref = useRef(null);
  const searchRef = useRef(null);
  const ele_ref = useRef(null);
  const optionsRef = useRef(new Array());
  const dropdownDisplayFieldRef = useRef(null);

  useEffect(() => {
    // Populate Units data from API

    getHoaUnits(hoaId, 0, keyWord)
      .then((res) => {
        setUnits(res.results);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("Units are ", units);
  }, [keyWord]);

  useEffect(() => {
    if (value && units.length > 0) {
      const unit = units.find((unit) => unit.id === value);
      setSelectedUnit(unit);
    }
  }, [value, units]);

  useEffect(() => {
    if (!openMenu || !parentRef) return;
    if (
      parentRef?.current?.offsetHeight -
        (ele_ref?.current?.offsetTop + ele_ref?.current?.offsetHeight) <
      320
    )
      set_dropdown_position("top");
    else set_dropdown_position("bottom");
  }, [openMenu]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpenMenu(false);
      }}
    >
      <div
        ref={ele_ref}
        className={
          "relative flex " +
          (dropdown_position === "top" ? "flex-col-reverse " : "flex-col ")
        }
      >
        <div
          onClick={() => {
            if (readOnly) return;
            ref?.current?.focus();
            setOpenMenu((openMenu) => !openMenu);
          }}
          className={
            "focus:ring-indigo-500 focus:border-indigo-500 hover:text-gray-600 bg-white cursor-default relative h-10 break-all flex items-center rounded-md focus:z-10 sm:text-sm w-64"
          }
          ref={dropdownDisplayFieldRef}
        >
          <div
            tabIndex={0}
            ref={ref}
            onKeyDown={(e) => {
              if (e.key === "ArrowDown") searchRef.current?.focus();
              else if (e.key === "Enter") setOpenMenu((openMenu) => !openMenu);
            }}
            className={
              "text-left flex flex-row items-center gap-2 flex-grow truncate w-full break-all focus:ring-none focus:outline-none " +
              inputFieldClassName
            }
          >
            {selectedUnit.unit_number
              ? selectedUnit.unit_number +
                " " +
                selectedUnit.address_object.street_address_1
              : "Enter Unit Number"}
          </div>
          {!readOnly && (
            <button className="relative right-7 w-0 overflow-visible">
              <LeftArrowIcon className="transform duration-100 h-6 w-6 -rotate-90" />
            </button>
          )}
        </div>

        <div
          style={{
            ...(dropdown_position === "top"
              ? {
                  marginBottom: dropdownDisplayFieldRef?.current?.offsetHeight,
                }
              : {
                  marginTop: dropdownDisplayFieldRef?.current?.offsetHeight,
                }),
          }}
          className={
            "w-full overflow-auto max-h-64 bg-white flex flex-col border border-gray-300 rounded absolute z-50 " +
            " " +
            (openMenu && !readOnly ? "" : "hidden")
          }
        >
          <div
            className={
              "focus:ring-indigo-500 focus:border-indigo-500 hover:text-gray-600 bg-white cursor-default relative h-10 break-all flex items-center rounded-md focus:z-10 sm:text-sm "
            }
          >
            <div className="w-0 overflow-visible relative left-2">
              <SearchIcon className="h-5 w-5 text-gray-500" />
            </div>
            <div className={"flex flex-row items-center w-full"}>
              <input
                ref={searchRef}
                onChange={(e) => {
                  setKeyWord(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowDown") optionsRef.current[0]?.focus();
                  else if (e.key === "ArrowUp") ref.current.focus();
                }}
                placeholder={"Search"}
                value={keyWord}
                className={
                  "text-left flex flex-grow truncate w-full px-9 py-2 break-all focus:ring-none focus:outline-none"
                }
              />
            </div>
          </div>
          <div className="w-full text-sm focus:bg-blue-600 focus:text-white text-gray-800 cursor-default border-b border-gray-500 bg-gray-300 flex flex-col">
            {units.map((unit) => {
              return (
                <div
                  tabIndex="0"
                  className="pl-3 p-1 bg-white hover:bg-gray-100 cursor-pointer flex flex-row items-center gap-2"
                  onClick={() => {
                    setOpenMenu(false);
                    setSelectedUnit(unit);
                    onChange(unit.id);
                  }}
                >
                  {unit.unit_number}
                  <div>{unit.address_object.street_address_1}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
}
