import { Link } from "raviger";
import React from "react";
import { sidebar_buttons } from "./data/sidebarData";

export default function Sidebar({
  featuresComponent,
  integrationsComponent,
  pricingComponent,
  closeSidebar,
}) {
  return (
    <div className="bg-white w-full h-screen sm:h-full overflow-y-auto flex flex-col rounded">
      <div className="pt-6 pb-10 flex flex-col gap-2 pl-4 pr-12">
        {sidebar_buttons({
          featuresComponent,
          integrationsComponent,
          pricingComponent,
        }).map((button, button_index) => (
          <SelectableItem
            key={button_index}
            sidebar_data={button}
            closeSidebar={closeSidebar}
          />
        ))}
      </div>
    </div>
  );
}

const SelectableItem = ({ sidebar_data, closeSidebar }) => {
  return (
    <Link
      href={sidebar_data.link ?? "#"}
      onClick={() => {
        sidebar_data.onClick && sidebar_data.onClick();
        closeSidebar();
      }}
      className={
        "truncate w-full rounded gap-5 font-semibold duration-200 hover:font-bold cursor-pointer hover:bg-indigo-700 hover:text-white flex items-center px-4 py-2 text-sm leading-6 " +
        sidebar_data.className
      }
    >
      {sidebar_data.label}
    </Link>
  );
};
