import React from "react";

import Row from "../../../../../common/Form/table/Row";
import Heading from "../../../../../common/Form/table/Heading";
import FormInput from "../billDataForm/component/FormInput";
import { tableHeadings } from "./data/tableHeading";
import { tableRows } from "./data/tableRows";
import { PlusInCircleIcon2 } from "../../../../../common/AppIcons";
import { accountingInputClassName } from "../../../../common/data/inputClassName";

export default function xBillTable({
  className,
  readOnly,
  data,
  setData,
  delete_bill_row,
  add_bill_row,
  warning,
  set_warning,
  expenseAccounts,
}) {
  const onChange = (value, name, index) => {
    let temp_warning = warning;
    delete temp_warning?.legs?.[index]?.[name];
    set_warning(temp_warning);

    setData({
      ...data,
      legs: data?.legs?.map((leg, mapIndex) =>
        mapIndex === index ? { ...leg, [name]: value } : leg
      ),
    });
  };

  return (
    <div className={className}>
      <div className="font-semibold text-sm">
        <Heading
          headings={tableHeadings}
          style={{ backgroundColor: "#F5F5F5" }}
          className="w-full grid grid-cols-5 gap-5 pl-4 pr-8 py-4 rounded text-gray-500"
          readOnly={readOnly}
        />
        {(data.just_display ? data?.items : data?.legs)?.map((value, index) => {
          return (
            <Row
              key={index}
              row={tableRows({
                value,
                index,
                onChange,
                expenseAccounts,
                warning,
                readOnly,
              })}
              deleteItem={() => {
                delete_bill_row(index);
              }}
              readOnly={readOnly ? true : false}
              className="w-full grid grid-cols-5 gap-5 pl-4 pr-4 py-4 text-gray-800 border-b"
            />
          );
        })}
      </div>
      {!readOnly && (
        <button
          className="mt-6 text-gray-700 font-semibold py-2 px-4 text-sm hover:opacity-70 focus:outline-none flex flex-row items-center gap-2"
          onClick={add_bill_row}
        >
          <PlusInCircleIcon2 className="text-green-500 h-3" />{" "}
          <span style={{ color: "#2A32FD" }}>Add New Item</span>
        </button>
      )}
    </div>
  );
}
