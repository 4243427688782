import React from "react";

import Paginator from "../../../../../common/Paginator";
import { amountFormat } from "../../../../common/AmountFormat";
import { LoadingCircleIcon } from "../../../../common/AppIcons";
import { Link } from "raviger";

export default function AccountsBalance({
  hoaId,
  accounts,
  accountsPage,
  setAccountsPage,
  entryPerPage,
}) {
  if (!accounts)
    return (
      <div className="flex-grow flex items-center justify-center my-10">
        <LoadingCircleIcon className="h-8" />
      </div>
    );
  else
    return (
      <div className="flex-grow">
        <span className="text-gray-500">Bank Accounts</span>
        <div className="flex flex-row gap-5 justify-between px-8 py-3 mb-1 mt-4 bg-gray-200 rounded">
          {[
            { class: "w-1/2", label: "Name" },
            { class: "w-1/2 text-right", label: "Balance" },
          ].map((value) => {
            return (
              <span
                key={value.name}
                className={"text-sm text-gray-500 " + value.class}
              >
                {value.label}
              </span>
            );
          })}
        </div>

        <div className="overflow-auto flex flex-col gap-1">
          {(!accounts || accounts.length === 0) && (
            <span className="text-sm w-full text-center mt-4 text-gray-400 font-semibold">
              No Accounts Found
            </span>
          )}
          {accounts.map((account) => {
            return (
              <Link
                href={
                  "/hoa/" + hoaId + "/accounts/" + account.id + "/transactions"
                }
              >
                <div
                  key={account.id}
                  className={`rounded-md justify-between px-6 py-3 flex flex-row gap-5 items-center bg-white hover:bg-gray-200`}
                >
                  <span className="w-1/2 text-sm">{account.name}</span>
                  <div className="w-1/2 flex flex-row-reverse">
                    <span className="text-sm ">
                      ${amountFormat(account.current_amount)}
                    </span>
                  </div>
                </div>
              </Link>
            );
          })}
          <Paginator
            currentPage={accountsPage.current + 1}
            totalPage={accountsPage.total}
            entryPerPage={entryPerPage}
            onChange={(pageNo) => {
              setAccountsPage({ ...accountsPage, current: pageNo - 1 });
            }}
          />
        </div>
      </div>
    );
}
