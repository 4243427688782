import { Link, navigate, useQueryParams } from "raviger";
import { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import {
  getHoaMembers,
  updateMember,
  getUnits,
  getBuildings,
} from "../../api/ApiV2";
import {
  BuildingIcon,
  LoadingCircleIcon,
  PlusIcon,
  RightArrowIcon,
} from "../common/AppIcons";
import Loading from "../common/Loading";
import { userType } from "../../common/constants";
import { userAtom } from "../../store/UserAtom";
import AddMemberPopup from "./AddMemberPopup";
import { addressFormatLine2 } from "../../utils/addressFormat";
import { adminTypes } from "../../common/data/adminTypes";

const default_profile_picture = (letter, className = "") => {
  return (
    <div
      className={
        "h-8 w-8 rounded-full flex items-center justify-center bg-indigo-200 shadow-inner uppercase font-semibold text-red-500 " +
        className
      }
    >
      {letter}
    </div>
  );
};

export default function MemberProfiles({ hoaId }) {
  const [queryParams, setQuery] = useQueryParams();
  const [members, set_members] = useState();
  const [member_focus, set_member_focus] = useState();
  const [loading, set_loading] = useState(false);
  const [membersLoading, setMembersLoading] = useState(true);
  const [add_member_popup, set_add_member_popup] = useState(false);
  const [warning, set_warning] = useState({});
  const [buildings, setBuildings] = useState([]);
  const [units, setUnits] = useState([]);

  const [user] = useRecoilState(userAtom);

  const filters = queryParams;

  const userPrevilage = adminTypes.includes(user.selectedUserType);

  const edit_user_privilage = (edit_user_id) => {
    let ans = false;

    if (userPrevilage) ans = user.selectedUserType;

    if (edit_user_id && user.id === edit_user_id && !ans)
      ans = "SAME_PROFILE_PERMISSION";

    return ans;
  };

  useEffect(() => {
    console.log("queryParams", queryParams);
    userPrevilage &&
      getUnits({ hoaId })
        .then((res) => {
          setUnits(res?.results);
        })
        .catch((res) => {
          console.log(res);
          toast.error("Error fetching units");
        });

    !filters.unit &&
      getBuildings({ hoaId })
        .then((res) => {
          setBuildings(res);
        })
        .catch((res) => {
          console.log(res);
          toast.error(res.message ?? "Error getting buildings");
        });
  }, [hoaId]);

  useEffect(() => {
    if (add_member_popup === false) get_members(true);
  }, [add_member_popup, queryParams?.unit]);

  const onSave = () => {
    set_loading(true);
    updateMember(
      hoaId,
      {
        ...member_focus,
        is_owner: [1, 2, "1", "2"].includes(member_focus?.user_type)
          ? member_focus?.is_owner
          : [3, "3"].includes(member_focus?.is_owner)
          ? true
          : false,
        unit_object: undefined,
      },
      member_focus?.id
    )
      .then((res) => {
        console.log(res);
        toast.success("Update Successful");
        set_loading(false);
        filters.unit
          ? navigate(
              `/hoa/${hoaId}/units/?property=${
                units?.find((val) => val.id === filters.unit)?.address
              }&&unit=${member_focus.unit}`
            )
          : get_members();
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        set_warning({ ...(res ?? {}), ...(res.user ?? {}) });
        toast.error("Update failed");
      });
  };

  const get_members = (set_user_focus, search) => {
    getHoaMembers(hoaId, { ...filters }, search)
      .then((res) => {
        console.log(res);
        set_members(
          res.results.sort(
            (val1, val2) =>
              val1.first_name + val1.last_name >
              val2.first_name + val2.last_name
          )
        );

        if (
          set_user_focus ||
          !res.results.find((val) => {
            return val?.user?.id === member_focus?.user?.id;
          })
        )
          set_member_focus(
            res.results.find((val) => {
              return val?.user?.id === user.id;
            }) ??
              res.results[0] ??
              undefined
          );
        setMembersLoading(false);
      })
      .catch((res) => {
        console.log(res);
        setMembersLoading(false);
      });
  };

  const search_members = (keyword) => {
    get_members(false, keyword);
  };

  const remove_warning = (key) => {
    delete warning[key];
  };

  if (membersLoading)
    return (
      <div className="max-w-3xl mx-auto lg:max-w-7xl rounded-xl shadow-lg bg-white flex items-center justify-center py-20">
        <LoadingCircleIcon className="h-10" />
      </div>
    );
  return (
    <div className="max-w-3xl mx-auto lg:max-w-7xl rounded-xl shadow-lg bg-white">
      <div
        className={
          "fixed bg-gray-100 bg-opacity-40 flex items-center justify-center z-50 inset-0 " +
          (loading ? "" : "hidden")
        }
      >
        <Loading className="h-10 w-10 text-gray-900" />
      </div>
      <div className="mx-auto relative z-0 flex overflow-hidden rounded-md">
        <main
          className={
            "flex-1 relative z-0 overflow-y-auto focus:outline-none md:order-last " +
            (member_focus ? "" : "hidden xl:flex-1")
          }
          // tabindex="0"
          // x-data=""
          // x-init="$el.focus()"
        >
          {/* <!-- Breadcrumb --> */}
          <nav
            className="flex items-start px-4 py-3 sm:px-6 lg:px-8 md:hidden"
            aria-label="Breadcrumb"
          >
            <div className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
              <div
                onClick={() => set_member_focus()}
                className="text-gray-400 hover:text-gray-600 cursor-pointer"
              >
                <svg
                  className="-ml-2 h-5 w-5"
                  //   x-description="Heroicon name: chevron-left"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <span>Members</span>
            </div>
          </nav>
          {member_focus ? (
            <article>
              {/* <!-- Profile header --> */}

              <div>
                <div>
                  <img
                    className="h-32 w-full object-cover lg:h-48"
                    src="https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                    alt=""
                  />
                </div>
                <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                    <div className="flex">
                      <div className="flex">
                        {member_focus.profilePic ? (
                          <img
                            className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                            src={member_focus.profilePic}
                            alt=""
                          />
                        ) : (
                          default_profile_picture(
                            member_focus?.user?.first_name.charAt(0),
                            "h-28 w-28 text-3xl sm:text-6xl rounded-full ring-2 sm:ring-4 ring-white sm:h-32 sm:w-32"
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 sm:mt-6 flex flex-col gap-3">
                    <h1 className="text-xl sm:text-2xl font-bold text-gray-900 truncate">
                      {member_focus?.user?.first_name}{" "}
                      {member_focus?.user?.last_name}
                    </h1>
                  </div>
                </div>
              </div>

              {/* <!-- Tabs --> */}
              <div className="mt-6 sm:mt-2 2xl:mt-5">
                <div className="border-b border-gray-200">
                  <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      <Link
                        href="#"
                        className="border-pink-500 text-gray-900 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                        aria-current="page"
                      >
                        Profile
                      </Link>
                    </nav>
                  </div>
                </div>
              </div>

              {/* <!-- Description list --> */}
              <div className="mt-6 max-w-5xl mx-auto px-4 py-2 sm:px-6 lg:px-8">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 pb-8">
                  {[
                    {
                      label: "First name",
                      value: "first_name",
                    },
                    {
                      label: "Last name",
                      value: "last_name",
                    },
                    {
                      label: "Email",
                      value: "email",
                    },
                    {
                      label: "Phone",
                      value: "phone",
                      type: "phone",
                    },
                  ].map((ele, ele_index) => (
                    <div key={ele_index} className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500 w-full flex flex-row justify-between">
                        {ele.label}
                        <span className="text-red-400 relative top-2 text-xs">
                          {warning[ele.value] && warning[ele.value][0]}
                        </span>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {ele.type === "phone" ? (
                          <PhoneInput
                            country={"us"}
                            value={
                              (member_focus &&
                                member_focus?.user?.[ele.value]) ??
                              ""
                            }
                            onChange={(phone) => {
                              remove_warning(ele.value);
                              set_member_focus({
                                ...member_focus,
                                user: {
                                  ...member_focus.user,
                                  [ele.value]: phone,
                                },
                              });
                            }}
                            className="text-xs font-semibold"
                            inputProps={{
                              className:
                                "pl-12 border-none px-3 py-2.5 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-indigo-100 focus:indigo-200 w-full rounded",
                            }}
                          />
                        ) : (
                          <input
                            className="border-none px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-indigo-100 focus:indigo-200 w-full rounded"
                            value={
                              (member_focus &&
                                member_focus?.user?.[ele.value]) ??
                              ""
                            }
                            onChange={(e) => {
                              remove_warning(ele.value);
                              set_member_focus({
                                ...member_focus,
                                user: {
                                  ...member_focus.user,
                                  [ele.value]: e.target.value,
                                },
                              });
                            }}
                          />
                        )}
                      </dd>
                    </div>
                  ))}
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500 w-full flex flex-row justify-between">
                      User type
                      <span className="text-red-400 relative top-2 text-xs">
                        {warning["user_type"] && warning["user_type"][0]}
                      </span>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <select
                        className="border-none px-3 py-2 focus:outline-none bg-indigo-100 focus:indigo-200 w-full rounded"
                        disabled={!edit_user_privilage()}
                        value={
                          (member_focus && member_focus["user_type"]) ?? ""
                        }
                        onChange={(e) => {
                          remove_warning("user_type");
                          if (e.target.value === "3")
                            set_member_focus({
                              ...member_focus,
                              user_type: e.target.value,
                              is_owner: true,
                            });
                          else if (e.target.value === "5")
                            set_member_focus({
                              ...member_focus,
                              user_type: e.target.value,
                              is_owner: false,
                            });
                          else
                            set_member_focus({
                              ...member_focus,
                              user_type: e.target.value,
                            });
                          console.log(member_focus?.user_type);
                        }}
                      >
                        {Object.keys(userType)
                          .filter(
                            (val) =>
                              !filters.unit ||
                              val === "OWNER" ||
                              val === "TENANT"
                          )
                          .map((value) => {
                            return {
                              value: value,
                              label: userType[value].label,
                            };
                          })
                          .map((value, val_index) => {
                            return (
                              <option key={val_index} value={value.value}>
                                {value.label}
                              </option>
                            );
                          })}
                      </select>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500 w-full flex flex-row justify-between">
                      Unit number
                      <span className="text-red-400 relative top-2 text-xs">
                        {warning["unit_number"] && warning["unit"][0]}
                      </span>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <select
                        value={member_focus?.unit ?? ""}
                        className="border-none px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-indigo-100 focus:indigo-200 w-full rounded"
                        disabled={!edit_user_privilage()}
                        onChange={(e) => {
                          remove_warning("unit_number");

                          set_member_focus({
                            ...member_focus,
                            unit: e.target.value,
                            unit_object: units.find(
                              (ele) => ele.id === e.target.value
                            ),
                          });
                        }}
                      >
                        <option value={""} selected hidden>
                          Select
                        </option>

                        {(userPrevilage ? units : [member_focus.unit_object])
                          ?.filter((val) => val)
                          ?.map((ele, ele_index) => (
                            <option key={ele_index} value={ele.id}>
                              {ele.unit_number}
                            </option>
                          ))}
                      </select>
                    </dd>
                  </div>
                </dl>
                {edit_user_privilage(member_focus?.user?.id) && (
                  <div className="w-full flex flex-row-reverse mb-4">
                    <button
                      onClick={onSave}
                      className="text-white px-2 py-1 font-semibold bg-blue-500 hover:bg-blue-600 rounded shadow"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </article>
          ) : (
            <div className="h-full w-full" />
          )}
        </main>

        <aside
          className={
            "md:order-first flex-shrink-0 w-96 md::border-r md::border-gray-200 " +
            (member_focus ? "hidden md:flex md:flex-col" : "flex flex-col")
          }
        >
          <div className="px-6 pt-6 pb-4">
            {queryParams?.unit && (
              <div className="text-gray-600 flex flex-row items-center gap-1 flex-wrap text-xs">
                <span className="hover:underline hover:text-blue-700">
                  <Link
                    href={`/hoa/${hoaId}/units/?Building=${member_focus?.unit_object?.address_object?.id}`}
                  >
                    {addressFormatLine2({
                      address_object: member_focus?.unit_object?.address_object,
                    })}
                  </Link>
                </span>
                <RightArrowIcon className="h-3" />
                {member_focus?.unit_object?.unit_number}
              </div>
            )}
            <div className="mt-2 flex flex-row gap-2 justify-between w-full">
              <div className="flex flex-col">
                {queryParams?.unit && (
                  <h2 className="text-lg font-medium text-gray-900">
                    {member_focus?.unit_object?.unit_number}
                  </h2>
                )}
                <p className="mt-1 text-sm text-gray-600">
                  Search directory of {members?.length} members{" "}
                  {queryParams?.unit && "in this unit"}
                </p>
              </div>
              <div>
                {edit_user_privilage() && (
                  <button
                    className="p-1 rounded bg-blue-600 hover:bg-blue-700"
                    style={{ boxShadow: "1px 1px 3px" }}
                    onClick={() => set_add_member_popup(true)}
                  >
                    <PlusIcon className="h-4 w-4 text-white" />
                  </button>
                )}
              </div>
            </div>
            <form
              className="mt-6 flex space-x-4"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="flex-1 min-w-0">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    {/* <!-- Heroicon name: mail --> */}
                    <svg
                      className="h-5 w-5 text-gray-400"
                      // x-description="Heroicon name: search"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    onChange={(e) => {
                      search_members(e.target.value);
                    }}
                    className="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 px-3 py-2 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Search"
                  />
                </div>
              </div>
            </form>
          </div>
          {/* <!-- Members list --> */}
          <nav
            className="flex-1 min-h-0 md:max-h-screen relative overflow-y-auto"
            aria-label="Members"
          >
            <ul className="relative z-0 divide-y divide-gray-200">
              {members?.map((member, member_index) => (
                <li
                  key={member_index}
                  onClick={() => {
                    set_member_focus(member);
                  }}
                >
                  <div
                    className={
                      "relative px-6 py-5 flex items-center space-x-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 " +
                      (member_focus?.id && member_focus?.id === member.id
                        ? "bg-gray-300"
                        : "hover:bg-gray-200")
                    }
                  >
                    <div className="flex-shrink-0">
                      {member.profilePic ? (
                        <img
                          className="h-10 w-10 rounded-full"
                          src={member.profilePic}
                          alt=""
                        />
                      ) : (
                        default_profile_picture(
                          member?.user?.first_name.charAt(0)
                        )
                      )}
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="focus:outline-none">
                        {/* <!-- Extend touch target to entire panel --> */}
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                        <p className="text-sm font-medium text-gray-900">
                          {member?.user?.first_name} {member?.user?.last_name}
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          {member.typeString}
                        </p>
                      </div>
                    </div>
                    {!filters.unit && (
                      <div className="ml-auto flex flex-col items-end gap-1">
                        {member?.unit_object?.address_object &&
                          buildings?.length > 1 && (
                            <div className="px-2 py-1.5 rounded bg-indigo-100 text-indigo-600 text-xs flex flex-row items-center gap-1.5">
                              <BuildingIcon className="h-3" />
                              {
                                member?.unit_object?.address_object
                                  ?.street_address_1
                              }
                            </div>
                          )}
                        {member?.unit_object && (
                          <div className="px-2 py-1.5 rounded bg-green-100 text-green-600 text-xs flex flex-row items-center gap-1.5">
                            {member?.unit_object?.unit_number}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
      </div>
      {userPrevilage && (
        <AddMemberPopup
          unit={filters?.unit}
          hoaId={hoaId}
          members={members}
          show={add_member_popup}
          setShow={() => {
            set_add_member_popup(false);
          }}
        />
      )}
    </div>
  );
}
