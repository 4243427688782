export const imageArray = (sr_data) => {
  let temp_files = [];

  if (sr_data?.appointments)
    temp_files = [
      ...temp_files,
      ...sr_data?.appointments.map((val) => {
        let temp_arr = [];
        if (val.beforeUrls && val.beforeUrls.length > 0)
          temp_arr = [...temp_arr, ...val.beforeUrls];
        if (val.afterUrls && val.afterUrls.length > 0)
          temp_arr = [...temp_arr, ...val.afterUrls];
        return temp_arr;
      }),
    ];
  let ans = [];

  for (let i = 0; i < temp_files.length; i++) {
    ans = [...ans, ...temp_files[i]];
  }

  if (sr_data?.mediaUrls && sr_data.mediaUrls?.length > 0)
    ans = [...ans, ...sr_data?.mediaUrls];

  return ans;
};
