export const amenityAvailability = [
  {
    label: "Requires Pass",
    value: "REQUIRES_PASS",
    style: { color: "#E87500", backgroundColor: "rgba(232, 117, 0, 0.1)" },
  },
  {
    label: "Open for all",
    value: "OPEN",
    style: { color: "#06B862", backgroundColor: "rgba(6, 184, 98, 0.11)" },
  },
  {
    label: "Closed",
    value: "CLOSED",
    style: { color: "#EF2929", backgroundColor: "rgba(239, 41, 41, 0.11)" },
  },
];
