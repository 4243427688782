import React, { useEffect } from "react";

export default function Paginator({ total_pages, value, onChange }) {
  useEffect(() => {
    if (total_pages > 0 && value === 0) onChange(1);
    else if (value === 0) return;
    else if (total_pages < value) onChange(total_pages > 0 ? total_pages : 1);
  }, [total_pages, value]);
  return (
    <div className="mx-auto">
      <nav aria-label="Page navigation example">
        <ul className="inline-flex -space-x-px">
          <li>
            <span
              onClick={() => {
                if (value !== 1) onChange(value - 1);
              }}
              className={
                "bg-white border border-gray-300 rounded-l-lg leading-tight py-2 px-3 " +
                (value === 1
                  ? "text-gray-300 cursor-default"
                  : "hover:bg-gray-100 text-gray-500 hover:text-gray-700 cursor-pointer")
              }
            >
              Previous
            </span>
          </li>
          {[...Array(total_pages ?? 1)].map((page, page_index) => (
            <li key={page_index}>
              <span
                key={page_index}
                onClick={() => {
                  onChange(page_index + 1);
                }}
                className={
                  "bg-white border border-gray-300 leading-tight py-2 px-3 " +
                  (value === page_index + 1
                    ? "text-gray-300 bg-indigo-600 cursor-default"
                    : "hover:bg-gray-100 text-gray-500 hover:text-gray-700 cursor-pointer")
                }
              >
                {page_index + 1}
              </span>
            </li>
          ))}

          <li>
            <span
              onClick={() => {
                if (total_pages !== value) onChange(value + 1);
              }}
              className={
                "bg-white border border-gray-300 rounded-r-lg leading-tight py-2 px-3 " +
                (total_pages === value
                  ? "text-gray-300 cursor-default"
                  : "hover:bg-gray-100 text-gray-500 hover:text-gray-700 cursor-pointer")
              }
            >
              Next
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
}
