import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "raviger";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import { ticketPrefix } from "../../../utils/SRPrefix";
import Paginator from "../../../common/Paginator";
import { userAtom } from "../../../store/UserAtom";
import { addressFormatLine2 } from "../../../utils/addressFormat";
import { LoadingCircleIcon, SentIcon } from "../../common/AppIcons";
import SRBase from "../srBase";
import { getHoa, getTicket } from "../../../api/ApiV2";
import { adminTypes } from "../../../common/data/adminTypes";

const queryParams = {
  ROUTINE_MAINTENANCE: { cron: true },
  UNSCHEDULED_MAINTENANCE: { type: "NOT_INSPECTION" },
  INSPECTIONS: { type: "INSPECTION" },
  ORDER_HISTORY: {},
};

const entry_per_page = 10;

export default function TicketList({
  selectedTab = "",
  url,
  hoaId,
  label = "Tickets History",
  onClickRowRedirectToMessage = false,
  openGeneralEnquiry = null,
}) {
  const [user] = useRecoilState(userAtom);
  const admin = adminTypes.includes(user.selectedUserType);

  const [loading, set_loading] = useState(false);
  const [sr_data, set_sr_data] = useState();
  const [page, set_page] = useState(1);
  const [totalElements, setTotalElements] = useState(1);
  const [hoaDetails, setHoaDetails] = useState({});
  const [hoaTicketFilter, setHoaTicketFilter] = useState("");

  useEffect(() => {
    getHoa({ external_id: hoaId })
      .then((res) => {
        setHoaDetails(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("srListType", selectedTab);

    let temp_message = sessionStorage.getItem("message_from_sr_create");
    if (temp_message) toast.success(temp_message);

    sessionStorage.removeItem("message_from_sr_create");
  }, []);

  useEffect(() => {
    set_page(1);
    fetchEstimate({ pageNo: 1 });
  }, [user.selectedUserProfile, hoaTicketFilter]);

  useEffect(() => {
    fetchEstimate({});
  }, [user?.requestType, selectedTab]);

  const fetchEstimate = ({ pageNo, status }) => {
    set_loading(true);
    console.log(selectedTab, queryParams[selectedTab]);
    getTicket({
      hoaId,
      offset: pageNo ? pageNo - 1 : page - 1,
      type: status,
      ...queryParams[selectedTab],
      is_hoa_ticket: hoaTicketFilter === "" ? null : hoaTicketFilter,
    })
      .then((res) => {
        console.log("getPublicDashboardSRs", res);
        set_sr_data(
          res.results.sort(
            (obj1, obj2) => new Date(obj2.createdAt) - new Date(obj1.createdAt)
          )
        );
        setTotalElements(res.totalElements);
        set_loading(false);
      })
      .catch((res) => {
        console.log({ res });
        toast.error(res?.data?.message ?? "Ticket fetch failed");
        set_loading(false);
      });
  };

  const row_format = (row, key) => (
    <div key={key} className="mt-1 w-full">
      <Link
        href={
          onClickRowRedirectToMessage
            ? "/hoa/" + hoaId + "/sr/messages/" + row.id
            : "/hoa/" + hoaId + "/ticket/" + row.id
        }
      >
        <div className="px-3 md:px-8 py-4 text-sm rounded-md grid grid-cols-6 gap-5 w-full bg-gray-50 hover:shadow-lg border-2 border-indigo-100 hover:border-indigo-800 cursor-pointer items-center">
          <span className="">
            {ticketPrefix({ ticketId: row.external_ticket_id })}
          </span>
          <span className="col-span-2 truncate">{row.title ?? ""}</span>
          <span className="col-span-2 truncate">
            {addressFormatLine2({
              ...(hoaDetails ?? {}),
              ...(row.unit_object ?? {}),
            })}
          </span>
          <span className="capitalize">
            {row.is_hoa_ticket ? (
              <div className="flex justify-start">
                <div className="px-2 py-1 rounded bg-green-500 text-white font-semibold text-xs">
                  HOA
                </div>
              </div>
            ) : (
              (row.created_by?.first_name ?? "") +
              " " +
              (row.created_by?.last_name ?? "")
            )}
          </span>
        </div>
      </Link>
    </div>
  );
  if (loading)
    return (
      <SRBase hoaId={hoaId} page={url}>
        <div className="w-full py-20 flex items-center justify-center">
          <LoadingCircleIcon className="h-10" />
        </div>
      </SRBase>
    );
  else
    return (
      <SRBase hoaId={hoaId} page={url} style={{ minWidth: "800px" }}>
        <div className="flex flex-col px-4">
          <span className="font-semibold text-2xl py-3 text-gray-800">
            {label}
          </span>

          {openGeneralEnquiry && (
            <Link href={"/hoa/" + hoaId + "/sr/messages/generalEnquiry"}>
              <div className="cursor-pointer -mb-5 mt-5 flex flex-row items-center bg-white pl-3 pr-2 py-2 rounded">
                <input
                  placeholder="Type a message..."
                  className="flex flex-grow text-gray-700 outline-none focus:outline-none bg-gray-100 rounded px-2 py-1"
                />
                <div className="p-2 rounded-full">
                  <SentIcon className="h-6 w-6 text-blue-500" />
                </div>
              </div>
            </Link>
          )}

          <div className="w-full mt-10 mb-1 px-5 md:px-10 flex flex-row justify-end">
            <select
              value={hoaTicketFilter}
              onChange={(e) => setHoaTicketFilter(e.target.value)}
              className="px-2 py-1 rounded border border-gray-300 outline-none focus:outline-none"
            >
              {[
                {
                  label: "All Tickets",
                  value: "",
                },
                ...(admin
                  ? [
                      { label: "HOA Tickets", value: true },
                      { label: "Other Tickets", value: false },
                    ]
                  : [
                      { label: "My Tickets", value: false },
                      { label: "HOA Tickets", value: true },
                    ]),
              ].map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full mt-10 mb-1 px-5 md:px-10 text-sm text-gray-800 grid grid-cols-6 gap-5 font-semibold">
            <span className="">Ticket</span>
            <span className="col-span-2">Description</span>
            <span className="col-span-2">Building</span>
            <span className="">Owner</span>
          </div>
          {(!sr_data || sr_data.length === 0) && (
            <span className="mx-auto text-gray-400 my-20">
              No Results Available.
            </span>
          )}
          {sr_data?.map((sr_data, sr_data_index) =>
            row_format(sr_data, sr_data_index)
          )}

          <div className="my-8">
            <Paginator
              entryPerPage={entry_per_page}
              totalPage={totalElements}
              currentPage={page}
              onChange={(val) => {
                set_page(val);
                fetchEstimate({ pageNo: val });
              }}
            />
          </div>
        </div>
      </SRBase>
    );
}
