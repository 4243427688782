import React, { useState } from "react";
import TicketList from "../TicketList";

export default function SelectChat({ url, hoaId }) {
  return (
    <div className="min-w-full flex flex-col">
      <TicketList
        label="Messages (Select a Ticket)"
        onClickRowRedirectToMessage={true}
        withoutBuilding={true}
        openGeneralEnquiry
        {...{ url, hoaId }}
      />
    </div>
  );
}
