import React from "react";
import { DeleteIcon3, EditIcon4 } from "../../../../../../common/AppIcons";
import { time_array, time_string_to_minutes } from "../../../data/time_array";
import { week_days } from "../../../data/week_days";
import FormElement from "./FormElement";

export default function WeeklyHours({
  show_custom,
  set_show_custom,
  amenityForm,
  set_amenityForm,
  inputClassName,
  inputStyle,
  error,
  set_error,
}) {
  return show_custom ? (
    <FormElement label="Set your weekly hours">
      <div className="mt-1 flex flex-col gap-3">
        {Object.entries(amenityForm.data.schedules).map(
          ([week, weekObject], weekIndex) => (
            <div key={weekIndex} className="flex flex-col">
              <div className="flex flex-row justify-end w-full">
                <span className="text-xs text-red-500">
                  {error?.schedules?.[week]?.time_ranges ?? ""}
                </span>
              </div>
              <WeekTimeElement
                label={week.toLowerCase()}
                value={week}
                weekObject={weekObject}
                error={error?.schedules?.[week]}
                remove_error={() => {
                  if (!error?.schedules?.[week]) return;

                  let temp_error = error;
                  delete temp_error?.schedules?.[week];
                  set_error(temp_error);
                }}
                {...{
                  amenityForm,
                  set_amenityForm,
                  inputClassName,
                  inputStyle,
                }}
              />
            </div>
          )
        )}
      </div>
    </FormElement>
  ) : (
    <div
      className="mt-4 flex flex-row items-center gap-2 cursor-pointer hover:opacity-70 duration-200 text-sm"
      style={{ color: "#2A32FD", fontSize: "14px" }}
      onClick={() => {
        set_show_custom(true);
      }}
    >
      <EditIcon4 className="rounded" style={{ width: "15px" }} />
      {amenityForm.readOnly ? "Custom hours" : "Set custom hours"}
    </div>
  );
}

function WeekTimeElement({
  label,
  value,
  amenityForm,
  set_amenityForm,
  inputClassName,
  inputStyle,
  error,
  weekObject,
  remove_error,
}) {
  return (
    <div className="w-full">
      <div className="w-full flex justify-end text-sm text-red-500">
        {error?.time_slot?.[0]}
      </div>
      <div className="w-full px-4 py-2 rounded border border-gray-300 flex flex-row items-start sm:items-center flex-wrap sm:grid sm:grid-cols-8">
        <span className="font-semibold col-span-2 text-sm text-gray-700 capitalize">
          {label}
        </span>

        {weekObject?.time_ranges?.length ? (
          <div className={"flex flex-col items-start gap-1 col-span-5"}>
            {weekObject?.time_ranges?.map(
              (availability, availability_index) => (
                <div
                  key={availability_index}
                  className="flex flex-row items-center gap-3"
                >
                  {[
                    {
                      value: availability.start_time,
                      name: "start_time",
                      array: time_array.slice(0, time_array.length - 1),
                      error:
                        error?.time_ranges ??
                        ""?.[availability_index]?.start_time,
                    },
                    {
                      value: availability.end_time,
                      name: "end_time",
                      array: time_array
                        .slice(1, time_array.length)
                        .filter(
                          (val) =>
                            time_string_to_minutes(availability.start_time) <
                            val.value
                        ),
                      error:
                        error?.time_ranges ??
                        ""?.[availability_index]?.end_time,
                    },
                  ].map((ele, ele_index) => (
                    <div>
                      <div className="flex flex-row justify-end w-full">
                        <span className="text-xs text-red-500">
                          {ele?.error}
                        </span>
                      </div>
                      <select
                        key={ele_index}
                        className={
                          inputClassName + " border border-gray-300 pr-6"
                        }
                        disabled={amenityForm.readOnly}
                        style={inputStyle}
                        value={ele.value}
                        onChange={(e) => {
                          remove_error();
                          let changed_value = e.target?.value;
                          set_amenityForm({
                            ...amenityForm,
                            data: {
                              ...amenityForm.data,
                              schedules: {
                                ...amenityForm.data?.schedules,
                                [value]: {
                                  ...weekObject,
                                  time_ranges: weekObject.time_ranges?.map(
                                    (val, index) =>
                                      index === availability_index
                                        ? { ...val, [ele.name]: changed_value }
                                        : val
                                  ),
                                },
                              },
                            },
                          });
                        }}
                      >
                        <option hidden selected>
                          Select Time
                        </option>
                        {ele.array.map((ele, index) => (
                          <option key={index} value={ele.label2}>
                            {ele.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                  <div
                    onClick={() => {
                      let temp_schedule = weekObject;

                      temp_schedule.time_ranges =
                        temp_schedule.time_ranges?.filter(
                          (val, index) => index !== availability_index
                        );

                      temp_schedule.is_available =
                        (temp_schedule.time_ranges ?? []).length === 0
                          ? false
                          : weekObject.is_available;

                      set_amenityForm({
                        ...amenityForm,
                        data: {
                          ...amenityForm.data,
                          schedules: {
                            ...amenityForm.data?.schedules,
                            [value]: temp_schedule,
                          },
                        },
                      });
                    }}
                    className={
                      "text-gray-700 hover:text-gray-500 cursor-pointer " +
                      (amenityForm.readOnly ? "invisible" : "")
                    }
                  >
                    <DeleteIcon3 className="h-4" />
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <span className="text-gray-400 text-sm col-span-5">
            No time ranges added
          </span>
        )}
        <div
          onClick={() => {
            if (amenityForm.readOnly) return;
            set_amenityForm({
              ...amenityForm,
              data: {
                ...amenityForm.data,
                schedules: {
                  ...amenityForm.data?.schedules,
                  [value]: {
                    ...weekObject,
                    time_ranges: [
                      ...(weekObject.time_ranges ?? []),
                      { start_time: null, end_time: null },
                    ],
                    is_available:
                      (weekObject.time_ranges ?? []).length === 0
                        ? true
                        : weekObject.is_available,
                  },
                },
              },
            });
          }}
          className={
            "flex justify-end cursor-pointer text-2xl " +
            (amenityForm.readOnly ? "invisible" : "")
          }
        >
          +
        </div>
      </div>
    </div>
  );
}
