import React from "react";
import { AdobeIcon, PrintIcon2 } from "../../../../../common/AppIcons";

export default function BottomButtons({
  setShow,
  set_loading,
  onSubmit,
  readOnly,
}) {
  return (
    <div className="w-full flex flex-row items-center justify-end gap-6">
      {/* <div className="flex flex-row items-center gap-4">
        <button className="whitespace-pre focus:outline-none font-medium border-2 border-gray-400 text-gray-600 px-2 py-1 rounded hover:opacity-60 duration-200 flex flex-row items-center gap-3">
          <PrintIcon2 className="h-5 text-green-600" /> Print
        </button>
        <button className="whitespace-pre focus:outline-none font-medium border-2 border-gray-400 text-gray-600 px-2 py-1 rounded hover:opacity-60 duration-200 flex flex-row items-center gap-3">
          <AdobeIcon className="h-5 text-red-600" /> Get PDF
        </button>
      </div> */}
      <div className="flex flex-row items-center gap-4">
        {!readOnly && (
          <button
            className="focus:outline-none font-medium border-2 border-gray-400 text-gray-600 px-7 py-1 rounded hover:opacity-60 duration-200"
            onClick={() => {
              setShow();
            }}
          >
            Cancel
          </button>
        )}
        <button
          style={{ backgroundColor: "#2A32FD", border: "2px solid #2A32FD" }}
          className={
            "rounded text-white font-medium px-7 py-1 hover:opacity-70"
          }
          onClick={() => {
            set_loading && set_loading(true);
            onSubmit();
          }}
        >
          {readOnly ? "Close" : "Save"}
        </button>
      </div>
    </div>
  );
}
