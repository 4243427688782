import stringSimilarity from "string-similarity";
import { amountFormat } from "../components/common/AmountFormat";

export const properString = (someString) => {
  const notNull = someString || "";
  return notNull
    .split("_")
    .map((word) => (word.length > 0 ? capitalizeFirst(word) : word))
    .join(" ");
};

const capitalizeFirst = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const renderBalance = (balance) => {
  return "$" + amountFormat(balance);
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};
export const isNumericBlank = (n) => {
  return n === "" || isNumeric(n);
};

export const getFileName = (url) => url.split("/").pop().split("?")[0];

export const classNames = (...classNames) =>
  classNames.reduce((acc, cur) => `${acc}${cur} `, " ");

export const objectMatch = (
  queries,
  objects,
  selector,
  ignorableGenerics = []
) => {
  const comparators = objects.map((obj) => obj[selector].toLowerCase());

  console.log("Queries", queries);
  const searches = queries
    .map((text) => text.toLowerCase())
    .filter((comparator) => !ignorableGenerics.includes(comparator));
  var bestMatch = searches
    .map((query) => stringSimilarity.findBestMatch(query, comparators))
    .reduce(
      (bestMatch, queryMatch, reduceIndex) => {
        console.log(
          `Matching Query ${reduceIndex} | ${searches[reduceIndex]}`,
          queryMatch
        );
        return queryMatch.bestMatch.rating > bestMatch.rating &&
          queryMatch.bestMatch.rating > 0.5
          ? {
              index: queryMatch.bestMatchIndex,
              rating: queryMatch.bestMatch.rating,
            }
          : bestMatch;
      },
      { index: 0, rating: 0 }
    );

  console.log("Match Result", bestMatch);
  return bestMatch.rating === 0 ? null : objects[bestMatch.index];
};
