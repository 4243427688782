import React from "react";
import { accountsDropdownOptions } from "../../../../../common/data/accountsDropdownOptions";
import { accountingInputClassName } from "../../../../../common/data/inputClassName";

const fieldClassName = accountingInputClassName + " w-full";
const element_className = "h-full flex items-center";

export const tableRow = ({
  leg,
  renderIndex,
  onChangeLeg,
  baseData,
  setNewAccountPopup,
  remove_description,
  readOnly,
  items,
}) =>
  [
    {
      label:
        leg.account_object?.name ??
        baseData?.find((val) => val.id === (leg.accountId || leg.account))
          ?.name,
      className: element_className,
      input: {
        className: fieldClassName,
        wrapperClassName: "mt-1",
        type: "dropdown",
        value: leg.accountId || leg.account,
        readOnly: readOnly,
        onChange: (value) => {
          if (value === "OPEN_NEW_ACCOUNT_POPUP") setNewAccountPopup(true);
          else {
            onChangeLeg(
              value === "" ? undefined : value,
              "accountId",
              renderIndex
            );
            onChangeLeg(
              value === "" ? undefined : value,
              "account",
              renderIndex
            );
          }
        },
        options: [
          {
            label: "Create New Account",
            value: "OPEN_NEW_ACCOUNT_POPUP",
          },
          ...accountsDropdownOptions(baseData, true),
        ],
      },
    },
    {
      label: leg.debit ?? "0.00",
      className: { element_className },
      input: {
        type: "currencyLocal",
        className: fieldClassName,
        value: leg.debit,
        readOnly: readOnly,
        onChange: (value) => {
          onChangeLeg(value === "" ? 0 : value, "debit", renderIndex);
          onChangeLeg(0, "credit", renderIndex);
        },
      },
    },
    {
      label: leg.credit ?? "0.00",
      className: { element_className },
      input: {
        type: "currencyLocal",
        className: fieldClassName,
        value: leg.credit,
        readOnly: readOnly,
        onChange: (value) => {
          onChangeLeg(value === "" ? 0 : value, "credit", renderIndex);
          onChangeLeg(0, "debit", renderIndex);
        },
      },
    },
    !remove_description && {
      label: leg.description,
      className: { element_className },
      input: {
        type: "text",
        className: fieldClassName + " mt-1",
        value: leg.description,
        readOnly: readOnly,
        onChange: (value) => {
          if (renderIndex === 0)
            items.map((val, index) => {
              if (val.description === leg.description)
                onChangeLeg(
                  value === "" ? undefined : value,
                  "description",
                  index
                );
            });
          onChangeLeg(
            value === "" ? undefined : value,
            "description",
            renderIndex
          );
        },
      },
    },
  ].filter((val) => val);
