import React, { useState } from "react";
import { inputFieldData } from "./data/inputFieldData";
import FormInput from "./component/FormInput";
import NewAccountPopup from "../../../NewAccountPopup";

export default function BillDataForm({
  data,
  setData,
  className,
  delete_warning,
  warning,
  setSelectAccount,
  accounts,
  selectAccount,
  hoaId,
  fetchAccounts,
  readOnly,
}) {
  const [new_account_popup, set_new_account_popup] = useState(false);
  return (
    <div
      className={"w-full flex justify-center flex-col items-center" + className}
    >
      {console.log("BillDataFormreadOnly", readOnly)}
      <div
        className={"mb-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-6 gap-x-12"}
      >
        {inputFieldData({
          data,
          setData,
          delete_warning,
          warning,
          setSelectAccount,
          accounts,
          selectAccount,
          readOnly,
          set_new_account_popup,
        })?.map((field, field_index) => (
          <FormInput key={field_index} {...field} />
        ))}
      </div>
      <div
        className={
          "absolute inset-0 bg-white bg-opacity-60 z-40 flex items-center justify-center " +
          (new_account_popup ? "" : "hidden")
        }
      >
        <NewAccountPopup
          hoaId={hoaId}
          closePopup={(_) => {
            set_new_account_popup(false);
            fetchAccounts();
          }}
          account_status={{ type: "ASSET", is_bank_account: true }}
        />
      </div>
    </div>
  );
}
