import React from "react";
import Paginator from "../../../../common/Paginator";
import { amountFormat } from "../../../common/AmountFormat";
import {
  CrossIcon2,
  LoadingCircleIcon,
  LoadingCircleIcon2,
} from "../../../common/AppIcons";

export default function BalanceSummaryPopup({
  loading,
  title,
  subTitle,
  data,
  onClose,
  page,
  set_page,
  entryPerPage,
}) {
  return (
    <div className="flex flex-col bg-white rounded px-5 sm:px-10 py-8">
      <div className="w-full flex flex-col-reverse sm:flex-row items-center justify-between">
        <span className="font-bold text-lg">{title}</span>
        {onClose && (
          <div className="w-full sm:w-min flex justify-end">
            <button
              onClick={onClose}
              className="p-1 rounded-full bg-red-200 text-red-600 hover:bg-red-300 hover:text-red-700"
            >
              <CrossIcon2 className="h-4 w-4" />
            </button>
          </div>
        )}
      </div>
      {subTitle && (
        <div className="mt-10 flex flex-row items-center justify-between font-semibold text-gray-800">
          <span className="">{subTitle[0]}</span>
          {subTitle[1]}
        </div>
      )}
      {loading ? (
        <LoadingCircleIcon2 className="text-blue-600 h-8 my-10" />
      ) : (
        <div className="px-5 sm:px-10 md:px-16 py-7 flex flex-col font-semibold">
          <div className="grid grid-cols-2 text-gray-600">
            <span>{data?.heading[0]}</span>
            <span className="w-full flex justify-end">{data?.heading[1]}</span>
          </div>
          {data?.body ? (
            <div className="mt-3 divide-y divide-gray-300">
              {data.body.map((ele, ele_index) => (
                <div
                  key={ele_index}
                  className="py-4 grid grid-cols-2 text-gray-800 truncate"
                >
                  <span>{ele[0] ?? ""}</span>
                  <span className="w-full flex justify-end truncate">
                    {ele[1] ?? ""}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full flex justify-center py-8">
              <LoadingCircleIcon className="text-blue-600 h-6 my-3" />
            </div>
          )}
        </div>
      )}
      {page && (
        <Paginator
          className="-mt-5 mx-5 sm:mx-10 border-none"
          entryPerPage={entryPerPage}
          currentPage={page.current + 1}
          totalPage={page.total}
          onChange={(pageNo) => {
            set_page({ ...page, current: pageNo - 1 });
          }}
        />
      )}
    </div>
  );
}
