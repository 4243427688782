import React, { useEffect, useState } from "react";

import { saveTransactions } from "../../../../api/Api";
import { getBills, getAccounts, postBillPayment } from "../../../../api/ApiV2";
import BillList from "./billList";
import BillDetail from "./billDetail";
import { userAtom } from "../../../../store/UserAtom";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";

export default function PayBill({ hoaId, onSuccess, parentRef, className }) {
  const [expenseAccounts, setExpenseAccounts] = useState();
  const [accounts, setAccounts] = useState();
  const [bills, setBills] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    popup: "BILL_LIST",
    billNo: 0,
    billPage: 1,
    amountPaying: 0,
    vendor_selected: 0,
  });
  const [selectAccount, setSelectAccount] = useState();

  useEffect((_) => {
    get_ex_accounts();
    get_asset_accounts();
  }, []);

  useEffect(() => {
    getBill();
  }, [data.billPage]);

  const getBill = () => {
    setLoading(true);
    getBills({ hoaId: hoaId, page: data.billPage - 1, paid: "False" })
      .then((res) => {
        console.log("bills", res.results);
        setData((data) => ({
          ...data,
          totalPage: res.count,
        }));
        setBills(res.results);
        setLoading(false);
      })
      .catch((res) => {
        toast.error("Error in getting bills");
        console.log(res);
        setLoading(false);
      });
  };

  const get_ex_accounts = () => {
    getAccounts({ hoaId, type: "EXPENSE" })
      .then((res) => {
        console.log("expense account", res.results);
        setExpenseAccounts(res.results);
      })
      .catch(console.log);
  };

  const get_asset_accounts = () => {
    getAccounts({ hoaId, type: "ASSET", bank_account: true })
      .then((res) => {
        console.log("account", res.results);
        setAccounts(res.results);
      })
      .catch(console.log);
  };

  const onSubmit = () => {
    if (!selectAccount) return;
    setLoading(true);
    postBillPayment({
      hoaId,
      billId: bills[data.billNo].id,
      data: { from_account: selectAccount, amount: data.amountPaying },
    })
      .then((res) => {
        setLoading(false);
        console.log("bill payed data : ", res);
        onSuccess();
        getBill();
      })
      .catch((res) => {
        console.log(res);
        toast.error("Error in paying bill");
        setLoading(false);
      });
  };
  return (
    <div className={"px-5 pt-6 pb-3 rounded bg-white " + className}>
      {data.popup === "BILL_LIST" ? (
        <BillList
          {...{
            hoaId,
            data,
            setData,
            bills,
            expenseAccounts,
            accounts,
            loading,
            setLoading,
            onSubmit,
            onSuccess,
          }}
        />
      ) : (
        <BillDetail
          {...{
            hoaId,
            onSubmit,
            parentRef,
            bills,
            data,
            setData,
            accounts,
            expenseAccounts,
            selectAccount,
            setSelectAccount,
            get_asset_accounts,
            payLoading: loading,
          }}
        />
      )}
    </div>
  );
}
