import { Transition } from "@tailwindui/react";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import {
  getAccounts,
  getHoa,
  getHoaMembers,
  getPlaidBanking,
  postBill,
  postInitialBill,
} from "../../../../api/ApiV2";
import PaymentPopup from "../../../payments/PaymentPopup";
import { generateUUID } from "./utility/generateUUID";
import RevenueDataForm from "./components/revenueDataForm";
import RevenueTable from "./components/revenueTable";
import Summary from "../../common/components/summary";
import BottomButtons from "./components/bottomButtons";
import PopupHeading from "../../../common/PopupHeading";
import LoadingScreen from "../../../common/LoadingScreen";
import Popup from "../../../common/Popup";
import NewAccountPopup from "../NewAccountPopup";
import Success from "./components/cardPaymentAfterScreens/Success";
import Failed from "./components/cardPaymentAfterScreens/Failed";

export default function AddRevenue({
  hoaId,
  source,
  invoice,
  setShow,
  remove_pay,
  set_invoice_paid,
  readOnly = false,
  cardPaymentStatus,
}) {
  const [cardPaymentPopup, setCardPaymentPopup] = useState(cardPaymentStatus);
  const payButtonRef = useRef(null);
  const [create_account_popup, set_create_account_popup] = useState(false);
  const [members, set_members] = useState([]);
  const [accounts, set_accounts] = useState([]);
  const [loading, set_loading] = useState(false);
  const [pay_button, set_pay_button] = useState("LOADING");
  const [payment_method_popup, set_payment_method_popup] = useState({
    view: false,
    popup: "PAY_OPTIONS",
    // PAY_OPTIONS, ACCOUNTS, ACH_PAY, CARD_PAY, SUCCESS
  });
  const [data, set_data] = useState({
    items: [
      {
        id: generateUUID(),
        name: "",
        qty: "",
        rate: "",
        total: "",
        gst: 18,
      },
    ],
    invoiceNumber: "",
    invoiceDate: undefined,
    invoiceDueDate: undefined,
    picture: null,
    plaid_data: null,

    netTotal: 0,

    unit_id: 0,

    billing: {
      name: "",
      address: "",
      extra: "",
    },
    from: {
      name: "",
      address: "",
      extra: "",
    },

    customer_name: "",
    account: 0,
  });

  useEffect(() => {
    console.log("props,", invoice);

    if (invoice) {
      set_data((data) => ({ ...data, ...invoice }));

      if (!invoice.items || invoice.items.length === 0) {
        set_data((data) => {
          return { ...data, netTotal: Number(invoice.amount) };
        });
      } else findItemTotal(invoice.items);
    }
  }, []);

  useEffect(() => {
    if (invoice && !remove_pay) get_plaid_data();
    if (!readOnly) get_accounts();
    getMembers();
  }, []);

  const get_accounts = () => {
    getAccounts({ hoaId: hoaId, page_size: 300, type: "INCOME" })
      .then((res) => {
        console.log(res);
        set_accounts(res.results);
      })
      .catch((res) => {
        console.log(res);
        toast.error(res.readableMessage ?? res.message ?? "Error");
      });
  };

  const get_plaid_data = () => {
    getHoa({ external_id: hoaId })
      .then((res) => {
        if (res.plaid_stripe_key === null) set_pay_button("DISABLE");
        else {
          getPlaidBanking({ hoaId })
            .then((res) => {
              console.log("plaidData user response:", res);
              set_data((data) => {
                return { ...data, plaid_data: res };
              });
              set_pay_button("ENABLE");
            })
            .catch((err) => {
              console.log(err);
              set_pay_button("DISABLE");
              toast.error(err.readableMessage ?? err.message ?? "Error");
            });
        }
      })
      .catch((err) => {
        console.log(err);
        set_pay_button("DISABLE");
        toast.error(err.readableMessage ?? err.message ?? "Error");
      });
  };

  const getMembers = () => {
    set_loading(true);
    getHoaMembers(hoaId)
      .then((res) => {
        set_loading(false);
        set_members(
          res.results?.sort((member1, member2) =>
            (
              member1.user?.first_name +
              " " +
              member1.user?.last_name
            ).localeCompare(
              member2.user?.first_name + " " + member2.user?.last_name
            )
          )
        );
        console.log("members:", res.results);
      })
      .catch((res) => {
        console.log(res);
        set_loading(false);
        toast.error(res.readableMessage ?? res.message ?? "Error");
      });
  };

  const add_new_row = () => {
    set_data((data) => {
      return {
        ...data,
        items: [
          ...(data.items ?? []),
          {
            id: generateUUID(),
            name: "",
            qty: "",
            rate: "",
            total: "",
            gst: 18,
          },
        ],
      };
    });
  };
  const deleteItem = (uuid) => {
    var a = data.items?.filter((item) => uuid !== item.id);
    set_data((data) => {
      return { ...data, items: a };
    });

    findItemTotal(a);
  };

  const findItemTotal = (items = data.items) => {
    console.log(items);
    var a =
      items?.length > 0
        ? items?.reduce((result, item) => {
            return item.total < 0 && readOnly ? result : result + item.total;
          }, 0)
        : 0;
    set_data((data) => {
      return { ...data, netTotal: a };
    });
  };

  const onSubmit = () => {
    data.items && data.items.length > 0 && source !== "HOA_CREATION"
      ? postBill({
          hoaId,
          data: {
            is_invoice: true,
            name: data.invoiceNumber,
            reference_number: data.invoiceNumber,
            date: data.invoiceDate,
            due_date: data.invoiceDueDate,
            customer_unit: data.unit_id,
            items:
              source === "HOA_CREATION"
                ? undefined
                : data.items?.map((value) => {
                    return {
                      account: value.account,
                      description: value.name,
                      amount: value.total,
                      quantity: value.qty,
                      unit_price: value.rate,
                    };
                  }),
            description: "Invoice",
            pending_amount: data.netTotal?.toFixed(2),
            transaction:
              source === "HOA_CREATION"
                ? undefined
                : {
                    date: data.invoiceDate,
                    description: "" + data.invoiceNumber,
                    legs: data.items.map((value) => {
                      return {
                        account: value.account,
                        amount: value.total,
                        description: value.name,
                      };
                    }),
                  },
          },
        })
          .then((res) => {
            console.log(res);
            set_loading(false);
            toast.success("Success");
            setShow();
          })
          .catch((res) => {
            console.log(res);
            set_loading(false);
            toast.error(res.readableMessage ?? res.message ?? "Error");
          })
      : postInitialBill({
          hoaId,
          data: {
            is_invoice: true,
            reference_number: data.invoiceNumber,
            date: data.invoiceDate,
            due_date: data.invoiceDueDate,
            customer_unit: data.unit_id,
            description: "Invoice",
            amount: data.netTotal,
          },
        })
          .then((res) => {
            console.log(res);
            set_loading(false);
            toast.success("Success");
            setShow();
          })
          .catch((res) => {
            console.log(res);
            set_loading(false);
            toast.error(res.readableMessage ?? res.message ?? "Error");
          });
  };

  const reload_pay_button = (res) => {
    if (res)
      set_data((data) => {
        return { ...data, plaid_data: res, autoClick: true };
      });

    set_pay_button("LOADING");

    setTimeout(() => {
      set_pay_button("ENABLE");

      setTimeout(() => {
        set_loading(false);
        payButtonRef?.current?.click();
      }, 1000);
    }, 1000);
  };

  return (
    <div className="w-full antialiased sans-serif rounded h-full max-h-screen overflow-auto">
      <LoadingScreen show={loading} />

      <div className="container mx-auto py-4 md:py-6 px-3 md:px-5 bg-white rounded">
        <PopupHeading closePopup={setShow} className="mb-5">
          Generate Revenue
        </PopupHeading>

        <div className="sm:mt-7 px-3 sm:px-5 w-full max-h-full">
          <RevenueDataForm
            className="my-8"
            data={data}
            set_data={set_data}
            members={members}
            readOnly={readOnly}
            hoaId={hoaId}
          />

          <RevenueTable
            source={source}
            data={data}
            readOnly={readOnly}
            set_data={set_data}
            accounts={accounts}
            deleteItem={deleteItem}
            findItemTotal={findItemTotal}
            add_new_row={add_new_row}
            set_create_account_popup={set_create_account_popup}
          />

          <div className="mt-8 w-full flex flex-col md:flex-row items-center md:items-end justify-end gap-x-6 gap-y-8">
            <Summary
              show={data?.netTotal}
              data={[
                {
                  label: "Sub Total",
                  value: data?.netTotal,
                },
                {
                  label: "Tax",
                  value: 0,
                },
                {
                  label: "Total amount",
                  value: data?.netTotal,
                },
              ]}
              title="Revenue Summary"
            />
          </div>

          <div className="py-2 ml-auto mt-7">
            <BottomButtons
              {...{
                pay_button,
                invoice,
                remove_pay,
                set_payment_method_popup,
                setShow,
                data,
                set_loading,
                onSubmit,
                payButtonRef,
                readOnly,
              }}
            />
          </div>
        </div>
      </div>
      <Popup
        show={create_account_popup}
        setShow={set_create_account_popup}
        className="p-4 flex flex-col items-center justify-center"
      >
        <div className="max-w-xl">
          <NewAccountPopup
            hoaId={hoaId}
            closePopup={(_) => {
              set_create_account_popup(false);
              get_accounts && get_accounts();
            }}
          />
        </div>
      </Popup>

      <Popup
        show={cardPaymentPopup ? true : false}
        setShow={setCardPaymentPopup}
        className="p-4 flex flex-col items-center justify-center"
      >
        {cardPaymentPopup === "success" ? (
          <Success onClose={setCardPaymentPopup} />
        ) : (
          <Failed onClose={setCardPaymentPopup} />
        )}
      </Popup>

      <Transition
        show={payment_method_popup.view}
        enter="transition-opacity duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <PaymentPopup
          hoaId={hoaId}
          reload_pay_button={reload_pay_button}
          disable={pay_button === "DISABLE"}
          payId={invoice?.id}
          plaid_data={data.plaid_data}
          update_data={(res) => {
            console.log("payment successfull");
            set_data({ ...data, paid: true, plaid_data: false });
            set_invoice_paid && set_invoice_paid(res);
          }}
          payment_method_popup={payment_method_popup}
          set_payment_method_popup={set_payment_method_popup}
        />
      </Transition>
    </div>
  );
}
