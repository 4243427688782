import { CrossCicleIcon, ThumbsUpIcon } from "../../../../../common/AppIcons";

export default function Success({ onClose }) {
  return (
    <div className="bg-white rounded px-10 pt-6 pb-16 flex flex-col gap-5 items-center">
      <div className="w-full flex justify-end">
        <div className="cursor-pointer" onClick={() => onClose()}>
          <CrossCicleIcon className="h-6 w-6 text-red-500" />
        </div>
      </div>
      <div className="bg-gray-100 rounded-full w-28 h-28 flex items-center justify-center">
        <ThumbsUpIcon className="text-indigo-600 h-20 w-20" />
      </div>
      <div className="flex flex-col text-center gap-2">
        <span className="text-2xl font-semibold text-gray-700">
          Card Payment Success
        </span>
        <span className="mt-2 text-sm text-gray-600">
          {
            "Successfully paid invoice with card. Close this popup to see paid invoice detail"
          }
        </span>
      </div>
    </div>
  );
}
