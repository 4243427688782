import { toast } from "react-toastify";
import {
  getBoardMeetingAgendas,
  getBoardMeetingAgendaMotions,
  getBoardMeetingAgendaActionItems,
  getBoardMeetingAgendaMotionVotes,
} from "../../../../../api/ApiV2";

export function getAgendas({ hoaId, boardMeetingId, onFinish }) {
  let agendas = [];

  getBoardMeetingAgendas({
    hoaId,
    boardMeetingId,
  })
    .then((res) => {
      agendas = res.results;

      let apiLoadingAgendas = [...Array(res.results.length)].fill(true);

      if (!agendas || agendas.length === 0) {
        onFinish(agendas);
      }

      agendas?.forEach((agenda, agendaIndex) => {
        let apiLoading = {
          motion: true,
          actionItem: true,
        };

        getBoardMeetingAgendaMotions({
          hoaId,
          boardMeetingId,
          agendaId: agenda.id,
        })
          .then((res) => {
            agendas[agendaIndex].agenda_motions = res.results
              ?.filter((val) => !val.deleted)
              .map((motion) => ({
                ...motion,
                agenda_motion_votes: [],
              }));

            let apiLoadingVotes = [
              ...Array(res.results?.filter((val) => !val.deleted).length),
            ].fill(true);

            if (
              !agendas[agendaIndex].agenda_motions ||
              agendas[agendaIndex].agenda_motions?.length === 0
            ) {
              apiLoading.motion = false;

              if (
                apiLoading.motion === false &&
                apiLoading.actionItem === false
              )
                apiLoadingAgendas[agendaIndex] = false;

              if (apiLoadingAgendas.every((val) => val === false))
                onFinish(agendas);
            }

            agendas[agendaIndex].agenda_motions?.forEach(
              (motion, motionIndex) => {
                getBoardMeetingAgendaMotionVotes({
                  hoaId,
                  boardMeetingId,
                  agendaId: agenda.id,
                  motionId: motion.id,
                })
                  .then((res) => {
                    agendas[agendaIndex].agenda_motions[
                      motionIndex
                    ].agenda_motion_votes = res.results;

                    apiLoadingVotes[motionIndex] = false;

                    if (apiLoadingVotes.every((val) => val === false)) {
                      apiLoading.motion = false;
                    }

                    if (
                      apiLoading.motion === false &&
                      apiLoading.actionItem === false
                    )
                      apiLoadingAgendas[agendaIndex] = false;

                    if (apiLoadingAgendas.every((val) => val === false))
                      onFinish(agendas);
                  })
                  .catch((err) => {
                    console.log(err);

                    apiLoadingVotes[motionIndex] = false;

                    if (apiLoadingVotes.every((val) => val === false)) {
                      apiLoading.motion = false;
                    }

                    if (
                      apiLoading.motion === false &&
                      apiLoading.actionItem === false
                    )
                      apiLoadingAgendas[agendaIndex] = false;

                    if (apiLoadingAgendas.every((val) => val === false))
                      onFinish(agendas);

                    toast.error("Fetching agenda motions failed");
                  });
              }
            );
          })
          .catch((err) => {
            console.log(err);
            toast.error("Fetching agenda motions failed");

            apiLoading.motion = false;

            if (apiLoading.motion === false && apiLoading.actionItem === false)
              apiLoadingAgendas[agendaIndex] = false;

            if (apiLoadingAgendas.every((val) => val === false))
              onFinish(agendas);
          });

        getBoardMeetingAgendaActionItems({
          hoaId,
          boardMeetingId,
          agendaId: agenda.id,
        })
          .then((res) => {
            agendas[agendaIndex].action_items = res.results;

            apiLoading.actionItem = false;
            if (apiLoading.motion === false && apiLoading.actionItem === false)
              apiLoadingAgendas[agendaIndex] = false;

            if (apiLoadingAgendas.every((val) => val === false))
              onFinish(agendas);
          })
          .catch((err) => {
            console.log(err);

            apiLoading.actionItem = false;
            if (apiLoading.motion === false && apiLoading.actionItem === false)
              apiLoadingAgendas[agendaIndex] = false;

            if (apiLoadingAgendas.every((val) => val === false))
              onFinish(agendas);

            toast.error("Fetching agenda motions failed");
          });
      });
    })
    .catch((err) => {
      console.log(err);
      toast.error("Fetching agendas failed");
      onFinish(agendas);
    });
}
