import React from "react";
import SelectableItem from "./components/SelectableItem";
import { sidebar_buttons } from "./data/sidebarData";

export default function Sidebar({ hoaId, page, type }) {
  return (
    <div className="bg-white h-screen sm:h-full overflow-y-auto flex flex-col rounded">
      {/* sidebar buttons */}
      <div className="pt-6 pb-10 flex flex-col gap-1 px-4">
        {sidebar_buttons
          .filter(
            (val) => val.label !== "Board Meetings" || type !== "MULTI_FAMILY"
          )
          .map((button, button_index) => (
            <SelectableItem
              key={button_index}
              sidebar_data={button}
              page={page}
              hoaId={hoaId}
            />
          ))}
      </div>
    </div>
  );
}
