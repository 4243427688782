import { navigate } from "raviger";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { patchHoa } from "../../../../../api/ApiV2";
import { findState } from "../../../../../utils/findState";
import { findTerritory } from "../../../../../utils/findTerritory";
import { LoadingCircleIcon } from "../../../../common/AppIcons";
import UserDueDate from "../../../../common/Form/UserDueDate";
import HoaForm from "../../../createHoa/components/hoaDetails/components/HoaForm";
import { building_types } from "../../../../common/Data";

const warning_format = (msg) => {
  if (msg === "This field may not be blank.") return "Please fill this field";
  else return "";
};

export default function UpdateHoaForm({
  hoaId,
  orgs,
  data,
  set_data,
  get_hoa,
  edit_hoa_previlege,
  className,
}) {
  const [tip, set_tip] = useState({});
  const [loading, set_loading] = useState(false);

  useEffect(() => {
    data.zipcode &&
      set_data((data) => {
        const newState = findState(data.zipcode);
        const territory = findTerritory(data.zipcode);
        console.log("Setting State to:", newState);
        territory && console.log("Setting Territory to:", territory);
        return { ...data, state: newState, territory: territory };
      });
  }, [data.zipcode]);

  const onSubmit = () => {
    set_loading(true);
    set_tip({});
    patchHoa({
      external_id: hoaId,
      data: {
        name: data.name,
        zipcode: data.zipCode,
        city: data.city,
        state: data.state,
        street_address_1: data.streetAddress1,
        building_type: data.building_type,
        territory: data.territory,
        geocodeLatitude: data.geocodeLatitude,
        geocodeLongitude: data.geocodeLongitude,
        melissa_base_mak: data.melissaBaseMak,
        units: data.units,
        dues_check_date: data.dues_check_date,
        managed_by: data.managed_by,
      },
    })
      .then((res) => {
        set_loading(false);
        console.log(res);
        toast.success("Property Updated Successfully");
        get_hoa();
      })
      .catch((res) => {
        set_loading(false);
        console.log(res);
        toast.error(res.detail ?? "Error Updating Property");
        set_tip(res);
      });
  };

  const delete_data = (name) => {
    let temp_tip = tip;
    delete temp_tip[name];
    set_tip(temp_tip);
  };

  const return_to_previous = () => {
    navigate("/hoa/" + hoaId + "/");
  };

  return (
    <div className={className}>
      <HoaForm
        {...{
          orgs,
          form: data,
          setHoaDetail: (value) => {
            set_data(value);
          },
          warnings: tip,
          setWarnings: set_tip,
          delete_warning: delete_data,
        }}
        readOnly={!edit_hoa_previlege}
      />
      <div className="-mt-4 sm:mt-0 w-full flex flex-row gap-x-10 gap-y-3 flex-wrap">
        <div className="flex-grow flex flex-col gap-px">
          <div className="w-full flex flex-row justify-between">
            <span className="text-sm text-gray-700">Building type</span>
            {tip?.building_type && (
              <span className="text-red-600 text-xs">
                {warning_format(tip["building_type"][0])}
              </span>
            )}
          </div>

          <select
            value={data.building_type}
            disabled={!edit_hoa_previlege}
            className="flex-grow px-3 py-2 sm:tracking-wide text-gray-700 rounded focus:outline-none bg-gray-100 focus:bg-gray-200"
            onChange={(e) => {
              delete_data("building_type");
              set_data({ ...data, building_type: e.target.value });
            }}
          >
            <option value={false} selected disabled hidden>
              -----------
            </option>
            {building_types.map((option, index1) => (
              <option key={index1} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col gap-px">
          <span className="text-sm text-gray-700">Due date</span>
          <UserDueDate
            readOnly={!edit_hoa_previlege}
            selectClassName="px-3 py-2 sm:tracking-wide text-gray-700 rounded focus:outline-none bg-gray-100 focus:bg-gray-200"
            value={
              data.dues_check_date &&
              new Date(
                2016,
                12,
                new Date(data.dues_check_date).getDate(),
                0,
                0,
                0,
                0
              ).toISOString()
            }
            onChange={(value) => set_data({ ...data, dues_check_date: value })}
          />
        </div>
      </div>

      {/* bottom buttons */}
      <div className="mt-7 w-full flex flex-row-reverse items-center">
        {edit_hoa_previlege && (
          <button
            onClick={onSubmit}
            className="rounded-md bg-blue-600 hover:bg-blue-700 shadow w-24 flex items-center justify-center py-2 text-white text-xl font-bold"
          >
            {loading ? <LoadingCircleIcon className="h-5" /> : "Submit"}
          </button>
        )}
        <button
          onClick={return_to_previous}
          className="px-4 py-2 text-gray-700 hover:text-gray-900 focus:outline-none font-bold text-lg"
        >
          {edit_hoa_previlege ? "Cancel" : "Close"}
        </button>
      </div>
    </div>
  );
}
