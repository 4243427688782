export const amountFormat = (base_amount) => {
  const amount = Number(base_amount);
  if (!base_amount && Number(amount) !== 0) return "";

  let a = amount < 0 ? -amount : amount;
  return a.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
