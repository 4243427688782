import React, { useRef } from "react";
import PayBill from ".";

import Popup from "../../../common/Popup";

export default function PayBillPopup({ hoaId, show, setShow }) {
  const parentRef = useRef(null);
  return (
    <Popup
      ref={parentRef}
      show={show}
      setShow={setShow}
      className="bg-white rounded-md shadow-lg flex flex-col pb-6 sm:max-w-4xl w-full"
    >
      <PayBill parentRef={parentRef} hoaId={hoaId} onSuccess={setShow} />
    </Popup>
  );
}
