import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import Paginator from "../../../../../../common/Paginator";
import {
  ChatIcon2,
  LoadingCircleIcon,
  MailIcon,
  PhoneIcon,
  TickInBoxIcon,
} from "../../../../../common/AppIcons";
import { terms } from "../../../../../common/Data";
import { dateStringFormat } from "../../../../../../utils/DateUtils";
import { amountFormat } from "../../../../../common/AmountFormat";

export default function Vendors({
  transactionLimitPerPage,
  vendors,
  vendorsPage,
  setVendorsPage,
  set_popup,
  set_transaction_popup,
  vendor_transactions,
  vendor_setTransactions,
  vendor_transactionPage,
  vendor_setTransactionPage,
  fetchVendorTransactions,
  vendor_transaction_loading,
}) {
  const [showId, setShowId] = useState();

  useEffect(() => {
    vendors?.length > 0 &&
      vendor_setTransactions((vendor_transactions) => {
        return { ...vendor_transactions, vendorId: vendors[0].id };
      });
  }, [vendors]);

  useEffect(() => {
    (vendor_transactionPage.current || vendor_transactionPage.current === 0) &&
      fetchVendorTransactions();
  }, [vendor_transactionPage.current]);

  useEffect(() => {
    vendor_setTransactionPage((vendor_transactionPage) => {
      return {
        ...vendor_transactionPage,
        current: 0,
      };
    });
    fetchVendorTransactions();
  }, [vendor_transactions?.vendorId]);

  return (
    <div className="flex flex-col lg:flex-row justify-around m-auto py-8 px-4 sm:px-6 lg:px-8 w-full">
      <div className="w-full min-w-max">
        <div className="flex flex-row gap-5 justify-between pl-5 mb-2">
          {[
            { class: "w-3/6", label: "Name" },
            { class: "w-2/6", label: "Contact" },
            { class: "w-1/6", label: "Balance" },
          ].map((value) => {
            return (
              <span
                key={value.name}
                className={"text-gray-600 font-semibold text-sm " + value.class}
              >
                {value.label}
              </span>
            );
          })}
        </div>
        {!vendors ? (
          <div className="h-20">
            <LoadingCircleIcon className="mt-8 h-7 text-blue-600 mx-auto" />
          </div>
        ) : vendors?.length === 0 ? (
          <span className="p-4 flex items-center justify-center font-semibold text-gray-600 text-sm">
            No Vendors available
          </span>
        ) : null}
        {vendors && (
          <div className="mt-4 overflow-auto divide-y divide-gray-400 rounded-md">
            {vendors?.map((vendor) => {
              return (
                <div
                  key={vendor.id}
                  onClick={() => {
                    vendor_setTransactions({
                      ...vendor_transactions,
                      vendorId: vendor.id,
                    });
                  }}
                  className={`justify-between px-5 py-3 flex flex-row gap-5 items-center ${
                    vendor_transactions.vendorId === vendor.id
                      ? "bg-gray-200"
                      : "bg-white hover:bg-gray-100"
                  }`}
                >
                  <div className="w-3/6 text-sm flex flex-row items-center gap-2 break-all">
                    {vendor?.display_name}
                    <FontAwesomeIcon
                      icon={faPen}
                      className="ml-3 text-gray-800 hover:text-gray-600 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        set_popup({
                          type: "VENDOR",
                          data: vendor,
                        });
                      }}
                    />
                  </div>
                  <div className="w-2/6 flex flex-row flex-wrap gap-4">
                    <a href={vendor?.phone ? `tel:${vendor?.phone}` : null}>
                      <PhoneIcon
                        style={{ height: "19px" }}
                        className={
                          "p-px rounded " +
                          (vendor?.phone
                            ? "cursor-pointer text-gray-900 hover:text-gray-500"
                            : "text-gray-400")
                        }
                      />
                    </a>

                    <a href={vendor?.email ? `tel:${vendor?.phone}` : null}>
                      <MailIcon
                        style={{ height: "15px" }}
                        className={
                          "p-px rounded " +
                          (vendor?.email
                            ? "cursor-pointer text-gray-900 hover:text-gray-500"
                            : "text-gray-400")
                        }
                      />
                    </a>

                    <ChatIcon2
                      style={{ height: "17px" }}
                      className="cursor-pointer text-gray-900 hover:text-gray-500"
                    />
                  </div>
                  <div className="w-1/6 flex flex-row-reverse">
                    <span className="text-sm">
                      {amountFormat(vendor?.pending_balance)}
                    </span>
                  </div>
                </div>
              );
            })}
            <Paginator
              currentPage={vendorsPage.current + 1}
              totalPage={vendorsPage.total}
              entryPerPage={300}
              onChange={(pageNo) => {
                setVendorsPage({ ...vendorsPage, current: pageNo - 1 });
              }}
            />
          </div>
        )}
      </div>
      <div className={"mt-10 lg:mt-0 lg:ml-5 w-full"}>
        <div className="min-w-max">
          <div className="items-center grid grid-cols-8 justify-between px-6">
            {["Ref", "Bill Date", "Due Date", "Amount", "Paid"].map((value) => {
              return (
                <span
                  key={value}
                  className={
                    "text-gray-600 font-semibold text-sm " +
                    (value === "Ref" || value === "Paid" ? "" : "col-span-2")
                  }
                >
                  {value === "Paid" ? (
                    <div className="flex flex-row-reverse">{value}</div>
                  ) : (
                    value
                  )}
                </span>
              );
            })}
          </div>
          <div className="mt-4 divide-y divide-gray-400 rounded-md bg-white">
            {vendor_transaction_loading ? (
              <div className="p-4 flex items-center justify-center">
                <LoadingCircleIcon className="h-7 text-blue-600" />
              </div>
            ) : vendor_transactions.data?.length === 0 ? (
              <div className="p-4 flex items-center justify-center font-semibold text-gray-600 text-sm">
                No Transactions to display
              </div>
            ) : (
              vendor_transactions.data.map((value, index) => {
                return (
                  <div
                    key={value.id}
                    className={
                      "hover:bg-gray-100 cursor-pointer items-center grid grid-cols-8 justify-between px-6 py-3 w-full "
                    }
                    onClick={() => {
                      set_transaction_popup({
                        popup: true,
                        type: "BILL",
                        transaction: { bill: value.id },
                      });
                    }}
                  >
                    <span
                      onMouseEnter={() => setShowId(value.id)}
                      onMouseLeave={() => setShowId()}
                      className="text-sm relative"
                    >
                      #{(value.id ?? "").slice(0, 4)}...
                      {value.id === showId && (
                        <span className="absolute px-1.5 py-1 bg-yellow-100 rounded">
                          {value.id}
                        </span>
                      )}
                    </span>
                    <span className="text-sm col-span-2">
                      {dateStringFormat(value.date)}
                    </span>
                    <span className="text-sm col-span-2">
                      {terms.find((val) => val.value === value.terms) &&
                        value.date &&
                        dateStringFormat(
                          new Date(value.date).setDate(
                            new Date(value.date).getDate() +
                              terms.find((val) => val.value === value.terms)
                                ?.value1
                          )
                        )}
                    </span>
                    <span className="text-sm col-span-2">${value.amount}</span>
                    <div className="flex flex-row-reverse">
                      {value.is_paid ? (
                        <TickInBoxIcon className="text-green-600 h-4" />
                      ) : (
                        <div className="h-4 w-4 border-2 border-green-600" />
                      )}
                    </div>
                  </div>
                );
              })
            )}

            <Paginator
              currentPage={vendor_transactionPage.current + 1}
              totalPage={vendor_transactionPage.total}
              entryPerPage={transactionLimitPerPage}
              onChange={(pageNo) =>
                vendor_setTransactionPage({
                  ...vendor_transactionPage,
                  current: pageNo - 1,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
