import React from "react";
import { CheckIcon } from "../AppIcons";

export default function TaskTicker({ tasks, last_finished }) {
  const task_finished = (task) => {
    let flag = false;
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].value === task.value) {
        return flag ? false : true;
      }
      if (tasks[i].value === last_finished) flag = true;
    }
    return tasks.slice(0, tasks.indexOf(last_finished) + 1)?.includes(task);
  };

  return (
    <div className="flex flex-row items-center px-4 pt-2 pb-8">
      {tasks.map((task, task_index) => (
        <div key={task_index} className="flex flex-row items-center">
          {task_index !== 0 && (
            <div
              className={
                "h-px w-10 md:w-16 lg:w-24 " +
                (task_finished(task) ? "bg-green-500" : "")
              }
            />
          )}
          <div
            className={
              "p-2 flex flex-col items-center rounded-full relative " +
              (task_finished(task)
                ? "bg-green-500 text-white"
                : "border border-gray-300")
            }
          >
            {task_finished(task) ? (
              <CheckIcon className="h-4 w-4" />
            ) : (
              <div className="h-4 w-4 rounded-full bg-gray-300" />
            )}
            <span
              className={
                "absolute transform translate-y-8 text-sm " +
                (task_finished(task) ? "text-gray-700" : "text-gray-400")
              }
            >
              {task.label}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}
