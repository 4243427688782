import React from "react";
import { inputFieldData } from "./data/inputFieldData";
import FormInput from "./component/FormInput";

export default function BillDataForm({
  data,
  setData,
  vendors,
  className,
  delete_warning,
  setVendorPopup,
  warning,
  set_vendor_default_account,
  readOnly,
}) {
  return (
    <div
      className={"w-full flex justify-center flex-col items-center" + className}
    >
      <div
        className={
          "mb-6 w-full grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-4"
        }
      >
        {inputFieldData(
          data,
          setData,
          vendors,
          delete_warning,
          setVendorPopup,
          warning,
          set_vendor_default_account,
          readOnly
        )?.top?.map((field, field_index) => (
          <FormInput key={field_index} {...field} />
        ))}
      </div>
      {inputFieldData(
        data,
        setData,
        vendors,
        delete_warning,
        setVendorPopup,
        warning,
        set_vendor_default_account,
        readOnly
      )?.bottom?.map((field, field_index) => (
        <FormInput labelClassName="w-40 mr-3" key={field_index} {...field} />
      ))}
    </div>
  );
}
