import { toast } from "react-toastify";
import {
  editBoardMeetingAgendaActionItem,
  editBoardMeetingAgendaMotion,
  editBoardMeetingAgendaMotionVotes,
  postBoardMeetingAgendaMotionVotes,
  editBoardMeetingAgendas,
  postBoardMeetingAgendaActionItem,
  postBoardMeetingAgendaMotion,
  postBoardMeetingAgendas,
} from "../../../../../api/ApiV2";

export function submitAgenda({
  hoaId,
  boardMeetingId,
  agendaBase,
  agenda_index,
  set_warnings,
  onFinish,
  onError,
}) {
  let agenda = agendaBase;

  let temp_errors = {};

  if (!agenda.title || agenda.title === "")
    temp_errors = {
      ...temp_errors,
      ["agenda" + agenda_index + "title"]: "Please fill this field",
    };

  if (
    !agenda.description ||
    agenda.description === "" ||
    agenda.description === "\n"
  )
    temp_errors = {
      ...temp_errors,
      ["agenda" + agenda_index + "description"]: "Please fill this field",
    };

  agenda.agenda_motions?.map((motion, motion_index) => {
    if (!motion.title || motion.title === "")
      temp_errors = {
        ...temp_errors,
        ["agenda" + agenda_index + "motion" + motion_index + "title"]:
          "Please fill this field",
      };

    if (!motion.proposed_by || motion.proposed_by === "")
      temp_errors = {
        ...temp_errors,
        ["agenda" + agenda_index + "motion" + motion_index + "proposed_by"]:
          "Please fill this field",
      };
    if (!motion.seconded_by || motion.seconded_by === "")
      temp_errors = {
        ...temp_errors,
        ["agenda" + agenda_index + "motion" + motion_index + "seconded_by"]:
          "Please fill this field",
      };
  });

  agenda.action_items?.map((action_item, index) => {
    if (!action_item.title || action_item.title === "")
      temp_errors = {
        ...temp_errors,
        ["action_item" + index + "title"]: "Please fill this field",
      };
    if (
      !action_item.responsible_member ||
      action_item.responsible_member === ""
    )
      temp_errors = {
        ...temp_errors,
        ["action_item" + index + "responsible_member"]:
          "Please fill this field",
      };
  });

  set_warnings(temp_errors);
  if (Object.keys(temp_errors).length > 0) {
    onError();
    return;
  }
  console.log(agenda);
  const agendaAPICall = agenda.id
    ? editBoardMeetingAgendas
    : postBoardMeetingAgendas;

  agendaAPICall({
    hoaId,
    boardMeetingId,
    data: agenda,
    agendaId: agenda.id,
  })
    .then((res) => {
      agenda = { ...agenda, ...res };

      let apiLoading = {
        agenda_motions: agenda.agenda_motions?.length ?? 0,
        action_items: agenda.action_items?.length ?? 0,
      };

      agenda.agenda_motions?.forEach((motion, motionIndex) => {
        submitMotion({
          hoaId,
          boardMeetingId,
          agendaId: res?.id ?? agenda.id,
          motionBase: { is_passed: false, ...(motion ?? {}) },
          onFinish: (val) => {
            agenda.agenda_motions[motionIndex] = { ...motion, ...val };

            apiLoading.agenda_motions--;
            if (
              apiLoading.agenda_motions === 0 &&
              apiLoading.action_items === 0
            ) {
              onFinish(agenda);
            }
          },
        });
      });

      agenda.action_items?.forEach((actionItem, actionIndex) => {
        submitActionItem({
          hoaId,
          boardMeetingId,
          agendaId: res?.id ?? agenda.id,
          actionItem,
          onFinish: (val) => {
            agenda.action_items[actionIndex] = { ...actionItem, ...val };

            apiLoading.action_items--;
            if (
              apiLoading.agenda_motions === 0 &&
              apiLoading.action_items === 0
            ) {
              onFinish(agenda);
            }
          },
        });
      });

      if (apiLoading.agenda_motions === 0 && apiLoading.action_items === 0)
        onFinish(agenda);
    })
    .catch((err) => {
      console.log(err);
      onError();
    });
}

function submitMotion({
  hoaId,
  boardMeetingId,
  agendaId,
  motionBase,
  onFinish,
}) {
  let motion = motionBase;

  const motionAPICall = motion.id
    ? editBoardMeetingAgendaMotion
    : postBoardMeetingAgendaMotion;

  motionAPICall({
    hoaId,
    boardMeetingId,
    agendaId,
    data: motion,
    motionId: motion.id,
  })
    .then((res) => {
      motion = { ...motion, ...res };

      let motionLoading = {
        votes: motion.agenda_motion_votes?.length ?? 0,
      };
      motion.agenda_motion_votes?.forEach((vote, voteIndex) => {
        submitVotes({
          hoaId,
          boardMeetingId,
          agendaId,
          motionId: res?.id ?? motion.id,
          votes: vote,
          onFinish: (val) => {
            motion.agenda_motion_votes[voteIndex] = { ...vote, ...val };
            motionLoading.votes--;
            if (motionLoading.votes === 0) {
              onFinish(motion);
            }
          },
        });
      });
      if (motionLoading.votes === 0) onFinish(motion);
    })
    .catch((err) => {
      console.log(err);
      toast.error("Submitting vote failed");
      onFinish(motion);
    });
}

function submitVotes({
  hoaId,
  boardMeetingId,
  agendaId,
  motionId,
  votes,
  onFinish,
}) {
  const motionAPICall = votes.id
    ? editBoardMeetingAgendaMotionVotes
    : postBoardMeetingAgendaMotionVotes;

  motionAPICall({
    hoaId,
    boardMeetingId,
    agendaId,
    motionId,
    data: votes,
    voteId: votes.id,
  })
    .then((res) => {
      onFinish({ ...votes, ...res });
    })
    .catch((err) => {
      console.log(err);
      toast.error("Submitting motion failed");
      onFinish(votes);
    });
}

function submitActionItem({
  hoaId,
  boardMeetingId,
  agendaId,
  actionItem,
  onFinish,
}) {
  const actionItemAPICall = actionItem.id
    ? editBoardMeetingAgendaActionItem
    : postBoardMeetingAgendaActionItem;

  actionItemAPICall({
    hoaId,
    boardMeetingId,
    agendaId,
    data: actionItem,
    actionItemId: actionItem.id,
  })
    .then((res) => {
      onFinish({ ...actionItem, ...res });
    })
    .catch((err) => {
      console.log(err);
      toast.error("Submitting action item failed");
      onFinish(actionItem);
    });
}
