import { Link } from "raviger";
import React from "react";
import { LeftArrowIcon } from "../../../common/AppIcons";
import { reports } from "./data";

export default function Reports({ hoaId }) {
  return (
    <div className="mt-8 flex flex-row items-start justify-around flex-wrap gap-6 md:grid md:grid-cols-3">
      {reports.map((ele, ele_index) => (
        <Link
          key={ele_index}
          href={ele.link?.replaceAll(":hoaId", hoaId) ?? "#"}
          className="p-6 bg-white rounded flex flex-col items-start gap-4 shadow-sm hover:shadow-lg border border-white hover:border-gray-200"
        >
          <div className={"p-2 " + ele.icon_container_className}>
            {ele.icon}
          </div>
          <span className="text-lg font-semibold flex flex-row gap-2 items-center">
            {ele.label}
            <LeftArrowIcon className="h-5 transform rotate-180 translate-y-px" />
          </span>
        </Link>
      ))}
    </div>
  );
}
