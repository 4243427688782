import { useCSVReader } from "react-papaparse";

import { DownloadIcon2, TemplateIcon } from "../../../../../common/AppIcons";
import { toast } from "react-toastify";

export default function CsvUpload({ setHoaData, units }) {
  const { CSVReader } = useCSVReader();

  const csv_upload = (value) => {
    console.log("csv data", value);

    if (value[0]?.[0] === "Unit") {
      let fields = [
        "unit_number",
        "unit_size",
        "beds",
        "baths",
        "monthly_dues",
      ];
      let tempUnits = [];
      value.slice(1).map((unit) => {
        let unitObj = {};
        fields.map((field, index) => {
          unitObj[field] = unit[index]?.length === 0 ? null : unit[index];
        });

        unitObj = {
          ...units.find((val) => val.unit_number === unit[0]),
          ...unitObj,
        };
        tempUnits.push(unitObj);
      });
      console.log(tempUnits);
      setHoaData((hoaData) => {
        return { ...hoaData, units: tempUnits };
      });
    } else if (value[0]?.[0] === "First Name") {
      // let fields = [
      //   "first_name",
      //   "last_name",
      //   "phone",
      //   "email",
      //   "unit_number",
      //   "user_type",
      // ];
      let members = [];
      value
        .filter((val) => val.length > 6)
        .slice(1)
        .map((member) => {
          let memberObj = {
            unit: units.find((val) => val.unit_number === member[4])?.id,
            user_type: member[5],
            user: {
              first_name: member[0],
              last_name: member[1],
              phone: member[2]?.length > 1 ? member[2] : null,
              email: member[3]?.length > 1 ? member[3] : null,
            },
          };
          members.push(memberObj);
        });
      setHoaData((hoaData) => {
        return {
          ...hoaData,
          members: [...(hoaData.members ?? []), ...members],
        };
      });
    } else toast.error("Invalid CSV file");
  };

  return (
    <div className="flex flex-row items-center gap-3">
      <CSVReader
        onUploadAccepted={(results) => {
          console.log(results.data);
          csv_upload(results.data);
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
          <>
            <div className="flex flex-row items-baseline gap-2">
              <button
                type="button"
                {...getRootProps()}
                className="flex flex-row items-center justify-center text-sm bg-white text-gray-500 hover:text-gray-600 border border-gray-500 hover:border-gray-600 py-2 px-3 rounded"
              >
                <div className="flex flex-row items-center gap-2">
                  <TemplateIcon className="h-4 w-4 shrink-0" />
                  Import CSV
                </div>
              </button>
            </div>
          </>
        )}
      </CSVReader>
    </div>
  );
}
