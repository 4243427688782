import { amountFormat } from "../../../../common/AmountFormat";

export const tableRows = ({ value }) => [
  {
    label: value?.account_object?.name,
    className: "col-span-2",
  },
  {
    label: value?.description,
    className: "col-span-2",
  },
  {
    label: "$" + amountFormat(value.amount),
    className: "justify-end",
  },
];
