import MarkdownPreview from "@uiw/react-markdown-preview";
import { ProfilePic } from "../../../community/pages/boardMeeting/constants";
import { timeDifference } from "../../../common/Form/TimeDisplay";

const status_className = (status) => {
  if (status === "APPROVE")
    return { outer: "bg-green-200 text-green-500", inner: "bg-green-500" };
  else if (status === "DENY")
    return { outer: "bg-red-200 text-red-500", inner: "bg-red-500" };
  else return { outer: "bg-gray-100 text-gray-500", inner: "bg-gray-500" };
};

export default function Recommendation({
  recommendation,
  recommendation_status_array,
  leading = true,
}) {
  return (
    <div className="min-w-full rounded bg-gray-200 px-6 pt-4 pb-6 flex flex-col gap-4">
      <div className="flex flex-row items-center gap-2 w-full">
        {leading && (
          <ProfilePic
            member={recommendation.created_by}
            className="shadow-none h-8 w-8 flex items-center justify-center"
          />
        )}
        {leading && (
          <span className="font-semibold text-sm mr-5">
            {recommendation.first_name ?? ""} {recommendation.last_name ?? ""}
          </span>
        )}
        <span
          className={
            "px-4 py-2 flex flex-row items-center gap-2 rounded-full text-sm " +
            status_className(recommendation.comment_type).outer
          }
        >
          <div
            className={
              "h-2 w-2 rounded-full " +
              status_className(recommendation.comment_type).inner
            }
          />
          {
            recommendation_status_array.find(
              (val) => val.value === recommendation.comment_type
            )?.label
          }
        </span>
        <div className="flex flex-grow justify-end">
          <span className="text-gray-500 text-xs">
            {timeDifference(recommendation.created_at)}
          </span>
        </div>
      </div>
      <div className="bg-white">
        <MarkdownPreview
          className="break-words border-none outline-none rounded bg-white px-6 py-3 w-full text-gray-700"
          source={'"' + recommendation.description + '"'}
        />
      </div>
    </div>
  );
}
