import React from "react";
import { HandymanIcon } from "../../../../common/AppIcons";

export default function MarketplaceVendorSelection({
  marketplaceVendors,
  data,
  setData,
  set_form,
}) {
  return (
    <div className="flex flex-col w-full items-center">
      <div className="w-full text-center mt-10 mb-6 text-gray-800 text-lg font-semibold items-center my-3">
        Choose a Vendor from our Marketplace
      </div>
      <div className="flex gap-2 p-4 items-center justify-center">
        {marketplaceVendors && marketplaceVendors?.length > 0 ? (
          marketplaceVendors.map((vendor) => {
            return (
              <div
                onClick={(_) => {
                  setData((data) => {
                    return { ...data, marketplaceVendor: vendor.id };
                  });
                }}
                className="bg-gray-400 hover:bg-gray-500 w-32 h-32 flex items-center flex-col rounded-md cursor-pointer"
              >
                <div className="flex w-32 h-32 justify-center items-center">
                  <div>
                    <HandymanIcon />
                  </div>
                </div>
                <div>{vendor.name}</div>
              </div>
            );
          })
        ) : (
          <div className="w-full flex items-center justify-center px-6 py-12 text-gray-500 font-semibold">
            No Vendors Found.
          </div>
        )}
      </div>
      <div className="w-full flex justify-center flex-row gap-6 mt-5">
        <button
          onClick={() => {
            set_form("VENDOR_SELECTION");
          }}
          style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
          className="px-4 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-70"
        >
          Go back
        </button>
        <button
          onClick={() => {
            data.marketplaceVendor && set_form("VENDOR_SELECTION");
          }}
          style={{ backgroundColor: "#2A32FD" }}
          className="px-5 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-80 text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
}
