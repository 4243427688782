import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { LoadingCircleIcon } from "../../../common/AppIcons";
import Heading from "../../../common/Form/table/Heading";
import Row from "../../../common/Form/table/Row";
import PopupHeading from "../../../common/PopupHeading";
import { tableHeadings } from "./data/tableHeading";
import { tableRows } from "./data/tableRows";
import { getTransactionLegs } from "../../../../api/ApiV2";

export default function TransactionLegsPopup({
  hoaId,
  type,
  transactionId,
  openDetailsPopup,
  closePopup,
}) {
  const [legs, setLegs] = useState([]);
  const [legsLoading, setLegsLoading] = useState(false);

  useEffect(() => {
    if (transactionId) {
      setLegsLoading(true);
      getTransactionLegs({ hoaId, transactionId })
        .then((res) => {
          setLegsLoading(false);
          console.log("legs", res);
          setLegs(res.results);
        })
        .catch((res) => {
          console.log(res);
          toast.error(res?.response?.data?.message);
          closePopup();
        });
    }
  }, [transactionId]);

  if (legsLoading)
    return (
      <div className="w-full flex items-center justify-center py-16">
        <LoadingCircleIcon className="h-6" />
      </div>
    );
  else
    return (
      <div className="w-full bg-white shadow-md rounded-md px-4 sm:px-10 pt-3 pb-5 sm:pt-6 sm:pb-10">
        <PopupHeading closePopup={() => closePopup()}>
          Transaction Legs
        </PopupHeading>

        <div className="mt-1 mx-auto px-1">
          {type !== "EXPENSE" && openDetailsPopup && (
            <div className="w-full flex justify-end px-6 sm:px-12">
              <button
                className="focus:outline-none font-medium border-2 border-gray-400 text-gray-600 px-7 py-1 rounded hover:opacity-60 duration-200"
                onClick={() => {
                  openDetailsPopup();
                }}
              >
                {type === "INVOICE_LEGS" || type === "INVOICE_NORMAL"
                  ? "Open Invoice"
                  : type === "BILL_LEGS" || type === "BILL_NORMAL"
                  ? "Open Bill"
                  : "Open Check"}
              </button>
            </div>
          )}

          {/* table */}
          <Heading
            headings={tableHeadings}
            style={{ backgroundColor: "#F5F5F5" }}
            className="w-full mt-4 grid grid-cols-5 gap-5 px-5 py-3 rounded text-gray-600"
          />

          {legs?.map((value) => {
            return (
              <Row
                key={value.id}
                className="w-full grid grid-cols-5 border-b border-gray-300 text-gray-700 font-semibold px-5 py-4 text-sm items-center gap-2"
                row={tableRows({ value })}
              />
            );
          })}
        </div>
      </div>
    );
}
