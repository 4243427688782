export const getBaseData = (selector = "base_data") => {
  // try {
  //   return JSON.parse(document.getElementById(selector).innerHTML);
  // } catch (e) {
  //   return null;
  // }
  return {};
};

export const getUserData = () => {
  // try {
  //   return JSON.parse(document.getElementById("user_data").innerHTML);
  // } catch (e) {
  //   return null;
  // }
  return {};
};

export const getCookie = (name) => {
  let cookieValue = null;
  console.log(document.cookie);
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      console.log("Finding Cookie");
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        console.log("Cookie Found");
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const getCsrfToken = () => {
  return document?.querySelector("[name=csrfmiddlewaretoken]")?.value;
};
