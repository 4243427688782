import { useState, useEffect, useRef } from "react";
import Datetime from "react-datetime";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import "react-datetime/css/react-datetime.css";

import {
  LeftArrowIcon,
  RightArrowIcon,
  CloseIcon,
  LoadingCircleIcon,
  PlusInSquareIcon,
} from "../../../common/AppIcons";
import { getEvents, postEvents, editEvents } from "../../../../api/ApiV2";
import { userAtom } from "../../../../store/UserAtom";
import { adminTypes } from "../../../../common/data/adminTypes";

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const isToday = (date) => {
  const today = new Date();
  const d = new Date(date);

  return today.toDateString() === d.toDateString() ? true : false;
};

const getBlankDays = (calendarState) => {
  const date = new Date(calendarState.year, calendarState.month);
  let arr = [];
  for (let i = 0; i < date.getDay(); i++) {
    arr.push(null);
  }
  return arr;
};

const getDays = (calendarState) => {
  let date = new Date(calendarState.year, calendarState.month);
  let arr = [];
  for (
    ;
    date.getMonth() === calendarState.month;
    date.setDate(date.getDate() + 1)
  ) {
    arr.push(date.getDate());
  }
  return arr;
};

const todayStyle = (date) => {
  if (isToday(date)) return "bg-blue-500 text-white";
  else return "text-gray-700 hover:bg-blue-200";
};

const eventStyler = (theme) => {
  if (theme === "blue") return "bg-blue-500";
  if (theme === "red") return "bg-red-500";
  if (theme === "yellow") return "bg-yellow-500";
  if (theme === "green") return "bg-green-500";
  if (theme === "purple") return "bg-purple-500";
};

export default function Calendar({ hoaId, set_page = null }) {
  const [user] = useRecoilState(userAtom);

  const scroll_ref = useRef(null);
  const eventTopRef = useRef(new Array());
  const eventBottomRef = useRef(new Array());

  const [calendarState, setCalendarState] = useState({
    date: new Date(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [eventEditor, setEventEditor] = useState();
  const [events, setEvents] = useState([]);
  const [show_event_arrows, set_show_event_arrows] = useState({});
  const [loading, set_loading] = useState(false);

  let editPrevilages = adminTypes.includes(user.selectedUserType);

  const showEventModal = (
    start_time,
    end_time,
    name = "",
    description = "",
    theme = "",
    readOnly = false,
    editEvent = false,
    event_id
  ) => {
    // open the modal
    setEventEditor({
      name: name,
      description: description,
      start_time: new Date(start_time),
      end_time: new Date(end_time ?? start_time),
      theme: theme,
      readOnly: readOnly,
      editEvent: editEvent,
      event_id: event_id,
    });
  };

  useEffect(() => {
    get_event_api();
  }, []);

  const get_event_api = () => {
    getEvents({ hoaId })
      .then((res) => {
        setTimeout(() => {
          scroll_ref?.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
        res.results = res.results.sort((a, b) =>
          new Date(a.start_time) > new Date(b.start_time) ? 1 : -1
        );
        setEvents(
          res.results.map((event) => {
            return {
              ...event,
              start_time: new Date(event.start_time),
              end_time: new Date(event.end_time),
              theme: "blue",
            };
          })
        );
        setTimeout(() => {
          let arr = {};
          for (let i = 0; i < 31; i++) {
            arr = {
              ...arr,
              ["event_" + (i + 1) + "_top"]: !isInViewport(
                eventTopRef.current[i + 1]
              ),
              ["event_" + (i + 1) + "_bottom"]: !isInViewport(
                eventBottomRef.current[i + 1]
              ),
            };
          }
          set_show_event_arrows(arr);
        }, 1000);
      })
      .catch((res) => {
        console.log(res);
        toast.error(
          res?.readableMessage ??
            res?.message ??
            res?.detail ??
            "Error fetching events"
        );
      });
  };

  const addEvent = () => {
    if (eventEditor.name !== "" && !eventEditor.readOnly) {
      set_loading(true);
      postEvents({
        hoaId,
        data: {
          start_time: eventEditor.start_time,
          end_time: eventEditor.end_time,
          name: eventEditor.name,
          description: eventEditor.description,
        },
      })
        .then((res) => {
          set_loading(false);
          console.log(res);
          setEventEditor();
          get_event_api();
        })
        .catch((res) => {
          set_loading(false);
          toast.error(
            res?.readableMessage ?? res?.message ?? "Error posting event"
          );

          console.log("res", res);
        });
    }
  };

  const editEvent = () => {
    if (eventEditor.name !== "" && !eventEditor.readOnly) {
      set_loading(true);
      editEvents({
        hoaId,
        eventId: eventEditor.event_id,
        data: {
          start_time: eventEditor.start_time,
          end_time: eventEditor.end_time,
          name: eventEditor.name,
          description: eventEditor.description,
        },
      })
        .then((res) => {
          set_loading(false);
          console.log(res);
          setEventEditor();
          get_event_api();
        })
        .catch((res) => {
          set_loading(false);
          set_loading(false);
          console.log("res", res);
        });
    }
  };

  const monthOffset = (change) => {
    setCalendarState((state) => {
      const currentDate = new Date(state.year, state.month + change);
      return {
        ...state,
        date: currentDate,
        month: currentDate.getMonth(),
        year: currentDate.getFullYear(),
      };
    });
  };

  function isInViewport(el = null) {
    if (el === undefined || el === null) return true;
    var rect = el.getBoundingClientRect(),
      top = rect.top,
      height = rect.height,
      el = el.parentNode;
    // Check if bottom of the element is off the page
    if (rect.bottom < 0) return false;
    // Check its within the document viewport
    if (top > document.documentElement.clientHeight) return false;
    do {
      rect = el.getBoundingClientRect();
      if (top <= rect.bottom === false) return false;
      // Check if the element is out of view due to a container scrolling
      if (top + height <= rect.top) return false;
      el = el.parentNode;
    } while (el != document.body);
    return true;
  }

  const get_events_of_day = (date) => {
    return events.filter((event) => {
      return (
        new Date(
          new Date(event.start_time).getFullYear(),
          new Date(event.start_time).getMonth(),
          new Date(event.start_time).getDate()
        ).toDateString() ===
        new Date(
          new Date(date).getFullYear(),
          new Date(date).getMonth(),
          new Date(date).getDate()
        ).toDateString()
      );
    });
  };
  return (
    <div className="antialiased sans-serif">
      <div ref={scroll_ref} className="bg-white rounded-lg py-6">
        <div className="flex flex-wrap items-center justify-between gap-3 py-2 px-6">
          <div className="flex flex-row items-center border border-gray-400 rounded-lg gap-1 p-1">
            <button
              type="button"
              className={
                "leading-none rounded-lg transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 items-center"
              }
              onClick={() => {
                monthOffset(-1);
              }}
            >
              <LeftArrowIcon className="h-6 w-6 text-gray-500 inline-flex leading-none" />
            </button>

            <div>
              <span className="text-lg font-semibold text-gray-800">
                {calendarState.date.toLocaleString("default", {
                  month: "long",
                })}
              </span>
              <span className="text-lg ml-1 text-gray-600 font-normal">
                {calendarState.year}
              </span>
            </div>

            <button
              type="button"
              className={
                "leading-none rounded-lg transition ease-in-out duration-100 inline-flex items-center cursor-pointer hover:bg-gray-200 p-1"
              }
              onClick={() => {
                monthOffset(1);
              }}
            >
              <RightArrowIcon className="h-6 w-6 text-gray-500 inline-flex leading-none" />
            </button>
          </div>

          <div className="flex flex-row flex-wrap gap-5 gap-y-3 items-center">
            <button
              onClick={() => {
                set_page && set_page("LIST");
              }}
              style={{
                color: "#2A32FD",
                border: "2px solid #2A32FD",
              }}
              className="px-8 py-1.5 rounded text-sm hover:opacity-50"
            >
              {editPrevilages ? "All Events" : "Requested Events"}
            </button>
            <button
              onClick={() => {
                showEventModal(new Date().setMinutes(0));
              }}
              style={{
                backgroundColor: "#2A32FD",
                border: "2px solid #2A32FD",
              }}
              className="px-5 py-1.5 rounded text-white text-sm flex flex-row items-center gap-2 hover:opacity-60"
            >
              <PlusInSquareIcon className="h-3" />
              {editPrevilages ? "Add Event" : "Request Event"}
            </button>
          </div>
        </div>

        <div className="mx-1 mb-1 px-2">
          <div className="grid grid-cols-7">
            {DAYS.map((value, index) => {
              return (
                <div className="px-2 py-2 ">
                  <div className="text-gray-600 text-sm uppercase tracking-wide font-bold text-center">
                    {value}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="border-t border-l grid grid-cols-7">
            {getBlankDays(calendarState).map((_) => {
              return (
                <div className="text-center border-r border-b px-4 pt-2 h-28"></div>
              );
            })}
            {getDays(calendarState).map((value, index1) => {
              return (
                <div id="cale" className="pt-2 border-r border-b relative h-28">
                  <div
                    onClick={() => {
                      showEventModal(
                        new Date(calendarState.year, calendarState.month, value)
                      );
                    }}
                    className={
                      "inline-flex w-6 h-6 mx-4 items-center justify-center cursor-pointer text-center leading-none rounded-full transition ease-in-out duration-100 " +
                      todayStyle(
                        new Date(calendarState.year, calendarState.month, value)
                      )
                    }
                  >
                    {value}
                  </div>
                  <div
                    className={
                      "absolute transform -translate-y-1 w-full py-px flex items-center justify-center bg-white bg-opacity-60 border border-gray-100 rounded transition duration-300 " +
                      (show_event_arrows["event_" + value + "_top"]
                        ? "opacity-100 "
                        : "opacity-10 ") +
                      (get_events_of_day(
                        new Date(calendarState.year, calendarState.month, value)
                      ).length > 0
                        ? ""
                        : "hidden")
                    }
                  >
                    <LeftArrowIcon className="h-3 transform rotate-90" />
                  </div>
                  <div
                    onScroll={() => {
                      set_show_event_arrows({
                        ...show_event_arrows,
                        ["event_" + value + "_top"]: !isInViewport(
                          eventTopRef.current[value]
                        ),
                        ["event_" + value + "_bottom"]: !isInViewport(
                          eventBottomRef.current[value]
                        ),
                      });
                    }}
                    className="overflow-y-auto h-16 mt-1"
                  >
                    {get_events_of_day(
                      new Date(calendarState.year, calendarState.month, value)
                    )
                      .filter((val) => val.status === "APPROVED")
                      .map((event, index) => {
                        return (
                          <p
                            key={index}
                            className={
                              "text text-white text-sm px-1 mt-px font-thin break-words cursor-pointer leading-4 pb-px rounded-sm hover:bg-gray-700 " +
                              eventStyler(event.theme)
                            }
                            onClick={() => {
                              showEventModal(
                                event.start_time,
                                event.end_time,
                                event.name,
                                event.description,
                                event.theme,
                                !editPrevilages,
                                true,
                                event.id
                              );
                            }}
                          >
                            {(index === 0 ||
                              index ===
                                get_events_of_day(
                                  new Date(
                                    calendarState.year,
                                    calendarState.month,
                                    value
                                  )
                                ).length -
                                  1) && (
                              <div
                                className="h-px w-px"
                                ref={(ele) => {
                                  index === 0
                                    ? (eventTopRef.current[value] = ele)
                                    : (eventBottomRef.current[value] = ele);
                                }}
                              />
                            )}
                            {(new Date(event.start_time).getHours() > 12 ||
                            new Date(event.start_time).getHours() === 0
                              ? (
                                  "0" +
                                  Math.abs(
                                    new Date(event.start_time).getHours() - 12
                                  )
                                ).slice(-2)
                              : (
                                  "0" + new Date(event.start_time).getHours()
                                ).slice(-2)) +
                              ":" +
                              (
                                "0" + new Date(event.start_time).getMinutes()
                              ).slice(-2) +
                              " " +
                              (new Date(event.start_time).getHours() > 11
                                ? " PM "
                                : " AM ") +
                              event.name}
                          </p>
                        );
                      })}
                  </div>
                  <div
                    className={
                      "absolute transform -translate-y-1 w-full py-px flex items-center justify-center bg-white bg-opacity-60 border border-gray-100 rounded " +
                      (show_event_arrows["event_" + value + "_bottom"]
                        ? "opacity-100 "
                        : "opacity-10 ") +
                      (get_events_of_day(
                        new Date(calendarState.year, calendarState.month, value)
                      ).length > 0
                        ? ""
                        : "hidden")
                    }
                  >
                    <LeftArrowIcon className="h-3 transform -rotate-90" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/*< !--Modal -->*/}
      {eventEditor ? (
        <div
          className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full bg-opacity-50 bg-gray-400 flex items-center justify-center"
          onClick={() => setEventEditor()}
        >
          {loading ? (
            <LoadingCircleIcon className="h-10 text-blue-500 mx-auto" />
          ) : (
            <div
              className="p-4 max-w-xl mx-auto absolute left-0 right-0"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                className="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
                onClick={() => setEventEditor()}
              >
                <CloseIcon className="fill-current w-6 h-6" />
              </div>

              <div className="shadow rounded-lg bg-white w-full block p-8">
                <h2 className="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">
                  {eventEditor.readOnly
                    ? ""
                    : eventEditor.editEvent
                    ? "Edit"
                    : !editPrevilages
                    ? "Request"
                    : "Add"}{" "}
                  Event Details
                </h2>

                <div className="mb-4">
                  <label className="text-gray-800 block mb-1 font-bold text-sm tracking-wide">
                    Event Name
                  </label>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    name="name"
                    value={eventEditor.name}
                    onChange={(e) => {
                      const newValue = e.target.value;

                      if (!eventEditor.readOnly)
                        setEventEditor((current) => {
                          return { ...current, name: newValue };
                        });
                    }}
                  ></input>
                </div>

                <div className="mb-4">
                  <label className="text-gray-800 block mb-1 font-bold text-sm tracking-wide">
                    Event Description
                  </label>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    type="text"
                    name="description"
                    value={eventEditor.description}
                    onChange={(e) => {
                      const newValue = e.target.value;

                      if (!eventEditor.readOnly)
                        setEventEditor((current) => {
                          return { ...current, description: newValue };
                        });
                    }}
                  ></input>
                </div>

                <div className="mb-4">
                  <label className="text-gray-800 block mb-1 font-bold text-sm tracking-wide">
                    Start Date and Time
                  </label>
                  <Datetime
                    classname="w-full"
                    inputProps={{
                      className:
                        "bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500",
                    }}
                    value={eventEditor.start_time}
                    onChange={(e) => {
                      setEventEditor({
                        ...eventEditor,
                        start_time: e._d,
                        end_time: e._d,
                      });
                    }}
                  />
                </div>

                <div className="mb-4">
                  <label className="text-gray-800 block mb-1 font-bold text-sm tracking-wide">
                    End Date and Time
                  </label>
                  <Datetime
                    classname="w-full"
                    inputProps={{
                      className:
                        "bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500",
                    }}
                    value={eventEditor.end_time}
                    onChange={(e) => {
                      setEventEditor({ ...eventEditor, end_time: e._d });
                    }}
                  />
                </div>

                <div className="mt-8 text-right">
                  <button
                    type="button"
                    className="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded-lg shadow-sm mr-2"
                    onClick={() => setEventEditor()}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      eventEditor.editEvent ? editEvent() : addEvent();
                    }}
                    className={
                      "text-white font-semibold py-2 px-4 border border-gray-700 rounded-lg shadow-sm " +
                      (eventEditor.readOnly
                        ? "bg-gray-500"
                        : "bg-gray-800 hover:bg-gray-700")
                    }
                  >
                    {eventEditor.editEvent
                      ? "Update"
                      : !editPrevilages
                      ? "Request Event"
                      : "Save Event"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}
