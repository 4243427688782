import { useEffect, useState } from "react";
import { useQueryParams } from "raviger";

import Paginator from "../../../../common/Paginator";
import { getAllTransactions } from "../../../../api/ApiV2";
import { LoadingCircleIcon } from "../../../common/AppIcons";
import Modal from "../../../common/Modal";
import PageHeading from "./components/PageHeading";
import { pageData } from "./data/pageData";
import Heading from "../../../common/Form/table/Heading";
import Row from "../../../common/Form/table/Row";
import TransactionLegsPopup from "../../components/transactionLegsPopup";
import Popup from "../../../common/Popup";
import EditJournalEntryPopup from "../journalEntry/EditJournalEntryPopup";
import CheckDetailPopup from "../../components/check/CheckDetailPopup";
import BillDetailPopup from "../../components/bill/BillDetailPopup";
import RevanueDetailPopup from "../../components/addRevenue/RevenueDetailPopup";

const entryPerPage = 25;
export default function TransactionList({
  hoaId,
  closePage = null,
  type,
  //   CHECK,JOURNAL,RECENT, SEARCH
}) {
  const [queryParams, setQueryParams] = useQueryParams();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [popup, setPopup] = useState({
    popup: false,
    data: {},
    transaction: {},
  });

  useEffect(() => {
    if (popup.popup === false)
      getAllTransactions({
        hoaId,
        page: page.current * entryPerPage,
        limit: entryPerPage,
        type: type === "CHECK" || type === "JOURNAL" ? type : undefined,
        search_field: type === "SEARCH" ? queryParams.search_field : undefined,
      })
        .then((res) => {
          console.log(res.results);
          setPage({ ...page, total: res.count });
          setTransactions(res.results);
          setLoading(false);
        })
        .catch((res) => {
          console.log(res);
          setLoading(false);
        });
  }, [page.current, popup.popup]);

  const openPopup = (transaction) => {
    if (transaction.type === "JOURNAL") {
      setPopup((popup) => {
        return {
          ...popup,
          popup: true,
          type: "JOURNAL",
          transaction: transaction,
        };
      });
    } else
      transaction.type === "INVOICE"
        ? setPopup({
            popup: true,
            type: "INVOICE_LEGS",
            transaction: transaction,
          })
        : transaction.type === "BILL"
        ? setPopup({
            popup: true,
            type: "BILL_LEGS",
            transaction: transaction,
          })
        : transaction.type === "CHECK"
        ? setPopup({
            popup: true,
            type: "CHECK_LEGS",
            transaction: transaction,
          })
        : setPopup({ popup: true, type: "EXPENSE", transaction: transaction });
  };

  const fetch_data_open_popup = () => {
    popup.type === "INVOICE_LEGS"
      ? setPopup((popup) => ({ ...popup, type: "INVOICE" }))
      : popup.type === "BILL_LEGS"
      ? setPopup((popup) => ({ ...popup, type: "BILL" }))
      : setPopup((popup) => ({ ...popup, type: "CHECK" }));
  };
  return (
    <div className="mt-10 relative z-10">
      <PageHeading
        closePage={closePage}
        label={pageData(type, queryParams?.search_field)?.heading}
      />

      {/* table */}
      <div className="mt-8 flex flex-col w-full gap-1">
        <Heading
          headings={pageData(type, queryParams?.search_field)?.table?.headings}
          className={
            "w-full grid gap-2 px-5 sm:px-8 text-gray-600 font-semibold " +
            pageData(type)?.table?.className
          }
        />

        <div className="w-full flex flex-col overflow-y-auto overflow-x-hidden divide-y divide-gray-300">
          {loading ? (
            <LoadingCircleIcon className="h-7 my-12 sm:my-16 mx-auto" />
          ) : transactions.length === 0 ? (
            <div className="text-sm my-12 text-gray-400 text-center">
              No Transaction History Available
            </div>
          ) : (
            transactions.map((value) => {
              return (
                <Row
                  key={value.id}
                  onClick={() => {
                    openPopup(value);
                  }}
                  className={
                    "w-full grid gap-2 hover:bg-gray-200 text-gray-800 rounded text-sm bg-white cursor-pointer px-5 sm:px-8 py-3 " +
                    pageData(type)?.table?.className
                  }
                  row={pageData(type, value)?.table?.rows}
                />
              );
            })
          )}
        </div>
      </div>

      <Paginator
        totalPage={page.total}
        currentPage={page.current + 1}
        entryPerPage={entryPerPage}
        onChange={(pageNo) => {
          setPage({ ...page, current: pageNo - 1 });
        }}
      />

      <Popup
        show={
          popup.popup &&
          !(
            popup.type === "INVOICE" ||
            popup.type === "BILL" ||
            popup.type === "CHECK" ||
            popup.type === "JOURNAL"
          )
        }
        setShow={() => {
          setPopup({ ...popup, popup: false });
        }}
        className="sm:max-w-4xl flex items-center justify-center p-2"
      >
        <TransactionLegsPopup
          type={popup.type}
          transactionId={popup.transaction.id}
          openDetailsPopup={fetch_data_open_popup}
          closePopup={() => setPopup({ ...popup, popup: false })}
          hoaId={hoaId}
        />
      </Popup>
      <Modal
        show={
          popup.popup &&
          (popup.type === "INVOICE" ||
            popup.type === "BILL" ||
            popup.type === "CHECK" ||
            popup.type === "JOURNAL")
        }
        setShow={() => {
          if (type === "CHECK") setPopup({ ...popup, popup: false });
          else if (popup.type === "INVOICE")
            setPopup({ ...popup, type: "INVOICE_LEGS" });
          else if (popup.type === "BILL") return;
          else if (popup.type === "CHECK")
            setPopup({ ...popup, type: "CHECK_LEGS" });
        }}
        wide
      >
        {popup.type === "BILL" ? (
          <BillDetailPopup
            hoaId={hoaId}
            onSuccess={() => {
              setPopup({ ...popup, type: "BILL_LEGS" });
            }}
            billId={popup.transaction.bill}
          />
        ) : popup.type === "INVOICE" ? (
          <RevanueDetailPopup
            hoaId={hoaId}
            onSuccess={() => {
              setPopup({ ...popup, type: "INVOICE_LEGS" });
            }}
            transactionId={popup.transaction.id}
            billId={popup.transaction.bill}
            remove_pay={true}
          />
        ) : popup.type === "CHECK" ? (
          <CheckDetailPopup
            hoaId={hoaId}
            onSuccess={() => {
              type === "CHECK"
                ? setPopup({ ...popup, popup: false })
                : setPopup({ ...popup, type: "CHECK_LEGS" });
            }}
            transactionId={popup.transaction.id}
          />
        ) : popup.type === "JOURNAL" ? (
          <EditJournalEntryPopup
            hoaId={hoaId}
            onSuccess={() => {
              setPopup({ ...popup, popup: false });
            }}
            readOnly
            transactionId={popup.transaction.id}
          />
        ) : null}
      </Modal>
    </div>
  );
}
