import Input from "../../../../../../common/Form/Input";

export default function FormInput({
  label,
  labelClassName,
  warning,
  ...props
}) {
  return (
    <div className={"mb-2 md:mb-1 md:flex items-center"}>
      <label
        className={
          "w-28 text-gray-700 font-semibold text-sm tracking-wide whitespace-pre flex flex-row items-center " +
          labelClassName
        }
      >
        {label} <span className="hidden md:block">:</span>
      </label>
      <div
        className={
          "w-full flex flex-col items-start " +
          (warning ? "relative md:bottom-1" : "")
        }
      >
        {warning && <span className="text-xs text-red-600">{warning}</span>}
        <Input {...props} />
      </div>
    </div>
  );
}
