import React from "react";

import {
  PersonIcon,
  LocationIcon,
  ContactBookIcon,
  PhoneIcon,
} from "../../../../common/AppIcons";

const detail_icon_className = "w-4 text-indigo-500";
const iconStyle = {};

export default function SRAddress({ sr_data, open_pdf }) {
  return (
    <div className="flex flex-col gap-5">
      {[
        {
          icon: (
            <PersonIcon style={iconStyle} className={detail_icon_className} />
          ),
          label: sr_data.clientName ?? "",
        },
        {
          icon: (
            <LocationIcon
              style={{ width: "1.2rem", marginLeft: "-0.1rem" }}
              className={detail_icon_className}
            />
          ),
          label:
            (sr_data?.streetAddress1 ? sr_data.streetAddress1 + ", " : "") +
            (sr_data?.streetAddress2 ? sr_data.streetAddress2 + ", " : "") +
            (sr_data?.city ? sr_data?.city : ""),
        },
        {
          icon: (
            <PhoneIcon style={iconStyle} className={detail_icon_className} />
          ),
          label:
            (sr_data.contactFirstName ?? "") +
            " " +
            (sr_data.contactLastName ?? ""),
        },
        {
          icon: (
            <PhoneIcon style={iconStyle} className={detail_icon_className} />
          ),
          label:
            (sr_data.alternateContactFirstName ?? "") +
            " " +
            (sr_data.alternateContactLastName ?? ""),
          warning:
            sr_data.alternateContactFirstName ||
            sr_data.alternateContactLastName
              ? null
              : "No alternate contact",
        },
      ].map((ele, ele_index) => (
        <div key={ele_index} className={"flex flex-row items-center gap-3"}>
          {ele.icon}
          {ele.warning ? (
            <span
              className={
                "text-xs text-red-400 " + (open_pdf ? "relative bottom-2" : "")
              }
            >
              {ele.warning}
            </span>
          ) : (
            <span className={open_pdf ? "relative bottom-2 text-sm" : ""}>
              {ele.label}
            </span>
          )}
        </div>
      ))}
    </div>
  );
}
