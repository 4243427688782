import { dateStringFormat } from "../../../../../utils/DateUtils";
import { meeting_types } from "../constants";

const string_adder = (arr) => {
  let array = "";

  for (let i = 0; i < arr.length; i++) {
    array = array + arr[i];
  }
  return array;
};

export function board_meeting_details({ meeting, members }) {
  return (
    " # **" +
    meeting.name +
    "**  \n " +
    dateStringFormat(meeting.start_time) +
    " " +
    new Date(meeting.start_time).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }) +
    "-" +
    (new Date(meeting.start_time).toDateString() ===
    new Date(meeting.end_time).toDateString()
      ? new Date(meeting.end_time).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      : new Date(meeting.end_time).toLocaleDateString() +
        " " +
        new Date(meeting.end_time).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })) +
    "   \n `Meeting type : " +
    meeting_types.find((value) => value.value === meeting.meeting_type)?.label +
    "`   \n  Description : " +
    meeting.description +
    " \n \n " +
    "## **Board Meeting Details** \n  \n *Secretary* :  " +
    (members?.find((value) => {
      return value.user?.id === meeting.secretary;
    })?.user?.first_name +
      " " +
      members?.find((value) => {
        return value.user?.id === meeting.secretary;
      })?.user?.last_name) +
    "       \n *Chairman* : " +
    (members?.find((value) => value.user?.id === meeting.chairman)?.user
      ?.first_name +
      " " +
      members?.find((value) => value.user?.id === meeting.chairman)?.user
        ?.last_name) +
    "   \n *Meeting Location* : " +
    meeting.location +
    "    \n *Required Attendees* : " +
    meeting.mandatory_attendees.map(
      (val, index) =>
        " " +
        (val.user_object?.first_name ?? "") +
        " " +
        (val.user_object?.last_name ?? "") +
        (val.attended ? " - Present" : " - Absent")
    ) +
    "    \n *Optional Attendees* : " +
    meeting.optional_attendees.map(
      (val, index) =>
        " " +
        (val.user_object?.first_name ?? "") +
        " " +
        (val.user_object?.last_name ?? "") +
        (val.attended ? " - Present" : " - Absent")
    ) +
    "    \n"
  );
}

export function agenda_details({ agenda, members, value, index }) {
  return (
    "    \n \n \n ### **" +
    (index + 1) +
    ". " +
    agenda.title +
    "**   \n \n *Description :* " +
    agenda.description +
    "    \n \n " +
    ((!value || value === "SCHEDULED" || value === "UNSCHEDULED") &&
    agenda.agenda_motions &&
    agenda.agenda_motions.length !== 0
      ? " ### ***Motions***" +
        string_adder(
          agenda.agenda_motions.map(
            (agenda_motion, agenda_motion_index) =>
              " \n \n * **" +
              agenda_motion.title +
              "**  \n *Proposed by :* " +
              (members?.find(
                (value1) => value1.user?.id === agenda_motion.proposed_by
              )?.user?.first_name +
                " " +
                members?.find(
                  (value1) => value1.user?.id === agenda_motion.proposed_by
                )?.user?.last_name) +
              "  \n *Seconded by :* " +
              (members?.find(
                (value1) => value1.user?.id === agenda_motion.seconded_by
              )?.user?.first_name +
                " " +
                members?.find(
                  (value1) => value1.user?.id === agenda_motion.seconded_by
                )?.user?.last_name) +
              (agenda_motion.agenda_motion_votes &&
              agenda_motion.agenda_motion_votes.length !== 0
                ? "  \n  *Votes :* " +
                  agenda_motion.agenda_motion_votes.map(
                    (votes) =>
                      " " +
                      members?.find((value1) => value1.user?.id === votes.user)
                        ?.user?.first_name +
                      " " +
                      members?.find((value1) => value1.user?.id === votes.user)
                        ?.user?.last_name +
                      " - `" +
                      votes.vote +
                      "`"
                  )
                : "") +
              "  \n " +
              (agenda_motion.is_passed
                ? "*Resolution :* " + agenda_motion.resolution
                : "")
          )
        )
      : "") +
    ((!value || value === "SCHEDULED" || value === "UNSCHEDULED") &&
    agenda.action_items &&
    agenda.action_items.length !== 0
      ? " \n \n ### ***Action Items***" +
        string_adder(
          agenda.action_items?.map(
            (item, item_index) =>
              "  \n * " +
              item.title +
              "   \n *Responsible Member :* " +
              (item.responsible_member &&
                members?.find(
                  (value1) => value1.user?.id === item.responsible_member
                )?.user?.first_name +
                  " " +
                  members?.find(
                    (value1) => value1.user?.id === item.responsible_member
                  )?.user?.last_name)
          )
        )
      : "")
  );
}
