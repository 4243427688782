import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import { userAtom } from "../../../../../store/UserAtom";
import { adminTypes } from "../../../../../common/data/adminTypes";

export default function AddressForm({ address, data, setData, set_form }) {
  const [user] = useRecoilState(userAtom);
  const admin = adminTypes.includes(user.selectedUserType);

  return (
    <div className="w-full flex flex-col items-center">
      {admin ? (
        <div>
          <div className="mt-16 mb-2">
            <span className="font-semibold text-gray-800">
              Please select the Building where you need the Service.
            </span>
          </div>

          <div className="mt-5 w-full flex flex-col max-w-sm mx-auto">
            <label>Address</label>
            <select
              className="mt-1 mb-2 w-full px-3 py-2 border border-gray-300 rounded"
              value={data.unit_address_id}
              onChange={(e) => {
                setData((data) => {
                  return { ...data, unit_address_id: e.target.value };
                });
              }}
            >
              <option value="" selected disabled>
                Select Building
              </option>
              {address?.map((building, index) => (
                <option key={index} value={building.id}>
                  {building.street_address_1}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-16 mb-2 text-gray-800">
            Service request being made for Building{" "}
            <span className="font-semibold">
              {" "}
              {
                user?.userProfiles.find(
                  (val) => val.id === user.selectedUserProfile
                )?.unit_object.address_object?.street_address_1
              }
            </span>
          </div>
        </div>
      )}

      <div className="w-full flex justify-center flex-row gap-6 mt-8">
        <button
          onClick={() => {
            if (data.subCategory) set_form("CATOGARY_SELECTION");
            else set_form("JOB_SELECTION");
          }}
          style={{ color: "#2A32FD", border: "1px solid #2A32FD" }}
          className="px-4 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-70"
        >
          Go back
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (!admin || data.unit_address_id) set_form("RECURRING_FORM");
            else toast.warning("Please select a Building");
          }}
          style={{ backgroundColor: "#2A32FD" }}
          className="px-5 py-1 mt-4 text-sm items-center whitespace-pre rounded hover:opacity-80 text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
}
